import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
} from "@material-ui/core";
import Toaster from '../../../commonMuiComponents/Toaster';
import { useOwnAnalysis, useLineFittingData, useOwnScopeSelector } from "../../../services/queries";
import useStore from "../../../store";
import { MemoizedOwnReport } from "../OwnReport";
import { MemoizedWeekSelector } from "../../WeekSelector";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { convertDateRange, getVolumeFieldName, getDropDownTreeData, getViewMoreElasticityAnalysisData, getEditWeekRangeforPowerBI } from "../../utils";
import { SingleGrid } from "../../single_grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    boxSizing: "border-box",
  },
  paperDiv: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#FFFFF",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
    },
    paddingTop: "25px",
    height: "auto",
  },
  container: {
    backgroundColor: "#F6F8FC",
    height: "100vh",
  },
  button: {
    display: "inline-flex",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  cellData: {
    borderLeft: "1px solid #ccc",
    textTransform: "capitalize",
  },
  xAddButtonCell: {
    color: "#2662FF",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    width: 100,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  yAddButtonCell: {
    color: "#2662FF",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    width: 100,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  dashBoardTable: {
    marginBottom: "1em",
    background: "inherit",
    width: "75%",
  },
  hide: {
    padding: theme.spacing(1),
    width: 390,
    backgroundColor: theme.palette.background.light,
  },
  gridHeader: {
    textAlign: "center",
    padding: "2.5rem",
  },
  tableHeadCell: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  headerCellLevel1: {
    borderBottom: "none",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
    fontWeight: "bold",
  },
  correlationCell: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    borderTop: "none",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  cellTable: {
    padding: "0",
    verticalAlign: "top",
  },
  high: {
    backgroundColor: "#CBF2DB",
    fontWeight: "bold",
    color: "#2ECC71",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  medium: {
    backgroundColor: "#FDF2C5",
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    color: "#DFB202",
  },
  low: {
    backgroundColor: "#FBCCCC",
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    color: "#F03434",
  },
  viewMore: {
    backgroundColor: "inherit",
    fontWeight: "bold",
    textAlign: "center",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    color: "#2663FF",
  },
  emptyHeader: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    border: "none",
  },
  emptyCell: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    borderBottom: "none",
    borderRight: "none",
  },
  iconButton: {
    backgroundColor: "#2663FF",
    color: "#FFFFFF",
    borderRadius: "50%",
    width: "2.5rem",
    height: "2.5rem",
    padding: "5px",
    flexShrink: "none",
    flex: "0 0 auto",
    transform: "rotate(180deg)",
  },
  buttonLabel: {
    fontWeight: "bold",
    display: "inline-flex",
    color: "#2663FF",
  },
  btnContainer: {
    display: "flex",
    justifyContent: 'flex-end',
    marginRight: '15%',
    marginBottom: "1em",
  },
  btnReset: {
    background: "#cb0c0c",
    color: "#fff",
    textTransform: "none",
    margin: "0 20px",
    padding: "6px 20px",
    borderRadius: "10px",
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
}));

const centerAlignText = {
  textAlign: "center",
};
let count = 4;

export const OwnDashboardPerScope = (props) => {
  const classes = useStyles();

  const [ownParameters, setownParameters] = useState({})
  const [selectedCountry, setselectedCountry] = useState("");
  const [productLevel, setProductLevel] = useState("");
  const [originalDataInstance, setOriginalDataInstance] = useState([]);
  const [treeDataInstance, setTreeDataInstance] = useState([]);
  const [slopeInt, setSlopeInt] = useState(0);
  const [slopeFrac, setSlopeFrac] = useState(0);
  const [interceptInt, setInterceptInt] = useState(0);
  const [interceptFrac, setInterceptFrac] = useState(0);
  const [rSquared, setRsquared] = useState(0);
  const [elasticityInt, setElasticityInt] = useState(0);
  const [elasticityFrac, setElasticityFrac] = useState(0);
  const [initial, setInitial] = useState("");
  const [combinedYearWeeks, setCombinedYearWeeks] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = useState("");
  const [customer, setCustomer] = useState("");
  const [averagePrice, setAveragePrice] = useState(0);
  const [ownURLParams, setOwnURLParams] = useState([]);
  const [ownScopeURLParams, setOwnScopeURLParams] = useState([]);
  const [selectedWeekRange, setSelectedWeekRange] = useState([]);
  const [ownElasticityPayload, setOwnElasticityPayload] = useState({});
  const [ownObj, setownObj] = useState();
  const [userSelectedWeekRange, setUserSelectedWeekRange] = useState([]);
  const [selectedWeeksDashboard, setSelectedWeeksDashboard] = useState([]);
  const [isPopUpDataChanges, setIsPopUpDataChanges] = useState(false);
  const [lineFittingDataPayload, setLineFittingDataPayload] = useState({});
  const [canShowProgress, setcanShowProgress] = useState(false);

  const setRegressionApiBodyOwn = useStore((state) => state.setRegressionApiBodyOwn);
  const regressionApiBodyOwn = useStore((state) => state.regressionApiBodyOwn);
  const setSelectedWeeksApply = useStore((state) => state.setSelectedWeeksApply);

  const { isLoading: isOwnDashboardDataPerScopeLoading, data: ownDashboardDataPerScopeResponse, isError: ownDashboardScopeDataError } = useOwnScopeSelector(ownElasticityPayload);
  const { isLoading: isOwnDashboardDataLoading, data: ownDashboardDataResponse, isError: ownDashboardDataError } = useOwnAnalysis(ownElasticityPayload);
  const { isLoading: loadingLineFittingData, data: lineFittingData, isError: isLineFittingError } =
    useLineFittingData(lineFittingDataPayload, "own");
  const [ownDashboardData, setOwnDashboardData] = useState([]);
  const [ownDashboardDataPerScope, setOwnDashboardDataPerScope] = useState([]);
  useEffect(() => {
    if (ownDashboardDataPerScopeResponse?.data) {
      setOwnDashboardDataPerScope(ownDashboardDataPerScopeResponse.data);
    }
  }, [ownDashboardDataPerScopeResponse]);

  useEffect(() => {
    if (ownDashboardDataResponse?.data) {
      setOwnDashboardDataPerScope(ownDashboardDataResponse.data);
    }
  }, [ownDashboardDataResponse]);
  const columns = [
    {
      Header: "Initial Product",
      accessor: "initialProduct",
    },
    {
      Header: getVolumeFieldName(selectedCountry),
      accessor: "averageVolume",
    },
    {
      Header: selectedCountry === 'KR' ? 'Price SU' : "Average Unit Price",
      accessor: "priceIndex",
    },
    {
      Header: "Range",
      accessor: "range",
    },
    {
      Header: "R-Squared",
      accessor: "rsquared",
    },
    {
      Header: "P Value",
      accessor: "pvalue",
    },
  ];

  useEffect(() => {

    const urlObj = new URL(window.location.href)
    const id = urlObj.searchParams.get('id');
    // Get data from sessionStorage
    const sessionStorageData = sessionStorage.getItem(id);
    if (sessionStorageData === null || sessionStorageData === undefined) {
      if (localStorage.getItem(id) !== null) {
        // Get data from localStorage
        sessionStorage.setItem(id, localStorage.getItem(id));
        setTimeout(() => { localStorage.removeItem(id) }, 1000 * 60 * 30);
      } else {
        window.open(`/price-elasticity`);
      }
    }
    let result = JSON.parse(sessionStorage.getItem(id));
    if (result.productLevel === 'EAN') {
      setInitial(result.initial.split('-')[0].trim());
    } else {
      setInitial(result.initial.trim());
    }
    setCategory(result.category)
    if (result.scope === 'PCUST') {
      setCustomer(result.customer)
    }

    setProductLevel(result.productLevel);
    setselectedCountry(result.country);
    setownParameters(result);
    setSelectedWeekRange(result.weekRange);
    setSelectedWeeksApply([])

    const weekRangeSelected = [];
    if (Object.keys(selectedWeekRange).length > 0 && selectedWeekRange[0] !== undefined && selectedWeekRange[0] !== null) {
      for (let [key, value] of Object.entries(selectedWeekRange[0])) {
        weekRangeSelected.push({ 'year': key, 'weeks': value });
      }
      setUserSelectedWeekRange(weekRangeSelected);
    };
  }, [])

  useEffect(() => {
    if (!ownParameters || ownParameters === undefined || Object.keys(ownParameters).length <= 0) return;
    let payload = {
      country: selectedCountry,
      customer: ownParameters.customer,
      hierarchyLevel: productLevel,
      initial: (productLevel === 'EAN') ? [ownParameters.initial.split('-')[0].trim()] : [ownParameters.initial],
      target: []
    }
    setLineFittingDataPayload(payload);
  }, [ownParameters, productLevel])

  useEffect(() => {
    if (lineFittingData !== undefined) {
      let weekRangeSelected = [];
      let selectedWeeksCount = 0;
      if (Object.keys(selectedWeekRange).length > 0 && selectedWeekRange[0] !== undefined && selectedWeekRange[0] !== null) {
        for (let [key, value] of Object.entries(selectedWeekRange[0])) {
          weekRangeSelected.push({ 'year': key, 'weeks': value });
          selectedWeeksCount += value.length;
        }
        setUserSelectedWeekRange(weekRangeSelected);
      };
      // timePeriods = [], when all week ranges are selected in the edit week popup
      if (selectedWeeksCount === lineFittingData.length) {
        weekRangeSelected = [];
      }

      if (ownParameters !== null && ownParameters !== undefined && Object.keys(ownParameters).length > 0) {
        const ownElascticityPayload = {
          category: category,
          country: selectedCountry,
          forOwn: true,
          hierarchyLevel: ownParameters.productLevel,
          initialBrands: ownParameters.productLevel === 'BRAND' ? [] : ownParameters.brandInitial,
          initialManufacturers: ownParameters.manufacturerInitial,
          initialNodeValues: [ownParameters.initial],
          initialPacks: ownParameters.productLevel === 'PACK' ? [] : ownParameters.packInitial,
          initialSubBrands: ownParameters.productLevel === 'SUB_BRAND' ? [] : ownParameters.subBrandInitial,
          initialSubCategories: null,
          scope: ownParameters.scope,
          source: ownParameters.source,
          subCategories: ownParameters.subCategory,
          targetBrands: ownParameters.brandTarget,
          targetManufacturers: ownParameters.manufacturerTarget,
          targetNodeValues: null,
          targetPacks: null,
          targetSubBrands: null,
          targetSubCategories: null,
          timePeriods: weekRangeSelected.length > 0 ? weekRangeSelected : [],
          topNBottomN: 0,
          customer: ownParameters.customer
        }
        setOwnElasticityPayload(ownElascticityPayload);
      }
    }
  }, [category, initial, productLevel, ownParameters, selectedWeekRange, lineFittingData]);

  useEffect(() => {
    const timePeriods = [];
    if (selectedWeeksDashboard.length > 0 && selectedWeeksDashboard.length !== lineFittingData.length) {
      let lineDataObj = {};
      selectedWeeksDashboard.forEach(obj => {
        let key = obj.year.toString();
        if (!lineDataObj[key]) {
          lineDataObj[key] = [obj];
        } else {
          lineDataObj[key].push(obj);
        }
      });

      for (const key in lineDataObj) {
        const weekArray = lineDataObj[key];
        const weeks = [];
        weekArray.forEach((weekData) => {
          if (weekData !== null && weekData !== undefined && weekData.week !== undefined) {
            weeks.push(weekData.week);
          }
        })
        const weekRange = {};
        weekRange["year"] = key;
        weekRange["weeks"] = weeks;
        timePeriods.push(weekRange);
      }
    }

    if (ownParameters !== null && ownParameters !== undefined && Object.keys(ownParameters).length > 0) {
      const ownElascticityPayload = {
        category: category,
        country: selectedCountry,
        forOwn: true,
        hierarchyLevel: ownParameters.productLevel,
        initialBrands: ownParameters.productLevel === 'BRAND' ? [] : ownParameters.brandInitial,
        initialManufacturers: ownParameters.manufacturerInitial,
        initialNodeValues: [ownParameters.initial],
        initialPacks: ownParameters.productLevel === 'PACK' ? [] : ownParameters.packInitial,
        initialSubBrands: ownParameters.productLevel === 'SUB_BRAND' ? [] : ownParameters.subBrandInitial,
        initialSubCategories: null,
        scope: ownParameters.scope,
        source: ownParameters.source,
        subCategories: ownParameters.subCategory,
        targetBrands: ownParameters.brandTarget,
        targetManufacturers: ownParameters.manufacturerTarget,
        targetNodeValues: null,
        targetPacks: null,
        targetSubBrands: null,
        targetSubCategories: null,
        timePeriods: timePeriods.length > 0 ? timePeriods : [],
        topNBottomN: 0,
        customer: ownParameters.customer
      }
      setOwnElasticityPayload(ownElascticityPayload);
    }
  }, [selectedWeeksDashboard]);

  useEffect(() => {
    if (ownObj !== null && ownObj !== undefined) {
      setSlopeInt(ownObj.slopeIntegral);
      setSlopeFrac(ownObj.slopeDecimal);
      setInterceptInt(ownObj.interceptIntegral);
      setInterceptFrac(ownObj.interceptDecimal);
      setRsquared(ownObj.rsquared);
      setElasticityInt(ownObj.elasticityIntegral);
      setElasticityFrac(ownObj.elasticityDecimal);
      setAveragePrice(ownObj.averagePrice);
    }
  }, [ownParameters, regressionApiBodyOwn])

  useEffect(() => {
    if (!loadingLineFittingData && lineFittingData !== undefined) {
      let lineDataObj = {};

      for (let i = 0; i < lineFittingData.length; i++) {
        let obj = lineFittingData[i];
        let key = obj.year.toString();
        if (!lineDataObj[key]) {
          lineDataObj[key] = [obj];
        } else {
          lineDataObj[key].push(obj);
        }
      }

      // Drop Down Tree Select Data
      const treeNodesData = getDropDownTreeData(selectedCountry, lineDataObj, selectedWeekRange[0]);

      setOriginalDataInstance(lineDataObj);
      setTreeDataInstance(treeNodesData);

      //On initial loading pushing all data points
      let result = [];
      for (const key in lineDataObj) {
        for (let i = 0; i < lineDataObj[key].length; i++) {
          result.push(lineDataObj[key][i]);
        }
      }
      setRegressionApiBodyOwn(result);
    }
  }, [loadingLineFittingData, lineFittingData, selectedWeekRange]);

  useEffect(() => {
    if (ownDashboardData !== undefined && ownDashboardData.length > 0 && ownParameters.scope === 'WC') {
      const elasticityRecord = getViewMoreElasticityAnalysisData(ownDashboardData, ownParameters, "own");
      setownObj(ownDashboardData);
    }
  }, [ownDashboardData])

  useEffect(() => {
    if (ownDashboardDataPerScope !== undefined && ownDashboardDataPerScope[ownParameters.initial] !== undefined && ownDashboardDataPerScope && ownParameters.scope !== 'WC') {
      let objectData = ownDashboardDataPerScope[ownParameters.initial][ownParameters.customer];
      setownObj(objectData);
      // Year week to pass to Power BI
      let combinedYearWeeksValues = getEditWeekRangeforPowerBI(objectData.timePeriods);
      setCombinedYearWeeks([...new Set(combinedYearWeeksValues)]);
    }
  }, [ownDashboardDataPerScope])

  useEffect(() => {
    if (isOwnDashboardDataLoading || isOwnDashboardDataPerScopeLoading) {
      setcanShowProgress(true);
    } else {
      setcanShowProgress(false);
    }
  }, [isOwnDashboardDataLoading, isOwnDashboardDataPerScopeLoading]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditPopUpData = (data) => {
    setIsPopUpDataChanges(data);
  }

  const handleClickClose = () => {
    setcanShowProgress(false);
  };

  const ownScopeData = ownDashboardDataPerScope !== undefined ? Object.entries(ownDashboardDataPerScope) : {};
  const ownDataCustomer = (ownDashboardDataPerScope !== undefined && ownScopeData !== undefined) ? ownScopeData[0] !== undefined ? ownScopeData[0][1] : {} : {};
  const ownData = ownDataCustomer !== null && ownDataCustomer !== undefined && ownParameters !== undefined && ownParameters.customer !== undefined ? ownDataCustomer[ownParameters.customer] !== undefined ? ownDataCustomer[ownParameters.customer] : {} : {};

  return (
    <Grid item xs={12} sm={12}>
      {ownDashboardScopeDataError ?  <Toaster showAlert={{
        type: 'error',
        message: "Some thing went wrong, please reload page"
      }} /> : null}
      {ownData?.viewMore && ownParameters !== undefined && ownParameters.initial !== undefined && ownParameters.initial !== 'null' && ownParameters.initial !== null && ownParameters.initial && ownParameters.initial !== '' ? <Grid className={classes.paperDiv}>
        <div className={classes.chartDiv}>
          <div className={classes.grid} style={{ display: "inline" }}>
            <Table align="center" className={classes.dashBoardTable}>
              <TableHead>
                <TableRow>
                  <Tooltip
                    title={<Typography fontSize={20}>{`Price Elasticity`}</Typography>}>
                    <TableCell
                      colSpan={11}
                      className={classes.headerCellLevel1}
                      style={centerAlignText}
                    >
                      {`Price Elasticity`} {customer ? ` - ${customer}` : ""}
                    </TableCell>
                  </Tooltip>
                </TableRow>
                <TableRow className={classes.headRow}>
                  {columns.map((tableCell) => (
                    <Tooltip
                      title={<Typography fontSize={20}>{tableCell.Header}</Typography>}>
                      <TableCell
                        colSpan={1}
                        className={classes.tableHeadCell}
                        style={centerAlignText}
                      >
                        {tableCell.Header}
                      </TableCell>
                    </Tooltip>
                  ))}
                  {ownData.averagePrice !== undefined ? (
                    <Tooltip
                      title={<Typography fontSize={20}>{`AVG`}</Typography>}>
                      <TableCell
                        colSpan={1}
                        className={classes.tableHeadCell}
                        style={centerAlignText}
                      >
                        {`AVG`}
                      </TableCell>
                    </Tooltip>
                  ) : null}
                  {ownData.promoPrice !== undefined ? (
                    <Tooltip
                      title={<Typography fontSize={20}>{`Promo`}</Typography>}>
                      <TableCell
                        colSpan={1}
                        className={classes.tableHeadCell}
                        style={centerAlignText}
                      >
                        {`Promo`}
                      </TableCell>
                    </Tooltip>
                  ) : null}
                  {ownData.basePrice !== undefined ? (
                    <Tooltip
                      title={<Typography fontSize={20}>{`Base`}</Typography>}>
                      <TableCell
                        colSpan={1}
                        className={classes.tableHeadCell}
                        style={centerAlignText}
                      >
                        {`Base`}
                      </TableCell>
                    </Tooltip>
                  ) : null}
                  {ownData.nweeks !== undefined ? (
                    <Tooltip
                      title={<Typography fontSize={20}>{`Number of weeks`}</Typography>}>
                      <TableCell
                        colSpan={1}
                        className={classes.tableHeadCell}
                        style={centerAlignText}
                      >
                        {`# of weeks`}
                      </TableCell>
                    </Tooltip>
                  ) : null}
                  {ownData.periodBegin !== undefined ? (
                    <Tooltip
                      title={<Typography fontSize={20}>{`Period`}</Typography>}>
                      <TableCell
                        colSpan={1}
                        className={classes.tableHeadCell}
                        style={centerAlignText}
                      >
                        {`Period`}
                      </TableCell>
                    </Tooltip>
                  ) : null}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  {ownData && (
                    <>
                      <TableCell
                        style={centerAlignText}
                        className={classes.yAddButtonCell}
                      > {ownParameters !== undefined ?
                        <div className="kcOvalShape">{ownParameters.initial}</div> : null}
                      </TableCell>
                      <TableCell
                        style={centerAlignText}
                        className={
                          ownData.range === "LOW"
                            ? classes.low : ownData.range === "HIGH"
                              ? classes.high
                              : classes.medium
                        }
                      >
                        {ownData.averageVolume !== undefined ?
                          `${parseFloat(ownData.averageVolume).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}` : null}
                      </TableCell>
                      <TableCell
                        style={centerAlignText}
                        className={
                          ownData.range === "LOW"
                            ? classes.low : ownData.range === "HIGH"
                              ? classes.high
                              : classes.medium
                        }
                      >
                        {ownData.priceIndex !== undefined ?
                          selectedCountry === 'KR' ? `${parseFloat(ownData.priceIndex).toLocaleString("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 })}` : `${parseFloat(ownData.priceIndex).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}` : null}
                      </TableCell>
                      <TableCell
                        style={centerAlignText}
                        className={
                          ownData.range === "LOW"
                            ? classes.low : ownData.range === "HIGH"
                              ? classes.high
                              : classes.medium
                        }
                      >
                        {ownData.range !== undefined ? ownData.range : null}
                      </TableCell>

                      <TableCell
                        style={centerAlignText}
                        className={
                          ownData.range === "LOW"
                            ? classes.low : ownData.range === "HIGH"
                              ? classes.high
                              : classes.medium
                        }
                      >
                        {ownData.rsquared !== undefined ? parseFloat(ownData.rsquared).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 }) : null}
                      </TableCell>

                      {(
                        <TableCell
                          style={centerAlignText}
                          className={
                            ownData.range === "LOW"
                              ? classes.low : ownData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {ownData?.pvalue !== undefined ? parseFloat(ownData.pvalue) : '-'}
                        </TableCell>
                      )}

                      {ownData.averagePrice !== undefined ? (
                        <TableCell
                          style={centerAlignText}
                          className={
                            ownData.range === "LOW"
                              ? classes.low : ownData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {parseFloat(ownData.averagePrice).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}
                        </TableCell>
                      ) : null}
                      {ownData.promoPrice !== undefined ? (
                        <TableCell
                          style={centerAlignText}
                          className={
                            ownData.range === "LOW"
                              ? classes.low : ownData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {parseFloat(ownData.promoPrice).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}
                        </TableCell>
                      ) : null}
                      {ownData.basePrice !== undefined ? (
                        <TableCell
                          style={centerAlignText}
                          className={
                            ownData.range === "LOW"
                              ? classes.low : ownData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {parseFloat(ownData.basePrice).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}
                        </TableCell>
                      ) : null}

                      {ownData.nweeks !== undefined ? (
                        <TableCell
                          style={centerAlignText}
                          className={
                            ownData.range === "LOW"
                              ? classes.low : ownData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {ownData.nweeks}
                        </TableCell>
                      ) : null}

                      {ownData.periodBegin !== undefined ? (
                        <TableCell
                          style={centerAlignText}
                          className={
                            ownData.range === "LOW"
                              ? classes.low : ownData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {convertDateRange(ownData.periodBegin)} - {convertDateRange(ownData.periodEnd)}
                        </TableCell>
                      ) : null}
                    </>
                  )}
                </TableRow>


              </TableBody>
            </Table>
            <div className={classes.btnContainer}>
              <button variant="contained" onClick={() => setOpen(true)} className="border border-primary text-primary rounded p-1 ">Edit Weeks</button>
            </div>
            {open ? <MemoizedWeekSelector
              open={open}
              initial={initial}
              originalDataInstance={originalDataInstance}
              treeDataInstance={treeDataInstance}
              handleClose={handleClose}
              tabName="own"
              setOpen={setOpen}
              fromDashboard={true}
              setSelectedWeeksDashboard={setSelectedWeeksDashboard}
              selectedWeekRange={selectedWeekRange}
              handleEditPopUpData={handleEditPopUpData}
              isPopUpDataChanges={isPopUpDataChanges}
            /> : null}
            {
              ownParameters && Object.keys(ownData).length > 0
                ? <MemoizedOwnReport
                  {...ownParameters}
                  category={category}
                  slopeInt={ownData !== undefined ? ownData.slopeIntegral : 0}
                  slopeFrac={ownData !== undefined ? ownData.slopeDecimal : 0}
                  interceptInt={ownData !== undefined ? ownData.interceptIntegral : 0}
                  interceptFrac={ownData !== undefined ? ownData.interceptDecimal : 0}
                  rSquared={ownData !== undefined ? ownData.rsquared : 0}
                  averagePrice={ownData !== undefined ? ownData.averagePrice : 0}
                  elasticityInt={ownData !== undefined ? ownData.elasticityIntegral : 0}
                  elasticityFrac={ownData !== undefined ? ownData.elasticityDecimal : 0}
                  combinedYearWeeks={combinedYearWeeks}
                />
                : null
            }
          </div>
        </div>
      </Grid>
        : <SingleGrid
          loading={isOwnDashboardDataPerScopeLoading || loadingLineFittingData || isOwnDashboardDataLoading}
          records={[]}
          showTargetProduct={false}
          scope={customer}
          showName={false}
          showOptions={false}
          selectedCountry={selectedCountry}
        />
      }
      <Dialog
        open={canShowProgress}
        keepMounted
        onClose={handleClickClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="pt-0">
          {"Your request is being processed"}
        </DialogTitle>
      </Dialog>
    </Grid>
  );
}
