// Reconstruct url with encoded & symbol
export function updateUrl(selectedBaseLineFilters) {
  let next;
  let updatedUrl = "";
  if (!selectedBaseLineFilters) return "";

  let ean = selectedBaseLineFilters?.trim();
  ean = ean?.split("&eans=");
  next = ean[0];
  ean.shift();

  let subBrand = next?.trim();
  subBrand = subBrand?.split("&subBrands=");
  next = subBrand[0];
  subBrand.shift();

  let brand = next?.trim();
  brand = brand?.split("&brands=");
  next = brand[0];
  brand.shift();

  let subCategory = next?.trim();
  subCategory = subCategory?.split("&subCategories=");
  next = subCategory[0];
  subCategory.shift();

  let category = next?.trim();
  category = category?.split("&categories=");
  next = category[0];
  category.shift();

  let soldTo = next?.trim();
  soldTo = soldTo?.split("&customers=");
  next = soldTo[0];
  soldTo.shift();

  let salesExecutives = next?.trim();
  salesExecutives = salesExecutives?.split("&salesExecutives=");
  next = salesExecutives[0];
  salesExecutives.shift();

  let planVal = next?.trim();
  planVal = planVal?.split("&planLevels=");
  planVal.shift();

  planVal.forEach((val) => {
    let encodedValue = encodeURIComponent(val);
    updatedUrl += `&planLevels=${encodedValue}`;
  });
  soldTo.forEach((val) => {
    let encodedValue = encodeURIComponent(val);
    updatedUrl += `&customers=${encodedValue}`;
  });
  salesExecutives.forEach((val) => {
    let encodedValue = encodeURIComponent(val);
    updatedUrl += `&salesExecutives=${encodedValue}`;
  });

  category.forEach((val) => {
    let encodedValue = encodeURIComponent(val);
    updatedUrl += `&categories=${encodedValue}`;
  });
  subCategory.forEach((val) => {
    let encodedValue = encodeURIComponent(val);
    updatedUrl += `&subCategories=${encodedValue}`;
  });
  brand.forEach((val) => {
    let encodedValue = encodeURIComponent(val);
    updatedUrl += `&brands=${encodedValue}`;
  });
  subBrand.forEach((val) => {
    let encodedValue = encodeURIComponent(val);
    updatedUrl += `&subBrands=${encodedValue}`;
  });
  ean.forEach((val) => {
    const justEan = val.split('-')[0]
    let encodedValue = encodeURIComponent(justEan);
    updatedUrl += `&eans=${encodedValue}`;
  });

  return updatedUrl;
}
// Extract different level values in product selector
export function extractProduct(selectedBaseLineProductNew) {
  let next;
  let ean = selectedBaseLineProductNew.trim();
  ean = ean.split("&eans=");
  next = ean[0];
  ean.shift();

  let subBrand = next.trim();
  subBrand = subBrand.split("&subBrands=");
  next = subBrand[0];
  subBrand.shift();

  let brand = next.trim();
  brand = brand.split("&brands=");
  next = brand[0];
  brand.shift();

  let subCategory = next.trim();
  subCategory = subCategory.split("&subCategories=");
  next = subCategory[0];
  subCategory.shift();

  let category = next.trim();
  category = category.split("&categories=");
  category.shift();

  return { category, subCategory, brand, subBrand, ean };
}
// Extract different level values in customer selector
export function extractCustomer(selectedBaseLineCustomerNew) {
  let next;
  let soldTo = selectedBaseLineCustomerNew?.trim();
  soldTo = soldTo.split("&customers=");
  next = soldTo[0];
  soldTo.shift();

  let planLevel = next?.trim();
  planLevel = planLevel.split("&planLevels=");
  planLevel.shift();

  return { planLevel, soldTo };
}