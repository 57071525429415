export function getUpdatedCustomerHierarchy(values, selectedCustomers) {
  if (values) {
    const objValues = {};

    Object.entries(values).map(([key, value]) => {
      objValues[key] = value.map(data => {
        if(key === 'customers' && selectedCustomers === data.name) {
          data['isChecked'] = true;
        } else {
          data['isChecked'] = false;
        }
        return data;
      })
    })
    return objValues;
  } else {
    return values;
  }
}

export function getUpdatedProductHierarchy(values, selectedPrducts) {
  if (values) {
    const objValues = {};

    Object.entries(values).map(([key, value]) => {
      objValues[key] = value.map(data => {
        if(key === 'eans' && selectedPrducts.includes(data.name)) {
          data['isChecked'] = true;
        } else {
          data['isChecked'] = false;
        }
        return data;
      })
    })
    return objValues;
  } else {
    return values;
  }
}

export const getProductDetailsData = (promoData) => {
  const tableData = promoData.map(data => {
    return {
      "baseGSVListPrice": data.baseGSVListPrice,
      "tradingTerms": data.tradingTerms,
      "baseSellInInvoicePrice": data.baseSellInInvoicePrice,
      "promoSellInInvoicePrice": data.promoSellInInvoicePrice,
      "baseSellOutPrice": data.baseSellOutPrice,
      "promoSellOutPrice": data.promoSellOutPrice,
      "discount": data.discount,
      "discountPercent": data.discountPercent,
      "cogs": data.cogs,
      "distributionExpense": data.distributionExpense,
      "baseVolume": data.baseVolume,
      "promoVolume": data.promoVolume,
      "volumeUplift": data.volumeUplift,
      "additionalFunding": data.additionalFunding,
      "sector": promoData.sector,
      "category": data.category,
      "subCategory": data.subCategory,
      "brand": data.brand,
      "subBrand": data.subBrand,
      "ean": data.ean,
      "eanDescription": data.eanDescription,
      "tax": data.tax
    }
  })
  return tableData;
}