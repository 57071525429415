import React, { useState, useEffect } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

import {getSelectedWeekRange, getDropDownTreeData} from './utils'

import ".././searchStyles.css";
import useStore from "../store";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "auto",
  },
  btnContainer: {
    marginTop: "10px",
    float: "right",
    justifyContent: "space-evenly",
  },
  btnReset: {
    background: "#cb0c0c",
    color: "#fff",
    textTransform: "none",
    margin: "0 20px",
    padding: "6px 20px",
    borderRadius: "10px",
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
  WeekSelectorRoot: {
    width: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  memorizedRoot: {
    height: "500px",
    width: "100%",
  },
}));

export const DropDown = (props) => {
  const classes = useStyles();
  let originalData = props.originalData;
  const { selectedCountry } = props;

  let selectedWeeks = [];

  useEffect(() => {
    if(props.selectedWeekRange){
      let weekRanges = props.selectedWeekRange[0];
      for(let key in weekRanges){
        weekRanges[key].forEach(week => {
          if(originalData !== undefined && originalData[key] !== undefined){
            let originalWeekData = originalData[key].filter(obj => obj.week === week);
            selectedWeeks = [...selectedWeeks,...originalWeekData];
          }
        });
      }
    }
    if(props.selectedWeekRangeApply !== undefined && props.selectedWeekRangeApply.length > 0) {
      selectedWeeks = [...selectedWeeks,...props.selectedWeekRangeApply];
    }
    props.setSelectedWeeks(selectedWeeks);

    updateWeekSelectorStyle();
  }, [])

  // Update week selector style according to the selection
  const updateWeekSelectorStyle = () => {
    setTimeout(() => {
      let selectedRangeCount = document.querySelector(".tag-list").childElementCount; //count child elements 
      selectedRangeCount = selectedRangeCount - 1; // excluding search bar

      const height = (selectedRangeCount > 3) ? "96px" : (selectedRangeCount > 0) ? "67px" : "42px";
      document.querySelector(".react-dropdown-tree-select .dropdown .dropdown-trigger").style.height = height;
    }, 100);
  };

  const handleChange = (item) => {
    let depth = item._depth;
    let val = depth === 2 || depth === 1 ? item._parent : item._id;
    let parIndex = parseInt(val.split("-")[1]);
    let parNode = Object.keys(originalData)[parIndex];
    let allParNodes = Object.keys(originalData);

    if (item.checked) {
      if (depth === 0) {
        //Parent node selected
        selectedWeeks = [...selectedWeeks, ...originalData[parNode]];
      } else if (depth === 1) {
        let month = item.label;
        let aux = originalData[parNode].filter((obj) => obj.month === month);
        selectedWeeks = [...selectedWeeks, ...aux];
      } else if (depth === 2) {
        //Child node selected
        let week = item.week;
        let aux = originalData[parNode].filter((obj) => obj.week === week);
        selectedWeeks = [...selectedWeeks, ...aux];
      }
      // Removing duplicates if any
      let selectedWeekRanges = selectedWeeks;
      selectedWeeks = Array.from(new Set(selectedWeekRanges.map(JSON.stringify))).map(JSON.parse);
      props.setSelectedWeeks(selectedWeeks);
    } else {
      if (depth === 0) {
        //Parent node de selected
        selectedWeeks = selectedWeeks.filter((val) =>
          val.year == parNode ? false : true
        );
      } else if (depth === 1) {
        //Child node de selected
        let month = item.label;
        let aux = originalData[parNode].filter((obj) => obj.month === month);
        selectedWeeks = selectedWeeks.filter((val) =>
          val.year == parNode && val.month == month ? false : true
        );
      } else if (depth === 2) {
        // Sub Child node de selected
        let week = item.week;
        let aux = originalData[parNode].filter((obj) => obj.week === week);
        selectedWeeks = selectedWeeks.filter((val) =>
          val.year == parNode && val.week == week ? false : true
        );
      }
      // Removing duplicates if any
      let selectedWeekRanges = selectedWeeks;
      selectedWeeks = Array.from(new Set(selectedWeekRanges.map(JSON.stringify))).map(JSON.parse);
      props.setSelectedWeeks(selectedWeeks);
    }
    updateWeekSelectorStyle();
  };

  return (
    <div>
      <DropdownTreeSelect
        disableUnderline
        texts={{ placeholder: "Search" }}
        className={classes.root}
        data={props.treeData}
        onChange={handleChange}
        showDropdown="initial"
        showPartiallySelected={true}
      />
    </div>
  );
};
export const MemoizedDropDown = React.memo(DropDown);

export const WeekSelector = (props) => {
  const classes = useStyles();
  const {open, handleClose, setOpen, treeDataInstance} = props;

  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [treeNodesData, setTreeNodesData] = useState([]);

  const setReset = useStore((state) => state.setReset);
  let reset = useStore((state) => state.reset);
  let selectedCountry = useStore((state) => state.selectedCountry);
  const setRegressionApiBodyOwn = useStore((state) => state.setRegressionApiBodyOwn);
  const setRegressionApiBodyCross = useStore((state) => state.setRegressionApiBodyCross);
  const setSelectedWeeksApply = useStore((state) => state.setSelectedWeeksApply);
  const selectedWeeksApply = useStore.getState().selectedWeeksApply;
  
  useEffect(() => {
    const weekRangeSelected = getSelectedWeekRange(selectedWeeksApply);
    const treeNodesData = getDropDownTreeData(selectedCountry, props.originalDataInstance, weekRangeSelected);
    const treeNodes = getDropDownTreeData(selectedCountry, props.originalDataInstance, {});
    if(selectedWeeksApply.length > 0){
      setTreeNodesData(treeNodesData);
    } else if(!props.isPopUpDataChanges){
      setTreeNodesData(treeDataInstance);
    } else {
      setTreeNodesData(treeNodes)
    }
  }, [])

  useEffect(() => {
    const weekRangeSelected = getSelectedWeekRange(selectedWeeksApply);
    const treeNodesData = getDropDownTreeData(selectedCountry, props.originalDataInstance, weekRangeSelected);
    const treeNodes = getDropDownTreeData(selectedCountry, props.originalDataInstance, {});
    if(selectedWeeksApply.length > 0){
      setTreeNodesData(treeNodesData);
    } else if(!props.isPopUpDataChanges){
      setTreeNodesData(treeDataInstance);
    } else {
      setTreeNodesData(treeNodes)
    }
  }, [treeDataInstance]);

  const handleSelection = () => {
    setSelectedWeeksApply(selectedWeeks);
    const actualSelectedWeeks = selectedWeeks.length === 0 ? props.originalDataInstance : selectedWeeks;
    props.tabName === "own" ? setRegressionApiBodyOwn(selectedWeeks) : setRegressionApiBodyCross(selectedWeeks);
    if ( props.setUserSelectedWeekRangesOwn || props.setUserSelectedWeekRangesCross) {
      props.tabName === "own" ? props.setUserSelectedWeekRangesOwn(selectedWeeks) : props.setUserSelectedWeekRangesCross(selectedWeeks);
    }
    if (props.fromDashboard) {
      props.setSelectedWeeksDashboard(selectedWeeks);
    }
    setOpen(false);
    if (props.setIsEditWeeksClicked) {
      props.setIsEditWeeksClicked(true);
    }
    if (props.handleEditPopUpData) {
      props.handleEditPopUpData(true);
    }
  };

  const handleClear = () => {
    const treeNodes = getDropDownTreeData(selectedCountry, props.originalDataInstance, {});
    setReset(!reset);
    setSelectedWeeks([]);
    setTreeNodesData(treeNodes)
    document.querySelector(".react-dropdown-tree-select .dropdown .dropdown-trigger").style.height = "42px";
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose(handleClear)}
      aria-describedby="alert-dialog-slide-description"
      disableBackdropClick
    >
      <DialogTitle>
        <h2 className="w-full flex justify-between">
          <span>Select week range</span>
          <CloseIcon onClick={() => handleClose(handleClear)} />
        </h2>
      </DialogTitle>
      <DialogContent style={{ height: "600px", alignItems: "center" }}>
        <DialogContentText id="alert-dialog-slide-description">
          <div className={classes.WeekSelectorRoot}>
            <div className={classes.memorizedRoot}>
              <MemoizedDropDown
                treeData={treeNodesData}
                reset={reset}
                originalData={props.originalDataInstance}
                selectedCountry={selectedCountry}
                setIsEditWeeksClicked={props.setIsEditWeeksClicked}
                setSelectedWeeks={setSelectedWeeks}
                selectedWeekRange={props.selectedWeekRange}
                selectedWeekRangeApply={selectedWeeksApply}
                isPopUpDataChanges={props.isPopUpDataChanges}
              />
            </div>
            <DialogActions className={classes.btnContainer}>
              <Button onClick={handleClear} className={classes.btnReset}>
                Clear
              </Button>
              <Button onClick={handleSelection} className={classes.btnSucess}>
                Apply
              </Button>
            </DialogActions>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export const MemoizedWeekSelector = React.memo(WeekSelector);
