import React from "react";
import useStore from "../store";
import {PreROIDashboard} from "./PreROIDashboard";
import PreRoiSimulation from "./PreRoiSimulation/PreRoiSimulation";

const PreRoiLayout = () => {
  
  let selectedCountry = useStore((state) => state.selectedCountry);

  if(selectedCountry === "KR"){
    return <PreRoiSimulation />
  }
  return (
    <>
       <PreROIDashboard />

    </>
  );
};

export default PreRoiLayout;
