import { ChevronRight } from "../icons/chevron_right";
import React, { useEffect, useState, useRef } from "react";
import {
    useGetBaseLineValue, useGetSimulatedValues, useGetSimulatedPAndLValues,
    useGetCalculatedIncVolume, usePostSimulatedData, useGetPromoInvMagicValues,
} from "../../services/mutations";
import { usePrePOIPromoTypes } from "../../services/queries";
import useStore from "../../store";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { PromoType } from './promo_type';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { extractCustomer, extractProduct, updateUrl } from "../../UpdateUrl";
import { PromoActions } from "./promo_actions";

import { IconButton } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import Toaster from '../../commonMuiComponents/Toaster'
import { currencySymbol } from '../../helper';
import { BaseLineValuePost, SimulatedROIAndNPLPost, SimulatedPAndLPost } from "../../models/common/post";

type SelectedProps = {
    key: string,
    item: string
}

export type CalculatedResult = {
    netProfit: number,
    totalInvestment: number
}

type PromoProps = {
    index: number,
    showResult: (x: number) => void,
    setSelectedPromoRadioVal: (x: number) => void,
    selectedPromoValParent: number | null,
    setSimulatedGridData: (x: string) => void,
    savePromoValues: boolean,
    setSavePromoValues: (x: boolean) => void,
    isLoadSimulationClicked: any
    calculateData: Function,
    resetForm: Function,
    savePromo: Function,
    handleModalOpen: Function,
    duplicateFun: Function,
    deletePromo: (x: number) => void,
    updateCalculatedResult: (idx: number, result: CalculatedResult) => void,
    setSelectedPromosHandler: (idx: number, checked: boolean) => void
}

function pad(n: number) {
    return (n < 10) ? ("0" + n) : n;
}

const useStyles = makeStyles(theme => ({
    wrapperInput: {
        overflow: 'hidden',
        minWidth: '160px'
    },
    selectedElement: {
        border: '1px solid blue'
    },
    propContainer: {
        minWidth: '200px',
        marginRight: '20px'
    },
    inputProps: {
        marginTop: '5px',
        marginRight: '2px',
        marginLeft: '5px'
    },
    btnContainer: {
        marginTop: "30px",
        float: "right",
        marginRight: "50px",
    },
    btnReset: {
        background: "#cb0c0c",
        color: "#fff",
        textTransform: "none",
        margin: "0 20px",
        padding: "6px 20px",
        borderRadius: "10px",
    },
    btnSucess: {
        background: "#163c62",
        color: "#fff",
        textTransform: "none",
        margin: "0 10px",
        padding: "6px 35px",
        borderRadius: "10px",
    },

}))

export const PromoSection = ({ index, showResult, setSelectedPromoRadioVal, selectedPromoValParent, setSimulatedGridData, savePromoValues, setSavePromoValues,
    isLoadSimulationClicked, calculateData, duplicateFun, handleModalOpen, resetForm, savePromo, deletePromo, updateCalculatedResult, setSelectedPromosHandler }: PromoProps) => {

    const classes = useStyles();
    let numberOfPromos: any = useStore((state) => state.numberOfPromos)

    let selectedContry = useStore((state) => state.selectedCountry);
    let selectedBaseLineProductNew = useStore(
        (state) => state.selectedBaseLineProductNew
    );
    let selectedProductValues = useStore(
        (state) => state.selectedProductValues
    );

    let selectedBaseLineCustomerNew = useStore(
        (state) => state.selectedBaseLineCustomerNew
    );
    let selectedCustomerValues = useStore(
        (state) => state.selectedCustomerValues
    );

    let calcutateFormNew = useStore((state) => state.calcutateFormNew);
    let setCalcutateFormNew = useStore(
        (state) => state.setCalcutateFormNew
    );

    let calcutateTotalInvest = useStore((state) => state.calcutateTotalInvest);
    let setCalcutateTotalInvest = useStore(
        (state) => state.setCalcutateTotalInvest
    );

    let resetFormFieldsNew = useStore((state) => state.resetFormFieldsNew);
    let setresetFormFieldsNew = useStore(
        (state) => state.setresetFormFieldsNew
    );

    let setSelectedRaidoPromo = useStore(
        (state) => state.setSelectedRaidoPromo
    );

    let promoStateData: any = useStore((state) => state.promoStateData);
    const setPromoStateData = useStore((state) => state.setPromoStateData);

    let promoValNew = useStore((state) => state.promoValNew);
    let setPromoValNew = useStore(
        (state) => state.setPromoValNew
    );

    const { isLoading: isPreROIPromoData, data: preROIPromoDataNew } = usePrePOIPromoTypes(selectedContry);

    const [showOpenDialog, setshowOpenDialog] = useState(false)
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [duration, setDuration] = useState(0);
    const [promoType, setToPromoType] = useState('');
    const [promoSpeed, setPromoSpeed] = useState('');
    const [incrementalVolume, setIncrementalVolume] = useState(0);
    const [totalVolume, setTotalVolume] = useState(0);
    // TODO replace it with the type SimulatedValue
    const [totalInvestment, setTotalInvestment] = useState(0);
    const [npp, setNpp] = useState(0);
    const [cogs, setCogs] = useState(0);
    const [netProfit, setNetProfit] = useState(0);
    const [targetRoi, setTargetRoi] = useState<number | 0>(0);
    const [promotedPrice, setPromotedPrice] = useState<number | null>();
    const [roi, setRoi] = useState(0);
    const [height, setHeight] = useState({});
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [header, setHeader] = useState('');
    const [isPromoSaved, setIsPromoSaved] = useState(false);
    //const [promoVal, setPromoVal] = useState();

    //for customer and product array to save data
    const [planLevel, setPlanLevel] = useState([]);
    const [customerValues, setCustomerValues] = useState([]);
    const [customerPlanValues, setCustomerPlanValues] = useState([]);

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [subBrands, setSubBrands] = useState([]);
    const [eans, setEans] = useState([]);

    const [baseLineVal, setBaseLineVal] = useState(0);
    const [isCalCulate, setIsCalCulate] = useState(false);
    const [promotedSelVal, setPromotedSelVal] = useState('promotedPrice');

    const [selectedPromoVal, setSelectedPromoVal] = useState<any>({});
    const { mutate: baseLineMutate, data: baseLineReponse, isSuccess: getBase, isError: successError } =
        useGetBaseLineValue();
    const { mutate: simulatedValues, data: simulatedValuesResponse, isSuccess: isSucessSimulate, isError: sucessSimulateError } =
        useGetSimulatedValues();
    const listRef = useRef<any>(null);

    const [UsePromotedPrice, setUsePromotedPrice] = useState(false)

    const [DeletingScenario, setDeletingScenario] = useState(false)

    const [showAlert, setShowAlert] = useState({ message: '', type: '' })

    // TODO replace it with the type SimulatedValue
    const [historicalVolume, sethistoricalVolume] = useState(0)
    const [taPercentage, settaPercentage] = useState(0)
    const [tpPercentagte, settpPercentagte] = useState(0)


    const { mutate: simulatedPLValues, data: simulatedPLValuesResponse, isSuccess: isSucessSimulatePL, isError: sucessSimulatePLError } =
        useGetSimulatedPAndLValues();

    const { mutate: incrementalValue, data: incrementalValueResponse, isSuccess: isSucessIncrementalValue, isError: incrementalValueError } =
        useGetCalculatedIncVolume();

    const { mutate: savePromoMutate, data: savePromoMutatesResponse, isSuccess: isSucessSavePromoMutate, isError: sucessSavePromoMutateError } =
        usePostSimulatedData();


    useEffect(() => {
        if (selectedBaseLineCustomerNew) {
            let { planLevel, soldTo } = extractCustomer(decodeURIComponent(selectedBaseLineCustomerNew));
            //soldTo = decodeURIComponent(soldTo)
            setCustomerValues(soldTo);
            setCustomerPlanValues(planLevel);
            setPlanLevel(planLevel);
        }
    }, [selectedBaseLineCustomerNew])

    useEffect(() => {
        if (selectedBaseLineProductNew) {
            let { category, subCategory, brand, subBrand, ean } = extractProduct(decodeURIComponent(selectedBaseLineProductNew));

            setCategories(category);
            setSubCategories(subCategory);
            setBrands(brand);
            setSubBrands(subBrand);
            setEans(ean);

        }
    }, [selectedBaseLineProductNew])

    useEffect(() => {
        if (savePromoValues && !isCalCulate) {
            setSavePromoValues(false);
            setShowAlert({
                type: 'error',
                message: "Run calculate first and then save the scenario"
            })
            return
        }
        if (savePromoValues && isCalCulate) {
            if (index === promoValNew) {

                let promoInvtAbsVal_local: string[] = [];
                let promoInvtNonAbsVal_local: string[] = [];

                if (Object.keys(selectedPromoVal).length > 0) {
                    Object.keys(selectedPromoVal).map((item: any) => (
                        preROIPromoDataNew && preROIPromoDataNew[item]
                            ? promoInvtAbsVal_local.push(`promoInvestmentAbsValues=${item.replace('&', '%26').replace('%', '%25')}-${selectedPromoVal[item]}`)
                            : promoInvtNonAbsVal_local.push(`promoInvestmentNonAbsValues=${item.replace('&', '%26').replace('%', '%25')}-${selectedPromoVal[item]}`)
                    ))
                }

                let request = {
                    "baseline": baseLineVal,
                    "cogs": cogs,
                    "country": selectedContry,
                    "fromDate": fromDate,
                    "incrementalVolume": incrementalVolume,
                    "name": header,
                    "netProfit": netProfit,
                    "npp": npp,
                    "roiPercent": roi,
                    "targetRoi": targetRoi,
                    "toDate": toDate,
                    "totalInvestment": totalInvestment,
                    "totalVolume": totalVolume,
                    "categories": categories,
                    "subCategories": subCategories,
                    "brands": brands,
                    "subBrands": subBrands,
                    "eans": eans,
                    "planLevels": planLevel,
                    "customers": customerValues,
                    "promoInvestmentAbsValues": promoInvtAbsVal_local,
                    "promoInvestmentNonAbsValues": promoInvtNonAbsVal_local,
                    "promotedPrice": 0,
                    "historicalVolume": historicalVolume,
                    "taPercentage": taPercentage,
                    "tpPercentagte": tpPercentagte
                }
                if (selectedContry === 'UK' && promotedPrice) {
                    request.promotedPrice = promotedPrice;
                }
                savePromoMutate(request)
            }
        } else if (savePromoValues && isCalCulate && index == promoValNew) {
            setShowAlert({
                type: 'error',
                message: "First calculate the Promo Values"
            })
            setSavePromoValues(false);
        }
    }, [savePromoValues]);

    useEffect(() => {
        if (isSucessSavePromoMutate) {

            setSavePromoValues(false);
            setIsPromoSaved(true);
            setShowAlert({
                type: 'success',
                message: "Data saved succesfully"
            })
        }
        if (sucessSavePromoMutateError) {

            setSavePromoValues(false);
            setShowAlert({
                type: 'error',
                message: "Faced some issue"
            })
        }
    }, [isSucessSavePromoMutate, sucessSavePromoMutateError]);

    const [allProfitLoss, setallProfitLoss] = useState(0);
    const selectedProductsHierarchy = useStore((state) => state.selectedProductsHierarchy);
    const selectedCustomersHierarchy = useStore((state) => state.selectedCustomersHierarchy);
    const setSelectedCustomersHierarchy = useStore(state => state.setSelectedCustomersHierarchy);
    const setSelectedProductsHierarchy = useStore(state => state.setSelectedProductsHierarchy);
    let preRoiCommonPayload = {
        country: selectedContry,
        planLevels: selectedCustomersHierarchy?.planLevel,
        customers: selectedCustomersHierarchy?.soldTo,
        categories: selectedProductsHierarchy?.categories,
        subCategories: selectedProductsHierarchy?.subCategories,
        brands: selectedProductsHierarchy?.brands,
        subBrands: selectedProductsHierarchy?.subBrands,
        eans: selectedProductsHierarchy?.products,
        fromDate: fromDate,
        toDate: toDate
    };

    useEffect(() => {
        if (fromDate.length !== 0 && toDate.length !== 0 && promoValNew === index) {
            let getBaseLineValuePayload = {
                ...preRoiCommonPayload
            }
            baseLineMutate(getBaseLineValuePayload);
        }
    }, [selectedContry, fromDate, toDate,
        selectedCustomersHierarchy?.planLevel,
        selectedCustomersHierarchy?.soldTo,
        selectedProductsHierarchy?.categories,
        selectedProductsHierarchy?.subCategories,
        selectedProductsHierarchy?.brands,
        selectedProductsHierarchy?.subBrands,
        selectedProductsHierarchy?.products]);

    useEffect(() => {
        selectedBaseLineCustomerNew.length === 0 && setSelectedCustomersHierarchy({});
        selectedBaseLineProductNew.length === 0 && setSelectedProductsHierarchy({});
    }, []);

    let promoInvtAbsVal: string[] = [];
    let promoInvtNonAbsVal: string[] = [];
    Object.entries(selectedPromoVal).map(([key, value]) => (
        preROIPromoDataNew && preROIPromoDataNew[key]
            ? promoInvtAbsVal.push(`${key}-${value}`)
            : promoInvtNonAbsVal.push(`${key}-${value}`)
    ));

    const getSimulatedValue = () => {
        if (fromDate && toDate
            && ((!UsePromotedPrice && Object.keys(selectedPromoVal).length > 0) || (UsePromotedPrice && promotedPrice && promotedPrice > 0))
            && !isPreROIPromoData) {
            let getSimulatedPLValuesPayload: SimulatedROIAndNPLPost = {
                ...preRoiCommonPayload,
                baseLineVolume: baseLineVal,
                incrementalVolume: incrementalVolume ? incrementalVolume : 0,
                promoInvestmentAbsValues: promoInvtAbsVal,
                promoInvestmentNonAbsValues: promoInvtNonAbsVal,
                promotedPrice: promotedPrice ? promotedPrice : 0
            }
            simulatedValues(getSimulatedPLValuesPayload);
        }
    };

    const getSimulatedPLValues = () => {
        if (fromDate && toDate
            && ((!UsePromotedPrice && Object.keys(selectedPromoVal).length > 0) || (UsePromotedPrice && promotedPrice && promotedPrice > 0))
            && incrementalVolume != 0) {
            showResult(index);
            let getSimulatedPLValuesPayload: SimulatedPAndLPost = {
                ...preRoiCommonPayload,
                baseLineVolume: baseLineVal,
                incrementalVolume: incrementalVolume ? incrementalVolume : 0,
                promoInvestmentAbsValues: promoInvtAbsVal,
                promoInvestmentNonAbsValues: promoInvtNonAbsVal,
                promotedPrice: promotedPrice ? promotedPrice : 0,
                netProfitOrLoss: netProfit ? netProfit : 0,
                roiPercentage: roi ? roi : 0,
                totalPromoInvestment: totalInvestment,
                npp,
                cogs,
            }
            simulatedPLValues(getSimulatedPLValuesPayload);
        } else {
            setShowAlert({
                type: 'error',
                message: "Please select values for form."
            })
        }
    }

    const [incrementalMagic, setIncrementalMagic] = useState(false);

    const getIncrementalVolume = () => {
        incrementalValue(
            `${selectedContry}&promoInvestment=${totalInvestment}&targetRoiPercentage=${targetRoi}&baseLineVolume=${baseLineVal}&npp=${npp}&cogs=${cogs}`
        );
        setIncrementalMagic(true);
    }

    useEffect(() => {
        if (index === promoValNew) {
            let temVar = promoStateData[index]
            setPromoStateData({ ...promoStateData, [index]: { ...temVar, selectedCustomerValues: selectedCustomerValues } })
        }

    }, [selectedCustomerValues]);

    useEffect(() => {
        if (index === promoValNew) {
            let temVar = promoStateData[index]
            setPromoStateData({ ...promoStateData, [index]: { ...temVar, selectedProductValues: selectedProductValues } })
        }

    }, [selectedProductValues]);

    useEffect(() => {
        const storeTem = promoStateData[selectedPromoValParent ?? index];
        if (storeTem && Object.keys(storeTem).length > 0) {
            setFromDate(storeTem.fromDate);
            setToDate(storeTem.toDate);
            setBaseLineVal(storeTem.baseLineVal);
            //calDuration(storeTem.fromDate, storeTem.toDate)
            setSelectedPromoVal(storeTem.selectedPromoVal || {})
            setDuration(storeTem.duration)
            setNetProfit(storeTem.netProfit);
            setRoi(storeTem.roi);
            setTotalInvestment(storeTem.totalInvestment);
            setIncrementalVolume(storeTem.incrementalVolume)
            setNpp(storeTem.npp);
            setCogs(storeTem.cogs);
            setIsCalCulate(storeTem.isCalCulate);
            setIsPromoSaved(storeTem.isPromoSaved)
            setTargetRoi(storeTem.targetRoi)
            setPromotedPrice(storeTem.promotedPrice)
            sethistoricalVolume(storeTem.historicalVolume)
            settaPercentage(storeTem.taPercentage)
            settpPercentagte(storeTem.tpPercentagte)
        }
    }, [])

    const calDuration = (startname: any, endDate: any) => {
        let stDate = moment(startname, "YYYY/MM/D");
        let edDate = moment(endDate, "YYYY/MM/D");
        let durationVal = edDate.diff(stDate, 'days');

        if (durationVal >= 0) {
            setDuration(durationVal + 1)
        } else if (durationVal < 0) {
            setShowAlert({
                type: 'error',
                message: "To date is greater than From date"
            })
            setDuration(0)
        }
    }

    useEffect(() => {
        const newState = {
            ...promoStateData,
            [index]: {
                ...promoStateData[index],
                fromDate,
                toDate,
                baseLineVal,
                selectedPromoVal,
                duration,
                netProfit,
                roi,
                totalInvestment,
                incrementalVolume,
                npp,
                cogs,
                targetRoi,
                promotedPrice,
                historicalVolume,
                taPercentage,
                tpPercentagte,
                totalVolume,
            }
        }
        setPromoStateData(newState)
    }, [fromDate, toDate, baseLineVal, selectedPromoVal, duration, netProfit, roi, totalInvestment, incrementalVolume, npp, cogs, targetRoi, promotedPrice, historicalVolume, taPercentage, tpPercentagte, totalVolume])

    const inputValueChange = (e: any, name: string) => {
        switch (name) {
            case 'fromDate':
                setFromDate(e.target.value);
                calDuration(e.target.value, toDate);

                break;
            case 'toDate':
                setToDate(e.target.value);
                calDuration(fromDate, e.target.value);
                break;
            case 'duration':
                var s11 = new Date(fromDate).getTime() - new Date(toDate).getTime();
                var days = Math.floor(s11 / (1000 * 60 * 60 * 24));
                setDuration(e.target.value)
                break;
            case 'promoType':
                setToPromoType(e.target.value)
                break;
            case 'promoSpeed':
                setPromoSpeed(e.target.value)
                break;
            case 'incrementalVolume':
                if (!e.target.value) {
                    setIncrementalVolume(0);
                    calTotalVolume(baseLineVal, 0);
                } else {
                    setIncrementalVolume(parseInt(e.target.value));
                    calTotalVolume(baseLineVal, parseInt(e.target.value));
                }

                break;
            case 'totalVolume':
                setTotalVolume(e.target.value)
                break;
            case 'totalInvestment':
                setTotalInvestment(e.target.value)
                break;
            case 'netProfit':
                setNetProfit(e.target.value)
                break;
            case 'targetRoi':
                setTargetRoi(e.target.value)
                break;
            case 'roi':
                setRoi(e.target.value);
                break;
            // setSelectedPromoRadioVal(e.target.value)
            // setSelectedRaidoPromo(e.target.value)
            // setPromoValNew(parseInt(e.target.value));
            // if (index) {
            //     const storeTem = promoStateData[index];
            //     if (storeTem.selectedProductValues.length > 0) {
            //         setSelectedProductValues(storeTem.selectedProductValues)
            //     }

            //     if (storeTem.selectedCustomerValues.length > 0) {
            //         setSelectedCustomerValues(storeTem.selectedCustomerValues);
            //         setSelectedBaseLineCustomerState(storeTem.selectedBaseLineCustomerState)
            //     }
            // }
            case 'promoValClick':
                setSelectedPromoRadioVal(e.target.value)
                setSelectedRaidoPromo(e.target.value)
                setPromoValNew(parseInt(e.target.value));
                break;
            case 'promotedPrice':
                setPromotedPrice(e.target.value)
                break;
            case 'promotedSelVal':
                setPromotedSelVal(e.target.value)
        }

    }

    const calTotalVolume = (base: number, incrementalVolume: number) => {
        let val = baseLineVal + incrementalVolume;
        setTotalVolume(val)
    }

    useEffect(() => {
        if (resetFormFieldsNew && promoValNew === index) {
            setToPromoType('default');
            setPromoSpeed('');
            setSelectedPromoVal({});
            setIncrementalVolume(0);

            setTotalInvestment(0);
            setNetProfit(0);
            setTargetRoi(0);
            setTotalVolume(0);

            setRoi(0);//roi
            setTotalInvestment(0);
            sethistoricalVolume(0)
            settaPercentage(0)
            settpPercentagte(0)
        }
        setresetFormFieldsNew(false)
    }, [resetFormFieldsNew]);

    useEffect(() => {
        if (index === promoValNew && calcutateFormNew) {
            if (calcutateFormNew && fromDate && toDate && incrementalVolume && ((!UsePromotedPrice && selectedPromoVal && Object.keys(selectedPromoVal).length > 0) || (UsePromotedPrice && promotedPrice && promotedPrice > 0))) {
                getSimulatedValue();
                // setCalcutateFormNew(false);
                setIsCalCulate(true);
            } else if (calcutateFormNew || (fromDate === '' || toDate === '' || incrementalVolume === 0 || (selectedPromoVal && Object.entries(selectedPromoVal).length) <= 0
            )) {
                setShowAlert({
                    type: 'error',
                    message: "All values are required"
                })
                setCalcutateFormNew(false)
            }

        }
    }, [calcutateFormNew]);

    useEffect(() => {
        if (getBase) {
            let val = JSON.stringify(baseLineReponse?.data?.response)
            let ary = val?.split(":");
            let numval = ary[1].substring(0, ary[1].length - 1);
            let strNum = parseFloat(numval);
            let printVal = Math.round(strNum);

            setBaseLineVal(printVal,)
            calTotalVolume(printVal, incrementalVolume);
        }
    }, [getBase]);

    useEffect(() => {
        calTotalVolume(baseLineVal, incrementalVolume);
    }, [baseLineVal]);

    useEffect(() => {
        if (isSucessSimulate) {
            let val = simulatedValuesResponse?.data;
            setTotalInvestment(Math.round(val?.totalPromoInvestment));
            setCalcutateFormNew(false);
            if (calcutateTotalInvest) {
                // TODO replace individual setstate with type SimulatedValue
                console.log("calculate bttn")
                setNetProfit(Math.round(val?.netProfitOrLoss))
                setRoi(val?.roiPercentage)
                setNpp(val?.npp)
                setCogs(val?.cogs)
                sethistoricalVolume(val?.historicalVolume)
                settaPercentage(val?.taPct)
                settpPercentagte(val?.tpPct)
                updateCalculatedResult(index, {
                    netProfit: Math.round(val?.netProfitOrLoss),
                    totalInvestment: Math.round(val?.totalPromoInvestment)
                })
                setCalcutateTotalInvest(false)
            }
        }
    }, [isSucessSimulate]);

    useEffect(() => {
        if (isSucessSimulatePL) {
            let val = simulatedPLValuesResponse?.data;
            setSimulatedGridData(val);
        }
    }, [isSucessSimulatePL]);



    useEffect(() => {
        if (isSucessIncrementalValue) {
            // alert("New api called for Incremental volume")
            let val = incrementalValueResponse?.data;
            if (!isNaN(val) && val !== "NaN") {
                setIncrementalVolume(val)
            }
        }
    }, [isSucessIncrementalValue]);

    useEffect(() => {
        setPromoValNew(index);
    }, [index]);

    useEffect(() => {
        getHeader("promo", pad(index))
        //setSelectedPromoVal({})
    }, [])

    const handleEditClick = () => {
        console.log("setPromoValNew(index);", index)
        if (!isPromoSaved) {
            setIsEdit(true);
        }
    }

    const handleEditChange = (evt: any) => {
        setHeader(evt.target.value);
    }
    const handleBlur = () => {
        setIsEdit(false);
    }
    const getHeader = (name: string, index: any) => {
        if (promoStateData[index] && promoStateData[index].name) {
            setHeader(promoStateData[index].name)
        } else {
            setHeader(name + index)
        }
    }

    const [magicSelectedVal, setMagicSelectedVal] = useState<string>();

    const { mutate: promoMagicValues, data: promoMagicValuesResponse, isSuccess: isSucessPromoMagic, isError: promoMagicError } =
        useGetPromoInvMagicValues();

    const getPromoMagicVal = (isAbs: any, selectedItem: string) => {

        promoMagicValues(
            `${selectedContry}&baseLineVolume=${baseLineVal}&incrementalVolume=${incrementalVolume ? incrementalVolume : 0}&targetRoiPercentage=${targetRoi}&totalPromoInvestment=${totalInvestment}&npp=${npp ? npp : 0}&cogs=${cogs ? cogs : 0}&isAbs=${isAbs}`
        );
        setMagicSelectedVal(selectedItem);

    }

    useEffect(() => {
        if (isSucessPromoMagic && magicSelectedVal) {
            let value = promoMagicValuesResponse?.data.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
            let newKey = magicSelectedVal;
            let obj: { [key: string]: any; } = {};
            obj[newKey] = value;
            setSelectedPromoVal({ ...selectedPromoVal, ...obj });

        }

    }, [isSucessPromoMagic]);

    const handleCloseDelete = () => {
        setshowOpenDialog(false);
    };

    const handleROIchange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        setSelectedPromosHandler(idx, e.currentTarget.checked)
    }

    const handleDuplicate = (e: React.MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
        duplicateFun()
    }

    return (
        <>
            <div className={`flex flex-col border shadow-sm   ${promoValNew === index ? 'shadow-md shadow-blue-600 border-blue-600' : ''}`}
            >
                {/* Header section */}
                <div className={`flex justify-between items-center py-2 px-5 font-bold cursor-pointer`} onClick={e => inputValueChange({ target: { value: index } }, 'promoValClick')}>
                    <span className="flex items-center font-bold gap-x-1 w-52">
                        {isEdit ?
                            <input type="text" ref={(input) => { input && input.focus() }} value={header} onChange={evt => handleEditChange(evt)} onBlur={handleBlur} />
                            :
                            <>
                                <input type='checkbox' title="Click to include this in the overall ROI calculation" checked={index === promoValNew} onChange={e => handleROIchange(e, index)} />
                                <span className="cursor-pointer" onClick={evt => handleEditClick()}> {header}</span>
                                <CancelIcon style={{ color: 'blue', display: 'inline', cursor: 'pointer' }}
                                    onClick={() => { setshowOpenDialog(true); setDeletingScenario(true); }} />
                            </>
                        }
                    </span>
                    {
                        promoValNew === index
                            ? <PromoActions tidy={true} calculateData={calculateData} setCalcutateTotalInvest={setCalcutateTotalInvest} resetForm={() => {
                                if (numberOfPromos === 1) {
                                    setallProfitLoss(0)
                                }
                                resetForm()
                            }} savePromo={savePromo} handleModalOpen={handleModalOpen} duplicateFun={handleDuplicate} />
                            : null
                    }

                    <div className="flex w-40 ml-10">
                        <div className="flex rounded-full bg-blue-600 p-2 text-white cursor-pointer" onClick={() => { getSimulatedPLValues(); }}>
                            <span className="text-xs font-bold self-center">Simulated P&L</span>
                            <ChevronRight color="white"></ChevronRight>
                        </div>
                    </div>

                </div>

                {/* Duration section */}
                <div id="container" ref={listRef} className="flex justify-start gap-5 items-center py-2 px-5 border-b bg-gray-200" >
                    <div className="flex flex-col gap-2 w-40">
                        <span className="text-sm">From<span className="text-red-900 font-bold text-base">*</span></span>
                        <div className="flex bg-white rounded h-10 items-center px-2">
                            <input type="date" name="fromDate" value={fromDate}
                                onChange={e => inputValueChange(e, 'fromDate')} id="" placeholder="DD/MM/AAAA"
                                className="placeholder:text-xs w-full calendar placeholder:text-gray-300"
                                min={moment().startOf('month').format('YYYY-MM-DD')}
                                max={moment().endOf('year').format('YYYY-MM-DD')}
                            />
                            {/* <Calendar color="#2663FF"></Calendar> */}
                        </div>
                    </div>

                    <div className="flex flex-col gap-2  w-52">
                        <span className="text-sm">To<span className="text-red-900 font-bold text-base">*</span></span>
                        <div className="flex bg-white rounded h-10 items-center px-2">
                            <input type="date" name="toDate" value={toDate}
                                onChange={e => inputValueChange(e, 'toDate')} id="" placeholder="DD/MM/AAAA"
                                className="placeholder:text-xs w-full "
                                min={moment().startOf('month').format('YYYY-MM-DD')}
                                max={moment().endOf('year').format('YYYY-MM-DD')}
                            />
                            {/* <Calendar color="#2663FF"></Calendar> */}
                        </div>
                    </div>


                    <div className="flex flex-col gap-2 w-40">
                        <span className="text-sm">Duration</span>
                        <div className="flex  rounded h-10 items-center px-2">
                            <span className="font-bold">{duration} days</span>
                        </div>

                    </div>

                    <div className="flex flex-col gap-2 w-40">
                        <Tooltip title="In Packs"><span className="text-sm">Baseline</span></Tooltip>
                        <div className="flex  rounded h-10 items-center px-2">
                            <span className="font-bold">
                                {baseLineVal ? baseLineVal.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}
                            </span>
                        </div>
                    </div>

                    {
                        selectedContry === 'PE'
                            ? <>
                                <div className="flex flex-col gap-2 w-40">
                                    <span className="text-sm">TA %</span>
                                    <div className="flex  rounded h-10 items-center px-2">
                                        <span className="font-bold">
                                            {taPercentage ? taPercentage.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2 w-40">
                                    <span className="text-sm">TP %</span>
                                    <div className="flex  rounded h-10 items-center px-2">
                                        <span className="font-bold">
                                            {tpPercentagte ? tpPercentagte.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}
                                        </span>
                                    </div>
                                </div>
                            </>
                            : null
                    }



                    <div className="flex flex-col gap-2 w-80">
                        <span className="text-sm">Promo Type<span className="text-red-900 font-bold text-base">*</span></span>
                        <div className="rounded h-10 items-center" style={{maxWidth: 180}}>
                            <PromoType
                                preROIPromoDataNew={!isPreROIPromoData ? preROIPromoDataNew : {}}
                                selectedPromoVal={selectedPromoVal}
                                setSelectedPromoVal={setSelectedPromoVal}
                                listRef={listRef}
                                setHeight={setHeight}
                                setIsDelete={setIsDelete}
                                disabled={UsePromotedPrice}
                            />
                        </div>
                    </div>

                    {selectedContry === 'UK' &&
                        <div className="flex flex-col gap-4 p-2  w-40">
                            <span className="text-sm flex items-center">
                                <input type="checkbox" name="promoted_price" checked={UsePromotedPrice} onChange={(e) => {
                                    if (UsePromotedPrice) {
                                        setPromotedPrice(null)
                                    } else {
                                        setSelectedPromoVal({})
                                    }
                                    setUsePromotedPrice(!UsePromotedPrice)

                                }} />
                                <label htmlFor="promoted_price" className="pl-2">Promoted Price</label>
                            </span>
                            <input disabled={!UsePromotedPrice} type="number" name="promotedPrice" value={promotedPrice || ''} onChange={e => inputValueChange(e, 'promotedPrice')} id="" placeholder="0.00" className="bg-white rounded h-10 items-center px-2 focus:border focus:border-blue-500 placeholder:text-xs w-full  outline-none shadow border" />
                        </div>
                    }

                    <div className="flex flex-col gap-4 p-2  w-40">
                        <Tooltip title={`${currencySymbol(selectedContry)}${totalInvestment ? totalInvestment.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}`}>
                            <span className="text-sm">Total Investment</span>
                        </Tooltip>

                        <div className="flex  rounded h-10 items-center px-2">
                            <span className="font-bold">
                                {totalInvestment ? totalInvestment.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}
                            </span>
                        </div>
                    </div>

                </div>

                <div className="flex flex-wrap justify-start gap-5 items-center px-5 border-b pb-5 bg-gray-200" style={{ paddingLeft: `${(160 * 3) + (80 * 4) + 20}px` }}>
                    {selectedPromoVal
                        ? Object.entries(selectedPromoVal).map(selectedItem => {
                            return <div className="flex">
                                {preROIPromoDataNew ? <div className="flex p-2 bg-primary text-white rounded-lg gap-x-3 items-center">
                                    <span className="text-xs"> {selectedItem[0]}</span>
                                    <span className="bg-white rounded-lg text-xs text-primary p-1" >{`${selectedItem[1]} ${preROIPromoDataNew[selectedItem[0]] ? '' : '%'}`}</span>
                                </div> : null}
                                {(isCalCulate && preROIPromoDataNew) ?
                                <IconButton onClick={() => { getPromoMagicVal(preROIPromoDataNew[selectedItem[0]], selectedItem[0]) }}>
                                    <AutoFixHighIcon sx={{color: 'blue'}} />
                                </IconButton> : null}
                            </div>
                        }) : null
                    }
                </div>

                {/* Promo info section */}
                <div className="flex justify-start gap-5 items-center py-2 px-5 border-b pb-5  bg-white">

                    {
                        selectedContry === 'PE'
                            ? <div className="flex flex-col gap-2   w-40">
                                <span className="text-sm">Historical Volume</span>
                                <div className="flex  rounded h-10 items-center px-2">
                                    <span className="font-bold">{historicalVolume ? Math.round(historicalVolume).toLocaleString('en-US') : 0}</span>
                                </div>
                            </div>
                            : null
                    }

                    <div className="flex flex-col gap-2 w-52">
                        <Tooltip title="In Packs">
                            <span className="text-sm">Incremental Volume<span className="text-red-900 font-bold text-base">*</span></span>
                        </Tooltip>
                        <span className="flex">
                            <input type="text" name="incrementalVolume" value={incrementalVolume} onChange={e => inputValueChange(e, 'incrementalVolume')} id=""
                                placeholder="Input"
                                className="bg-white rounded h-10 items-center px-2 focus:border focus:border-blue-500 placeholder:text-xs w-full-test  outline-none shadow border" />
                            <IconButton onClick={() => { getIncrementalVolume(); }}>
                                <AutoFixHighIcon sx={{color: 'blue' }} />
                            </IconButton>
                        </span>
                    </div>

                    <div className="flex flex-col gap-2 w-40">
                        <Tooltip title="In Packs">
                            <span className="text-sm">Total Volume</span>
                        </Tooltip>

                        <div className="flex  rounded h-10 items-center px-2">
                            <span className="font-bold">{totalVolume ? Math.round(totalVolume).toLocaleString('en-US') : 0}</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 w-40">
                        <Tooltip title={`Non Promoted Price ${currencySymbol(selectedContry)}${npp ? npp.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}`}>
                            <span className="text-sm">NPP</span>
                        </Tooltip>
                        <div className="flex  rounded h-10 items-center px-2">
                            <span className="font-bold">{npp ? npp.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 w-40">
                        <Tooltip title={`${currencySymbol(selectedContry)}${cogs ? cogs.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}`}>
                            <span className="text-sm">COGS Variable</span>
                        </Tooltip>
                        <div className="flex  rounded h-10 items-center px-2">
                            <span className="font-bold">{cogs ? cogs.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 w-40">
                        <Tooltip title={`${currencySymbol(selectedContry)}${netProfit ? netProfit.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}`}>
                            <span className="text-sm">Profit / Loss</span>
                        </Tooltip>
                        <div className="flex  rounded h-10 items-center px-2">
                            <span className="font-bold">{netProfit ? netProfit.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 w-80">
                        <span className="text-sm">Target ROI</span>
                        <input type="number" name="targetRoi" value={targetRoi || ''} onChange={e => inputValueChange(e, 'targetRoi')} id="" placeholder="0.00" className="bg-white rounded h-10 items-center px-2 focus:border focus:border-blue-500 placeholder:text-xs w-full  outline-none shadow border" />
                    </div>
                    <div className="flex flex-col gap-2 p-2 w-40">
                        <Tooltip title={`Return on Investment ${roi ? roi.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}%`}>
                            <span className="text-sm">ROI %</span>
                        </Tooltip>

                        <div className="flex  rounded h-10 items-center px-2">
                            <span className="font-bold">{roi ? roi.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : 0}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={showOpenDialog}
                //TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDelete}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    {`Are you sure you want to delete promo${pad(index)}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
                </DialogContent>
                <DialogActions className={classes.btnContainer}>
                    <Button onClick={handleCloseDelete} className={classes.btnSucess}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => deletePromo(index)}
                        className={classes.btnReset}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Toaster showAlert={showAlert} />
        </>
    )
}

