import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import useStore from "../../../../../store";
import NestedProductSearch from "./NestedProductSearch";
import useClickOutside from "../../../../../ClickOutside";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useGetPreRoiProducts } from "../../../../../services/queries";
import usePromoSimulatorStore from "../../../../../store/promoSimulatorStore";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex", component: "form", flexDirection: "column"
  },
  containerDisabled: {
    display: "flex", component: "form", flexDirection: "column", pointerEvents: 'none'
  },
  root: {
    backgroundColor: 'transparent',
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    width: theme.spacing(143),
  },
  listbox: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  input: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    float: "right",
    padding: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    float: "right",
    height: 28,
    margin: 4,
  },
  checkBox: {
    color: theme.palette.primary.main,
    fontFamily: "sans-serif",
  },
  listItem: {
    marginLeft: "10px",
    listStyle: "none",
    backgroundColor: "#f4f4f4",
    borderRadius: "5px",
  },
  disabledRoot: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: '#f5f5f5'
  },
}));

function ProductSearch(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [openEventSelector, setOpenEventSelector] = React.useState(false);
  const [clear, setClear] = useState(false);
  const promoSimulatorTabs = usePromoSimulatorStore((state) => state.promoSimulatorTabs);
  const preRoiSelectedCustomers = useStore((state) => state.preRoiSelectedCustomers);
  const setPreRoiProductsStateData = useStore((state) => state.setPreRoiProductsStateData);
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);
  let selectedCountry = useStore((state) => state.selectedCountry);
  let selectedPreRoiProducts = useStore((state) => state.selectedPreRoiProducts);
  const setSelectedPreRoiProducts = useStore((state) => state.setSelectedPreRoiProducts);
  const [customerValidationMsg, setCustomerValidationMsg] = useState('')

  const { isLoading: isProductsDataLoading, data: productsData } = useGetPreRoiProducts(`${selectedCountry}&customer=${preRoiSelectedCustomers}`, "Promotion");

  const handleClick = (event) => {
    if (selectedCountry && preRoiSelectedCustomers?.length > 0) {
      setOpenSelector(!openSelector);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
    if (preRoiSelectedCustomers.length === 0) {
      setCustomerValidationMsg('Products should be selected only after customer selection')
    }
  };

  useEffect(() => {
    setSelectedPreRoiProducts([]);
  }, [])

  useEffect(() => {
    if (promoSimulatorTabs[0].reset) {
      setSelectedPreRoiProducts([]);
      setPreRoiProductsStateData(props.data);
      setClear(true);
    }
  }, [promoSimulatorTabs])

  useEffect(() => {
    if (props.clear) {
      setSelectedPreRoiProducts([]);
      setPreRoiProductsStateData(props.data);
      setClear(true);
    }
  }, [props]);

  useEffect(() => {
    if (props.promoData && selectedPromoDetails.fromView) {
      const { products } = props.promoData;
      const selectedProducts = products.map(prod => {
        return `${prod.eanDescription} - ${prod.ean}`;
      })

      setSelectedPreRoiProducts([...selectedProducts]);
    }
  }, [selectedPromoDetails, props.promoData])

  let domNode = useClickOutside(() => {
    setOpenSelector(false);
    setOpenEventSelector(false);
  })

  const resetClear = () => {
    setClear(false);
    props.resetClearProduct();
  }

  useEffect(() => {
    if (preRoiSelectedCustomers.length > 0) {
      setCustomerValidationMsg('')
    }
  }, [preRoiSelectedCustomers])

  return (
    <div className={promoSimulatorTabs[0].calculateButtonClicked ? classes.containerDisabled : classes.container}
      ref={domNode}
    >
      <Paper component="form" className={promoSimulatorTabs[0].calculateButtonClicked ? classes.disabledRoot : classes.root} onClick={handleClick}>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton className={classes.iconButton} aria-label="search" onClick={handleClick}>
              <FilterAltIcon
                className={`icon_${props.dataFor.toLowerCase()}`}
                alt="filterIcon"
                sx={{ color: '#000' }}
              />
            </IconButton>
          </div>
          <div style={{ display: "flex" }}>
            {
              (
                selectedPreRoiProducts
                || []).length > 2 ? (<li
                  className={`selected_${props.dataFor.toLowerCase()}`}
                  style={{
                    marginLeft: "10px",
                    listStyle: "none",
                    borderRadius: "5px",
                  }}
                >
                  Multiple Item Selected({selectedPreRoiProducts.length})
                </li>) : (
                (
                  selectedPreRoiProducts
                  || []).filter((value, index, self) => self.indexOf(value) === index).map((item) => (
                    <li
                      className={`selected_${props.dataFor.toLowerCase()}`}
                      key={item}
                      style={{
                        marginLeft: "10px",
                        listStyle: "none",
                        borderRadius: "5px",
                      }}
                    >
                      {item}
                    </li>
                  ))
              )
            }
          </div>
        </>
        {isProductsDataLoading && <CircularProgress size=".75rem" />}
      </Paper>
      {customerValidationMsg.length > 0 &&
        <div style={{ color: '#d32f2f', margin: '4px', fontSize: '.75rem', fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: 400, lineHeight: 1.66, letterSpacing: '0.03333em' }}>{customerValidationMsg}!</div>}
      <div style={{ zIndex: 8525 }} className="absolute mt-11">
        {openSelector && (
          <NestedProductSearch
            key={'ProductSearch'}
            data={productsData}
            allData={productsData}
            previouslySelected={selectedPreRoiProducts}
            clear={props.clear || clear}
            resetClear={resetClear}
            indexValue={props.indexValue}
            tabName={props?.tabName}
            hasScopeData={props?.hasScopeData}
          />
        )}
      </div>

    </div >
  );
}
export default ProductSearch;

