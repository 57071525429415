import { useState, useEffect } from "react";
import { Grid, Box, MenuItem, Stack, TextField } from "@mui/material"
import { makeStyles } from '@material-ui/core';

import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import InputAdornment from '@mui/material/InputAdornment';
import useStore from "../../../../store";

export const validateInput = (input: any, validationType: string) => {
  switch (validationType) {
    case "positiveNumber":
      return (/^[0-9]+$/.test(input));
    case "positivePercentage":
      return (/^(?!0(\.0)?$)(?:\d{1,2}(?:\.\d)?|99(?:\.0)?)$/.test(input));
  };
};

export const validateRedemptionRate = (input: any) => {
  return (/^(?:\d{1,2}(?:\.\d)?|100(?:\.0)?)$/.test(input));
}

export const PromoSellOutPriceInput = ({ id, promoSellOutPriceTypes, promoData }: any) => {
  const classes = useStyles();
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const [selectedSellOutPriceType, setSelectedSellOutPriceType] = useState('');
  const [value, setValue] = useState('');
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);
  const [additionalFunding, setAdditionalFunding] = useState(promoSimulatorTabs[id].additionalFunding);
  const [redemptionRate, setRedemptionRate] = useState(promoSimulatorTabs[id].redemptionRate);
  const isValueValid = usePromoSimulatorStore((state: any) => state.isValueValid)
  const setIsValueValid = usePromoSimulatorStore((state: any) => state.setIsValueValid)
  const [validationValueInputErrorText, setValidationValueInputErrorText] = useState("");
  const [validationRedemptionInputErrorText, setValidationRedemptionInputErrorText] = useState("");
  const [isAdditionalFundingValueValid, setIsAdditionalFundingValueValid] = useState(true);
  const [validationAdditionalFundingErrorText, setValidationAdditionalFundingErrorText] = useState("");

  useEffect(() => {
    if (promoSimulatorTabs[id].reset) {
      setSelectedSellOutPriceType(promoSimulatorTabs[id].sellOutPriceType);
      setValue(promoSimulatorTabs[id].sellOutPriceTypeValue);
    }
  }, [promoSimulatorTabs])

  useEffect(() => {
    if (promoData && selectedPromoDetails.fromView) {
      setSelectedSellOutPriceType(promoData.promoSellOutOption);
      const promoSellOutvalue = promoData.promoSellOutOption === 'MULTIBUY' ? promoData.multiBuyOption : promoData.promoSellOutValue;
      setValue(promoSellOutvalue);
      setRedemptionRate(promoData.redemptionRate);
      setAdditionalFunding(promoData.additionalFunding)
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceType: promoData.promoSellOutOption, sellOutPriceTypeValue: promoSellOutvalue, redemptionRate: promoData.redemptionRate, additionalFunding: promoData.additionalFunding } : tab));
    }
  }, [selectedPromoDetails, promoData])

  const validateRedemptionValueInput = (e: any) => {
    if (!validateRedemptionRate(e.target.value)) {
      setIsValueValid({ ...isValueValid, isRedemptionRateValid: false });
      setValidationRedemptionInputErrorText("Only positive values from 0 and up to 100 with up to 1 decimal are allowed");
    }
    else {
      setIsValueValid({ ...isValueValid, isRedemptionRateValid: true });
      setValidationRedemptionInputErrorText("");
    }
    setRedemptionRate(e.target.values);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, redemptionRate: e.target.value, reset: false } : tab));
  };

  const validateValueInputs = (e: any) => {
    //accept only +ve numbers for value
    if (e.target.value) {
      if (selectedSellOutPriceType === 'FIXED_OFF' || selectedSellOutPriceType === 'MULTIBUY') {
        if (!validateInput(e.target.value, "positiveNumber")) {
          setIsValueValid({ ...isValueValid, isSellOutPriceValid: false });
          setValidationValueInputErrorText("Only positive values without decimals are allowed");
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceTypeValue: '' } : tab));
        }
        else {
          setIsValueValid({ ...isValueValid, isSellOutPriceValid: true });
          setValidationValueInputErrorText("");
          setValue(e.target.value);
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceTypeValue: e.target.value } : tab));
        }
        setValue(e.target.value);
      };
      //accept values only in percentages for value
      if (selectedSellOutPriceType === 'PERCENT_OFF') {
        if (!validateInput(e.target.value, "positivePercentage")) {
          setIsValueValid({ ...isValueValid, isSellOutPriceValid: false });
          setValidationValueInputErrorText("Only positive values greater than 0 and lesser than 100 with up to 1 decimal are allowed");
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceTypeValue: '' } : tab));
        }
        else {
          setIsValueValid({ ...isValueValid, isSellOutPriceValid: true });
          setValidationValueInputErrorText("");
          setValue(e.target.value);
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceTypeValue: e.target.value } : tab));
        }
        setValue(e.target.value);
      };
    };
  }

  const validateAdditionalFundingInput = (e: any) => {
    // accept only +ve number
    if (e.target.value) {
      if (!validateInput(e.target.value, "positiveNumber")) {
        setIsAdditionalFundingValueValid(false);
        setValidationAdditionalFundingErrorText("Only positive numbers with up to 1 decimal value is accepted!");
      }
      else {
        setIsAdditionalFundingValueValid(true);
        setValidationAdditionalFundingErrorText("")
      };
    };
    setAdditionalFunding(e.target.value);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, additionalFunding: e.target.value } : tab));
  };

  const handleSellOutPriceTypeChange = (e: any) => {
    setValue("");
    setIsValueValid({ ...isValueValid, isSellOutPriceValid: true });
    setValidationValueInputErrorText("");

    setRedemptionRate("");
    setIsValueValid({ ...isValueValid, isRedemptionRateValid: true });
    setValidationRedemptionInputErrorText("");

    setAdditionalFunding("");
    setIsAdditionalFundingValueValid(true);
    setValidationAdditionalFundingErrorText("");

    setSelectedSellOutPriceType(e.target.value);
    if (e.target.value === 'MULTIBUY') {
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceType: e.target.value, multiBuy: true, sellOutPriceTypeValue: '' } : tab));
    } else {
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceType: e.target.value, sellOutPriceTypeValue: '' } : tab));
    }
  };

  const handleValueChange = (e: any) => {
    setIsValueValid({ ...isValueValid, isSellOutPriceValid: true });
    setValidationValueInputErrorText("");
    if (selectedSellOutPriceType !== 'MULTIBUY') {
      validateValueInputs(e);
      setValue(e.target.value);
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceTypeValue: e.target.value } : tab));
    }
    else {
      setValue(e.target.value);
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellOutPriceTypeValue: e.target.value } : tab));
    };
  };

  const handleAdditionalFundingChange = (e: any) => {
    setIsAdditionalFundingValueValid(true);
    setValidationAdditionalFundingErrorText("");
    validateAdditionalFundingInput(e);
  };

  const handleRedemptionRateChange = (e: any) => {
    setIsValueValid({ ...isValueValid, isRedemptionRateValid: true });
    setValidationRedemptionInputErrorText("");
    validateRedemptionValueInput(e)
  };

  useEffect(() => {
    if (promoSimulatorTabs[id].reset) {
      setIsValueValid({ ...isValueValid, isSellOutPriceValid: true })
    }
  }, [promoSimulatorTabs[id].reset])

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Box sx={{ fontWeight: '500', m: 1, fontSize: '0.75rem' }}>Promo Sell Out Price<span className="text-red-900 font-bold text-base">*</span></Box>
        </Grid>
        <Grid item xs={2.5}>
          <TextField
            select
            size="small"
            variant="outlined"
            disabled={promoSimulatorTabs[id].calculateButtonClicked}
            className={classes.fieldWidth}
            value={selectedSellOutPriceType}
            onChange={e => handleSellOutPriceTypeChange(e)}
            InputLabelProps={{ shrink: true }}
            required
          >
            {promoSellOutPriceTypes?.options?.map((promoType: any, index: number) => {
              return <MenuItem sx={{ fontSize: '0.8rem' }} key={index} value={promoType.internal}>{promoType.label}</MenuItem>
            })}
          </TextField>
        </Grid>
        <Grid item xs={2.5}>
          <Stack direction="row" spacing={1}>
            {selectedSellOutPriceType !== "MULTIBUY" ?
              <TextField
                error={!isValueValid.isSellOutPriceValid}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedSellOutPriceType === "PERCENT_OFF" ? '%' : ''}</InputAdornment>,
                }}
                size="small"
                variant="outlined"
                label="Value"
                className={classes.fieldWidth}
                value={value}
                onChange={e => handleValueChange(e)}
                InputLabelProps={{ shrink: true }}
                disabled={!selectedSellOutPriceType || selectedSellOutPriceType === "MANUAL_INPUT" || promoSimulatorTabs[id].calculateButtonClicked}
                helperText={!promoSimulatorTabs[id].reset ? (!isValueValid.isSellOutPriceValid ? validationValueInputErrorText : null) : null}
                required
              /> :
              <TextField
                select
                size="small"
                variant="outlined"
                className={classes.fieldWidth}
                value={value}
                onChange={e => handleValueChange(e)}
                label="Value"
                disabled={!selectedSellOutPriceType || promoSimulatorTabs[id].calculateButtonClicked}
                InputLabelProps={{ shrink: true }}
                required
              >
                {['3 for 2', '2 for 1'].map((option: any, index: number) => {
                  return <MenuItem sx={{ fontSize: '0.8rem' }} key={index} value={option}>{option}</MenuItem>
                })}

              </TextField>
            }
          </Stack>
        </Grid>
        <Grid item xs={3}>
          {selectedSellOutPriceType === "MULTIBUY" &&
            <Stack direction="row" spacing={1}>
              <div style={{ fontWeight: '500', margin: 1, fontSize: '0.75rem' }}>Redemption Rate<span className="text-red-900 font-bold text-base">*</span></div>
              <TextField
                error={!isValueValid?.isRedemptionRateValid}
                size="small"
                variant="outlined"
                className={classes.fieldWidth}
                value={redemptionRate}
                disabled={promoSimulatorTabs[id].calculateButtonClicked}
                onChange={e => handleRedemptionRateChange(e)}
                InputLabelProps={{ shrink: true }}
                helperText={!isValueValid?.isRedemptionRateValid ? validationRedemptionInputErrorText : null}
                required
                inputProps={{ "aria-label": "redemptionRate" }}
              />
            </Stack>
          }
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  fieldWidth: {
    width: '228px',
    '& input': {
      fontSize: '0.75rem',
    },
    '& div': {
      fontSize: '0.75rem !important',
    }
  },
}));
