import { useEffect, useState } from "react";

import { useTargetProductHierarchy } from "../../services/queries";
import NestedSearch from "../NestedSearch";
import { ChannelScopeData, CustomerScopeData } from "../types";
import { ScopeFilter } from "./components/scope_filter";
import useClickOutside from "../../ClickOutside";
import ErrorMessage from "../ErrorMessage";
import { getHierarchyLevel } from "../utils";

type SelectionRowProps = {
  handleSelectedValues: (obj: any) => void;
  initialScopeData: any;
  emptySelection: boolean;
  comparisonAdded: () => void;
  scope?: String;
  source?: String;
  selectedProducts: any;
  selectedTargetProducts: any;
  useNewScopeBy?: boolean;
  selectedCategoryLevel?: string;
  selectedProductLevel?: string;
  selectedCountry: string
  filteredproducts?: any,
  filteredTargetdproducts?: any
};

export const SelectionRow = ({ handleSelectedValues, selectedCountry, initialScopeData, emptySelection, comparisonAdded, selectedCategoryLevel, selectedProductLevel,
  scope, source, selectedProducts, selectedTargetProducts, useNewScopeBy = false }: SelectionRowProps) => {

  const [canShowSearch, setcanShowSearch] = useState(false);
  const [canShowTargetSearch, setcanShowTargetSearch] = useState(false);
  const [targetProductUrl, settargetProductUrl] = useState("");
  const [scopeName, setScopeName] = useState("Product");
  const [SelectionFixed, setSelectionFixed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [targetProductHierarchyPayload, setTargetProductHierarchyPayload] = useState(null);

  const { isSuccess: haveTargetData, data: targetData } = useTargetProductHierarchy(targetProductHierarchyPayload);

  useEffect(() => {
    if (useNewScopeBy && scope) {
      setScopeName(scope.toLowerCase());
    }
  }, [useNewScopeBy, scope]);

  // useEffect(() => {
  //     const scrollEvent = (e:any) => {
  //         const table = document.getElementById('crossTable')!
  //         const body = document.getElementsByTagName('body')[0]
  //         if (body.scrollTop > table.offsetTop){
  //             setSelectionFixed(true)
  //         }else{
  //             setSelectionFixed(false)
  //         }
  //     }
  //     document.addEventListener('scroll',scrollEvent)

  //     return () => {
  //         document.removeEventListener('scroll', scrollEvent)
  //     }
  // }, [])

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(getErrorMessageValue())
    }
  }, [source, selectedCategoryLevel, selectedProductLevel])

  const getErrorMessageValue = () => {
    return source && selectedCategoryLevel && selectedProductLevel
      ? ''
      : 'Please select mandatory fields'
  }
  const showNestedSearch = () => {
    setErrorMessage(getErrorMessageValue())
    setcanShowSearch(!getErrorMessageValue())
  }

  const showNestedTargetSearch = () => {
    if (!selectedProducts.length) {
      setErrorMessage('Please select initial Products')
      return
    }
    setcanShowTargetSearch(true)
  }

  const getFlatScopeData = (dataFor: string) => {
    if (!useNewScopeBy) {
      if (dataFor === "initial") {
        return initialScopeData;
      }
      return targetData;
    }
    if (scopeName === "channel") {
      if (dataFor === "initial") {
        return (initialScopeData as ChannelScopeData[]).map(
          (x) => x.channelInitial
        );
      } else if (dataFor === "target") {
        // return (initialScopeData as ChannelScopeData[]).filter(x => selectedProducts.includes(x.channelInitial)).map(x => x.channelTarget)
        return (initialScopeData as ChannelScopeData[]).map((x) => x.channelTarget).filter((x) => !selectedProducts.includes(x));
      }
    } else if (scopeName === "customer") {
      if (dataFor === "initial") {
        return (initialScopeData as CustomerScopeData[]).map(
          (x) => x.customerInitial
        );
      } else if (dataFor === "target") {
        // return (initialScopeData as CustomerScopeData[]).filter(x => selectedProducts.includes(x.customerInitial)).map(x => x.customerTarget)
        return (initialScopeData as CustomerScopeData[]).map((x) => x.customerTarget).filter((x) => !selectedProducts.includes(x));
      }
    }
    return null;
  };

  const handleSelectedProducts = (kcObject: any) => {
    if (useNewScopeBy) {
      handleSelectedValues(kcObject);
      return;
    }
    if (kcObject.dataFor === "initial") {
      let apiURL = kcObject.apiUrl;
      if (scope) {
        apiURL = `${apiURL}&scope=${scope}`;
      } else if (source) {
        apiURL = `${apiURL}&source=${source}`;
      }
      let payload = {
        country: selectedCountry,
        scope: scope,
        source: source,
        leafValues: kcObject.selectedKcArr,
        levelIndicator: selectedProductLevel ? getHierarchyLevel(selectedProductLevel) : ""
      }
      setTargetProductHierarchyPayload(payload as any);
      // setcanShowSearch(false)
    }
    handleSelectedValues(kcObject);
  };

  const handleClickOutSideInitial: any = useClickOutside(() => {
    setcanShowSearch(false);
  })

  const handleClickOutSideTarget: any = useClickOutside(() => {
    setcanShowTargetSearch(false);
  })

  return (
    <div className={`min-h-20 flex flex-col top-0 ${SelectionFixed ? "fixed" : ""}`}>
      <ErrorMessage message={errorMessage} />
      <div className="flex pb-4">
        <span className="capitalize">Initial {scopeName}s: </span>
        <div className={`relative flex pr-2 ${canShowSearch ? "z-30" : ""}`}>
          <div
            onClick={showNestedSearch}
            className="cursor-pointer px-2 py-1"
            data-testid="initial-product-plus-icon"
          >
            <svg
              data-testid="initial-product-plus-icon-svg"
              className="fill-primary cursor-pointer"
              focusable="false"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              id="prod_picker1"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
            </svg>
          </div>
          {canShowSearch && initialScopeData && !useNewScopeBy ? (
            <div
              data-testid="nested-search-initial"
              className="absolute z-6 z-30"
              ref={handleClickOutSideInitial}
            >
              <NestedSearch
                data={getFlatScopeData("initial")}
                handleSelectedValues={handleSelectedProducts}
                tabName="cross"
                dataFor={"initial"}
                id={`initialSearch`}
                previouslySelected={selectedProducts}
              //    onMouseLeave={() => {
              //       setcanShowSearch(false);
              //       setcanShowTargetSearch(false);
              //    }}
              />
            </div>
          ) : null}
          {canShowSearch && initialScopeData && useNewScopeBy ? (
            <div className="absolute z-6 z-30">
              <ScopeFilter
                scopeData={getFlatScopeData("initial")}
                onMouseLeave={() => {
                  setcanShowSearch(false);
                  setcanShowTargetSearch(false);
                }}
                onSelected={(selected) => {
                  handleSelectedProducts({
                    dataFor: "initial",
                    selectedKcArr: selected,
                  });
                }}
                previouslySelected={selectedProducts}
              />
            </div>
          ) : null}
        </div>
        <div className="flex flex-wrap">
          {selectedProducts.map((x: string) => {
            return <div className="text-xs p-2 bg-gray-300 rounded mt-1.5 ml-1.5">{x}</div>
          })}
        </div>
      </div>
      <div className="flex pb-4">
        <span className="capitalize">Target {scopeName}s: </span>
        <div className=" relative flex pr-2 z-20">
          <div
            onClick={showNestedTargetSearch}
            className="cursor-pointer px-2 py-1"
            data-testid="target-product-plus-icon"
          >
            <svg
              data-testid="target-product-plus-icon-svg"
              className="fill-purple-700 cursor-pointer"
              focusable="false"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              id="prod_picker1"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
            </svg>
          </div>

          {canShowTargetSearch && !useNewScopeBy ? (
            <div
              className="absolute z-6 z-30"
              data-testid="nested-search-target"
              ref={handleClickOutSideTarget}
            >
              <NestedSearch
                data={getFlatScopeData("target")}
                handleSelectedValues={handleSelectedProducts}
                tabName="cross"
                dataFor={"target"}
                id={`targetSearch`}
                previouslySelected={selectedTargetProducts}
              //    onMouseLeave={() => {
              //    setcanShowSearch(false);
              //    setcanShowTargetSearch(false);
              //    }}
              />
            </div>
          ) : null}

          {canShowTargetSearch && useNewScopeBy ? (
            <div className="absolute z-6 z-30">
              <ScopeFilter
                scopeData={getFlatScopeData("target")}
                onMouseLeave={() => {
                  setcanShowSearch(false);
                  setcanShowTargetSearch(false);
                }}
                onSelected={(selected) => {
                  handleSelectedProducts({
                    dataFor: "target",
                    selectedKcArr: selected,
                  });
                }}
                previouslySelected={selectedTargetProducts}
              />
            </div>
          ) : null}
        </div>
        <div className="flex flex-wrap">
          {selectedTargetProducts.map((x: string) => {
            return <div className="text-xs p-2 bg-gray-300 rounded mt-1.5 ml-1.5">{x}</div>
          })}
        </div>
      </div>
    </div>
  );
};
