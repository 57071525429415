import { useEffect } from "react";

import { SingleGrid } from "../../single_grid";
import { ElasticityRecord, Scope, PriceType, Source } from "../../types";
import useStore from "../../../store";

type Props = {
  records: ElasticityRecord[],
  addProductClick: () => void,
  selectedCountry: string,
  unselectRow: (name: string, target?: any, id?: any) => void,
  handleViewMore: (id: any, initial: string, target?: string, customer?: string, category?: string, subCategoryInitial?: string, subCategoryTarget?: string, manufacturerInitial?: string, manufacturerTarget?: string, brandInitial?: string, brandTarget?: string, subBrandInitial?: string, subBrandTarget?: string, packInitial?: string, packTarget?: string) => void,
  selectedProducts: string[],
  loading: boolean,
  priceType: PriceType,
  source: Source
}

export const CountryScope = ({ records, addProductClick, selectedCountry, unselectRow, handleViewMore, selectedProducts, loading, priceType, source }: Props) => {
  let selectedProductLevel = useStore((state: any) => state.selectedProductLevel);

  return <div className="flex border-b border-gray-300">
    <div className="flex flex-col bg-white">
      <div data-testid="addproduct-button" onClick={() => addProductClick()} className="cursor-pointer px-5 py-4 h-16  border-t border-l border-gray-300 ">
        <svg className={selectedProductLevel !== 'category' ? "fill-primary cursor-pointer" : ""}
          focusable="false" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" id="prod_picker1">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
        </svg>
      </div>
      <div className="h-full border-l border-b border-gray-300"></div>
    </div>
    <SingleGrid
      records={records}
      selectedCountry={selectedCountry}
      scope={Scope.COUNTRY}
      priceType={priceType}
      unselectRow={unselectRow}
      handleViewMore={handleViewMore}
      showName={true}
      showOptions={true}
      loading={loading}
      source={source}
    />
  </div>
}

