import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppTabs from "../AppTabs";
import useStore from "../store";
import { isEmpty } from "lodash";
import {useCountryMasterData} from '../services/queries'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
    width: "100%",
    flexGrow: 1,
  },
  paperDiv: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
    },
  },
  container: {
    backgroundColor: "#F6F8FC",
    height: "100vh",
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
  },
  searchBar: {
    display: "inline-block",
    paddingRight: "2.5rem",
  },
  searchContainer: {
    background: "#F6F8FC",
    width: "100%",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    color: "black",
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  autoCompleteSearch: {
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    background: "#FFFFFF",
  },
  gridContainer: {
    background: "#F6F8FC",
  },
  showCheckBox: {},
  appTab: {
    fontSize: "20px",
  },
  hide: {
    "& .checkbox-item": {
      display: "none",
    },
    "& .radio-item": {
      display: "none",
    },
  },
}));

const PriceElasticityLayout = (props) => {
  const classes = useStyles();

  let selectedCountry = useStore((state) => state.selectedCountry);
  const { isLoading: isMasterDataLoading, data: masterData } = useCountryMasterData(`country=${selectedCountry}`)
  let setPriceElasticityTab = useStore((state) => state.setPriceElasticityTab);

  const setSelectedSubCategoryLevel = useStore((state) => state.setSelectedSubCategoryLevel)
  const setDefaultSubCategory = useStore((state) => state.setDefaultSubCategory)
  
  const setOwnMasterData = useStore((state)=>state.setOwnMasterData)
  const setCrossMasterData = useStore((state)=>state.setCrossMasterData)

  function itemProcess(ownObj, item) {
    if (ownObj.hasOwnProperty(item.name)) {
      if (ownObj[item.name].hasOwnProperty(item.source)) {
        ownObj[item.name][item.source].push(item.scope);
      } else {
        ownObj[item.name][item.source] = [item.scope]
      }
    } else {
      let obj = {};
      obj[item.source] = [item.scope]
      ownObj[item.name] = obj;
    }
    return ownObj;
  }

  //When Country is changes extract and set master data for Own and Cross tab
  useEffect(() => {
    if (!isMasterDataLoading && !isEmpty(masterData)) {
      let productLocatorsOwn = masterData['productLocators']?.filter(item => item.flag === 'OWN');
      let productLocatorsCross = masterData['productLocators']?.filter(item => item.flag === 'CROSS');

      let obj = {};
      productLocatorsOwn.forEach(item => {
        obj = itemProcess(obj, item);
      })
      setOwnMasterData(obj);
      obj = {};
      productLocatorsCross.forEach(item => {
        obj = itemProcess(obj, item);
      })
      setCrossMasterData(obj);
    }
  }, [isMasterDataLoading, masterData]);

  useEffect(() => {
    setPriceElasticityTab("");
    setSelectedSubCategoryLevel([]);
    setDefaultSubCategory([]);
  }, []);

  let tabs = [
    { index: 0, tag: "Correlation", value: "CorrelationTab", id: 'CorrelationTab' },
    { index: 1, tag: "Own", value: "OwnTab", id: 'OwnTab' },
    { index: 2, tag: "Cross", value: "CrossTab", id: 'CrossTab' },
  ]
  const crossByScopeTab = { index: 3, tag: "Cross By Scope", value: "CrossTab", id: 'CrossTabByScope' }
  const [tabsToShow, setTabsToShow] = useState(tabs)

  useEffect(() => {
    if (selectedCountry === 'KR') {
      const found = tabsToShow.findIndex(x => x.id === 'CrossTabByScope')
      if (found === -1) {
        setTabsToShow([...tabsToShow])
        // setTabsToShow([...tabsToShow, crossByScopeTab])
      }
    } else {
      setTabsToShow(tabs)
    }
  }, [selectedCountry])


  return (
    <Grid item xs={12} sm={12}>
      <Grid className={classes.paperDiv}>
        <div className={classes.chartDiv}>
          <AppTabs
            className={classes.appTab}
            tabs={tabsToShow}
            logo={null}

          />
        </div>
      </Grid>
    </Grid>
  );
};

export default PriceElasticityLayout;
