import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { useHistory, withRouter } from "react-router-dom";
import { useLocation } from "react-router";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import BaseLine from "./Baseline";
import ImpactHistory from "./BaseLine/ImpactedHistory";
import ImpactHistoryDelete from "./BaseLine/ImpactedHistoryDelete";
import NewImpactors from "./BaseLine/NewImpactors";
import CorrelationTab from "./Elasticity/Correlation/CorrelationTab";

import PromoProductTable from "./PostRoi/DashboardTab/PromoProductTable";

import Box from "@material-ui/core/Box";
import useStore from "./store";
import { OwnTab } from "./Elasticity/Own/own_tab";
import { CrossTab } from "./Elasticity/Cross/cross_tab";


const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    textTransform: "none",
    minWidth: 50,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const AppTabs = ({ tabs, children, logo }) => {
  const history = useHistory();
  let setVisibilityYearMonthly = useStore(
    (state) => state.setVisibilityYearMonthly
  );

  let priceElasticityTab = useStore((state) => state.priceElasticityTab);

  //------SELECTION: KC PRODUCT------//
  const setSelectedKcProducts = useStore(
    (state) => state.setSelectedKcProducts
  );

  //------SELECTION: TARGET PRODUCT------//
  const setSelectedTargetProducts = useStore(
    (state) => state.setSelectedTargetProducts
  );

  //------SELECTION: OWN PRODUCT------//
  const setSelectedOwnProducts = useStore(
    (state) => state.setSelectedOwnProducts
  );


  const setSelectedCustomer = useStore((state) => state.setSelectedCustomer);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [subValue, setSubValue] = React.useState(0);
  const [subCustomerValue, setSubCustomerValue] = React.useState(0);

  //to get location
  let location = useLocation();

  useEffect(() => {
    switch (location.pathname.split("/")[1]) {
      case "baseline-forcasting":
        setValue(0);
        break;

      case "pre-roi":
        setValue(1);
        break;
      case "promo-performace":
        setValue(2);
        break;
      case "price-elasticity":
        setValue(3);
        break;

      default:
        setValue(0);
        break;
    }

    if (
      priceElasticityTab === "Correlation" ||
      priceElasticityTab === "Own" ||
      priceElasticityTab === "Cross"
    ) {
      switch (priceElasticityTab) {
        case "Correlation":
          setSubValue(0);
          setSubCustomerValue(0);
          break;
        case "Own":
          setSubValue(1);
          break;
        case "Cross":
          setSubValue(2);
          break;

        default:
          setSubValue(0);
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (subValue === 3 && !tabs.find(x => x.index === 3)) {
      setSubValue(0)
    }
  }, [tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    const navTab = tabs.filter((tab) => tab.index === newValue);
    history.push(navTab[0].route);
    history["params"] = navTab[0].tag;

    if (navTab[0].route === "/price-elasticity") {
      setVisibilityYearMonthly(false);
    }

    if (navTab[0].route === "/baseline-forcasting") {
      setVisibilityYearMonthly(true);
    }
  };

  const subHandleChange = (event, newValue) => {
    setSubValue(newValue);
    setSelectedKcProducts("");
    setSelectedTargetProducts("");
    setSelectedOwnProducts("");
    console.log("newValue", newValue);
    const navTab = tabs.filter((tab) => tab.index === newValue);

    history.push(navTab[0].route);
    history["params"] = navTab[0].tag;

  };

  let imgLogo = null;

  if (logo !== null) {
    imgLogo = (
      <Container style={{ maxWidth: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={1} style={{ maxWidth: 60 }}>
            {logo}
          </Grid>
          <Grid item xs={12} sm={9}>
            <AntTabs value={tabs[0] !== undefined && tabs[0].name !== undefined ? (tabs[0].name === 'CUSTOMER' ? subCustomerValue : value) : value} onChange={handleChange}>
              {tabs.map((tab) => (
                <AntTab key={tab.tag} label={tab.tag} id={`tab-${tab.id}`} name={tab.name} />
              ))}
            </AntTabs>
          </Grid>
          <Grid item xs={12} sm={2}>
            {children}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    imgLogo = (
      <Container style={{ maxWidth: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10}>
            <AntTabs value={subValue} onChange={subHandleChange}>
              {tabs.map((tab) => (
                <AntTab key={tab.tag} label={tab.tag} id={`tab-${tab.id}`} />
              ))}
            </AntTabs>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
        {tabs.map((tab, index) => (
          <TabPanel value={subValue} index={index}>
            {tab.value === "BaseLine" && <BaseLine />}
          </TabPanel>
        ))}

        {tabs.map((tab, index) => (
          <TabPanel value={subValue} index={index}>
            {tab.value === "newImpactors" && <NewImpactors />}
          </TabPanel>
        ))}

        {tabs.map((tab, index) => (
          <TabPanel value={subValue} index={index}>
            {tab.value === "ImpactorsHistory" && <ImpactHistory />}
          </TabPanel>
        ))}

        {tabs.map((tab, index) => (
          <TabPanel value={subValue} index={index}>
            {tab.value === "ImpactorsHistoryDelete" && <ImpactHistoryDelete />}
          </TabPanel>
        ))}
        {tabs.map((tab, index) => (
          <TabPanel value={subValue} index={index}>
            {tab.value === "PromoProduct" && <PromoProductTable />}
          </TabPanel>
        ))}

        {tabs.map(
          (tab, index) =>
            tab.value === "CorrelationTab" && (
              <TabPanel value={subValue} index={index}>
                <CorrelationTab />
              </TabPanel>
            )
        )}
        {tabs.map(
          (tab, index) =>
            tab.value === "OwnTab" && (
              <TabPanel value={subValue} index={index}>
                <OwnTab />
              </TabPanel>
            )
        )}


        {tabs.map(
          (tab, index) =>
            tab.value === "CrossTab" && (
              <TabPanel value={subValue} index={index}>
                <CrossTab byScope={tab.id === 'CrossTabByScope'} />
              </TabPanel>
            )
        )}

        {/* {tabs.map((tab, index) => <TabPanel value={value} index={index} >
        {
          (tab.value === "Case") && (
            <PowerBIReport />
          )
        }
          </TabPanel>)} */}
      </Container>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>{imgLogo}</div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0.5}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default withRouter(AppTabs);
