import React, { useEffect, useRef } from "react";

const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (domNode && !domNode.current?.contains(event.target)) {
        let nodeName=event.srcElement.nodeName;
        let innerText=event.srcElement.innerText;
        let flag=false;

        if (nodeName === "BUTTON") flag = innerText === "CLEAR" ? true : false;

        handler(flag);
      }
    };
    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });
  return domNode;
};

export default useClickOutside;
