import React, { useEffect } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from 'moment';
import useStore from './store';
import { extractCustomer } from "./UpdateUrl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import MonthRangePicker from './MonthRangePicker';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingTop:'0px',
  },
  dialogContent: {
    textAlign:'center',
    fontWeight:600,
    fontSize:18
  },
  btnClose: {
    color:'red', 
    fontSize:'1.25rem',
    position: 'relative',
    justifyContent:'right',
    padding:'10px 10px 0px 10px',
  }
}));

export default function DatePicker(props) {
  const classes = useStyles();
  let selectedSource = useStore((state) => state.selectedSource);

  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState();
  const [focusedInput, setFocusedInput] = React.useState();
  const [showMonthRange,setShowMonthRange] = React.useState(false);
  const [openSubmit, setOpenSubmit] = React.useState(false);
  const [monthGranularityReason, setMonthGranularityReason] = React.useState('');

  //store
  let startMonth = useStore((state) => state.startMonth);
  let endMonth = useStore((state) => state.endMonth);
  const setStartMonth = useStore((state) => state.setStartMonth);
  const setEndMonth = useStore((state) => state.setEndMonth);
  const setYearSelection = useStore((state) => state.setYearSelection);
  let sellInCustomerHashMap = useStore((state) => state.sellInCustomerHashMap);
  let selectedBaseLineCustomerNew = useStore((state) => state.selectedBaseLineCustomerNew);

  const handleDatesChange = ({ startDate, endDate }) => {
    if(endDate === null) {
      setEndDate(moment(startMonth));
      setEndMonth(moment(startMonth).format('YYYY-MM-DD'));
    } else {
      var end = moment(endDate);
      setEndDate(endDate);
      setEndMonth(end.format('YYYY-MM-DD'));
    }
    setStartDate(startDate)

    var d = moment(startDate);
    setYearSelection(d.year());
    setStartMonth(d.format('YYYY-MM-DD'));

  }; 

  const handleUpdate = (newRangeValue) => {
 
    let fromYear = newRangeValue['from']['year'];
    let fromMonth = newRangeValue['from']['month'];

    let toYear = newRangeValue['to']['year'];
    let toMonth = newRangeValue['to']['month'];

    let fromMonthFormatted = ("0" + fromMonth).slice(-2);
    let toMonthFormatted = ("0" + toMonth).slice(-2);
    let daysInMonth = new Date(toYear, toMonthFormatted, 0).getDate();

    let sDate = moment(`${fromYear}-${fromMonthFormatted}-01`);
    let eDate =  moment(`${toYear}-${toMonthFormatted}-${daysInMonth}`);

    setStartMonth(`${fromYear}-${fromMonthFormatted}-01`);
    setEndMonth(`${toYear}-${toMonthFormatted}-${daysInMonth}`);
    setStartDate(sDate);
    setEndDate(eDate);
  };

  const handleClickClose = () => {
    setOpenSubmit(false);
  };


  useEffect(() => {
    setStartMonth('')
    setEndMonth('')
    setYearSelection('')

    let stdate = moment().subtract(6, 'months').format('YYYY-MM-DD');
    let eddate = moment().format('YYYY-MM-DD');

    setStartDate(moment().subtract(6, 'months'));
    setEndDate(moment());

    setStartMonth(stdate)
    setEndMonth(eddate)
    setShowMonthRange(false);
    setOpenSubmit(false);

  }, []);

  useEffect(() => {
    if (props.clear) {
      setStartMonth("");
      setEndMonth("");
      setYearSelection("");

      let stdate = moment().subtract(6, "months").format("YYYY-MM-DD");
      let eddate = moment().format("YYYY-MM-DD");

      setStartDate(moment().subtract(6, "months"));
      setEndDate(moment());

      setStartMonth(stdate);
      setEndMonth(eddate);
      setShowMonthRange(false);
      setOpenSubmit(false);
      props.reset();
    }
  }, [props]);

  useEffect(() => {
    let granularityFlag = false;
    let customerLevelValues = extractCustomer(decodeURIComponent(selectedBaseLineCustomerNew));
    let combinedCustomer = [];

    for (const level in customerLevelValues) {
      combinedCustomer.push(...customerLevelValues[level]);
    }

    for (const level in sellInCustomerHashMap) {
      for (
        let index = 0;
        index < sellInCustomerHashMap[level].length;
        index++
      ) {
        let item = sellInCustomerHashMap[level][index];
        if (combinedCustomer.includes(item.name)) granularityFlag = true;
      }
    }
    const lastYearStartDate = moment().subtract(1,'year').startOf('year')
    let stdate = lastYearStartDate.format('YYYY-MM-DD');
    let eddate = moment().format('YYYY-MM-DD');

    let startDay = startDate;
    let endDay = endDate;
    if (!startDay) {
      startDay = stdate;
    }
    if (endDay === null || !endDay) {
      endDay = endDate;
    }

    if(startDate === '' || endDate === '')
    {
      
      setStartDate(lastYearStartDate);
      setEndDate(moment());

      setStartMonth(stdate);
      setEndMonth(eddate);
      setShowMonthRange(false);
    } else {
      if(startDate !== null){
        var d = moment(startDay);
        var end = moment(endDay);

        var demoDateF = startDay["_d"];
        var firstDay = new Date(demoDateF.getFullYear(), demoDateF.getMonth(), 1);

        var demoDateL = endDay["_d"];
        var lastDay = new Date(demoDateL.getFullYear(),demoDateL.getMonth() + 1,0);
        if (selectedSource.includes("SELL IN")) {
          setMonthGranularityReason("SELL IN is selected as source");
        } else if (granularityFlag) {
          setMonthGranularityReason("Selected customer belongs to SELL IN source");
        }      

        if (selectedSource.includes("SELL IN") || granularityFlag) {
          startDay["_d"] = firstDay;
          endDay["_d"] = lastDay;
        
          setStartDate(startDay);
          setEndDate(endDay);

          d = moment(startDay);
          end = moment(endDay);
          setYearSelection(d.year());

          setStartMonth(d.format("YYYY-MM-DD"));
          setEndMonth(end.format("YYYY-MM-DD"));
          setShowMonthRange(true);
        } else {
          setShowMonthRange(false);
        }
      }
    }
  }, [startDate,endDate,selectedSource, selectedBaseLineCustomerNew]);

  useEffect(() => {
    if (showMonthRange) {
      setOpenSubmit(true);
    } else {
      setOpenSubmit(false);
    }
  }, [showMonthRange]);

  return (
    <div className="App">
      {
        showMonthRange ? (
          <MonthRangePicker 
            name="React" 
            handleUpdate={handleUpdate}
            startMonth={startMonth}
            endMonth={endMonth}
            />
        ) : (
          <DateRangePicker
            startDate={startDate}
            startDateId="start-date"
            endDate={endDate}
            endDateId="end-date"
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            onDatesChange={handleDatesChange}
            enableOutsideDays={false}
            isOutsideRange={(day) => day.isBefore( moment().subtract(1,'year').startOf('year')) || day.isAfter(moment().format('YYYY-MM-DD'))}
          />
        )
      }
      <Dialog
        open={openSubmit}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton
          aria-label="close"
          className={classes.btnClose}
        >
          <CloseIcon onClick={handleClickClose}/>
        </IconButton>
        <DialogTitle className={classes.dialogTitle}>
          {"Updated date range granularity to monthly"}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-slide-description">
            {`Reason: ${monthGranularityReason}`}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>

  );
}