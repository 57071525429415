import { ReactNode } from "react"
import { TableColumn } from "react-data-table-component"

export type ElasticityRecord = {
    initial?: string,
    target?: string,
    product?: string,
    channelInitial?: string,
    channelTarget?: string,
    customerInitial?: string,
    customerTarget?: string,
    category?: string,
    subCategoryInitial?: string,
    subCategoryTarget?: string,
    manufacturerInitial?: string,
    manufacturerTarget?: string,
    brandInitial?: string,
    brandTarget?: string,
    subBrandInitial?: string,
    subBrandTarget?: string,
    packInitial?:string,
    packTarget?:string,
    averagePrice?: number
    basePrice?:number
    priceIndex?: number
    promoPrice?:number
    range?: string
    rsquared?: number
    viewMore?: boolean
    customer? : string,
    elasticity?: string,
    nweeks?: number,
    periodBegin?: string,
    periodEnd?: string,
    modelRun?: string,
    pvalue?: number,
    averageVolume?: number
    channel?: string,
    source?: string,
    scope?: string,
    id?: any,
}

export type ElasticityScopeRecord = {
    customer: string,
    channel?: string,
    data: ElasticityRecord[]
}

export enum Scope  {
    COUNTRY = 'WC',
    CUSTOMER = 'CUSTOMER',
    CHANNEL = 'CHANNEL',
    PCUST = 'pcust'
}

export enum Source  {
    POS = 'POS',
    NIELSEN = 'NIELSEN'
}

export type SelectedProduct = {
    apiUrl?: string,
    selectedKcArr: any[],
    selectedOwnArr?: any[],
    crossAnalysisApiUrl?: string,
    flag?: boolean,
    selectedBrand?: any[],
    selectedManufacturer?: any[],
    selectedSubBrands?: any[],
    selectedPacks?: any[],
    selectedProductLevel?: string
}

export type ProductsOnComparison = {
    initial: string,
    target: string
}

export type CustomSortFuction =  (field:  TableColumn<ElasticityRecord>, direction: string) => void
export type SortSelector = (row: ElasticityRecord, rowIndex: number) => ReactNode

export type PriceType =   {
    avgPrice: boolean,
    promoPrice: boolean,
    basePrice: boolean
}

export type CustomerScopeData = {
    customerInitial: string,
    customerTarget: string
}

export type ChannelScopeData = {
    channelInitial: string,
    channelTarget: string
}

export type AvailableScopes =  {
    customers: CustomerScopeData[],
    channels: ChannelScopeData[],
}