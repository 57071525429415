import { useState, useEffect, useCallback } from 'react';
import DataTable, { defaultThemes, TableColumn, ConditionalStyles, TableStyles } from 'react-data-table-component';
import { ElasticityRecord, Scope, CustomSortFuction, PriceType, Source } from './types';
import { ArrowDownward } from '@mui/icons-material'
import CSS from 'csstype';
import CancelIcon from '@mui/icons-material/Cancel';
import { convertDateRange, getVolumeFieldName } from "./utils";

type Props= {
    records: ElasticityRecord[],
    selectedCountry: string,
    unselectRow?: (name: string, target?:string, id?: any) => void,
    handleViewMore?: (id: any, initial: string, target?:string, customer?:string, category?:string, subCategoryInitial?:string, subCategoryTarget?:string, manufacturerInitial?:string, manufacturerTarget?:string, brandInitial?:string, brandTarget?:string, subBrandInitial?: string, subBrandTarget?: string, packInitial?: string, packTarget?: string
        ) => void,
    showName: boolean,
    showOptions: boolean,
    scope: Scope,
    loading: boolean,
    customSort?: CustomSortFuction,
    showTargetProduct?: boolean,
    priceType?: PriceType,
    useNewScopeBy?: boolean,
    source?: Source,
    selectedTab? : string
}

const cancelIconStyle = {
    color: '#0000b3',
    display: 'inline', 
    cursor: 'pointer'
}

export const SingleGrid = ({records,  selectedCountry, unselectRow, handleViewMore, showName, showOptions, scope, loading, customSort, showTargetProduct = false, priceType, useNewScopeBy = false, source, selectedTab }:Props) => {

    const [Columns, setColumns] = useState<TableColumn<ElasticityRecord>[]>([]);
    const [ElasticityRecords, setElasticityRecords] = useState<ElasticityRecord[]>([]);
    const [IsLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(loading)
    }, [loading]);

    useEffect(() => {
        setElasticityRecords([...records]) 
    }, [records]);
    
    const getModelUpdateDateRanage = useCallback(
      (record: ElasticityRecord) => {
            if (record.periodBegin && record.periodEnd){
                let startDate = convertDateRange(record.periodBegin);
                let endDate = convertDateRange(record.periodEnd);
                return `${startDate} - ${endDate}`
            }else if (record.periodBegin){
                let startDate = convertDateRange(record.periodBegin);
                return `${startDate}`
            }
            return '-'
      },
      [records],
    )
    
    const handleUnselect = (initial: string, target?: string, id?: any)  => {
        if (unselectRow) {
            unselectRow(initial, target, id);
        } 
    }

    const getPriceFieldName = () => {
        if (selectedCountry === 'KR'){
            if(!showTargetProduct) {
                return 'Price SU'
            } else {
                return 'Price SU Initial'
            }
        } else{
            return (source === Source.NIELSEN ? (showTargetProduct ? 'Index Price ' : 'Average Price') : (showTargetProduct ? 'Price Index' : 'Average Price'));
        }
    }

    const getInitialSelectorBasedOnScope = (row: ElasticityRecord) => {
        if (useNewScopeBy){
            if (scope === Scope.CUSTOMER) {
                 return row.customerInitial || '-'
            }else if (scope === Scope.CHANNEL) {
                return row.channelInitial || '-'
            } 
        }
        return row.initial || '-'
    }

    const getInititalFieldNameBasedOnScope = () => {
        if (useNewScopeBy){
            if (scope === Scope.CUSTOMER) {
                 return 'customerInitial'
            }else if (scope === Scope.CHANNEL) {
                return 'channelInitial'
            } 
        }
        return 'initial'
    }

    const getInitialValueBasedOnScope = (row: ElasticityRecord) => {
        if (useNewScopeBy){
            if (scope === Scope.CUSTOMER) {
                 return row.customerInitial 
            }else if (scope === Scope.CHANNEL) {
                return row.channelInitial 
            } 
        }
        return row.initial 
    }

    const getTargetSelectorBasedOnScope = (row: ElasticityRecord) => {
        if (useNewScopeBy){
            if (scope === Scope.CUSTOMER) {
                 return row.customerTarget || '-'
            }else if (scope === Scope.CHANNEL) {
                return row.channelTarget || '-'
            } 
        }
        return row.target || '-'
    }

    const getTargeValueBasedOnScope = (row: ElasticityRecord) => {
        if (useNewScopeBy){
            if (scope === Scope.CUSTOMER) {
                 return row.customerTarget 
            }else if (scope === Scope.CHANNEL) {
                return row.channelTarget 
            } 
        }
        return row.target 
    }

    const getProductName = (row: ElasticityRecord) => {
        if (useNewScopeBy){
            return row.product || '-';
        }
        return row.product || '-';
    }

    const getScopeHeader = () => {
        if (useNewScopeBy && scope) {
            return scope.toLowerCase()
        }
        return "Product"
    }
  
    useEffect(() => {
        const _columns: TableColumn<ElasticityRecord>[]   = []
        if (showName){
            const nameColumn: TableColumn<ElasticityRecord> = {
                name:  <span title={getScopeHeader()} className="h-16 flex justify-center items-center capitalize">{showTargetProduct ?  `Initial ${getScopeHeader()}` : getScopeHeader()}</span >,
                selector: (row:ElasticityRecord) => getInitialSelectorBasedOnScope(row),
                sortable: false,
                sortField: getInititalFieldNameBasedOnScope(),
                cell: (row:ElasticityRecord) => <div className="inner w-full bg-white flex items-center py-5 h-24">
                     <span className="bg-primary font-bold text-white p-2 rounded-xl  mx-5 w-72 text-xs">{getInitialValueBasedOnScope(row)}</span>
                </div>
            }
            _columns.push(nameColumn)
        }

        if (showTargetProduct){
            const nameColumn: TableColumn<ElasticityRecord> = {
                name:  <span title={getScopeHeader()} className="h-16 flex justify-center items-center">Target {getScopeHeader()}</span >,
                selector: (row:ElasticityRecord) => getTargetSelectorBasedOnScope(row),
                sortable: false,
                cell: (row:ElasticityRecord) => <div className="inner w-full bg-white flex items-center py-5 h-24">
                     <span className="bg-primary font-bold text-white p-2 rounded-xl  mx-5 w-72 text-xs">{getTargeValueBasedOnScope(row)}</span>
                </div>
            }
            _columns.push(nameColumn)
        }

        if(useNewScopeBy && selectedTab === 'cross') {
            const nameColumn: TableColumn<ElasticityRecord> = {
                name:  <span title="Product" className="h-16 flex justify-center items-center">Product</span >,
                selector: (row:ElasticityRecord) => getProductName(row),
                sortable: false,
                cell: (row:ElasticityRecord) => <div className="inner w-full bg-white flex items-center py-5 h-24">
                     <span className="bg-primary font-bold text-white p-2 rounded-xl  mx-5 w-72 text-xs">{getProductName(row)}</span>
                </div>
            }
            _columns.push(nameColumn)
        }

        const averageVolumeColumn: TableColumn<ElasticityRecord> = {
            name: <span title='Average Volume' className="pl-1 pr-1">{getVolumeFieldName(selectedCountry, showTargetProduct)}</span>, 
            selector: (row:ElasticityRecord) => row.averageVolume || '-',
            sortable: true,
            sortField: 'averageVolume',
            cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-30 h-24 flex justify-center items-center">{row.averageVolume?.toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3}) || '-'}</span>
        }
        _columns.push(averageVolumeColumn)
        
        const priceIndexColumn: TableColumn<ElasticityRecord> = {
            name: <span title='Price Index' className="flex justify-center items-center h-16 pr-1">{getPriceFieldName()}</span>, 
            selector: (row:ElasticityRecord) => row.priceIndex || '-',
            sortable: true,
            sortField: 'priceIndex',
            cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-30 h-24 flex justify-center items-center">{row.priceIndex?.toLocaleString("en-US", {maximumFractionDigits: selectedCountry === "KR" ? 0 : 3, minimumFractionDigits: selectedCountry === "KR" ? 0 : 3}) || '-'}</span>
        }
        _columns.push(priceIndexColumn)
        const rangeColumn: TableColumn<ElasticityRecord> = {
            name: <span title='Range' className="pr-1">Range</span>, 
            selector: (row:ElasticityRecord) => row.range || '-',
            sortable: true,
            sortField: 'range',
            cell: (row:ElasticityRecord) => <span className="inner font-bold  text-center min-w-20 h-24 flex justify-center items-center">{row.range || '-'}</span>
        }
        _columns.push(rangeColumn)
        const rsquaredColumn: TableColumn<ElasticityRecord> = {
            name: <span title='R Squared' className="pr-1">RSquared</span>, 
            selector: (row:ElasticityRecord) => row.rsquared || '-',
            sortable: true,
            sortField: 'rsquared',
            cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-30 h-24 flex justify-center items-center">{row.rsquared?.toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3}) || '-'}</span>
        }
        _columns.push(rsquaredColumn)

        const pValueColumn: TableColumn<ElasticityRecord> = {
            name: <span title='P Value' className="pr-1">P Value</span>, 
            selector: (row:ElasticityRecord) => row.pvalue || '-',
            sortable: true,
            sortField: 'pValue',
            cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-30 h-24 flex justify-center items-center">{row.pvalue || '-'}</span>
        }
        _columns.push(pValueColumn)
       
        if (priceType?.avgPrice){
            const avgColumn: TableColumn<ElasticityRecord> = {
                name: <span title='AVG' className="pr-1">Elasticity</span>, 
                selector: (row:ElasticityRecord) => row.averagePrice || '0',
                sortField: 'averagePrice',
                sortable: true,
                cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-20 h-24 flex justify-center items-center">{row.averagePrice?.toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3}) || '-'}</span>
            }
            _columns.push(avgColumn)
        }

        if (priceType?.promoPrice){
            const promoColoumn: TableColumn<ElasticityRecord> = {
                name: <span title='Promo' className="pr-1">Promo</span>, 
                selector: (row:ElasticityRecord) => row.promoPrice || '0',
                sortField: 'promoPrice',
                sortable: true,
                cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-20 h-24 flex justify-center items-center">{row.promoPrice?.toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3}) || '-'}</span>
            }
            _columns.push(promoColoumn)
        }

        if (priceType?.basePrice){
            const baseColumn: TableColumn<ElasticityRecord> = {
                name: <span title='Base' className="pr-1">Base</span>, 
                selector: (row:ElasticityRecord) => row.basePrice || '0',
                sortField: 'basePrice',
                sortable: true,
                cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-20 h-24 flex justify-center items-center">{row.basePrice?.toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3}) || '-'}</span>
            }
            _columns.push(baseColumn)
        }

        const weeksColumn: TableColumn<ElasticityRecord> = {
            name: <span title='n Weeks' className="pr-1"># of weeks</span>, 
            selector: (row:ElasticityRecord) => row.nweeks != null ? row.nweeks :  '-',
            sortable: true,
            sortField: 'nWeeks',
            cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-30 h-24 flex justify-center items-center">{row.nweeks != null ? row.nweeks :  '-'}</span>
        }
        _columns.push(weeksColumn)
        const lastUpdatedColumn: TableColumn<ElasticityRecord> = {
            name: <span title='Last Updated' className="pr-1">Period</span>, 
            selector: (row:ElasticityRecord) => getModelUpdateDateRanage(row),
            sortable: false,
            sortField: 'nWeeks',
            cell: (row:ElasticityRecord) => <span className="inner font-bold text-center min-w-30 h-24 flex justify-center items-center">{getModelUpdateDateRanage(row)}</span>
        }
        _columns.push(lastUpdatedColumn)
        if (showOptions && !useNewScopeBy){
            const viewMoreColumn: TableColumn<ElasticityRecord> = {
                name: <span></span>,
                sortable: false,
                cell: (row: ElasticityRecord) => <div className="flex items-center gap-2 w-full justify-center bg-white h-full">
                    {row.priceIndex ? <div className='block pl-2'><CancelIcon style={cancelIconStyle} onClick={() => handleUnselect(row.initial!, row.target, row.id!)}/></div> : null}
                    {row.viewMore ? <div className="flex px-1 cursor-pointer align-center" onClick={()=> handleViewMore ? handleViewMore(row.id, row.initial!, row.target||"",row.customer||"", row.category||"", row.subCategoryInitial, row.subCategoryTarget, row.manufacturerInitial, row.manufacturerTarget, row.brandInitial, row.brandTarget, row.subBrandInitial, row.subBrandTarget, row.packInitial, row.packTarget) : null}>
                        <svg className="bg-primary w-10 h-10 fill-white rounded-full object-cover" focusable="false" viewBox="0 0 24 24" aria-hidden="true" ><path d="M12 8V4l8 8-8 8v-4H4V8z"></path></svg>
                        <span className="text-primary font-bold text-lg hover:underline">View more</span>
                    </div> : <div className="bg-white h-full" style={{width:'138.53px'}}></div>}
                </div>
            }
            _columns.push(viewMoreColumn)
        }
        
        setColumns(_columns)
    }, [showName, priceType, showOptions]);
    
    const getRowStyleByRange = (range: string) => {

        let bg = ''
        let textColor = ''
        if ( range === 'LOW'){
            bg = '#FBCCCC'
            textColor = '#F03434'
        }else if (range === 'MEDIUM'){
            bg = '#FDF2C5'
            textColor = '#DFB202'
        }else if (range === 'HIGH'){
            bg = '#CBF2DB'
            textColor = '#2ECC71'
        }
        const RowStyle   = {
            backgroundColor:bg,
            color: textColor,
            '&:hover': {
                // backgroundColor: '#ffecec !important',
                backgroundColor: '#eaf5ff !important',
                cursor: 'pointer',
            },
            '&:hover .inner': {
                transform:"scale(1.02)"
            },
        }

        return RowStyle

    }
    
    const getRowStyle: ConditionalStyles<ElasticityRecord>[] = [
        {
            when: (row : ElasticityRecord) => row.range === 'LOW',
            style: getRowStyleByRange('LOW')
        },
        {
            when: (row: ElasticityRecord) => row.range === 'MEDIUM',
            style:  getRowStyleByRange('MEDIUM')
        },
        {
            when: (row: ElasticityRecord) => row.range === 'HIGH',
            style:  getRowStyleByRange('HIGH')
        },
    ]

    const headerRowStyle: CSS.Properties = {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
    }

    const cellStyle: CSS.Properties = {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        justifyContent: 'center'
    }

    const bodyCellStyle = cellStyle
    bodyCellStyle.padding = '0 !important'

    const customStyle: TableStyles = {
        headRow: {
            style: headerRowStyle
        },
        headCells: {
            style: cellStyle
        },
        cells: {style: bodyCellStyle},
        
    }

    return <DataTable columns={Columns} data={ElasticityRecords} 
                sortIcon={<ArrowDownward />} progressPending={IsLoading}
                persistTableHead={true}
                {...{ onSort: customSort}}
                conditionalRowStyles={getRowStyle}  customStyles={customStyle} />
}