import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { useCountriesList } from "./services/queries";
import useStore from "./store";
import { useOktaAuth } from '@okta/okta-react';
import {authenticateWithServer} from './services/queries'
import jwt_decode,{JwtPayload} from "jwt-decode";
import { useFlagStore } from "./store/flagStore"

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Country() {
  const classes = useStyles();
  const [country, setCountry] = React.useState('PE');
  const [open, setOpen] = React.useState(false);

  //store
  const setSelectedCountry = useStore((state) => state.setSelectedCountry);

  const setSelectedBaseLineProduct = useStore(
    (state) => state.setSelectedBaseLineProduct
  );
  const setSelectedBaseLineCustomer = useStore(
    (state) => state.setSelectedBaseLineCustomer
  );
  const setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );

  //------SELECTION: KC PRODUCT------//
  const setSelectedKcProducts = useStore(
    (state) => state.setSelectedKcProducts
  );

  //------SELECTION: TARGET PRODUCT------//
  const setSelectedTargetProducts = useStore(
    (state) => state.setSelectedTargetProducts
  );

  //------SELECTION: OWN PRODUCT------//
  const setSelectedOwnProducts = useStore(
    (state) => state.setSelectedOwnProducts
  );

  //------SELECTION: PRODUCT LEVEL------//
  const setSelectedProductLevel = useStore(
    (state) => state.setSelectedProductLevel
  );

  //------SELECTION: SCOPE LEVEL------//
  const setSelectedScopeLevel = useStore(
    (state) => state.setSelectedScopeLevel
  );

  //------SELECTION: CROSS PRODUCT------//
  const setRowData = useStore((state) => state.setRowData);

  const setSelectedBaseLineCustomerNew = useStore(
    (state) => state.setSelectedBaseLineCustomerNew
  );
  const setSelectedBaseLineProductNew = useStore(
    (state) => state.setSelectedBaseLineProductNew
  );
  const setSelectedCustomerValues = useStore(
    (state) => state.setSelectedCustomerValues
  );
  const setSelectedProductValues = useStore(
    (state) => state.setSelectedProductValues
  );
  const setSelectedCategoryLevel = useStore(
    (state) => state.setSelectedCategoryLevel
  );
  const setPriceType = useStore(
    (state) => state.setPriceType
  );
  const setSelectedSource = useStore(
    (state) => state.setSelectedSource
  );
  const setDisplayTopItems = useStore(
    (state) => state.setDisplayTopItems
);

  const setGroupByValue = useStore((state) => state.setGroupByValue);

 

  let selectedCountry = useStore((state) => state.selectedCountry);
  const { isLoading: isCountryData, data: countryData } = useCountriesList();
  
  const [countryService, setCountryService] = useState([]);

  const setCountryAndRoles = useStore((state) => state.setCountryAndRoles);

  const elasticityPrice = {
    avgPrice: true,
    promoPrice: false,
    basePrice: false
  }


  const defaultCountries = {
    PE: "Peru",
    KR: "South Korea",
    UK: "United Kingdom",
    XX: "Test",
    XY: "TestDay"
  };

  const [countries, setCountries] = useState({})

  const validGroups = [
    'KR_Admin',
    'KR_Read_Users',
    'PE_Admin',
    'PE_Read_Users',
    'UK_Admin',
    'UK_Read_Users',
  ]

  let groupPrefix = process.env.REACT_APP_OKTA_GROUP_PREFIX

  const {oktaAuth, authState } = useOktaAuth();
  const token = useStore.getState().apiToken

  const setLoginState = useStore((state) => state.setLoginState);
  const setAuthError = useStore((state) => state.setAuthError);
  const setAPIToken = useStore((state) => state.setAPIToken);
  const setOktaAccessToken = useStore((state) => state.setOktaAccessToken);
  const setOktaIdToken = useStore((state) => state.setOktaIdToken);
  const setViewMonthly = useStore((state) => state.setViewMonthly);
  const setSelectedSubCategoryLevel = useStore((state) => state.setSelectedSubCategoryLevel)
  const setDefaultSubCategory = useStore((state) => state.setDefaultSubCategory)

  useEffect(() => {
    const validateRolesAndPermissions = async () => {
      let availableCountries = defaultCountries
      if (countryData){
        availableCountries =  Object.fromEntries(
          Object.entries(defaultCountries).filter(([k,v]) => countryData.includes(k))
        )
      }
      if (authState && authState.isAuthenticated){
        if (authState.idToken.claims.groups){
          let accessToCountries = []
            if (authState.idToken.claims.groups.includes(`${groupPrefix}Contributor`)){
              setCountries(availableCountries)
              const countriesForDevs = {
                'PE' : ['Admin'],
                'UK' : ['Admin'],
                'KR' : ['Admin'],
              }
              if (availableCountries['XX']){
                countriesForDevs['XX'] = ['Admin']
              }
              if (availableCountries['XY']){
                countriesForDevs['XY'] = ['Admin']
              }
              setCountryAndRoles(countriesForDevs)
              accessToCountries = Object.keys(availableCountries)
            }else{
              const accessGroups = []
              if (availableCountries['XX']){
                delete availableCountries['XX']
              }
              if (availableCountries['XY']){
                delete availableCountries['XY']
              }
              for (const userGroup of authState.idToken.claims.groups) {
                if (validGroups.map(_group=> `${groupPrefix}${_group}`).includes(userGroup)){
                  accessGroups.push(userGroup)
                }
              }

              if (accessGroups.length > 0){
                let codeRoleMap = {}
                for (const _group of accessGroups) {
                  const codes = _group.replace(groupPrefix,'').split('_')
                  if (!codeRoleMap[codes[0]]){
                    codeRoleMap[codes[0]] = []
                  }
                  if (codes.length  === 2 ){
                    codeRoleMap[codes[0]].push(codes[1])
                  }else  if (codes.length  === 3 ){
                    codeRoleMap[codes[0]].push(`${codes[1]} ${codes[2]}}`)
                  }
                }
                
                setCountryAndRoles(codeRoleMap)
                accessToCountries = Object.keys(availableCountries).filter(x=> codeRoleMap[x])
                const filtered = accessToCountries.reduce((obj, key) => ({ ...obj, [key]: availableCountries[key] }), {});
                setCountries(filtered)
              }
            }

            if (accessToCountries.length > 0 ){
              if (token){
                const decoded = jwt_decode(token)
                if (decoded){
                  if ((decoded.exp && Date.now() >= (decoded.exp * 1000) )){
                    setOktaAccessToken(authState.accessToken.accessToken)
                    setOktaIdToken(authState.idToken.idToken)
                    const result = await authenticateWithServer(authState.accessToken.accessToken, authState.idToken.idToken)
                    if (result.error || !result.jwtToken){
                        setLoginState(false)
                        setAuthError(result.error || 'Invalid login')
                        setAPIToken('')
                    }else{
                        setLoginState(true)
                        setAuthError('')
                        setAPIToken(result.jwtToken)
                    }
                  }
                }
              }
              if (accessToCountries.length === 1){
                setSelectedCountry(accessToCountries[0])
                setCountry(accessToCountries[0])
              }
            }else{
              setLoginState(false)
              setAuthError('Unauthorized access')
              setAPIToken('')
            }
           
        }
     }else{
      setCountries({})
     }
    }
    validateRolesAndPermissions()
    if (selectedCountry){
      setCountry(selectedCountry)
    }

  }, [authState,isCountryData])


  useEffect(() => {
    console.log("SELECTED COUNTRY", selectedCountry);
    console.log("isCountryData", isCountryData);
    console.log("COUNTRY DATA", countryData);

    if (!isCountryData) {
      setSelectedBaseLineProduct("");
      setSelectedBaseLineCustomer("");
      //setSelectedBaseLineCustomerNew('');
      //setSelectedBaseLineProductNew('');
      // setSelectedCountry("");
      // setCountry("");
      setSelectedBaseLineFilter("");
      setSelectedKcProducts("");
      setSelectedTargetProducts("");
      setSelectedOwnProducts("");
      setSelectedProductLevel("ean");
      setRowData([]);
      setSelectedCustomerValues([]);
      setSelectedProductValues([]);
      setSelectedCategoryLevel("");
      setPriceType(elasticityPrice);
      setSelectedSource([]);
      setViewMonthly("monthly");
      setSelectedSubCategoryLevel([]);
      setDefaultSubCategory([]);

      setCountryService(countryData);
    }
  }, [isCountryData]);

  let getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };
  const handleChange = (event) => {
    setCountry(event.target.value);
    setSelectedBaseLineProduct("");
    setSelectedBaseLineCustomer("");
    setSelectedBaseLineCustomerNew('');
    setSelectedBaseLineProductNew('');
    setSelectedBaseLineFilter("");
    setSelectedKcProducts("");
    setSelectedTargetProducts("");
    setSelectedOwnProducts("");
    setSelectedProductLevel("ean");
    setRowData([]);
    setSelectedCustomerValues([]);
    setSelectedProductValues([]);
    setSelectedCategoryLevel("");
    setPriceType(elasticityPrice);
    setSelectedSource([]);
    setDisplayTopItems( event.target.value === 'KR' ? 3 : 0 );
    setViewMonthly("monthly");
    setGroupByValue('Customer');
    setSelectedSubCategoryLevel([]);
    setDefaultSubCategory([]);

    setSelectedCountry(event.target.value);
    console.log("event.target11", event.target);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setCountry(selectedCountry);
  }, [selectedCountry]); 


  const getDefaultCountry = () => {
    if (selectedCountry){
     return selectedCountry
    }else if (country){
     return country 
    }
    return 0
  
  }

  const onViewMore = useFlagStore(store => store.onViewMore)


  return (
      <>
      {
        onViewMore
        ? <div className="mt-6 p-x-4 underline p-y-1 font-semibold">{countries[selectedCountry]}</div>
        : <div style={{ display: "inline" }}> 
            <FormControl className={classes.formControl}>
            <InputLabel id="country-list-label"></InputLabel>
            <Select
              labelId="country-list-label"
              id="country-list"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              //value={countries[country] ? countries[country] : 0}
              value = {getDefaultCountry()}
              onChange={handleChange}
            >
              <MenuItem value={0} disabled>
                <em>Select Country</em>
              </MenuItem>

                {
                  countries && Object.keys(countries).length > 0
                  ? Object.keys(countries).map((item,idx) => (
                    <MenuItem  key={item} className="countryItem" id={`countryItem_${item}`} value={item}>{countries[item]}</MenuItem>
                  ))
                  :  defaultCountries && Object.keys(defaultCountries).length > 0
                      ? Object.keys(defaultCountries).map((item,idx) => (
                        <MenuItem  key={item} className="countryItem" id={`countryItem_${item}`} value={item}>{defaultCountries[item]}</MenuItem>
                      ))
                      : null
                }

            
            </Select>
          </FormControl>
        </div>

      }
    </>
      
  );
}