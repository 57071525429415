import {useState}  from 'react';
import { Grid, Box} from "@mui/material";
import { useRef } from "react";

import { PromoNameInput } from './PromoNameInput';
import { PromoPeriodSelector } from './PromoPeriodSelector';
import { PromoSellInPriceInput } from './PromoSellInPriceInput';
import { PromoSellOutPriceInput } from './PromoSellOutPriceInput';
import { PromoVolumeInput } from './PromoVoIumeInput';
import { CustomerSelectionInput } from './CustomerSelectionInput';
import { ProductSelectionInput } from './ProductSelectionInput';
import { AdditionalFundingInput } from './AdditionalFundingInput';
import { FixedCostInput } from './FixedCost';
import { ProductGridButton } from './ProductGridButton';
import { useInputMethods } from "../../../../services/queries";
import useStore from "../../../../store";

export const InputSectionLayout = ({ id, promoData, setPromoNameError, promoNameError }: any) => {
    
    let selectedCountry = useStore((state) => state.selectedCountry);
    const { isLoading, data: inputMethods } = useInputMethods(`${selectedCountry}`);

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container className="mt-2">
                <Grid item xs={8}>
                    <PromoNameInput id={id} promoData={promoData} setPromoNameError={setPromoNameError} promoNameError={promoNameError}/>
                </Grid>                
            </Grid>
            <Grid container className="mt-2">
                <Grid item xs={6}>
                    <CustomerSelectionInput id={id} promoData={promoData}/>
                </Grid>                
                <Grid item xs={6}>
                    <ProductSelectionInput id={id} promoData={promoData}/>
                </Grid>
            </Grid>
            <PromoPeriodSelector id={id} promoData={promoData}/>
            <div><span style={{fontWeight:'bold',textDecoration:'underline',marginLeft:'10px'}}> Promotion Entry Method</span></div>
            <PromoSellInPriceInput id={id} promoData={promoData} promoSellInPriceType={inputMethods &&inputMethods.sellInPrice?inputMethods.sellInPrice:{}} />
            <PromoSellOutPriceInput id={id} promoData={promoData} promoSellOutPriceTypes={inputMethods &&inputMethods.sellOutPrice?inputMethods.sellOutPrice:{}} />
            <PromoVolumeInput id={id} promoData={promoData} PromoVolumeTypes={inputMethods &&inputMethods.volume?inputMethods.volume:{}} />
            <AdditionalFundingInput id={id} promoData={promoData}/>
            <FixedCostInput id={id} promoData={promoData}/>
            <ProductGridButton id={id} setPromoNameError={setPromoNameError}/>
        </Box>
    );
};