import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {map, isEmpty, uniq} from 'lodash';

import useStore from "../store";
import { isSelected, putAll, getProductSelectorHierarchy, getUpdatedHierarchy } from "./utils";
import ItemSearch from "./Widgets/SearchWidget/ItemSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '600px',
    width: '450px',
    zIndex: 5,
    backgroundColor: 'white',
    overflowY: 'auto',
    direction: 'rtl',
    padding: '1rem',
    boxShadow: '5px 5px 8px #d6d9dc',
    marginTop: '5px',
    borderRadius: '5px',
    marginBottom:'20px'
  },
  paperDiv: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
    },
  },
  container: {
    backgroundColor: "#F6F8FC",
    height: "100vh",
  },
  searchBar: {
    display: "inline-block",
    paddingRight: "2.5rem",
  },
  searchContainer: {
    background: "#F6F8FC",
    width: "100%",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    color: "black",
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  autoCompleteSearch: {
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    background: "#FFFFFF",
  },
  gridContainer: {
    background: "#F6F8FC",
  },
  showCheckBox: {},
  appTab: {
    fontSize: "15px",
  },
  hide: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "650",
    opacity: "0.70",
  },
  open: {
    fontSize: "14px",
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

let display = {
  ean: {
    data: [
      { name: "Manufacturer", value: "manufacturer" },
      { name: "Brands", value: "brand" },
      { name: "Sub Brands", value: "subBrand" },
      { name: "Pack", value: "pack" },
      { name: "EAN", value: "ean" },
    ],
  },
  pack: {
    data: [
      { name: "Manufacturer", value: "manufacturer" },
      { name: "Brands", value: "brand" },
      { name: "Sub Brands", value: "subBrand" },
      { name: "Pack", value: "pack" },
    ],
  },
  tier: {
    data: [
      { name: "Manufacturer", value: "manufacturer" },
      { name: "Tier", value: "tier" },
    ],
  },
  subBrand: {
    data: [
      { name: "Manufacturer", value: "manufacturer" },
      { name: "Brands", value: "brand" },
      { name: "Sub Brands", value: "subBrand" },
    ],
  },
  brand: {
    data: [
      { name: "Manufacturer", value: "manufacturer" },
      { name: "Brands", value: "brand" },
    ],
  },
  subCategory: {
    data: [
      { name: "Manufacturer", value: "manufacturer" },
      { name: "Sub Categories", value: "subCategory" }
    ],
  },
};

const NestedSearch = (props) => {
  const classes = useStyles();

  const initial = {
    subCategory: false,
    manufacturer: false,
    brand: false,
    subBrand: false,
    tier: false,
    pack: false,
    ean: false,
  };
  
  const initialValue = {
    subCategory: [],
    manufacturer: [],
    brand: [],
    subBrand: [],
    tier: [],
    pack: [],
    ean: [],
  };
  
  const [initialState, setInitialState] = useState({});
  const [initialParentState, setInitialParentState] = useState(initial);
  const [kcProductData, setkcProductData] = useState(props.data);

  let selectedCategoryLevel = useStore((state) => state.selectedCategoryLevel);
  let selectedSubCategoryLevel = useStore((state) => state.selectedSubCategoryLevel);
  let selectedProductLevel = useStore((state) => state.selectedProductLevel ? state.selectedProductLevel : "ean");
  let initialProductCheckBox = useStore((state) => state.initialProductCheckBox);
  const setInitialProductCheckBox = useStore((state) => state.setInitialProductCheckBox);
  let targetProductCheckBox = useStore((state) => state.targetProductCheckBox);
  const setTargetProductCheckBox = useStore((state) => state.setTargetProductCheckBox);
  let selectedCountry = useStore((state) => state.selectedCountry);
  let selectedInitialProductState = useStore((state) => state.selectedInitialProductState);
  const setSelectedInitialProductState = useStore((state) => state.setSelectedInitialProductState);
  let selectedTargetProductState = useStore((state) => state.selectedTargetProductState);
  const setSelectedTargetProductState = useStore((state) => state.setSelectedTargetProductState);
  let removeItem = useStore((state) => state.removeItem);
  const setRemoveItem = useStore((state) => state.setRemoveItem);

  useEffect(() => {
    setInitialState(initial);
    setInitialParentState(initial); 
  }, [])

  useEffect(() => {
    if (!props.previouslySelected || !props.previouslySelected.length) {
      let Values = calculateInitial();
      updateState(Values);
      props.dataFor === "initial" ? setInitialProductCheckBox(initial) : setTargetProductCheckBox(initial);
    }
  }, [props]);

  useEffect(() => {
    if(props.clear){
      let Values=calculateInitial();
      setSelectedInitialProductState(Values);
      setSelectedTargetProductState(Values);
      props.dataFor === "initial" ? setInitialProductCheckBox(initial) : setTargetProductCheckBox(initial);
      props.handleClear();
    } 
    if(props.tabName==="own" && removeItem?.length)
    {
      let state=selectedTargetProductState;
      let rowDataNew=[];
      state[selectedProductLevel].map(row => rowDataNew.push({name: row.name, isChecked: removeItem.includes(row.name) ? false : row.isChecked }));  
      state[selectedProductLevel]=rowDataNew;
      updateState(state);
      setRemoveItem([]);
    }
  }, [props])

  useEffect(() => {
    if(!props.previouslySelected.length) {
      updateHierarchyOnSubCategoryChange();
      setInitialState(initial);
      if(props.dataFor === "initial" ){
        setInitialProductCheckBox(initial);
      }
      if(props.dataFor !== "initial" ){
        setTargetProductCheckBox(initial);
      }
    }
  }, [selectedSubCategoryLevel])

  const handleOpen = (x) => {
    setInitialState({
      ...initialState,
      [x]: !initialState[x],
    });
  }

  const transformDataUnique = (values) => {
    let arr = ["subCategory", "manufacturer", "brand", "subBrand", "tier", "pack", "ean"];
    arr.map((val) => {
      values[val] = [
        ...new Map(values[val].map((item) => [item["name"], item])).values(),
      ];
    });
    arr.map((val) => {
      values[val].sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
      });
    });

    return values;
  }

  const updateState = (values) => {
    if (props.dataFor === "initial") {
      setSelectedInitialProductState({ ...values });
      
    } else {
      setSelectedTargetProductState({ ...values });
    }
  }

  const getHierarchy = () => {
    switch (selectedProductLevel) {
      case "ean":
        return "EAN";
      case "pack":
        return "PACK";
      case "tier":
        return "TIER";
      case "subBrand":
        return "SUB_BRAND";
      case "brand":
        return "BRAND";
      default:
        return "SUB_CATEGORY";
    }
  }

  function calculateInitial () {
    let Values = { ...initialValue };

    if(selectedProductLevel === 'subCategory' && kcProductData && kcProductData.categories[selectedCategoryLevel]?.manufacturers){
      if(isEmpty(selectedSubCategoryLevel)) {
        Object.entries(
          kcProductData.categories[selectedCategoryLevel]?.manufacturers
        ).map(([key,  manufacturer ]) => {
          Values["manufacturer"].push({ name: key, isChecked: false })
          manufacturer!==null && Object.entries(manufacturer).map(
            ([key3, subcategories]) => {
              map(subcategories, subcategory => {
                Values["subCategory"].push({ name: subcategory, isChecked: false })
              })
              return
            }
          )
        })
      } else {
        Object.entries(
          kcProductData.categories[selectedCategoryLevel]?.manufacturers
        ).map(([key, manufacturer ]) => {
          const hasSelectedSubCategory = selectedSubCategoryLevel.every(elem => (manufacturer.subCategories).includes(elem));
          if(hasSelectedSubCategory){
            Values["manufacturer"].push({ name: key, isChecked: false })
          }
          manufacturer!==null && Object.entries(manufacturer).map(
            ([key3, subcategories]) => {
              map(subcategories, subcategory => {
                  if(selectedSubCategoryLevel.includes(subcategory)) {
                    Values["subCategory"].push({ name: subcategory, isChecked: false })
                  }
                })
                return
              }
            )
        })
      }
    } else if(kcProductData && kcProductData?.categories[selectedCategoryLevel]?.subCategories){
      Object.entries(
        kcProductData.categories[selectedCategoryLevel]?.subCategories
      ).map(
        ([key, subcategory]) => {
          if(selectedSubCategoryLevel.length === 0 || selectedSubCategoryLevel.includes(key)) {
            Values["subCategory"].push({ name: key, isChecked: false })
            subcategory!==null && Object.entries(subcategory?.manufacturers).map(
              ([key3, manufacturer]) => {
                Values["manufacturer"].push({ name: key3, isChecked: false })
                if (selectedProductLevel === 'brand'){
                  manufacturer!==null && Object.keys(manufacturer?.brands).map(x=> {
                    Values["brand"].push({ name: x, isChecked: false })
                  })
                  return
                }
                manufacturer!==null && Object.entries(manufacturer?.brands).map(
                  ([key4, brand]) => {
                    Values["brand"].push({ name: key4, isChecked: false })
                    if (selectedProductLevel === 'subBrand'){
                      brand!==null && Object.keys(brand?.subBrands).map(x=> {
                        Values["subBrand"].push({ name: x, isChecked: false })
                      })
                      return
                    }
                    brand!==null && Object.entries(brand?.subBrands).map(
                      ([key5, subBrand]) => {
                        Values["subBrand"].push({ name: key5, isChecked: false })
                        if (selectedProductLevel === 'tier'){
                          subBrand!==null && Object.keys(subBrand?.tiers).map(x=> {
                            Values["tier"].push({ name: x, isChecked: false })
                          })
                          return
                        }
                        if (subBrand!==null && subBrand.tiers){
                          subBrand!==null && Object.entries(subBrand?.tiers).map(([key6, tier]) =>
                            tier!==null && Object.entries(tier?.packs).map(
                              ([key7, pack]) => (
                                Values["pack"].push({ name: key7, isChecked: false }),
                                pack!==null && pack.map((x) =>
                                  Values["ean"].push({ name: x, isChecked: false })
                                )
                              )
                            )
                          )
                        }
                      }
                    )
                  }
                )
              }
            )
          }
        }
      );
    }
    Values = transformDataUnique(Values);
    return Values;
  }

  const getDepthValue = (level) => {
    switch (level) {
      case 'subCategory':
        return 1;
      case 'manufacturer':
        return 2;
      case 'brand':
        return 3;
      case 'subBrand':
        return 4;
      case 'tier':
        return 5;
      case 'pack':
        return 6;
      default:
        return 7;
    }
  };

  const updateHierarchyOnSubCategoryChange = () => {
    if(kcProductData && kcProductData?.categories[selectedCategoryLevel]){
      const availableSubCategories = [];
      selectedProductLevel === 'subCategory' ? Object.entries(kcProductData.categories[selectedCategoryLevel].manufacturers).forEach((data) => {
        data[1].subCategories.map(subCat => {
          !availableSubCategories.includes(subCat) && availableSubCategories.push(subCat);
        })
      }) : Object.entries(kcProductData.categories[selectedCategoryLevel].subCategories).forEach(data => {
        availableSubCategories.push(data[0])
      });

      const hierarchy = selectedProductLevel === 'subCategory' ? Object.entries(kcProductData.categories[selectedCategoryLevel].manufacturers)
        : Object.entries(kcProductData.categories[selectedCategoryLevel].subCategories);
      const selectedHierarchy = props.dataFor==="initial"?{ ...selectedInitialProductState }:{...selectedTargetProductState};

      const updatedHierarchy = getUpdatedHierarchy(hierarchy, selectedHierarchy, selectedSubCategoryLevel, uniq(availableSubCategories), selectedProductLevel);
      const values = transformDataUnique(updatedHierarchy);
      if (props.dataFor === "initial") {
        setSelectedInitialProductState({ ...values });
      } else {
        setSelectedTargetProductState({ ...values });
      }
    }
  }

  //Function to updated selector values 
  const updateSelectedLevel = (level) => {
    let values = props.dataFor==="initial"?{ ...selectedInitialProductState }:{...selectedTargetProductState};
    let depth=getDepthValue(level);

    if (level === 'ean')
      return;
    // original product hierarchy
    let hierarchy = [];
    if(kcProductData && kcProductData.categories[selectedCategoryLevel]) {
      if(selectedProductLevel === 'subCategory'){
        hierarchy = Object.entries(kcProductData.categories[selectedCategoryLevel].manufacturers);
      } else {
        hierarchy = Object.entries(kcProductData.categories[selectedCategoryLevel].subCategories);
      }
      // selected product selector hierarchy
      const selectedHierarchy = getProductSelectorHierarchy(hierarchy, values, depth, selectedSubCategoryLevel, selectedProductLevel);
  
      // get unique data by sorting
      values = transformDataUnique(selectedHierarchy);
      updateState(values);
    }
  };

  const generateUrl = () => {
    let values = props.dataFor === "initial" ? { ...selectedInitialProductState }:{ ...selectedTargetProductState };
    let arr = ["manufacturer", "brand", "subBrand", "tier", "pack", "ean"];
    let selectedManufacturer= [];
    let selectedBrand= [];
    let selectedSubBrands = [];
    let selectedTiers = [];
    let selectedPacks = [];

    for(let i=0;i<arr.length;i++) {
      switch(arr[i]) {
        case "manufacturer": 
          selectedManufacturer = values[arr[i]].filter(isSelected).map(putAll);
          break;
        case "brand": 
          selectedBrand = values[arr[i]].filter(isSelected).map(putAll);
          break;
        case "subBrand":
          selectedSubBrands = values[arr[i]].filter(isSelected).map(putAll);
            break;
        case "tier":
          selectedTiers = values[arr[i]].filter(isSelected).map(putAll);
            break;
        case "pack": 
          selectedPacks = values[arr[i]].filter(isSelected).map(putAll);
            break;
        default:
          break;
      }
    }
    let selectedNodes = values[selectedProductLevel].filter(isSelected).map(putAll);

    let reformatKcStr = "";
    let reformatKcStrNew = "";
    let reformatKcStrNewT = "";
    let crossT = "";
    let crossI = "";
    let hierarchy = getHierarchy();

    selectedNodes.map((val, i) => {
      if (i <= 0) {
        reformatKcStr += `country=${selectedCountry}&nodeValues=${encodeURIComponent(val)}`;
        reformatKcStrNew += `?country=${selectedCountry}&initialNodeValues=${encodeURIComponent(val)}`;
        crossI += `country=${selectedCountry}&initialNodeValues=${encodeURIComponent(val)}`;
        crossT += `&targetNodeValues=${encodeURIComponent(val)}`;
        reformatKcStrNewT += `&targetNodeValues=${encodeURIComponent(val)}`;
      } else {
        reformatKcStr += `&nodeValues=${encodeURIComponent(val)}`;
        reformatKcStrNew += `&initialNodeValues=${encodeURIComponent(val)}`;
        reformatKcStrNewT += `&targetNodeValues=${encodeURIComponent(val)}`;
      }
    });
    reformatKcStr += `&hierarchyLevel=${hierarchy}`;
    reformatKcStrNewT += `&hierarchyLevel=${hierarchy}`;
    crossT += `&hierarchyLevel=${hierarchy}`;

    return {
      reformatKcStr,
      reformatKcStrNew,
      reformatKcStrNewT,
      crossI,
      crossT,
      selectedManufacturer,
      selectedBrand,
      selectedSubBrands,
      selectedTiers,
      selectedPacks
    };
  }

  const unCheckBelowParentLevels = (x) => {
    let toggleParentState = props.dataFor === "initial" ? {
      ...initialProductCheckBox
    } : {
      ...targetProductCheckBox
    };
    let flagP = false;
    for (let [key, value] of Object.entries(toggleParentState)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentState[key] = false;
    }
    props.dataFor === "initial" ? setInitialProductCheckBox({
      ...toggleParentState
    }) : setTargetProductCheckBox({
      ...toggleParentState
    });
  };

  const onSelection = (data) => {
    let values = props.dataFor === "initial" ? selectedInitialProductState : selectedTargetProductState;
    let x = data[0]; //parent level name:subcat
    let y = data[1]; //value in subcat ex-subcat1
    let selectedArr = [];
    values[x].map(function (item) {
      if (item.name === y) item.isChecked = !item.isChecked;

      if (item.isChecked) {
        selectedArr.push(item.name);
      }
    });

    updateState(values);

    if (selectedArr.length === values[x].length) {
      props.dataFor === "initial" ? setInitialProductCheckBox({
        ...initialProductCheckBox,
        [x]: true,
      }) : setTargetProductCheckBox({
        ...targetProductCheckBox,
        [x]: true,
      });
    } else {
      props.dataFor === "initial" ? setInitialProductCheckBox({
        ...initialProductCheckBox,
        [x]: false,
      }) : setTargetProductCheckBox({
        ...targetProductCheckBox,
        [x]: false,
      });

      if(selectedProductLevel !== 'subCategory') {
        unCheckBelowParentLevels(x);
      }
    }

    updateSelectedLevel(x);

    if (selectedProductLevel === x) {
      let url = generateUrl();
      props.handleSelectedValues({
        selectedKcArr: selectedArr,
        apiUrl: url.reformatKcStr,
        correlationAnalysisApiUrl: url.reformatKcStrNew,
        correlationAnalysisApiUrlT: url.reformatKcStrNewT,
        crossAnalysisApiUrl: url.crossI,
        crossAnalysisApiUrlT: url.crossT,
        dataFor: props.dataFor,
        selectedBrand:  url.selectedBrand,
        selectedManufacturer:  url.selectedManufacturer,
        selectedSubBrands: url.selectedSubBrands,
        selectedTiers: url.selectedTiers,
        selectedPacks: url.selectedPacks,
      });
    }
  };

  const SelectAllChildren = (parentLevel) => {
    let values = props.dataFor==="initial"?{ ...selectedInitialProductState }:{...selectedTargetProductState};
    let selectedArr = [];

    values[parentLevel].map(
      (x) => ((x.isChecked = true), selectedArr.push(x.name))
    );

    let size = display[selectedProductLevel].data.length;
    if (display[selectedProductLevel].data[size - 1].value === parentLevel) {
      let url = generateUrl(selectedArr);
      props.handleSelectedValues({
        selectedKcArr: selectedArr,
        apiUrl: url.reformatKcStr,
        correlationAnalysisApiUrl: url.reformatKcStrNew,
        correlationAnalysisApiUrlT: url.reformatKcStrNewT,
        crossAnalysisApiUrl: url.reformatKcStrNew,
        crossAnalysisApiUrlT: url.reformatKcStrNewT,
        dataFor: props.dataFor,
        selectedBrand:  url.selectedBrand,
        selectedManufacturer:  url.selectedManufacturer,
        selectedSubBrands: url.selectedSubBrands,
        selectedTiers: url.selectedTiers,
        selectedPacks: url.selectedPacks,
      });
    }
    updateSelectedLevel(parentLevel);
  }

  const DeSelectAllChildren = (parentLevel) => {
    let values = props.dataFor==="initial" ? { ...selectedInitialProductState }:{...selectedTargetProductState};
    values[parentLevel].map((x) => (x.isChecked = false));
    updateSelectedLevel(parentLevel);
    if(parentLevel === selectedProductLevel) unCheckBelowParentLevels(parentLevel);
    if(props.dataFor === 'initial' && parentLevel === selectedProductLevel) props.handleInitialDeselectAll();
    if(props.dataFor==='target' && parentLevel === selectedProductLevel) props.handleTargetDeselectAll();
    if(props.dataFor ==='own' && parentLevel === selectedProductLevel) props.handleOwnDeselectAll();
  }

  const handleParentClick = (e) => {
    let x = e.target.value;
    props.dataFor === "initial"
      ? setInitialProductCheckBox({ ...initialProductCheckBox, [x]: !initialProductCheckBox[x] })
      : setTargetProductCheckBox({ ...targetProductCheckBox, [x]: !targetProductCheckBox[x] });

    if (props.dataFor === "initial") {
      !initialProductCheckBox[x] ? SelectAllChildren(x) : DeSelectAllChildren(x);
    };
    if (props.dataFor === "target") {
      !targetProductCheckBox[x] ? SelectAllChildren(x) : DeSelectAllChildren(x);
    };
    if(props.dataFor === "own"){
      !targetProductCheckBox[x] ? SelectAllChildren(x) : DeSelectAllChildren(x);
    }
  }

  return (
    <div className={`${classes.root} z-50`} id={props.id || null} onMouseLeave={() => props.onMouseLeave ? props.onMouseLeave() : null}>
      <div style={{ direction: 'ltr' }}>
      {Object.entries(display).map(([key, item]) => (
        key === selectedProductLevel
        ?  <>
          {
            item.data.map((x) => (
              <div key={x.value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className={`selector_${x.value} w-3 cursor-pointer`}
                    onClick={() => handleOpen(x.value)}
                    style={{ cursor: "pointer", justifyContent: "center" }}
                  >
                    {initialState[x.value] ? "-" : "+"}
                  </div>
                  {props.tabName === "cross" &&
                  x.value === item.data[item.data.length - 1].value ? (
                    " "
                  ) : (
                    <input
                      type="checkbox"
                      checked={props.dataFor==="initial"?initialProductCheckBox[x.value]:targetProductCheckBox[x.value]}
                      value={x.value || ''}
                      onChange={handleParentClick}
                      className="ml-1"
                    />
                  )}
                  <label
                    className={
                      `${initialState[x.value] ? classes.open : classes.hide} levelHeader ml-1 cursor-pointer`
                    }
                  >
                    {x.name}
                  </label>
                </div>
                <div style={{ marginLeft: "20px" }}>           
                  {initialState[x.value] && (
                    <ItemSearch
                      values={props.dataFor==="initial"?selectedInitialProductState[x.value]:selectedTargetProductState[x.value]}
                      parentv={x.value}
                      onSelection={(e) => {
                        onSelection(e);
                      }}
                      id={`searcher_${x.value}`}
                      
                    />
                  )}
                </div>
              </div>
            ))}
        </>
        : null
      ))}
      </div>
    </div>
  );
};

export default NestedSearch;
