import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useStore from './store';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '600px',
    width: '450px',
    backgroundColor: 'white',
    overflowY: 'auto',
    padding: '1rem',
    boxShadow: '5px 5px 8px #d6d9dc',
    marginTop: '5px',
    borderRadius: '5px',
  },
  iconButton: {
    float: 'right',
    padding: 10,
    color: theme.palette.primary.main,
  },
  searchInput: {
    outline: 'none',
    border: 'none',
  },
  searchBox: {
    width: '80%',
    margin: '10px 20px 0px 10px',
    display: 'flex',
    position: 'relative',
    borderBottom: '1px solid #B8B8B8',
  },
  filterItem: {
    fontSize: '15px',
    color: theme.palette.primary.main,
    opacity: '0.75',
    marginLeft: '30px',
    fontWeight: '500',
    maxHeight: '100px',
    overflowY: 'auto',
    padding: '3px 3px 0px 3px',
  },
  item: {
    width: '450px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function SearchBar(props) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const [query, setQuery] = useState('');
  let selectedEventIdState = useStore((state) => state.selectedEventIdState);
  const setSelectedEventIdState = useStore(
    (state) => state.setSelectedEventIdState
  );
  const setSelectedEventIdValues = useStore(
    (state) => state.setSelectedEventIdValues
  );
  const setSelectedEventId = useStore((state) => state.setSelectedEventId);
  const setSelectedEventIdNew = useStore(
    (state) => state.setSelectedEventIdNew
  );

  useEffect(() => {
    setData(selectedEventIdState);
    setFilteredList(selectedEventIdState);
  }, []);

  const filterBySearch = (event) => {
    let query = event.target.value;
    setQuery(query);
  };

  const handleSelect = (e) => {
    let eventValues = [...selectedEventIdState];
    let checkedValues = [];
    let eventUrl = '';
    for (let event of eventValues) {
      if (event.name === e.target.name) {
        event.isChecked = !event.isChecked;
      }
      if (event.isChecked) {
        checkedValues.push(event.name);
        eventUrl += `&eventIds=${event.name}`;
      }
    }
    setSelectedEventIdState(eventValues);
    setSelectedEventIdValues(checkedValues);
    setSelectedEventId(eventUrl);
    setSelectedEventIdNew(eventUrl);
  };
  const FilteredValues = () => {
    var updatedList = query ? [...data] : [];

    updatedList = updatedList.filter((item) => {
      if (item.name.toLowerCase().includes(query.toLowerCase())) return item;
    });

    return (query ? updatedList : filteredList).map((item, index) => (
      <div key={index} className='flex items-center'>
        <input
          type={props.isRadio ? 'radio' : 'checkbox'}
          name={item.name}
          checked={item.isChecked}
          onChange={handleSelect}
          className='checker pt-1'
        />
        <Tooltip
          title={<Typography fontSize={20}>{item.name}</Typography>}
          disableFocusListener
          disableTouchListener
        >
          <div className={`${classes.item} checkerName pl-1`}>{item.name}</div>
        </Tooltip>
      </div>
    ));
  };

  return (
    <div className={classes.container} id={props.id || null}>
      <div className={classes.searchBox}>
        <IconButton className={classes.iconButton}>
          <SearchIcon />
        </IconButton>
        <input
          className={classes.searchInput}
          type='text'
          placeholder='Search...'
          onChange={filterBySearch}
        />
      </div>

      <div className={classes.filterItem}>
        <FilteredValues />
      </div>
    </div>
  );
}

export default SearchBar;
