
import React, { useState } from 'react';
import AppTabs from "./AppTabs"
import Paper from "@material-ui/core/Paper";


export default function PostRoiTable(props) {
    const [subTabs] = useState([
        { index: 0, tag: "Promo", value: "Promo", id:'Promo' },
        {
          index: 1,
          tag: "Product",
          value: "Product",
          id:'Product'
        },
        {
          index: 2,
          tag: 'Promo By Product',
          value: 'PromoProduct',
          id: 'PromoProduct',
        },
        {
            index: 3,
            tag: 'Product By Customer',
            value: 'ProductCustomer',
            id: 'ProductCustomer',
          },
      ])

    return (
        <div style={{ display: "inline" }}>
            <Paper >
              <AppTabs
                tabs={subTabs}
                logo={null}
              >                
              </AppTabs>
            </Paper>
        </div>
    )
}