import React, { useEffect, useState } from 'react';
import Toaster from '../commonMuiComponents/Toaster'
import { PowerBIEmbed } from 'powerbi-client-react';
import { useTokenBI } from '../services/queries';
import { models } from 'powerbi-client';
import '../styles.css';
import useStore from '../store';
import { extractProduct, extractCustomer } from '../UpdateUrl';

function PromoReport() {
  const { isLoading: isTokenData, data: tokenData, isError: tokenDataError } = useTokenBI(process.env.REACT_APP_PROMO_ID);
  const [biToken, setBiToken] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');
  let selectedCountry = useStore((state) => state.selectedCountry);
  let selectedEventIdNew = useStore((state) => state.selectedEventIdNew);
  let selectedBaseLineCustomerNew = useStore(
    (state) => state.selectedBaseLineCustomerNew
  );
  let selectedBaseLineProductNew = useStore(
    (state) => state.selectedBaseLineProductNew
  );
  let selectedSource = useStore(
    (state) => state.selectedSource
  );

  let selectedYear = useStore((state) => state.selectedYear);

  let viewMonthly = useStore((state) => state.viewMonthly);

  const [customerValues, setCustomerValues] = useState([]);
  const [customerPlanValues, setCustomerPlanValues] = useState([]);
  
  const [productValues, setProductValues] = useState([]);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subBrands, setSubBrands] = useState([]);
  const [eans, setEans] = useState([]);
  const [eventID, setEventID] = useState([]);

  
  const startMonth = useStore((state) => state.startMonth);
  const  endMonth = useStore((state) => state.endMonth);

  // let categories = []
  /*    let subCategories = []
      let brands = []
     let subBrands = []
     let eans = []; */

  const [reportPageName, setReportPageName] = useState('');

  /*  pageName: 'Summary_Month',  */
  /* pageName: 'Summary_GSU', */

  useEffect(() => {
    if (viewMonthly === 'monthly') {
      setReportPageName(process.env.REACT_APP_PROMO_PAGENAME);
    } else if (viewMonthly === 'daily') {
      setReportPageName(process.env.REACT_APP_PROMO_PAGENAME_DAILY);
    }
  }, [viewMonthly]);

  useEffect(() => {
    setCustomerValues(' ');
    setCustomerPlanValues(' ');
    if (!selectedBaseLineCustomerNew !== '') {
      let { planLevel, soldTo } = extractCustomer(selectedBaseLineCustomerNew);

      setCustomerValues(soldTo);
      setCustomerPlanValues(planLevel);
    }
  }, [selectedBaseLineCustomerNew]);

  useEffect(() => {
    setEventID(' ');
    if (!selectedEventIdNew !== '') {
      let val;
      val = selectedEventIdNew?.trim().replaceAll('&eventIds=', ',');
            val = val.split(',');
      val.shift();
      setEventID(val);
    }
  }, [selectedEventIdNew]);

  useEffect(() => {
    if (!selectedBaseLineProductNew !== '') {
      let { category, subCategory, brand, subBrand, ean } = extractProduct(selectedBaseLineProductNew);

      let eanOnlyNum = []

      if (Object.keys(ean).length > 0) {
        ean.map(item => {
          eanOnlyNum.push(item.split('-')[0])
        })
      }

      setCategories(category);
      setSubCategories(subCategory);
      setBrands(brand);
      setSubBrands(subBrand);
      setEans(eanOnlyNum);
    }
  }, [selectedBaseLineProductNew]);

  useEffect(() => {
    if (!isTokenData) {
      setBiToken(tokenData?.accessToken);
      setEmbedUrl(tokenData?.url);
      
    }
  }, [isTokenData]);

  const basicFilterCountry = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'COUNTRY',
    },
    operator: 'In',
    values: [selectedCountry],
    filterType: models.FilterType.BasicFilter,
  };
  /* const basicFilterFrequency = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'WEEK'
    },
    operator: 'In',
    values: ['WEEK'],
    filterType: models.FilterType.BasicFilter
  }  */

  const basicFilterYear = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'YEAR',
    },
    operator: 'In',
    values: selectedYear, //[2021-4-22, 2021-6-18], 2021, '2021'
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: false,
  };

  const basicFilterCustomer = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'SOLD_TO_DESC',
    },
    operator: 'In',
    //values: ['Asda-UK','Coop GB-UK'],
    values: customerValues,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterCustomerPlan = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'PLAN_LEVEL',
    },
    operator: 'In',
    //values: ['Asda-UK','Coop GB-UK'],
    values: customerPlanValues,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProductCategory = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'CATEGORY',
    },
    operator: 'In',
    values: categories,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProducSubCategories = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'SUB_CATEGORY',
    },
    operator: 'In',
    values: subCategories,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProductBrands = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'BRAND',
    },
    operator: 'In',
    values: brands,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProducteSubBrands = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'SUB_BRAND',
    },
    operator: 'In',
    values: subBrands,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProductEans = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'EAN',
    },
    operator: 'In',
    values: eans,
    //values: [17751493009406],
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProductEventID = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'EVENT_ID',
    },
    operator: 'In',
    values: eventID,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterCustomerSource = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
      column: 'DATA_SOURCE',
    },
    operator: 'In',
    values: selectedSource,
    filterType: models.FilterType.BasicFilter,
  };


  /* const basicFilterWeekndLessThan = {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
    table: "PBIDatasourceStatus",
    column: "Date"
    },
    logicalOperator: "AND",
    conditions: [
    {
    operator: "GreaterThanOrEqual",
    value: "2021-09-30T00:00:00.000Z"
    },
    {
    operator: "LessThanOrEqual",
    value: "2021-11-02T00:00:00.000Z"
    }
    ],
    filterType: models.FilterType.AdvancedFilter
  }
    ]
  } */
  //const startMonth = useStore((state) => state.startMonth);
  //const endMonth = useStore((state) => state.endMonth);
  var Filter1 = {
    $schema: 'http://powerbi.com/product/schema#advanced',
    target: {
    table: 'V_RGM_SLS_FCT_DATA_POST_ROI',
    column: 'Calendar Date'
    },
    logicalOperator: 'AND',
    conditions: [
    {
    operator: 'GreaterThanOrEqual',
    value: `${startMonth} 00:00:00`
    },
    {
    operator: 'LessThanOrEqual',
    value: `${endMonth} 00:00:00`
    }
    ],
    filterType: models.FilterType.AdvancedFilter
    }

  const [filtersValue, setFiltersValue] = useState([]);

  useEffect(() => {
    let filter = [];

    //filter.push(basicFilterYear);
    // filter.push(basicFilterFrequency)

    if (selectedCountry?.length > 0) {
      filter.push(basicFilterCountry);
    }

    if (customerValues?.length > 0) {
      filter.push(basicFilterCustomer);
    }

    if(customerPlanValues?.length > 0) {
      filter.push(basicFilterCustomerPlan);
    }

    if (categories?.length > 0) {
      filter.push(basicFilterProductCategory);
    }

    if (subCategories?.length > 0) {
      filter.push(basicFilterProducSubCategories);
    }
    if (brands?.length > 0) {
      filter.push(basicFilterProductBrands);
    }
    if (subBrands?.length > 0) {
      filter.push(basicFilterProducteSubBrands);
    }
    if (eans?.length > 0) {
      filter.push(basicFilterProductEans);
    }
    if (eventID?.length > 0) {
      filter.push(basicFilterProductEventID);
    }
    if (selectedSource?.length > 0) {
      filter.push(basicFilterCustomerSource);
    }
    filter.push(Filter1)
    /* filter.push(basicFilterWeekndGreaterThan) */
    setFiltersValue(filter);
  }, [
    selectedYear,
    selectedCountry,
    customerValues,
    customerPlanValues,
    categories,
    subCategories,
    brands,
    subBrands,
    eans,
    eventID,
    startMonth,
    selectedSource,
    endMonth 
  ]);

  return (
    <div className='App' id='powerBIFrame'>



      {tokenDataError && <Toaster showAlert={{
                type: 'error',
                message: "Some thing went wrong, please reload page"
            }} />}
      {/*  {embedUrl} */}
       {/* <p>eans {JSON.stringify(eans)} </p> 
      <p>customerValues {JSON.stringify(customerValues)}</p>
    <p>customerValues {customerValues}</p>
          <p>subBrands {JSON.stringify(subBrands)}</p>
          <p>eans {JSON.stringify(eans)}</p> 
          <p>basicFilterKCEans {JSON.stringify(basicFilterYear)}</p>
         {/*  <p>basicFilterFrequency {JSON.stringify(basicFilterFrequency)}</p> */} 
       {/*  <p>filtersValue {JSON.stringify(filtersValue)}</p> 
       <p>reportPageName {JSON.stringify(reportPageName)}</p> */} 
      {/* <p> startMonth {JSON.stringify(filtersValue)}</p>
       <p> endMonth {JSON.stringify(filtersValue)}</p>  */}

      <header className='App-header'>
        {!isTokenData && biToken && (
          <PowerBIEmbed
            embedConfig={{
              /*  pageName: 'Summary_Month',  */
              pageName: process.env.REACT_APP_PROMO_PAGENAME,
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              id: process.env.REACT_APP_PROMO_ID,
              embedUrl: embedUrl,
              accessToken: biToken,
              tokenType: models.TokenType.Embed,
              filters: filtersValue,
             //filters: basicFilterCountry,
              settings: {
                panes:{
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                },
                layoutType: models.LayoutType.Custom,
        customLayout: {
            pageSize: {
                type: models.PageSizeType.Custom,
                width: 1050,
                height: 500
            }, 
            displayOption: models.DisplayOption.FitToPage,
          },
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  function () {
                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  function () {
                    console.log('Report rendered');
                  },
                ],
                [
                  'error',
                  function (event) {
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={'Embed-containerr-promo'}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        )}
      </header>
    </div>
  );
}

export default PromoReport;
