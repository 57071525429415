import React from "react";
import useStore from "./store";
import PromoPerformanceKR from "./PostRoi/PromoPerformaceLayoutKR";
import PromoPerformanceOther from "./PostRoi/PromoPerformaceOther";

const RoiSimulatorLayout = () => {
  
  let selectedCountry = useStore((state) => state.selectedCountry);

  if(selectedCountry === "KR"){
    return <PromoPerformanceKR />
  }
  return (
    <>
       <PromoPerformanceOther />

    </>
  );
};

export default RoiSimulatorLayout;
