import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    main: {
        padding:100,
        display: 'flex',
        flexDirection:'column'
    },
    wrapper: {
        paddingTop:20,
        display:'flex',
        flexDirection:'column',
        gap:'10px'
    },
    inner: {
        display:'flex',
        flexDirection:'row',
        gap:'10px'
    }
}))

const Profile = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const [currentUser, setcurrentUser] = useState()
    const classes = useStyles();

    useEffect(() => {
        const getCurrentUser = async () => {
          if (authState.isAuthenticated){
            const userInfo = await oktaAuth.getUser()
            setcurrentUser(authState.idToken.claims);
            console.log(`Access token:: ${authState.accessToken.accessToken}`)
            console.log(`Id token :: ${authState.idToken.idToken}`)
          }
        }
        getCurrentUser();
      }, [authState, oktaAuth])

    return (
        <div className={classes.main}>
            <h3>Your account details: </h3>
            {
                currentUser
                ? <div className={classes.wrapper}>
                    {
                        Object.keys(currentUser).map((key) => {
                            return (
                                <div className={classes.inner}>
                                <div><b>{key}:</b></div>
                                <div>{currentUser[key].toString()}</div>
                            </div>
                            )
                        })
                    }

                 </div>
                : null
            }
           
            
        </div>
    )
}

export default Profile;