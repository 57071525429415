import { Grid, Box, MenuItem, Stack, TextField } from "@mui/material"
import { makeStyles } from '@material-ui/core';
import { useState, useEffect } from "react";
import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import { validateInput } from "../utils/validateInput";
import { mockDropdownApiResposne } from "../Constants/mockDropdownApiResposne/mockDropdownApiResposne";

export const AdditionalFundingInput = ({ id }: any) => {
  const classes = useStyles();
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const [additionalFunding, setAdditionalFunding] = useState(promoSimulatorTabs[id].additionalFunding);
  const isValueValid = usePromoSimulatorStore((state: any) => state.isValueValid)
  const setIsValueValid = usePromoSimulatorStore((state: any) => state.setIsValueValid)
  const [validationAdditionalFundingErrorText, setValidationAdditionalFundingErrorText] = useState("");

  useEffect(() => {
    setAdditionalFunding(promoSimulatorTabs[id].additionalFunding);
  }, [promoSimulatorTabs])
  const validateAdditionalFundingInput = (e: any) => {
    setIsValueValid({ ...isValueValid, isAdditionalFundingValid: true });
    // accept only +ve number
    if (e.target.value) {
      if (!validateInput(e.target.value, "positiveNumber")) {

        setIsValueValid({ ...isValueValid, isAdditionalFundingValid: false });
        setValidationAdditionalFundingErrorText("Only positive numbers allowed!");
      }
      else {

        setIsValueValid({ ...isValueValid, isAdditionalFundingValid: true });
        setValidationAdditionalFundingErrorText("")
      };
    };
    setAdditionalFunding(e.target.value);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, additionalFunding: e.target.value } : tab));
  };



  const handleAdditionalFundingChange = (e: any) => {
    setIsValueValid({ ...isValueValid, isAdditionalFundingValid: true });
    setValidationAdditionalFundingErrorText("");
    validateAdditionalFundingInput(e);
  };


  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Box sx={{ fontWeight: '500', m: 1, fontSize: '0.75rem' }}>Additional Funding</Box></Grid>
        <Grid item xs={2.5}>
          <TextField
            error={!isValueValid.isAdditionalFundingValid}
            size="small"
            variant="outlined"
            className={classes.fieldWidth}
            value={additionalFunding}
            onChange={e => handleAdditionalFundingChange(e)}
            InputLabelProps={{ shrink: true }}
            helperText={!isValueValid.isAdditionalFundingValid && validationAdditionalFundingErrorText}
            inputProps={{ "aria-label": "additionalFunding" }}
            disabled={promoSimulatorTabs[id].calculateButtonClicked}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  fieldWidth: {
    width: '228px',
    '& input': {
      fontSize: '0.75rem',
    },
    '& div': {
      fontSize: '0.75rem !important',
    }
  },
}));
