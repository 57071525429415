import { useState, useEffect } from "react";
import { Grid, Box, TextField, MenuItem, Stack } from "@mui/material"
import { makeStyles } from '@material-ui/core';

import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import useStore from "../../../../store";

export const validateInput = (input: any, validationType: string) => {
  switch (validationType) {
    case "positiveNumber":
      return (/^(\d)*(\.)?([0-9]{1})?$/.test(input));
    case "positivePercentage":
      return (/^(?!0(\.0)?$)(?:\d{1,2}(?:\.\d)?|99(?:\.0)?)$/.test(input));
  };
};

export const PromoVolumeInput = ({ id, PromoVolumeTypes, promoData }: any) => {
  const classes = useStyles();
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);
  const [selectedPromoVolumeType, setSelectedPromoVolumeType] = useState(promoSimulatorTabs[id].volumeType);
  const [value, setValue] = useState(promoSimulatorTabs[id].volumeTypeValue);
  const isValueValid = usePromoSimulatorStore((state: any) => state.isValueValid)
  const setIsValueValid = usePromoSimulatorStore((state: any) => state.setIsValueValid)
  const [validationErrorText, setValidationErrorText] = useState("");

  useEffect(() => {
    if (promoData && selectedPromoDetails.fromView) {
      setSelectedPromoVolumeType(promoData.promoVolumeOption);
      setValue(promoData.promoVolumeValue);
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => {
        return tab.tabIndex === id ? { ...tab, volumeType: promoData.promoVolumeOption, volumeTypeValue: promoData.promoVolumeValue, reset: false } : tab
      }));
    }
  }, [])

  useEffect(() => {
    if (promoData && selectedPromoDetails.fromView) {
      setSelectedPromoVolumeType(promoData.promoVolumeOption);
      setValue(promoData.promoVolumeValue);
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => {
        return tab.tabIndex === id ? { ...tab, volumeType: promoData.promoVolumeOption, volumeTypeValue: promoData.promoVolumeValue, reset: false } : tab
      }));
    }
  }, [selectedPromoDetails, promoData])

  const validateValueInputs = (e: any) => {
    if (e.target.value) {
      if (!validateInput(e.target.value, "positiveNumber")) {
        setIsValueValid({ ...isValueValid, isPromoVolumeValid: false });
        setValidationErrorText("Only positive values with up to 1 decimal are allowed");
      } else {
        setIsValueValid({ ...isValueValid, isPromoVolumeValid: true });
        setValidationErrorText("");
      };
    }
    setValue(e.target.value);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, volumeTypeValue: e.target.value } : tab));
  };

  const handlePromoVolumeTypeChange = (e: any) => {
    setIsValueValid({ ...isValueValid, isPromoVolumeValid: true });
    setValidationErrorText("")
    setValue("");
    setSelectedPromoVolumeType(e.target.value);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, volumeType: e.target.value, volumeTypeValue: '' } : tab));
  };

  const handleValueChange = (e: any) => {
    setIsValueValid({ ...isValueValid, isPromoVolumeValid: true });
    setValidationErrorText("");
    validateValueInputs(e);
  };

  useEffect(() => {
    if (promoSimulatorTabs[id]?.reset) {
      setSelectedPromoVolumeType('')
      setValue('')
    }
  }, [promoSimulatorTabs[id]?.reset])

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Box sx={{ fontWeight: '500', m: 1, fontSize: '0.75rem' }}>Promo Volume<span className="text-red-900 font-bold text-base">*</span></Box>
        </Grid>
        <Grid item xs={2.5}>
          <TextField
            select
            size="small"
            variant="outlined"
            disabled={promoSimulatorTabs[id].calculateButtonClicked}
            className={classes.fieldWidth}
            value={selectedPromoVolumeType}
            onChange={e => handlePromoVolumeTypeChange(e)}
            InputLabelProps={{ shrink: true }}
            required
          >
            {PromoVolumeTypes?.options?.map((promoType: any, index: number) => {
              return <MenuItem sx={{ fontSize: '0.8rem' }} key={index} value={promoType.internal}>{promoType.label}</MenuItem>
            })}
          </TextField>
        </Grid>
        <Grid item xs={2.5}>
          <Stack direction="row" spacing={1}>
            <TextField
              error={!isValueValid.isPromoVolumeValid}
              size="small"
              variant="outlined"
              label="Value"
              className={classes.fieldWidth}
              value={value}
              onChange={e => handleValueChange(e)}
              InputLabelProps={{ shrink: true }}
              helperText={!isValueValid.isPromoVolumeValid ? validationErrorText : null}
              disabled={!selectedPromoVolumeType || selectedPromoVolumeType === 'MANUAL_INPUT' || promoSimulatorTabs[id].calculateButtonClicked}
              required
              inputProps={{ 'aria-label': 'volumeValue' }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  fieldWidth: {
    width: '228px',
    '& input': {
      fontSize: '0.75rem',
    },
    '& div': {
      fontSize: '0.75rem !important',
    }
  }
}));
