
// Product hierarchy response from server
// "countries" : {
//     "PE": {
//         "categories": {
//             "Facial Tissue": {
//                 "subCategories": {
//                     "FACIAL TISSUE BOXED": {
//                         "brands": {
//                             "FACIAL TISSUE BOX KLEENEX": {
//                                 "subBrands": {
//                                     "FACBOX KLEENEX ULTRASOFT": [
//                                         "17702425533612-FAC KLEENEX USOFT POCKET 3P 36X4 X10", "17702425807911-FAC KLEENEX USOFT SOFTP 3P 36X1 X40", "17702425533629-FAC KLEENEX USOFT CUBE 3P 32X1 X55 BIG"
//                                     ],
//                                     "FACBOX KLEENEX SUPER": ["27702425524969-FAC KLEENEX ORIG 12X1 X80 BIG"],
//                                     "FACIAL TISSUE BOX KLEENEX": ["17702425803999-FAC KLEENEX JUNIOR 12X1 X50 BIG", "17702425547435-FAC KLEENEX TUBO MAINLINE RUNWAY 36X40", "17702425640785-FAC KLEENEX ORIG JUNIOR 12X3 X50 P2LLE3"]
//                                 }
//                             }
//                         }
//                     },
//                     "FACIAL TISSUE HANKS": {
//                         "brands": {
//                             "FACHAN  KLEENEX": {
//                                 "subBrands": {
//                                     "FACHAN  KLEENEX": [
//                                         "17702425538587-FAC KLEENEX ORIG POCKET 24X6 X10 PE RW",
//                                         "27702425528073-FAC KLEENEX ORIG POCKET 36X4 X10 TRE RW",
//                                         "17702425546087-FAC KLEENEX MINIPOCK MAINLINE POP 12X6X7",
//                                         "17702425802558-FAC KLEENEX POCKET 12X4 X10 PORTABLE",
//                                         "17702425805795-FAC KLEENEX ORIG POCKET 3P 24X8 X10 PE B",
//                                         "17702425546063-FAC KLEENEX ORIG POCKET 36X4 X10 BIG",
//                                         "27702425552252-FAC KLEENEX ORIG POCKET 12X4 X10 NEUTRAL",
//                                         "17798038152142-FAC KLEENEX CLASSIC WALLET D2 3P 18x12x8",
//                                         "17702425803982-FAC KLEENEX POCKET 12X4 X10 BIG",
//                                         "17702425806396-FAC KLEENEX MINIPOCK 3P 12X4X7 PC II",
//                                         "27702425546060-FAC KLEENEX ORIG POCKET 12X4 X10 BIG"
//                                     ],
//                                     "FACHAN  KLEENEX ORIGINAL": ["17702425806372-FAC KLEENEX ORIG MINIPOCK 3P 12X4X7"]
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

export type HierarchyNode = {
    name: string,
    isChecked: boolean
}

export type ProductHierarchyKeys = 'categories' | 'subCategories' | 'brands' | 'subBrands' | 'products'

const mapSelected  = (selected: HierarchyNode[] | null) => (item: string) => ({name: item, isChecked: selected ? selected.some(sel => sel.name === item && sel.isChecked) : false} as HierarchyNode)
export const processProductHierarchy =  (hieararchy: any, selected: Record<ProductHierarchyKeys, HierarchyNode[]> ) : Record<ProductHierarchyKeys, HierarchyNode[]> | null  =>  {
    if (!hieararchy.categories) return null

    const categoriesMap = hieararchy.categories
    if (!categoriesMap) return null

    const categories:  HierarchyNode[] = Object.keys(categoriesMap).map(mapSelected(selected ? selected.categories : null))
    const subCategories: HierarchyNode[] = [], brands: HierarchyNode[] = [], subBrands: HierarchyNode[]=[], products: HierarchyNode[] = []

    let availableCategories
    if (selected && selected.categories && selected.categories.some(x=> x.isChecked)){
        availableCategories = Object.keys(categoriesMap).filter((x: string )=> selected.categories.some(y => x === y.name && y.isChecked))
    }else{
        availableCategories = Object.keys(categoriesMap)
    }

    for (const category of availableCategories) {
       
        const subCategoryMap = hieararchy.categories[category].subCategories
        if (subCategoryMap){
            let thisSubCategories = Object.keys(subCategoryMap)
            subCategories.push(...(thisSubCategories.map(mapSelected(selected ? selected.subCategories : null))))
            if (selected && selected.subCategories && selected.subCategories.some(x=> x.isChecked)){
                thisSubCategories = thisSubCategories.filter((x: string )=> selected.subCategories.some(y => x === y.name && y.isChecked))
            }
            for (const subCategory of thisSubCategories) {
                const brandMap = hieararchy.categories[category].subCategories[subCategory].brands
                let thisBrands = Object.keys(brandMap)
                brands.push(...(thisBrands.map(mapSelected(selected ? selected.brands : null))))
                if (selected && selected.brands && selected.brands.some(x=> x.isChecked)){
                    thisBrands = thisBrands.filter((x: string )=> selected.brands.some(y => x === y.name && y.isChecked))
                }
                for (const brand of thisBrands) {
                    const subBrandMap =  hieararchy.categories[category].subCategories[subCategory].brands[brand].subBrands
                    let thisSubBrands = Object.keys(subBrandMap)
                    subBrands.push(...(thisSubBrands.map(mapSelected(selected ? selected.subBrands : null))))
                    if (selected && selected.subBrands && selected.subBrands.some(x=> x.isChecked)){
                        thisSubBrands = thisSubBrands.filter((x: string )=> selected.subBrands.some(y => x === y.name && y.isChecked))
                    }
                    for (const subBrand of thisSubBrands) {
                        const productsArray =  hieararchy.categories[category].subCategories[subCategory].brands[brand].subBrands[subBrand]
                        products.push(...(productsArray.map(mapSelected(selected ? selected.products : null))))
                    }
                }
            }
        }
    }

    const out : Record<ProductHierarchyKeys, HierarchyNode[]> = {
        categories: categories,
        subCategories: subCategories,
        brands: brands,
        subBrands: subBrands,
        products: products
    }

    return out
}

export type CustomerHierarchyKeys = 'channel' | 'salesOffice' | 'planLevel' | 'salesExecutive' | 'soldTo'

export const processCustomerHierarchy =  (hieararchy: any, selected: Record<CustomerHierarchyKeys, HierarchyNode[]> ) : Record<CustomerHierarchyKeys, HierarchyNode[]> | null  =>  {
    if (!hieararchy.distChannels) return null

    const channelMap = hieararchy.distChannels
    if (!channelMap) return null

    const channels:  HierarchyNode[] = Object.keys(channelMap).map(mapSelected(selected ? selected.channel : null))
    const salesOffices: HierarchyNode[] = [], planLevels: HierarchyNode[] = [], soldTos: HierarchyNode[]=[];
    let salesExecutives: HierarchyNode[] = [];

    let availableChannels
    if (selected && selected.channel && selected.channel.some(x=> x.isChecked)){
        availableChannels = Object.keys(channelMap).filter((x: string )=> selected.channel.some(y => x === y.name && y.isChecked))
    }else{
        availableChannels = Object.keys(channelMap)
    }

    for (const channel of availableChannels) {
        const salesOfficeMap = hieararchy.distChannels[channel].salesOffice
        if (salesOfficeMap){
            let thisSAlesOffices = Object.keys(salesOfficeMap)
            salesOffices.push(...(thisSAlesOffices.map(mapSelected(selected ? selected.salesOffice : null))))
            if (selected && selected.salesOffice && selected.salesOffice.some(x=> x.isChecked)){
                thisSAlesOffices = thisSAlesOffices.filter((x: string )=> selected.salesOffice.some(y => x === y.name && y.isChecked))
            }
            for (const office of thisSAlesOffices) {
                const planLevelMap = hieararchy.distChannels[channel].salesOffice[office].planLevel
                let thisPlanLevels = Object.keys(planLevelMap)
                planLevels.push(...(thisPlanLevels.map(mapSelected(selected ? selected.planLevel : null))))
                if (selected && selected.planLevel && selected.planLevel.some(x=> x.isChecked)){ 
                    thisPlanLevels = thisPlanLevels.filter((x: string )=> selected.planLevel.some(y => x === y.name && y.isChecked))
                }
                for (const pl of thisPlanLevels) {
                    const soldToMap =  hieararchy.distChannels[channel].salesOffice[office].planLevel[pl].soldTo
                    let thisSoldToLevels = Object.keys(soldToMap)
                    soldTos.push(...(thisSoldToLevels.map(mapSelected(selected ? selected.soldTo : null))))
                    if (selected && selected.soldTo && selected.soldTo.some(x=> x.isChecked)){ 
                        thisSoldToLevels = thisSoldToLevels.filter((x: string )=> selected.soldTo.some(y => x === y.name && y.isChecked))
                    }
                    if(hieararchy.displaySalesExecutives){
                        for (const st of thisSoldToLevels) {
                            const salesExecutiveArray =  hieararchy.distChannels[channel].salesOffice[office].planLevel[pl].soldTo[st]
                            salesExecutives.push(...(salesExecutiveArray.map(mapSelected(selected ? selected.salesExecutive : null))))
                        }
                    }
                }
            }
        }
    }
    // get unique sorted sales executives data
    salesExecutives = getUniqueSortedData(salesExecutives);

    const out : Record<CustomerHierarchyKeys, HierarchyNode[]> = {
        channel: channels,
        salesOffice: salesOffices,
        planLevel: planLevels,
        salesExecutive: salesExecutives,
        soldTo: soldTos,
    }

    return out
}

export const validateDate = (data: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    const splitData = data.split("&");
    const startDateArr = splitData.find(data => data.split("=").includes("fromDate"));
    const endDateArr = splitData.find(data => data.split("=").includes("toDate"));
    const startDate = startDateArr !== undefined ? startDateArr.split("=") : '';
    const endDate = endDateArr !== undefined ? endDateArr.split("=") : '';

    if(startDate === undefined || endDate === undefined){
        return true;
    } else if(startDate[1] === "Invalid date" || endDate[1] === "Invalid date"){
        console.log("Validate Date");
        return true;
    } else if (startDate[1].match(regex) === null || endDate[1].match(regex) === null) {
        return true;
    } 
    return false;
}

const getUniqueSortedData = (data: any) => {
    if(data.length > 0) {
        let uniqueData: any = Array.from(new Set(data.map((el: any) => JSON.stringify(el)))).map((el: any) => JSON.parse(el));
        uniqueData.sort((a: any,b: any) => a.name! > b.name! ? 1 : -1);
        return uniqueData;
    }
    return data;
}

