
import React, {useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStore from '../store';


const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
      minWidth: 120,
  },
}));

export default function GroupSelect() {
    const classes = useStyles();   
    const [openGroup, setOpenGroup] = React.useState(false);
    let groupBy = useStore((state) => state.groupBy);

  //store
  const setGroupByValue = useStore((state) => state.setGroupByValue);
  const selectedCountry = useStore((state) => state.selectedCountry);

  useEffect(()=>{
    setGroupByValue('Customer');
  }, [])
 
 
  const handleChangeGroup = (event) => {
    setGroupByValue(event.target.value)  
  }

  const handleCloseGroup = () => {
    setOpenGroup(false);
  };

  const handleOpenMonth = () => {
    setOpenGroup(true);
  };

  let groupByItems = {
    Month: "Month",
    Week: "Week",
    SalesOffice: "Sales Office",
    PlanningCustomer: "Planning Customer",
    SalesExecutive: "Sales Executive",
    Customer: "Customer",
    Category: "Category",
    SubCategory: "Sub Category",
    Brand: "Brand",
    SubBrand: "Sub Brand",
    EventId: "Event Id",
    Pack: "Pack Count",
    Roll: "Roll",
    Tier: "Tier",
    Size: "Size",
  };
  const [groupByOptions,setGroupByOptions] = useState(groupByItems);

  useEffect(()=>{
    let obj = {
      Feature:"Feature",
      Duration: "Duration",
      PricePoint: "Price Point",
      Mechanic: "Mechanic",
      osku: "OSKU"
    }
    if(selectedCountry === 'UK') {
      let options = {...groupByItems,...obj};
      setGroupByOptions(options);
    } else {
      let options = {...groupByItems,Ean: "Ean"};
      setGroupByOptions(options);
    }
  },[selectedCountry])

  return (
    <div style={{ display: "inline" }}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label"></InputLabel>

        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={openGroup}
          onClose={handleCloseGroup}
          onOpen={handleOpenMonth}
          value={groupBy}
          onChange={handleChangeGroup}
        >
          <MenuItem value={0} className="groupByItem" disabled>
            <em>Select</em>
          </MenuItem>
          {
            Object.entries(groupByOptions).map((item) => (
              (selectedCountry === "UK" && item[0] === "PromoType") ||
                (selectedCountry !== "UK" && item[0] === "Mechanic") || 
                (selectedCountry === "KR" && item[0] === "SalesExecutive") ? "" :
                <MenuItem
                  key={item[0]}
                  value={item[0]}
                  className="groupByItem"
                  id={`groupByItem_${item[0]}`}
                >
                  {item[1]}
                </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  );
}