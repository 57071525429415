import create from 'zustand';

import { persist } from 'zustand/middleware';

let store = (set: any) => ({

    // SELECTION: COUNTRY
    selectedCountry: '',
    setSelectedCountry: (req: any) => set((state: any) => ({ selectedCountry: req })),

    // LIST: KC PRODUCT
    kcProductList: '',
    setKcProductList: (req: any) => set((state: any) => ({ kcProductList: req })),

    // LIST: KC PRODUCT
    targetProductList: '',
    setTargetProductList: (req: any) => set((state: any) => ({ targetProductList: req })),

    //SELECTED KC PRODUCTS
    selectedKcProducts: {},
    setSelectedKcProducts: (req: any) => set((state: any) => ({ selectedKcProducts: req })),

    //SELECTED TARGET PRODUCTS
    selectedTargetProducts: {},
    setSelectedTargetProducts: (req: any) => set((state: any) => ({ selectedTargetProducts: req })),

    //SELECTED OWN PRODUCTS
    selectedOwnProducts: {},
    setSelectedOwnProducts: (req: any) => set((state: any) => ({ selectedOwnProducts: req })),

    //SELECTED PRODUCT LEVEL
    selectedProductLevel: '',
    setSelectedProductLevel: (req: any) => set((state: any) => ({ selectedProductLevel: req })),

    //SELECTED SOURCE
    selectedSourceChoice: '',
    setSelectedSourceChoice: (req: any) => set((state: any) => ({ selectedSourceChoice: req })),

    //SELECTED CATEGORY LEVEL
    selectedCategoryLevel: '',
    setSelectedCategoryLevel: (req: any) => set((state: any) => ({ selectedCategoryLevel: req })),

    //SELECTED SUB CATEGORY LEVEL
    selectedSubCategoryLevel: [],
    setSelectedSubCategoryLevel: (req: any) => set((state: any) => ({ selectedSubCategoryLevel: req })),

    //SELECTED SCOPE LEVEL
    selectedScopeLevel: '',
    setSelectedScopeLevel: (req: any) => set((state: any) => ({ selectedScopeLevel: req })),

    //SELECTED CROSS PRODUCTS
    selectedCrossData: [],
    setSelectedCrossProducts: (req: any) => set((state: any) => ({ selectedCrossData: req })),

    // SELECTION: BaseLine  PRODUCT
    selectedBaseLineProduct: '',
    setSelectedBaseLineProduct: (req: any) => set((state: any) => ({ selectedBaseLineProduct: req })),

    // SELECTION: BaseLine  Customer
    selectedBaseLineCustomer: '',
    setSelectedBaseLineCustomer: (req: any) => set((state: any) => ({ selectedBaseLineCustomer: req })),

    // SELECTION: BaseLine  filters
    selectedBaseLineFilters: '',
    setSelectedBaseLineFilter: (req: any) => set((state: any) => ({ selectedBaseLineFilters: req })),


    // SELECTION: BaseLine  PRODUCT
    selectedBaseLineProductNew: '',
    setSelectedBaseLineProductNew: (req: any) => set((state: any) => ({ selectedBaseLineProductNew: req })),

    // SELECTION: BaseLine  Customer
    selectedBaseLineCustomerNew: '',
    setSelectedBaseLineCustomerNew: (req: any) => set((state: any) => ({ selectedBaseLineCustomerNew: req })),

    // SELECTION: 
    selectedPromotionalItem: '',
    setSelectedPromotionalItem: (req: any) => set((state: any) => ({ selectedPromotionalItem: req })),

    // SELECTION: BaseLine  Customer
    selectedCustomers: '',
    setSelectedCustomers: (req: any) => set((state: any) => ({ selectedCustomers: req })),

    // SELECTION: BaseLine  Customer
    selectedProducts: '',
    setSelectedProducts: (req: any) => set((state: any) => ({ selectedProducts: req })),

    customersStateData: {},
    setCustomersStateData: (req: any) => set((state: any) => ({ customersStateData: req })),

    // SELECTION: BaseLine  Customer
    preRoiSelectedCustomers: '',
    setPreRoiSelectedCustomers: (req: any) => set((state: any) => ({ preRoiSelectedCustomers: req })),

    preRoiCustomersStateData: {},
    setPreRoiCustomersStateData: (req: any) => set((state: any) => ({ preRoiCustomersStateData: req })),

    productsStateData: {},
    setProductsStateData: (req: any) => set((state: any) => ({ productsStateData: req })),

    preRoiProductsStateData: {},
    setPreRoiProductsStateData: (req: any) => set((state: any) => ({ preRoiProductsStateData: req })),
    selectedPreRoiProducts: '',
    setSelectedPreRoiProducts : (req: any) => set((state: any) => ({ selectedPreRoiProducts: req })),


    // SELECTION: BaseLine  year
    selectedYear: '',
    setSelectedYear: (req: any) => set((state: any) => ({ selectedYear: req })),

    // SELECTION: BaseLine  monthly or weekly
    viewMonthly: '',
    setViewMonthly: (req: any) => set((state: any) => ({ viewMonthly: req })),

    // SELECTION: BaseLine  monthly or weekly
    visibilityYearMonthly: true,
    setVisibilityYearMonthly: (req: any) => set((state: any) => ({ visibilityYearMonthly: req })),

    //price owns selected value;
    priceOwnsValue: true,
    setPriceOwnsValue: (req: any) => set((state: any) => ({ priceOwnsValue: req })),

    rowData: [],
    setRowData: (req: any) => set((state: any) => ({ rowData: req })),

    //group by value post roi;
    groupBy: 'Customer',
    setGroupByValue: (req: any) => set((state: any) => ({ groupBy: req })),

    selectedQuadrant: [],
    setSelectedQuadrant: (quadrant: any) => set((state: any) => ({ selectedQuadrant: quadrant })),

    //KC product selected value;
    kcProductCrossValue: true,
    setKcProductCrossValue: (req: any) => set((state: any) => ({ kcProductCrossValue: req })),

    //target product cross selected value;
    targetCrossValue: true,
    setTargetCrossValue: (req: any) => set((state: any) => ({ targetCrossValue: req })),

    //price-elasticity selected tab for backbutton;
    priceElasticityTab: '',
    setPriceElasticityTab: (req: any) => set((state: any) => ({ priceElasticityTab: req })),


    //month selection start
    startMonth: '',
    setStartMonth: (req: any) => set((state: any) => ({ startMonth: req })),


    //month selection end
    endMonth: '',
    setEndMonth: (req: any) => set((state: any) => ({ endMonth: req })),

    //month selection end
    yearSelection: '',
    setYearSelection: (req: any) => set((state: any) => ({ yearSelection: req })),

    //selected event ID
    selectedEventId: '',
    setSelectedEventId: (req: any) => set((state: any) => ({ selectedEventId: req })),


    //selected event ID
    selectedEventIdNew: '',
    setSelectedEventIdNew: (req: any) => set((state: any) => ({ selectedEventIdNew: req })),

    //hierarchyLevel
    hierarchyLevel: 'EAN',
    setHierarchyLevel: (req: any) => set((state: any) => ({ hierarchyLevel: req })),

    //State of Baseline product selector
    selectedBaseLineProductState: {},
    setSelectedBaseLineProductState: (req: any) => set((state: any) => ({ selectedBaseLineProductState: req })),

    //State of Baseline customer selector
    selectedBaseLineCustomerState: {},
    setSelectedBaseLineCustomerState: (req: any) => set((state: any) => ({ selectedBaseLineCustomerState: req })),

    //State of Baseline setPromotionalsStateData selector
    promotionalsStateData: {},
    setPromotionalsStateData: (req: any) => set((state: any) => ({ promotionalsStateData: req })),

    //State of payload for  POSTROI Filters
    payloadforPOSTROIFilters: {},
    setPayloadforPOSTROIFilters: (req: any) => set((state: any) => ({ payloadforPOSTROIFilters: req })),

    //State of Post ROI EventIds
    selectedEventIdState: [],
    setSelectedEventIdState: (req: any) => set((state: any) => ({ selectedEventIdState: req })),

    //State \of selected time periods
    selectedYearWeeks: [],
    setSelectedYearWeeks: (req: any) => set((state: any) => ({ selectedYearWeeks: req })),

    //To store selected event Id values
    selectedEventIdValues: [],
    setSelectedEventIdValues: (req: any) => set((state: any) => ({ selectedEventIdValues: req })),

    //Auth state
    loginState: false,
    setLoginState: (login: boolean) => set((state: any) => ({ loginState: login })),

    // Auth error state
    authError: '',
    setAuthError: (error: string) => set((state: any) => ({ authError: error })),

    // Users access to the country and roles
    countryAndRole: {},
    setCountryAndRoles: (roles: Object) => set((state: any) => ({ countryAndRole: roles })),

    //API bearer token
    apiToken: '',
    setAPIToken: (token: string) => set((state: any) => ({ apiToken: token })),

    oktaAccessToken: '',
    setOktaAccessToken: (token: string) => set((state: any) => ({ oktaAccessToken: token })),

    oktaIdToken: '',
    setOktaIdToken: (token: string) => set((state: any) => ({ oktaIdToken: token })),

    //Initial Correlation url
    initialValueCorrelationUrl: '',
    setInitialValueCorrelationUrl: (req: any) => set((state: any) => ({ initialValueCorrelationUrl: req })),

    //target Correlation url
    targetValueCorrelationUrl: '',
    setTargetValueCorrelationUrl: (req: any) => set((state: any) => ({ targetValueCorrelationUrl: req })),

    //State of Correlation Initial Product selector
    selectedInitialProductState: {},
    setSelectedInitialProductState: (req: any) => set((state: any) => ({ selectedInitialProductState: req })),
    
    //State of Correlation Target Product selector
    selectedTargetProductState: {},
    setSelectedTargetProductState: (req: any) => set((state: any) => ({ selectedTargetProductState: req })),
   
    //State of Correlation Initial Product selector
    referenceInitialProduct: {},
    setReferenceInitialProduct: (req: any) => set((state: any) => ({ referenceInitialProduct: req })),
    
    //State of Correlation Target Product selector
    referenceTargetProduct: {},
    setReferenceTargetProduct: (req: any) => set((state: any) => ({ referenceTargetProduct: req })),

    //Parent checkbox state in Customer selector
    baseLineCustomerCheckBox: {},
    setBaseLineCustomerCheckBox: (req: any) => set((state: any) => ({ baseLineCustomerCheckBox: req })),

    //Parent checkbox state in promo
    promoTypeCheckBox: {},
    setPromoTypeCheckBox: (req: any) => set((state: any) => ({ promoTypeCheckBox: req })),

    //Parent checkbox state in Customer selector
    baseLineProductCheckBox: {},
    setBaseLineProductCheckBox: (req: any) => set((state: any) => ({ baseLineProductCheckBox: req })),

    //To store checkbox state in PE Product selector
    initialProductCheckBox: {},
    setInitialProductCheckBox: (req: any) => set((state: any) => ({ initialProductCheckBox: req })),

    targetProductCheckBox: {},
    setTargetProductCheckBox: (req: any) => set((state: any) => ({ targetProductCheckBox: req })),

    //To store items to be removed from own tab
    removeItem: [],
    setRemoveItem: (req: any) => set((state: any) => ({ removeItem: req })),

    //Array to store values selected in customer selector [used for sync selectors]
    selectedCustomerValues: [],
    setSelectedCustomerValues: (req: any) => set((state: any) => ({ selectedCustomerValues: req })),

    //Array to store values selected in product selector [used for sync selectors]
    selectedProductValues: [],
    setSelectedProductValues: (req: any) => set((state: any) => ({ selectedProductValues: req })),

    promoStateData: {},
    setPromoStateData: (req: any) => set((state: any) => ({ promoStateData: req })),

    //To store selected elasticity price [used to sync elasticity price in own and cross tab]
    priceType: { avgPrice: true, promoPrice: false, basePrice: false },
    setPriceType: (req: any) => set((state: any) => ({ priceType: req })),

    calcutateFormNew: false,
    setCalcutateFormNew: (req: any) => set((state: any) => ({ calcutateFormNew: req })),

    calcutateTotalInvest: false,
    setCalcutateTotalInvest: (req: any) => set((state: any) => ({ calcutateTotalInvest: req })),

    // SELECTION:source
    selectedSource: [],
    setSelectedSource: (req: any) => set((state: any) => ({ selectedSource: req })),

    //To store checkbox state of source level
    postROISourceCheckbox: { 'Sell In': false, 'Sell Out': false },
    setPostROISourceCheckbox: (req: any) => set((state: any) => ({ postROISourceCheckbox: req })),

    resetFormFieldsNew: false,
    setresetFormFieldsNew: (req: any) => set((state: any) => ({ resetFormFieldsNew: req })),

    selectedRaidoPromo: 1,
    setSelectedRaidoPromo: (req: any) => set((state: any) => ({ selectedRaidoPromo: req })),

    promoValNew: 1,
    setPromoValNew: (req: any) => set((state: any) => ({ promoValNew: req })),

    displayTopItems: 0,
    setDisplayTopItems: (req: any) => set((state: any) => ({ displayTopItems: req })),

    //promo count storing 
    promoVal: 1,
    setPromoVal: (req: any) => set((state: any) => ({ promoVal: req })),

    selectedPromoVal: 1,
    setSelectedPromoVal: (req: any) => set((state: any) => ({ selectedPromoVal: req })),

    numberOfPromos: {},
    setnumberOfPromos: (req: any) => set((state: any) => ({ numberOfPromos: req })),

    //To store all sellIn customer to check for granularity level
    sellInCustomerHashMap: {},
    setSellInCustomerHashMap: (req: any) => set((state: any) => ({ sellInCustomerHashMap: req })),

    //bi report updated
    biReportUpdated: false,
    setBiReportUpdated: (req: any) => set((state: any) => ({ biReportUpdated: req })),

    //to select default Sub Category (on load) when tab changed
    defaultSubCategory: [],
    setDefaultSubCategory: (req: any) => set((state: any) => ({ defaultSubCategory: req })),

    //To store source and scope data
    ownMasterData: {},
    setOwnMasterData: (req: any) => set((state: any) => ({ ownMasterData: req })),

    crossMasterData: {},
    setCrossMasterData: (req: any) => set((state: any) => ({ crossMasterData: req })),

    //Regression API body
    regressionApiBodyOwn: [],
    setRegressionApiBodyOwn: (req: any) => set((state: any) => ({ regressionApiBodyOwn: req })),

    //Regression API body
    regressionApiBodyCross: [],
    setRegressionApiBodyCross: (req: any) => set((state: any) => ({ regressionApiBodyCross: req })),

    //Original data array of obj {'2019':[]}
    originalData: [],
    setOriginalData: (req: any) => set((state: any) => ({ originalData: req })),

    //Tree data for storing check box states
    treeData: [],
    setTreeData: (req: any) => set((state: any) => ({ treeData: req })),

    //data points
    dataPoints: [],
    setDataPoints: (req: any) => set((state: any) => ({ dataPoints: req })),

    //reset
    reset: false,
    setReset: (req: any) => set((state: any) => ({ reset: req })),

    // selectedCustomer
    selectedCustomer: "",
    setSelectedCustomer: (req: any) => set((state: any) => ({ selectedCustomer: req })),

    // SEt FIxed COST Include or Exclude
    fixedCostInclude: "Y",
    setFixedCostInclude: (req: any) => set((state: any) => ({ fixedCostInclude: req })),

    weekRangeData: [],
    setWeekRangeData: (req: any) => set((state: any) => ({ weekRangeData: req })),

    ParsedData: [],
    setParsedData: (req: any) => set((state: any) => ({ ParsedData: req })),

    ownTabScopeData: [],
    setOwnTabScopeData: (req: any) => set((state: any) => ({ ownTabScopeData: req })),

    crossTabParsedData: [],
    setcrossTabParsedData: (req: any) => set((state: any) => ({ crossTabParsedData: req })),

    crossTabScopeData: [],
    setCrossTabScopeData: (req: any) => set((state: any) => ({ crossTabScopeData: req })),

    subCustomerData: [],
    setSubCustomerData: (req: any) => set((state: any) => ({ subCustomerData: req })),

    crossTabDataForWeekRange: [],
    setCrossTabDataForWeekRange: (req: any) => set((state: any) => ({ crossTabDataForWeekRange: req })),

    userSelectedWeekRangesCross: [],
    setUserSelectedWeekRangesCross: (req: any) => set((state: any) => ({ userSelectedWeekRangesCross: req })),

    userSelectedWeekRangesPromotional: [],
    setUserSelectedWeekRangesPromotional: (req: any) => set((state: any) => ({ userSelectedWeekRangesPromotional: req })),

    isClearSelectedWeeks: false,
    setClearSelectedWeeks: (req: any) => set((state: any) => ({ isClearSelectedWeeks: req })),

    userSelectedWeekRangesOwn: [],
    setUserSelectedWeekRangesOwn: (req: any) => set((state: any) => ({ userSelectedWeekRangesOwn: req })),

    selectedWeeksApply: [],
    setSelectedWeeksApply: (req: any) => set((state: any) => ({ selectedWeeksApply: req })),

    selectedProductsForScope: {},
    setSelectedProductsForScope: (req: any) => set((state: any) => ({ selectedProductsForScope: req })),

    selectedProductsHierarchy: {} as any,
    setSelectedProductsHierarchy: (req: any) => set((state: any) => ({ selectedProductsHierarchy: req })),

    selectedCustomersHierarchy: {} as any,
    setSelectedCustomersHierarchy: (req: any) => set((state: any) => ({ selectedCustomersHierarchy: req })),

    selectedSimulationFromDate: '',
    setSelectedSimulationFromDate: (req: any) => set((state: any) => ({ selectedSimulationFromDate: req })),

    selectedSimulationToDate: '',
    setSelectedSimulationToDate: (req: any) => set((state: any) => ({ selectedSimulationToDate: req })),

    selectedSimulationProducts: [],
    setSelectedSimulationProducts: (req: any) => set((state: any) => ({ selectedSimulationProducts: req })),

    selectedSimulationCustomers: [],
    setSelectedSimulationCustomers: (req: any) => set((state: any) => ({ selectedSimulationCustomers: req })),

    selectedSimulationPromoNames: [],
    setSelectedSimulationPromoNames: (req: any) => set((state: any) => ({ selectedSimulationPromoNames: req })),

    simulationFilterProducts: [],
    setSimulationFilterProducts: (req: any) => set((state: any) => ({ simulationFilterProducts: req })),

    selectedSimulationFilterProducts: [],
    setSelectedSimulationFilterProducts: (req: any) => set((state: any) => ({ selectedSimulationFilterProducts: req })),

    selectedPromoDetails: {promoName : '', type: '', fromView: false,},
    setSelectedPromoDetails: (req: any) => set((state: any) => ({selectedPromoDetails : req})),

    simulatedProductDetails: [],
    setSimulatedProductDetails: (req: any) => set((state: any) => ({simulatedProductDetails : req})),

    enableDownloadButton: false,
    setEnableDownloadButton: (req: any) => set((state: any) => ({enableDownloadButton : req}))

})

const useStore = create(persist(store, { name: 'req' }));

export default useStore;