import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import useStore from "../store";
import CustomSearch from "../CustomSearch";
import PostRoiGrid from "./PostRoiGrid";
import RoiDetails from "./roiDetails";
import RoiQuadrant from "./roiQuadrant";
import PromoReport from "./promoReport";
import GroupSelect from "./groupSelect";
import QuadrantSelector from "./QuadrantSelector";
import NewCal from "../calender";
import PostRoi from "./PostROIDataGrid";
import { processCustomerHierarchy } from "../util";
import { ApiRequestService } from '../services/api-service';
import { useCustomerHierarchy, useProductHierarchy, useSellInSellOutCheck } from "../services/queries";
import { useEventIDData } from '../services/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
    width: 345,
  },
  gridPaper: {
    backgroundColor: theme.palette.primary.light,
    margin: '10px auto',
    width: '100%'
  },
  media: {
    height: 140,
  },
  title: {
    color: theme.palette.primary.main,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
  },
  paperDiv: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
      height: theme.spacing(12),
    },
  },

  chartDiv: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
      height: theme.spacing(100),
    },

  },

  defaultTxt: {
    padding: "20px",
    margin: "15px auto",
    fontSize: "1.3rem",
    textAlign: "center",
  },

  dateLinks: {
    cursor: "pointer",
    fontWeight: 700,
    marginLeft: "30px",
  },
  dateLinksSpan: {
    marginRight: "40px",
    paddingBottom: "5px",
    color: "#0000b3",
  },
  dateLinksSpanActive: {
    borderBottom: "2px solid",
  },
  datedefaultText: {
    marginRight: "40px",
  },
  dataDiv: {
    // border: '1px solid',
    padding: theme.spacing(1),
    width: 390,
    backgroundColor: theme.palette.background.light,
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    height: "35rem",
  },
  note: {
    alignSelf: "centre",
  },
  clearAll: {
    color: "#0000b3",
    opacity: 0.8,
    fontWeight: 400,
    cursor: "pointer",
    marginLeft: "15px",
  },
  btnSucess: {
    background: '#163c62',
    color: '#fff',
    textTransform: 'none',
    margin: '5px 10px 0',
    padding: '6px 35px',
    borderRadius: '10px',
    textDecoration: 'none',
    cursor: 'pointer'
  },

  listItem: {
    marginLeft: "10px",
    listStyle: "none",
    borderRadius: "5px",
  },
  radioitem: {
    marginLeft: '10px !important',
    marginBottom: "10px",
    marginTop: "-5px",
    marginRight: "5px !important"
  }
}));

const PromoPerformanceOther = () => {
  const classes = useStyles();
  const [kcProductData, setKcProductData] = useState();
  const [kcEventData, setKcEventData] = useState();
  const [isROIFileDownloading, setIsROIFileDownloading] = useState(false);
  const [clearProduct, setClearProduct] = useState(false);
  const [clearPromoType, setClearPromo] = useState(false);
  const [clearCustomer, setClearCustomer] = useState(false);
  const [clearEventId, setClearEventId] = useState(false);
  const [apidataLocal, setApidataLocal] = useState([]);
  const [clearTime, setClearTime] = useState(false);

  let selectedCountry = useStore((state) => state.selectedCountry);
  const setSelectedEventId = useStore((state) => state.setSelectedEventId);
  const setSelectedEventIdNew = useStore((state) => state.setSelectedEventIdNew);
  const [kpiType, setkpiType] = useState('case');
  let selectedSource = useStore((state) => state.selectedSource);
  const setSelectedSource = useStore((state) => state.setSelectedSource);
  let selectedBaseLineCustomerNew = useStore((state) => state.selectedBaseLineCustomerNew);
  let selectedBaseLineProductNew = useStore((state) => state.selectedBaseLineProductNew);
  let setSelectedYearWeeks = useStore((state) => state.setSelectedYearWeeks);
  const startMonth = useStore((state) => state.startMonth);
  const endMonth = useStore((state) => state.endMonth);
  const setSelectedBaseLineProductNew = useStore((state) => state.setSelectedBaseLineProductNew);
  const setSelectedPromotionalItem = useStore((state) => state.setSelectedPromotionalItem);
  let groupBy = useStore((state) => state.groupBy);
  const setSelectedBaseLineCustomerNew = useStore( (state) => state.setSelectedBaseLineCustomerNew);
  const setSelectedEventIdState = useStore((state) => state.setSelectedEventIdState);
  let setSelectedBaseLineFilter = useStore((state) => state.setSelectedBaseLineFilter);
  const setSelectedProductsHierarchy = useStore(state => state.setSelectedProductsHierarchy);
  const setSelectedProductValues = useStore(state => state.setSelectedProductValues);
  const setSelectedCustomerValues = useStore(state => state.setSelectedCustomerValues);
  const setSelectedEventIdValues = useStore((state) => state.setSelectedEventIdValues);
  const [postRoiEventIdsPayload, setPostRoiEventIdsPayload] = useState(null);
  const selectedCustomersHierarchy = useStore(state => state.selectedCustomersHierarchy);
  const setSelectedCustomersHierarchy = useStore(state => state.setSelectedCustomersHierarchy);
  const selectedProductsHierarchy = useStore(state => state.selectedProductsHierarchy);
  const selectedEventIdValues = useStore(state => state.selectedEventIdValues);
  const selectedQuadrant = useStore(state => state.selectedQuadrant);
  const setSellInCustomerHashMap = useStore((state) => state.setSellInCustomerHashMap);

  let sourceUrl = "";
  for (var source of selectedSource) {
    sourceUrl += `&source=${source}`
  }

  //services
  const { isLoading: isCustomerData, data: customerData } = useCustomerHierarchy(`${selectedCountry}${sourceUrl}&feature=Promo Performance`);
  const { isLoading: isSellInCustomerData, data: sellInCustomerData } = useCustomerHierarchy(`${selectedCountry}&source=SELL IN&feature=Promo Performance`);
  const { isLoading: isProductData, data: productData } = useProductHierarchy(`${selectedCountry}${sourceUrl}`, "Promo Performance");
  const { isLoading: isSourceData, data: sourceData } = useSellInSellOutCheck(selectedCountry);
  const { isLoading: isEventIDData, data: eventIDData } = useEventIDData(postRoiEventIdsPayload);

  useEffect(() => {
    let payload = {
      country: selectedCountry,
      customers: selectedCustomersHierarchy?.soldTo,
      planLevels: selectedCustomersHierarchy?.planLevel,
      salesExecutive: selectedCustomersHierarchy?.salesExecutive,
      source: selectedSource,
      fromDate: startMonth,
      toDate: endMonth,
      eans: selectedProductsHierarchy?.products,
      categories: selectedProductsHierarchy?.categories,
      subCategories: selectedProductsHierarchy?.subCategories,
      brands: selectedProductsHierarchy?.brands,
      subBrands: selectedProductsHierarchy?.subBrands,
      groupBy,
      eventIds: selectedEventIdValues,
      quadrants: selectedQuadrant,
      kpiType: kpiType
    };

    setPostRoiEventIdsPayload(payload);
  }, [selectedCountry, startMonth, endMonth,
    groupBy, selectedSource, selectedQuadrant,
    selectedCustomersHierarchy?.soldTo,
    selectedCustomersHierarchy?.planLevel,
    selectedCustomersHierarchy?.salesExecutive,
    selectedProductsHierarchy?.products,
    selectedProductsHierarchy?.categories,
    selectedProductsHierarchy?.subCategories,
    selectedProductsHierarchy?.brands,
    selectedProductsHierarchy?.subBrands, ,
    selectedBaseLineCustomerNew, selectedBaseLineProductNew, selectedEventIdValues]);

  useEffect(() => {
    if (selectedBaseLineCustomerNew.length === 0) {
      setSelectedCustomersHierarchy({})
    }

    if (selectedBaseLineProductNew.length === 0) {
      setSelectedProductsHierarchy({})
    }
  }, [selectedBaseLineProductNew, selectedBaseLineCustomerNew])

  useEffect(() => {
    if (!isEventIDData) {
      setKcEventData(generateEventId(eventIDData));
      setSelectedEventIdState(generateEventId(eventIDData));
    }
  }, [isEventIDData]);

  useEffect(() => {
    if (!isProductData && selectedCountry !== undefined && productData) {
      setApidataLocal(productData);
      setKcProductData(productData['countries'][selectedCountry]);
    }
  }, [isProductData, productData]);
  
  useEffect(() => {
    setSelectedEventId('');
    setSelectedEventIdNew('');
    setSelectedEventIdValues([]);
    setSelectedEventIdState(generateEventId(eventIDData));
  }, [])

  useEffect(() => {
    if (sellInCustomerData) {
      let Values = calculateInitial(sellInCustomerData);
      setSellInCustomerHashMap({ ...Values });
    }
  }, [sellInCustomerData]);

  const clearProductData = () => {
    setSelectedBaseLineProductNew("");
    setSelectedBaseLineFilter(selectedBaseLineCustomerNew);
    setClearProduct(true);
    setSelectedProductValues([]);
    setSelectedProductsHierarchy({});
  };

  const clearPromo = () => {
    setSelectedPromotionalItem("");
    setClearPromo(true);
  };

  const clearCustomerData = () => {
    setSelectedBaseLineCustomerNew("");
    setSelectedBaseLineFilter(selectedBaseLineProductNew);
    setClearCustomer(true);
    setSelectedSource([]);
    setSelectedCustomerValues([]);
  };

  const generateEventId = (events) => {
    let eventsLevel = [];
    for (const key in events) {
      let itemList = {
        name: events[key],
        isChecked: false,
      };
      eventsLevel.push(itemList);
    }
    return eventsLevel;
  };

  const clearEventData = () => {
    setSelectedEventIdState(generateEventId(eventIDData));
    setSelectedEventId('');
    setSelectedEventIdValues([]);
    setClearEventId(true);
    setSelectedEventIdNew('');
  }

  const resetEventId = () => {
    setClearEventId(false);
  }

  const clearTimeData = () => {
    setClearTime(true);
    setSelectedYearWeeks([]);
  }

  const resetTime = () => {
    setClearTime(false);
  }

  const resetProduct = () => {
    setClearProduct(false);
  }

  const resetCustomer = () => {
    setClearCustomer(false);
  }

  
  const changeKPIType = (e) => {
    setkpiType(e.target.value)
  }

  function calculateInitial(data) {
    const processed = processCustomerHierarchy(data, null)
    const transformed = transformDataUnique(processed);
    return transformed;
  }

  function transformDataUnique(values) {
    let arr1 = ['channel', 'salesOffice', 'planLevel', 'soldTo'];
    arr1.map((val) => {
      values[val] = [
        ...new Map(values[val].map((item) => [item['name'], item])).values(),
      ];
    });
    arr1.map((val) => {
      values[val].sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
      });
    });

    return values;
  }

  const downloadFile = async () => {
    try {
      setIsROIFileDownloading(true);
      const apiRequestService = ApiRequestService.createInstance();
      const res = await apiRequestService.roiFileDownload(postRoiEventIdsPayload)
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PromoPerformance.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      setIsROIFileDownloading(false);
    } catch (error) {
      console.log('ROI download error')
      console.log(error)
    }
  }

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={1}>

          <Grid item xs={12} sm={6}>
            <div className={classes.paperDiv}>
              <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                Customer
                <span className={classes.clearAll} id="clearCustomer" onClick={() => clearCustomerData()}>
                  Clear
                </span>
              </div>
              {!isCustomerData && !isSourceData && !isSellInCustomerData && (
                <CustomSearch data={customerData} sellInData={sellInCustomerData} dataFor="customer" tabName="postROI" hasScopeData={sourceData?.available} clear={clearCustomer} reset={resetCustomer} />
              )}

              {(isCustomerData || isSourceData || isSellInCustomerData) && (
                <>
                  <CustomSearch dataFor="customer" clear={clearCustomer} tabName="postROI" hasScopeData={sourceData?.available} reset={resetCustomer} /> Loading...!
                </>
              )}
            </div>
          </Grid>


          <Grid item xs={12} sm={6}>
            <div className={classes.paperDiv}>
              <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                Product
                <span className={classes.clearAll} id="clearProducts" onClick={() => clearProductData()}>
                  Clear
                </span>
              </div>

              {!isProductData && (
                <>
                  <CustomSearch data={kcProductData} dataFor="Product" tabName="postROI" clear={clearProduct} reset={resetProduct} />
                </>
              )}

              {isProductData && (
                <>
                  <CustomSearch dataFor="Product" clear={clearProduct} tabName="postROI" reset={resetProduct} /> Loading...!
                </>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={classes.paperDiv}>
              <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                Select Time Interval
                <span className={classes.clearAll} onClick={() => clearTimeData()}>
                  Clear
                </span>
              </div>
              <Paper className={classes.paperDiv}>
                {sellInCustomerData ?
                  <div>
                    <NewCal clear={clearTime} reset={resetTime} />
                  </div> : <>
                    <div>
                      <NewCal clear={clearTime} reset={resetTime} />
                    </div> Loading...!
                  </>}

              </Paper>
            </div>
          </Grid>


          <Grid item xs={12} sm={6}>
            <div className={classes.paperDiv}>
              <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                Event ID
                <span className={classes.clearAll} onClick={() => clearEventData()}>
                  Clear
                </span>
              </div>
              {
                !isEventIDData && (
                  <CustomSearch data={kcEventData} dataFor="EventId" clear={clearEventId} reset={resetEventId} />
                )
              }
              {
                isEventIDData && (
                  <CustomSearch dataFor="EventId" clear={clearEventId} reset={resetEventId} />
                )
              }
            </div>
          </Grid>

        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12} container>
            <Grid item xs={12} sm={4}>
              <div className={classes.paperDiv}>
                <Paper className={classes.paperDiv} style={{ height: 300 }}>
                  <div>
                    <RoiQuadrant kpiType={kpiType} />
                  </div>
                </Paper>
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <div className={classes.paperDiv}>
                <Paper className={classes.paperDiv} style={{ height: 300 }}>


                  {selectedCountry && <div>
                    <PromoReport kpiType={kpiType} />
                  </div>}

                  {!selectedCountry && (
                    <p className={classes.defaultTxt}>
                      Please select country to view Reports/Graph.
                    </p>
                  )}
                </Paper>
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <div className={classes.paperDiv}>
                <Paper className={classes.paperDiv} style={{ height: 300 }}>
                  <div>
                    <RoiDetails kpiType={kpiType} />
                  </div>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {
          selectedCountry === 'UK' &&
          <div className="flex flex-col ml-2 p-2 mt-3 border border-gray-400 w-fit">
            <div className="font-bold ">
              KPI Type
            </div>
            <div>
              <FormControl required
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
                defaultValue="case"
                color="primary"
                onChange={changeKPIType}
                id="kpiType">
                <RadioGroup className="flex mt-1 " row="true">
                  <FormControlLabel checked={kpiType === "case"} value="case" control={<Radio color='primary' />} label="Per Case" className="w-32" />
                  <FormControlLabel checked={kpiType === "osku"} value="osku" control={<Radio color='primary' />} label="Per SU" className="w-32" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        }

        <Paper className={classes.gridPaper}>
          <div className="flex justify-between ml-2 mr-5">
            <div className="flex">
              <div className="flex mr-7">
                <h3
                  style={{
                    justifyContent: "left",
                    display: "flex",
                    marginTop: 25,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  Group by :
                </h3>
                <GroupSelect style={{ justifyContent: "left" }} />
              </div>
              <div className="flex">
                <h3
                  style={{
                    justifyContent: "right",
                    display: "flex",
                    marginTop: 23,
                    marginLeft: 10,
                    marginRight: 10
                  }}>
                  Quadrant :
                </h3>
                <QuadrantSelector style={{ justifyContent: "center", zIndex : "999" }} />
              </div>
            </div>
            <div className="block" style={{height:'35px', margin:'10px 5px 0 0'}}>
              <button onClick={downloadFile} className={classes.btnSucess}> Download </button>
            </div>
          </div>
          <div className="" style={{width:'99%', margin:'10px auto', paddingBottom:'10px'}}>
            <PostRoi kpiType={kpiType} />
          </div>
        </Paper>

        <Dialog
          open={isROIFileDownloading}
          keepMounted
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle className="pt-0">
            {"Your request is being processed"}
          </DialogTitle>
        </Dialog>

      </Grid>

    </>
  );
};

export default PromoPerformanceOther;
