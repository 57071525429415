import { Chip, Box, Tooltip } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { InfoOutlined, ExpandMore } from '@mui/icons-material';

type Exclusion = {
  product: string,
  customer: string
};
type PropTypes = {
  exclusions: Exclusion[],
  exclusionMessage: string
};

export const Exclusions = (props: PropTypes) => {

  const { exclusionMessage, exclusions } = props;
  exclusions.sort((a: any, b: any) => a.product > b.product ? 1 : -1);

  return <Box border={1} borderColor={"#e0e0e0"}>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div>Exclusions&nbsp;
          <Tooltip title={exclusionMessage} arrow placement='top'>
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box flexDirection="row">
          {exclusions?.map((item: Exclusion, idx: number) => {
            return <Chip key={idx} variant="outlined" size="small" label={item.customer + ' | ' + item.product} style={{marginRight: "8px", marginBottom: "8px"}} />

          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  </Box>
}