import React, {useState, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import clsx from 'clsx';
import moment from "moment";

import useStore from "../../../../store";
import Toaster from '../../../../commonMuiComponents/Toaster';
import PromoNameFilter from './PromoNameFilter';
import CustomerFilter from './CustomerFilter';
import DateSelector from './DateSelector/DateSelector';
import ProductSelector from './ProductSelector/ProductSelector';

const useStyles: any = makeStyles((theme) => ({
  boxDiv: {
    display: "flex",
    flexWrap: "wrap",
    width: '23%',
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
    },
  },
  dateSelector: {
    width: '15.5%',
  },
  fieldHeight: {
    height: '56px !important',
  },
  clearAll: {
    color: "#0000b3",
    opacity: 0.8,
    fontWeight: 400,
    cursor: "pointer",
    marginLeft: "15px",
  },
}));

type SimualtionFilterProps = {
  promotions: string[],
  setFromDate: any,
  setToDate: any,
  fromDate: any,
  toDate: any
}

const SimulationFilter = ({promotions, setFromDate, setToDate, fromDate, toDate} : SimualtionFilterProps) => {
  const classes: any = useStyles();

  const [clearProduct, setClearProduct] = useState(false);
  const [clearPromoNames, setClearPromoNames] = useState(false);
  const [clearCustomer, setClearCustomer] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState({ message: '', type: '' });

  const setSelectedSimulationFromDate = useStore((state) => state.setSelectedSimulationFromDate);
  const setSelectedSimulationToDate = useStore((state) => state.setSelectedSimulationToDate);

  const clearProductData = () => {
    setClearProduct(true);
  };

  const clearPromo = () => {
    setClearPromoNames(true);
  };

  const clearCustomers = () => {
    setClearCustomer(true);   
  };

  const resetClear = () => {
    setClearProduct(false);
  }

  const resetCustomer = () => {
    setClearCustomer(false);
  }

  const resetPromo = () => {
    setClearPromoNames(false);
  }

  const clearSelectedDate = (event: any, id: string) => {
    if(id === 'fromDate') {
      setFromDate('')
    } else if (id === 'toDate') {
      setToDate('');
    }
  }

  const inputValueChange = (event : any, target: string) : void => {
    switch (target) {
      case 'fromDate':
        setFromDate(event);
        setSelectedSimulationFromDate(event);
        // calDuration(event, toDate);
        break;
      case 'toDate':
        setToDate(event);
        setSelectedSimulationToDate(event);
        // calDuration(fromDate, event);
        break;
      default:
        break;
    }
  }

  const calDuration = (startDate: any, endDate: any) => {
    let stDate = moment(startDate, "YYYY/MM/DD");
    let edDate = moment(endDate, "YYYY/MM/DD");
    let durationVal = edDate.diff(stDate, 'days');

    if (durationVal < 0) {
      setShowAlert({
        type: 'error',
        message: 'To date must be greater than From date'
      })
      setToDate('');
    }
  }

  return (
    <Fragment>
      <Grid item 
        xs={12}
        sm={12}   
        style={{ 
          margin: 'auto',
          width:'98%'
        }}
      >
        <Grid container style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }} spacing={1}>
          <Box className={classes.boxDiv}>
            <div style={{ height: 10, color: "black", fontWeight: "bold", marginBottom:'5px' }}>
              Customer
              <span className={classes.clearAll} id="clearCustomer" onClick={() => clearCustomers()}>
                Clear
              </span>
            </div>
            <Box className={clsx(classes.fieldHeight)}>
              <CustomerFilter clearCustomers={clearCustomer} resetCustomer={resetCustomer}/>
            </Box>
          </Box>
      
          <Box className={classes.boxDiv}>
            <div style={{ height: 10, color: "black", fontWeight: "bold", marginBottom:'5px' }}>
              Product
              <span className={classes.clearAll} id="clearCustomer" onClick={() => clearProductData()}>
                Clear
              </span>
            </div>
            <Box className={(classes.fieldHeight)}>
              <ProductSelector dataFor="Promo" clear={clearProduct} resetClearProduct={resetClear} />
            </Box>
          </Box>

          <Box className={clsx(classes.boxDiv, classes.dateSelector)}>
            <div  style={{ height: 10, color: "black", fontWeight: "bold", marginBottom:'5px' }} >
              From Date
              <span onClick={(event) => clearSelectedDate(event, 'fromDate')} className={classes.clearAll} id="clearProducts" >
                Clear
              </span>
            </div>
            <div style={{width:'100%'}}>
              <DateSelector
                name="fromDate"
                id="from-date"
                value={fromDate}
                inputValueChange={(e) => inputValueChange(e, 'fromDate')}
              />
            </div>
          </Box>

          <Box className={clsx(classes.boxDiv, classes.dateSelector)}>
            <div  style={{ height: 10, color: "black", fontWeight: "bold", marginBottom:'5px' }} >
              To Date
              <span onClick={(event) => clearSelectedDate(event, 'toDate')} className={classes.clearAll} id="clearProducts" >
                Clear
              </span>
            </div>
            <div style={{width:'100%'}}>
              <DateSelector
                name="toDate"
                id="to-date"
                value={toDate}
                inputValueChange={(e) => inputValueChange(e, 'toDate')}
              />
            </div>
          </Box>
      
          <Box className={classes.boxDiv}>
            <div  style={{ height: 10, color: "black", fontWeight: "bold", marginBottom:'5px' }} >
              Promo Name
              <span onClick={() => clearPromo()} className={classes.clearAll} id="clearProducts" >
                Clear
              </span>
            </div>
            <PromoNameFilter availablePromotions={promotions} clearPromoNames={clearPromoNames} resetPromo={resetPromo}/>
          </Box>
        </Grid>
        <Toaster showAlert={showAlert} />
      </Grid>
    </Fragment>
  )
}

export default SimulationFilter;