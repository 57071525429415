
export const validateInput = (input: any, validationType: string) => {
  switch (validationType) {
    case "positiveNumber":
      return (/^[0-9.]+$/.test(input));
    case "positivePercentage":
      //0.1 < x < 99.9 
      return (/^(?!0(\.0)?$)(?:\d{1,2}(?:\.\d)?|99(?:\.0)?)$/.test(input));
  };
};

