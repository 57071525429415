import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { SingleGrid } from "../../single_grid";
import { ElasticityScopeRecord, Scope, CustomSortFuction, PriceType, Source } from "../../types";
import { SelectionRow } from "../selection_row";
import AppPagination from '../../Pagination';
import { Exclusions } from "../../Exclusions";

type Props = {
    records: ElasticityScopeRecord[],
    selectedCountry: string,
    unselectRow: (initial?: string, target?: string, id?: any) => void,
    handleViewMore: (id: any, initial: string, target?: string, customer?: string) => void,
    loading: boolean,
    initialScopeData: any
    handleSelectedValues: (obj: any) => void,
    customSort?: CustomSortFuction,
    allCustomers: string[],
    filterCustomer: (name: string[]) => void,
    priceType: PriceType,
    scope: Scope,
    source?: Source,
    selectedProducts: any,
    selectedTargetProducts: any,
    pageData?: any,
    dataCount?: any,
    handlePageData?: any,
    setHiddenCustomersArray?: (customer: string[]) => void,
    useNewScopeBy: boolean,
    selectedCategoryLevel?: string,
    selectedProductLevel?: string,
    crossScopeDataExclusions?: any,
    crossScopeDataExclusionMessage?: any,
    userSelectedWeekRangesCross?: any
}

export const CrossMarketScope = ({ records, selectedCountry, unselectRow, handleViewMore, loading, initialScopeData, handleSelectedValues, customSort, selectedCategoryLevel, selectedProductLevel,
    allCustomers, filterCustomer, priceType, scope, source, selectedProducts, selectedTargetProducts, pageData, dataCount, handlePageData, setHiddenCustomersArray, useNewScopeBy,
    crossScopeDataExclusions, crossScopeDataExclusionMessage, userSelectedWeekRangesCross }: Props) => {
    const [comparisonCount, setcomparisonCount] = useState(0);
    const sortedAllCustomers = [...allCustomers].sort();
    const sortedRecords = [...records].sort((record1: any, record2: any) => (record1.customer && record2.customer) && record1.customer.toLowerCase() > record2.customer.toLowerCase() ? 1 : record1.customer.toLowerCase() < record2.customer.toLowerCase() ? -1 : 0);

    const [canShowName, setcanShowName] = useState(false);
    const [canShowCustomerFilter, setcanShowCustomerFilter] = useState(false);
    const [hiddenCustomers, setHiddenCustomers] = useState<string[]>([]);

    useEffect(() => {
        setHiddenCustomers([]);
    }, [])

    useEffect(() => {
        if (selectedProducts.length === 0 || selectedTargetProducts.length === 0) {
            setHiddenCustomers([]);
        }
    }, [selectedProducts, selectedTargetProducts])


    const handleSelected = (kcObj: any) => {
        handleSelectedValues(kcObj)
    }

    const handleSort: CustomSortFuction = (field, direction) => {
        if (customSort) {
            customSort(field, direction)
            setcanShowName(true)
        }
    }

    const ScopeFilterInGrid = () => <div className="relative flex justify-center p-2" data-testid="scope-filter">
        <button data-testid="filter-button" className="bg-white border border-primary text-primary rounded text-sm p-2" onClick={() => setcanShowCustomerFilter(!canShowCustomerFilter)}>Filter {scope === Scope.CUSTOMER || scope.toLowerCase() === Scope.PCUST ? 'Customer' : 'Channel'}</button>
        {canShowCustomerFilter ?
            <div data-testid="customer-filter" className="absolute bg-white top-12 flex flex-col p-5 border shadow border-primary rounded z-50 filter-customer-channel-div" onMouseLeave={() => setcanShowCustomerFilter(false)}>
                <div className="hover:bg-blue-200 p-1 flex justify-start items-center gap-1" onClick={(e) => {
                    if (hiddenCustomers.length === 0) {
                        setHiddenCustomers(sortedAllCustomers)
                        filterCustomer(sortedAllCustomers)
                        if (setHiddenCustomersArray !== undefined) {
                            setHiddenCustomersArray(sortedAllCustomers)
                        }
                    } else {
                        setHiddenCustomers([])
                        filterCustomer([])
                        if (setHiddenCustomersArray !== undefined) {
                            setHiddenCustomersArray([])
                        }
                    }
                }}
                >
                    <input data-testid="all-checkbox" type="checkbox" checked={hiddenCustomers.length === 0} />
                    <span data-testid="all-checkbox-text" className="text-sm"> <b>All</b> </span>
                </div>
                {sortedAllCustomers.map((customer, idx) => {
                    return <div key={idx} className="hover:bg-blue-200 p-1 flex justify-start items-center gap-1" onClick={() => {
                        if (hiddenCustomers.includes(customer)) {
                            const except = hiddenCustomers.filter(e => e !== customer)
                            setHiddenCustomers(except)
                            filterCustomer(except)
                            if (setHiddenCustomersArray !== undefined) {
                                setHiddenCustomersArray(except)
                            }
                        } else {
                            const added = [...hiddenCustomers, customer]
                            setHiddenCustomers(added)
                            filterCustomer(added)
                            if (setHiddenCustomersArray !== undefined) {
                                setHiddenCustomersArray(added)
                            }
                        }
                    }}>
                        <input data-testid="customer-checkbox" type="checkbox" checked={!hiddenCustomers.includes(customer)} />
                        <span data-testid="customer-checkbox-text" className="text-sm"> {customer} </span>
                    </div>
                })
                }
            </div> : null
        }
    </div>

    return (
        <div className="mt-2 flex flex-col  overflow-x-hidden relative w-full" style={{ minHeight: 5000 }}>
            <SelectionRow
                initialScopeData={initialScopeData}
                handleSelectedValues={handleSelected}
                comparisonAdded={() => setcomparisonCount(comparisonCount + 1)}
                emptySelection={true}
                source={source}
                scope={scope}
                selectedProducts={selectedProducts}
                selectedTargetProducts={selectedTargetProducts}
                useNewScopeBy={useNewScopeBy}
                selectedCategoryLevel={selectedCategoryLevel}
                selectedProductLevel={selectedProductLevel}
                selectedCountry={selectedCountry}
            />
            <div className="flex bg-white w-full relative">
                <div className={`absolute flex flex-col left-0 right-0`} >
                    <div className="flex flex-row justify-between">
                        {source === "POS" && isEmpty(selectedTargetProducts) ? null :
                            <div className="left-0 w-2/6 border border-gray-300 flex flex-col" style={{ width: '36rem' }}>
                                {/* {(records && records.length > 0)  && (source ==="POS" && selectedTargetProducts.length>0) ? <ScopeFilterInGrid/> : null }                     */}
                                <ScopeFilterInGrid />
                            </div>}
                            <AppPagination
                                pageData={pageData}
                                dataCount={dataCount}
                                handlePageData={handlePageData}
                                fromTab='cross'
                                applyPadding={false}
                            />
                    </div>
                    {userSelectedWeekRangesCross.length > 0 && crossScopeDataExclusions?.length > 0 && <Exclusions exclusions={crossScopeDataExclusions} exclusionMessage={crossScopeDataExclusionMessage} />}
                    <div className="flex w-full border border-gray-300">
                        {sortedRecords && sortedRecords.length > 0 ?
                            <div className="border w-full border-gray-300 overflow-x-scroll flex">
                                {sortedRecords.map((record, idx) => {
                                    return (<div className="flex flex-col  border-l border-t border-gray-300 w-full w-full" key={idx}>
                                        <div data-testid="customer-name" className="flex  h-16 self-center items-center" >{record.customer}</div>
                                        <SingleGrid
                                            loading={false}
                                            records={record.data === undefined ? [] : record.data}
                                            customSort={handleSort}
                                            priceType={priceType}
                                            scope={scope}
                                            showName={true}
                                            showOptions={true}
                                            selectedCountry={selectedCountry}
                                            showTargetProduct={true}
                                            handleViewMore={handleViewMore}
                                            useNewScopeBy={useNewScopeBy}
                                            source={source}
                                            unselectRow={unselectRow}
                                            key={idx}
                                        />
                                    </div>)
                                })
                                }
                            </div>
                            : <SingleGrid
                                loading={false}
                                records={[...records]}
                                showTargetProduct={true}
                                scope={scope || Scope.COUNTRY}
                                priceType={priceType}
                                showName={true}
                                showOptions={true}
                                selectedCountry={selectedCountry}
                                handleViewMore={handleViewMore}
                                unselectRow={unselectRow}
                                useNewScopeBy={useNewScopeBy}
                                source={source}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}