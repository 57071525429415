export let PNL_KEYS = {
    "gsv": "GSV (₩)",
    "rir": "RIR/TI (₩)",
    "nsv": "NSV (₩)",
    "csd": "Cost Of Sales + Distribution (₩)",
    "gp": "GP (₩)",
    "gpPercent": "GP %",
    "rsv": "RSV Excl Tax (₩)",
    "rtp": "Retailer Profit (₩)",
    "rtpPercent": "Retailer Profit %",
};

export let PNL_KEYS_INC = {
    "gsv": "GSV (₩)",
    "rir": "RIR/TI (₩)",
    "nsv": "NSV (₩)",
    "csd": "Cost Of Sales + Distribution (₩)",
    "gp": "GP (₩)",
    "gpPercent": "GP %",
    "rsv": "RSV Excl Tax (₩)",
    "rtp": "Retailer Profit (₩)",
    "rtpPercent": "Retailer Profit %"
};

export let PNL_PARENT_KEYS = {
    "product": "product",
    "base": "base",
    "incremental": "incremental",
    "total": "total"
};

export const PROMOCALCULATIONKEYS = {
    "sellInListPrice": "Sell in List Price",
    "sellInListPriceOverride": "Sell in List Price Override",
    "tradingTerms": "Trading terms ",
    "baseSellInInvoicePrice": "Base Sell invoice Price",
    "promoSellInInvoicePrice": "Promo Sell in invoice Price",
    "baseSellOutPrice": "Base Sell out Price",
    "selloutManualInput": "Sell out Manual Input",
    "promoSellOutPrice": "Promo Sell out price/ RPP ",
    "discountValue": "Discount Value",
    "discountPercent": "Discount %",
    "baseVolume": "Base Volume ",
    "volumeOverride": "Volume Override",
    "promoVolume": "Promo Volume",
    "promoVolumeUplift": "Volume Uplift",
    "additionalFunding": "Additional Funding",
    "additionalFundingOverride": "Additional Funding Override",
}
export const KEYSFORTOTALVALUE = [
    "discountValue",
    "discountPercent",
    "baseVolume",
    "volumeOverride",
    "promoVolume",
    "promoVolumeUplift",
    "additionalFunding",
    "additionalFundingOverride",
]

export const PNL_TOTAL_KEYS = {
    "base": "baseTotals",
    "incremental": "incrementalTotals",
    "promo": "totalTotals"
}

export const PNL_ROI_KEYS = {
    "gpRoi": "GP ROI",
    "ykRoi": "YK ROI"
}
