import {useEffect} from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import { BrowserRouter as Router } from 'react-router-dom';
import SecureAll from './SecureAll';
import { createBrowserHistory } from "history";
import useStore from './store';

function App() {
  const history = createBrowserHistory();
  console.log({DEPLOYMENT_TYPE : process.env.REACT_APP_DEPLOYMENT_TYPE})

  const setPromoStateData = useStore((state) => state.setPromoStateData);
  const setPromoVal = useStore(
		(state) => state.setPromoVal
	);

  useEffect(() => {
  if(performance.navigation.type === 1){
			//alert("page reloaded");
      setPromoStateData({});
      setPromoVal(1)
		}else{
			console.log("page not loaded")
		}  

		
	}, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <div>
          <SecureAll/>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
