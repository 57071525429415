export const mockDropdownApiResposne = {
  "inputs":
    [
      {
        "label": "Promo sell in price",
        "internal": "PROMO_SELL_IN_PRICE",
        "types": [
          {
            "name": "Direct Price Input",
            "internal": "DIRECT_PRICE_INPUT"
          },
          {
            "name": "Promo deal to customer (fix amount)",
            "internal": "PROMO_DEAL_FIXED"
          },
          {
            "name": "Promo deal to customer (percentage)",
            "internal": "PROMO_DEAL_PERCENT"
          },
          {
            "name": "Expected Customer Margin",
            "internal": "EXPECTED_CUSTOMER_MARGIN"
          }
        ],
        "units": [
          "Packs"
        ]
      },
      {
        "label": "Promo sell out Price (RPP)",
        "internal": "PROMO_SELL_OUT_PRICE",
        "types": [
          {
            "name": "% off",
            "internal": "PERCENT_OFF"
          },
          {
            "name": "$ off",
            "internal": "FIXED_OFF"
          },
          {
            "name": "Multibuy",
            "internal": "MULTIBUY",
            "values": [
              "3 for 2",
              "1 for 2"
            ]
          },
          {
            "name": "Manual input",
            "internal": "MANUAL_INPUT"
          }
        ]
      },
      {
        "label": "Promo Volume",
        "internal": "PROMO_VOLUME",
        "types": [
          {
            "name": "Direct Input",
            "internal": "DIRECT_INPUT"
          },
          {
            "name": "Multiplier of base",
            "internal": "BASE_MULTIPLIER"
          }
        ]
      }
    ]
};

export const PnLResposne =
  [
    {
      "product": '18801166259658 - DEP Style Pad Slim L18',
      "base": {
        "grossSalesValue": 123,
        "rir": 1025,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 1010101010,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }

    },
    {
      "product": "18801166259672 - DEP Style Pad Slim Anshim L14",
      "base": {
        "grossSalesValue": 456,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }

    },
    {
      "product": "18801166259672 - DEP Style Pad Ultraslim L14",
      "base": {
        "grossSalesValue": 1231231,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 56757,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166259689 - DEP Style Pad Ultraslim Anshim L12",
      "base": {
        "grossSalesValue": 78900,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166383445 - 20 TN ACNE CLEANSING FOAM 100ml*2 (CP)",
      "base": {
        "grossSalesValue": 123456,
        "rir": 10025,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue":789101112,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 12221,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166383759 - TN AC Toner 230ml_19",
      "base": {
        "grossSalesValue": 12500,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166383766 - TN AC Lotion 190ml_19",
      "base": {
        "grossSalesValue": 463234,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166410608 - KLEENEX 99+ HW HERBPOWDER FOAMING 4L",
      "base": {
        "grossSalesValue": 12500,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166259641 - DEP Style Pad Charcoal Anshim M18",
      "base": {
        "grossSalesValue": 12500,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166259658 - DEP Style Pad Slim L18",
      "base": {
        "grossSalesValue": 12500,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166259665 - DEP Style Pad Slim Anshim L14",
      "base": {
        "grossSalesValue": 12500,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "18801166259672 - DEP Style Pad Ultraslim L14",
      "base": {
        "grossSalesValue": 12500,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
    {
      "product": "2 - product13",
      "base": {
        "grossSalesValue": 12500,
        "rir": 125,
        "netSalesValue": 15789,
        "costOfSalesPlusDistribution": 254,
        "grossProfit": 698,
        "grossProfitPercentage": 256,
        "rsv": 246,
        "retailerProfit": 478,
        "retailerProfitPercentage": 68789,
      },
      "incremental": {
        "grossSalesValue": 54,
        "rir": 7979,
        "netSalesValue": 5464,
        "costOfSalesPlusDistribution": 6546,
        "grossProfit": 64684,
        "grossProfitPercentage": 8797,
        "gpRoi": 126,
        "rsv": 978,
        "retailerProfit": 879,
        "retailerProfitPercentage": 146,
      },
      "promo": {
        "grossSalesValue": 4654,
        "rir": 464,
        "netSalesValue": 654654,
        "costOfSalesPlusDistribution": 4654,
        "grossProfit": 134,
        "grossProfitPercentage": 46546,
        "rsv": 56465,
        "retailerProfit": 4566,
        "retailerProfitPercentage": 156,
      }
    },
  ];


export const ProductsCalculatedArr = [
    {
      product: '18801166259658 - DEP Style Pad Slim L18',
      customer: 'cust1',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166259665 - DEP Style Pad Slim Anshim L14',
      customer: 'cust2',
      additionalFunding: 130,
      promoVolumeUplift: 440,
      promoVolume: 440,
      baseVolume: 184,
      discountPercent: 60,
      discountValue: 70,
      discountIsAbs: false,
      promoSellOutPrice: 800,
      baseSellOutPrice: 260,
      promoSellInInvoicePrice: 70,
      baseSellInInvoicePrice: 80,
      tradingTerms: 17,
      sellInListPrice: 48
    },
    {
      product: '18801166259672 - DEP Style Pad Ultraslim L14',
      customer: 'cust3',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166259689 - DEP Style Pad Ultraslim Anshim L12',
      customer: 'cust4',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166383445 - 20 TN ACNE CLEANSING FOAM 100ml*2 (CP)',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166383759 - TN AC Toner 230ml_19',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166383766 - TN AC Lotion 190ml_19',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166410608 - KLEENEX 99+ HW HERBPOWDER FOAMING 4L',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166259641 - DEP Style Pad Charcoal Anshim M18',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166259658 - DEP Style Pad Slim L18',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166259665 - DEP Style Pad Slim Anshim L14',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '18801166259672 - DEP Style Pad Ultraslim L14',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
    {
      product: '2 - product13',
      customer: 'cust6',
      additionalFunding: 100,
      promoVolumeUplift: 400,
      promoVolume: 200,
      baseVolume: 180,
      discountPercent: 40,
      discountValue: 0,
      discountIsAbs: false,
      promoSellOutPrice: 100,
      baseSellOutPrice: 200,
      promoSellInInvoicePrice: 20,
      baseSellInInvoicePrice: 10,
      tradingTerms: 10,
      sellInListPrice: 40
    },
  ]