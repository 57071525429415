import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import useStore from "../../../../../store";
import NestedCustomerSearch from "./NestedCustomerSearch";
import useClickOutside from "../../../../../ClickOutside";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { usePreRoiGetCustomersSelectors } from "../../../../../services/queries";
import usePromoSimulatorStore from "../../../../../store/promoSimulatorStore";

function CustomerSearch(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [openEventSelector, setOpenEventSelector] = React.useState(false);
  const [clear, setClear] = useState(false);

  let selectedCountry = useStore((state) => state.selectedCountry);
  let preRoiSelectedCustomers = useStore(
    (state) => state.preRoiSelectedCustomers
  );
  const promoSimulatorTabs = usePromoSimulatorStore((state) => state.promoSimulatorTabs);
  const { isLoading: isCustomersData, data: customersData } =
    usePreRoiGetCustomersSelectors(`${selectedCountry}`, "Promotion");

  const setPreRoiSelectedCustomers = useStore((state) => state.setPreRoiSelectedCustomers);
  const setPreRoiCustomersStateData = useStore((state) => state.setCustomersStateData);
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);

  const handleClick = (event) => {
    if (selectedCountry) {
      setOpenSelector(!openSelector);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };
  const handleEventClick = () => {
    setOpenEventSelector(!openEventSelector);
  }

  useEffect(() => {
    setPreRoiSelectedCustomers("");
  }, [])

  useEffect(() => {
    if (promoSimulatorTabs[0].reset) {
      setPreRoiSelectedCustomers("");
      setPreRoiCustomersStateData(props.data);
      setClear(true);
    }
  }, [promoSimulatorTabs])

  useEffect(() => {
    if (props.clear) {
      setPreRoiSelectedCustomers("");
      setPreRoiCustomersStateData(props.data);
      setClear(true);
    }
  }, [props])

  useEffect(() => {
    if (props.promoData && selectedPromoDetails.fromView) {
      const { customer } = props.promoData;
      setPreRoiSelectedCustomers(customer);
    }
  }, [selectedPromoDetails, props.promoData])

  let domNode = useClickOutside(() => {
    setOpenSelector(false);
    setOpenEventSelector(false);
  })

  const resetClear = () => {
    setClear(false);
    props.reset();
  }
  return (
    <div
      className={promoSimulatorTabs[0].calculateButtonClicked ? classes.containerDisabled : classes.container}
      ref={domNode}
    >
      <Paper component="form" className={promoSimulatorTabs[0].calculateButtonClicked ? classes.disabledRoot : classes.root} onClick={handleClick}>
        <>
          <div onClick={handleClick}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton className={classes.iconButton} aria-label="search" onClick={handleClick}>
              <FilterAltIcon
                className={`icon_${props.dataFor.toLowerCase()}`}
                alt="filterIcon"
                sx={{ color: '#000' }}
              />
            </IconButton>
          </div>
          <div style={{ display: "flex" }} onClick={handleClick}>
            <li
              className={`selected_${props.dataFor.toLowerCase()}`}
              style={{
                marginLeft: "10px",
                listStyle: "none",
                borderRadius: "5px",
              }}
            >
              {preRoiSelectedCustomers}
            </li>
          </div>
        </>
      </Paper>

      <div style={{ zIndex: 8525 }} className="absolute mt-11">
        {openSelector && (
          <NestedCustomerSearch
            key={'CustomerSearch'}
            data={customersData}
            sellInData={props.sellInData}
            previouslySelected={preRoiSelectedCustomers}
            clear={props.clear || clear}
            resetClear={resetClear}
            indexValue={props.indexValue}
            tabName={props?.tabName}
            hasScopeData={props?.hasScopeData}
            promoData={props.promoData}
          />
        )}
      </div>

    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex", component: "form", flexDirection: "column"
  },
  containerDisabled: {
    display: "flex", component: "form", flexDirection: "column", pointerEvents: 'none'
  },
  root: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: 'transparent'
  },
  disabledRoot: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: '#f5f5f5'
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    width: theme.spacing(143),
    backgroundColor: theme.palette.background.paper,
  },
  listbox: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  input: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    float: "right",
    padding: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    float: "right",
    height: 28,
    margin: 4,
  },
  checkBox: {
    color: theme.palette.primary.main,
    fontFamily: "sans-serif",
  },
  listItem: {
    marginLeft: "10px",
    listStyle: "none",
    backgroundColor: "#f4f4f4",
    borderRadius: "5px",
  }
}));
export default CustomerSearch;

