
type SimulatedResultProps = {

    simulatedGridData: [{ [key: string]: string }],

}



export const NetPromotedSales = ({ simulatedGridData }: SimulatedResultProps) => {

    /* List of Simulated P&L Item values to be shown with decimals */
    const decimalFormattedList: string[] = ["Non-Promoted Price"];

    /* Format Simulated P&L values to either whole number or decimal */
    const formatValue = (itemName: string, itemValue: any) => {
        const isDecimalField = decimalFormattedList.some((item: string) => item === itemName)
        const value = isDecimalField ? itemValue.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) : Math.round(itemValue).toLocaleString('en-US')
        return value;
    }

    return <div className="flex flex-col">
        {
            simulatedGridData.map((item: any, index: number) =>
            (
                <div key={index} className="flex justify-between py-4 items-center bg-gray-100-test text-sm  px-2">
                    <div data-testid="scenario-name" className="min-w-50 w-[260px] font-bold text-left">{item.name}</div>
                    <div data-testid="scenario-actual" className="min-w-40 text-left">{formatValue(item.name, item.actuals)}</div>
                    <div data-testid="scenario-baseline" className="min-w-40 text-left">{formatValue(item.name, item.baselineScenario)}</div>
                    <div data-testid="scenario-promo" className="min-w-40 text-left">{formatValue(item.name, item.promoScenario)}</div>
                    <div data-testid="scenario-lift" className="min-w-40 text-left">{formatValue(item.name, item.liftScenario)}</div>
                </div>
            )
            )
        }

    </div>
}