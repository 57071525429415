import React, { useEffect, useState } from "react";
import { Button, Box, makeStyles } from '@material-ui/core';
import usePromoSimulatorStore from "../../../store/promoSimulatorStore";
import { SaveButton } from "../CreateSimulations/InputSection/SaveButton";
import { AlertDialog } from "../CreateSimulations/InputSection/AlertDialog";

import useStore from "../../../store";
interface prompCalculationType {
  key: string, value: { [key: string]: string }[]
}

export const ActionBar = (props: any) => {
  const classes = useStyles();
  const [hasMandatoryInputs, setHasMandatoryInputs] = useState(false);
  const [open, setOpen] = React.useState(false);

  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const setSelectedPromoDetails = useStore((state) => state.setSelectedPromoDetails);

  useEffect(() => {
    //( promoSimulatorTabs[props.id].selectedCustomer.length > 0) &&
    //promoSimulatorTabs[props.id].selectedProduct.length > 0 &&
    let hasAllRequiredInputs = (
      promoSimulatorTabs[props.id].duration ||
      promoSimulatorTabs[props.id].promoName || promoSimulatorTabs[props.id].sellInPriceType ||
      promoSimulatorTabs[props.id].sellInPriceTypevalue ||
      promoSimulatorTabs[props.id].sellOutPriceType ||
      promoSimulatorTabs[props.id].sellOutPriceTypeValue ||
      promoSimulatorTabs[props.id].volumeType ||
      promoSimulatorTabs[props.id].additionalFunding ||
      promoSimulatorTabs[props.id].volumeTypeValue);
    hasAllRequiredInputs ? setHasMandatoryInputs(true) : setHasMandatoryInputs(false)
    //clean up 
    return (() => {
      setHasMandatoryInputs(false);
    });
  }, [promoSimulatorTabs])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setSelectedPromoDetails({
      promoName : '',
      type: '',
      fromView: false,
    })
    
    const tabValues = {
      promoName: "",
      fromDate: "",
      toDate: "",
      duration: 0,
      sellInPriceType: "",
      sellInPriceTypevalue: "",
      selectedCustomer: "",
      selectedProduct: [],
      unitOfMeasure: "Packs",
      sellOutPriceType: "",
      sellOutPriceTypeValue: "",
      additionalFunding: "",
      redemptionRate: "",
      volumeType: "",
      volumeTypeValue: "",
      fixedCost: 'N',
      reset: true,
      PnLResposne: [],
      displayPromo: []
    };
    const initialTab = [{
      tabIndex: 0,
      tabLabel: '',
      ...tabValues
    }];
    setPromoSimulatorTabs(initialTab);
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <Button className={hasMandatoryInputs ? classes.createNewBtn : classes.disabledBtn} onClick={handleClickOpen} disabled={!hasMandatoryInputs}>
        Create New
      </Button>
      <SaveButton />
      <AlertDialog openDialog={open} handleYes={handleYes} handleNo={handleClose} message="Data already exists on screen. Are you sure to create new simulation?" />

    </div>
  )
};

const useStyles = makeStyles(() => ({
  actionsIconTextColor: {
    color: '#0000b3',
    textTransform: 'none'
  },
  disabledActionsIconTextColor: {
    color: 'gray',
    textTransform: 'none'
  },
  container: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  createNewBtn: {
    float: 'left',
    color: '#fff',
    background: '#1976d2',
    margin: '10px',
    '&:hover': {
      backgroundColor: "#1976d2 !important",
    },
  },
  calculateBtn: {
    float: 'right',
    color: '#fff',
    background: '#1976d2',
    margin: '10px',
    '&:hover': {
      backgroundColor: "#1976d2 !important",
    },
  },
  disabledBtn: {
    color: 'rgb(217, 217, 217)',
    background: 'rgb(240, 240, 240)',
    margin: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: "rgb(240, 240, 240) !important",
    },
  }
}))

