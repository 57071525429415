import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ItemSearch from './ItemSearch';
import useStore from '../../store';


const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '600px',
    width: '450px',
    zIndex: 8525,
    backgroundColor: 'white',
    overflowY: 'auto',
    direction: 'rtl',
    padding: '1rem',
    boxShadow: '5px 5px 8px #d6d9dc',
    marginTop: '5px',
    borderRadius: '5px',
  },
  paperDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  container: {
    backgroundColor: '#F6F8FC',
    height: '100vh',
  },
  searchBar: {
    display: 'inline-block',
    paddingRight: '2.5rem',
  },
  searchContainer: {
    background: '#F6F8FC',
    width: '100%',
    boxShadow: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    color: 'black',
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  autoCompleteSearch: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    background: '#FFFFFF',
  },
  gridContainer: {
    background: '#F6F8FC',
  },
  showCheckBox: {},
  appTab: {
    fontSize: '20px',
  },
  hide: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '650',
    opacity: '0.70',
    marginBottom: '1px',
  },
  open: {
    fontSize: '14px',
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '1px',
    marginLeft: '5px',
  },
  filterItem: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: theme.palette.primary.main,
    opacity: '0.75',
    fontWeight: '480',
    padding: '3px',
  }
}));

let display = {
  sector: { name: 'Sector', isOpen: false },
  category: { name: 'Category', isOpen: false },
  subCategory: { name: 'Sub Category', isOpen: false },
  brand: { name: 'Brand', isOpen: false },
  subBrand: { name: 'Sub Brand', isOpen: false },
  eans: { name: 'Product', isOpen: false }
};
const arr = [
  "sector",
  "category",
  "subCategory",
  "brand",
  "subBrand",
  "eans"
];

const NestedProductSearch = (props) => {
  const classes = useStyles();
  //It controls expand of level
  const initial = {
    sector: false,
    category: false,
    subCategory: false,
    brand: false,
    subBrand: false,
    eans: false,
  };

  const [iconState, setIconState] = useState(initial);
  let baseLineProductCheckBox = useStore(
    (state) => state.baseLineProductCheckBox
  );
  const setBaseLineProductCheckBox = useStore(
    (state) => state.setBaseLineProductCheckBox
  );
  const setSelectedProducts = useStore(
    (state) => state.setSelectedProducts
  );

  let selectedProducts = useStore(
    (state) => state.selectedProducts
  );
  let productsStateData = useStore(
    (state) => state.productsStateData
  );

  const setProductsStateData = useStore(
    (state) => state.setProductsStateData
  );

  const setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );
  function handleOpen(x) {
    setIconState({
      ...iconState,
      [x]: !iconState[x],
    });
  }

  function transformDataUnique(values) {
   if (values && values.data) {
      values = values.data;

     const objValues = {
        "sector": [],
        "category": [],
        "subCategory": [],
        "brand": [],
        "subBrand": [],
        "eans": []
      }
      arr.map((val) => {
        let formatedLevel = getFormatedLevel(values,val);
        objValues[val] =  formatedLevel;
        values[val] = formatedLevel;
      });
      

      arr.map((val) => {
        objValues[val].sort(function (a, b) {
          if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          else if (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else return 0;
        });
      });
       return objValues;
    } else {
      return values;
    }
  }
  

  function isSelected(x) {
    return x.isChecked;
  }

  function putAll(x) {
    return x.name;
  }

  //during initial render displays all values for diff levels 
  function calculateInitial(data) {
    //const processed = processProductHierarchy(data, null)
    const transformed = transformDataUnique(data);   
    return transformed;
  }

  useEffect(() => {
    if (props.previouslySelected !== undefined  && props.previouslySelected.length === 0) {
      let Values = calculateInitial(props.data);
      setProductsStateData({
        ...Values,
      });
      setBaseLineProductCheckBox({ ...initial });
      setSelectedProducts("");
    } else if (props.previouslySelected !== undefined && props.previouslySelected.length > 0) {
     // updateSelectedLevel()
     // getFormatedData(props.data)
    }
  }, [props]);  

  useEffect(() => {
    if (props.clear ) {
      //if (props.clear) {
      props.resetClear();
    }
  }, [props]);

  const updateSelectedLevel = (level) => {
    const transformed = transformDataUnique(props.data);    
    setProductsStateData({
      ...transformed,
    });
  };

  function generateUrl() {
    let selectedArr = [];
    let reformatKcStr = '';
    let values = { ...productsStateData };
    //iterating backword
    for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      let selectedNodes = values[level].filter(isSelected).map(putAll);
      if (selectedNodes.length !== 0) {
        selectedNodes.map((val, i) => {
          reformatKcStr += `&${level}=${val}`;
          selectedArr.push(val);
        });
      }
    }
    return reformatKcStr === '' ? { url: '', selectedArr: [] } : { url: reformatKcStr, selectedArr: selectedArr };
  }

  function unCheckBelowParentLevels(x) {
    let toggleParentCheckBox = { ...baseLineProductCheckBox };
    let flagP = false;
    for (let [key] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }
    setBaseLineProductCheckBox({ ...toggleParentCheckBox });
  };

  const onSelection = (data) => {
    let values = { ...productsStateData };
    let x = data[0]; //parent level name:subcat
    let y = data[1]; //value in subcat ex-subcat1
    let selectedArr = [];
    values[x].map(function (item) {
      if (item.name === y) item.isChecked = !item.isChecked;
      if (item.isChecked) {
        selectedArr.push(item.name);
      }
    });
    let toggleParentCheckBox = { ...baseLineProductCheckBox };
    let flagP = false;
    for (let [key, value] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }

    if (selectedArr.length === productsStateData[x].length) {
      toggleParentCheckBox[x] = true;
    }
    setBaseLineProductCheckBox({ ...toggleParentCheckBox });

    //updateSelectedLevel(x);
    values = loadChildOfSelectedParent(x, y, values);
    setProductsStateData({
      ...values,
    });
    let obj = generateUrl();
    setSelectedProducts(obj['selectedArr']);
  };
  function getSelectedAllValue(values,lavel){
    let selectedSEC = values[lavel].filter((item) => item.isChecked === true);       
    let sCategory = props.allData.data.filter((item)=>{
    return selectedSEC.find(element => {
      return element.name === item[lavel];
    });
    });
    return sCategory;
  }
  function loadChildOfSelectedParent(x, y, values) {   
    if (values.data) {
      values = values.data;
    }
    
    if (x !== "eans") {
      if (x === "sector") {
        let selectedSEC = values['sector'].filter((item) => item.isChecked === true);       
        let sCategory = props.allData.data.filter((item)=>{
          return selectedSEC.find(element => {
            return element.name === item["sector"];
          });
        })
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"category");         
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"brand");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"subCategory");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"subBrand");
        }
        if (sCategory.length === 0) {
          selectedSEC = values['eans'].filter((item) => item.isChecked === true);
          sCategory = props.allData.data.filter((item) => {
            return selectedSEC.find(element => {
              return item["eans"].includes(element.name);
            });
          })
        }
        values['category'] = getFormatedLevel(sCategory,'category');
        values['subCategory'] = getFormatedLevel(sCategory,'subCategory')
        values['brand'] = getFormatedLevel(sCategory,'brand');
        values['subBrand'] = getFormatedLevel(sCategory,'subBrand');
        values['eans'] = getFormatedLevel(sCategory,'eans');
      } 
      else if(x=== 'category'){        
        let selectedSEC = values[x].filter((item) => item.isChecked === true);       
        let sCategory = props.allData.data.filter((item)=>{
          return selectedSEC.find(element => {
            return element.name === item[x];
          });
        })
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"sector");         
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"brand");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"subCategory");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"subBrand");
        }
        
        if (sCategory.length === 0) {
          selectedSEC = values['eans'].filter((item) => item.isChecked === true);
          sCategory = props.allData.data.filter((item) => {
            return selectedSEC.find(element => {
              return item["eans"].includes(element.name);
            });
          })
        }
        values['sector'] = getFormatedLevel(sCategory,'sector');
        values['subCategory'] = getFormatedLevel(sCategory,'subCategory')
        values['brand'] = getFormatedLevel(sCategory,'brand');
        values['subBrand'] = getFormatedLevel(sCategory,'subBrand');
        values['eans'] = getFormatedLevel(sCategory,'eans');
      }
      else if(x=== 'subCategory'){
        let selectedSEC = values[x].filter((item) => item.isChecked === true);       
        let sCategory = props.allData.data.filter((item)=>{
          return selectedSEC.find(element => {
            return element.name === item[x];
          });
        })
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"sector");         
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"category");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"brand");
        }
        
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"subBrand");
        }
        if (sCategory.length === 0) {
          selectedSEC = values['eans'].filter((item) => item.isChecked === true);
          sCategory = props.allData.data.filter((item) => {
            return selectedSEC.find(element => {
              return item["eans"].includes(element.name);
            });
          })
        }

        values['sector'] = getFormatedLevel(sCategory,'sector');
        values['category'] = getFormatedLevel(sCategory,'category');
        values['brand'] = getFormatedLevel(sCategory,'brand');
        values['subBrand'] = getFormatedLevel(sCategory,'subBrand');
        values['eans'] = getFormatedLevel(sCategory,'eans');
      }
      else if(x=== 'brand'){
        let selectedSEC = values[x].filter((item) => item.isChecked === true);       
        let sCategory = props.allData.data.filter((item)=>{
          return selectedSEC.find(element => {
            return element.name === item[x];
          });
        })
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"sector");         
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"category");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"subCategory");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"subBrand");
        }
        if (sCategory.length === 0) {
          selectedSEC = values['eans'].filter((item) => item.isChecked === true);
          sCategory = props.allData.data.filter((item) => {
            return selectedSEC.find(element => {
              return item["eans"].includes(element.name);
            });
          })
        }
        values['sector'] = getFormatedLevel(sCategory,'sector');
        values['category'] = getFormatedLevel(sCategory,'category');
        values['subCategory'] = getFormatedLevel(sCategory,'subCategory')
        values['subBrand'] = getFormatedLevel(sCategory,'subBrand');
        values['eans'] = getFormatedLevel(sCategory,'eans');
      }
      else if(x=== 'subBrand'){       
        let selectedSEC = values[x].filter((item) => item.isChecked === true);       
        let sCategory = props.allData.data.filter((item)=>{
          return selectedSEC.find(element => {
            return element.name === item[x];
          });
        })
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"sector");        
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"category");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"subCategory");
        }
        if(sCategory.length ===0){
          sCategory =  getSelectedAllValue(values,"brand");
        }
        if(sCategory.length ===0){
          selectedSEC = values["sector"].filter((item) => item.isChecked === true);       
          sCategory = props.allData.data.filter((item)=>{
          return selectedSEC.find(element => {
            return element.name === item["sector"];
          });
        })
        }
        values['sector'] = getFormatedLevel(sCategory,'sector');
        values['category'] = getFormatedLevel(sCategory,'category');
        values['subCategory'] = getFormatedLevel(sCategory,'subCategory')
        values['brand'] = getFormatedLevel(sCategory,'brand');
        values['eans'] = getFormatedLevel(sCategory,'eans');
      }


      return values;
    }else {
      let selectedSEC = values['eans'].filter((item) => item.isChecked === true);       
      let sCategory = props.allData.data.filter((item)=>{
        return selectedSEC.find(element => {
          return item["eans"].includes(element.name);
        });
      })
      if(sCategory.length ===0){
        sCategory =  getSelectedAllValue(values,"sector");         
      }
      if(sCategory.length ===0){
        sCategory =  getSelectedAllValue(values,"category");
      }
      if(sCategory.length ===0){
        sCategory =  getSelectedAllValue(values,"subCategory");
      }
      if(sCategory.length ===0){
        sCategory =  getSelectedAllValue(values,"brand");
      }
      if(sCategory.length ===0){
        sCategory =  getSelectedAllValue(values,"subBrand");
      }
      values['sector'] = getFormatedLevel(sCategory,'sector');
      values['category'] = getFormatedLevel(sCategory,'category');
      values['subCategory'] = getFormatedLevel(sCategory,'subCategory');
      values['brand'] = getFormatedLevel(sCategory,'brand');
      values['subBrand'] = getFormatedLevel(sCategory,'subBrand');
      return values;
     
    } 
    return values;
  }
  function getFormatedLevel(data,level){
    let formatedLevel = [];
    data.forEach((listItem) => {
      if(listItem[level] !== null)
      if(level === "eans"){
        listItem.eans.forEach((listItem) => {
          formatedLevel.push({ 'name': listItem, 'isChecked': selectedProducts.includes(listItem) });
        })
      }else{
        formatedLevel.push({ 'name': listItem[level],'isChecked': selectedProducts.includes(listItem[level]) });
      }
    })
    const unique = [...new Map(formatedLevel.map(item =>
      [item['name'], item])).values()];
      unique.sort(function (a, b) {
        if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else if (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
      });
    return unique;
  }
  function SelectAllChildren(parentLevel) {
    let values = { ...productsStateData };
    let selectedArr = [];
    values[parentLevel].map(
      (x) => ((x.isChecked = true), selectedArr.push(x.name))
    );
    setProductsStateData(values);

    //updateSelectedLevel(parentLevel);

    let obj = generateUrl();
    let completeUrl = obj['url'];
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setSelectedProducts(obj['selectedArr']);
    };
  }

  function DeSelectAllChildren(parentLevel) {
    let values = { ...productsStateData };
    values[parentLevel].map((x) => (x.isChecked = false));
    setProductsStateData(values);

    updateSelectedLevel(parentLevel);

    let obj = generateUrl();
    let completeUrl = obj['url'];
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setSelectedProducts(obj['url']);
    }
    unCheckBelowParentLevels(parentLevel);
  }

  const handleParentClick = (e) => {
    let x = e.target.value;
    setBaseLineProductCheckBox({
      ...baseLineProductCheckBox,
      [x]: !baseLineProductCheckBox[x],
    });

    if (!baseLineProductCheckBox[x]) SelectAllChildren(x);
    else DeSelectAllChildren(x);
  };
  return (
    <div className={classes.root}>
      <div style={{ direction: 'ltr' }}>
        {Object.entries(display).map(([key, item]) => (
          <>
            <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                className="productExpander w-4"
                onClick={() => handleOpen(key)}
                style={{ cursor: 'pointer', justifyContent: 'center' }}>
                {iconState[key] ? '-' : '+'}
              </div>
              <input
                type='checkbox'
                value={key}
                checked={baseLineProductCheckBox[key]}
                onChange={handleParentClick} />
              <label className={`${iconState[key] ? classes.open : classes.hide} productLabel pl-1`}>
                {item.name}
              </label>
            </div>
            {
              <div style={{ marginLeft: '20px' }}>
                {iconState[key] && (
                  <ItemSearch
                    values={productsStateData[key]}
                    level={key}
                    onSelection={(e) => {
                      onSelection(e);
                    }}
                  />
                )}
              </div>
            }
          </>
        ))}

      </div>
    </div>
  );
};
export default NestedProductSearch;
