import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { makeStyles } from "@material-ui/core/styles";

import '../../styles.css'
import useStore from "../../store";
import { useTokenBI } from '../../services/queries';
import { PowerBITableMapWithProductlevel } from '../utils';
import { useFlagStore } from "../../store/flagStore"

const useStyles = makeStyles((theme) => ({
	spinner: {
		position: 'absolute',
		height: '50px',
		width: '50px',
		top: '50%',
		left:'50%'
	},
	btnContainer: {
		color: "rgb(0 0 179)",
		display: "flex",
    justifyContent:'flex-end',
		margin: "1em",
	},
}))

export const OwnReport = (props) => {  
	const classes = useStyles(); 

	let {slopeInt, slopeFrac, interceptInt, interceptFrac, rSquared, elasticityInt, elasticityFrac, combinedYearWeeks, customer, category, averagePrice} = {...props};

	const [ selecteddValue , setSelecteddValue] = useState([]);
	const [ bookmark , setBookmark] = useState({});
	const [ progress , setProgress] = useState(false);
	const [showAllReports, setShowAllReports] = useState(false);
	const [ selectedSubCategory , setSelectedSubCategory] = useState([]);
  const [ selectedManufacturer , setSelectedManufacturer] = useState([]);
  const [ selectedBrand , setSelectedBrand] = useState([]);
	const [biToken, setBiToken] = useState('');
	const [embedUrl, setEmbedUrl] = useState('');
	const [filtersValue, setFiltersValue] = useState([])
	
	let selectedCountry = useStore((state) => state.selectedCountry);
	const setOnViewMore = useFlagStore(store => store.setOnViewMore);

	const { isLoading: isTokenData, data: tokenData, isSuccess } = useTokenBI(process.env.REACT_APP_OWN_ID);

	useEffect(()=>{
		if (typeof props.initial === 'string' || props.initial instanceof String){
			let initial = props.initial.trim();
			if (props.productLevel === 'EAN'){
				initial = initial.split('-')[0].trim()
			}

			let newAry = [];
			newAry.push(initial)
			setSelecteddValue(newAry)
			setSelectedSubCategory(props.subCategoryInitial);
	
			if(props.manufacturerInitial!=='null') {
				setSelectedManufacturer(props.manufacturerInitial);
			}
	
			if(props.brandInitial!=='null'){
				setSelectedBrand(props.brandInitial);
			}
		}
	},[props])

	useEffect(() => {
		if (!isTokenData && tokenData) {
			setBiToken(tokenData?.accessToken);
			setEmbedUrl(tokenData?.url)
		}
		if(props.productLevel === 'EAN') {
			if(selectedCountry === 'KR') {
				setBookmark({name:process.env.REACT_APP_OWN_KOREA_BOOKMARK})
			} else {
				setBookmark({name:process.env.REACT_APP_OWN_OTHER_BOOKMARK})
			}
		} else if(props.productLevel === 'SUB_BRAND') {
			if(selectedCountry === 'KR') {
				setBookmark({name:process.env.REACT_APP_OWN_SUB_BRAND_KOREA_BOOKMARK})
			} else {
				setBookmark({name:process.env.REACT_APP_OWN_SUB_BRAND_BOOKMARK})
			}
		}
	}, [isTokenData, tokenData])

	const basicFilterCountry = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][0],
			column: "COUNTRY"
		},
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterCountry_1 = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][1],
			column: "COUNTRY"
		},
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterCustomer = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][0],
			column: "CUSTOMER"
		},
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterCustomer_1 = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][1],
			column: "CUSTOMER"
		},
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterKCEans = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][0],
			column:`${props.productLevel}_INITIAL`
		},
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterKCEans_1 = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][1],
			column:`${props.productLevel}_INITIAL`
		},
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterCategory = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][0],
			column:`CATEGORY`
		},
		operator: "In",
		values: [category],
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterCategory_1 = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][1],
			column:`CATEGORY`
		},
		operator: "In",
		values: [category],
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterSubCategory = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`SUB_CATEGORY_INITIAL`
    },
    operator: "In",
    values: selectedSubCategory,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterSubCategory_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`SUB_CATEGORY_INITIAL`
    },
    operator: "In",
    values: selectedSubCategory,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterManufacturer = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`MANUFACTURER_INITIAL`
    },
    operator: "In",
    values: selectedManufacturer,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterManufacturer_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`MANUFACTURER_INITIAL`
    },
    operator: "In",
    values: selectedManufacturer,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterBrand = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`BRAND_INITIAL`
    },
    operator: "In",
    values: selectedBrand,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterBrand_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`BRAND_INITIAL`
    },
    operator: "In",
    values: selectedBrand,
    filterType: models.FilterType.BasicFilter
  }

	const basicFilterSlopeInt = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Slope Integer",
			column: "Slope Integer"
		},
		values: [slopeInt],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}
	
	const basicFilterSlopeFrac = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Slope Fraction",
			column: "Slope Fraction"
		},
		values: [slopeFrac],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}
	const basicFilterInterceptInt = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Intercept Integer",
			column: "Intercept"
		},
		values: [interceptInt],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterInterceptFrac = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Intercept Fraction",
			column: "Intercept Fraction"
		},
		values: [interceptFrac],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}
	
	const basicFilterrSquared = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "R Square Table",
			column: "R Square"
		},
		values: [rSquared],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}
	
	const basicFilterElasticityInt = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Elasticity Integer",
			column: "Elasticity Integer"
		},
		values: [elasticityInt],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterElasticityFrac = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Elasticity Fraction",
			column: "Elasticity Fraction"
		},
		values: [elasticityFrac],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterYearWeek = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][0],
			column: "YEAR_WEEK"
		},
		values: combinedYearWeeks,
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	useEffect(() => {
		const filter = []

		if (props.country && selecteddValue && selecteddValue.length > 0){
			if (props.country?.length > 0) {
				basicFilterCountry.values =  [props.country]
				basicFilterCountry_1.values =  [props.country]
				filter.push(basicFilterCountry) 
				filter.push(basicFilterCountry_1)
			}

			if (customer) {
				basicFilterCustomer.values =  [customer]
				basicFilterCustomer_1.values =  [customer]
				filter.push(basicFilterCustomer) 
				filter.push(basicFilterCustomer_1)
			}
			basicFilterKCEans_1.values = selecteddValue
			basicFilterKCEans.values = selecteddValue

			if(selectedManufacturer.length > 0 && selectedManufacturer[0]!=='null' ) {
				filter.push(basicFilterManufacturer)
				filter.push(basicFilterManufacturer_1)
			}

			if(selectedBrand.length > 0 && selectedBrand[0]!=='null') {
				filter.push(basicFilterBrand)
				filter.push(basicFilterBrand_1)
			}

			if(selectedSubCategory.length > 0) {
				filter.push(basicFilterSubCategory)
				filter.push(basicFilterSubCategory_1)
			}

			filter.push(basicFilterCategory)
			filter.push(basicFilterCategory_1)
			filter.push(basicFilterKCEans) 
			filter.push(basicFilterKCEans_1)
			filter.push(basicFilterSlopeInt) 
			filter.push(basicFilterSlopeFrac)
			filter.push(basicFilterInterceptInt) 
			filter.push(basicFilterInterceptFrac) 
			filter.push(basicFilterrSquared)
			filter.push(basicFilterElasticityInt) 
			filter.push(basicFilterElasticityFrac)
			if(combinedYearWeeks.length > 0) {
				filter.push(basicFilterYearWeek)
			}
		}

		setFiltersValue(filter) 
		setProgress(true);
	}, [props, selecteddValue])

	useEffect(() => {
	  setOnViewMore(true)
	  return () => {
		setOnViewMore(false)
	  }
	}, [])

	const getPageName = () =>{
		if(selectedCountry === "KR") {
			if(props.productLevel === 'SUB_BRAND') {
				return process.env.REACT_APP_OWN_SUB_BRAND_KR1_PAGENAME;
			} else {
				return process.env.REACT_APP_OWN_KR1_PAGENAME;
			}
		} else {
			if(props.productLevel === 'SUB_BRAND') {
				return process.env.REACT_APP_OWN_SUB_BRAND_PAGENAME;
			} else {
				return process.env.REACT_APP_OWN_PAGENAME;
			}
		}
	}

	const getPageNameForKRMore = () => {
		if(props.productLevel === 'SUB_BRAND') {
			return process.env.REACT_APP_OWN_SUB_BRAND_KR2_PAGENAME;
		} else {
			return process.env.REACT_APP_OWN_KR2_PAGENAME;
		}
	}	

	console.log("getPageName", getPageName());
	console.log("getPageNameForKRMore", getPageNameForKRMore());
	console.log("bookmarks", bookmark);
	console.log("process.env", process.env);
	
	const generateReport = () =>{
		console.log("filtersValue", filtersValue);
		return (<header className="App-header">
			{/* {progress && <CircularProgress className={classes.spinner}/>} */}
				<PowerBIEmbed
					embedConfig={{
						pageName: getPageName(),
						type: 'report',   // Supported types: report, dashboard, tile, visual and qna
						id: process.env.REACT_APP_OWN_ID,
						embedUrl: embedUrl,
						accessToken: biToken, 
						tokenType: models.TokenType.Embed,
						filters: filtersValue,
						bookmark:  selectedCountry === "KR" ?  {name: ""} : bookmark,
						settings: {
							panes: {
								filters: {
									expanded: false,
									visible: false
								},
								pageNavigation: {
									visible: false
								},
							},
						}
					}}
					eventHandlers={
					new Map([
						['loaded', function() {console.log("loaded"); setProgress(false)}],
						['rendered', function () { 
							console.log('Report rendered');
							setProgress(false);
							setTimeout(() => {
								const parent = document.getElementsByClassName('Embed-container')
								if (parent && parent.length > 0){
									const iframe = document.getElementsByTagName('iframe',parent)
									if (iframe && iframe[0]){
										iframe[0].setAttribute('id','powerBIFrame')
									}
								}
								
							}, 2000)
						}],
						['error', function() {console.log("error"); setProgress(false)}]
					])
					}
					cssClassName={"Embed-container"}
					getEmbeddedComponent={(embeddedReport) => {
						window.report = embeddedReport;
					}}
			/>
			{selectedCountry === "KR" ? <div className={classes.btnContainer}>
        <button variant="contained" onClick={()=>setShowAllReports(!showAllReports)} className = "border-b-2 border-primary font-semibold text-lg">{showAllReports ? "Hide" : "More"}</button>
      </div> : null}
			{selectedCountry === "KR" && showAllReports ?			
				<PowerBIEmbed
					embedConfig={{
						pageName: getPageNameForKRMore(),
						type: 'report',   // Supported types: report, dashboard, tile, visual and qna
						id: process.env.REACT_APP_OWN_ID,
						embedUrl: embedUrl,
						accessToken: biToken, 
						tokenType: models.TokenType.Embed,
						filters: filtersValue,
						// bookmark: bookmark,
						settings: {
							panes: {
								filters: {
									expanded: false,
									visible: false
								},
								pageNavigation: {
									visible: false
								},
							},
						}
					}}
					eventHandlers={
					new Map([
						['loaded', function () {console.log("loaded"); setProgress(false)}],
						['rendered', function () {
							console.log("rendered")
							setProgress(false);
							setTimeout(() => {
								const parent = document.getElementsByClassName('Embed-container')
								if (parent && parent.length > 0){
									const iframe = document.getElementsByTagName('iframe',parent)
									if (iframe && iframe[0]){
										iframe[0].setAttribute('id','powerBIFrame')
									}
								}
								
							}, 2000)
						}],
						['error', function() {console.log("error"); setProgress(false)}]
					])
					}
					cssClassName={"Embed-container"}
					getEmbeddedComponent={(embeddedReport) => {
						window.report = embeddedReport;
					}}
				/> : null}
		</header>)
	}

	return (
		<div className="App" style={{height: '100%'}}>
			{
				!isTokenData && biToken && isSuccess && filtersValue && filtersValue.length > 0 && embedUrl
				? generateReport()
				: <CircularProgress className={classes.spinner}/>
			}

		</div>
	);
}

export const MemoizedOwnReport = React.memo(OwnReport);