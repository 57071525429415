import { useEffect, useState, useMemo, Fragment } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconButton from '@mui/material/IconButton';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from "@material-ui/core/styles";

import useStore from '../store';
import Toaster from '../commonMuiComponents/Toaster';
import { usePostROIData } from "../services/queries";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
    fontSize: "0.75rem",
  },
  tableCell: {
    padding: "0px 0px 0px 12px",
    width: 40,
    minWidth: 40,
    position: "sticky",
    left: 0,
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
	    width: 6,
      height: 6,
    },
    "&::-webkit-scrollbar-track": {
	    boxShadow: "inset 0 0 5px #e0dfde",
      borderRadius: 4
    },
    "&::-webkit-scrollbar-thumb": {
	    backgroundColor: "#D3D3D3",
      borderRadius: 4
    }
  }
}));

export default function PostRoi(props) {
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('groupBy');
  const [expandArray, setExpandArray] = useState([]);

  const [postRoiDataPayload, setPostRoiDataPayload] = useState(null);
  const selectedCustomersHierarchy = useStore((state) => state.selectedCustomersHierarchy);
  const selectedProductsHierarchy = useStore((state) => state.selectedProductsHierarchy);
  const selectedEventIdValues = useStore((state) => state.selectedEventIdValues);
  const selectedCountry = useStore((state) => state.selectedCountry);
  const selectedBaseLineCustomerNew = useStore((state) => state.selectedBaseLineCustomerNew);
  const selectedBaseLineProductNew = useStore((state) => state.selectedBaseLineProductNew);
  const selectedSource = useStore((state) => state.selectedSource);
  const startMonth = useStore((state) => state.startMonth);
  const endMonth = useStore((state) => state.endMonth);
  const groupBy = useStore((state) => state.groupBy);
  const selectedQuadrant = useStore((state) => state.selectedQuadrant);

  //calling api
  const { isLoading: isRoiData, data: RoiData, isError: roiDataError, } = usePostROIData(postRoiDataPayload);

  const fieldArray = [
    {
      name: groupBy.match(/[A-Z][a-z]+/g).join(" "),
      field: "groupBy",
      sticky: true,
      width: 200,
      left: 0,
      isSortable: false,
      alignLeft: true,
      title: 'GroupBy'
    },
    {
      name: "Promo Profit",
      field: "promoProfit",
      isSortable: true,
      fractionDigit: 2,
      alignLeft: false,
      title: 'Actual sold cases * (unit non promoted price - unit COGs) - Promo Investment'
    },

    {
      name: "Baseline Profit",
      field: "baseLineProfit",
      isSortable: true,
      fractionDigit: 2,
      title: 'Baseline cases * (unit non promoted price - unit COGs)'
    },
    {
      name: "Incremental Profit/Loss",
      field: "netProfit",
      isSortable: true,
      fractionDigit: 2,
      title: 'Promo Profit - Baseline Profit'
    },

    {
      name: "Total Volume",
      field: "totalVolume",
      isSortable: false,
      fractionDigit: 0,
      title: 'Actual sold cases'
    },

    {
      name: "Baseline  Volume",
      field: "baseLineVolume",
      isSortable: false,
      fractionDigit: 0,
      title: 'Estimated sold cases when no promotion exists'
    },

    {
      name: "Lift",
      field: "lift",
      isSortable: false,
      fractionDigit: 2,
      title: 'Total Volume - Baseline Volume'
    },

    {
      name: "Non Promoted Price",
      field: "nppPerCase",
      isSortable: false,
      fractionDigit: 2,
      title: 'SUM(QUANTITY_CS * COGS_PER_CASE)/SUM(QUANTITY_CS)'
    },

    {
      name: "COGS",
      field: "cogsPerCase",
      isSortable: false,
      fractionDigit: 2,
      title: 'SUM(QUANTITY_CS * COGS_PER_CASE)/SUM(QUANTITY_CS)'
    },
    {
      name: "Retailer Margin",
      field: "retailerMargin",
      isSortable: false,
      fractionDigit: 2,
      title: 'Sell out volume * (Sell out price * (1-VAT) - Sell in price * (1-VAT))'
    },
    {
      name: "Retailer Margin %",
      field: "retailerMarginPercentage",
      isSortable: false,
      title: 'Sell in price * (1-VAT) / Sell out price * (1-VAT) * 100'
    },
    {
      name: "Promo Investment",
      field: "promoInvestment",
      isSortable: true,
      fractionDigit: 2,
      title: 'Money spent in promotions'
    },
    {
      name: "ROI %",
      field: "roi",
      isSortable: true,
      title: 'Incremental Profit | Loss/Promo Investement'
    },
  ];

  const fieldArrayUK = [
    {
      name: "Price Point",
      field: "pricePoint",
      isSortable: false,
      fractionDigit: 2,
      title: 'Price point'
    },

    {
      name: "Duration",
      field: "duration",
      isSortable: false,
      fractionDigit: 2,
      title: 'Duration'
    },

    {
      name: "Mechanic",
      field: "mechanic",
      isSortable: false,
      isStringConversion: true,
      title:'Mechanic'
    },

    {
      name: "Feature",
      field: "feature",
      isSortable: false,
      isStringConversion: true,
      title:'Feature'
    },
  ]

  let gridDataFieldsArray = []

  if(selectedCountry === 'UK') {
    gridDataFieldsArray = [...fieldArray, ...fieldArrayUK]
  } else {
    gridDataFieldsArray = [...fieldArray]
  }

  useEffect(() => {
    let payload = {
      country: selectedCountry,
      customers: selectedCustomersHierarchy?.soldTo,
      planLevels: selectedCustomersHierarchy?.planLevel,
      salesExecutive: selectedCustomersHierarchy?.salesExecutive,
      source: selectedSource,
      fromDate: startMonth,
      toDate: endMonth,
      eans: selectedProductsHierarchy?.products,
      categories: selectedProductsHierarchy?.categories,
      subCategories: selectedProductsHierarchy?.subCategories,
      brands: selectedProductsHierarchy?.brands,
      subBrands: selectedProductsHierarchy?.subBrands,
      groupBy,
      eventIds: selectedEventIdValues,
      kpiType: props.kpiType,
      quadrants: selectedQuadrant,
    };

    setPostRoiDataPayload(payload);
  }, [selectedCountry, startMonth,
     endMonth, groupBy, selectedSource,
    selectedQuadrant, selectedCustomersHierarchy?.soldTo,
    selectedCustomersHierarchy?.planLevel, selectedCustomersHierarchy?.salesExecutive,
    selectedProductsHierarchy?.products, selectedProductsHierarchy?.categories, 
    selectedProductsHierarchy?.subCategories, selectedProductsHierarchy?.brands, 
    selectedProductsHierarchy?.subBrands, selectedBaseLineCustomerNew, 
    selectedBaseLineProductNew, selectedEventIdValues, props.kpiType]);

  useEffect(() => {
    if (!isRoiData && RoiData) {
      setTableData(RoiData);
    }
  }, [isRoiData, groupBy, RoiData, selectedQuadrant]);

  const sortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const convertStringData = (value) => {
    return value && value.length > 0 ? value.slice(0, value.length > 2 ? 2 : value.length).join(', ').concat(value.length > 2 ? (' ', '...') : '') : null;
  }

  const gridData = useMemo(() =>
      stableSort(tableData && tableData.length > 0 ? tableData : [], getComparator(order, orderBy)),
    [order, orderBy, tableData],
  );

  const expandRowHandler = (item) => {
    if(expandArray.includes(item.groupBy)) {
      setExpandArray(expandArray.filter((arr => arr !== item.groupBy)))
    } else {
      setExpandArray([...expandArray, item.groupBy]);
    }
  }

  const ExpandedComponent = ({ data }) => {
    return <div className="w-1/2 p-2">
      <div className="flex gap-2">
        <b>Mechanics</b>
        <span>{data.mechanic.join(', ')}</span>
      </div>
      <div className="flex gap-2">
        <b>Features</b>
        <span>{data.feature.join(', ')}</span>
      </div>
    </div>
  };

  return (
    <Fragment>
      <div className="inline left pt-3 w-full">
        {roiDataError &&  <Toaster showAlert={{
          type: 'error',
          message: "Some thing went wrong, please reload page"
        }} />}   
        {!isRoiData && selectedCountry && tableData.length > 0 ? 
          (
            <div>
              <TableContainer className={classes.scrollBar} style={{ maxHeight: "70vh" }}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="simple table"
                  data-testid="PostROIDataGrid"
                  sx={{
                    tableLayout: "auto",
                    width: "max-content",
                    height: "max-content"
                  }}
                >
                  <TableHead>
                    <TableRow key="Head-row">
                    <>
                      {selectedCountry === 'UK' ? <TableCell 
                        style={{
                          zIndex: 1200,
                          background: "#FDEEEF",
                        }}
                        className={classes.tableCell}
                      /> : null}
                      {gridDataFieldsArray.map((headCell) => (
                        <TableCell
                          key={headCell.field}
                          sortDirection={
                            orderBy === headCell.field ? order : false
                          }
                          style={{
                            width: headCell.sticky ? headCell.width:'auto',
                            minWidth: headCell.sticky ? headCell.width:'auto',
                            position: headCell.sticky ? "sticky" : "",
                            left: selectedCountry === 'UK' ? 40 : 0,
                            zIndex: headCell.sticky ? 1200 : 200,
                            background: "#FDEEEF",
                            top: 0,
                            borderRight: "1px solid #ccc",
                            textAlign: "center",
                            fontSize: "13px",
                            padding:"3px"
                          }}
                          title={headCell.title}
                        >
                          {headCell.isSortable ? 
                            <TableSortLabel
                              active={orderBy === headCell.field}
                              direction={
                                orderBy === headCell.field ? order : "asc"
                              }
                              onClick={() => sortHandler(headCell.field)}
                            > 
                              {headCell.name} 
                            </TableSortLabel>
                            : <span>{headCell.name}</span>}
                        </TableCell>
                      ))}
                      </>
                    </TableRow>
                  </TableHead>
                  {gridData && (
                    <TableBody>
                      {gridData.map((promoItem, i) => (
                        <>
                          <TableRow key={`data-row-${i}`}
                            style={{
                              borderBottom: expandArray && expandArray.includes(promoItem.groupBy) ? 0 : "1px solid #ccc"
                            }}>
                            {selectedCountry === 'UK' ? <TableCell 
                              style={{
                                  zIndex: 99,
                                  background: "#F6F8FC",
                                }}
                              onClick={() => expandRowHandler(promoItem)}
                              className={classes.tableCell}
                            >
                              <IconButton
                                aria-label="expand row"
                                size="small"
                              >
                                {expandArray.includes(promoItem.groupBy) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                              </IconButton>
                            </TableCell> : null}
                            {gridDataFieldsArray.map((item) => (
                              <TableCell
                                key={`data-cell-${item.field}`}
                                style={{
                                  width: item.sticky ? item.width:'auto',
                                  minWidth: item.sticky ? item.width:'auto',
                                  position: item.sticky ? "sticky" : "",
                                  left: selectedCountry === 'UK' ? 40 : 0,
                                  zIndex: item.sticky ? 99 : 0,
                                  background: item.sticky ? "#F6F8FC" : "",
                                  fontSize: "12px",
                                  borderRight: "1px solid #ccc",
                                  textAlign: item.alignLeft ? "left" : "center",
                                  padding:"8px"
                                }}
                              >
                                {item.isStringConversion && item.isStringConversion ? convertStringData(promoItem[item.field]) : 
                                  (item.fractionDigit ? promoItem[item.field].toLocaleString('en-US', { maximumFractionDigits: item.fractionDigit, minimumFractionDigits: item.fractionDigit }) : promoItem[item.field])}
                              </TableCell>
                            ))}
                          </TableRow>
                          {selectedCountry === 'UK' && expandArray && expandArray.length > 0 && expandArray.includes(promoItem.groupBy ? promoItem.groupBy : '') 
                          ? <TableRow key={`data-row-${promoItem.groupBy}`}>
                            <TableCell style={{ 
                                paddingBottom: 0, 
                                paddingTop: 0,
                                borderRight: "1px solid #ccc",
                              }} 
                              colSpan={18}>
                              <ExpandedComponent data={promoItem}/>
                            </TableCell>
                          </TableRow> : null}
                        </>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
        ) : (
          <div className="p-6 max-w-sm mx-auto flex items-center space-x-4">
            <div className="text-base">There is no Data with selected filters</div>
          </div>
        )}
        <Dialog
          open={isRoiData}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="pt-0">
            {"Your request is being processed"}
          </DialogTitle>
        </Dialog>
      </div>
    </Fragment>
  );
}
