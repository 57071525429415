

import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EventIdSelector from "./EventIdSelector";
import useStore from "./store";
import NestedBaseLineCustomerSearch from "./NestedBaseLineCustomerSearch";
import NestedBaseLineProductSearch from "./NestedBaselineProductSearch";
import useClickOutside from "./ClickOutside";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%", 
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    width: theme.spacing(143),
    backgroundColor: theme.palette.background.paper,
  },
  listbox: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  input: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    float: "right",
    padding: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    float: "right",
    height: 28,
    margin: 4,
  },
  checkBox: {
    color: theme.palette.primary.main,
    fontFamily: "sans-serif",
  },
  listItem: {
    marginLeft: "10px",
    listStyle: "none",
    backgroundColor: "#f4f4f4",
    borderRadius: "5px",
  }
}));

function CustomSearch(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [openEventSelector, setOpenEventSelector] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [clear, setClear] = useState(false);
  const [csValues, setCSValues] = useState({});      
  const [pValues, setPValues] = useState({});        

  let selectedCountry = useStore((state) => state.selectedCountry);
  let selectedEventIdValues = useStore((state) => state.selectedEventIdValues);
  let selectedCustomerValues = useStore(
    (state) => state.selectedCustomerValues
  );
    
  const setSelectedCustomerValues = useStore(
    (state) => state.setSelectedCustomerValues
  );

  let selectedProductValues = useStore(
    (state) => state.selectedProductValues
  );
  const setSelectedProductValues = useStore(
    (state) => state.setSelectedProductValues
  );
  const setSelectedSource = useStore(
    (state) => state.setSelectedSource
  );
  const setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );
  let promoStateData = useStore((state) => state.promoStateData);
	const setPromoStateData = useStore((state) => state.setPromoStateData);

  const handleClick = (event) => {
    if (selectedCountry) {
      setOpenSelector(!openSelector);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };
  const handleEventClick = () => {
    setOpenEventSelector(!openEventSelector);
  }

  const handleSelect = (selectedArr) => {
    if(props.tabName === "preROI_test"){
      if(props.dataFor === "customer"){    
        let obj = {...csValues};    
        obj[props.indexValue] = selectedArr;
        setCSValues(obj);
        setSelectedCustomerValues(selectedArr);
        const updatedPromostate = promoStateData[props.indexValue];
        if(updatedPromostate?.selectedCustomerValues){
          updatedPromostate.selectedCustomerValues = [...selectedArr];
        }
        setPromoStateData({...promoStateData, [props.indexValue] : {...updatedPromostate}});
      }
      else{
        let obj = {...pValues};
        obj[props.indexValue] = selectedArr;
        setPValues(obj);
        setSelectedProductValues(selectedArr)
      }
    }
    if(props.tabName !== 'preROI_test') {
      props.dataFor === "customer" 
        ? setSelectedCustomerValues(selectedArr)
        : setSelectedProductValues(selectedArr);
    }
    else {
      setSelectedSource([]);
      setSelectedBaseLineFilter("");
    }
  }

  useEffect(() => {
    if (props.clear) {
        setClear(true);
    }
  }, [props.clear])

 useEffect(() => {
    if(props.clear && props.dataFor=== 'customer' && props.tabName === "preROI_test"){
        let obj = {...csValues};
        obj[props.deleteId ? props.deleteId : props.indexValue] = [];
        setCSValues(obj);
        props.reset();
      }
      else if(props.clear && props.dataFor === 'Product' && props.tabName === "preROI_test"){
        let obj = {...pValues};
        obj[props.deleteId ? props.deleteId : props.indexValue] = [];
        setPValues(obj);
        props.reset();
      }
      else if(props.clear && props.dataFor === 'EventId') {
        props.reset();
      }
  }, [props])
   

  let domNode = useClickOutside(()=>{
    setOpenSelector(false);
    setOpenEventSelector(false);
  })
  let selectedSource = useStore(
    (state) => state.selectedSource
  );

  const resetClear = () =>{
    setClear(false);
    props.reset();
  }

  useEffect(() => {
    if(props.duplicateFlag && props.tabName === 'preROI_test'){
      if(props.dataFor === "customer"){
        let obj = {...csValues};
        obj[Object.keys(csValues).length + 1] = [...obj[props.indexValue]];
        setCSValues(obj);
      }
      else{
        let obj = {...pValues};
        obj[Object.keys(pValues).length + 1] = [...obj[props.indexValue]];
        setPValues(obj);
      }
      props.endDuplication();
    }
  }, [props]);

  useEffect(()=> {
    if(!csValues[props.indexValue] && props.dataFor === 'customer' && props.tabName === "preROI_test"){
      let obj = {...csValues};
      obj[props.indexValue] = [];
      setCSValues(obj);
    }
    else if(!pValues[props.indexValue] && props.dataFor === 'Product' && props.tabName === "preROI_test"){
      let obj = {...pValues};
      obj[props.indexValue] = [];
      setPValues(obj);
    }
  }, [props])
  const getValueToPrint=()=>{
   if(props.dataFor === "customer"){
             return  props.tabName === 'preROI_test' && csValues[props.indexValue] ? csValues[props.indexValue].concat(selectedCustomerValues) : selectedCustomerValues 
    } else{
            
      return props.tabName === 'preROI_test' && pValues[props.indexValue] ? pValues[props.indexValue].concat(selectedProductValues) : selectedProductValues
              }
  }

  return (
    <div
      style={{ display: "flex", component: "form", flexDirection: "column" }}
      ref={domNode}
    >
      <Paper component="form" className={classes.root}>
        {props.dataFor === "EventId" ? (
          <>
            <div>
              <IconButton className={classes.iconButton} aria-label="search" onClick={handleEventClick}>
                <FilterAltIcon
                  className={`icon_${props.dataFor.toLowerCase()}`}
                  alt="filterIcon"
                  sx={{color: '#000'}}
                />
              </IconButton>
            </div>
            <div style={{ display: "flex" }}>
            {selectedEventIdValues.map((eventVal) => (
              <li className={classes.listItem} key={eventVal}>
                {eventVal}
              </li>
            ))}
          </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton className={classes.iconButton} aria-label="search" onClick={handleClick}>
                <FilterAltIcon
                  className={`icon_${props.dataFor.toLowerCase()}`}
                  alt="filterIcon"
                  sx={{color: '#000'}}
                />
              </IconButton>
            </div>
            <div style={{ display: "flex" }}>

            {
              getValueToPrint().length > 3?(<li
                className={`selected_${props.dataFor.toLowerCase()}`}
               
                style={{
                  marginLeft: "10px",
                  listStyle: "none",
                  backgroundColor: "#f4f4f4",
                  borderRadius: "5px",
                }}
              >
               Multiple Item Selected({getValueToPrint().length})
              </li>):(
            getValueToPrint().filter((value, index, self)=>self.indexOf(value) === index).map((item) => (
                <li
                  className={`selected_${props.dataFor.toLowerCase()}`}
                  key={item}
                  style={{
                    marginLeft: "10px",
                    listStyle: "none",
                    backgroundColor: "#f4f4f4",
                    borderRadius: "5px",
                  }}
                >
                  {item}
                </li>
              )))
              }
            </div>
          </>
        )}
      </Paper>
      
      <div style={{ zIndex: "999" }} className="absolute mt-11">
        {!props.data
          ? ""
          : props.dataFor !== "customer"
          ? openSelector && (
              <NestedBaseLineProductSearch
                key={'productSearch'}
                data={props.data}
                handleSelect={handleSelect}
                previouslySelected={props.tabName === 'preROI_test' && pValues[props.indexValue] ? pValues[props.indexValue].concat(selectedProductValues) : selectedProductValues }
                clear={props.clear}
                resetClear={resetClear}
                indexValue={props.indexValue}
                tabName={props?.tabName}
              />
            )
          : openSelector && (
              <NestedBaseLineCustomerSearch
                key={'customerSearch'}
                data={props.data}
                sellInData={props.sellInData}
                handleSelect={handleSelect}
                previouslySelected={props.tabName === 'preROItest' &&   [props.indexValue] ? pValues[props.indexValue].concat(selectedCustomerValues)  : selectedCustomerValues }
                clear={props.clear || clear}
                resetClear={resetClear}
                indexValue={props.indexValue}
                tabName={props?.tabName}
                hasScopeData={props?.hasScopeData}
              />
            )}
      </div>
      <div style={{ zIndex: 5 }}>{openEventSelector && <EventIdSelector />}</div>
    </div>
  );
}
export default CustomSearch;

