import React, { useEffect, useState, Fragment } from "react";

import {CrossDashboardAnalysis} from './components/CrossDashboardAnalysis';
import {CrossDashboardPerScope} from './components/CrossDashboardPerScope';

export const CrossDashboard=(props)=> {
  const [selectedCountry, setselectedCountry] = useState();

  useEffect(() => {
    const urlObj = new URL(window.location.href);
    setselectedCountry(urlObj.searchParams.get('country'));
  }, [])
  
  return (
    <Fragment>
      {selectedCountry === 'KR' ?
        <CrossDashboardPerScope /> : <CrossDashboardAnalysis />}
    </Fragment>
  )
}