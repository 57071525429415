import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { getAPITestResults } from "./services/queries";

type TestData =  {
    title: String,
    query: String,
    result: any[],
    error: String

}

export const TestDataAPI = () => {
    const [testResults, settestResults] = useState<TestData[]>([])

    useEffect(() => {
        const getTestReults = async () => {
          const res = await getAPITestResults(2)
          settestResults(res)

        }
        getTestReults();
      }, [])

      const getColumns = (result: any[]) => {
        const _columns: TableColumn<any>[]   = []
        if (!result || result.length === 0) return _columns;
        const keys = Object.keys(result[0])
        for (const key of keys) {
            const col : TableColumn<any> = {
                name:  <span > {key} </span >,
                cell: (row:any) => <span > {row[key]} </span >,
            }
            _columns.push(col)
        }
        return _columns
      }
    return <div className="container bg-white mx-auto">
        {
            testResults.length === 0
            ? <div className="text-2xl mx-auto pt-10">Loading test results, please wait...</div>
            : <div className="flex flex-col p-5">
                <div className="text-xl underline font-bold">Data Test results: </div>
                <div className="flex flex-col">
                {
                    testResults.map((test, idx) => {
                        return <div className=" p-5 flex flex-col border-b border-blue-900">
                            <div className="flex flex-row pb-3">
                                <span className="font-bold pr-3">{idx+1}. {test.title}</span>
                            </div>
                            <div className="flex flex-row pb-3">
                                <span className="font-bold pr-3">Query: </span>
                                <span className="italic">{test.query}</span>
                            </div>
                            {
                                !test.error 
                                ? <div className="flex flex-col pb-3 w-3/4">
                                        <span className="font-bold">Results: </span>
                                        {
                                            test.result
                                            ? <DataTable data={test.result} columns={getColumns(test.result)} dense />
                                            : null

                                        }

                                    </div>
                                : <div className="flex flex-row pb-3">
                                        <span className="font-bold pr-3">Error: </span>
                                        <span>{test.error}</span>
                                    </div>

                            }
                           
                            
                        </div>
                    })
                }
                </div>
               
                </div>
        }
    </div>
}