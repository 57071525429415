import {MemoizedPriceVolumeReport} from "../PriceVolumeTab/PriceVolumeChart"

function PriceVolumeTab(props) {
    return(
        <>
        
        <div>
        <MemoizedPriceVolumeReport/>
        </div>
        
        </>
    );
}

export default PriceVolumeTab;