import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useTokenBI } from '../../services/queries';
import CircularProgress from '@mui/material/CircularProgress';
import { models } from 'powerbi-client';
import '../../styles.css';
import useStore from "../../store";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	spinner: {
		position: 'absolute',
		height: '50px',
		width: '50px',
		top: '50%',
		left:'50%'
	},
	btnContainer: {
		color: "rgb(0 0 179)",
		display: "flex",
    justifyContent:'flex-end',
		margin: "1em",
	},
}))

export const PostRoiReport = (props) => {  
	const classes = useStyles(); 
	let payloadforPOSTROIFilters = useStore(
        (state) => state.payloadforPOSTROIFilters
    );
	const [ allFilters , setAllFilters] = useState([]);
	const [ progress , setProgress] = useState(true);
	
	let selectedCountry = useStore((state) => state.selectedCountry);
	const { isLoading: isTokenData, data: tokenData, isSuccess } = useTokenBI(
		props.reportID
		);
	const [biToken, setBiToken] = useState('');
	const [embedUrl, setEmbedUrl] = useState('');

	useEffect(() => {
		if (!isTokenData && tokenData) {
			setBiToken(tokenData?.accessToken);
			setEmbedUrl(tokenData?.url)
		}
	}, [isTokenData, tokenData])

	const getPageName = () =>{
		return props.pageName
	}
	const fieldArray = [
		{column:'Category',field:'categories',table:'Product Dim'},
		{column:'Brand',field:'brands',table:'Product Dim'},
		{column:'Channel',field:'channels',table:'Customer Dim'},
		{column:'Sold To Desc',field:'customers',table:'Customer Dim'},
		{column:'EAN',field:'eans',table:'Product Dim'},
		{column:'Sales Office',field:'salesOffice',table:'Customer Dim'},
		{column:'Sector',field:'sectors',table:'Product Dim'},
		{column:'Sub Brand',field:'subBrands',table:'Product Dim'},
		{column:'Sub Category',field:'subCategories',table:'Product Dim'},
		{column:'Planning Level',field:'planningCustomers',table:'Customer Dim'},
		{column:'Year Week',field:'yearWeeks',table:'Date Dim'},
		{column:'Event ID',field:'promoIds',table:'Promo Type Dim'},
		{column:'Promo Description',field:'promoTypes',table:'Promo Type Dim'},
	]	
	const basicFilterCountry = {
		$schema: 'http://powerbi.com/product/schema#basic',
		target: {
		  table: 'Country Dim',
		  column: 'Country ID',
		},
		operator: 'In',
		values: [selectedCountry],
		filterType: 1,
	  };
	  const fixedCostFilter = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: 'Fixed cost Dim',
			column: "Fixed Costs"
		},
		operator: "In",
		values:[payloadforPOSTROIFilters['includeFixedCosts']],	
		filterType: models.FilterType.Basic
	}
	useEffect(() => {
		let selectedFilters = [basicFilterCountry,fixedCostFilter];
		fieldArray.forEach((item)=>{
				
			if (payloadforPOSTROIFilters && payloadforPOSTROIFilters[item.field] && payloadforPOSTROIFilters[item.field].length > 0) {
				let filter = {
					$schema: "http://powerbi.com/product/schema#basic",
					target: {
						table: item.table,
						column: item.column
					},
					operator: "In",
					requireSingleSelection:false,
					values: payloadforPOSTROIFilters[item.field],
					filterType:models.FilterType.Basic
				}
				selectedFilters.push(filter);

			}
		
	})
	setAllFilters(selectedFilters);

	}, [payloadforPOSTROIFilters])
	
	const generateReport = () =>{
		
		return (<header className="App-header">
			<PowerBIEmbed
					embedConfig={{
						pageName: getPageName(),
						type: 'report',   // Supported types: report, dashboard, tile, visual and qna
						id: props.reportID, //"78afa3ce-f6da-4aca-9f13-361a73e2d6cf",
						embedUrl: embedUrl,
						accessToken: biToken,
						filters: allFilters, 
						tokenType: models.TokenType.Embed,						
						settings: {
							panes: {
								filters: {
									expanded: false,
									visible: false
								},
								pageNavigation: {
									visible: false
								},
							},
						}
					}}
					eventHandlers={
					new Map([
						['loaded', (() => setProgress(true))],
						['rendered', function () { 
							setProgress(false);
							setTimeout(() => {
								const parent = document.getElementsByClassName('Embed-container')
								if (parent && parent.length > 0){
									const iframe = document.getElementsByTagName('iframe',parent)
									if (iframe && iframe[0]){
										iframe[0].setAttribute('id','powerBIFrame')
									}
								}
								
							}, 2000)
						}],
						['error', (() =>{setProgress(true)})]
					])
					}
					cssClassName={"Embed-container"}
					getEmbeddedComponent={(embeddedReport) => {
						window.report = embeddedReport;
					}}
			/>
		</header>)
	}
	
//style={{maxHeight: '30%',overflowY:'hidden', overflowX: 'scroll',maxWidth:'100%'}}
	return (
		<div className="App KR-PostROI-Dashboard-Reports">
			{
				!isTokenData && biToken &&  isSuccess && embedUrl
				? generateReport()
				: <CircularProgress className={classes.spinner}/>
			}

		</div>
	);
}

export const MemoizedPostRoiReport = React.memo(PostRoiReport);