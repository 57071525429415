import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ProductItemSelect from './productItemSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '4rem'
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        width:theme.spacing(143),
        backgroundColor: theme.palette.background.paper,
      },
    listbox: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    input: {
        color: theme.palette.primary.main,
    },
    iconButton: {
        float: 'right',
        padding: 10,
        color: theme.palette.primary.main
    },
    divider: {
        float: 'right',
        height: 28,
        margin: 4,
    },
    checkBox: {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,

    }
}));

export default function ProductCustomSearch(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
      console.log("I am Clicked.")
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

    return (
        <Paper component="form" className={classes.root} >           
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton type="submit" className={classes.iconButton}  aria-label="search">
                    <SearchIcon />
                </IconButton>
            </div>
            <ProductItemSelect data={props.data}></ProductItemSelect>
               
        </Paper>
    );
}



