import React, {Component} from 'react';
import './MonthRangePicker.css';
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css';

class MonthBox extends Component {
    constructor(props, context) {
      super(props, context)
      this.state = {
          value: this.props.value || 'N/A',
          displayLabel: false,
      }
      this._handleClick = this._handleClick.bind(this);
    }

    componentWillReceiveProps(nextProps){
      this.setState({
          value: nextProps.value || 'N/A',
          displayLabel: nextProps.displayLabel,
      })
    }

    render() {
      return (
        <div className="box" onClick={this._handleClick}>
            <label>{this.state.value}</label>
            {
                this.state.displayLabel && (
                    <div className="LabelBox">
                        <label className="LabelItem1">From</label>
                        <label className="LabelItem2">To</label>
                    </div>
                )
            }
        </div>
      );
    }

    _handleClick(e) {
      this.props.onClick && this.props.onClick(e);
    }
}

class List extends Component {
    constructor(props, context) {
        super(props, context)
        var startMonth = props.startMonth.split('-');
        var endMonth = props.endMonth.split('-');

        this.state = {
            displayLabel: false,
            mvalue: {year: 2014, month: 11},
            mvalue2: {year: 2016, month: 7},
            mrange: {from: {year: 2014, month: 8}, to: {year: 2015, month: 5}},
            mrange2: {from: {year: 2013, month: 11}, to: {year: 2016, month: 3}},
            rangeValue: {from: {year: parseInt(startMonth[0]), month: parseInt(startMonth[1])}, to: {year: parseInt(endMonth[0]), month: parseInt(endMonth[1])}},
            years: {min :  {year: parseInt(startMonth[0]), month: parseInt(startMonth[1])}, max: {year: parseInt(endMonth[0]), month: parseInt(endMonth[1])}}
        }

        this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
        this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this)

        this.handleClickMonthBox2 = this.handleClickMonthBox2.bind(this)
        this.handleAMonthDissmis2 = this.handleAMonthDissmis2.bind(this)

        this._handleClickRangeBox = this._handleClickRangeBox.bind(this)
        this.handleRangeChange = this.handleRangeChange.bind(this)
        this.handleRangeDissmis = this.handleRangeDissmis.bind(this)

        this._handleClickRangeBox2 = this._handleClickRangeBox2.bind(this)
        this.handleRangeDissmis2 = this.handleRangeDissmis2.bind(this)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value || 'N/A',
        })
    }

    render() {

        const pickerLang = {
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            from: 'From', to: 'To',
        }
        const rangeValue = this.state.rangeValue

        const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
            return '?'
        }

        return (
            <ul style={{padding:0}}>
                <li>
                    <div className="edit">
                        <Picker
                            ref="pickRange"
                            years={this.state.years}
                            value={rangeValue}
                            lang={pickerLang.months}
                            onChange={this.handleRangeChange}
                            onDismiss={this.handleRangeDissmis}
                        >
                            <MonthBox value={makeText(rangeValue.from) + '  -  ' + makeText(rangeValue.to)} displayLabel={this.state.displayLabel} onClick={this._handleClickRangeBox} />
                        </Picker>
                    </div>
                </li>

            </ul>
        )
    }

    handleClickMonthBox(e) {
        this.refs.pickAMonth.show()
    }
    handleAMonthDissmis(value) {
        this.setState( {mvalue: value} )
    }
    handleClickMonthBox2(e) {
        this.refs.pickAMonth2.show()
    }
    handleAMonthDissmis2(value) {
        this.setState( {mvalue2: value} )
    }

    _handleClickRangeBox(e) {
        if(!this.state.displayLabel)
            this.setState( {displayLabel: !this.state.displayLabel} )
        this.refs.pickRange.show()
    }
    handleRangeChange(value, text, listIndex) {
        let newRangeValue = this.state.rangeValue;
        if(listIndex === 0) {
            newRangeValue['from']['year'] = value;
            newRangeValue['from']['month'] = text;
        } else {
            newRangeValue['to']['year'] = value;
            newRangeValue['to']['month'] = text;
        }
        this.props.passValue(newRangeValue);
        this.setState({rangeValue: newRangeValue});
    }
    handleRangeDissmis(value) {
        this.setState( {mrange: value} )
        this.setState( {displayLabel: false} )
    }
    _handleClickRangeBox2(e) {
        this.refs.pickRange2.show()
    }
    handleRangeDissmis2(value) {
        this.setState( {mrange2: value} )
    }
}

class Main extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            value: this.props.value
        }
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value
        })
    }
    render() {

        return (
            <div className="list-area">
                <List 
                    passValue={(event)=>{this.props.handleUpdate(event)}}
                    startMonth={this.props.startMonth}
                    endMonth={this.props.endMonth}
                />
            </div>
        )
    }
}

export default Main;