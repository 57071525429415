
import React, { useEffect, useState } from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';

import useStore from '../../store';
import { usePromotionPerformances } from '../../services/queries';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./style.css";
import CurrencyFormat from 'react-currency-format';


const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    table: {
        minWidth: 650,
        background: '#fff',
        border: "1px solid rgba(0,0,0,0.2)",
        fontSize: "0.75rem"
    },
    cellData: {
        borderLeft: '1px solid #ccc',
        textTransform: 'capitalize'
    }

}));

export default function PromoProductTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    
    //store value
    const colorCode =
        { 'GREEN': "75c975", 'RED': "e8625a", 'AMBER': "fad05c" }

    let selectedCountry = useStore((state) => state.selectedCountry);
    let selectedPromotionalItem = useStore(
        (state) => state.selectedPromotionalItem
    );
    let customersStateData = useStore(
        (state) => state.customersStateData
    );
    let promotionalsStateData = useStore(
        (state) => state.promotionalsStateData
    );
    let productsStateData = useStore(
        (state) => state.productsStateData
    );
    let setPayloadforPOSTROIFilters = useStore(
        (state) => state.setPayloadforPOSTROIFilters
    );

    const selecteYeardWeeks = useStore((state) => state.selectedYearWeeks);
    let groupBy = useStore((state) => state.groupBy);
    const selectedQuadrant = useStore((state) => state.selectedQuadrant);

    const fixedCostInclude = useStore((state) => state.fixedCostInclude);

    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [payload, setPayload] = useState({
        "dataFor": props.dataFor,
        "brands": [],
        "categories": [],
        "channels": [],
        "country": "KR",
        "customers": [],
        "eans": [],
        "includeFixedCosts": fixedCostInclude,
        "packs": [],
        "planningCustomers": [],
        "salesOffice": [],
        "sectors": [],
        "subBrands": [],
        "subCategories": [],
        "yearWeeks": [],
        "promoIds": [],
        "promoTypes": [],
    });

    //calling api POST /promotionPerformances
    const { isLoading: isRoiData, data: RoiData } = usePromotionPerformances(payload);

    useEffect(() => {
        if (!isRoiData && RoiData) {
            setTableData([]);
            if(RoiData.data.length > 3000){
                let sliceData = RoiData;
                sliceData.data = sliceData.data.slice(0,2000);
                setTableData(sliceData);
            }else{
            setTableData(RoiData);
        }
            handleRequestSort('', "ASC");
        }
    }, [isRoiData, groupBy, RoiData, selectedQuadrant]);
    
    let productFieldArray = [
        {
            name: "Brand",
            field: "brand",
            sticky: true,
            left: 0,
            width: 100,
            formatRequired:false
        },

        {
            name: "Sub Brand",
            field: "subBrand",
            sticky: true,
            left: 100,
            width: 150,
            formatRequired:false
        },
        {
            name: "Product",
            field: "product",
            sticky: true,
            left: 250,
            width: 250,
            formatRequired:false
        },
    ];

    let productByCustomerFieldArray = [
        {
            name: "Customer",
            field: "customer",
            sticky: true,
            left: 0,
            width: 150,
            formatRequired:false
        },
        {
            name: "Brand",
            field: "brand",
            sticky: true,
            left: 150,
            width: 150,
            formatRequired:false
        },

        {
            name: "Sub Brand",
            field: "subBrand",
            sticky: true,
            left: 300,
            width: 150,
            formatRequired:false
        },
        {
            name: "Product",
            field: "product",
            sticky: true,
            left: 450,
            width: 150,
            formatRequired:false

        },
    ];

    let promoByProductArray = [
        {
            name: "Start Date",
            field: "startDate",
            sticky: true,
            left: 0,
            width: 100,
            formatRequired:false
        },

        {
            name: "Duration",
            field: "duration",
            sticky: true,
            left: 100,
            width: 120,
            formatRequired:false
        },

        {
            name: "Promotion Grouping",
            field: "promotionGrouping",
            sticky: true,
            left: 220,
            width: 130,
            formatRequired:false
        },

        {
            name: "Promotion",
            field: "promotion",
            sticky: true,
            left: 350,
            width: 250,
            formatRequired:false

        },
        {
            name: "Customer",
            field: "customer",
            sticky: true,
            left: 600,
            width: 100,
            formatRequired:false

        },
        {
            name: "Product",
            field: "product",
            sticky: true,
            left: 700,
            width: 180,
            formatRequired:false

        },
    ];

    let promoByArray = [
        {
            name: "Start Date",
            field: "startDate",
            sticky: true,
            left: 0,
            width: 100,
            formatRequired:false
        },

        {
            name: "Duration",
            field: "duration",
            sticky: true,
            left: 100,
            width: 120,
            formatRequired:false
        },

        {
            name: "Promotion Grouping",
            field: "promotionGrouping",
            sticky: true,
            left: 220,
            width: 130,
            formatRequired:false
        },

        {
            name: "Promotion",
            field: "promotion",
            sticky: true,
            left: 350,
            width: 250,
            formatRequired:false

        },
        {
            name: "Customer",
            field: "customer",
            sticky: true,
            left: 600,
            width: 180,
            formatRequired:false

        },
    ];
    
    let fieldArray = [
        {
            name: "YK ROI",
            field: "ykRoi",
            formatRequired:true
        },
        {
            name: "GP ROI",
            field: "gpROI",
            formatRequired:true
        },
        {
            name: "Base KC GP %",
            field: "baseKCGPPercentage",
            formatRequired:false
        },
        {
            name: "Promo KC GP %",
            field: "promoKCGPPercentage",
            formatRequired:false
        },
        {
            name: "Base NSV (₩)",
            field: "baselineNSV",
            formatRequired:true
        },
        {
            name: "Incremental NSV (₩)",
            field: "incrementalNSV",
            formatRequired:true
        },
        {
            name: "Promo NSV (₩)",
            field: "totalNSV",
            formatRequired:true
        },
        {
            name: "Base GP (₩)",
            field: "baseGP",
            formatRequired:true
        },
        {
            name: "Incremental GP (₩)",
            field: "incrementalGP",
            formatRequired:true
        },
        {
            name: "Promo GP (₩)",
            field: "totalGP",
            formatRequired:true
        },
       
        {
            name: "Base  Volume",
            field: "baselineVolume",
            formatRequired:true
        },
    
        {
            name: "Incremental Volume",
            field: "incrementalVolume",
            formatRequired:true
        },
        {
            name: "Promo Volume",
            field: "totalVolume",
            formatRequired:true
        },
         {
            name: "Volume Uplift",
            field: "volumeUplift",
            formatRequired:false
        },
    
        {
            name: "Base RIR (₩)",
            field: "baselineRIR",
            formatRequired:true
        },
    
        {
            name: "Incremental RIR (₩)",
            field: "incrementalRIR",
            formatRequired:true
        },
    
        {
            name: "Promo RIR (₩)",
            field: "totalRIR",
            formatRequired:true
        },
        {
            name: "Base Retailer RSV Excl Tax (₩)",
            field: "baselineRetRSV",
            formatRequired:true
        },
        {
            name: "Incremental Retailer RSV Excl Tax (₩)",
            field: "incrementalRetRSV",
            formatRequired:true
        },
        {
            name: "Promo Retailer RSV Excl Tax (₩)",
            field: "totalRetRSV",
            formatRequired:true
        },
        {
            name: "Base Retailer GP (₩)",
            field: "baselineRetGP",
            formatRequired:true
        },
        {
            name: "Incremental Retailer GP (₩)",
            field: "incrementalRetGP",
            formatRequired:true
    
        },
        {
            name: "Promo Retailer GP (₩)",
            field: "totalRetGP",
            formatRequired:true
        },
        {
            name: "Base Retailer GP %",
            field: "baseRetailerGPPercentage",
            formatRequired:false
    
        },
        {
            name: "Promo Retailer GP %",
            field: "promoRetailerGPPercentage",
            formatRequired:false
    
        },
        
        {
            name: "Base Sell-In Price (₩)",
            field: "baseSellInPrice",
            formatRequired:true
        },
        {
            name: "Promo Sell-In Price (₩)",
            field: "promoSellInPrice",
            formatRequired:true
        },
        {
            name: "Base Sell-Out Price (₩)",
            field: "baseSellOutPrice",
            formatRequired:true
        },
        {
            name: "Promo Sell-Out Price (₩)",
            field: "promoSellOutPrice",
            formatRequired:true
    
        },
        {
            name: "KC Profit Split",
            field: "kcProfitSplit",
            formatRequired:true
        },
        {
            name: "Retailer Profit Split",
            field: "retailerProfitSplit",
            formatRequired:true
        }    
    ];

    if(props.dataFor !== "PROMOTION") {
        const eanField = {
            name: "EAN",
            field: "ean",
            formatRequired:false,
            alignCenter: true,
        }
        fieldArray.splice(0, 0, eanField);
    }

    let displayFieldArray = [];

    if (props.dataFor === "PRODUCT") {
        displayFieldArray = [...productFieldArray, ...fieldArray]
    }

    else if (props.dataFor === "PROMO_PRODUCT") {
        displayFieldArray = [...promoByProductArray, ...fieldArray]
    }

    else if (props.dataFor === "PROMOTION") {
        displayFieldArray = [...promoByArray, ...fieldArray]
    } else {
        displayFieldArray = [...productByCustomerFieldArray, ...fieldArray]
    }

    useEffect(() => {
        let selectedValue = {
            country: selectedCountry,
            yearWeeks: selecteYeardWeeks,  //202244 
            "includeFixedCosts": fixedCostInclude,
        }
        let customer = getCustomerData();
        let product = getProductData();
        let promo = gePromoData();
        selectedValue = { ...payload, ...selectedValue, ...customer.data, ...product.data, ...promo.data };
        setPayload(selectedValue)
        setPayloadforPOSTROIFilters(selectedValue)

    }, [selectedCountry, selecteYeardWeeks, customersStateData, fixedCostInclude, productsStateData, promotionalsStateData, selectedPromotionalItem]);

    function putAll(x) {
        return x.name;
    }

    function isSelected(x) {
        return x.isChecked;
    }

    function getCustomerData() {
        let selectedArr = [];
        let customerPayLoad = {};
        let formatedPayLoad = {};
        const arr = [
            "channel",
            "salesOffice",
            "planningCustomer",
            "customers"
        ];
        let values = { ...customersStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            customerPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                        customerPayLoad[level].push(val);
                        selectedArr.push(val);
                    });
                }
            }
        }
        formatedPayLoad['customers'] = customerPayLoad["customers"];
        formatedPayLoad['channels'] = customerPayLoad["channel"];
        formatedPayLoad['salesOffice'] = customerPayLoad["salesOffice"];
        formatedPayLoad["planningCustomers"] = customerPayLoad["planningCustomer"];
        return { data: formatedPayLoad };
    }

    function getProductData() {
        let selectedArr = [];
        let productPayLoad = {};
        let formatedPayLoad = {};
        const arr = [
            "brand",
            "category",
            "eans",
            "sector",
            "subBrand",
            "subCategory",
        ];
        let values = { ...productsStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            productPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                    productPayLoad[level].push(val);
                    selectedArr.push(val);    
                    });
                }
            }
        }

        formatedPayLoad.eans = [];
        if (productPayLoad["eans"].length > 0) {
            productPayLoad["eans"].forEach(element => {
                let eans = element.split("-");
                if (eans.length > 0) {
                    formatedPayLoad.eans.push(eans[eans.length - 1].trim())
                }
            });
        }
        formatedPayLoad.sectors = productPayLoad["sector"];
        formatedPayLoad.categories = productPayLoad["category"];
        formatedPayLoad.subBrands = productPayLoad["subBrand"];
        formatedPayLoad.brands = productPayLoad["brand"];
        formatedPayLoad.subCategories = productPayLoad["subCategory"];
        //formatedPayLoad.eans = productPayLoad["eans"];
        return { data: formatedPayLoad };
    }

    function gePromoData() {
        let selectedArr = [];
        let customerPayLoad = {};
        let formatedPayLoad = { promoTypes: [], promoIds: [] };
        const arr = [
            "promoType",
            "promoIds",
        ];
        let values = { ...promotionalsStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            customerPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                        customerPayLoad[level].push(val);
                        selectedArr.push(val);
                    });
                }
            }
        }

        formatedPayLoad.promoIds = customerPayLoad["promoIds"];
        formatedPayLoad.promoTypes = customerPayLoad["promoType"];
        return { data: formatedPayLoad };
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow key="Head-row">

                    {displayFieldArray.map((headCell) => (
                        <TableCell
                            key={headCell.field}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.field ? order : false}
                            style={{
                                width: headCell.sticky ? headCell.width : 150,
                                minWidth: headCell.sticky ? headCell.width : 150,
                                position: headCell.sticky ? 'sticky' : '',
                                left: headCell.sticky ? headCell.left : 0,
                                zIndex: headCell.sticky ? 1200 : 0,
                                background: headCell.sticky ? "#F6F8FC" : '',
                                top: 0,
                                borderLeft: '1px solid #ccc',
                                textAlign: "center",
                                fontSize: '11px'
                            }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.field}
                                direction={orderBy === headCell.field ? order : 'asc'}
                                onClick={createSortHandler(headCell.field)}
                            >
                                {headCell.name}

                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(tableData && tableData.data && tableData.data.length > 0 ? tableData.data : [], getComparator(order, orderBy)),
        [order, orderBy],
    );

    return (
        <>
            <div>
                {!isRoiData && selectedCountry && tableData && tableData.data && tableData.data.length > 0 ? (
                    <>
                        <div>
                            <TableContainer style={{ maxHeight: "80vh" }} >
                                <Table  stickyHeader className={classes.table} aria-label="simple table" data-testid="PromoProductsGrid">
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={tableData && tableData.data ? tableData.data.length : 0}
                                    />

                                    {tableData && tableData.data && (
                                        <TableBody>
                                            <TableRow key="total-row">

                                                {displayFieldArray.map((item, i) =>

                                                    <TableCell style={{
                                                        width: item.sticky ? item.width : 0,
                                                        minWidth: item.sticky ? item.width : 0,
                                                        position: 'sticky',
                                                        left: item.sticky ? item.left : 0,
                                                        zIndex: item.sticky ? 1200 : 1040,
                                                        background: item.sticky ? '#dcdcdc' : '#dcdcdc',
                                                        top: 60,
                                                        fontSize: "0.70rem",
                                                        borderLeft: '1px solid #ccc',
                                                        textAlign: item.sticky ? "center" : "right",
                                                        padding: '8px'
                                                        
                                                    }}>
                                                        {(i === 0 && <> Total</>)}{
                                                        tableData[`${item.field}Total`]!== null && item.formatRequired && item.formatRequired===true  ? (
                                                                <CurrencyFormat value={tableData[`${item.field}Total`]} displayType={'text'} thousandSeparator={true}  />):(tableData[`${item.field}Total`])
                                                            }</TableCell>)}
                                            </TableRow>
                                            {visibleRows.map((promoItem, i) =>
                                                <TableRow key={`data-row-${i}`}>

                                                    {displayFieldArray.map((item) =>
                                                        <TableCell key={`data-cell-${item.field}`} 
                                                         style={{
                                                            width: item.sticky ? item.width : 0,
                                                            minWidth: item.sticky ? item.width : 0,
                                                            position: item.sticky ? 'sticky' : '',
                                                            left: item.sticky ? item.left : 0,
                                                            zIndex: item.sticky ? item.width : 0,
                                                            background: item.sticky ? item.field === "promotionGrouping" ? `#${colorCode[promoItem[item.field]]}` : "#F6F8FC" : '',
                                                            top: 0,
                                                            fontSize: "0.70rem",
                                                            borderLeft: '1px solid #ccc',
                                                            textAlign: item.sticky ? "center" : item.alignCenter ? "center" : "right",
                                                            padding: '8px'
                                                        }}>{
                                                            promoItem[item.field]!== null && item.formatRequired && item.formatRequired===true  ? (
                                                                <CurrencyFormat value={promoItem[item.field]} displayType={'text'} thousandSeparator={true}  />):(promoItem[item.field])
                                                            }</TableCell>)}
                                                </TableRow>
                                            )}
                                        </TableBody>)}

                                </Table>
                            </TableContainer>
                        </div>

                    </>
                ) : (
                    <div>
                        <h4>There is no Data with selected filters</h4>
                    </div>
                )
                }
                <Dialog
                    open={isRoiData}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle className="pt-0">
                        {"Your request is being processed"}
                    </DialogTitle>
                </Dialog>
            </div>
        </>
    )
}

