import React, {useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core';

import useStore from "../../../../store";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

type PromoNames = {
  availablePromotions: string[],
  clearPromoNames: boolean,
  resetPromo: () => void,
}

const useStyles = makeStyles((theme) => ({
  select: {
    fontSize: '0.875rem !important',
    backgroundColor: '#fff',
  },
  dropdown: {
    '& > span' : {
      fontSize: '0.875rem !important',
      padding: '3px !important'
    }
  },
}));

const PromoNameFilter = ({availablePromotions, clearPromoNames, resetPromo} : PromoNames) => {
  const classes = useStyles();

  const promoNames: string[] = availablePromotions ? availablePromotions : [];
  const [selectedPromoNames, setSelectedPromoNames] = React.useState<string[]>([]);

  const selectedSimulationPromoNames: string[] = useStore((state) => state.selectedSimulationPromoNames);
  const setSelectedSimulationPromoNames = useStore((state) => state.setSelectedSimulationPromoNames);

  useEffect(() => {
    setSelectedSimulationPromoNames([])
  }, []);

  useEffect(() => {
    if(clearPromoNames){
      setSelectedPromoNames([])
      setSelectedSimulationPromoNames([])
    }
  }, [clearPromoNames])

  const handleChange = (event: SelectChangeEvent<typeof selectedPromoNames>) => {
    resetPromo();
    const {target: { value }} = event;
    setSelectedSimulationPromoNames( 
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedSimulationPromoNames}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return 'Promo Name';
            } else {
              return selected.length > 2 ? `Multiple Items Selected(${selected.length})` : selected.join(', ') }
            }
          }
          MenuProps={MenuProps}
          className={classes.select}
        >
          {promoNames.map((name: any) => (
            <MenuItem key={name} value={name} className={classes.dropdown}>
              <Checkbox checked={selectedSimulationPromoNames.indexOf(name) > -1}/>
              <ListItemText primary={name} className={classes.dropdown}/>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default PromoNameFilter;