import { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";

import '../quadrantStyles.css';
import useStore from '../store';

const quadrantOptions = [
  { value: 1, label: "ROI>0, IS>0" },
  { value: 2, label: "ROI<=0, IS>0" },
  { value: 3, label: "ROI<=0, IS<=0" },
  { value: 4, label: "ROI>0, IS<=0" },
];

const QuadrantSelector = () => {
  const setQuadrantFilters = useStore((state) => state.setSelectedQuadrant);
  const [selectedQuadrant, setSelectedQuadrant] = useState(quadrantOptions);

  useEffect(()=>{
    setQuadrantFilters([0]);
  }, []);

  useEffect(() => {
    if(selectedQuadrant && selectedQuadrant.length > 0){
      selectedQuadrant.length === quadrantOptions.length ? setQuadrantFilters([0]) : setQuadrantFilters(selectedQuadrant.map(quad => quad.value));
    } else {
      setQuadrantFilters([0]);
    }
  }, [selectedQuadrant]);

  const SelectIconRenderer = () => (
    <>
      <svg 
        enable-background="new 0 0 12 12" 
        height="12px"
        viewBox="0 0 26 26"
        width="12px"
        style={{marginLeft:"10px"}}
      >
        <polygon fill="#808080" points="0,6.438 22,6.438 10,16.562 "/>
      </svg>
    </>
  );
 
  return (
    <div className="mt-3" style={{zIndex : "999"}}>
      <MultiSelect
        options={quadrantOptions}
        value={selectedQuadrant}
        onChange={setSelectedQuadrant}
        disableSearch={true}
        multiple={true}
        overrideStrings={{
          selectSomeItems: "Select Quadrant",
          allItemsAreSelected: "All Quadrants are selected",
          selectAll: "All",
        }}
        ArrowRenderer={() => <SelectIconRenderer/>}
      />
    </div>
  );
}

export default QuadrantSelector;