import ProductTableSummary from "../SummaryScreen/Product/ProductTableSummary";
import CustomerTableSummary from "../SummaryScreen/Customer/CustomerTableSummary";
import { MemoizedPostRoiReport } from "../Chart/PostRoiChart"

function SummaryTab(props) {
    return (
        <>
            <ProductTableSummary dataFor="PRODUCT" />
            <div className="App KR-PostROI-Summary-Reports">
                <MemoizedPostRoiReport reportID={process.env.REACT_APP_SUMMARYCHART_REPORT_ID} pageName={process.env.REACT_APP_SUMMARYCHART_PAGENAME} />
             </div>
            <CustomerTableSummary dataFor="CUSTOMER" />
            <div className="App KR-PostROI-Summary-Reports">
                <MemoizedPostRoiReport reportID={process.env.REACT_APP_CUSTOMERSUMMARYCHART_REPORT_ID} pageName={process.env.REACT_APP_CUSTOMERSUMMARYCHART_PAGENAME} />
            </div>
        </>
    );
}

export default SummaryTab;