import { useMutation } from 'react-query';
import { ApiRequestService } from './api-service';
import { AppQueryClient } from './clients/query-client';
import { BaseLineValuePost, SimulatedPAndLPost, IROIPost, ISimulationPost, SimulatedROIAndNPLPost,PromoCalculationPost } from '../models/common/post';



export const useDeleteImpactor = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: string) => apiRequestService.getImpactorDelete(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useGetImpactorHistory');
    },
  });
  return mutation;
};


export const useCreateNewImpactorHistory = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: object) => apiRequestService.createImpactorHistory(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useGetImpactorHistory');
    },
  });
  return mutation;
};

export const useGetBaseLineValue = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: BaseLineValuePost) => apiRequestService.getBaseLineValue(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useGetBaseLineValue');
    },
  });
  return mutation;
};


export const useGetSimulatedValues = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: SimulatedPAndLPost) => apiRequestService.getSimulatedValues(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useGetSimulatedValues');
    },
  });
  return mutation;
};


export const useGetSimulatedPAndLValues = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: SimulatedROIAndNPLPost) => apiRequestService.getSimulatedPAndL(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useGetSimulatedPAndLValues');
    },
  });
  return mutation;
};

export const useGetPromoInvMagicValues = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: string) => apiRequestService.getPromoInvMagic(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useGetPromoInvMagicValues');
    },
  });
  return mutation;
};


export const useGetCalculatedIncVolume = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: string) => apiRequestService.getCalculatedIncVolume(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('UseGetCalculatedIncVolume');
    },
  });
  return mutation;
};


export const usePostSimulatedData = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: IROIPost) => apiRequestService.simulatedSaveURL(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('PostSimulatedData');
    },
  });
  return mutation;
};


export const useSimulationsForTallyURL = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data: ISimulationPost) => apiRequestService.simulationsForTallyURL(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useSimulationsForTallyURL');
    },
  });
  return mutation;
};
export const usePromoCalculations = () => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data:PromoCalculationPost) => apiRequestService.getpromoforCalculations(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('usePromoCalculations');
    },
  });
  return mutation;
};
export const usePromoNameCheck = (data: any) => {
  const apiRequestService = ApiRequestService.createInstance();

  const mutation = useMutation((data:any) =>   apiRequestService.checkPromoName(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('usePromoNameCheck');
    },
  });
  return mutation;  
}
export const useSimulationParameters = (data:any) => { //POST /
  const apiRequestService = ApiRequestService.createInstance();
  const mutation = useMutation((data: any) => apiRequestService.simulationParameters(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useSimulationParameters');
    },
  });
  return mutation;
};
export const useGeneratePnL = () => { //POST /
  const apiRequestService = ApiRequestService.createInstance();
  const mutation = useMutation((data: any) => apiRequestService.generatePnl(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useGeneratePnL');
    },
  });
  return mutation;
};
export const useSavePnL = () => { //POST /
  const apiRequestService = ApiRequestService.createInstance();
  const mutation = useMutation((data: any) => apiRequestService.savePnL(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useSavePnL');
    },
  });
  return mutation;
};

export const useDeletePromotion = () => { //POST /
  const apiRequestService = ApiRequestService.createInstance();
  const mutation = useMutation((data: any) => apiRequestService.deletePromotion(data), {
    onSuccess: () => {
      // Query Invalidations
      AppQueryClient.invalidateQueries('useDeletePromotion');
    },
  });
  return mutation;
};
