
import { useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import useStore from "../../../store";
import { InputSectionLayout } from './InputSection/InputSectionLayout';
import { PromoProductsGrid } from './PromoProductsGrid/PromoProductsGrid';
import { ActionBar } from './ActionBar';
import { PnLGrids } from './ProfitAndLossPannel/PnLGrids';
import usePromoSimulatorStore from '../../../store/promoSimulatorStore';
import { useGetSimulatedPromoDetails } from '../../../services/queries';
import { getProductDetailsData } from '../utils';
import { useGeneratePnL } from "../../../services/mutations";

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary

    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const CreateSimulations = (props) => {
  const [promoQuery, setPromoQuery] = useState('');
  const [expandProductGrid, setExpandProductGrid] = useState(false);
  const [expandPnL, setExpandPnL] = useState(false);
  const [promoNameError, setPromoNameError] = useState(false);

  const promoSimulatorTabs = usePromoSimulatorStore((state) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state) => state.setPromoSimulatorTabs);
  const selectedCountry = useStore((state) => state.selectedCountry);
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);
  const setSimulatedProductDetails = useStore((state) => state.setSimulatedProductDetails);
  const simulatedProductDetails = useStore((state) => state.simulatedProductDetails);

  const { mutate: getPnLData, data: pnLData, isSuccess: pnLDataSuccess, isError: successError, isLoading: isPnLDataLoading } = useGeneratePnL();
  const { data: promoData, isLoading: isSimulationDataLoading } = useGetSimulatedPromoDetails(promoQuery);

  const tabValues = {
    promoName: '',
    fromDate: "",
    toDate: "",
    duration: 0,
    sellInPriceType: "",
    sellInPriceTypevalue: "",
    selectedCustomer: [],
    selectedProduct: [],
    unitOfMeasure: "Packs",
    sellOutPriceType: "",
    sellOutPriceTypeValue: "",
    additionalFunding: "",
    redemptionRate: "",
    volumeType: "",
    volumeTypeValue: "",
    fixedCost: 'Y',
    reset: false,
    calculateButtonClicked: false,
    pnLGridGenerated: false,
    PnLResposne: [],
    displayPromo: []
  };

  const initialTab = [{
    tabIndex: 0,
    tabLabel: '',
    ...tabValues
  }];

  useEffect(() => {
    setPromoSimulatorTabs(initialTab);
    setSimulatedProductDetails([]);
  }, []);

  useEffect(() => {
    if (promoData && selectedPromoDetails.fromView) {
      setExpandProductGrid(true);
      let tempPromoArray = [];
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab) => tab.tabIndex === props.id ? { ...tab, triggerCalculate: false } : tab));

      let calculatedArr = promoData && promoData.products ? getProductDetailsData(promoData.products) : [];
      if (promoData && promoData.products && calculatedArr.length > 0) {
        calculatedArr = calculatedArr.sort((a, b) => a.ean > b.ean ? 1 : -1);
        Object.keys(calculatedArr[0]).forEach((row) => {
          tempPromoArray.push(generateKeyObject(row, row, calculatedArr));
          if (row === "baseGSVListPrice") {
            tempPromoArray.push(generateKeyObject("baseGSVListPrice", "sellInListPriceOverride", calculatedArr));
          } else if (row === "baseVolume") {
            tempPromoArray.push(generateKeyObject("baseVolume", "baseVolumeOverride", calculatedArr));
          } else if (row === "additionalFunding") {
            tempPromoArray.push(generateKeyObject("additionalFunding", "additionalFundingOverride", calculatedArr));
          } else if (row === "baseSellOutPrice") {
            tempPromoArray.push(generateKeyObject("baseSellOutPrice", "baseSellOutPriceOverride", calculatedArr));
          } else if (row === "cogs") {
            tempPromoArray.push(generateKeyObject("cogs", "fixedCogOverride", calculatedArr));
          }

          const findValueByKey = (keyToFind, targetKey) => {
            const row = tempPromoArray.find((item) => item.key === keyToFind);
            if (row) {
              const valueItem = row.value.find((item) => Object.keys(item)[0] === targetKey)
              if (valueItem) {
                return valueItem[targetKey];
              }
            }
            return 0;
          }
  
          tempPromoArray.forEach((row) => {
            if (row.key === "discount") {
              row.value.forEach((item) => {
                const key = Object.keys(item)[0];
                const baseSellOutPriceOverrideValue = findValueByKey("baseSellOutPriceOverride", key);
                const promoSellOutPriceValue = findValueByKey("promoSellOutPrice", key);
                item[key] = Number(baseSellOutPriceOverrideValue) - Number(promoSellOutPriceValue)
              })
            }
          })
  
          tempPromoArray.forEach((row) => {
            if (row.key === "discountPercent") {
              row.value.forEach((item) => {
                const key = Object.keys(item)[0];
                const baseSellOutPriceOverrideValue = findValueByKey("baseSellOutPriceOverride", key);
                const promoSellOutPriceValue = findValueByKey("promoSellOutPrice", key);
                item[key] = baseSellOutPriceOverrideValue === 0 ? 0 : (((Number(baseSellOutPriceOverrideValue) - Number(promoSellOutPriceValue)) / Number(baseSellOutPriceOverrideValue)) * 100).toFixed(1);
              })
            }
          })
  
          tempPromoArray.forEach((row) => {
            if (row.key === "volumeUplift") {
              row.value.forEach((item) => {
                const key = Object.keys(item)[0];
                const baseVolumeOverrideValue = findValueByKey("baseVolumeOverride", key);
                const promoVolumeValue = findValueByKey("promoVolume", key);
                item[key] = baseVolumeOverrideValue === 0 ? 0 : ((Number(promoVolumeValue) - Number(baseVolumeOverrideValue)) / Number(baseVolumeOverrideValue)).toFixed(1);
              })
            }
          })
        })
        setSimulatedProductDetails(calculatedArr);
      }
      const duration = calDuration(new Date(promoData.promoFrom), new Date(promoData.promoTo));
      const promoSellOutvalue = promoData.promoSellOutOption === 'MULTIBUY' ? promoData.multiBuyOption : promoData.promoSellOutValue;
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab) => tab.tabIndex === 0 ?
        {
          ...tab,
          promoName: selectedPromoDetails.type === "view" ? promoData.name : "",
          fromDate: new Date(promoData.promoFrom),
          toDate: new Date(promoData.promoTo),
          duration: duration,
          sellInPriceType: promoData.promoSellInOption,
          sellInPriceTypevalue: promoData.promoSellInValue,
          selectedCustomer: promoData.customer,
          selectedProduct: [],
          unitOfMeasure: "Packs",
          sellOutPriceType: promoData.promoSellOutOption,
          sellOutPriceTypeValue: promoSellOutvalue,
          additionalFunding: promoData.additionalFunding ? promoData.additionalFunding : "",
          redemptionRate: promoData.redemptionRate,
          volumeType: promoData.promoVolumeOption,
          volumeTypeValue: promoData.promoVolumeValue,
          fixedCost: promoData.fixedCosts,
          reset: false,
          calculateButtonClicked: selectedPromoDetails.fromView,
          pnLGridGenerated: false,
          promoCalculation: calculatedArr,
          displayPromo: tempPromoArray,
          triggerCalculate: false,
          PnLResposne: [],
          promoCalculation: [],
          multiBuy: promoData.promoSellOutOption === 'MULTIBUY' ? true : false,
        } : tab));
    }
  }, [promoData, selectedPromoDetails])

  useEffect(() => {
    if (selectedPromoDetails && selectedPromoDetails.promoName && selectedPromoDetails.fromView) {
      const promoName = selectedPromoDetails.promoName;
      setPromoQuery(`${selectedCountry}&name=${promoName}`);
    }
  }, [selectedPromoDetails])

  useEffect(() => {
    if ((promoSimulatorTabs[0]?.calculateButtonClicked && promoSimulatorTabs[0]?.promoCalculation && promoSimulatorTabs[0]?.promoCalculation.length > 0) ||
      (promoData && selectedPromoDetails.fromView)) {
      setExpandProductGrid(true);
    } else {
      setExpandProductGrid(false);
    }
    if (promoSimulatorTabs[0]?.pnLGridGenerated && promoSimulatorTabs[0]?.PnLResposne && promoSimulatorTabs[0]?.PnLResposne.length > 0) {
      setExpandPnL(true);
    } else if (promoSimulatorTabs[0]?.PnLResposne && promoSimulatorTabs[0]?.PnLResposne.length <= 0) {
      setExpandPnL(false);
    }
  }, [promoSimulatorTabs, selectedPromoDetails])

  useEffect(() => {
    if (promoData && promoData.products && selectedPromoDetails.fromView && simulatedProductDetails.length > 0) {
      generatePnlGrid();
    }
  }, [selectedPromoDetails, promoData, simulatedProductDetails])

  useEffect(() => {
    let data = pnLData?.data.data;
    if (data) {
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab, i) => i === 0 ? { ...tab, PnLResposne: data, pnlTotalData: pnLData?.data, pnLGridGenerated: true } : tab))
    }
  }, [pnLData]);

  const calDuration = (startDate, endDate) => {
    let stDate = moment(startDate, "YYYY/MM/D");
    let edDate = moment(endDate, "YYYY/MM/D");
    let durationVal = edDate.diff(stDate, 'days');

    if (durationVal >= 0) {
      return durationVal + 1;
    } else if (durationVal < 0) {
      return 0;
    }
  };

  const generateKeyObject = (row, displayRow, calculatedArr) => {
    let tempObject = {
      key: displayRow,
      value: []
    }
    let displayPromo = promoSimulatorTabs[0] ? promoSimulatorTabs[0].displayPromo : [];
    let selectedItem = displayPromo.filter((item) => item.key === displayRow);
    if (row === displayRow) {
      calculatedArr.forEach((col) => {
        const findValue = calculatedArr.find((data) => data.ean === col.ean);
        if (tempObject.key === 'promoSellInInvoicePrice' && promoSimulatorTabs[0].sellInPriceType === "MANUAL_INPUT") {
          tempObject.value.push({ [col.ean]: findValue.baseSellInInvoicePrice })
        }
        else if (tempObject.key === 'promoSellInInvoicePrice' && promoSimulatorTabs[0].sellInPriceType === "PROMO_DEAL_FIXED") {
          // tempObject.value.push({ [col.ean]: (findValue.baseSellInInvoicePrice - promoSimulatorTabs[0].sellInPriceTypevalue).toString() })
          tempObject.value.push({ [col.ean]: (findValue.promoSellInInvoicePrice).toString() })
        }
        else if (tempObject.key === 'promoSellInInvoicePrice' && promoSimulatorTabs[0].sellInPriceType === "PROMO_DEAL_PERCENT") {
          // tempObject.value.push({ [col.ean]: (findValue.baseSellInInvoicePrice - Math.Round((promoSimulatorTabs[0].sellInPriceTypevalue / 100) * findValue.baseSellInInvoicePrice)).toString() })
          tempObject.value.push({ [col.ean]: (findValue.promoSellInInvoicePrice).toString() })
        }
        else if (tempObject.key === 'promoSellInInvoicePrice' && promoSimulatorTabs[0].sellInPriceType === "EXPECTED_MARGIN") {
          // tempObject.value.push({ [col.ean]: Math.round(findValue.promoSellOutPrice / (1 + promoSimulatorTabs[0].sellInPriceTypevalue)).toString() })
          tempObject.value.push({ [col.ean]: (findValue.promoSellInInvoicePrice).toString() })
        }
        else {
          tempObject.value.push({ [col.ean]: col[row] })
        }
      })
    } else {
      calculatedArr.forEach((col) => {
        if (selectedItem.length > 0) {
          let findIndex = selectedItem[0].value.findIndex((item) => Object.keys(item)[0] === col.ean);
          if (findIndex >= 0) {
            selectedItem[0].value.map((item) => {
              if (Object.keys(item)[0] === col.ean) {
                tempObject.value.push({ [col.ean]: item[col.ean] });
              }
            })
          } else {
            tempObject.value.push({ [col.ean]: '0' });
          }
        } else {
          const findValue = calculatedArr.find((data) => data.ean === col.ean);
          const getSavedValue = promoData?.products?.find((data) => data.ean === col.ean)
          if (tempObject.key === 'sellInListPriceOverride') {
            if (!selectedPromoDetails.fromView) {
              tempObject.value.push({ [col.ean]: findValue ? findValue.baseGSVListPrice : '0' })
            }
            else {
              tempObject.value.push({ [col.ean]: getSavedValue ? getSavedValue.sellInListPriceOverride : '0' })
            }
          } else if (tempObject.key === 'baseVolumeOverride') {
            if (!selectedPromoDetails.fromView) {
              tempObject.value.push({ [col.ean]: findValue ? findValue.baseVolume : '0' })
            }
            else {
              tempObject.value.push({ [col.ean]: getSavedValue ? getSavedValue.baseVolumeOverride : '0' })
            }

          } else if (tempObject.key === 'additionalFundingOverride') {
            if (!selectedPromoDetails.fromView) {
              tempObject.value.push({ [col.ean]: findValue ? findValue.additionalFunding : '0' })
            }
            else {
              tempObject.value.push({ [col.ean]: getSavedValue ? getSavedValue.additionalFundingOverride : '0' })
            }

          } else if (tempObject.key === 'baseSellOutPriceOverride') {
            if (!selectedPromoDetails.fromView) {
              tempObject.value.push({ [col.ean]: findValue ? findValue.baseSellOutPrice : '0' })
            }
            else {
              tempObject.value.push({ [col.ean]: getSavedValue ? getSavedValue.baseSellOutPriceOverride : '0' })
            }

          } else if (tempObject.key === 'fixedCogOverride') {
            if (!selectedPromoDetails.fromView) {
              tempObject.value.push({ [col.ean]: findValue ? findValue.cogs : '0' })
            }
            else {
              tempObject.value.push({ [col.ean]: getSavedValue ? getSavedValue.cogsOverride : '0' })
            }

          } else {
            tempObject.value.push({ [col.ean]: '0' })
          }
        }
      })
    }
    return tempObject
  }

  const generatePnlGrid = () => {
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab, i) => i === 0 ? { ...tab, pnLGridGenerated: false } : tab))
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab, i) => i === 0 ? { ...tab, pnLGridGenerated: true, productDetailsCalculateButtonClicked: true } : tab))
    let displayPromoCalculations = promoSimulatorTabs[0].displayPromo ? promoSimulatorTabs[0].displayPromo : [];
    let formatedArray = [{ key: '', value: {} }];
    let pnlInputArray = [{ key: '', value: {} }];
    displayPromoCalculations.map((item) => {
      item.value.map((element) => {
        let selectedIndex = formatedArray.findIndex((fItem) => {
          return fItem.key === Object.keys(element)[0]
        })
        if (selectedIndex <= 0) {
          let tempObj = {
            "additionalFunding": 0,
            "baseGSVListPrice": 0,
            "baseSellInInvoicePrice": 0,
            "baseSellOutPrice": 0,
            "baseVolume": 0,
            "brand": "",
            "category": "",
            "discount": 0,
            "discountPercent": 0,
            "distributionExpense": 0,
            "ean": "",
            "eanDescription": "",
            "fixedCog": 0,
            "multiBuy": false,
            "promoSellInInvoicePrice": 0,
            "promoSellOutPrice": 0,
            "promoVolume": 0,
            "redemptionRate": 0,
            "sector": "",
            "subBrand": "",
            "subCategory": "",
            "tradingTerms": 0,
            "variableCog": 0,
            "volumeUplift": 0
          }
          // @ts-expect-error
          tempObj[item.key] = element[Object.keys(element)[0]];
          formatedArray.push({ key: Object.keys(element)[0], value: tempObj })
          pnlInputArray.push({ key: Object.keys(element)[0], value: tempObj })
        }
        else {
          // @ts-expect-error
          pnlInputArray[selectedIndex].value[item.key] = element[Object.keys(element)[0]];
          if (item.key === "sellInListPriceOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['baseGSVListPrice'] = element[Object.keys(element)[0]];
          }
          else if (item.key === "baseSellOutPriceOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['baseSellOutPrice'] = element[Object.keys(element)[0]];
          }
          else if (item.key === "fixedCogOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['cogs'] = element[Object.keys(element)[0]];
          }
          else if (item.key === "baseVolumeOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['baseVolume'] = element[Object.keys(element)[0]];
          }
          else if (item.key === "additionalFundingOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['additionalFunding'] = element[Object.keys(element)[0]];
          }
          else {
            // @ts-expect-error
            formatedArray[selectedIndex].value[item.key] = element[Object.keys(element)[0]];
          }

        }
      })
    })
    let finalPayload = []
    formatedArray.forEach((item) => {
      if (item.key.length > 0) {
        finalPayload.push(item.value);
      }
    })

    let payload = {
      "multiBuy": promoSimulatorTabs[0].multiBuy,
      "redemptionRate": promoSimulatorTabs[0].redemptionRate,
      "inputs": finalPayload
    }
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab, i) => i === 0 ? { ...tab, pnlInputArray: pnlInputArray } : tab))
    getPnLData(payload)
  }

  const toggleAccordionProductGrid = () => {
    setExpandProductGrid((prev) => !prev);
  };

  const toggleAccordionPnL = () => {
    setExpandPnL((prev) => !prev);
  };

  return (
    <>
      <Card raised={true} className="p-4">
        {promoSimulatorTabs[0] && (
          <>
            <ActionBar id={0} />
            <Accordion defaultExpanded={selectedPromoDetails ? selectedPromoDetails.fromView : false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="create-simulations-content"
                id="create-simulations"
              >
                <Typography>Promotion Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <InputSectionLayout
                  id={0}
                  promoData={promoData}
                  setPromoNameError={setPromoNameError}
                  promoNameError={promoNameError}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandProductGrid}
              defaultExpanded={selectedPromoDetails ? selectedPromoDetails.fromView : false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon onClick={toggleAccordionProductGrid} />}
                aria-controls="product-details-content"
                id="product-details"
                onClick={toggleAccordionProductGrid}
              >
                <Typography>Product Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PromoProductsGrid id={0} promoData={promoData} />

              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expandPnL} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon
                  onClick={toggleAccordionPnL} />}
                aria-controls="product-details-content"
                id="product-details"
                onClick={toggleAccordionPnL}
              >
                <Typography>Promotion P&L</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PnLGrids
                  id={0}
                  setPromoNameError={setPromoNameError}
                  promoNameError={promoNameError}
                />
              </AccordionDetails>
            </Accordion>
            <Dialog
              open={isSimulationDataLoading}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle className="pt-0">
                {"Your request is being processed"}
              </DialogTitle>
            </Dialog>
          </>
        )}
      </Card>
    </>
  );
};