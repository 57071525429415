
import React, { useEffect, useState } from 'react';
import Toaster from '../commonMuiComponents/Toaster'
import { makeStyles } from '@material-ui/core/styles';
import useStore from '../store';
import DataTable from 'react-data-table-component';
import {  usePostROIData } from '../services/queries';

import { updateUrl } from "../UpdateUrl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ArrowDownward } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: '#fff',
    border: "1px solid rgba(0,0,0,0.2)",
  },
  cellData: {
    borderLeft: '1px solid #ccc',
    textTransform: 'capitalize'
  },
  sticky: {
    position: 'sticky'
  }

}));



export default function PostRoi(props) {

  const [postRoiDataPayload, setPostRoiDataPayload] = useState(null);
  const selectedCustomersHierarchy = useStore(state => state.selectedCustomersHierarchy);
  const setSelectedCustomersHierarchy = useStore(state => state.setSelectedCustomersHierarchy);
  const selectedProductsHierarchy = useStore(state => state.selectedProductsHierarchy);
  const setSelectedProductsHierarchy = useStore(state => state.setSelectedProductsHierarchy);
  const selectedEventIdValues = useStore(state => state.selectedEventIdValues);
  let selectedContry = useStore((state) => state.selectedCountry);
  let selectedBaseLineCustomerNew = useStore((state) => state.selectedBaseLineCustomerNew);
  let selectedBaseLineProductNew = useStore((state) => state.selectedBaseLineProductNew);
  const startMonth = useStore((state) => state.startMonth);
  const endMonth = useStore((state) => state.endMonth);
  let groupBy = useStore((state) => state.groupBy);
  const selectedQuadrant = useStore((state) => state.selectedQuadrant);
  const [tableData, setTableData] = useState([]);
  let selectedSource = useStore((state) => state.selectedSource);

  //calling api
  const { isLoading: isRoiData, data: RoiData, isError: roiDataError } = usePostROIData(postRoiDataPayload);

  useEffect(() => {
    let payload = {
      country: selectedContry,
      customers: selectedCustomersHierarchy?.soldTo,
      planLevels: selectedCustomersHierarchy?.planLevel,
      salesExecutive: selectedCustomersHierarchy?.salesExecutive,
      source: selectedSource,
      fromDate: startMonth,
      toDate: endMonth,
      eans: selectedProductsHierarchy?.products,
      categories: selectedProductsHierarchy?.categories,
      subCategories: selectedProductsHierarchy?.subCategories,
      brands: selectedProductsHierarchy?.brands,
      subBrands: selectedProductsHierarchy?.subBrands,
      groupBy,
      eventIds: selectedEventIdValues,
      kpiType: props.kpiType,
      quadrants: selectedQuadrant
    };

    setPostRoiDataPayload(payload);
  }, [selectedContry, startMonth, endMonth,
    groupBy, selectedSource, selectedQuadrant,
    selectedCustomersHierarchy?.soldTo,
    selectedCustomersHierarchy?.planLevel,
    selectedCustomersHierarchy?.salesExecutive,
    selectedProductsHierarchy?.products,
    selectedProductsHierarchy?.categories,
    selectedProductsHierarchy?.subCategories,
    selectedProductsHierarchy?.brands,
    selectedProductsHierarchy?.subBrands, ,
    selectedBaseLineCustomerNew, selectedBaseLineProductNew, selectedEventIdValues]);

  useEffect(() => {
    if (!isRoiData && RoiData) {
      setTableData(RoiData);
    }
  }, [isRoiData, groupBy, RoiData, selectedQuadrant]);

  let columns = [
    {
      name: groupBy.match(/[A-Z][a-z]+/g).join(' '),
      selector: row => row.groupBy,
      cell: (row) => (
        <div className='tableBld' id='table_item'>
          {row.groupBy}
        </div>
      )
    },


    {
      name: <span title='Actual sold cases * (unit non promoted price - unit COGs) - Promo Investment' id='header_promoProfit'>Promo Profit</span>,
      //Header : <span data-tip='tooltip'>Promo Profit</span>,
      selector: row => row.promoProfit,
      sortable: true,
      cell: (row) => (
        <div id='promoProfit'>
          {row.promoProfit.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },

    {
      name: <span title='Baseline cases * (unit non promoted price - unit COGs)' id='header_baseLineProfit'>Baseline Profit</span>,
      selector: row => row.baseLineProfit,
      sortable: true,
      cell: (row) => (
        <div id='baseLineProfit'>
          {row.baseLineProfit.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },

    {
      name: <span title='Promo Profit - Baseline Profit' id='header_netProfit'>Incremental Profit/Loss</span>,
      selector: row => row.netProfit,
      sortable: true,
      cell: (row) => (
        <div id='netProfit'>
          {row.netProfit.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },



    {
      name: <span title='Actual sold cases' id='header_totalVolume'>Total Volume</span>,
      selector: row => row.totalVolume,
      cell: (row) => (
        <div id='totalVolume'>
          {row.totalVolume.toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
        </div>
      )

    },

    {
      name: <span title='Estimated sold cases when no promotion exists' id='header_baseLineVolume'>Baseline  Volume</span>,
      selector: row => row.baseLineVolume,
      cell: (row) => (
        <div id='baseLineVolume'>
          {row.baseLineVolume.toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
        </div>
      )

    },

    {
      name: <span title='Total Volume - Baseline Volume' id='header_lift'>Lift</span>,
      selector: row => row.lift,
      cell: (row) => (
        <div id='lift'>
          {row.lift.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },

    {
      name: <span title='SUM(QUANTITY_CS * COGS_PER_CASE)/SUM(QUANTITY_CS)' id='header_nppPerCase'>Non Promoted Price</span>,
      selector: row => row.nppPerCase,
      cell: (row) => (
        <div id='nppPerCase'>
          {row.nppPerCase.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },

    {
      name: <span title='SUM(QUANTITY_CS * COGS_PER_CASE)/SUM(QUANTITY_CS)' id='header_cogsPerCase'>COGS</span>,
      selector: row => row.cogsPerCase,
      cell: (row) => (
        <div id='cogsPerCase'>
          {row.cogsPerCase.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },

    {
      name: <span title='Sell out volume * (Sell out price * (1-VAT) - Sell in price * (1-VAT))' id='header_retailerMargin'>Retailer Margin</span>,
      selector: row => row.retailerMargin,
      cell: (row) => (
        <div id='retailerMargin'>
          {row.retailerMargin.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },

    {
      name: <span title='Sell in price * (1-VAT) / Sell out price * (1-VAT) * 100' id='header_retailerMarginPercentage'>Retailer Margin %</span>,
      selector: row => row.retailerMarginPercentage,
      cell: (row) => (
        <div id='retailerMarginPercentage'>
          {row.retailerMarginPercentage.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },

    {
      name: <span title='Money spent in promotions' id='header_promoInvestment'>Promo Investment </span>,
      selector: row => row.promoInvestment,
      sortable: true,
      cell: (row) => (
        <div className='tableBld' id='promoInvestment'>
          {row.promoInvestment.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },
    {
      name: <span title='Incremental Profit | Loss/Promo Investement' id='header_roi'>ROI %</span>,
      selector: row => parseFloat(row.roi),
      sortable: true,
      /*  cell: (row) => (
           <div className='tableBld'>
               {row.roi.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
           </div>
       ) */
    }
  ];


  if (selectedContry === 'UK') {
    columns = [...columns,
    {
      name: <span title='Price point' id='header_pricePoint'>Price point</span>,
      selector: row => row.pricePoint,
      cell: (row) => (
        <div id='pricePoint'>
          {row.pricePoint.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },
    {
      name: <span title='Duration' id='header_duration'>Duration</span>,
      selector: row => row.duration,
      cell: (row) => (
        <div id='duration'>
          {row.duration.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      )
    },
    {
      name: <span title='Mechanic' id='header_mechanic'>Mechanic</span>,
      selector: row => row.mechanic,
      cell: (row) => (
        <div id='mechanic'>
          {
            row.mechanic && row.mechanic.length > 0
              ? <div>
                {row.mechanic.slice(0, row.mechanic.length > 2 ? 2 : row.mechanic.length).join(', ')}
                {row.mechanic.length > 2 ? '...' : ''}
              </div>
              : null
          }
        </div>
      )
    },
    {
      name: <span title='Mechanic' id='header_feature'>Feature</span>,
      selector: row => row.feature,
      cell: (row) => (
        <div id='feature'>
          {
            row.feature && row.feature.length > 0
              ? <div>
                {row.feature.slice(0, row.feature.length > 2 ? 2 : row.feature.length).join(', ')}
                {row.feature.length > 2 ? '...' : ''}
              </div>
              : null
          }
        </div>
      )
    },
    ]
  }

  const ExpandedComponent = ({ data }) => {
    return <div className="flex flex-col gap-2 p-2">
      <div className="flex gap-2">
        <b>Mechanics</b>
        <span>{data.mechanic.join(', ')}</span>
      </div>
      <div className="flex gap-2">
        <b>Features</b>
        <span>{data.feature.join(', ')}</span>
      </div>
    </div>
  };

  return (
    <div style={{ display: "inline" }}>
      {roiDataError &&  <Toaster showAlert={{
                type: 'error',
                message: "Some thing went wrong, please reload page"
            }} />}   
      {!isRoiData && selectedContry &&
        <DataTable
          columns={columns}
          data={tableData}
          expandableRows={selectedContry === 'UK'} expandableRowsComponent={selectedContry === 'UK' ? ExpandedComponent : null}
          sortIcon={<ArrowDownward />}
        />
        }
      <Dialog
        open={isRoiData}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="pt-0">
          {"Your request is being processed"}
        </DialogTitle>
      </Dialog>
    </div>
  )
}