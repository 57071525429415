import { useEffect, useState } from "react"

type ScopeFilterProps = {
    scopeData: string[],
    onMouseLeave?: React.MouseEventHandler,
    onSelected: (selected: String[]) => void,
    previouslySelected: string[]
}
export const ScopeFilter = ({scopeData, onMouseLeave, onSelected, previouslySelected}: ScopeFilterProps) => {
    const [selected, setSelected] = useState<string[]>([])
    useEffect(() => {
        setSelected(previouslySelected)
    }, [previouslySelected])
    
    return  <div className="relative flex justify-center p-2">
        <div className="absolute bg-white top-6 left-3 flex flex-col p-5 border shadow border-primary rounded z-50 filter-customer-channel-div" onMouseLeave={onMouseLeave}>
            { scopeData.map((customer, idx)=> {
                return <div key={idx} className="hover:bg-blue-200 p-1 flex justify-start items-center gap-1" onClick={() => {
                    let allSelected: string[]
                    if (selected.includes(customer)){
                        allSelected = selected.filter(x=>x !== customer)
                    } else {
                        allSelected = [...selected, customer]
                    }
                    setSelected(allSelected)
                    onSelected(allSelected)
                  }}>
                        <input type="checkbox" checked={selected.includes(customer)} />
                        <span className="text-sm"> {customer} </span>
                    </div>
                })
            }
        </div>
    </div>
}