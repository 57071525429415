import create from "zustand";

type FlagStore={
    onViewMore: boolean,
    setOnViewMore:(value:boolean)=>void,
}

export const useFlagStore = create<FlagStore>((set)=>({
    onViewMore:false,
    setOnViewMore: (value: boolean) => set({onViewMore : value})
}))