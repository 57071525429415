import React, { useEffect, useState } from "react";
import { Button, Box, makeStyles, CircularProgress } from '@material-ui/core';

import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import useStore from "../../../../store";
import { scrollTo } from "../utils/scrollTo";
import { AlertDialog } from "./AlertDialog";
import { useGeneratePnL, useSimulationParameters } from "../../../../services/mutations";

export const CalculateButton = ({ promoData }: any) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openCalculateDialog, setOpenCalculateDialog] = useState(false);
  const [isCalculateClicked, setIsCalculateClicked] = useState(false);

  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const { mutate: getPnLData, data: pnLData, isSuccess: pnLDataSuccess, isError: successError, isLoading: isPnLDataLoading } = useGeneratePnL();
  let displayPromoCalculations = promoSimulatorTabs[0].displayPromo;

  const generatePnlGrid = () => {
    hasNegativeValue(displayPromoCalculations) ? setOpenCalculateDialog(true) : handleYes();
  }

  const hasNegativeValue = (displayPromoCalculations: any) => {
    for (const item of displayPromoCalculations) {
      for (const valueObject of item.value) {
        for (const key in valueObject) {
          const value = Number(valueObject[key]) ? Number(valueObject[key]) : "";
          if (typeof value === 'number' && value < 0) {
            return true
          }
        }
      }
    }
  }

  useEffect(() => {
    let data = pnLData?.data.data;
    if (data) {
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any, i: any) => i === 0 ? { ...tab, PnLResposne: data, pnlTotalData: pnLData?.data, pnLGridGenerated: true } : tab))
    }
  }, [pnLData]);

  useEffect(() => {
    if (isCalculateClicked && promoSimulatorTabs[0].pnLGridGenerated && promoSimulatorTabs[0].PnLResposne.length > 0) {
      setTimeout(() => {
        if (document.getElementById('pnl-grids')) {
          scrollTo('pnl-grids');
          setIsCalculateClicked(false);
        }
      }, 1000)
    }
  }, [isCalculateClicked, promoSimulatorTabs]);

  const handleEditClick = () => {
    setOpenDialog(true);
  }

  const handleYes = () => {
    setIsCalculateClicked(true);
    setOpenCalculateDialog(false);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any, i: any) => i === 0 ? { ...tab, pnLGridGenerated: false } : tab))
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any, i: any) => i === 0 ? { ...tab, pnLGridGenerated: true, productDetailsCalculateButtonClicked: true } : tab))
    let displayPromoCalculations = promoSimulatorTabs[0].displayPromo ? promoSimulatorTabs[0].displayPromo : [];
    let formatedArray = [{ key: '', value: {} }];
    let pnlInputArray = [{ key: '', value: {} }];
    displayPromoCalculations.map((item: any) => {
      item.value.map((element: any) => {
        let selectedIndex = formatedArray.findIndex((fItem: any) => {
          return fItem.key === Object.keys(element)[0]
        })
        if (selectedIndex <= 0) {
          let tempObj = {
            "additionalFunding": 0,
            "baseGSVListPrice": 0,
            "baseSellInInvoicePrice": 0,
            "baseSellOutPrice": 0,
            "baseVolume": 0,
            "brand": "",
            "category": "",
            "discount": 0,
            "discountPercent": 0,
            "distributionExpense": 0,
            "ean": "",
            "cogs": 0,
            "eanDescription": "",
            "promoSellInInvoicePrice": 0,
            "promoSellOutPrice": 0,
            "promoVolume": 0,
            "sector": "",
            "subBrand": "",
            "subCategory": "",
            "tradingTerms": 0,
            "volumeUplift": 0
          }
          // @ts-expect-error
          tempObj[item.key as keyof typeof tempObj] = element[Object.keys(element)[0]];
          formatedArray.push({ key: Object.keys(element)[0], value: tempObj })
          pnlInputArray.push({ key: Object.keys(element)[0], value: tempObj })
        }
        else {
          // @ts-expect-error
          pnlInputArray[selectedIndex].value[item.key] = element[Object.keys(element)[0]];
          if (item.key === "sellInListPriceOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['baseGSVListPrice'] = element[Object.keys(element)[0]];
          }
          else if (item.key === "baseSellOutPriceOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['baseSellOutPrice'] = element[Object.keys(element)[0]];
          }
          else if (item.key === "baseVolumeOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['baseVolume'] = element[Object.keys(element)[0]];
          }
          else if (item.key === "additionalFundingOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['additionalFunding'] = element[Object.keys(element)[0]];
          }
          else if (item.key === "fixedCogOverride" && element[Object.keys(element)[0]] > 0) {
            // @ts-expect-error
            formatedArray[selectedIndex].value['cogs'] = element[Object.keys(element)[0]];
          }
          else {
            // @ts-expect-error
            formatedArray[selectedIndex].value[item.key] = item.key === "promoSellInInvoicePrice" ? Number(element[Object.keys(element)[0]]) : (element[Object.keys(element)[0]]);
          }
        }
      })
    })
    let finalPayload: any = []
    formatedArray.forEach((item: any) => {
      if (item.key.length > 0) {
        const { additionalFundingOverride, baseSellOutPriceOverride, baseVolumeOverride, sellInListPriceOverride, fixedCogOverride, distributionExpense, ...trimmedValue } = item.value
        finalPayload.push(trimmedValue);
      }
    });
    let payload = {
      "multiBuy": promoSimulatorTabs[0].sellOutPriceType === 'MULTIBUY',
      "redemptionRate": Number(promoSimulatorTabs[0].redemptionRate),
      "inputs": finalPayload
    }
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any, i: any) => i === 0 ? { ...tab, pnlInputArray: pnlInputArray } : tab))
    getPnLData(payload);
    setTimeout(() => {
      scrollTo('pnl-grids');
    }, 100);
  }

  const handleNo = () => {
    setOpenCalculateDialog(false)
  }

  const handleEditYes = () => {
    setOpenDialog(false);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any, i: any) => i === 0 ? { ...tab, pnLGridGenerated: false, PnLResposne: [] } : tab))
  }

  const handleEditNo = () => {
    setOpenDialog(false);
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 5 }}>
      <label className={classes.requiredField}>Fields marked * are mandatory and editable</label>
      {!promoSimulatorTabs[0].pnLGridGenerated && (
        <Button onClick={generatePnlGrid} className={classes.calculateBtn}>
          {isPnLDataLoading && <CircularProgress size="1rem" style={{ marginRight: '10px', color: 'white' }} />}
          Calculate
        </Button>
      )}
      {promoSimulatorTabs[0].pnLGridGenerated && (
        <Button onClick={handleEditClick} className={classes.calculateBtn}>
          Edit
        </Button>
      )}
      <AlertDialog openDialog={openCalculateDialog} handleYes={handleYes} handleNo={handleNo} message="There are negative values in the product details grid. Do you wish to continue?" />
      <AlertDialog openDialog={openDialog} handleYes={handleEditYes} handleNo={handleEditNo} message="Product details are being changed which could cause reworking on entire simulation. Are you sure to continue?" />
    </Box>
  )
};

const useStyles = makeStyles(() => ({
  actionsIconTextColor: {
    color: '#0000b3',
    textTransform: 'none'
  },
  disabledActionsIconTextColor: {
    color: 'gray',
    textTransform: 'none'
  },
  calculateBtn: {
    float: 'right',
    color: '#fff',
    background: '#1976d2',
    margin: '10px',
    '&:hover': {
      backgroundColor: "#1976d2 !important",
    },
  },
  requiredField: {
    color: '#d32f2f !important',
    fontSFize: '0.75rem'
  }
}))

