import React, { useEffect, useState } from 'react';
import { useCustomerHierarchy, useProductHierarchy } from './services/queries';
import { makeStyles } from "@material-ui/core/styles";
import { productDataJson } from "./ProductJson";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CustomSearch from "./CustomSearch";
import useStore from './store';
import PostRoiGrid from './PostRoi/PostRoiGrid';
import RoiDetails from './PostRoi/roiDetails';
import GroupSelect from './PostRoi/groupSelect';

import NewCal from './calender';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3),
        width: 345,
    },
    appBar: {
        backgroundColor: theme.palette.primary.light,
    },
    media: {
        height: 140,
    },
    title: {
        color: theme.palette.primary.main,
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        height: "100vh",
    },
    paperDiv: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(2),
            width: "100%",
            height: theme.spacing(12),
        },
    },

    chartDiv: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(2),
            width: "100%",
            height: theme.spacing(100),
        },

    },

    defaultTxt: {
        padding: '20px',
        margin: '15px auto',
        fontSize: '1.3rem',
        textAlign: 'center'
    },

    dateLinks: {
        cursor: 'pointer',
        fontWeight: 700,
        marginLeft: '30px',
    },
    dateLinksSpan: {
        marginRight: '40px',
        paddingBottom: '5px',
        color: '#0000b3',
    },
    dateLinksSpanActive: {
        borderBottom: '2px solid'
    },
    datedefaultText: {
        marginRight: '40px',
    },
    dataDiv: {
        // border: '1px solid',
        padding: theme.spacing(1),
        width: 390,
        backgroundColor: theme.palette.background.light,
    },
    paper: {
        backgroundColor: theme.palette.primary.light,
        height: '35rem'
    },
    note: {
        alignSelf: "centre",
    },

}));

const RoiSimulatorLayout = () => {
    const classes = useStyles();
    const productDataJsonNew = productDataJson.map((data, key) => {
        return data.countries.PE.categories;
    });

    //store
    const setSelectedCountry = useStore((state) => state.setSelectedCountry);
    let selectedCountry = useStore((state) => state.selectedCountry);

    //services
    const { isLoading: isCustomerData, data: customerData } = useCustomerHierarchy(`${selectedCountry}&feature=ROI Simulator`);
    const [customerService, setCustomerService] = useState([]);
    const [customerapiNew, setCustomerapiNew] = useState([]);

    const { isLoading: isProductData, data: productData } = useProductHierarchy(selectedCountry, "ROI Simulator");
    const [productService, setProductService] = useState([]);
    const [apidataLocal, setApidataLocal] = useState([]);
    const [apidata, setApidata] = useState([]);

    const setSelectedBaseLineProductNew = useStore((state) => state.setSelectedBaseLineProductNew);
    const setSelectedBaseLineCustomerNew = useStore((state) => state.setSelectedBaseLineCustomerNew);


    useEffect(() => {
        if (!isProductData && selectedCountry !== undefined) {

            setApidataLocal(productData);
            setApidata(generateData(productData));

        }
    }, [isProductData])

    useEffect(() => {
        if (!isCustomerData) {
            setCustomerapiNew(generateCustomerNew(customerData));

        }
    }, [isCustomerData])

    useEffect(() => {

        setSelectedBaseLineProductNew('');
        setSelectedBaseLineCustomerNew('');
    }, [])

    useEffect(() => {
        setApidata(generateData(apidataLocal));

    }, [selectedCountry])

    const generateCustomerNew = (customeData) => {

        const customers = customeData?.distChannels;
        let mainCustomerObg = []

        let customerLevel = [];
        let subcustomerLevel = [];

        for (const key in customers) {
            let cuslis = [];
            console.log("keys231", key);


            const categories = customers[key].salesOffice;
            console.log("categories111", categories);

            for (const item in categories) {
                let itemList = {
                    label: item,
                    value: item,
                    className: 'hideCheckbox',
                    children: []
                };

                const sublevel = categories[item].planLevel;
                let cuslisSub = [];

                for (const itemval in sublevel) {
                    let itemListVal = {
                        label: itemval,
                        value: itemval,
                        children: []
                    };

                    console.log("itemval", itemval)
                    console.log("sublevel[itemval]", sublevel[itemval])
                    let subplanlisSub = [];
                    for (const subplanitemval in sublevel[itemval]) {
                        let subitemListval = {
                            label: sublevel[itemval][subplanitemval],
                            value: sublevel[itemval][subplanitemval],
                        };

                        subplanlisSub.push(subitemListval);
                    }


                    itemListVal.children = subplanlisSub;
                    cuslisSub.push(itemListVal);
                }

                subcustomerLevel.push({
                    label: 'planLevel',
                    value: 'planLevel',
                    children: cuslisSub,
                })

                itemList.children = cuslisSub;

                cuslis.push(itemList);
            }
            customerLevel.push({
                label: key,
                value: key,
                className: 'hideCheckbox',
                children: cuslis,
            })



            // return mainCustomerObg;
        }

        let newObj = {
            label: "DistChannels",
            value: "distChannels",
            className: 'hideCheckbox',
            children: customerLevel
        }


        mainCustomerObg.push(newObj);

        return mainCustomerObg;

    };


    const generateData = (values) => {
        let mainResultant = [];
        const countries = values.countries;


        for (const keyCon in countries) {
            console.log("line 187", keyCon, selectedCountry);
            if (keyCon === selectedCountry) {
                console.log("line 189", keyCon)
                for (const key in countries) {
                    let resultant = [];


                    const categories = countries[keyCon].categories;
                    for (const catKey in categories) {
                        const category = categories[catKey];
                        let resultCategory = {
                            label: catKey,
                            value: catKey,
                            className: "classes.dataDiv",
                        };




                        const subCategories = category.subCategories;
                        let subcatArr = [];
                        for (const subcatKey in subCategories) {
                            const subCategory = subCategories[subcatKey];
                            let resultSubCategory = {
                                label: subcatKey,
                                value: subcatKey,
                            };

                            const brands = subCategory.brands;
                            let brandsArr = [];
                            for (const brandKey in brands) {
                                const brand = brands[brandKey];
                                let resultBrand = {
                                    label: brandKey,
                                    value: brandKey,
                                };
                                const subBrands = brand.subBrands;
                                let subBrandsArr = [];
                                let subBrandsObj = {
                                    label: "sub Brands",
                                    value: "subBrands"
                                }
                                for (const subBrandKey in subBrands) {
                                    const subBrand = subBrands[subBrandKey];

                                    let resultSubBrand = {
                                        label: subBrandKey,
                                        value: subBrandKey,
                                    };
                                    const finalList = subBrand;
                                    let finalListAry = [];
                                    for (const item of finalList) {
                                        let itemList = {
                                            label: item,
                                            value: item,
                                        };
                                        finalListAry.push(itemList);
                                        resultSubBrand.children = finalListAry;
                                    }
                                    subBrandsArr.push(resultSubBrand);
                                    subBrandsObj.children = subBrandsArr;
                                    resultBrand.children = [subBrandsObj];
                                }

                                brandsArr.push(resultBrand);
                            }

                            let BrandsObj = {
                                label: "Brands",
                                value: "Brands",
                                children: brandsArr
                            }

                            resultSubCategory.children = [BrandsObj];
                            subcatArr.push(resultSubCategory);
                        }

                        let subCategoriesObj = {
                            label: "Sub Categories",
                            value: "SubCategories",
                            children: subcatArr
                        }


                        resultCategory.children = [subCategoriesObj];

                        resultant.push(resultCategory);
                    }

                    let categoriesObj = {
                        label: "Categories",
                        value: "Categories",
                        children: resultant
                    }
                    mainResultant.push(categoriesObj)
                    console.log("resultant", mainResultant)
                    return mainResultant;
                }
            }
        }
        return mainResultant;
    };



    return (
        <Grid item xs={12} sm={12}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <div className={classes.paperDiv}>
                        <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                            Customer
                        </div>
                        {!isCustomerData && customerapiNew &&
                            <CustomSearch data={customerapiNew} dataFor='customer' />
                        }
                        {isCustomerData && (
                            <>
                                <CustomSearch data={customerapiNew} dataFor='customer' /> Loading...!
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={classes.paperDiv}>
                        <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                            Product
                        </div>

                        {!isProductData && apidata &&
                            <>
                                <CustomSearch data={apidata} dataFor='Product' />

                            </>
                        }

                        {isProductData && (
                            <>
                                <CustomSearch data={apidata} dataFor='Product' /> Loading...!
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={classes.paperDiv}>
                    <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                            Select Time Interval
                        </div>
                        <Paper className={classes.paperDiv}>
                            <NewCal />
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={classes.paperDiv}>
                        <div style={{ height: 5 }}></div>
                        <Paper className={classes.paperDiv}></Paper>

                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid item xs={12} sm={12} container>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.paperDiv}>
                            <Paper className={classes.paperDiv} style={{ height: 300 }}>
                                <div>Graph 1</div>
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.paperDiv}>
                            <Paper className={classes.paperDiv} style={{ height: 300 }}>
                                <div>Graph 2</div>
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={classes.paperDiv} >
                            <Paper className={classes.paperDiv} style={{ height: 300 }}>
                                <div><RoiDetails /></div>
                            </Paper>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                    <Grid item xs={12} sm={12} container>
                        <Grid item xs={12} sm={1}>
                            <h3 style={{ justifyContent: "left", display: "flex", marginTop: 25, marginLeft: 10 }}>Group by </h3>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <GroupSelect style={{ justifyContent: "center" }} />
                        </Grid>
                    </Grid>
                    <div className={classes.chartDiv}>
                        <PostRoiGrid />
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default RoiSimulatorLayout;

