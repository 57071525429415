import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toaster from '../commonMuiComponents/Toaster'
import useStore from "../store";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";

import { currencySymbol } from "../helper";

import { usePostRoiQuadrant } from "../services/queries";
import { updateUrl } from "../UpdateUrl";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  cellData: {
    borderLeft: "1px solid #ccc",
    textTransform: "capitalize",
  },
  textGreen: {
    color: "green",
    fontSize: 32,
    fontWeight: 600,
    margin: "5px 5px 25px 0",
  },
  textRed: {
    color: "red",
    fontSize: 32,
    fontWeight: 600,
    margin: "5px 5px 25px 0",
  },
  disFlex: {
    display: "flex",
  },
  disFlexSpaceBet: {
    display: "flex",
    justifyContent: "space-between",
  },
  disFlexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  txtBld: {
    fontWeight: 600,
    fontSize: 18,
    margin: "5px 5px 5px 0",
  },
  txtGreen: {
    color: "green",
  },
  txtRed: {
    color: "red",
  },
  marBtm30: {
    marginBottom: 35,
  },
  manContainer: {
    padding: 15,
  },
  rdt_TableHeader: {
    display: "none",
  },
  btnReset: {
    background: "#cb0c0c",
    color: "#fff",
    textTransform: "none",
    margin: "0 20px",
    padding: "6px 20px",
    borderRadius: "10px",
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
  dialogWidth: {
    minWidth: "500px",
  },
  btnClose: {
    position: "absolute",
    top: "0",
    right: "5px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PostRoi(props) {
  const classes = useStyles();
  const [data, setData] = useState("");
  const [postRoiQuadrantPayload, setPostRoiQuadrantPayload] = useState(null);
  //store value
  let selectedContry = useStore((state) => state.selectedCountry);
  let selectedBaseLineFilters = useStore((state) => state.selectedBaseLineFilters);
  let selectedBaseLineCustomerNew = useStore((state) => state.selectedBaseLineCustomerNew);
  let selectedBaseLineProductNew = useStore((state) => state.selectedBaseLineProductNew);
  let groupBy = useStore((state) => state.groupBy);
  const startMonth = useStore((state) => state.startMonth);
  const endMonth = useStore((state) => state.endMonth);
  let yearSelection = useStore((state) => state.yearSelection);
  let selectedEventIdNew = useStore((state) => state.selectedEventIdNew);
  const selectedFilters = selectedBaseLineCustomerNew.concat(selectedBaseLineProductNew);
  let updatedBaseLineFilter = updateUrl(selectedFilters);
  let selectedSource = useStore((state) => state.selectedSource);
  const selectedCustomersHierarchy = useStore(state => state.selectedCustomersHierarchy);
  const selectedProductsHierarchy = useStore(state => state.selectedProductsHierarchy);
  const selectedEventIdValues = useStore(state => state.selectedEventIdValues);
  const selectedQuadrant = useStore(state => state.selectedQuadrant);
  let sourceUrl = "";
  for (var source of selectedSource) {
    sourceUrl += `&source=${source}`
  }
  //calling api
  const { isLoading: isRoiData, data: RoiData, isError: roiDataError } = usePostRoiQuadrant(postRoiQuadrantPayload);

  useEffect(() => {
    let payload = {
      country: selectedContry,
      customers: selectedCustomersHierarchy?.soldTo,
      planLevels: selectedCustomersHierarchy?.planLevel,
      salesExecutive: selectedCustomersHierarchy?.salesExecutive,
      source: selectedSource,
      fromDate: startMonth,
      toDate: endMonth,
      eans: selectedProductsHierarchy?.products,
      categories: selectedProductsHierarchy?.categories,
      subCategories: selectedProductsHierarchy?.subCategories,
      brands: selectedProductsHierarchy?.brands,
      subBrands: selectedProductsHierarchy?.subBrands,
      groupBy,
      eventIds: selectedEventIdValues,
      quadrants: selectedQuadrant
    };

    setPostRoiQuadrantPayload(payload);
  }, [selectedContry, startMonth, endMonth,
    groupBy, selectedSource, selectedQuadrant,
    selectedCustomersHierarchy?.soldTo,
    selectedCustomersHierarchy?.planLevel,
    selectedCustomersHierarchy?.salesExecutive,
    selectedProductsHierarchy?.products,
    selectedProductsHierarchy?.categories,
    selectedProductsHierarchy?.subCategories,
    selectedProductsHierarchy?.brands,
    selectedProductsHierarchy?.subBrands, ,
    selectedBaseLineCustomerNew, selectedBaseLineProductNew, selectedEventIdValues]);

  useEffect(() => {
    if (!isRoiData) {
      setData(RoiData);
      if (RoiData?.roi == "NaN") {
        handleClickOpen();
      }
    }
  }, [isRoiData, groupBy, selectedBaseLineFilters]);

  let testVale = 1304354500.2455;

  const str = testVale.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  console.log("str", str);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let RoiTitle = "Incremental Profit | Loss/Promo Investement";
  let promitProfitTitle =
    "Actual sold cases * (unit non promoted price - unit COGs) - Promo Investment";
  let baneLineTitle = "Baseline cases * (unit non promoted price - unit COGs)";
  let incrementalPLTitle = "Promo Profit - Baseline Profit"
  let promoInvestTitle = "Money spent in promotions";
  const retailerMarginTitle = "Sell out volume * (Sell out price * (1-VAT) - Sell in price * (1-VAT))";
  const retailerMarginPercentageTitle = "Sell in price * (1-VAT) / Sell out price * (1-VAT) * 100";

  return (
    <div className={classes.manContainer}>
      {roiDataError && <Toaster showAlert={{
        type: 'error',
        message: "Some thing went wrong, please reload page"
      }} />}
      {data && selectedContry && (
        <>
          <p className={classes.txtBld} title={RoiTitle}>
            ROI %
          </p>

          <p className={data.roi > 0 ? `${classes.textGreen}` : `${classes.textRed}`} title={RoiTitle}>
            {data.roi.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </p>

          <div className={`${classes.disFlexSpaceBet} ${classes.marBtm30}`}>
            <div className={classes.disFlexColumn} title={promitProfitTitle}>
              <div className={classes.disFlex}>Promo Profit</div>

              {data.promoProfit > 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtGreen}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.promoProfit.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}

              {data.promoProfit < 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtRed}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.promoProfit.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}
            </div>

            <div className={classes.disFlexColumn} title={baneLineTitle}>
              <div className={classes.disFlex}>Baseline Profit</div>

              {data.baseLineProfit > 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtGreen}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.baseLineProfit.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}

              {data.baseLineProfit < 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtRed}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.baseLineProfit.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}
            </div>

            <div className={classes.disFlexColumn} title={incrementalPLTitle}>
              <div className={classes.disFlex}>Incremental profit/loss</div>
              {data.netProfit > 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtGreen}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.netProfit.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}

              {data.netProfit < 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtRed}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.netProfit.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}
            </div>

          </div>

          <div className={classes.disFlexSpaceBet}>
            <div className={classes.disFlexColumn} title={promoInvestTitle}>
              <div className={classes.disFlex}>Promo Investment </div>
              {data.promoInvestment > 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld} ${classes.txtGreen}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.promoInvestment.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}

              {data.promoInvestment < 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld} ${classes.txtRed}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.promoInvestment.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}
            </div>

            <div className={classes.disFlexColumn} title={retailerMarginTitle}>
              <div className={classes.disFlex}>Retailer Margin</div>
              {data.retailerMargin > 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtGreen}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.retailerMargin.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}

              {data.retailerMargin < 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtRed}`}
                >
                  {currencySymbol(selectedContry)}
                  {data.retailerMargin.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              )}
            </div>

            <div className={classes.disFlexColumn} title={retailerMarginPercentageTitle}>
              <div className={classes.disFlex}>Retailer Margin %</div>
              {data.retailerMarginPercentage > 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtGreen}`}
                >
                  {data.retailerMarginPercentage.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  {`%`}
                </div>
              )}

              {data.retailerMarginPercentage < 0 && (
                <div
                  className={`${classes.disFlex} ${classes.txtBld}  ${classes.txtRed}`}
                >
                  {data.retailerMarginPercentage.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  {`%`}
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="mini-dialog"
      >
        <DialogTitle>
          {"Change Values"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.btnClose}
          >
            X
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {"Selection doesn’t have data, please select different data"}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btnContainer}>
          <Button onClick={handleClose} className={classes.btnSucess}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
