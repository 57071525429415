import DataTable from 'react-data-table-component';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useEffect, useState } from 'react';
import { ApiRequestService } from '../../services/api-service';
import { Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import useStore from '../../store';
import { useOktaAuth } from '@okta/okta-react';


const columns = [
    {
        name: 'Promotion Name',
        selector: (row: any) => row.name,
        sortable: true,
        width: '180px',
        cell: (row: any) => <Link data-tag="allowRowEvents" href="#">{row.name}</Link>,
        allowRowEvents: true
    },
    {
        name: 'From Date',
        sortable: true,
        selector: (row: any) => row.fromDate,
        width: '180px'
    },

    {
        name: 'Thru Date',
        sortable: true,
        selector: (row: any) => row.toDate,
        width: '180px'
    },
    {
        name: 'Target ROI',
        sortable: true,
        selector: (row: any) => row.targetRoi,
        width: '180px'
    },
    {
        name: 'ROI',
        sortable: true,
        selector: (row: any) => row.roi
    },
    {
        name: 'Net Profile/Loss',
        sortable: true,
        selector: (row: any) => row.netProfit,
        width: '180px'
    },
    {
        name: 'Total Investment',
        sortable: true,
        selector: (row: any) => row.totalInvestment,
        width: '180px'
    },
    {
        name: 'Total Volume',
        sortable: true,
        selector: (row: any) => row.totalVolume,
        width: '180px'
    },
    {
        name: 'Baseline',
        sortable: true,
        selector: (row: any) => row.baseline
    },
    {
        name: 'Incremental',
        sortable: true,
        selector: (row: any) => row.incrementalVolume,
        width: '180px'
    },
];

export const Table = ({ rows, isLoading, deleteCallback, addPromo, setOpenModal }: { rows: any, isLoading: boolean, setOpenModal: any, addPromo: any, deleteCallback: (deleted: string[]) => void }) => {
    const [selectedRows, setSelectedRows] = useState<any[]>([])
    const { oktaAuth, authState } = useOktaAuth();
    const [currentUser, setcurrentUser] = useState<any>()

    useEffect(() => {
        const getCurrentUser = async () => {
            if (!authState) return;
            if (!authState.error && authState?.accessToken && authState?.idToken) {
                const userInfo = await oktaAuth.getUser()
                setcurrentUser(userInfo);
            }
        }
        getCurrentUser()
    }, [authState, oktaAuth]);

    const country = useStore(x => x.selectedCountry)

    const handleChange = (selected: { allSelected: boolean, selectedCount: number, selectedRows: any[] }) => {
        setSelectedRows(selected.selectedRows)
    }

    const deleteSim = async () => {
        const names = selectedRows.map(x => x.name) as string[]
        const api = ApiRequestService.createInstance()
        const res: AxiosResponse = await api.deletePromoSimulation(country, names)
        if (res.status === 200) {
            deleteCallback(names)
            setSelectedRows([])
            alert('Simulation deleted successfully! ');
        }
    }

    const handleRowItemClick = (row: any) => {
        addPromo(row);
        setOpenModal(false);
    }

    return (
        <>
            <div className="pb-5 bg-gray-200 flex " style={{ marginTop: "5px", padding: "5px", borderBottom: "1px solid" }}>
                <Typography variant="h6" component="h5" color='primary'>
                    Results
                </Typography>
                {
                    selectedRows.length > 0
                        ? <button className="bg-red-700 text-white px-3 ml-5" onClick={deleteSim}>
                            Delete selected
                        </button>
                        : null
                }
                <Typography  style={{ margin: "5px 0px 0px 20px", color:'gray', fontSize: '1rem', marginLeft: selectedRows.length > 0 ? '20px':'158.496px'}}>
                    [ Disabled Promotions owned by someone else so can not be deleted ]
                </Typography>
            </div>
            <div className="sim-table" style={{ height: '270px', width: 'auto', overflowY: 'auto' }}>
                {isLoading ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </div>
                    :
                    <div>
                        <DataTable
                            columns={columns}
                            data={rows}
                            sortIcon={<ArrowDownwardIcon />}
                            selectableRows
                            onSelectedRowsChange={handleChange}
                            selectableRowDisabled={(row) => !currentUser || row.createdBy !== currentUser.name}
                            pagination
                            onRowClicked={row => handleRowItemClick(row)}
                        />
                    </div>
                }
            </div>
        </>
    )
}

