import { AxiosResponse } from 'axios';
import { useQueries, useQuery } from 'react-query';
import { ApiRequestService, FeatureType } from './api-service';
import { validateDate } from '../util';
import { AvailableScopes } from '../Elasticity/types';

/**
 *
 * @returns Deals with character data api
 * Call this method from anywhere
 * Caching handled by react query
 * TODO: Enforce strong typing
 */
export const useCountriesList = () => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery('getCountries', async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.countriesList();
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};

//get customer data
export const useCustomerHierarchy = (data: string) => {
  let enabled = true;
  const countryCode = (data) ? data.split('&')[0] : '';
  if (countryCode === '') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['baseLineData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.customerHierarchy(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
};

//get productList
export const useProductHierarchy = (data: string, feature: FeatureType = null) => {
  let enabled = true;
  const countryCode = (data) ? data.split('&')[0] : '';
  if (countryCode === '') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['productHierarchy', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.productHierarchy(data, feature);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
};


//get baseline data
export const useBaseLineData = (data: any) => {

  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useBaseLineData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.baseLineData(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};


//get token for BI
export const useTokenBI = (reportId: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery('useTokenBI', async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.biToken(reportId);
    return res.data;
  }, { refetchOnWindowFocus: false, refetchInterval: 3300000, cacheTime: 0 });
};

//get elasticity data
export const useKcElasticityData = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useKcElasticityData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.baseLineData(data);
    return res.data;
  }, { refetchOnWindowFocus: false });
};

//GET KC PRODUCT LIST
export const useKcProductHierarchy = (data: string, hierarchyLevel?: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['kcproductHierarchy', data, hierarchyLevel], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.kcproductHierarchy(data, hierarchyLevel);
    return res.data;
  }, { refetchOnWindowFocus: false });
};

//GET TARGET PRODUCT LIST
export const useTargetProductHierarchy = (data: any) => {
  let enabled = true
  if (!data) {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['targetProductHierarchy', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.targetProductHierarchy(data);
    return res.data;
  }, { refetchOnWindowFocus: false, enabled });
};

//GET CORRELATION ANALYSIS
export const useCorrelationAnalysis = (data: any) => {
  let enabled = true
  if (!data || Object.keys(data).length === 0 || data.country === '' || data.scope === '' || data.hierarchyLevel === '' || data.initialNodeValues.length === 0 || data.targetNodeValues.length === 0) {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['correlationAnalysis', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.correlationAnalysis(data);
    return res.data;
  }, { refetchOnWindowFocus: false, enabled });
};

//GET OWN PRODUCT LIST
export const useOwnProductHierarchy = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['ownProductHierarchy', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.ownProductHierarchy(data);
    return res.data;
  }, { refetchOnWindowFocus: false });
};

//GET OWN ANALYSIS
export const useOwnAnalysis = (data: any) => {
  let enabled = true
  if (!data || Object.keys(data).length === 0 || (Object.keys(data).length !== 0 ? data.initialNodeValues.length === 0 : false) || data.country === '' || data.scope !== 'WC') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['ownAnalysis', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.ownAnalysis(data);
    return res.data;
  }, { refetchOnWindowFocus: false, enabled });
};

export const useAvailableSource = (data: string) => {
  let enabled = true
  if (!data) {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['ownAvailableSource', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.ownAvailableSource(data);
    return res.data;
  }, { refetchOnWindowFocus: false, enabled });
}

//GET CROSS ANALYSIS
export const useCrossAnalysis = (data: any) => {
  let enabled = true;
  if (!data || Object.keys(data).length === 0 || (Object.keys(data).length !== 0 ? (data.initialNodeValues !== undefined ? (data.initialNodeValues.length === 0 ? true : false) : true) || (data.targetNodeValues.length !== undefined ? (data.targetNodeValues.length === 0 ? true : false) : true) : true) || data.country === '' || data.scope !== 'WC') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['crossElasticityAnalysis', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.crossElasticityAnalysis(data);
    return res.data;
  }, { refetchOnWindowFocus: false, enabled });
};

//GET PRODUCTS BASED ON CHANNEL CUSTOMER ALL
export const useOwnScopeSelector = (data: any) => {
  let enabled = true;
  if (!data || Object.keys(data).length === 0 || (Object.keys(data).length !== 0 ? data.initialNodeValues.length === 0 : false) || data.country === '' || data.scope !== 'CUSTOMER') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['ownScopeSelector', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.ownScopeSelector(data);
    return res.data;
  }, { refetchOnWindowFocus: false, enabled });
};

export const useCrossScopeSelector = (data: any) => {
  let enabled = true;
  if (!data || Object.keys(data).length === 0 || (Object.keys(data).length !== 0 ? (data.initialNodeValues !== undefined ? (data.initialNodeValues.length === 0 ? true : false) : true) || (data.targetNodeValues.length !== undefined ? (data.targetNodeValues.length === 0 ? true : false) : true) : true) || data.country === '' || data.scope !== 'CUSTOMER') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['ownCrossSelector', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.crossScopeSelector(data);
    return res.data;
  }, { refetchOnWindowFocus: false, enabled });
};

//get roi data
export const usePostROIData = (data: any) => {
  let enabled = true;
  if (!data) {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['usePostROIData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.postRoi(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
};
//get Promotion Performances data
export const usePromotionPerformances = (data: string) => {

  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['usePromotionPerformances', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.promotionPerformances(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};
//get useProductSummary
export const useProductSummary = (data: string) => {

  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useProductSummary', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.productSummary(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};
//get useCustomerSummary
export const useCustomerSummary = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useCustomerSummary', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.customerSummary(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};


//postRoiQuadrant data
export const usePostRoiQuadrant = (data: any) => {
  let enabled = true;
  if (!data) {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['usePostRoiQuadrant', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.postRoiQuadrant(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
};


//Roifile download uadrant data
export const useRoiFileDownload = (data: any) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useRoiFileDownload', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.roiFileDownload(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};

//get event id data
export const useEventIDData = (data: any) => {
  let enabled = true
  if (!data) {
    enabled = false;
  }

  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useEventIDData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getEventIds(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
};

//postRoiQuadrant data
export const useRoiQuadrant = (data: any) => {
  let enabled = true
  if (!data) {
    enabled = false;
  }

  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useRoiQuadrant', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.roiQuadrant(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
};


//get names list 
export const useGetImpactorNamesList = () => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery('usegetNamesList', async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getImpactorNamesList();
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};


//get Delete Impactor History 
export const useGetDeleteImpactorHistory = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useGetDeleteImpactorHistory', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getDeleteImpactorHistory(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};


//get Impactor History 
export const useGetImpactorHistory = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useGetImpactorHistory', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getImpactorHistory(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};


//GET ImpactorInfo
export const useImpactorInfo = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useImpactorInfo', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getImpactorInfo(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};


//Impactor Delete
export const useImpactorDelete = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useImpactorDelete', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getImpactorDelete(data);
    return res.data;
  }, { refetchOnWindowFocus: false });
};

export enum TokenType {
  AccessToken,
  IdToken
}

export const validateToken = async (type: TokenType, token: string) => {
  try {
    const apiRequestService = ApiRequestService.createInstance()
    let res: AxiosResponse
    if (type === TokenType.AccessToken) {
      res = await apiRequestService.validateAccessToken(token);
    } else {
      res = await apiRequestService.validateIdToken(token);
    }
    console.log(res)
    return res.data;
  } catch (error) {
    console.log(error)
  }

}

export const authenticateWithServer = async (accessToken: string, idToken: string) => {
  try {
    const apiRequestService = ApiRequestService.createInstance()
    const res = await apiRequestService.autenticateWithAPI({
      accessToken,
      idToken
    });
    console.log(res)
    if (res.status === 200) {
      return res.data;
    } else {
      return {
        error: 'Unauthorized access'
      }
    }

  } catch (error) {
    console.log(error)
    return {
      error: 'Login error'
    }
  }

}


export const getAPITestResults = async (type: number) => {
  try {
    const apiRequestService = ApiRequestService.createInstance()
    let res
    if (type === 1) {
      res = await apiRequestService.testAPI()
    } else {
      res = await apiRequestService.testDataAPI()
    }
    console.log(res)
    if (res.status === 200) {
      return res.data;
    } else {
      console.log(res)
      return null
    }

  } catch (error) {
    console.log(error)
    return null
  }

}

//get customer data
export const usePrePOIPromoTypes = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['usePrePOIPromo', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getPrePOIPromoTypes(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};


export const useSellInSellOutCheck = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useSellInSellOutCheck', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.hasSellInSelloutData(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
}

//check if daily data is available or not
export const useDailyDataCheck = (data: string) => {
  let enabled = true;
  if (!data || data === '') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useDailyDataCheck', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.dailyAvailable(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
}


//check if scope level data is available or not
export const useScopeLevelData = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useScopeLevelData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.marketScopeToggles(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
}
export const useGetPromotionSelectors = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useGetPromotionSelectors', data], async () => {
    // done to avoid data.data nesting in container
    //const res: AxiosResponse = await apiRequestService.getPromotionSelectors(country);
    const res: AxiosResponse = await apiRequestService.getLinkedPromotion(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};
export const useGetCustomersSelectors = (country: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useGetCustomersSelectors', country], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getCustomersSelectors(country);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};
export const usePreRoiGetCustomersSelectors = (country: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['usePreRoiGetCustomersSelectors', country], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getPreRoiCustomersSelectors(country);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};

export const useGetProductsSelectors = (country: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useGetProductsSelectors', country], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getProductsSelectors(country);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};
export const useGetPreRoiProducts = (country: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useGetPreRoiProducts', country], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getPreRoiProducts(country);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};


export const useLastModelRunDate = (country: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useLastModelRunDate', country], async () => {
    const res: AxiosResponse = await apiRequestService.getLastModelRunDate(country);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
}

//fetch Sub Category level data 
export const useSubCategoryLevelData = (data: string) => {
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useSubCategoryLevelData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getSubCategories(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
}

//Fetch Source and Scope information in Price Elasticity tab
export const useCountryMasterData = (data: string) => {
  let enabled = true;
  const countryCode = (data) ? data.split('country=')[1] : '';
  if (countryCode === '') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useCountryMasterData', data], async () => {
    const res: AxiosResponse = await apiRequestService.getCountryMasterData(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled })

}

//fetch regression data (slope, intercept, rsquare)
export const useRegressionData = (data: object) => {
  const apiRequestService = ApiRequestService.createInstance();

  return useQuery(['useRegressionData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getRegressionValues(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0 });
};

//fetch Line Fitting data (slope, intercept, rsquare)
export const useLineFittingData = (data: any, tabName: string) => {
  let enabled = true;
  if (!data || (Object.keys(data).length !== 0 ? (data.initial !== undefined ? (data.initial.length === 0 ? true : false) : true) || ((tabName === "cross" ? data.target !== undefined ? (data.target.length === 0 ? true : false) : true : false)) : true) || data.country === '') {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useLineFittingData', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getLineFittingWeeks(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
}

// fetch available market scopes for selected filters
export const useAvailableScopes = (data: string, useNewScopeBy: boolean = false) => {
  let enabled = true;
  if (!useNewScopeBy || !data.includes("category")) {
    enabled = false
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery<AvailableScopes>(['useAvailableScopes', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getAvailableScopes(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
}

export const useProductForElasticity = (data: any) => {
  const enabled = data ? Object.keys(data).length > 0 : false
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useProductForElasticity', data], async () => {
    const res: AxiosResponse = await apiRequestService.getProductForElasticity(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
};
export const useWeekSelectors = (country: any, tabName: string) => {
  let enabled = true;
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useWeekSelectors', country], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getWeekSelectors(country);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
}
export const useInputMethods = (country: any) => {
  let enabled = true;
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useInputMethods', country], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getInputMethods(country);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
}

export const useGetSimulatedPromotions = (data: any) => {
  let enabled = Object.keys(data).length >=0  ? true : false;
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useGetSimulatedPromotions', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getSimulatedPromotions(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
}

export const useGetSimulatedPromoDetails = (data: string) => {
  let enabled = true;
  if(!data) {
    enabled = false;
  }
  const apiRequestService = ApiRequestService.createInstance();
  return useQuery(['useGetSimulatedPromoDetails', data], async () => {
    // done to avoid data.data nesting in container
    const res: AxiosResponse = await apiRequestService.getSimulatedPromoDetails(data);
    return res.data;
  }, { refetchOnWindowFocus: false, cacheTime: 0, enabled });
}