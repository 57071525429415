import React, { useEffect, useState, useStore } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  linkWindow: {
    display: 'flex',
    backgroundColor: 'white',
    marginLeft: '10px',
    justifyContent: 'center',
    borderRadius: '10px',
  },
  dateLinks: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    display: 'flex',
  },
  dateLinksSpan: {
    marginRight: '10px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  dateLinksSpanActive: {
    cursor: 'pointer',
    color: 'black',
  },
}));

export default function DateSelection(props) {
  const classes = useStyles();
  let lastYear = new Date().getFullYear() - 1;
  let currentYear = new Date().getFullYear();

  const [lastYears, setlastYear] = useState([lastYear]);
  const [currentYears, setcurrentYear] = useState([currentYear]);
  const [bothYears, setbothYears] = useState([lastYear, currentYear]);
  const [year, setYear] = React.useState(currentYears);

  const handleChangeYear = (value) => {
    props.selectedYear(value);
    setYear(value);
  };

  return (
    <div className={classes.linkWindow}>
      <p className={classes.dateLinks}>
        <span
          value={lastYears}
          onClick={() => handleChangeYear(lastYears)}
          className={`${classes.dateLinksSpan} ${
            year === lastYears ? classes.dateLinksSpanActive : ''
          } `}
        >
          Previous Year
        </span>
        <span>|</span>
        <span
          value={currentYears}
          onClick={() => handleChangeYear(currentYears)}
          className={`${classes.dateLinksSpan} ${
            year === currentYears ? classes.dateLinksSpanActive : ''
          } `}
        >
          Current Year
        </span>
        <span>|</span>
        <span
          value={bothYears}
          onClick={() => handleChangeYear(bothYears)}
          className={`${classes.dateLinksSpan} ${
            year === bothYears ? classes.dateLinksSpanActive : ''
          } `}
        >
          Both
        </span>
      </p>
    </div>
  );
}
