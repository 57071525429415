import { makeStyles } from "@material-ui/core/styles";
import CachedIcon from '@mui/icons-material/Cached';
import DifferenceIcon from '@mui/icons-material/Difference';
import SaveIcon from '@mui/icons-material/Save';
import ReplayIcon from '@mui/icons-material/Replay';
import BoltIcon from '@mui/icons-material/Bolt';
import { Button } from "@mui/material";


type ActionProps = {
    calculateData: Function,
    setCalcutateTotalInvest: Function,
    resetForm: Function,
    savePromo: Function,
    handleModalOpen: Function,
    duplicateFun: Function,
    tidy?: boolean

}

const useStyles = makeStyles((theme) => ({
    actionsTxt: {
        fontWeight: 700,
        alignSelf: 'center'
    },
    actionsBtn: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1) !important'
          },
    },
    actionsIconText: {
        color: '#2663ff',
        lineHeight: '16px',
    },
    flexEnd: {
        alignItems: 'end'
    },
    flexCenter: {
        alignItems: 'end'
    },
}))

export const PromoActions = ({ calculateData, duplicateFun, setCalcutateTotalInvest, handleModalOpen, resetForm, savePromo, tidy = false }: ActionProps) => {
    const classes = useStyles();

    return <div className="flex justify-start gap-10 w-full px-3 mt-2">
        <div className="flex flex-col gap-2 w-1/2">
            <div className={` ${!tidy ? 'p-5 pt-10' : 'p-1'} flex flex-col gap-10`}>

            </div>
        </div>
        <div className={`flex flex-col gap-1 w-1/2 ${classes.flexEnd}`}>
            <div className={` ${!tidy ? 'p-5 pt-10' : 'p-1'} flex flex-row gap-5`}>
                <span className={classes.actionsTxt}>Actions </span>
                <Button className={`flex flex-row gap-1 ${classes.actionsBtn}`} onClick={() => { calculateData(); setCalcutateTotalInvest(true); }}>
                    <BoltIcon sx={{ color: '#2663ff' }} />
                    <span className={`${tidy ? 'text-sm font-semibold' : 'text-md font-bold'} ${classes.actionsIconText}`}>Calculate</span>
                </Button>
                <Button className={`flex flex-row gap-1 ${classes.actionsBtn}`} >
                    <CachedIcon sx={{ color: '#2663ff' }} />
                    <span className={`${tidy ? 'text-sm font-semibold' : 'text-md font-bold'} ${classes.actionsIconText}`}>Update</span>
                </Button>
                <Button className={`flex flex-row gap-1 ${classes.actionsBtn}`} onClick={() => resetForm()}>
                    <ReplayIcon sx={{ color: '#2663ff' }} />
                    <span className={`${tidy ? 'text-sm font-semibold' : 'text-md font-bold'} first-letter: ${classes.actionsIconText}`}>Reset</span>
                </Button>
                <Button className={`flex flex-row gap-1 ${classes.actionsBtn}`} onClick={() => { savePromo() }}>
                    <SaveIcon sx={{ color: '#2663ff' }} />
                    <span className={`${tidy ? 'text-sm font-semibold' : 'text-md font-bold'} first-letter: ${classes.actionsIconText}`}>Save</span>
                </Button>
                <Button className={`flex flex-row gap-1 ${classes.actionsBtn}`} onClick={() => handleModalOpen()}>
                    <CachedIcon sx={{ color: '#2663ff' }} />
                    <span className={`${tidy ? 'text-sm font-semibold' : 'text-md font-bold'} first-letter: ${classes.actionsIconText}`}>Load</span>
                </Button>
                <Button className={`flex flex-row gap-1 ${classes.actionsBtn}`} onClick={(e) => duplicateFun(e)} >
                    <DifferenceIcon sx={{ color: '#2663ff' }} />
                    <span className={`${tidy ? 'text-sm font-semibold' : 'text-md font-bold'} ${classes.actionsIconText}`}>Duplicate</span>
                </Button>
            </div>
        </div>
    </div>
}