import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import useStore from "../../../store";
import Toaster from '../../../commonMuiComponents/Toaster'
import { MemoizedCrossReport } from "../CrossReport";
import { useCrossAnalysis, useLineFittingData, useCrossScopeSelector } from "../../../services/queries";
import { convertDateRange, getVolumeFieldName, getDropDownTreeData, getViewMoreElasticityAnalysisData, getEditWeekRangeforPowerBI } from "../../utils";
import { MemoizedWeekSelector } from '../../WeekSelector';
import { SingleGrid } from "../../single_grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    boxSizing: "border-box",
  },
  paperDiv: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#FFFFF",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
    },
    paddingTop: "25px",
    height: "auto",
  },
  headerCellLevel1: {
    borderBottom: "none",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
    fontWeight: "bold",
  },
  container: {
    backgroundColor: "#F6F8FC",
    height: "100vh",
  },
  button: {
    display: "inline-flex",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  cellData: {
    borderLeft: "1px solid #ccc",
    textTransform: "capitalize",
  },
  xAddButtonCell: {
    color: "#2662FF",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    width: 100,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  yAddButtonCell: {
    color: "#2662FF",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    width: 100,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  dashBoardTable: {
    marginBottom: "3em",
    background: "inherit",
    width: "75%",
  },
  hide: {
    padding: theme.spacing(1),
    width: 390,
    backgroundColor: theme.palette.background.light,
  },
  gridHeader: {
    textAlign: "center",
    padding: "2.5rem",
  },
  tableHeadCell: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  correlationCell: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    borderTop: "none",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  cellTable: {
    padding: "0",
    verticalAlign: "top",
  },
  high: {
    backgroundColor: "#CBF2DB",
    fontWeight: "bold",
    color: "#2ECC71",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  medium: {
    backgroundColor: "#FDF2C5",
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    color: "#DFB202",
  },
  low: {
    backgroundColor: "#FBCCCC",
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    color: "#F03434",
  },
  viewMore: {
    backgroundColor: "inherit",
    fontWeight: "bold",
    textAlign: "center",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    color: "#2663FF",
  },
  emptyHeader: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    border: "none",
  },
  emptyCell: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    borderBottom: "none",
    borderRight: "none",
  },
  iconButton: {
    backgroundColor: "#2663FF",
    color: "#FFFFFF",
    borderRadius: "50%",
    width: "2.5rem",
    height: "2.5rem",
    padding: "5px",
    flexShrink: "none",
    flex: "0 0 auto",
    transform: "rotate(180deg)",
  },
  buttonLabel: {
    fontWeight: "bold",
    display: "inline-flex",
    color: "#2663FF",
  },
  btnContainer: {
    display: "flex",
    justifyContent: 'flex-end',
    marginRight: '256px',
    marginBottom: "1em",
  },
  btnReset: {
    background: "#cb0c0c",
    color: "#fff",
    textTransform: "none",
    margin: "0 20px",
    padding: "6px 20px",
    borderRadius: "10px",
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
}));

const centerAlignText = {
  textAlign: "center",
};

export const CrossDashboardPerScope = (props) => {
  const classes = useStyles();
  const urlObj = new URL(window.location.href);

  const [crossParameters, setcrossParameters] = useState({});
  const [selectedCountry, setselectedCountry] = useState('');
  const [crossElasticityPayload, setCrossElasticityPayload] = useState({});
  const [initial, setInitial] = useState("");
  const [target, setTarget] = useState("");
  const [open, setOpen] = useState(false);
  const [originalDataInstance, setOriginalDataInstance] = useState([]);
  const [treeDataInstance, setTreeDataInstance] = useState([]);
  const [slopeInt, setSlopeInt] = useState(0);
  const [slopeFrac, setSlopeFrac] = useState(0);
  const [interceptInt, setInterceptInt] = useState(0);
  const [interceptFrac, setInterceptFrac] = useState(0);
  const [rSquared, setRsquared] = useState(0);
  const [elasticityInt, setElasticityInt] = useState(0);
  const [elasticityFrac, setElasticityFrac] = useState(0);
  const [combinedYearWeeks, setCombinedYearWeeks] = useState([]);
  const [productLevel, setProductLevel] = useState("");
  const [category, setCategory] = useState("");
  const [customer, setCustomer] = useState("");
  const [selectedWeekRange, setSelectedWeekRange] = useState(JSON.parse(urlObj.searchParams.get('weekRange')) || []);
  const [userSelectedWeekRange, setUserSelectedWeekRange] = useState([]);
  const [selectedWeeksDashboard, setSelectedWeeksDashboard] = useState([]);
  const [crossObj, setCrossObj] = useState([]);
  const [isPopUpDataChanges, setIsPopUpDataChanges] = useState(false);
  const [lineFittingDataPayload, setLineFittingDataPayload] = useState({});

  const setRegressionApiBodyCross = useStore((state) => state.setRegressionApiBodyCross);
  const setSelectedWeeksApply = useStore((state) => state.setSelectedWeeksApply);
  const [crossDashboardScopeData, setCrossDashboardScopeData] = useState([]);
  const [crossDashboardData, setCrossDashboardData] = useState([]);
  const [canShowProgress, setcanShowProgress] = useState(false);

  const { isLoading: isCrossDashboardDataLoading, data: crossDashboardDataResponse, isError: crossDashboardDataError } = useCrossAnalysis(crossElasticityPayload);
  const { isLoading: isCrossDashboardScopeDataLoading, data: crossDashboardScopeDataResponse, isError: crossDashboardScopeDataError } = useCrossScopeSelector(crossElasticityPayload);
  const { isLoading: loadingLineFittingData, data: lineFittingData, isError: isLineFittingError } =
    useLineFittingData(lineFittingDataPayload, "cross");

  const columns = [
    {
      Header: "Initial Product",
      accessor: "initialProduct",
    },
    {
      Header: "Target Product",
      accessor: "targetProduct",
    },
    {
      Header: getVolumeFieldName(selectedCountry, true),
      accessor: "averageVolume",
    },
    {
      Header: selectedCountry === 'KR' ? 'Price SU Initial' : "Index Unit Price",
      accessor: "averagePrice",
    },
    {
      Header: "Range",
      accessor: "range",
    },
    {
      Header: "R-Squared",
      accessor: "rsquared",
    },
    {
      Header: "P Value",
      accessor: "pValue",
    },
  ];

  useEffect(() => {
    if (crossDashboardDataResponse?.data) {
      setCrossDashboardData(crossDashboardDataResponse.data)
    }
  }, [crossDashboardDataResponse]);

  useEffect(() => {
    if (crossDashboardScopeDataResponse?.data) {
      setCrossDashboardScopeData(crossDashboardScopeDataResponse.data);
    }
  }, [crossDashboardScopeDataResponse])

  useEffect(() => {
    const urlObj = new URL(window.location.href)
    const id = urlObj.searchParams.get('id');
    // Get data from sessionStorage
    const sessionStorageData = sessionStorage.getItem(id);
    if (sessionStorageData === null || sessionStorageData === undefined) {
      if (localStorage.getItem(id) !== null) {
        // Get data from localStorage
        sessionStorage.setItem(id, localStorage.getItem(id));
        setTimeout(() => { localStorage.removeItem(id) }, 1000 * 60 * 30);
      } else {
        window.open(`/price-elasticity`);
      }
    }
    let result = JSON.parse(sessionStorage.getItem(id));
    if (result.productLevel === 'EAN') {
      setInitial(result.initial[0].split('-')[0].trim())
      setTarget(result.target[0].split('-')[0].trim())
    } else {
      setInitial(result.initial);
      setTarget(result.target);
    }
    setCategory(result.category)
    setCustomer(result.customer)
    setProductLevel(result.productLevel);
    setselectedCountry(result.country);
    setcrossParameters(result);

    setSelectedWeekRange(result.weekRange);
    setSelectedWeeksApply([]);

    if (Object.keys(selectedWeekRange).length > 0) {
      const weekRangeSelected = [];
      for (let key in selectedWeekRange) {
        weekRangeSelected.push({ 'year': key, 'weeks': selectedWeekRange[key] });
      }
      setUserSelectedWeekRange(weekRangeSelected);
    };
  }, []);

  useEffect(() => {
    if (!crossParameters || crossParameters === undefined || Object.keys(crossParameters).length <= 0) return;
    let payload = {
      country: selectedCountry,
      customer: crossParameters.customer,
      hierarchyLevel: productLevel,
      initial: (productLevel === 'EAN') ? [crossParameters.initial[0].split('-')[0].trim()] : crossParameters.initial,
      target: (productLevel === 'EAN') ? [crossParameters.target[0].split('-')[0].trim()] : crossParameters.target
    }
    setLineFittingDataPayload(payload);
  }, [crossParameters, productLevel])

  useEffect(() => {
    if (lineFittingData !== undefined) {
      let weekRangeSelected = [];
      let selectedWeeksCount = 0;
      if (Object.keys(selectedWeekRange).length > 0 && selectedWeekRange[0] !== undefined && selectedWeekRange[0] !== null) {
        for (let [key, value] of Object.entries(selectedWeekRange[0])) {
          weekRangeSelected.push({ 'year': key, 'weeks': value });
          selectedWeeksCount += value.length;
        }
        setUserSelectedWeekRange(weekRangeSelected);
      };
      // timePeriods = [], when all week ranges are selected in the edit week popup
      if (selectedWeeksCount === lineFittingData.length) {
        weekRangeSelected = []
      }

      if (Object.keys(crossParameters).length > 0) {
        const crossElascticityPayload = {
          category: category,
          country: selectedCountry,
          forOwn: false,
          hierarchyLevel: crossParameters.productLevel,
          initialBrands: crossParameters.brandInitial !== 'null' && crossParameters.brandInitial !== null && crossParameters.brandInitial !== undefined ? [crossParameters.brandInitial] : null,
          initialManufacturers: crossParameters.manufacturerInitial !== 'null' && crossParameters.manufacturerInitial !== null && crossParameters.manufacturerInitial !== undefined ? [crossParameters.manufacturerInitial] : null,
          initialNodeValues: crossParameters.initial,
          initialPacks: null,
          initialSubBrands: null,
          initialSubCategories: null,
          scope: crossParameters.scope,
          source: crossParameters.source,
          subCategories: crossParameters.subCategory,
          targetBrands: crossParameters.brandTarget !== 'null' && crossParameters.brandTarget !== null && crossParameters.brandTarget !== undefined ? [crossParameters.brandTarget] : null,
          targetManufacturers: crossParameters.manufacturerTarget !== 'null' && crossParameters.manufacturerTarget !== null && crossParameters.manufacturerTarget !== undefined ? [crossParameters.manufacturerTarget] : null,
          targetNodeValues: crossParameters.target,
          targetPacks: null,
          targetSubBrands: null,
          targetSubCategories: null,
          timePeriods: weekRangeSelected.length > 0 ? weekRangeSelected : [],
          topNBottomN: 0,
          customer: crossParameters.customer
        }
        setCrossElasticityPayload(crossElascticityPayload);
      }
    }
  }, [category, initial, target, productLevel, crossParameters, selectedWeekRange, lineFittingData]);

  useEffect(() => {
    setSlopeInt(crossObj.slopeIntegral);
    setSlopeFrac(crossObj.slopeDecimal);
    setInterceptInt(crossObj.interceptIntegral);
    setInterceptFrac(crossObj.interceptDecimal);
    setRsquared(crossObj.rSquared);
    setElasticityInt(crossObj.elasticityIntegral);
    setElasticityFrac(crossObj.elasticityDecimal);
  }, [crossDashboardScopeData]);

  useEffect(() => {
    const timePeriods = [];
    if (selectedWeeksDashboard.length > 0 && selectedWeeksDashboard.length !== lineFittingData.length) {
      let lineDataObj = {};
      for (let i = 0; i < selectedWeeksDashboard.length; i++) {
        let obj = selectedWeeksDashboard[i];
        let key = obj.year.toString();
        if (!lineDataObj[key]) {
          lineDataObj[key] = [obj];
        } else {
          lineDataObj[key].push(obj);
        }
      }

      for (const key in lineDataObj) {
        const weekArray = lineDataObj[key];
        const weeks = [];
        weekArray.forEach((weekData) => {
          if (weekData !== null && weekData !== undefined && weekData.week !== undefined) {
            weeks.push(weekData.week);
          }
        })
        const weekRange = {};
        weekRange["year"] = key;
        weekRange["weeks"] = weeks;
        timePeriods.push(weekRange);
      }
    }

    if (crossParameters !== null && crossParameters !== undefined && Object.keys(crossParameters).length > 0) {
      const crossElascticityPayload = {
        category: category,
        country: selectedCountry,
        forOwn: false,
        hierarchyLevel: crossParameters.productLevel,
        initialBrands: crossParameters.brandInitial !== 'null' && crossParameters.brandInitial !== null && crossParameters.brandInitial !== undefined ? [crossParameters.brandInitial] : [],
        initialManufacturers: crossParameters.manufacturerInitial !== 'null' && crossParameters.manufacturerInitial !== null && crossParameters.manufacturerInitial !== undefined ? [crossParameters.manufacturerInitial] : [],
        initialNodeValues: crossParameters.initial,
        initialPacks: null,
        initialSubBrands: null,
        initialSubCategories: null,
        scope: crossParameters.scope,
        source: crossParameters.source,
        subCategories: crossParameters.subCategory,
        targetBrands: crossParameters.brandTarget !== 'null' && crossParameters.brandTarget !== null && crossParameters.brandTarget !== undefined ? [crossParameters.brandTarget] : [],
        targetManufacturers: crossParameters.manufacturerTarget !== 'null' && crossParameters.manufacturerTarget !== null && crossParameters.manufacturerTarget !== undefined ? [crossParameters.manufacturerTarget] : [],
        targetNodeValues: crossParameters.target,
        targetPacks: null,
        targetSubBrands: null,
        targetSubCategories: null,
        timePeriods: timePeriods.length > 0 ? timePeriods : [],
        topNBottomN: 0,
        customer: crossParameters.customer
      }
      setCrossElasticityPayload(crossElascticityPayload);
    }
  }, [selectedWeeksDashboard]);

  useEffect(() => {
    if (!loadingLineFittingData && lineFittingData !== undefined) {
      let lineDataObj = {};

      for (let i = 0; i < lineFittingData.length; i++) {
        let obj = lineFittingData[i];
        let key = obj.year.toString();
        if (!lineDataObj[key]) {
          lineDataObj[key] = [obj];
        } else {
          lineDataObj[key].push(obj);
        }
      }
      // Drop Down Tree Select Data
      const treeNodesData = getDropDownTreeData(selectedCountry, lineDataObj, selectedWeekRange[0]);

      setOriginalDataInstance(lineDataObj);
      setTreeDataInstance(treeNodesData);

      //On initial loading pushing all data points
      let result = [];
      for (const key in lineDataObj) {
        for (let i = 0; i < lineDataObj[key].length; i++) {
          result.push(lineDataObj[key][i]);
        }
      }
      setRegressionApiBodyCross(result);
    }
  }, [loadingLineFittingData]);

  useEffect(() => {
    if (crossDashboardData !== undefined && crossDashboardData !== null && Object.keys(crossDashboardData).length > 0 && crossDashboardData) {
      const elasticityRecord = getViewMoreElasticityAnalysisData(crossDashboardData, crossParameters, "cross");
      setCrossObj(elasticityRecord)
    }
  }, [crossDashboardData])


  useEffect(() => {
    if (crossDashboardScopeData !== undefined && crossDashboardScopeData !== null && crossDashboardScopeData[customer] !== undefined) {
      setCrossObj(crossDashboardScopeData[customer][0]);
      // Year week to pass to Power BI
      let combinedYearWeeksValues = getEditWeekRangeforPowerBI(crossDashboardScopeData[customer][0].timePeriods);
      setCombinedYearWeeks([...new Set(combinedYearWeeksValues)]);
    }
  }, [crossDashboardScopeData]);

  useEffect(() => {
    if (isCrossDashboardDataLoading || isCrossDashboardScopeDataLoading) {
      setcanShowProgress(true)
    } else {
      setcanShowProgress(false)
    }
  }, [isCrossDashboardDataLoading, isCrossDashboardScopeDataLoading]);

  const handleClose = () => {
    setOpen(false)
  }

  const handleEditPopUpData = (data) => {
    setIsPopUpDataChanges(data);
  }

  const handleClickClose = () => {
    setcanShowProgress(false);
  };

  const crossScopeData = crossDashboardScopeData !== undefined && crossParameters !== undefined ? crossDashboardScopeData[crossParameters.customer] : {};
  const crossData = crossDashboardScopeData !== undefined ? crossScopeData !== undefined && crossScopeData[0] !== undefined && crossScopeData[0] : {};

  return (
    <Grid item xs={12} sm={12}>
      {(crossDashboardDataError || crossDashboardScopeDataError) &&  <Toaster showAlert={{
        type: 'error',
        message: "Some thing went wrong, please reload page"
      }} />}
      {crossData?.viewMore
        ? <Grid className={classes.paperDiv}>
          <div className={classes.chartDiv}>
            <div className={classes.grid} style={{ display: "inline" }}>
              <Table align="center" className={classes.dashBoardTable}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      className={classes.headerCellLevel1}
                      style={centerAlignText}
                    >
                      {`Price Elasticity`} {customer ? ` - ${customer}` : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.headRow}>
                    {columns.map((tableCell) => (
                      <Tooltip
                        title={<Typography fontSize={20}>{tableCell.Header}</Typography>}>
                        <TableCell
                          colSpan={1}
                          className={classes.tableHeadCell}
                          style={centerAlignText}
                        >
                          {tableCell.Header}
                        </TableCell>
                      </Tooltip>
                    ))}
                    {crossData.averagePrice !== undefined ? (
                      <Tooltip
                        title={<Typography fontSize={20}>{`AVG`}</Typography>}>
                        <TableCell
                          colSpan={1}
                          className={classes.tableHeadCell}
                          style={centerAlignText}
                        >
                          {`AVG`}
                        </TableCell>
                      </Tooltip>
                    ) : null}
                    {crossData.promoPrice !== undefined ? (
                      <Tooltip
                        title={<Typography fontSize={20}>{`Promo`}</Typography>}>
                        <TableCell
                          colSpan={1}
                          className={classes.tableHeadCell}
                          style={centerAlignText}
                        >
                          {`Promo`}
                        </TableCell>
                      </Tooltip>
                    ) : null}
                    {crossData.basePrice !== undefined ? (
                      <Tooltip
                        title={<Typography fontSize={20}>{`Base`}</Typography>}>
                        <TableCell
                          colSpan={1}
                          className={classes.tableHeadCell}
                          style={centerAlignText}
                        >
                          {`Base`}
                        </TableCell>
                      </Tooltip>
                    ) : null}

                    {crossData.nweeks !== undefined ? (
                      <Tooltip
                        title={<Typography fontSize={20}>{`Number of weeks`}</Typography>}>
                        <TableCell
                          colSpan={1}
                          className={classes.tableHeadCell}
                          style={centerAlignText}
                        >
                          {`# of weeks`}
                        </TableCell>
                      </Tooltip>
                    ) : null}
                    {crossData.periodBegin !== undefined ? (
                      <Tooltip
                        title={<Typography fontSize={20}>{`Period`}</Typography>}>
                        <TableCell
                          colSpan={1}
                          className={classes.tableHeadCell}
                          style={centerAlignText}
                        >
                          {`Period`}
                        </TableCell>
                      </Tooltip>
                    ) : null}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    {crossData ? (
                      <>
                        <TableCell
                          style={centerAlignText}
                          className={classes.yAddButtonCell}
                        >
                          <div className="kcOvalShape">{crossData.initial}</div>
                        </TableCell>
                        <TableCell
                          style={centerAlignText}
                          className={classes.yAddButtonCell}
                        >
                          <div className="targetOvalShape">{crossData.target}</div>

                        </TableCell>
                        <TableCell
                          style={centerAlignText}
                          className={
                            crossData.range === "LOW"
                              ? classes.low
                              : crossData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {crossData.averageVolume !== undefined ?
                            `${parseFloat(crossData.averageVolume).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}` : null}
                        </TableCell>
                        <TableCell
                          style={centerAlignText}
                          className={
                            crossData.range === "LOW"
                              ? classes.low
                              : crossData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {crossData.priceIndex !== undefined ?
                            selectedCountry === 'KR' ? `${parseFloat(crossData.priceIndex).toLocaleString("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 })}` : `${parseFloat(crossData.priceIndex).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}` : null}
                        </TableCell>
                        <TableCell
                          style={centerAlignText}
                          className={
                            crossData.range === "LOW"
                              ? classes.low
                              : crossData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {crossData.range}
                        </TableCell>

                        <TableCell
                          style={centerAlignText}
                          className={
                            crossData.range === "LOW"
                              ? classes.low
                              : crossData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {crossData.rsquared !== undefined ? parseFloat(crossData.rsquared).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 }) : null}
                        </TableCell>

                        <TableCell
                          style={centerAlignText}
                          className={
                            crossData.range === "LOW"
                              ? classes.low
                              : crossData.range === "HIGH"
                                ? classes.high
                                : classes.medium
                          }
                        >
                          {crossData.pvalue !== undefined ? parseFloat(crossData.pvalue) : "-"}
                        </TableCell>
                        {crossData.averagePrice !== undefined ? (
                          <TableCell
                            style={centerAlignText}
                            className={
                              crossData.range === "LOW"
                                ? classes.low
                                : crossData.range === "HIGH"
                                  ? classes.high
                                  : classes.medium
                            }
                          >
                            {parseFloat(crossData.averagePrice).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}
                          </TableCell>
                        ) : null}
                        {crossData.promoPrice !== undefined ? (
                          <TableCell
                            style={centerAlignText}
                            className={
                              crossData.range === "LOW"
                                ? classes.low
                                : crossData.range === "HIGH"
                                  ? classes.high
                                  : classes.medium
                            }
                          >
                            {parseFloat(crossData.promoPrice).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}
                          </TableCell>
                        ) : null}
                        {crossData.basePrice !== undefined ? (
                          <TableCell
                            style={centerAlignText}
                            className={
                              crossData.range === "LOW"
                                ? classes.low
                                : crossData.range === "HIGH"
                                  ? classes.high
                                  : classes.medium
                            }
                          >
                            {parseFloat(crossData.basePrice).toLocaleString("en-US", { maximumFractionDigits: 3, minimumFractionDigits: 3 })}
                          </TableCell>
                        ) : null}

                        {crossData.nweeks !== undefined ? (
                          <TableCell
                            style={centerAlignText}
                            className={
                              crossData.range === "LOW"
                                ? classes.low
                                : crossData.range === "HIGH"
                                  ? classes.high
                                  : classes.medium
                            }
                          >
                            {crossData.nweeks}
                          </TableCell>
                        ) : null}

                        {crossData.periodBegin !== undefined ? (
                          <TableCell
                            style={centerAlignText}
                            className={
                              crossData.range === "LOW"
                                ? classes.low
                                : crossData.range === "HIGH"
                                  ? classes.high
                                  : classes.medium
                            }
                          >
                            {convertDateRange(crossData.periodBegin)} - {convertDateRange(crossData.periodEnd)}
                          </TableCell>
                        ) : null}
                      </>
                    ) : null}
                  </TableRow>
                </TableBody>
              </Table>
              <div className={classes.btnContainer}>
                <button variant="contained" onClick={() => setOpen(true)} className="border border-primary text-primary rounded p-1 ">Edit Weeks</button>
              </div>
              {open ? <MemoizedWeekSelector
                open={open}
                setOpen={setOpen}
                initial={initial}
                target={target}
                originalDataInstance={originalDataInstance}
                treeDataInstance={treeDataInstance}
                handleClose={handleClose}
                tabName="cross"
                fromDashboard={true}
                setSelectedWeeksDashboard={setSelectedWeeksDashboard}
                selectedWeekRange={selectedWeekRange}
                handleEditPopUpData={handleEditPopUpData}
                isPopUpDataChanges={isPopUpDataChanges}
              /> : null}
              {
                Object.keys(crossData).length > 0 && crossParameters.productLevel !== undefined
                  ? <MemoizedCrossReport
                    {...crossParameters}
                    slopeInt={crossData !== undefined ? crossData.slopeIntegral : 0}
                    slopeFrac={crossData !== undefined ? crossData.slopeDecimal : 0}
                    interceptInt={crossData !== undefined ? crossData.interceptIntegral : 0}
                    interceptFrac={crossData !== undefined ? crossData.interceptDecimal : 0}
                    rSquared={crossData !== undefined ? crossData.rsquared : 0}
                    averagePrice={crossData !== undefined ? crossData.averagePrice : 0}
                    elasticityInt={crossData !== undefined ? crossData.elasticityIntegral : 0}
                    elasticityFrac={crossData !== undefined ? crossData.elasticityDecimal : 0}
                    combinedYearWeeks={combinedYearWeeks}
                  />
                  : null
              }
            </div>
          </div>
        </Grid>
        : <SingleGrid
          loading={isCrossDashboardDataLoading || loadingLineFittingData || isCrossDashboardScopeDataLoading}
          records={[]}
          showTargetProduct={true}
          scope={customer}
          showName={false}
          showOptions={false}
          selectedCountry={selectedCountry}
        />
      }
      <Dialog
        open={canShowProgress}
        keepMounted
        onClose={handleClickClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle className="pt-0">
            {"Your request is being processed"}
        </DialogTitle>
      </Dialog>
    </Grid>
  );
}
