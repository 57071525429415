import * as React from 'react';
import {useOktaAuth} from '@okta/okta-react';
import OktaError from './OktaError'
import {authenticateWithServer} from '../services/queries'
import useStore from '../store'

export declare type OnAuthResumeFunction = () => void;

interface LoginCallbackProps {
  errorComponent?: React.ComponentType<{ error: Error }>;
  onAuthResume?:OnAuthResumeFunction;
  loadingElement?: React.ReactElement;
}


const LoginCallback: React.FC<LoginCallbackProps> = ({ errorComponent, loadingElement = null, onAuthResume }) => { 
    const { oktaAuth, authState } = useOktaAuth();
    const [callbackError, setCallbackError] = React.useState<Error | null>(null);
    const setLoginState = useStore((state) => state.setLoginState);
    const setAuthError = useStore((state) => state.setAuthError);
    const setAPIToken = useStore((state) => state.setAPIToken);
    const setOktaAccessToken = useStore((state) => state.setOktaAccessToken);
    const setOktaIdToken = useStore((state) => state.setOktaIdToken);

    const ErrorReporter = errorComponent || OktaError;

    React.useEffect(() => {
        if (onAuthResume && oktaAuth.idx.isInteractionRequired?.() ) {
            onAuthResume();
            return;
        }
        oktaAuth.handleLoginRedirect().then(async() => {
            oktaAuth.start();
        }).catch(e => {
            console.log('Auth callback error from Okta :: ')
            console.log(e)
            setCallbackError(e);
        });
    },[oktaAuth])
    
    React.useEffect(() => {
        const validateTokenWithApi = async () => {
            if (authState?.accessToken && authState?.idToken){
                setOktaAccessToken(authState.accessToken.accessToken)
                setOktaIdToken(authState.idToken.idToken)
                const result = await authenticateWithServer(authState.accessToken.accessToken, authState.idToken.idToken)
                if (result.error || !result.jwtToken){
                    setLoginState(false)
                    setAuthError(result.error || 'Invalid login')
                }else{
                    setLoginState(true)
                    setAuthError('')
                    setAPIToken(result.jwtToken)
                }
            }
            
        }
        validateTokenWithApi()
    }, [authState]);

    const authError = authState?.error;
    const displayError = callbackError || authError;
    if (displayError) { 
        console.log('Auth error from Okta:: ')
        console.log(authError)
        return <ErrorReporter error={displayError}/>;
    }

    return loadingElement;
};

export default LoginCallback;