import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { makeStyles } from "@material-ui/core/styles";

import '../../styles.css'
import useStore from "../../store";
import { useTokenBI } from '../../services/queries';
import { PowerBITableMapWithProductlevel } from '../utils';
import { useFlagStore } from "../../store/flagStore"

const useStyles = makeStyles((theme) => ({
	spinner: {
		position: 'absolute',
		height: '50px',
		width: '50px',
		top: '50%',
		left:'50%'
	}
}))

export const CrossReport = (props) => {
  const classes = useStyles(); 
  const {slopeInt, slopeFrac, interceptInt, interceptFrac, rSquared, elasticityInt, elasticityFrac, combinedYearWeeks, customer, averagePrice} = {...props};
  
  const [ selectedInitialValue , setselectedInitialValue] = useState([]);
  const [ selecteddTargetValue , setSelecteddTargetValue] = useState([]);
  const [ progress , setProgress] = useState(true)
  const [ bookmark , setBookmark] = useState({});
  const [ selectedInitialCategory , setSelectedInitialCategory] = useState([]);
  const [ selectedInitialSubCategory , setSelectedInitialSubCategory] = useState([]);
  const [ selectedTargetSubCategory , setSelectedTargetSubCategory] = useState([]);
  const [ selectedInitialManufacturer , setSelectedInitialManufacturer] = useState([]);
  const [ selectedTargetManufacturer , setSelectedTargetManufacturer] = useState([]);
  const [ selectedInitialBrand , setSelectedInitialBrand] = useState([]);
  const [ selectedTargetBrand , setSelectedTargetBrand] = useState([]);
  const [filtersValue, setFiltersValue] = useState([]);
  const [biToken, setBiToken] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');

  const selectedCountry = useStore((state) => state.selectedCountry);
  const setOnViewMore = useFlagStore(store => store.setOnViewMore)

  const { isLoading: isTokenData, data: tokenData, isSuccess } = useTokenBI(process.env.REACT_APP_CROSS_ID);

  useEffect(()=>{
    let target = props.target[0].trim();
    let initial = props.initial[0].trim();

    if (props.productLevel === 'EAN'){
      initial = initial.split('-')[0].trim()
      target = target.split('-')[0].trim()
    }
    let newAry = [];

    newAry.push(initial);
    setselectedInitialValue(newAry)
   
    let newAryTarget = [];
    newAryTarget.push(target);
    setSelecteddTargetValue(newAryTarget)

    setSelectedInitialCategory([props.category]);

    setSelectedInitialSubCategory([props.subCategoryInitial]);
    setSelectedTargetSubCategory([props.subCategoryTarget]);

    if(props.manufacturerInitial!=='null') {
      setSelectedInitialManufacturer([props.manufacturerInitial]);
    }

    if(props.manufacturerTarget!=='null') {
      setSelectedTargetManufacturer([props.manufacturerTarget]);
    }

    if(props.brandInitial!=='null'){
      setSelectedInitialBrand([props.brandInitial]);
    }

    if(props.brandTarget!=='null'){
      setSelectedTargetBrand([props.brandTarget]);
    }

  },[props])

  useEffect(() => {
    if (!isTokenData) {
      setBiToken(tokenData?.accessToken);
      setEmbedUrl(tokenData?.url)
      console.log("token", tokenData?.accessToken)
    }
    if(props.productLevel === 'EAN') {
			if(selectedCountry === 'KR') {
				setBookmark({name:process.env.REACT_APP_CROSS_KOREA_BOOKMARK})
			} else {
				setBookmark({name:process.env.REACT_APP_CROSS_OTHER_BOOKMARK})
			}
		} else if(props.productLevel === 'SUB_BRAND') {
			if(selectedCountry === 'KR') {
				setBookmark({name:process.env.REACT_APP_CROSS_SUB_BRAND_KOREA_BOOKMARK})
			} else {
				setBookmark({name:process.env.REACT_APP_CROSS_SUB_BRAND_BOOKMARK})
			}
		}
  }, [isTokenData])

  const basicFilterCountry = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column: "COUNTRY"
    },
    operator: "In",
    filterType: models.FilterType.BasicFilter
  }
 
  const basicFilterCountry_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column: "COUNTRY"
    },
    operator: "In",
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterCustomer = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column: "CUSTOMER"
    },
    operator: "In",
    filterType: models.FilterType.BasicFilter
  }
 
  const basicFilterCustomer_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column: "CUSTOMER"
    },
    operator: "In",
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterKCEans = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column: `${props.productLevel}_INITIAL`
    },
    operator: "In",
    values: selectedInitialValue,
    filterType: models.FilterType.BasicFilter
  }

  
  const basicFilterKCEans_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column: `${props.productLevel}_INITIAL`
    },
    operator: "In",
    values: selectedInitialValue,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterTarget = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`${props.productLevel}_TARGET`
    },
    operator: "In",
    values: selecteddTargetValue,
    filterType: models.FilterType.BasicFilter
  }


  const basicFilterTarget_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`${props.productLevel}_TARGET`
    },
    operator: "In",
    values: selecteddTargetValue,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterCategory = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`CATEGORY`
    },
    operator: "In",
    values: selectedInitialCategory,
    filterType: models.FilterType.BasicFilter
  }
  
  const basicFilterCategory_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`CATEGORY`
    },
    operator: "In",
    values: selectedInitialCategory,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterSubCategoryInitial = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`SUB_CATEGORY_INITIAL`
    },
    operator: "In",
    values: selectedInitialSubCategory,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterSubCategoryInitial_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`SUB_CATEGORY_INITIAL`
    },
    operator: "In",
    values: selectedInitialSubCategory,
    filterType: models.FilterType.BasicFilter
  }
  
  const basicFilterSubCategoryTarget = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`SUB_CATEGORY_TARGET`
    },
    operator: "In",
    values: selectedTargetSubCategory,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterSubCategoryTarget_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`SUB_CATEGORY_TARGET`
    },
    operator: "In",
    values: selectedTargetSubCategory,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterManufacturerInitial = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`MANUFACTURER_INITIAL`
    },
    operator: "In",
    values: selectedInitialManufacturer,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterManufacturerInitial_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`MANUFACTURER_INITIAL`
    },
    operator: "In",
    values: selectedInitialManufacturer,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterManufacturerTarget = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`MANUFACTURER_TARGET`
    },
    operator: "In",
    values: selectedTargetManufacturer,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterManufacturerTarget_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`MANUFACTURER_TARGET`
    },
    operator: "In",
    values: selectedTargetManufacturer,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterBrandInitial = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`BRAND_INITIAL`
    },
    operator: "In",
    values: selectedInitialBrand,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterBrandInitial_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`BRAND_INITIAL`
    },
    operator: "In",
    values: selectedInitialBrand,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterBrandTarget = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][1],
      column:`BRAND_TARGET`
    },
    operator: "In",
    values: selectedTargetBrand,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterBrandTarget_1 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: PowerBITableMapWithProductlevel[props.productLevel][0],
      column:`BRAND_TARGET`
    },
    operator: "In",
    values: selectedTargetBrand,
    filterType: models.FilterType.BasicFilter
  }

  const basicFilterSlopeInt = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Slope Integer",
			column: "Slope Integer"
		},
		values: [slopeInt],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}
	
	const basicFilterSlopeFrac = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Slope Fraction",
			column: "Slope Fraction"
		},
		values: [slopeFrac],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterInterceptInt = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Intercept Integer",
			column: "Intercept"
		},
		values: [interceptInt],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterInterceptFrac = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Intercept Fraction",
			column: "Intercept Fraction"
		},
		values: [interceptFrac],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}
	
  const basicFilterrSquared = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "R Square Table",
			column: "R Square"
		},
		values: [rSquared],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}
	
const basicFilterElasticityInt = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Elasticity Integer",
			column: "Elasticity Integer"
		},
		values: [elasticityInt],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}
	const basicFilterElasticityFrac = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "Elasticity Fraction",
			column: "Elasticity Fraction"
		},
		values: [elasticityFrac],
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

	const basicFilterYearWeek = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: PowerBITableMapWithProductlevel[props.productLevel][0],
			column: "YEAR_WEEK"
		},
		values: combinedYearWeeks,
		operator: "In",
		filterType: models.FilterType.BasicFilter
	}

  useEffect(() => {
    let filter = []
    if (props.country) {
      basicFilterCountry.values =  [props.country]
      basicFilterCountry_1.values =  [props.country]
      filter.push(basicFilterCountry)
      filter.push(basicFilterCountry_1)
    }
    if (customer) {
				basicFilterCustomer.values =  [customer]
				basicFilterCustomer_1.values =  [customer]
				filter.push(basicFilterCustomer) 
				filter.push(basicFilterCustomer_1)
		}

    if(selectedInitialValue?.length > 0){ 
      basicFilterKCEans.values = selectedInitialValue       
      basicFilterKCEans_1.values = selectedInitialValue       
      filter.push( basicFilterKCEans)
      filter.push( basicFilterKCEans_1)
    }

    if(selecteddTargetValue?.length > 0){  
        basicFilterTarget.values = selecteddTargetValue       
        basicFilterTarget_1.values = selecteddTargetValue       
        filter.push(basicFilterTarget)
        filter.push(basicFilterTarget_1)
    }
    if(selectedInitialManufacturer.length > 0 && selectedInitialManufacturer[0]!=='null' ) {
      filter.push(basicFilterManufacturerInitial)
      filter.push(basicFilterManufacturerInitial_1)
    }

    if(selectedTargetManufacturer.length > 0 && selectedTargetManufacturer[0]!=='null') {
      filter.push(basicFilterManufacturerTarget)
      filter.push(basicFilterManufacturerTarget_1)
    }

    if(selectedInitialBrand.length > 0 && selectedInitialBrand[0]!=='null') {
      filter.push(basicFilterBrandInitial)
      filter.push(basicFilterBrandInitial_1)
    }

    if(selectedTargetBrand.length > 0 && selectedTargetBrand[0]!=='null') {
      filter.push(basicFilterBrandTarget)
      filter.push(basicFilterBrandTarget_1)
    }

    if(selectedInitialSubCategory.length > 0) {
      filter.push(basicFilterSubCategoryInitial)
      filter.push(basicFilterSubCategoryInitial_1)
    }

    if(selectedTargetSubCategory.length > 0) {
      filter.push(basicFilterSubCategoryTarget)
      filter.push(basicFilterSubCategoryTarget_1)
    }

    if(selectedInitialCategory.length > 0) {
      filter.push(basicFilterCategory)
      filter.push(basicFilterCategory_1)
    }

    filter.push(basicFilterSlopeInt) 
		filter.push(basicFilterSlopeFrac)
		filter.push(basicFilterInterceptInt) 
		filter.push(basicFilterInterceptFrac) 
		filter.push(basicFilterrSquared)
		filter.push(basicFilterElasticityInt) 
		filter.push(basicFilterElasticityFrac)
    if(combinedYearWeeks.length > 0) {
      filter.push(basicFilterYearWeek)
    }

    setFiltersValue(filter)
    setProgress(true)

  }, [ props, selectedInitialValue, selecteddTargetValue])

  useEffect(() => {
    setOnViewMore(true)
    return () => {
      setOnViewMore(false)
    }
  }, [])

  const getPageName = () => {
		switch(props.productLevel) {
			case 'SUB_BRAND':
				return process.env.REACT_APP_CROSS_SUB_BRAND_PAGENAME;
			default:
				return process.env.REACT_APP_CROSS_PAGENAME;
		}
	}

  console.log("getPageName", getPageName());
  console.log("bookmarks", bookmark);
  console.log("process.env", process.env);

  const generateReport = () =>{
    console.log("filtersValue", filtersValue);
    return ( <header className="App-header">
      {/* {progress && <CircularProgress className={classes.spinner}/>} */}
      <PowerBIEmbed
        embedConfig={{
          pageName: getPageName(),
          type: 'report',   // Supported types: report, dashboard, tile, visual and qna
          id: process.env.REACT_APP_CROSS_ID,
          embedUrl: embedUrl,
          accessToken: biToken, 
          tokenType: models.TokenType.Embed,
          filters: filtersValue,
          bookmark: bookmark,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              },
              pageNavigation: {
                visible: false
              },
            },
          }
        }}
        eventHandlers={
          new Map([
            ['loaded', function () { console.log('Report loaded'); setProgress(false)}],
            ['rendered', function () { console.log('Report rendered'); setProgress(false)}],
            ['error', function (event) { console.log(event.detail); setProgress(false)}]
          ])
        }
        cssClassName={"Embed-container"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
      </header>
    )
  }

  return (
    <div className="App">
      {!isTokenData && biToken && isSuccess 
        ? generateReport()
        : <CircularProgress className={classes.spinner}/>
      }
    </div>
  );
}

export const MemoizedCrossReport = React.memo(CrossReport);