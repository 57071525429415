
import React, { useState } from 'react';
import AppTabs from "./AppTabs"
import Paper from "@material-ui/core/Paper";


export default function PreRoiSimulation(props) {
    const [subTabs] = useState([
        { index: 0, 
          tag: "Create Simulations", 
          value: "CreateSimulations", 
          id:'CreateSimulations' 
        },
        {
          index: 1,
          tag: "View Simulations",
          value: "ViewSimulations",
          id:'ViewSimulations'
        },       
      ])

    return (
        <div style={{ display: "inline" }}>
            <Paper >
              <AppTabs
                tabs={subTabs}
                logo={null}
              >                
              </AppTabs>
            </Paper>
        </div>
    )
}