
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//buromobelexperte
import InfoIcon from '@mui/icons-material/Info';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';
import TripOriginRoundedIcon from '@mui/icons-material/TripOriginRounded';
import LanIcon from '@mui/icons-material/Lan';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    cellData: {
       
        textTransform: 'capitalize'
    },
    cellDataCenter: {
       
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    fieldNames: {
        fontWeight: 700,
        color: '#09309b'
    },
    fieldValue: {
        color: '#393737'
    }
}));




export default function InfoDialog(props) {

    const classes = useStyles();

    const dalVal = props.impactorData

    return (
        <div style={{ displya: "inline" }} data-testid="infoDialog">

{dalVal && (
            <TableContainer component={Paper} >

                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                    <PersonIcon sx={{ color: "#0000b3" }} />  Created By</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                               <span className= {classes.fieldValue}> {dalVal.createdBy}</span>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                    <CalendarMonthIcon sx={{ color: "#0000b3" }} />  Created On (UTC)</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                               <span className= {classes.fieldValue}>  {dalVal.createdOn}</span>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                    <PersonIcon sx={{ color: "#0000b3" }} /> Deleted By</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                               <span className= {classes.fieldValue}>  {dalVal.deletedBy}</span>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                    <CalendarMonthIcon sx={{ color: "#0000b3" }} /> Deleted On (UTC)</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                               <span className= {classes.fieldValue}>  {dalVal.deletedOn}</span>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                    <PublicIcon sx={{ color: "#0000b3" }} />  Country</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                               <span className= {classes.fieldValue}>  {dalVal.country}</span>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                <ShowChartRoundedIcon sx={{ color: "#0000b3" }} />  Planning Customer</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                            {dalVal.planLevels}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                <TripOriginRoundedIcon sx={{ color: "#0000b3" }} />  Sold To</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                            {dalVal.soldTo}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                    <InfoIcon sx={{ color: "#0000b3" }} /> Category</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                            {dalVal.categories}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                   <LanIcon /> Sub Category</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                            {dalVal.subCategories}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                                <span className={classes.fieldNames}>
                                    <QrCodeOutlinedIcon />  EANS</span>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.cellData}>
                            {dalVal.eans}
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>

)}
        </div>
    );
}