import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDailyDataCheck } from "./services/queries";
import useStore from "./store";
import { isUndefined } from "lodash";

const useStyles = makeStyles((theme) => ({
  linkWindow: {
    display: 'flex',
    backgroundColor: 'white',
    marginLeft: '10px',
    justifyContent: 'center',
    borderRadius: '10px',
  },
  dateLinks: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  dateLinksSpan: {
    marginRight: '10px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  dateLinksSpanActive: {
    cursor: 'pointer',
    color: 'black',
  },
}));

export default function MonthlySelection(props) {
  const classes = useStyles();
  const viewMonthly = useStore((state) => state.viewMonthly);
  const [month, setMonth] = React.useState(viewMonthly);
  const [dailyDataAvailable, setDailyDataAvailable] = React.useState(false);

  let selectedCountry = useStore((state) => state.selectedCountry);

  const { isLoading: isLoadingData, data: dailyData } = useDailyDataCheck(selectedCountry);

  useEffect(() => {
    //Disabled daily option for Peru per the requirement
    //TODO: Update the condition when daily data is available
    if (!isLoadingData && !isUndefined(dailyData)) {
      if(selectedCountry !== 'PE' && dailyData.dailyAvailable) {
        setDailyDataAvailable(dailyData.dailyAvailable);
      } else {
        setDailyDataAvailable(false);
      }
    }
  }, [isLoadingData]);
  const handleChangeFrequency = (value) => {
    props.selectedFrequency(value);
    setMonth(value);
  };

  const frequencyData = {
    'Monthly': 'monthly',
    'Weekly': 'weekly',
    'Daily': 'daily'
  }

  return (
    <div className={classes.linkWindow}>
      <div className={classes.dateLinks} style={{ display: 'flex' }}>
      {
        Object.entries(frequencyData).map(([key, value]) => (
          <div key={key} className="frequencyItem" id={`frequency_${ key === 'Daily' && !dailyDataAvailable ? "inactive_":"active_"}${key}`}>
            <span
              value={value}
              onClick={() =>  key === 'Daily' && !dailyDataAvailable ? "" : handleChangeFrequency(value)}
              className={`${classes.dateLinksSpan} ${
                viewMonthly === value ? classes.dateLinksSpanActive : ""
              } `}
              style={{ color: key === 'Daily' && !dailyDataAvailable ? 'GrayText' : "" }}
            >
              {key}
            </span>
            <span>{key !== "Daily" ? "|" : ""}</span>
          </div>
        ))
      }
      </div>
    </div>
  );
}
