import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

type DateSelectorProps = {
  name: string,
  id: string,
  value: any,
  inputValueChange : (e: React.ChangeEvent<HTMLInputElement>, name: string) => void
}

const useStyles: any = makeStyles((theme) => ({
  fieldWidth: {
    width: '100%',
    background: '#fff',
    '& input': {
      fontSize: '0.75rem !important',
    },
    '& div': {
      fontSize: '0.75rem !important',
    }
  }
}));

const DateSelector = ({name, id, value, inputValueChange } : DateSelectorProps) => {
  const classes: any = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        className={classes.fieldWidth}
        value={value}
        onChange={(e : any)=> inputValueChange(e , name)}
        format="yyyy-MM-dd"
        slotProps={{
          textField: {
            error: false,
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default DateSelector;