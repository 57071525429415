import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ItemSearch from './ItemSearch';
import useStore from '../../../../../store';
import { getUpdatedCustomerHierarchy } from '../../../utils';

let display = {
  channel: { name: 'Channel', isOpen: false },
  salesOffice: { name: 'Sales Office', isOpen: false },
  planningCustomer: { name: 'Planning Customer', isOpen: false },
  customers: { name: 'Customer', isOpen: false }
};
const arr = [
  "channel",
  "salesOffice",
  "planningCustomer",
  "customers"
];

const NestedCustomerSearch = (props) => {
  const classes = useStyles();
  //It controls expand of level
  const initial = {
    channel: false,
    salesOffice: false,
    planningCustomer: false,
    customers: false
  };

  const [iconState, setIconState] = useState(initial);
  const [allCustomersData, setAllCustomersData] = useState({});
  const setSelectedPreRoiProducts = useStore((state) => state.setSelectedPreRoiProducts);
  let baseLineCustomerCheckBox = useStore(
    (state) => state.baseLineCustomerCheckBox
  );
  const setBaseLineCustomerCheckBox = useStore(
    (state) => state.setBaseLineCustomerCheckBox
  );

  const setPreRoiSelectedCustomers = useStore(
    (state) => state.setPreRoiSelectedCustomers
  );

  let preRoiCustomersStateData = useStore(
    (state) => state.preRoiCustomersStateData
  );

  const setPreRoiCustomersStateData = useStore(
    (state) => state.setPreRoiCustomersStateData
  );

  const setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );

  const preRoiSelectedCustomers = useStore(
    (state) => state.preRoiSelectedCustomers
  );

  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);

  function handleOpen(x) {
    setIconState({
      ...iconState,
      [x]: !iconState[x],
    });
  }

  function transformDataUnique(values) {
    if (values && values.data) {
      values = values.data;

      const objValues = {
        "channel": [],
        "salesOffice": [],
        "planningCustomer": [],
        "customers": [],
      }
      arr.map((val) => {

        values[val] = [
          values.map((item) => {
            if (item[val] !== undefined) {
              if (val === "customers") {
                item[val].forEach((listItem) => {
                  objValues[val].push({ 'name': listItem, "planningCustomer": item["planningCustomer"], 'isChecked': false });
                })
              } else {
                let isIndex = objValues[val].find((element => element.name === item[val]));
                if (!isIndex) {
                  if (val === "planningCustomer") {
                    objValues[val].push({ 'name': item[val], channel: item["channel"], 'isChecked': false });
                  } else {
                    objValues[val].push({ 'name': item[val], 'isChecked': false });
                  }
                }
              }
            }
          }),
        ];
      });
      arr.map((val) => {
        objValues[val].sort(function (a, b) {
          if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          else if (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else return 0;
        });
      });

      setAllCustomersData(objValues);
      return objValues;

    } else {
      setAllCustomersData(values);
      return values;
    }
  }

  function isSelected(x) {
    return x.isChecked;
  }

  function putAll(x) {
    return x.name;
  }

  //during initial render displays all values for diff levels 
  function calculateInitial(data) {
    //const processed = processCustomerHierarchy(data, null)
    const transformed = transformDataUnique(data);
    return transformed;
  }

  useEffect(() => {
    if (selectedPromoDetails.fromView) {
      let values = { ...preRoiCustomersStateData };
      const updatedhierarchy = getUpdatedCustomerHierarchy(values, preRoiSelectedCustomers);
      setPreRoiCustomersStateData({
        ...updatedhierarchy,
      });
    }
  }, [preRoiSelectedCustomers, selectedPromoDetails]);

  useEffect(() => {
    if (props.previouslySelected !== undefined && props.previouslySelected.length === 0) {
      let Values = calculateInitial(props.data);
      setPreRoiCustomersStateData({
        ...Values,
      });
      setBaseLineCustomerCheckBox({ ...initial });
      setPreRoiSelectedCustomers("");
    } else if (props.previouslySelected !== undefined && props.previouslySelected.length > 0) {
      updateSelectedLevel()
    }
  }, [props]);

  useEffect(() => {
    if (props.clear && props.tabName !== "preROI_test") {
      //if (props.clear) {
      props.resetClear();
    }
  }, [props]);

  const updateSelectedLevel = (level) => {
    let values = { ...preRoiCustomersStateData };
    //const processed = processCustomerHierarchy(props.data, values)
    const transformed = transformDataUnique(values);
    setPreRoiCustomersStateData({
      ...transformed,
    });

  };

  function generateUrl() {
    let selectedArr = [];
    let reformatKcStr = '';
    let values = { ...preRoiCustomersStateData };

    //iterating backword
    for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      let selectedNodes = values[level].filter(isSelected).map(putAll);
      if (selectedNodes.length !== 0) {
        selectedNodes.map((val, i) => {
          reformatKcStr += `&${level}=${val}`;
          selectedArr.push(val);
        });
      }
    }
    return reformatKcStr === '' ? { url: '', selectedArr: [] } : { url: reformatKcStr, selectedArr: selectedArr };
  }

  function unCheckBelowParentLevels(x) {
    let toggleParentCheckBox = { ...baseLineCustomerCheckBox };
    let flagP = false;
    for (let [key, value] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }
    setBaseLineCustomerCheckBox({ ...toggleParentCheckBox });
  };

  const onSelection = (data) => {
    let values = { ...preRoiCustomersStateData };
    let x = data[0]; //parent level name:subcat
    let y = data[1]; //value in subcat ex-subcat1
    let selectedArr = [];
    if (x === 'customers') {
      setPreRoiSelectedCustomers(y);
      values[x].map(function (item) {
        if (item.name === y) {
          item.isChecked = !item.isChecked;
        } else {
          item.isChecked = false
        }

        if (item.isChecked) {
          selectedArr.push(item.name);
        }
      });

    } else {
      values[x].map(function (item) {
        if (item.name === y) item.isChecked = !item.isChecked;

        if (item.isChecked) {
          selectedArr.push(item.name);
        }
      });
    }


    let toggleParentCheckBox = { ...baseLineCustomerCheckBox };
    let flagP = false;
    for (let [key, value] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }

    if (selectedArr.length === preRoiCustomersStateData[x].length) {
      toggleParentCheckBox[x] = true;
    }
    setBaseLineCustomerCheckBox({ ...toggleParentCheckBox });

    //updateSelectedLevel(x);
    values = loadChildOfSelectedParent(x, y, values);

    setPreRoiCustomersStateData({
      ...values,
    });
    setSelectedPreRoiProducts([])

  };
  function loadChildOfSelectedParent(x, y, values) {
    if (values.data) {
      values = values.data;
    }
    const objValues = {
      "channel": [],
      "salesOffice": [],
      "planningCustomer": [],
      "customers": [],
    }
    if (x !== "customers") {
      if (x === "channel") {
        let selectedCHA = values['channel'].filter((item) => item.isChecked === true);
        let selectedPC = allCustomersData['planningCustomer'].filter((item) => {
          return selectedCHA.find(element => {
            return element.name === item["channel"];
          });
        })
        let selectedCUS = allCustomersData['customers'].filter((item) => {
          return selectedPC.find(element => {
            return element.name === item["planningCustomer"];
          });
        })

        values['planningCustomer'] = selectedPC;
        values['customers'] = selectedCUS;
      } else {
        let selectedPC = values['planningCustomer'].filter((item) => item.isChecked === true);
        let selectedCUS = allCustomersData['customers'].filter((item) => {
          return selectedPC.find(element => {
            return element.name === item["planningCustomer"];
          });
        })
        values['customers'] = selectedCUS;
      }


      return values;
    }
    return values;
  }
  function SelectAllChildren(parentLevel) {
    let values = { ...preRoiCustomersStateData };
    let selectedArr = [];
    values[parentLevel].map(
      (x) => ((x.isChecked = true), selectedArr.push(x.name))
    );
    setPreRoiCustomersStateData(values);

    updateSelectedLevel(parentLevel);

    let obj = generateUrl();
    let completeUrl = obj['url'];
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setPreRoiSelectedCustomers(obj['selectedArr']);
    };
  }

  function DeSelectAllChildren(parentLevel) {
    let values = { ...preRoiCustomersStateData };
    values[parentLevel].map((x) => (x.isChecked = false));
    setPreRoiCustomersStateData(values);

    updateSelectedLevel(parentLevel);

    let obj = generateUrl();
    let completeUrl = obj['url'];
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setPreRoiSelectedCustomers(obj['url']);
    }
    unCheckBelowParentLevels(parentLevel);
  }

  const handleParentClick = (e) => {
    let x = e.target.value;
    setBaseLineCustomerCheckBox({
      ...baseLineCustomerCheckBox,
      [x]: !baseLineCustomerCheckBox[x],
    });

    if (!baseLineCustomerCheckBox[x]) SelectAllChildren(x);
    else DeSelectAllChildren(x);
  };

  return (
    <div className={classes.root}>

      <div style={{ direction: 'ltr' }}>
        {Object.entries(display).map(([key, item]) => (
          <>
            <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                className="customerExpander w-4"
                onClick={() => handleOpen(key)}
                style={{ cursor: 'pointer', justifyContent: 'center' }}>
                {iconState[key] ? '-' : '+'}
              </div>
              <input
                disabled={key === 'customers'}
                type='checkbox'
                value={key}
                checked={baseLineCustomerCheckBox[key]}
                onChange={handleParentClick} />
              <label className={`${iconState[key] ? classes.open : classes.hide} customerLabel pl-1`}>
                {item.name}
              </label>
            </div>
            {
              <div style={{ marginLeft: '20px' }}>
                {iconState[key] && (
                  <ItemSearch
                    values={preRoiCustomersStateData[key]}
                    isRadio={key === 'customers'}
                    parentv={key}
                    onSelection={(e) => {
                      onSelection(e);
                    }}
                  />
                )}
              </div>
            }
          </>
        ))}

      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '600px',
    width: '450px',
    zIndex: 8525,
    backgroundColor: 'white',
    overflowY: 'auto',
    direction: 'rtl',
    padding: '1rem',
    boxShadow: '5px 5px 8px #d6d9dc',
    marginTop: '5px',
    borderRadius: '5px',
  },
  paperDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  container: {
    height: '100vh',
  },
  searchBar: {
    display: 'inline-block',
    paddingRight: '2.5rem',
  },
  searchContainer: {
    width: '100%',
    boxShadow: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    color: 'black',
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  autoCompleteSearch: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    background: '#FFFFFF',
  },
  showCheckBox: {},
  appTab: {
    fontSize: '20px',
  },
  hide: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '650',
    opacity: '0.70',
    marginBottom: '1px',
  },
  open: {
    fontSize: '14px',
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '1px',
    marginLeft: '5px',
  },
  filterItem: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: theme.palette.primary.main,
    opacity: '0.75',
    fontWeight: '480',
    padding: '3px',
  }
}));

export default NestedCustomerSearch;
