import { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom';

import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { Container, makeStyles } from '@material-ui/core';

import useStore from './store';
import Navbar from './Navbar'
import theme from './theme';
import Profile from './profile';
import TestAPI from './testAPI';
import LoginCallback from './OktaSSO/LoginCallback';
import BaseLineLayout from './BaseLineLayout';
import RoiSimulatorLayout from './RoiSimulatorLayout';
import PriceElasticityLayout from './Elasticity/PriceElasticityLayout';
import PoromoPerformaceLayout from './PromoPerformaceLayout';
import { TestDataAPI } from './testData';
import { oktaAuthConfig } from './OktaSSO/config'
import { OwnDashboard } from './Elasticity/Own/OwnDashboard';
import { CrossDashboard } from './Elasticity/Cross/CrossDashboard';
import PreRoiLayout  from './PreROI/PreRoiLayout';
import { OwnTab } from './Elasticity/Own/own_tab';
import { CrossTab } from './Elasticity/Cross/cross_tab';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh !important',
    height: 'auto',
  },
}))

const oktaAuth = new OktaAuth(oktaAuthConfig);

function SecureAll() {
  const classes = useStyles()
  const history = useHistory();

  const token = useStore(state => state.apiToken)
  const setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );
  const setSelectedBaseLineCustomerNew = useStore(
    (state) => state.setSelectedBaseLineCustomerNew
  );
  const setSelectedBaseLineProductNew = useStore(
    (state) => state.setSelectedBaseLineProductNew
  );

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    localStorage.removeItem('req');
    // Reset baseline filters in zustand store on page refresh 
    setSelectedBaseLineFilter("");
    setSelectedBaseLineCustomerNew("");
    setSelectedBaseLineProductNew("");
    console.log("run from secureAll")
  }, [])

  // useEffect(() => {
  //   const checkTokenExpiry = async () => {
  //     const decoded = jwt_decode<JwtPayload>(token)
  //     if (decoded){
  //       if ((decoded.exp && Date.now() >= decoded.exp)){
  //         if ( authState && authState.accessToken && authState.idToken){
  //             const result = await authenticateWithServer(authState.accessToken.accessToken, authState.idToken.idToken)
  //             if (result.error || !result.jwtToken){
  //               useStore.setState({
  //                 loginState: false,
  //                 authError:  result.error || 'Invalid login',
  //               })
  //             }else{
  //               useStore.setState({
  //                 loginState: true,
  //                 authError: '',
  //                 apiToken: result.jwtToken
  //               })
  //               token = result.jwtToken
  //             }
  //           }else{
  //             window.location.reload()
  //           }
  //       }

  //     }
  //   }
  // }, [token])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Security
        oktaAuth={oktaAuth}
        scope={['openid', 'profile', 'groups']}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Container style={{ maxWidth: '100%' }} className={classes.container}>
          {
            oktaAuth.isAuthenticated && token
              ? <>
                <Navbar />
                <Switch>
                  <SecureRoute exact path='/' component={BaseLineLayout} />
                  <SecureRoute
                    exact
                    path="/baseline-forcasting"
                    component={BaseLineLayout}
                  />
                  <SecureRoute
                    exact
                    path="/roi-simulator"
                    component={RoiSimulatorLayout}
                  />
                  <SecureRoute
                    exact
                    path="/price-elasticity"
                    component={PriceElasticityLayout}
                  />
                  <SecureRoute
                    exact
                    path="/price-elasticity/own"
                    component={OwnTab}
                  />
                  <SecureRoute
                    exact
                    path="/price-elasticity/cross"
                    component={CrossTab}
                  />
                  <SecureRoute
                    exact
                    path="/promo-performace"
                    component={PoromoPerformaceLayout}
                  />

                  <SecureRoute
                    exact
                    path="/price-elasticity/own-dashboard"
                    component={OwnDashboard}
                  />
                  <SecureRoute
                    exact
                    path="/price-elasticity/cross-dashboard"
                    component={CrossDashboard}
                  />
                  <SecureRoute exact path="/profile" component={Profile} />
                  <SecureRoute exact path="/api_test" component={TestAPI} />
                  <SecureRoute exact path="/test-data" component={TestDataAPI} />
                  <SecureRoute
                    exact
                    path="/pre-roi"
                    component={PreRoiLayout} 
                  />

                  {/* <Route
                  path='/login'
                  render={() => <Login config={oktaSignInConfig} />}
                /> */}
                  <Route path='/login/callback' component={LoginCallback} />
                </Switch>
              </>
              : <Switch>
                <SecureRoute exact path='/' component={Navbar} />
                <Route path='/login/callback' component={LoginCallback} />
              </Switch>
          }

        </Container>
      </Security>
    </ThemeProvider>
  );
}

export default SecureAll;
