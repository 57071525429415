import moment from "moment";

export const currencySymbol = (countryValue) => {
  switch (countryValue) {
    case "PE":
      return "S/.";
    case "UK":
      return "£";
    case "KR":
      return "₩";
    default:
      return "S/.";
  }
};

export const generateBaseLineDataList = (
  value,
  viewMonthly,
  typeValue,
  impactorValue
) => {
  let defaultCurrentYear = moment().format("YYYY");
  /* console.log("moment().format('YYYY')", moment().format("YYYY"));
  console.log("moment().format('MM')", moment().format("MM"));
  console.log("valuevalue12", value); */
  const countriesList = value;

  let dataRows = [];
  let headerData = [];
  let weekCountAPI = [];

  let totalQty = 0;
  let avgVal = 0;

  let statisticalBaseLineHeader;

  let rowList = [];
  let headerList = [];

  let headerMain = {};
  let rowMain = {};
  let sourceMain = {};

  let headerListLocal = [];
  let rowListLocal = [];
  let rowsTotal = [];

  let mainHeaderForWeek = [];
  let monthList = []

  for (const key in countriesList) {
    let curYear = countriesList[key]["year"];

    statisticalBaseLineHeader = countriesList[key]["statisticalBaseLine"];

    totalQty += statisticalBaseLineHeader?.totalQuantity;
    avgVal += statisticalBaseLineHeader?.averageQuantity;

    let mainList = countriesList[key];
    
    //delete countriesList[key]["year"];

    let indexAuto = 0;

    // headerListLocal = ["Source", "Total", "Average"];
    headerListLocal = ["Source"];

    let totalSum = [];
    let j = 0;
    if (parseInt(defaultCurrentYear) <= parseInt(curYear)) {
      for (const keyMain in mainList) {
        let source = keyMain;

        if (Array.isArray(sourceMain[indexAuto])) {
          //sourceMain[indexAuto] = [source, totalQty, avgVal];
          sourceMain[indexAuto] = [source];
        } else {
          // sourceMain[indexAuto] = [source, totalQty, avgVal];
         
            if(source === 'statisticalBaseLine'){
              sourceMain[indexAuto] = ['Statistical Baseline'];
            }else if(source === 'adjustedBaseLine'){
              sourceMain[indexAuto] = ['Adjustment Baseline'];
            }else{
              sourceMain[indexAuto] = [source];
            }
          
        }

        rowList = [...rowListLocal];
        headerList = [...headerListLocal];

        for (const month in mainList[keyMain]) {
          // let apiMonth = moment(month, "MMMM").isValid()
          //   ? moment(month, "MMMM").format("MM")
          //   : null;
          // let currentMonth = moment().format("MM");

          // if (apiMonth && parseInt(currentMonth) <= parseInt(apiMonth)) {
            let count = 0;
            if (month !== "totalQuantity" && month !== "averageQuantity") {
              if (indexAuto === 1) {
                mainHeaderForWeek.push(`${month} ${curYear}`);
                monthList.push(`${month}`);
              }
             
              if (mainList[keyMain][month]) {
                
                for (const monthVal in mainList[keyMain][month]) {
                 
                  if (impactorValue || keyMain !== "adjustedBaseLine") {
                    let rowDataValue = mainList[keyMain][month][monthVal];

                    if (
                      typeValue &&
                      impactorValue &&
                      keyMain === "adjustedBaseLine"
                    ) {
                      if (typeValue === "AbsoluteValue") {
                        //rowDataValue = parseInt(rowDataValue) + parseInt(impactorValue);

                          rowDataValue =  parseInt(impactorValue);
                      } else {
                        //rowDataValue = parseInt(rowDataValue) +   (parseInt(rowDataValue) * parseInt(impactorValue)) / 100;

                       // rowDataValue =   (parseInt(rowDataValue) * parseInt(impactorValue)) / 100;

                        //rowDataValue =   rowDataValue;

                        //console.log("line138245", rowMain[1][j], keyMain)
                        console.log("impactorValueimpactorValue12345", impactorValue, (parseInt(rowMain[1][j]) * impactorValue)/ 100)
                        console.log("rowMainrowMain12w", rowMain[1])
                        rowDataValue =  (parseInt(rowMain[1][j]) * (impactorValue)) / 100
                        //rowDataValue =  j
                        j++
                      }
                    }

                    if (Array.isArray(rowMain[indexAuto])) {
                      rowMain[indexAuto].push(rowDataValue);

                      let headerTxt =
                        viewMonthly === "monthly"
                          ? `${monthVal} ${curYear}`
                          : `${monthVal}-${month}`;
                      headerMain[indexAuto].push(headerTxt);
                    } else {
                      rowMain[indexAuto] = [rowDataValue];

                      let headerTxt =
                        viewMonthly === "monthly"
                          ? `${monthVal} ${curYear}`
                          : `${monthVal}-${month}`;
                      headerMain[indexAuto] = [headerTxt];
                    }

                    console.log("12wsx",  mainList[keyMain][month][monthVal])
                    rowList.push(mainList[keyMain][month][monthVal]);

                    headerList.push(`${monthVal} ${curYear}`);
                    count = count + 1;
                  }
                }

                weekCountAPI.push(count);
              }
            }
          // }
          //month close here
        }

        headerData.push(headerList);

        dataRows.push(rowList);
        headerData.push(headerList);

        /*    dataRows.map((item, i) => {
          if (totalSum.length === 0) {
            totalSum.push(parseInt(item));
          } else {
            totalSum[i] = parseInt(totalSum[i]) + parseInt(item);
            console.log("totalsum140 totalSum[i]", totalSum[i]);
            console.log("totalsum141 item", item);
          }
        }); */

        indexAuto++;
      }

      console.log("totalsum140+", totalSum);
      dataRows.push(totalSum);
    }

    console.log("dataRows", dataRows);
    ///}
  }

  dataRows = [];
  headerData = [];

  for (const key in rowMain) {
    dataRows.push([...sourceMain[key], ...rowMain[key]]);
    headerData.push([...headerListLocal, ...headerMain[key]]);
    
  }

  if(impactorValue){
    let totalsumVal = [];

    for (let index = 0; index < 2; index++) {
      for (let j = 0; j < dataRows[index]?.length; j++) {
        if (index === 0) {
          totalsumVal.push(dataRows[index][j]);
        } else {
          if (dataRows[index][j]) {
            
            totalsumVal[j] = totalsumVal[j] + dataRows[index][j];
            //console.log("test224",  totalsumVal[j] + dataRows[index][j])
          }
        }
      }
    }
    totalsumVal[0] = "stat + adj";
    dataRows.push(totalsumVal);
  }
  
  let minValue = Number.POSITIVE_INFINITY;
  let temAry5  ;
  if(dataRows.length > 0){ 
   temAry5  =  [...dataRows[0]];

  Array.min = function(array){
    return Math.min.apply(Math, array)
  }
  var minimum = Array.min(temAry5)
  console.log("newReturnnewRetur123n15145",minimum);
  for (const value of temAry5){
    minValue = Math.min(minValue, value)
  }
}
 
  

  let newReturn = { dataRows, headerData, weekCountAPI, mainHeaderForWeek, minValue, monthList };
  console.log("newReturnnewReturn23", newReturn);
  return newReturn;
};

export const getWeeksInYear = (year) => {
  let weeksArr = [];
  let yearStart = moment([year, 1]).startOf("year");
  let yearEnd = moment([year, 1]).endOf("year");
  let weekNo = 0;
  let monthNo = 0;
  while (yearStart.isSameOrBefore(yearEnd)) {
    console.log(parseInt(yearStart.format("MM")));
    if (
      weekNo === yearStart.isoWeek() ||
      monthNo === parseInt(yearStart.format("MM"))
    ) {
    } else {
      weekNo = yearStart.isoWeek();
      monthNo = yearStart.format("MMMM");
      weeksArr.push({
        month: monthNo,
        week: weekNo,
      });
    }
    yearStart.add(1, "days");
  }
  return weeksArr;
};

export const generateCountiesDataList = (value) => {
  const currentYear = moment().format("YYYY");

  console.log("currentYearcurrentYear", moment().format("YYYY"))

  let weeksList = getWeeksInYear(currentYear);

  if (weeksList[0].week > 1) {
    weeksList = weeksList.slice(1);
  }

  const countriesList = value;

  let dataRows = [];
  let headerData = [];
  let weekCountAPI = [1];

  let subAryheader = ["-"];
  for (const impactorName in countriesList) {
    let newHeaderData = ["Source"];

    let tempAry = [];
    let temSubHeader = [];
    let absTrue ;
    for (const impVal in countriesList[impactorName]) {
      //console.log("monthNames123", impVal);
      console.log("countriesList[impactorName][impVal]", countriesList[impactorName][impVal])

      if (impVal !== "totalQuantity" && impVal !== "averageQuantity" && impVal !== "isAbs"){
        newHeaderData.push(`${impVal}`);
      }
      
      if(impVal === "isAbs"){
        absTrue = countriesList[impactorName][impVal];
      }
     

      if (
        countriesList[impactorName][impVal] &&
        Object.keys(countriesList[impactorName][impVal]).length > 0
      ) {
        let weekCount = 0;
        let localAry = [];
        for (const iterator of weeksList) {
          if (iterator.month.toLowerCase() === impVal.toLowerCase()) {
            if (countriesList[impactorName][impVal][`W${iterator.week}`]) {
              console.log(
                "test123456",
                countriesList[impactorName][impVal][`W${iterator.week}`]
              );
              let newVal1 = `${countriesList[impactorName][impVal][`W${iterator.week}`]}${absTrue == 'FALSE' ? '%' : ''}`
              localAry.push(
                newVal1
              );

            } else {
              localAry.push(" ");
            }

            weekCount++;
          }
        }

        tempAry.push(...localAry);

        weekCountAPI.push(weekCount);
      } else if (impVal !== "totalQuantity" && impVal !== "averageQuantity" && impVal !== "isAbs") {
        let weekCount = 0;
        for (const iterator of weeksList) {
          if (iterator.month.toLowerCase() === impVal.toLowerCase()) {
            tempAry.push(" ");
            weekCount++;
          }
        }
        weekCountAPI.push(weekCount);
      }
    }

    for (const iterator of weeksList) {
      temSubHeader.push(`W${iterator.week}`);
    }

    dataRows.push([
      impactorName,
      //countriesList[impactorName]["totalQuantity"] || "-",
      //countriesList[impactorName]["averageQuantity"] || "-",
      ...tempAry,
    ]);
    subAryheader.push(["-", ...temSubHeader]);
    headerData.push(newHeaderData);
  }

  let newReturn = { dataRows, headerData, weekCountAPI, subAryheader };
  console.log("newReturn567", newReturn)

  return newReturn;
};
export const generateCountriesDailyDataList = (value) => {
  const currentYear = moment().format("YYYY");
  let daysInNonLeapYear = {'january':31,'february':28, 'march':31, 'april':30, 'may':31, 'june':30, 'july':31, 'august':31, 'september':30, 'october':31, 'november':30, 'december':31}

  if (currentYear%400 === 0) {
    daysInNonLeapYear['february']=29;
  }
  let weeksList = getWeeksInYear(currentYear);

  if (weeksList[0].week > 1) {
    weeksList = weeksList.slice(1);
  }

  const countriesList = value;

  let dataRows = [];
  let headerData = [];
  let weekCountAPI = [1];

  let subAryheader = ["-"];
  for (const impactorName in countriesList) {
    let newHeaderData = ["Source"];

    let tempAry = [];
    let temSubHeader = [];
    let absTrue;

    for (const impVal in countriesList[impactorName]) {
      if (
        impVal !== "totalQuantity" &&
        impVal !== "averageQuantity" &&
        impVal !== "isAbs"
      ) {
        newHeaderData.push(`${impVal}`);
      }

      if (impVal === "isAbs") {
        absTrue = countriesList[impactorName][impVal];
      }

      let totalDays = Object.keys(countriesList[impactorName][impVal]).length;

      if (
        impVal !== "isAbs" &&
        countriesList[impactorName][impVal] &&
        totalDays > 0
      ) {
        let localAry = [];
        let dayCount = 0;
        for (let day = 1; day <= daysInNonLeapYear[impVal]; day++) {
          if (countriesList[impactorName][impVal][day]) {
            let newVal1 = `${countriesList[impactorName][impVal][day]}${
              absTrue === "FALSE" ? "%" : ""
            }`;
            localAry.push(newVal1);
          } else {
            localAry.push(" ");
          }
          dayCount++;
        }

        tempAry.push(...localAry);
        weekCountAPI.push(dayCount);
      } else if (
        impVal !== "totalQuantity" &&
        impVal !== "averageQuantity" &&
        impVal !== "isAbs"
      ) {
        let dayCount = 0;
        for (let day = 1; day <= daysInNonLeapYear[impVal]; day++) {
          tempAry.push(" ");
          dayCount++;
        }
        weekCountAPI.push(dayCount);
      }

      if (impVal !== "isAbs") {
        for (let day = 1; day <= daysInNonLeapYear[impVal]; day++) {
          temSubHeader.push(`${day}`);
        }
      }
    }

    dataRows.push([impactorName, ...tempAry]);
    subAryheader.push(["-", ...temSubHeader]);
    headerData.push(newHeaderData);
  }

  let newReturn = { dataRows, headerData, weekCountAPI, subAryheader };

  return newReturn;
};