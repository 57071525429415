import { Grid, Box, Button, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import useStore from '../../../../store';
import ClearIcon from '@mui/icons-material/Clear';
import CustomerSearch from "./CustomerSearch/CustomerSearch";
import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";

const useStyles = makeStyles((theme) => ({
  clearBtn: {
    padding: '5px !important',
    minWidth: '50px !important',
    '&:hover': {
      backgroundColor: "#fcf !important",
    },
  }
}));

export const CustomerSelectionInput = ({ id, promoData }: any) => {
  let preRoiSelectedCustomers = useStore(
    (state) => state.preRoiSelectedCustomers
  );
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const classes = useStyles();
  const [clearCustomer, setClearCustomer] = useState(false);
  const setSelectedBaseLineCustomerNew = useStore(
    (state: any) => state.setSelectedBaseLineCustomerNew
  );
  useEffect(() => {
    if (preRoiSelectedCustomers.length > 0) {
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, selectedCustomer: preRoiSelectedCustomers } : tab))
    }
  }, [preRoiSelectedCustomers])
  //   useEffect(() => {
  // 	if(promoSimulatorTabs[id].selectedCustomer.length ===0 && preRoiSelectedCustomers.length > 0){
  // 		console.log("This")
  // 		setSelectedBaseLineCustomerNew(" ");	
  // 	}		
  // 	}, [promoSimulatorTabs])
  const clearCustomerData = () => {
    setClearCustomer(true);
    setSelectedBaseLineCustomerNew(" ");
    if (promoSimulatorTabs[id].selectedCustomer.length > 0) {
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, selectedCustomer: '' } : tab))
    }
  }

  const resetCustomer = () => {
    setClearCustomer(false);
  }

  return (
    <Grid container className="mt-2">
      <Grid item xs={3}>
        <Box sx={{ fontWeight: '500', m: 1, fontSize: '0.75rem' }}>Customer <span className="text-red-900 font-bold text-base">*</span></Box>
      </Grid>
      <Grid item sx={{ pl: 10 }} xs={9}>
        <Stack direction="row" spacing={1}>
          <div style={{ width: '380px' }}>
            <CustomerSearch disabled={promoSimulatorTabs[id].calculateButtonClicked} dataFor="Promo" clear={clearCustomer} reset={resetCustomer} promoData={promoData} />
          </div>
          <Button className={classes.clearBtn} disabled={promoSimulatorTabs[id].calculateButtonClicked} variant="outlined" color="error" onClick={() => clearCustomerData()}><ClearIcon /></Button>
        </Stack>
      </Grid>
    </Grid>
  )
}