import { useEffect, useState, useMemo } from "react";
import { AxiosResponse } from "axios";
import { TableColumn } from "react-data-table-component";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
import { forEach, isEmpty, map, omit, filter } from 'lodash';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import useStore from "../../store";
import ProductLevelSelector from "../Widgets/ProductLevelSelector";
import NestedSearch from "../NestedSearch";
import { ApiRequestService } from "../../services/api-service";
import { useCrossAnalysis, useCrossScopeSelector, useKcProductHierarchy, useLastModelRunDate, useLineFittingData, useAvailableScopes, useProductForElasticity } from "../../services/queries";
import { ElasticityScopeRecord, ElasticityRecord, SelectedProduct, Scope, Source } from "../types";
import { getHierarchyLevel, parseCrossAnalysisData, sortScopeData, paginationPerPageDataLimit, getSelectedWeekRange, getDropDownTreeData, getCrossViewMoreData } from "../utils";
import { CrossCountryScope } from "./components/country_scope";
import { CrossMarketScope } from "./components/market_scope";
import { MemoizedWeekSelector } from "../WeekSelector";

const useStyles = makeStyles((theme) => ({
    btnContainer: {
        display: "flex",
        justifyContent: 'flex-end',
        marginRight: '15px',
    },
    btnReset: {
        background: "#cb0c0c",
        color: "#fff",
        textTransform: "none",
        margin: "0 20px",
        padding: "6px 20px",
        borderRadius: "10px",
    },
    btnSucess: {
        background: "#163c62",
        color: "#fff",
        textTransform: "none",
        margin: "0 10px",
        padding: "6px 35px",
        borderRadius: "10px",
    },
}));

type CrossTabProps = {
    byScope: boolean;
};

export const CrossTab: React.FunctionComponent<CrossTabProps> = ({ byScope }) => {
    const classes = useStyles();
    const initialProducts: SelectedProduct = {
        crossAnalysisApiUrl: "",
        flag: false,
        selectedKcArr: []
    }
    const elasticityPrice = {
        avgPrice: true,
        promoPrice: false,
        basePrice: false
    }

    let selectedCountry = useStore((state) => state.selectedCountry);
    let source = useStore((state) => state.selectedSourceChoice);
    let selectedScopeLevel = useStore((state) => state.selectedScopeLevel ? state.selectedScopeLevel : "WC");
    let selectedCategoryLevel = useStore((state) => state.selectedCategoryLevel);
    const setSelectedScopeLevel = useStore((state) => state.setSelectedScopeLevel);
    let setTargetCrossValue = useStore((state) => state.setTargetCrossValue);
    let setPriceElasticityTab = useStore((state) => state.setPriceElasticityTab);
    const setSelectedCategoryLevel = useStore((state) => state.setSelectedCategoryLevel);
    let selectedProductLevel = useStore((state) => state.selectedProductLevel);
    const setSelectedProductLevel = useStore((state) => state.setSelectedProductLevel);
    let selectedSubCategoryLevel: any = useStore((state) => state.selectedSubCategoryLevel);
    let setKcProductCrossValue = useStore((state) => state.setKcProductCrossValue);
    let priceType = useStore((state) => state.priceType ? state.priceType : elasticityPrice);
    const setPriceType = useStore((state) => state.setPriceType);
    const regressionApiBodyCross = useStore((state) => state.regressionApiBodyCross);
    const setRegressionApiBodyCross = useStore((state) => state.setRegressionApiBodyCross);
    const userSelectedWeekRangesCross = useStore.getState().userSelectedWeekRangesCross;
    const setUserSelectedWeekRangesCross = useStore((state) => state.setUserSelectedWeekRangesCross);
    const setSelectedWeeksApply = useStore((state) => state.setSelectedWeeksApply);

    const [crossTabData, setCrossTabData] = useState<any[]>([]);
    const [crossTabScopeData, setCrossTabScopeData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [crossTabDataCount, setCrossTabPaginationCount] = useState(0);
    const [selectedKcProducts, setSelectedKcProducts] = useState<SelectedProduct>(initialProducts);
    const [selectedTargetProducts, setSelectedTargetProducts] = useState<SelectedProduct>(initialProducts);
    const [selectedProductsForScope, setSelectedProductsForScope] = useState<SelectedProduct>(initialProducts);
    const [parsedData, setParsedData] = useState<ElasticityRecord[]>([]);
    const [subCustomerData, setSubCustomerData] = useState<ElasticityScopeRecord[]>([]);
    const [customerOriginal, setcustomerOriginal] = useState<ElasticityScopeRecord[]>([]);
    const [crossElsaticityByScopePayload, setCrossElsaticityByScopePayload] = useState<object>({});
    const [crossElasticityPayload, setcrossElasticityPayload] = useState<object>({});
    const [isDownloading, setisDownloading] = useState(false);
    const [canShowProgress, setcanShowProgress] = useState(false);
    const [originalDataInstance, setOriginalDataInstance] = useState([]);
    const [treeDataInstance, setTreeDataInstance] = useState([]);
    const [lineFittingDataPayload, setLineFittingDataPayload] = useState([]);
    const [isEditWeeksClicked, setIsEditWeeksClicked] = useState(false);
    const [hiddenCustomers, setHiddenCustomers] = useState([]);
    const [open, setOpen] = useState(false);
    const [crossDataNew, setCrossDataNew] = useState<any>([]);
    const [crossScopeDataNew, setCrossScopeDataNew] = useState<any>([]);
    const [crossScopeDataForGrid, setCrossScopeDataForGrid] = useState<any>([]);
    const [canShowSearch, setcanShowSearch] = useState(false);
    const [clear, setClear] = useState(false);
    const [availableScopesResult, setavailableScopesResult] = useState<any>([]);
    const [crossScopeData, setCrossScopeData] = useState([]);
    const [crossScopeDataExclusions, setCrossScopeDataExclusions] = useState([]);
    const [crossScopeDataExclusionMessage, setCrossScopeExclusionMessage] = useState('');
    const [crossData, setCrossData] = useState([]);
    const [crossReferenceData, setCrossReferenceData] = useState<any>([]);
    const [filteredProducts, setFilteredProducts] = useState<any>([]);
    const [filteredTargetProducts, setFilteredTargetProducts] = useState<any>([]);

    const useNewScopeBy = useMemo(() => ((byScope, selectedProductLevel) => {
        return (byScope && selectedProductLevel === 'subBrand')
    })(byScope, selectedProductLevel), [byScope, selectedProductLevel])

    const { data: lastModelRunData, isError: lastModelRunError } = useLastModelRunDate(selectedCountry);
    const { isLoading: isKcProductData, data: kcProductData } = useKcProductHierarchy(`${selectedCountry}${source ? `&source=${source}` : ''}`, getHierarchyLevel(selectedProductLevel));
    const { isLoading: isCrossDataLoading, data: crossDataResposne, isError: crossDataError } = useCrossAnalysis(crossElasticityPayload);
    const { isLoading: isCrossScopeLoading, data: crossScopeDataResponse, isError: crossScopeDataError } = useCrossScopeSelector(crossElasticityPayload);
    const { isLoading: loadingLineFittingData, data: lineFittingData, isError: isLineFittingError } = useLineFittingData(lineFittingDataPayload, "cross");
    const { isLoading: isScopeLoading, data: availableScopes } = useAvailableScopes(`country=${selectedCountry}${selectedCategoryLevel ? `&category=${encodeURIComponent(selectedCategoryLevel)}` : ''}
            ${selectedSubCategoryLevel ? `&subCategories=${encodeURIComponent(selectedSubCategoryLevel)}` : ''}&hierarchyLevel=${getHierarchyLevel(selectedProductLevel)}&isOwn=false`, useNewScopeBy);
    const { isLoading: isNewCrossScopeLoading, data: newCrossScopeData, isError: newCrossScopeDataError } = useProductForElasticity(crossElsaticityByScopePayload);

    useEffect(() => {
        if (crossDataResposne?.data) {
            setCrossData(crossDataResposne.data);
        }
    }, [crossDataResposne])

    useEffect(() => {
        if (crossScopeDataResponse?.data || crossScopeDataResponse?.exclusions.length > 0) {
            setCrossScopeData(crossScopeDataResponse?.data);
            if (userSelectedWeekRangesCross.length > 0) {
                setCrossScopeDataExclusions(crossScopeDataResponse?.exclusions);
                setCrossScopeExclusionMessage(crossScopeDataResponse?.message);
            }
        }
    }, [crossScopeDataResponse, userSelectedWeekRangesCross])
    // calls when component mounted
    useEffect(() => {
        setUserSelectedWeekRangesCross([]);
        setCrossTabPaginationCount(0);
        setCurrentPage(1);
        setSelectedWeeksApply([])
    }, [])

    useEffect(() => {
        onClickClear()
    }, [selectedCountry])

    useEffect(() => {
        if (lineFittingData === undefined) return;

        let lineDataObj: any = {};

        if (lineFittingData.length > 0) {
            for (const val of lineFittingData) {
                if (val !== null && val !== undefined) {
                    let obj: any = val;
                    let key = obj.year.toString();
                    if (!lineDataObj[key]) {
                        lineDataObj[key] = [obj];
                    } else {
                        lineDataObj[key].push(obj);
                    }
                }
            }
            // Constructing drop down tree select object
            const treeNodesData: any = getDropDownTreeData(selectedCountry, lineDataObj);
            setTreeDataInstance(treeNodesData);
            setOriginalDataInstance(lineDataObj);

        }

        //On initial loading pushing all data points
        let dp = [];
        for (const key in lineDataObj) {
            for (let i = 0; i < lineDataObj[key].length; i++) {
                dp.push(lineDataObj[key][i]);
            }
        }
        setRegressionApiBodyCross(dp);

    }, [lineFittingData]);

    useEffect(() => {
        const initialProducts = selectedKcProducts && selectedKcProducts !== undefined ? selectedKcProducts.selectedKcArr : [];
        const targetProducts = selectedTargetProducts && selectedTargetProducts !== undefined ? selectedTargetProducts.selectedKcArr : [];
        const productLevel = getHierarchyLevel(selectedProductLevel);
        const selectedCrossInitialArr: any = [];
        initialProducts.forEach(product => {
            if (productLevel === 'EAN') {
                selectedCrossInitialArr.push(product.split('-')[0].trim());
            } else {
                selectedCrossInitialArr.push(product);
            }
        })
        const selectedCrossTargetArr: any = [];
        targetProducts.forEach(product => {
            if (productLevel === 'EAN') {
                selectedCrossTargetArr.push(product.split('-')[0].trim());
            } else {
                selectedCrossTargetArr.push(product);
            }
        })
        let payload: any = {
            country: selectedCountry,
            customer: null,
            hierarchyLevel: productLevel,
            initial: selectedCrossInitialArr,
            target: selectedCrossTargetArr
        }
        setLineFittingDataPayload(payload);
    }, [selectedProductLevel, selectedKcProducts, selectedTargetProducts, userSelectedWeekRangesCross]);

    useEffect(() => {
        const selectedHierarchyLevel = getHierarchyLevel(selectedProductLevel);

        const crossElascticityPayload = {
            category: selectedCategoryLevel,
            country: selectedCountry,
            forOwn: false,
            hierarchyLevel: selectedHierarchyLevel,
            initialBrands: selectedKcProducts.selectedBrand ?? [],
            initialManufacturers: selectedKcProducts.selectedManufacturer ?? [],
            initialNodeValues: selectedKcProducts.selectedKcArr,
            initialPacks: selectedKcProducts.selectedPacks,
            initialSubBrands: selectedKcProducts.selectedSubBrands ?? [],
            initialSubCategories: selectedSubCategoryLevel,
            scope: selectedScopeLevel,
            source: source,
            subCategories: selectedSubCategoryLevel,
            targetBrands: selectedTargetProducts.selectedBrand ?? [],
            targetManufacturers: selectedTargetProducts.selectedManufacturer ?? [],
            targetNodeValues: selectedTargetProducts.selectedKcArr,
            targetPacks: selectedTargetProducts.selectedPacks,
            targetSubBrands: selectedTargetProducts.selectedSubBrands ?? [],
            targetSubCategories: selectedSubCategoryLevel,
            timePeriods: [],
            topNBottomN: 0
        }

        if (!useNewScopeBy) {
            setcrossElasticityPayload(crossElascticityPayload);
        } else {
            if (selectedTargetProducts && selectedTargetProducts.selectedKcArr && selectedTargetProducts.selectedKcArr.length > 0 && selectedProductsForScope && selectedProductsForScope.selectedKcArr && selectedProductsForScope.selectedKcArr.length > 0) {
                setCrossElsaticityByScopePayload({
                    category: selectedCategoryLevel,
                    country: selectedCountry,
                    forOwn: false,
                    hierarchyLevel: getHierarchyLevel(selectedProductLevel),
                    initialChannels: selectedScopeLevel === Scope.CHANNEL ? selectedKcProducts.selectedKcArr : [],
                    initialCustomers: selectedScopeLevel === Scope.CUSTOMER ? selectedKcProducts.selectedKcArr : [],
                    initialSubBrands: selectedProductsForScope.selectedKcArr,
                    scope: selectedScopeLevel,
                    source: source,
                    subCategories: selectedSubCategoryLevel,
                    targetChannels: selectedScopeLevel === Scope.CHANNEL ? selectedTargetProducts.selectedKcArr : [],
                    targetCustomers: selectedScopeLevel === Scope.CUSTOMER ? selectedTargetProducts.selectedKcArr : [],
                    topNBottomN: 0
                })
            }

        }

        if (isEditWeeksClicked && !useNewScopeBy) {
            const timePeriods: any = [];
            let lineDataObj: any = {};
            if (userSelectedWeekRangesCross.length > 0) {
                const dataPoints = userSelectedWeekRangesCross ? userSelectedWeekRangesCross : [];
                if (lineFittingData === undefined) return;
                if (dataPoints !== undefined && dataPoints.length > 0 && dataPoints.length !== lineFittingData.length) {
                    for (const val of dataPoints) {
                        if (val !== null && val !== undefined) {
                            let obj: any = val;
                            let key = obj.year.toString();
                            if (!lineDataObj[key]) {
                                lineDataObj[key] = [obj];
                            } else {
                                lineDataObj[key].push(obj);
                            }
                        }
                    }

                    for (const key in lineDataObj) {
                        const weekArray = lineDataObj[key];
                        const weeks: any = [];
                        weekArray.forEach((weekData: any) => {
                            if (weekData !== null && weekData !== undefined && weekData.week !== undefined) {
                                weeks.push(weekData.week);
                            }
                        })
                        const weekRange: any = {};
                        weekRange["year"] = key;
                        weekRange["weeks"] = weeks;
                        timePeriods.push(weekRange);
                    }
                }
            }
            const crossElascticityPayload = {
                category: selectedCategoryLevel,
                country: selectedCountry,
                forOwn: false,
                hierarchyLevel: selectedHierarchyLevel,
                initialBrands: selectedKcProducts.selectedBrand ?? [],
                initialManufacturers: selectedKcProducts.selectedManufacturer ?? [],
                initialNodeValues: selectedKcProducts.selectedKcArr,
                initialPacks: selectedKcProducts.selectedPacks,
                initialSubBrands: selectedKcProducts.selectedSubBrands ?? [],
                initialSubCategories: selectedSubCategoryLevel,
                scope: selectedScopeLevel,
                source: source,
                subCategories: selectedSubCategoryLevel,
                targetBrands: selectedTargetProducts.selectedBrand ?? [],
                targetManufacturers: selectedTargetProducts.selectedManufacturer ?? [],
                targetNodeValues: selectedTargetProducts.selectedKcArr,
                targetPacks: selectedTargetProducts.selectedPacks,
                targetSubBrands: selectedTargetProducts.selectedSubBrands ?? [],
                targetSubCategories: selectedSubCategoryLevel,
                timePeriods: timePeriods,
                topNBottomN: 0
            }
            setcrossElasticityPayload(crossElascticityPayload);
        }

    }, [selectedKcProducts.selectedKcArr, selectedTargetProducts.selectedKcArr, selectedProductLevel, selectedCountry, source, selectedScopeLevel, isEditWeeksClicked, regressionApiBodyCross, userSelectedWeekRangesCross, selectedProductsForScope]);

    useEffect(() => {
        if (crossDataNew && selectedKcProducts.flag && selectedTargetProducts.flag) {
            const record = parseCrossAnalysisData(crossDataNew)
            const sortedData = record.sort((a, b) => a.initial! > b.initial! ? 1 : -1)
            if (record && sortedData) {
                setParsedData(sortedData);
            }
        } else {
            setParsedData([])
            setHiddenCustomers([])
        }

    }, [crossData, selectedKcProducts, selectedTargetProducts, crossDataNew]);

    useEffect(() => {
        if (isEmpty(crossScopeDataForGrid)) {
            setSubCustomerData([])
        }
        if (!isCrossScopeLoading && selectedKcProducts.flag && selectedTargetProducts.flag) {
            if (crossScopeDataForGrid && !isEmpty(crossScopeDataForGrid)) {
                const scopeResult: ElasticityScopeRecord[] = []
                const hiddenCustomerData: any = hiddenCustomers;
                for (const scope in crossScopeDataForGrid) {
                    if (!hiddenCustomerData.includes(scope)) {
                        const scopeData: ElasticityScopeRecord = {
                            customer: scope,
                            data: parseCrossAnalysisData(crossScopeDataForGrid[scope], scope)
                        }
                        scopeResult.push(scopeData)
                    }
                }
                setSubCustomerData(scopeResult)
                setcustomerOriginal(scopeResult)

            } else {
                setSubCustomerData([])
            }
        }
    }, [isCrossScopeLoading, crossScopeData, selectedKcProducts, selectedTargetProducts, crossScopeDataForGrid]);

    useEffect(() => {
        if (isCrossDataLoading || isCrossScopeLoading) {
            setcanShowProgress(true)
        } else {
            setcanShowProgress(false)
        }
    }, [isCrossDataLoading, isCrossScopeLoading]);

    useEffect(() => {
        const startIndex = currentPage * paginationPerPageDataLimit - paginationPerPageDataLimit;
        const endIndex = startIndex + paginationPerPageDataLimit;
        if (selectedScopeLevel === Scope.COUNTRY) {
            setCrossTabPaginationCount(parsedData.length);
            setCrossTabData(parsedData ? parsedData.slice(startIndex, endIndex) : []);
        } else {
            let maxCount = 0;
            const customerData = subCustomerData.map(({ customer, data }) => {
                const sortedData: any = data.sort((a, b) => a.initial! > b.initial! ? 1 : -1);
                if (sortedData.length > maxCount) {
                    setCrossTabPaginationCount(sortedData.length);
                    maxCount = sortedData.length;
                }
                return { customer: customer, data: sortedData.slice(startIndex, endIndex) };
            });
            setCrossTabScopeData(customerData);
        }
    }, [currentPage, crossData, crossScopeData, parsedData, subCustomerData]);

    useEffect(() => {
        setSelectedWeeksApply([]);
    }, [selectedKcProducts, selectedTargetProducts]);

    useEffect(() => {
        if (parsedData.length > 0 && crossTabData.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage => currentPage - 1);
        }
    }, [crossTabData]);

    useEffect(() => {
        if (crossData) {
            const modifiedData = handleAddUniqueIdForCrossData(crossData);
            setCrossDataNew(modifiedData);
            setCrossReferenceData(modifiedData)
        }
    }, [crossData])

    useEffect(() => {
        const checkEmptyArr: any = [];
        forEach(crossScopeDataNew, (custData, customerValue) => {
            const filteredData = filter(custData, data => !isEmpty(data));
            if (isEmpty(filteredData)) {
                checkEmptyArr.push(customerValue);
                setcustomerOriginal((customerData: any) => {
                    const filteredCustomer = filter(customerData, ({ customer }) => {
                        return customer !== customerValue;
                    });
                    return filteredCustomer;
                })
            }
        })
        const modifiedData = omit(crossScopeDataNew, checkEmptyArr);
        setCrossScopeDataForGrid(modifiedData);
        if (isEmpty(modifiedData)) {
            setCrossScopeDataForGrid({});
            setcustomerOriginal([])
        }
    }, [crossScopeDataNew])

    useEffect(() => {
        if (crossScopeData) {
            const modifiedData = handleAddUniqueIdForScopeData(crossScopeData);
            setCrossScopeDataNew(modifiedData);
        }
    }, [crossScopeData])

    useEffect(() => {
        if (!isScopeLoading && availableScopes) {
            setavailableScopesResult(availableScopes)
        }
    }, [isScopeLoading, availableScopes])

    useEffect(() => {
        if (!isNewCrossScopeLoading) {
            if (newCrossScopeData && Object.entries(newCrossScopeData).length > 0) {
                const records = parseCrossAnalysisData(newCrossScopeData)
                const sortedData = records.sort((a, b) => a.initial! > b.initial! ? 1 : -1)
                setParsedData(sortedData);
            } else {
                setParsedData([]);
                setHiddenCustomers([]);
            }

        }
    }, [isNewCrossScopeLoading, newCrossScopeData]);

    const handleAddUniqueIdForCrossData = (crossData:any) => {
        const modifiedData = crossData.map((data: any) => {
            data["id"] = uuidv4();
            return data;
        })
        modifiedData.sort((a: any, b: any) => a.initial > b.initial ? 1 : -1);
        return modifiedData
    }

    const handleAddUniqueIdForScopeData = (crossScopeData:any) => {
        const modifiedDataArr: any = map(crossScopeData, (custData, customer) => {
            const custDataArr: any = [];
            custDataArr.push(customer);
            const newData: any = map(custData, (data: any) => {
                data["id"] = uuidv4();
                return data;
            });
            custDataArr.push(newData);
            return custDataArr;
        });
        const modifiedData = Object.fromEntries(modifiedDataArr);
        return modifiedData;
    }

    const getScopeData = () => {
        if (!useNewScopeBy) {
            return kcProductData
        }

        if (availableScopesResult) {
            if (selectedScopeLevel === Scope.CHANNEL) { return availableScopesResult.channels }
            if (selectedScopeLevel === Scope.CUSTOMER) { return availableScopesResult.customers }
        }
        return []
    }

    const handleSelectedValues = (kcObject: any) => {
        if (useNewScopeBy && selectedProductLevel === 'subBrand' && kcObject.dataFor !== "initial" && kcObject.dataFor !== "target") {
            setSelectedProductsForScope({
                selectedKcArr: kcObject.selectedKcArr,
                apiUrl: kcObject.apiUrl,
                selectedBrand: kcObject.selectedBrand,
                selectedManufacturer: kcObject.selectedManufacturer,
                selectedSubBrands: kcObject.selectedSubBrands,
                selectedPacks: kcObject.selectedPacks,
            })
        }
        if (kcObject.dataFor === "initial") {
            setSelectedKcProducts({
                ...selectedKcProducts,
                selectedKcArr: kcObject.selectedKcArr,
                crossAnalysisApiUrl: kcObject.crossAnalysisApiUrl,
                flag: true,
                selectedBrand: kcObject.selectedBrand,
                selectedManufacturer: kcObject.selectedManufacturer,
                selectedSubBrands: kcObject.selectedSubBrands,
                selectedPacks: kcObject.selectedPacks
            });
        } else {
            setSelectedTargetProducts({
                ...selectedTargetProducts,
                selectedKcArr: kcObject.selectedKcArr,
                crossAnalysisApiUrl: kcObject.crossAnalysisApiUrlT,
                flag: true,
                selectedBrand: kcObject.selectedBrand,
                selectedManufacturer: kcObject.selectedManufacturer,
                selectedSubBrands: kcObject.selectedSubBrands,
                selectedPacks: kcObject.selectedPacks
            });
        }
        if (!kcObject.selectedKcArr || kcObject.selectedKcArr.length === 0) {
            setParsedData([])
            setSubCustomerData([]);
            setCrossDataNew([]);
        }
    };

    const handleUnselect = (initial?: string, target?: string, id?: any) => {
        if (useNewScopeBy) {
            if (Object.keys(selectedProductsForScope).length > 0) {
                setSelectedProductsForScope({
                    ...selectedKcProducts,
                    selectedKcArr: selectedProductsForScope.selectedKcArr ? selectedProductsForScope.selectedKcArr.filter(x => x !== id) : []
                })
            }
        } else if (selectedScopeLevel === Scope.COUNTRY) {
            setParsedData(data => {
                const newData = data.filter(row => !(row.id === id))
                if (!newData.length) {
                    setSelectedKcProducts(initialProducts);
                    setSelectedTargetProducts(initialProducts);
                    setFilteredProducts([]);
                    setFilteredTargetProducts([])
                    setSubCustomerData([]);
                }
                return newData
            });
        } else {
            setCrossScopeDataNew((customerData: any) => {
                const modifiedDataArr: any = map(customerData, (custData, key) => {
                    const custDataArr: any = [];
                    custDataArr.push(key);
                    const newData: any = map(custData, (data, key) => data.id !== id ? data : {});
                    custDataArr.push(newData);
                    return custDataArr;
                });
                const modifiedData = Object.fromEntries(modifiedDataArr);
                return modifiedData;
            })
        }
    }

    const handleViewMore = (id: any, initial: string, target?: string, customer?: string, category?: string, subCategoryInitial?: string, subCategoryTarget?: string, manufacturerInitial?: string, manufacturerTarget?: string, brandInitial?: string, brandTarget?: string, subBrandInitial?: string, subBrandTarget?: string, packInitial?: string, packTarget?: string) => {
        const userSelectedWeekRangesCross = useStore.getState().userSelectedWeekRangesCross;
        const _source = useStore.getState().selectedSourceChoice;
        setKcProductCrossValue(
            `${initial.split("-")[0]}`
        );
        setTargetCrossValue(
            `${target?.split("-")[0]}`
        );
        setPriceElasticityTab("Cross");
        const pl = getHierarchyLevel(useStore.getState().selectedProductLevel)
        const weekRangeSelected = getSelectedWeekRange(userSelectedWeekRangesCross);
        let data = (userSelectedWeekRangesCross.length > 0)
            ? getCrossViewMoreData(id, selectedCountry, initial, target!, pl, _source, selectedScopeLevel, category!, subCategoryInitial!, subCategoryTarget!, manufacturerInitial!, manufacturerTarget!, brandInitial!, brandTarget!, subBrandInitial!, subBrandTarget!, packInitial!, packTarget!, customer!, selectedSubCategoryLevel!, weekRangeSelected)
            : getCrossViewMoreData(id, selectedCountry, initial, target!, pl, _source, selectedScopeLevel, category!, subCategoryInitial!, subCategoryTarget!, manufacturerInitial!, manufacturerTarget!, brandInitial!, brandTarget!, subBrandInitial!, subBrandTarget!, packInitial!, packTarget!, customer!, selectedSubCategoryLevel!);
        // Set data in localStorage to refer in View More page
        localStorage.setItem(id, JSON.stringify(data));
        window.open(`/price-elasticity/cross-dashboard?country=${selectedCountry}&id=${id}`);
    }

    const onClickClear = () => {
        setParsedData([]);
        setSubCustomerData([]);
        setSelectedKcProducts(initialProducts);
        setSelectedTargetProducts(initialProducts);
        setSelectedProductsForScope(initialProducts);
        setCrossTabPaginationCount(0);
        setCurrentPage(1);
        setUserSelectedWeekRangesCross([]);
        setHiddenCustomers([]);
        setCrossDataNew([]);
        setCrossScopeDataNew([]);
        setCrossScopeDataForGrid([]);
        setCrossScopeDataExclusions([]);
        setCrossReferenceData([]);
        setFilteredProducts([]);
        setFilteredTargetProducts([])
    };

    const onchange = (data: any) => {
        if (data[0] === "productLevel") {
            setSelectedProductLevel(data[1]);
            setSubCustomerData([])
            setcustomerOriginal([])
            setSelectedKcProducts(initialProducts);
            setSelectedTargetProducts(initialProducts);
            setSelectedProductsForScope(initialProducts);
            setUserSelectedWeekRangesCross([]);
            setCrossTabPaginationCount(0);
            setCurrentPage(1);
            setSelectedWeeksApply([])
            setHiddenCustomers([]);
            setCrossDataNew([]);
            setCrossScopeDataNew([]);
            setCrossScopeDataForGrid([]);
            setCrossScopeDataExclusions([]);
            setFilteredProducts([]);
            setFilteredTargetProducts([])
        } else if (data[0] === "categoryLevel") {
            setSelectedCategoryLevel(data[1]);
            setSelectedKcProducts(initialProducts);
            setSelectedTargetProducts(initialProducts);
            setSelectedProductsForScope(initialProducts);
            setSubCustomerData([])
            setcustomerOriginal([])
            setUserSelectedWeekRangesCross([]);
            setCrossTabPaginationCount(0);
            setCurrentPage(1);
            setSelectedWeeksApply([])
            setHiddenCustomers([]);
            setCrossDataNew([]);
            setCrossScopeDataNew([]);
            setCrossScopeDataForGrid([]);
            setCrossScopeDataExclusions([]);
            setFilteredProducts([]);
            setFilteredTargetProducts([])
        } else {
            setPriceType(data[1])
        }
    };

    const onScopeChange = (data: any) => {
        setSelectedScopeLevel(data);
        setSubCustomerData([])
        setcustomerOriginal([])
        setSelectedProductsForScope(initialProducts);
        setCrossTabData([])
        setSelectedKcProducts(initialProducts)
        setSelectedTargetProducts(initialProducts)
        setParsedData([])
        setCrossDataNew([]);
        setCrossScopeDataNew([]);
        setCrossScopeDataForGrid([]);
        setCrossScopeDataExclusions([]);
        setFilteredProducts([]);
        setFilteredTargetProducts([])
        // setproductsInComparison([])
    };

    const handleScopeLevelSort = (field: TableColumn<ElasticityRecord>, direction: string) => {
        const sorted = sortScopeData(subCustomerData, field, direction)
        setSubCustomerData(sorted)
    }

    const handleClickClose = () => {
        setcanShowProgress(false);
    };

    const handleCrossData = (currentPage: any) => {
        setCurrentPage(currentPage);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const filterCustomer = (names: string[]) => {
        setSubCustomerData(customerOriginal.filter(x => !names.includes(x.customer)))
    }

    const downloadOwnElasticity = async () => {
        const { selectedKcArr, selectedBrand, selectedManufacturer, selectedSubBrands, selectedPacks } = selectedKcProducts;
        const selectedHeirarchyLevel = getHierarchyLevel(selectedProductLevel);

        const timePeriods: any = [];
        let lineDataObj: any = {};
        if (userSelectedWeekRangesCross.length > 0) {
            const dataPoints: any = userSelectedWeekRangesCross ? userSelectedWeekRangesCross : [];
            if (lineFittingData === undefined) return;
            if (dataPoints !== undefined && dataPoints.length > 0 && dataPoints.length !== lineFittingData.length) {
                for (const val of dataPoints) {
                    if (val !== null && val !== undefined) {
                        let obj = val;
                        let key = obj.year.toString();
                        if (!lineDataObj[key]) {
                            lineDataObj[key] = [obj];
                        } else {
                            lineDataObj[key].push(obj);
                        }
                    }
                }

                for (const key in lineDataObj) {
                    const weekArray: any = lineDataObj[key];
                    const weeks: any = [];
                    weekArray.forEach((weekData: any) => {
                        if (weekData !== null && weekData !== undefined && weekData.week !== undefined) {
                            weeks.push(weekData.week);
                        }
                    })
                    const weekRange: any = {};
                    weekRange["year"] = key;
                    weekRange["weeks"] = weeks;
                    timePeriods.push(weekRange);
                }
            }
        }

        const crossElasticityDownloadData = {
            country: selectedCountry,
            source: source,
            scope: selectedScopeLevel,
            hierarchyLevel: selectedHeirarchyLevel,
            category: selectedCategoryLevel,
            forOwn: false,
            subCategories: selectedSubCategoryLevel,
            initialNodeValues: selectedKcArr,
            targetNodeValues: selectedTargetProducts.selectedKcArr,
            initialManufacturers: selectedManufacturer,
            targetManufacturers: selectedTargetProducts.selectedManufacturer,
            initialBrands: selectedHeirarchyLevel === 'BRAND' ? [] : selectedBrand,
            targetBrands: selectedHeirarchyLevel === 'BRAND' ? [] : selectedTargetProducts.selectedBrand,
            initialSubBrands: selectedHeirarchyLevel === 'SUB_BRAND' ? [] : selectedSubBrands,
            targetSubBrands: selectedHeirarchyLevel === 'SUB_BRAND' ? [] : selectedTargetProducts.selectedSubBrands,
            initialPacks: selectedHeirarchyLevel === 'PACK' ? [] : selectedPacks,
            targetPacks: selectedHeirarchyLevel === 'PACK' ? [] : selectedTargetProducts.selectedPacks,
            timePeriods: timePeriods
        }

        try {
            setisDownloading(true)
            const api = ApiRequestService.createInstance()
            const res: AxiosResponse = await api.elasticityFileDownload(crossElasticityDownloadData);
            const url = window.URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            const modelRun = lastModelRunData && lastModelRunData[selectedCountry] ? new Date(lastModelRunData[selectedCountry]).toLocaleDateString() : ''
            link.setAttribute('download', `${selectedCountry}_Elasticity_${modelRun}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setisDownloading(false)
        } catch (error) {
            setisDownloading(false)
            console.log('Own Elasticity download error')
            console.log(error)
        }
    }

    let disableDownloadbtn = (selectedCountry === '' || source === '' || selectedProductLevel === '' || selectedScopeLevel === '') ? true : isDownloading ? true : false;
    const productLevel = getHierarchyLevel(selectedProductLevel);
    const showEditWeeks = (selectedCountry === 'KR' ? !isEmpty(lineFittingData) : source.toLowerCase() !== 'pos' && (productLevel !== 'PACK' && productLevel !== 'BRAND' && productLevel !== 'SUB_CATEGORY')
        && (selectedScopeLevel === Scope.COUNTRY ? crossTabData && crossTabData.length > 0 : crossTabScopeData));

    return <div className="flex flex-col p-5">
        <ProductLevelSelector
            onchange={(e: any) => onchange(e)}
            onScopeChange={(e: any) => onScopeChange(e)}
            onSourceChange={(e: any) => onClickClear}
            tabName="cross"
            useNewScopeBy={useNewScopeBy}
            onSubCategoryChange={(e: any) => onClickClear()}
        />
        <div className="flex flex-row w-full justify-between my-5">
            <div>
                <button data-testid="clear-button" className="border border-primary text-primary rounded p-2 w-20 " onClick={() => onClickClear()}>Clear</button>
            </div>
            <div className="flex">
                {showEditWeeks && getHierarchyLevel(selectedProductLevel) !== 'CATEGORY' ? <div className={classes.btnContainer}>
                    <button onClick={() => setOpen(true)} className="border border-primary text-primary rounded p-1 ">Edit Weeks</button>
                </div> : null}
                <button className={`border border-primary text-primary rounded p-2 flex justify-center align-middle ${disableDownloadbtn ? 'cursor-not-allowed' : ''}`} onClick={disableDownloadbtn ? () => { return null } : () => downloadOwnElasticity()} disabled={disableDownloadbtn}>
                    {
                        isDownloading
                            ? <svg className="animate-spin ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#0000b3" stroke-width="4"></circle>
                                <path className="opacity-75" fill="#0000b3" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            : null
                    }
                    <span > Download</span>
                </button>
            </div>
        </div>
        {
            parsedData && parsedData.length > 0 && parsedData[0].modelRun
                ? <div className="text-right w-full">
                    <span className="text-primary">Last model update: <strong>{new Date(parsedData[0].modelRun).toLocaleDateString()}</strong> </span>
                </div>
                : null
        }
        {open ? <MemoizedWeekSelector
            open={open}
            originalDataInstance={originalDataInstance}
            treeDataInstance={treeDataInstance}
            handleClose={handleClose}
            tabName="cross"
            setOpen={setOpen}
            setIsEditWeeksClicked={setIsEditWeeksClicked}
            isEditWeeksClicked={isEditWeeksClicked}
            setUserSelectedWeekRangesCross={setUserSelectedWeekRangesCross}
        /> : null}
        <div className="w-full flex felx-col">
            <div className="block relative">
                <div className="top-48 left-8 absolute z-50">
                    {useNewScopeBy && canShowSearch && selectedProductLevel === 'subBrand' ?
                        <NestedSearch
                            data={kcProductData}
                            handleSelectedValues={handleSelectedValues}
                            tabName="own"
                            dataFor=""
                            clear={clear}
                            handleClear={() => setClear(false)}
                            id="searchOwn"
                            onMouseLeave={() => setcanShowSearch(false)}
                            previouslySelected={selectedProductsForScope.selectedKcArr}
                        />
                        : null
                    }
                </div>
            </div>
            {selectedScopeLevel === Scope.COUNTRY || useNewScopeBy ?
                <CrossCountryScope
                    records={crossTabData}
                    selectedCountry={selectedCountry}
                    priceType={priceType}
                    handleViewMore={handleViewMore}
                    unselectRow={handleUnselect}
                    loading={isCrossDataLoading}
                    initialProductData={getScopeData()}
                    handleSelectedValues={handleSelectedValues}
                    source={source as Source}
                    selectedCategoryLevel={selectedCategoryLevel}
                    selectedProductLevel={selectedProductLevel}
                    selectedProducts={!isEmpty(selectedSubCategoryLevel) && !isEmpty(filteredProducts) ? filteredProducts : selectedKcProducts.selectedKcArr || []}
                    selectedTargetProducts={!isEmpty(selectedSubCategoryLevel) && !isEmpty(filteredTargetProducts) ? filteredTargetProducts : selectedTargetProducts.selectedKcArr || []}
                    pageData={selectedScopeLevel === Scope.COUNTRY ? crossData : crossScopeData}
                    dataCount={crossTabDataCount}
                    scope={selectedScopeLevel as Scope}
                    handlePageData={handleCrossData}
                    useNewScopeBy={useNewScopeBy}
                    addProductClick={() => setcanShowSearch(!canShowSearch)}
                    selectedTab="cross"
                    crossScopeDataExclusions={crossScopeDataExclusions}
                    crossScopeDataExclusionMessage={crossScopeDataExclusionMessage}
                />
                : <CrossMarketScope
                    records={crossTabScopeData}
                    selectedCountry={selectedCountry}
                    customSort={handleScopeLevelSort}
                    filterCustomer={filterCustomer}
                    allCustomers={customerOriginal.map(x => x.customer)}
                    handleViewMore={handleViewMore}
                    unselectRow={handleUnselect}
                    loading={isCrossScopeLoading}
                    initialScopeData={kcProductData}
                    scope={selectedScopeLevel as Scope}
                    handleSelectedValues={handleSelectedValues}
                    priceType={priceType}
                    source={source as Source}
                    selectedProducts={!isEmpty(selectedSubCategoryLevel) && !isEmpty(filteredProducts) ? filteredProducts : selectedKcProducts.selectedKcArr || []}
                    selectedTargetProducts={!isEmpty(selectedSubCategoryLevel) && !isEmpty(filteredTargetProducts) ? filteredTargetProducts : selectedTargetProducts.selectedKcArr || []}
                    pageData={selectedScopeLevel === Scope.COUNTRY ? crossData : crossScopeData}
                    dataCount={crossTabDataCount}
                    handlePageData={handleCrossData}
                    setHiddenCustomersArray={(customerData: any) => setHiddenCustomers(customerData)}
                    useNewScopeBy={useNewScopeBy}
                    selectedCategoryLevel={selectedCategoryLevel}
                    selectedProductLevel={selectedProductLevel}
                    crossScopeDataExclusions={crossScopeDataExclusions}
                    crossScopeDataExclusionMessage={crossScopeDataExclusionMessage}
                    userSelectedWeekRangesCross={userSelectedWeekRangesCross}
                />
            }
        </div>
        <Dialog
            open={canShowProgress}
            keepMounted
            onClose={handleClickClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle className="pt-0">
                {"Your request is being processed"}
            </DialogTitle>
        </Dialog>
    </div>

}