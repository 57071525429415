import PostRoiTable from "../DashboardTab/PostRoiTable";
import { ApiRequestService } from '../../services/api-service';
import {MemoizedPostRoiReport} from "../Chart/PostRoiChart"
import Button from '@mui/material/Button';
import useStore from "../../store";

function DashBoardTab(props) {
    let payloadforPOSTROIFilters = useStore(
        (state) => state.payloadforPOSTROIFilters
    );

    const downloadFile = async () => {
        try {
         // setIsROIFileDownloading(true);
          const apiRequestService = ApiRequestService.createInstance();

          const res = await apiRequestService.downloadPostRoi(payloadforPOSTROIFilters)
          const url = window.URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'PromoPerformance.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          //setIsROIFileDownloading(false);
        } catch (error) {
          console.log('ROI download error')
          console.log(error)
        }
      }
    return(
        <>
        <div>
        <MemoizedPostRoiReport reportID={process.env.REACT_APP_POSTROI_2_REPORT_ID} pageName={process.env.REACT_APP_POSTROI_2_PAGENAME} />
        </div>
        <div style={{float:'right',marginTop:"10px",marginRight:"20px",position:'absolute',right:'30px' }}><Button onClick={()=>{downloadFile()}} variant="contained" >Download</Button></div>
        <PostRoiTable/>
        </>
    );
}

export default DashBoardTab;