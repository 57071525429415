import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import { DropDown } from "../icons/dropdown";
import Toaster from '../../commonMuiComponents/Toaster'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircle from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@material-ui/core/styles';


type PromoTypeProps = {
  preROIPromoDataNew: { [key: string]: boolean };
  selectedPromoVal: { [key: string]: string };
  setSelectedPromoVal: Function;
  setHeight: Function,
  listRef: any,
  setIsDelete: Function,
  disabled?: boolean
}

const useStyles = makeStyles(theme => ({

  adBtn: {
    position: 'relative',
    top: '5px',
    left: '2px',
    cursor: 'pointer'
  },
  magicBtn : {
    display: 'inline',  
    marginLeft: '10px',   
    top: '-3px',
    position: 'relative',
    cursor: 'pointer'
  }

}))

export const PromoType = ({ preROIPromoDataNew, selectedPromoVal,
  setSelectedPromoVal, setHeight, listRef, setIsDelete ,  disabled=false}: PromoTypeProps) => {

  const [showAlert, setShowAlert] = useState({message: '', type: ''})

  const [showList, setShowList] = useState(false);

  const classes = useStyles();

  const [expanded, setExpanded] = useState<string | false>(false);

 

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const addthisValue = (name: string) => {
    console.log("btn clicked", name);
    let doc: any = document.getElementById(name);
    console.log("value", doc.value);

    if (doc.value > 0) {
      setSelectedPromoVal({ ...selectedPromoVal, [name]: doc.value });
    } else {
      setShowAlert({
        type: 'error',
        message: "You need to enter positive values"
      })
    }
  };

  const handleDelete = (name: string) => {
    setIsDelete(true);
    let demtemp = { ...selectedPromoVal };
    delete demtemp[name];
    setSelectedPromoVal(demtemp);
    if (Object.entries(demtemp).length >= 3) {
      let newHeight = listRef?.current.clientHeight;
      newHeight = parseInt(newHeight) - 40 + "px";
      setHeight({ height: newHeight });
    }
  };


  const [orderedPreROIPromoDataNew, setOrderedPreROIPromoDataNew] = useState({});

  useEffect(() => {
    if (preROIPromoDataNew && Object.keys(preROIPromoDataNew).length > 0) {
      let val = Object.keys(preROIPromoDataNew).sort().reduce(
        (obj: any, key: any) => {
          obj[key] = preROIPromoDataNew[key]
          return obj;
        }, {}
      );
      setOrderedPreROIPromoDataNew(val);
    }

  }, [preROIPromoDataNew]);


  const inputValidate = (e: any) => {
    if (e.target.value < 0) {
      setShowAlert({
        type: 'error',
        message: "Enter positive values only"
      })
    }

  }

  const handleSuggestionClose = () =>{
    setShowList(false);
  }
 
  //calling custom hooks
  const searchContainer = useRef(null);
  useSearchSuggestionClose(searchContainer, handleSuggestionClose);


  return (
    <>
    {preROIPromoDataNew && (

    
      <div className="flex flex-col bg-white  right-0" ref={searchContainer}>
       
        <div className="flex h-10 border border-gray-300 justify-center  relative  p-1">
          {selectedPromoVal  && Object.keys(selectedPromoVal).length === 0 && (
            <Button
              className="bg-white border border-primary text-primary rounded text-sm p-2"
              onClick={() => setShowList(!showList)}
              disabled={disabled}
            >
              Add promo type
            </Button>
          )}
 
          {preROIPromoDataNew && selectedPromoVal && Object.keys(selectedPromoVal).length != 0 && (
            <>
           
              <div
                onClick={() => setShowList(!showList)}
                className="text-sm flex rounded-lg  p-1 text-white cursor-pointer bg-primary  mx-3 items-center gap-1"
                key={Object.keys(selectedPromoVal)[0]}
              >
                {`${Object.keys(selectedPromoVal)[0]}`}
                {
                  (selectedPromoVal[Object.keys(selectedPromoVal)[0]] && preROIPromoDataNew[Object.keys(selectedPromoVal)[0]]) &&
                  <span className="bg-white text-primary p-1 rounded-lg">{`${selectedPromoVal[Object.keys(selectedPromoVal)[0]]}${preROIPromoDataNew[Object.keys(selectedPromoVal)[0]] ? '' : '%'}`}</span>
                }
                <CancelIcon onClick={() => handleDelete(Object.keys(selectedPromoVal)[0])} className="promo-cross" />
              </div>
            

            </>
          )} 


          { !disabled &&
            <span onClick={() => setShowList(!showList)}>
              <DropDown color={'blue'} className={'downArrow'} />
            </span>
          }
         

          {showList && (
            <div
              className="absolute bg-white top-10 flex flex-col border shadow border-primary rounded z-10  gap-y-1 py-1"
              
              //onMouseLeave={() => setShowList(false)}
            >

              {selectedPromoVal && Object.keys(selectedPromoVal).length != 0 && (
                Object.keys(selectedPromoVal).map((chipValue: any) => (

                  
                    <div
                      onClick={() => setShowList(!showList)}
                      className="text-sm flex rounded-lg  p-1  text-white items-center cursor-pointer promo-chip-main chips-list bg-primary gap-1 mx-3 justify-end"
                      key={chipValue}
                    >
                      {`${chipValue}`}
                      <span className="bg-white text-primary p-1 rounded-lg">{`${selectedPromoVal[chipValue]}${preROIPromoDataNew[chipValue] ? '' : '%'}`}</span>
                      <CancelIcon onClick={() => handleDelete(chipValue)} className="promo-cross" />
                    </div>
                 

                ))
              )} 


              {orderedPreROIPromoDataNew &&
                Object.keys(orderedPreROIPromoDataNew).map((item: any) => {
                  return !selectedPromoVal[item] &&
                    <Accordion
                      expanded={expanded === item}
                      onChange={handleChange(item)}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon style={{ color: "blue" }} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography ><span className="text-sm">{item}</span></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="flex bg-white rounded h-10 items-left">
                          <input
                            type="number"
                            placeholder="input value"
                            className="bg-white rounded h-10 items-center px-2 focus:border focus:border-blue-500 placeholder:text-xs w-full  outline-none shadow border"
                            id={item}
                            value={selectedPromoVal[item] as string}
                            min="0"
                            onChange={(e) => inputValidate(e)}
                          />

                          <AddCircle
                            style={{ color: "blue" }}
                            onClick={() => addthisValue(item)}
                            className={classes.adBtn}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>

                })
              }
            </div>
          )}


        </div>

      </div>

)}
    <Toaster showAlert={showAlert} />
    </>
  );
};


const useSearchSuggestionClose = (ref:any, handleSuggestionClose: Function) => {
  useEffect(()=> {
    function handleClickOutSide(event: any){
      //handleSuggestionClose();
        if(ref.current && !ref.current.contains(event.target)){
          handleSuggestionClose();
        }
    }

    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    }
  },[handleSuggestionClose, ref])
};