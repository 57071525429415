import React, { useEffect, useState } from "react";
import {
  useCustomerHierarchy,
  useProductHierarchy,
  useTokenBI,
} from "./services/queries";
import { makeStyles } from "@material-ui/core/styles";
import { productDataJson } from "./ProductJson";
import Grid from "@material-ui/core/Grid";
import AppTabs from "./AppTabs";
import Paper from "@material-ui/core/Paper";
import CustomSearch from "./CustomSearch";
import ProductCustomSearch from "./productCustomSearch";
import PowerBIReport from "./PowerBIReport";
import DateSelection from "./dateSelection";
import MonthlySelection from "./monthlySelection";

import useStore from "./store";
const drawerWidth = 340;
//import ProductData from './ProductMockData';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
    width: 345,
  },
  appBar: {
    backgroundColor: theme.palette.primary.light,
  },
  media: {
    height: 140,
  },
  title: {
    color: theme.palette.primary.main,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
  },
  paperDiv: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
      height: theme.spacing(12),
    },
  },

  chartDiv: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
      height: theme.spacing(100),
    },
  },

  defaultTxt: {
    padding: "20px",
    margin: "15px auto",
    fontSize: "1.3rem",
    textAlign: "center",
  },

  dateLinks: {
    cursor: "pointer",
    fontWeight: 700,
    marginLeft: "20px",
  },
  dateLinksSpan: {
    marginRight: "10px",
    marginLeft: "10px",
    paddingBottom: "5px",
  },
  dateLinksSpanActive: {
    color: "#0000b3",
  },
  datedefaultText: {
    marginRight: "40px",
  },
  dataDiv: {
    padding: theme.spacing(1),
    width: 390,
    backgroundColor: theme.palette.background.light,
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    height: "35rem",
  },
  note: {
    alignSelf: "centre",
  },
  clearAll: {
    color: "#0000b3",
    opacity: 0.8,
    fontWeight: 400,
    cursor: "pointer",
    marginLeft: "15px",
  },
  frequency: {
    marginLeft:'20px',
    marginTop:'5px',
    textDecoration:'underline'
  }
}));

const BaseLineLayout = () => {
  const classes = useStyles();
  const productDataJsonNew = productDataJson.map((data, key) => {
    return data.countries.PE.categories;
  });

  //store
  let selectedCountry = useStore((state) => state.selectedCountry);

  const { isLoading: isCustomerData, data: customerData } =
    useCustomerHierarchy(`${selectedCountry}&feature=Baseline Forecasting`);

  const { isLoading: isSellInCustomerData, data: sellInCustomerData } =
    useCustomerHierarchy(`${selectedCountry}&source=SELL IN&feature=Baseline Forecasting`);

  const { isLoading: isProductData, data: productData } =
    useProductHierarchy(selectedCountry, "Baseline Forecasting");

  const setSelectedBaseLineProductNew = useStore(
    (state) => state.setSelectedBaseLineProductNew
  );
  const setSelectedBaseLineCustomerNew = useStore(
    (state) => state.setSelectedBaseLineCustomerNew
  );

  const [kcProductData,setKcProductData]= useState();

  useEffect(() => {
    if (!isProductData && selectedCountry !== undefined && productData) {
      console.log("productData[sc]:",productData['countries'][selectedCountry]);
      setKcProductData(productData['countries'][selectedCountry]);
    }
  }, [isProductData]);


  let lastYear = new Date().getFullYear() - 1;
  let currentYear = new Date().getFullYear();

  //store
  const setViewMonthly = useStore((state) => state.setViewMonthly);
  const setSelectedYear = useStore((state) => state.setSelectedYear);

  let setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );
  let selectedBaseLineCustomerNew = useStore(
    (state) => state.selectedBaseLineCustomerNew
  );
  let selectedBaseLineProductNew = useStore(
    (state) => state.selectedBaseLineProductNew
  );

  const [clear,setClear]=useState(false);

  //for clearing all
  const clearData = () => {
    setSelectedBaseLineProductNew("");
    setSelectedBaseLineCustomerNew("");
    setSelectedBaseLineFilter("");
    setClear(true);
  };

  const [clearProduct, setClearProduct ]=useState(false);
  const [clearCustomer, setClearCustomer ]=useState(false)

  const countryAndRole = useStore((state) => state.countryAndRole);


  const clearProductData = () => {
    setSelectedBaseLineProductNew("");
    setSelectedBaseLineFilter(selectedBaseLineCustomerNew);
    setClearProduct(true);
  }
  const clearCustomerData = () => {
    setSelectedBaseLineCustomerNew("");
    setSelectedBaseLineFilter(selectedBaseLineProductNew);
    setClearCustomer(true);
  }
  const resetProduct = () =>{
    setClearProduct(false);
  }
  const resetCustomer = () =>{
    setClearCustomer(false);
  }
  const setSelectedCustomerValues = useStore(
    (state) => state.setSelectedCustomerValues
  );
  const setSelectedSource = useStore(
    (state) => state.setSelectedSource
  );

  let selectedProductValues = useStore(
    (state) => state.selectedProductValues
  );
  let selectedCustomerValues = useStore(
    (state) => state.selectedCustomerValues
  );
  //SelectedYear is an array to handle both option
  useEffect(() => {
    setViewMonthly("monthly");
    setSelectedYear([currentYear]);
  }, []);

  const handleSelectedYear = (value) => {
    setSelectedYear(value);
  };
  const handleSelectedFrequency = (value) => {
    setViewMonthly(value);
  };

  const [subTabs, setsubTabs] = useState([
    { index: 0, tag: "Adjusted baseline", value: "BaseLine", id:'BaseLine' },
    {
      index: 1,
      tag: "Impactors history",
      value: "ImpactorsHistory",
      id:'ImpactorsHistory'
    },
    {
      index: 2,
      tag: 'Impactors history(Delete)',
      value: 'ImpactorsHistoryDelete',
      id: 'ImpactorsHistoryDelete',
    },
  ])
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    if (!countryAndRole) return;
    const roles = countryAndRole[selectedCountry]
    if (roles && roles.includes('Admin')) {
      setCanEdit(true)
      const exist = subTabs.find(x=>x.index === 3)
      if (!exist
        && selectedProductValues.length
        && selectedCustomerValues.length){
        setsubTabs([...subTabs,{
          index: 3,
          tag: 'New Impactors',
          value: 'newImpactors',
          id: 'newImpactors',
        }])
      } else if(!selectedProductValues.length || !selectedCustomerValues.length) {
        const withoutNewImpactors = subTabs.filter(x=>x.index !== 3)
        setsubTabs(withoutNewImpactors)
      }
    } else {
      setCanEdit(false)
      const withoutNewImpactors = subTabs.filter(x=>x.index !== 3)
      setsubTabs(withoutNewImpactors)
    }
  }, [countryAndRole, 
      selectedCountry,
      selectedProductValues,
      selectedCustomerValues])

  return (
    <Grid item xs={12} sm={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <div className={classes.paperDiv}>
            <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
              Customer
              <span id="clearCustomer" className={classes.clearAll} onClick={() => clearCustomerData()}>
                Clear
              </span>
            </div>

            {!isCustomerData && !isSellInCustomerData && customerData && (
              <CustomSearch data={customerData} sellInData={sellInCustomerData} dataFor="customer" tabName="baseline" clear={clearCustomer} reset={resetCustomer} />
            )}

            {(isCustomerData || isSellInCustomerData) && (
              <>
                <CustomSearch dataFor="customer" tabName="baseline" clear={clearCustomer} reset={resetCustomer} /> Loading...!
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.paperDiv}>
            <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
              Product
              <span id="clearProducts" className={classes.clearAll} onClick={() => clearProductData()}>
                Clear
              </span>
            </div>

            {!isProductData && kcProductData && (
              <>
                <CustomSearch data={kcProductData} dataFor="Product" tabName="baseline" clear={clearProduct} reset={resetProduct} />

              </>
            )}

            {isProductData && (
              <>
                <CustomSearch dataFor="Product" tabName="baseline" clear={clearProduct} reset={resetProduct} /> Loading...!
              </>
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.frequency}>
            <strong>Frequency</strong>
          </div>
        </Grid>


        <Grid item xs={12} sm={6} md={3} lg={2}>
          <div style={{ display: "flex" }}>
            <MonthlySelection selectedFrequency={handleSelectedFrequency} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div style={{ display: "flex" }}>
            <DateSelection selectedYear={handleSelectedYear} />
          </div>
        </Grid>






        <Grid item xs={12} sm={12}>
          <div className={classes.chartDiv} id="chartDiv">
            <Paper className={classes.paper}>
              {/*   <AppTabs
                                tabs={[
                                    { index: 0, tag: 'Cases' , value: 'Case'},
                                    { index: 1, tag: 'GSU' },
                                ]}
                                logo={null}
                            /> */}

              {selectedCountry && <PowerBIReport />}

              {!selectedCountry && (
                <p className={classes.defaultTxt}>
                  Please select country to view Reports/Graph.
                </p>
              )}
            </Paper>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} className={classes.posRel}>
          <div className={classes.chartDiv}>
            <Paper className={classes.paper}>
              <AppTabs
                tabs={subTabs}
                logo={null}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BaseLineLayout;
