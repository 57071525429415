import React, { useEffect, useState } from "react";
import { Grid, Box, TextField, Button } from "@mui/material"
import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import { CircularProgress, makeStyles } from '@material-ui/core';
import { usePromoNameCheck } from "../../../../services/mutations";
import DoneAllIcon from '@mui/icons-material/DoneAll';

import useStore from "../../../../store";

export const PromoNameInput = ({ id, promoData, setPromoNameError, promoNameError }: any) => {
  const classes = useStyles();
  const [payLoad, setPayLoad] = useState({
    "country": "KR",
    "promoName": ""
  })
  const { isLoading: isPromoNameCheckLoading, mutate: validatePromoName, data: prompNameResponse, isSuccess: promoNameCheckSuccess, isError: successError } =
    usePromoNameCheck(payLoad);
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);

  const [promoName, setPromoName] = useState(promoSimulatorTabs[id].promoName);
  const [isPromoNameValid, setIsPromoNameValid] = useState(true);
  const [enablePromoName, setEnbaleProductName] = useState(false);

  const onPromoNameChange = (newName: string) => {
    setPromoNameError(false);
    if (newName.length <= 100) {
      setPromoName(newName);
    }
  };

  useEffect(() => {
    if(promoData && promoData.name && selectedPromoDetails.fromView) {
      setPromoName(promoData.name);
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, promoName: promoData.name, reset:false } : tab))
    }
  }, [])

  useEffect(() => {
    if(selectedPromoDetails.type !== 'view') {
      if (promoName.length > 0 && promoNameCheckSuccess) {
        setPromoNameError(false);
        if (prompNameResponse && prompNameResponse.data && prompNameResponse.data.uniqueName && promoNameCheckSuccess) {
          setIsPromoNameValid(true);
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, promoName: promoName, reset: false } : tab));
  
        } else {
          setIsPromoNameValid(false)
        }
      } else {
        if (promoName.length === 0) {
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, promoName: promoName } : tab));
        }
      }
    }
  }, [prompNameResponse])

  useEffect(() => {
    setPromoName(promoSimulatorTabs[id].promoName);
    if(promoSimulatorTabs[id].promoName && promoSimulatorTabs[id].calculateButtonClicked) {
      setEnbaleProductName(true);
    }
  }, [promoSimulatorTabs])

  useEffect(() => {
    if(promoData && promoData.name && selectedPromoDetails.fromView && selectedPromoDetails.type === 'view') {
      setPromoName(promoData.name);
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, promoName: promoData.name, reset:false } : tab))
    } else {
      if(promoData && promoData.name && selectedPromoDetails.fromView && selectedPromoDetails.type === 'duplicate') {
        setEnbaleProductName(false);
      }
    }
  }, [selectedPromoDetails, promoData])

  const checkPromoName = (newName: string) => {
    if (newName?.length > 0) {
      validatePromoName({
        "country": "KR",
        "promoName": newName
      })
    }
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <Box sx={{ fontWeight: '500', m: 1, fontSize: '0.75rem' }}>Promo Name<span className="text-red-900 font-bold text-base">*</span></Box>
      </Grid>
      <Grid item xs={9}>
        <div style={{ display: "flex" }}>
          <TextField
            className={classes.fieldWidth}
            error={!isPromoNameValid}
            size="small"
            disabled={selectedPromoDetails.type === 'duplicate' ? enablePromoName : promoSimulatorTabs[id].calculateButtonClicked || selectedPromoDetails.type === 'view'}
            value={promoName}
            onChange={e => onPromoNameChange(e.target.value)}
            onBlur={e => checkPromoName(e.target.value)}
            placeholder="Add your Promo Name here..."
            helperText={!isPromoNameValid ? "Promo name Must be Unique" : ""}
            id="promo-name"
          />
          <div style={{ margin: '8px' }}>
            {isPromoNameCheckLoading && <CircularProgress size=".85rem" />}
          </div>
        </div>
        {promoNameError ? <div>
          <span style={{fontSize: '0.75rem', color: 'rgb(211, 47, 47)', lineHeight: '1.66px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, 
            letterSpacing: '0.03333em'}}
          >
            Please enter Promo Name
          </span>
        </div> : null}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  fieldWidth: {
    width: '27rem',
    fontSize: '0.75rem',
    '& input': {
      fontSize: '0.75rem',
    }
  },
  successBtn: {
    backgroundColor: '#52c41a !important',
    padding: '8px !important',
    minWidth: '35px !important',
    marginLeft: '15px !important',
    borderRadius: '50% !important'
  }
}));