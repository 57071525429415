import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import NestedSearch from "../NestedSearch";
import useStore from "../../store";
import useClickOutside from "../../ClickOutside";
import CorrelationGrid from "./CorrelationGrid";
import AppPagination from '../Pagination';
import ErrorMessage from "../ErrorMessage";
import ProductLevelSelector from "../Widgets/ProductLevelSelector";
import { useCorrelationAnalysis } from "../../services/queries";
import { getHierarchyLevel, paginationPerPageDataLimit } from "../utils";
import { useKcProductHierarchy, useTargetProductHierarchy } from "../../services/queries";

export default function CorrelationTab(props) {

  const selectedCustomer = useStore((state) => state.selectedCustomer);
  const setSelectedCustomer = useStore((state) => state.setSelectedCustomer);
  const setSelectedKcProducts = useStore((state) => state.setSelectedKcProducts);
  const selectedScopeLevel = useStore((state) => state.selectedScopeLevel ? state.selectedScopeLevel : "WC");
  const setSelectedTargetProducts = useStore((state) => state.setSelectedTargetProducts);
  const selectedProductLevel = useStore((state) => state.selectedProductLevel ? state.selectedProductLevel : "ean");
  const setSelectedProductLevel = useStore((state) => state.setSelectedProductLevel);
  const source = useStore((state) => state.selectedSourceChoice);
  const selectedCountry = useStore((state) => state.selectedCountry);
  const setSelectedCategoryLevel = useStore((state) => state.setSelectedCategoryLevel);
  const selectedCategoryLevel = useStore((state) => state.selectedCategoryLevel);
  const setSelectedScopeLevel = useStore((state) => state.setSelectedScopeLevel);
  const setInitialValueCorrelationUrl = useStore((state) => state.setInitialValueCorrelationUrl);
  const setTargetValueCorrelationUrl = useStore((state) => state.setTargetValueCorrelationUrl);
  const selectedSubCategoryLevel = useStore(state => state.selectedSubCategoryLevel);

  const [canShowCustomerFilter, setCanShowCustomerFilter] = useState(false);
  const [tabsToShow, setTabsToShow] = useState([]);
  const [tabsToHide, setTabsToHide] = useState([]);
  const [sortedAllCustomers, setSortedAllCustomers] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [correlationDataNew, setCorrelationDataNew] = useState([]);
  const [correlationTabPaginationCount, setCorrelationTabPaginationCount] = useState(0);
  const [correlationInitialProducts, setCorrelationInitialProducts] = useState([]);
  const [correlationAnalysisPayload, setCorrelationAnalysisPayload] = useState({});
  const [initialProductsObject, setInitialProductsObject] = useState(null);
  const [targetProductsObject, setTargetProductsObject] = useState(null);
  const [initialProductSelected, setInitialProductSelected] = useState([]);
  const [targetProductSelected, setTargetProductSelected] = useState([]);
  const [clear, setClear] = useState(false);
  const [openXAxis, setOpenXAxis] = useState(false);
  const [openYAxis, setOpenYAxis] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [posCorrelationData, setPosCorrelationData] = useState([]);
  const [elasticityCol, setElasticityCol] = useState({
    avgPrice: true,
    promoPrice: false,
    basePrice: false,
  });
  const [showCustomers, setShowCustomers] = useState([]);
  const [targetProductHierarchyPayload, setTargetProductHierarchyPayload] = useState(null);
  
  const headCells = [];
  
  const { isLoading: isKcProductDataLoading, data: kcProductData } = useKcProductHierarchy(`${selectedCountry}${source ? `&source=${source}` : ''}`, getHierarchyLevel(selectedProductLevel));
  const { isSuccess: haveTargetData, data: targetData } = useTargetProductHierarchy(targetProductHierarchyPayload);
  const { isLoading: isCorrelationDataLoading, data: correlationData } = useCorrelationAnalysis(correlationAnalysisPayload);

  const tableStyle = {
    headCells: {
      style: {
        display: 'flex',
        justifyContent: 'center'
      }
    }
  };

  //Perform action on clicking outside
  let domNodeX = useClickOutside(() => { setOpenXAxis(false); });
  let domNodeY = useClickOutside((flag) => { if (flag) { onClickClear(); } setOpenYAxis(false); })

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(getErrorMessageValue())
    }
  }, [selectedCategoryLevel, source, selectedProductLevel])

  useEffect(() => {
    const data = {
      category: selectedCategoryLevel,
      country: selectedCountry,
      hierarchyLevel: getHierarchyLevel(selectedProductLevel),
      initialNodeValues: initialProductSelected,
      targetNodeValues: targetProductSelected,
      scope: selectedScopeLevel,
      source: source,
      subCategories: selectedSubCategoryLevel,
      targetPacks: targetProductsObject?.targetPacks,
      targetSubBrands: targetProductsObject?.targetSubBrands,
      targetTiers: targetProductsObject?.targetTiers,
      initialManufacturers: initialProductsObject?.initialManufacturers,
      initialBrands: initialProductsObject?.initialBrands,
      initialSubBrands: initialProductsObject?.initialSubBrands,
      targetManufacturers: targetProductsObject?.targetManufacturers,
      targetBrands: targetProductsObject?.targetBrands
    }
    setCorrelationAnalysisPayload(data);
  }, [selectedCountry, selectedProductLevel, initialProductSelected, targetProductSelected, selectedScopeLevel]);

  useEffect(() => {
    setInitialProductSelected([]);
    setTargetProductSelected([]);
    setOpenXAxis(false);
    setOpenYAxis(false);
  }, [selectedProductLevel, selectedCountry]);

  useEffect(() => {
    setInitialValueCorrelationUrl("");
    setTargetValueCorrelationUrl("");
    setPosCorrelationData('');
    clearGrid();
  }, []);

  useEffect(() => {
    if (!isCorrelationDataLoading && initialProductSelected && targetProductSelected && correlationData) {
      source === "POS" && parseCorrelationData(correlationData.data);
      setCorrelationDataNew(correlationData);
    } else {
      clearGrid();
    }
  }, [isCorrelationDataLoading, correlationData, initialProductSelected, targetProductSelected]);

  useEffect(() => {
    const startIndex = currentPage * paginationPerPageDataLimit - paginationPerPageDataLimit;
    const endIndex = startIndex + paginationPerPageDataLimit;
    if (initialProductSelected) {
      setCorrelationTabPaginationCount(initialProductSelected.length);
      setCorrelationInitialProducts(initialProductSelected.slice(startIndex, endIndex));
    } else {
      setCorrelationTabPaginationCount(0);
      setCorrelationInitialProducts([]);
    }
  }, [currentPage, correlationData, selectedCustomer, initialProductSelected, correlationDataNew]);

  useEffect(() => {
    syncSelectedCustomerWithTab();
  }, [tabsToHide, tabsToShow]);

  function clearGrid() {
    setPosCorrelationData([]);
    setCorrelationDataNew([]);
    setSortedAllCustomers([]);
    setSelectedCustomer("");
  };

  const onchange = (data) => {
    if (data[0] === "productLevel") {
      setSelectedProductLevel(data[1]);
      setSelectedKcProducts("");
      setSelectedTargetProducts("");
    } else if (data[0] === "categoryLevel") {
      setSelectedCategoryLevel(data[1]);
    } else {
      setElasticityCol(data[1]);
    }
    clearGrid();
    onClickClear();
  };

  const onScopeChange = (data) => {
    setSelectedScopeLevel(data);
    setSelectedKcProducts("");
    setSelectedTargetProducts("");
  };

  const onClickClear = () => {
    setInitialProductSelected([]);
    setTargetProductSelected([]);
    setInitialValueCorrelationUrl("");
    setTargetValueCorrelationUrl("");
    setInitialProductsObject(null);
    setTargetProductsObject(null);
    setClear(true);
    clearGrid();
  };

  const handleFilterAllClick = (e) => {
    if (!e.target.checked) {
      setTabsToHide(sortedAllCustomers);
      setTabsToShow([]);
    }
    else {
      setTabsToShow(sortedAllCustomers);
      setTabsToHide([]);
    };
  };

  const handleTargetDeselectAll = () => {
    setTargetProductSelected([]);
  };

  const handlePageData = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const handleSelectedValues = (kcObject) => {
    if (kcObject.dataFor === "target") {
      setTargetProductSelected(kcObject.selectedKcArr);
      setTargetValueCorrelationUrl(kcObject.correlationAnalysisApiUrlT);
      setTargetProductsObject({
        targetManufacturers: kcObject?.selectedManufacturer ?? [],
        targetBrands: kcObject?.selectedBrand ?? [],
        targetPacks: kcObject?.selectedPacks ?? [],
        targetSubBrands: kcObject?.selectedSubBrands ?? [],
        targetTiers: kcObject?.selectedTiers ?? []
      });
    } else {
      setInitialProductSelected(kcObject.selectedKcArr);
      let apiURL = kcObject.apiUrl;
      if (selectedScopeLevel) {
        apiURL = `${apiURL}&scope=${selectedScopeLevel}`
      }
      if (source) {
        apiURL = `${apiURL}&source=${source}`
      }
      let payload = {
        country: selectedCountry,
        scope: selectedScopeLevel,
        source: source,
        leafValues: kcObject.selectedKcArr,
        levelIndicator: getHierarchyLevel(selectedProductLevel)
      }
      setTargetProductHierarchyPayload(payload);
      setInitialValueCorrelationUrl(kcObject.correlationAnalysisApiUrl);
      setInitialProductsObject({
        initialManufacturers: kcObject?.selectedManufacturer ?? [],
        initialBrands: kcObject?.selectedBrand ?? [],
        initialPacks: kcObject?.selectedPacks ?? [],
        initialSubBrands: kcObject?.selectedSubBrands ?? [],
        initialTiers: kcObject?.selectedTiers ?? []
      });
      if (!kcObject.selectedKcArr.length) {
        setTargetProductSelected([]);
        setTargetValueCorrelationUrl("");
        setInitialProductsObject(null);
        setTargetProductsObject(null);
      }
    }
  };

  const handleCustomerSelectClick = (e, customer) => {
    if (e.target.checked) {
      setTabsToShow([...tabsToShow, customer]);
      setTabsToHide(tabsToHide.filter(item => item !== customer));
    }
    else {
      setTabsToShow(sortedAllCustomers.filter(item => item !== customer));
      setTabsToHide([...tabsToHide, customer]);
      if (tabsToHide.length === sortedAllCustomers.length - 1) {
        setTabsToShow([]);
      };
    }
  };

  const handleOpenXAxis = () => {
    const msg = !!initialProductSelected.length ? '' : 'Please select Initial products'
    setErrorMessage(msg)
    setOpenXAxis(!msg && !openXAxis);
  };

  const handleOpenYAxis = () => {
    setErrorMessage(getErrorMessageValue())
    setOpenYAxis(!getErrorMessageValue() && !openYAxis);
  };

  const handleClear = () => {
    setClear(false);
  };

  const handleInitialDeselectAll = () => {
    setInitialProductSelected([]);
  };

  const getErrorMessageValue = () => {
    return selectedCategoryLevel && source && selectedProductLevel
      ? ''
      : 'Please select mandatory fields'
  }

  function parseCorrelationData(correlationData) {
    const sortedCorrelationData = correlationData.sort(function (a, b) {
      if (a.scopeName > b.scopeName) return 1;
      if (a.scopeName < b.scopeName) return -1;
      return 0;
    });
    const groupByCustomer = sortedCorrelationData.reduce(function (r, a) {
      r[a.scopeName] = r[a.scopeName] || [];
      r[a.scopeName].push(a);
      return r;
    }, Object.create(null));

    setSortedAllCustomers(Object.keys(groupByCustomer));
    setSelectedCustomer(Object.keys(groupByCustomer)[0]);
    setPosCorrelationData(groupByCustomer);
    setTabsToShow(Object.keys(groupByCustomer));
  };
  const syncSelectedCustomerWithTab = () => {
    const tabsToShowSet = [...new Set(tabsToShow)];
    const tabsToHideSet = [...new Set(tabsToHide)];
    const difference = tabsToShowSet.filter(item => !tabsToHideSet.includes(item));
    setShowCustomers(difference);
    setSelectedCustomer(difference[0])
  }

  const ClearButton = () => {
    return (
      <div className="flex flex-row w-full justify-between my-5">
        <button data-testid="clear-button" className="border border-primary text-primary rounded p-2 w-20" onClick={onClickClear}>Clear</button>
      </div>
    );
  };

  const ScopeFilter = () =>
    <div className="relative flex justify-start p-2">
      {(source === "POS" && targetProductSelected.length > 0 ?
        <button
          className="bg-white border border-primary text-primary rounded text-sm p-2"
          onClick={() => setCanShowCustomerFilter(!canShowCustomerFilter)}>
          Filter Customer
        </button>
        : '')}
      {canShowCustomerFilter &&
        <div className="absolute bg-white top-12 flex flex-col p-5 border shadow border-primary rounded z-50 filter-customer-channel-div" onMouseLeave={() => setCanShowCustomerFilter(false)}>
          <div className="hover:bg-blue-200 p-1 flex justify-start items-center gap-1">
            <input type="checkbox" checked={tabsToHide.length === 0} onChange={e => handleFilterAllClick(e)} />
            <span className="text-sm"><b>All</b></span>
          </div>
          {sortedAllCustomers.map((customer, idx) => {
            return <div key={idx} className="hover:bg-blue-200 p-1 flex justify-start items-center gap-1" >
              <input type="checkbox" checked={showCustomers.includes(customer)} onChange={e => handleCustomerSelectClick(e, customer)} />
              <span className="text-sm"> {customer} </span>
            </div>
          })
          }
        </div>
      }
    </div>

  return (
    <div className="flex flex-col p-5">
      <ProductLevelSelector
        onchange={(e) => onchange(e)}
        onScopeChange={(e) => onScopeChange(e)}
        tabName="correlation"
        onSourceChange={onClickClear}
        onSubCategoryChange={(e) => onClickClear()}
      />
      <ClearButton />
      <ErrorMessage message={errorMessage} />
      <div className="flex pb-4">
        <span>Initial Products: </span>
        <div className={`relative flex pr-2 z-10}`}>
          <div onClick={handleOpenYAxis} className="cursor-pointer px-2 py-1 z-6 ">
            <svg className="fill-primary cursor-pointer" focusable="false" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" id="prod_picker1"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>
          </div>
          {
            openYAxis ?
              <div className="absolute z-30" ref={domNodeY}>
                <NestedSearch
                  data={kcProductData}
                  handleSelectedValues={handleSelectedValues}
                  tabName="correlation"
                  dataFor="initial"
                  clear={clear}
                  handleClear={handleClear}
                  previouslySelected={initialProductSelected}
                  handleInitialDeselectAll={handleInitialDeselectAll}
                  id="searchYAxis"
                />
              </div>
              : null
          }
        </div>
        <div className="flex gap-x-3">
          {initialProductSelected.map((x) => {
            return <span key={x} className="text-xs p-2 bg-gray-300 rounded">{x}</span>
          })}
        </div>
      </div>
      <div className="flex pb-4">
        <span>Target Products: </span>
        <div className=" relative flex pr-2">
          <div onClick={handleOpenXAxis} className="cursor-pointer px-2 py-1">
            <svg className="fill-purple-700 cursor-pointer" focusable="false" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" id="prod_picker1"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>
          </div>
          {
            openXAxis && haveTargetData
              ? <div className="absolute z-30" ref={domNodeX}>
                <NestedSearch
                  data={targetData}
                  handleSelectedValues={handleSelectedValues}
                  tabName="correlation"
                  dataFor="target"
                  clear={clear}
                  handleClear={handleClear}
                  previouslySelected={targetProductSelected}
                  handleTargetDeselectAll={handleTargetDeselectAll}
                  id="searchXAxis"
                />
              </div>
              : null
          }
        </div>
        <div className="flex gap-x-3">
          {targetProductSelected.map((x) => {
            return <span key={x} className="text-xs p-2 bg-gray-300 rounded">{x}</span>
          })}
        </div>
      </div>
      <div className="flex">
        <div className="w-1/4">
          <ScopeFilter />
        </div>
        <div className="w-3/4">
          {(showCustomers?.length > 0 || initialProductSelected.length > 0) &&
            <AppPagination
              pageData={source === 'POS' ? posCorrelationData[selectedCustomer] : correlationDataNew?.data}
              handlePageData={handlePageData}
              dataCount={correlationTabPaginationCount}
              fromTab='correlation'
              applyPadding={false}
            />}
        </div>
      </div>
      <div className="flex-column justify-center overflow-x-auto" >
        <div className="flex gap-3 flex-wrap">
          {posCorrelationData.length !== 0 &&
            showCustomers?.sort().map(tab => {
              return <span key={tab} className={`p-2 text-primary uppercase font-bold cursor-pointer text-sm ${selectedCustomer === tab ? 'border-primary border-b text-black' : ''}`} onClick={() =>
                setSelectedCustomer(tab)
              }>{tab}</span>
            })
          }
        </div>
        <div className="mt-2.5 h-full border border-gray-300 justify-center">
          {targetProductSelected.length > 0 &&
            (posCorrelationData.length !== 0 || correlationDataNew.length !== 0) &&
            (source === 'POS' ? tabsToShow.length !== 0 : true)
            ?
            <CorrelationGrid
              initialProductSelected={correlationInitialProducts}
              targetProductSelected={targetProductSelected}
              headCells={headCells}
              correlationDataNew={source === 'POS' ? posCorrelationData[selectedCustomer] : correlationDataNew?.data}
              customer={selectedCustomer}
              productLevel={getHierarchyLevel(selectedProductLevel)}
            /> : <DataTable
              customStyles={tableStyle}
              progressPending={isCorrelationDataLoading || isKcProductDataLoading}
              columns={[{ name: source === "POS" ? 'Customer' : 'All' }]}
              persistTableHead={true}
            />
          }
          <Dialog
            open={isCorrelationDataLoading || isKcProductDataLoading}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="pt-0">
              {"Your request is being processed"}
            </DialogTitle>
          </Dialog>
        </div>
      </div>
    </div>
  )
};