import React, { useEffect, useState, Fragment } from "react";

import { OwnDashboardAnalysis } from './components/OwnDashboardAnalysis';
import { OwnDashboardPerScope } from './components/OwnDashboardPerScope';

export const OwnDashboard = (props) => {
  const [selectedCountry, setselectedCountry] = useState();

  useEffect(() => {
    const urlObj = new URL(window.location.href);
    setselectedCountry(urlObj.searchParams.get('country'));
  }, [])

  return (
    <Fragment>
      {selectedCountry === 'KR' ?
        <OwnDashboardPerScope /> : <OwnDashboardAnalysis />}
    </Fragment>
  )
}