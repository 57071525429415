import { useEffect, useState } from "react";

import { useWeekSelectors } from '../services/queries';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PromoTypeSearch from "./PromoType/PromoTypeSearch";
import CustomerSearch from "./CustomerSearch/CustomerSearch";
import ProductSearch from "./ProductSearch/ProductSearch";
import useStore from "../store";

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MemoizedWeekSelector } from "./WeekSelector/WeekSelector";

import AppTabs from "./DashboardTab/AppTabs"                //./SubTabs/AppTabs";
import { getDropDownTreeData } from "./WeekSelector/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
    width: 345,
  },
  appBar: {
    backgroundColor: theme.palette.primary.light,
  },
  media: {
    height: 140,
  },
  title: {
    color: theme.palette.primary.main,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
  },
  paperDiv: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
      height: theme.spacing(12),
    },
  },

  chartDiv: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
      height: theme.spacing(100),
    },
  },

  defaultTxt: {
    padding: "20px",
    margin: "15px auto",
    fontSize: "1.3rem",
    textAlign: "center",
  },

  dateLinks: {
    cursor: "pointer",

    fontWeight: 700,
    marginLeft: "30px",
  },
  dateLinksSpan: {
    marginRight: "40px",
    paddingBottom: "5px",
    color: "#0000b3",
  },
  dateLinksSpanActive: {
    borderBottom: "2px solid",
  },
  datedefaultText: {
    marginRight: "40px",
  },
  dataDiv: {
    // border: '1px solid',
    padding: theme.spacing(1),
    width: 390,
    backgroundColor: theme.palette.background.light,
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    height: "35rem",
  },
  note: {
    alignSelf: "centre",
  },
  clearAll: {
    color: "#0000b3",
    opacity: 0.8,
    fontWeight: 400,
    cursor: "pointer",
    marginLeft: "15px",
  },
  btnSucess: {
    background: '#163c62',
    color: '#fff',
    textTransform: 'none',
    margin: '0 10px',
    padding: '6px 35px',
    borderRadius: '10px',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  btnContainer: {
    float: 'right',
    marginTop: 20
  },
  listItem: {
    marginLeft: "10px",
    listStyle: "none",
    borderRadius: "5px",
  },
  radioitem: {
    marginLeft: '5px !important',
    marginBottom: "10px",
    marginTop: "-5px",
    marginRight: "2px !important",
    fontSize:'11px !important'
  }
}));

const PromoPerformanceKR = () => {
  const classes = useStyles();
  
  const [isROIFileDownloading, setIsROIFileDownloading] = useState(false);
 
  const [open, setOpen] = useState(false);
  const [isEditWeeksClicked, setIsEditWeeksClicked] = useState(false);
  const [originalDataInstance, setOriginalDataInstance] = useState([]);
  const [treeDataInstance, setTreeDataInstance] = useState([]);
  const [subTabs, setsubTabs] = useState([
    { index: 0, tag: "Summary", value: "Summary", id: 'Summary' },
    {
      index: 1,
      tag: "Dashboard",
      value: "Dashboard",
      id: 'Dashboard'
    },
    {
      index: 2,
      tag: 'Price Volume',
      value: 'PriceVolume',
      id: 'PriceVolume',
    },
  ])

  //store
  let selectedCountry = useStore((state) => state.selectedCountry);

  const setUserSelectedWeekRangesPromotional = useStore((state) => state.setUserSelectedWeekRangesPromotional);
  const setClearSelectedWeeks = useStore((state) => state.setClearSelectedWeeks);
  const setSelectedWeeksApply = useStore((state) => state.setSelectedWeeksApply);

  const setFixedCostInclude = useStore((state) => state.setFixedCostInclude);
  const fixedCostInclude = useStore((state) => state.fixedCostInclude);
  const selecteYeardWeeks = useStore((state) => state.selectedYearWeeks);

  const [fixedCost, setFixedCost] = useState(fixedCostInclude);

  
  const { data: weekSelectorsData } =
    useWeekSelectors(`${selectedCountry}`);

  let selectedBaseLineCustomerNew = useStore((state) => state.selectedBaseLineCustomerNew);
  let selectedBaseLineProductNew = useStore((state) => state.selectedBaseLineProductNew);


  let setSelectedYearWeeks = useStore((state) => state.setSelectedYearWeeks);


  const setSelectedBaseLineProductNew = useStore(
    (state) => state.setSelectedBaseLineProductNew
  );

  const setSelectedPromotionalItem = useStore(
    (state) => state.setSelectedPromotionalItem
  );


   const setSelectedBaseLineCustomerNew = useStore(
    (state) => state.setSelectedBaseLineCustomerNew
  );

  
  let setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );

  const [clearProduct, setClearProduct] = useState(false);
  const [clearPromoType, setClearPromo] = useState(false);
  const [clearCustomer, setClearCustomer] = useState(false)
  const setSelectedProductsHierarchy = useStore(state => state.setSelectedProductsHierarchy);
  const setSelectedProductValues = useStore(state => state.setSelectedProductValues);
  const setSelectedCustomerValues = useStore(state => state.setSelectedCustomerValues);

  const clearProductData = () => {
    setSelectedBaseLineProductNew("");
    setClearProduct(true);
    setSelectedProductValues([]);
    setSelectedProductsHierarchy({});
  };

  const clearPromo = () => {
    setSelectedPromotionalItem([]);
    setClearPromo(true);
  };

  const clearCustomerData = () => {
    setSelectedBaseLineCustomerNew("");
    setSelectedBaseLineFilter(selectedBaseLineProductNew);
    setClearCustomer(true);   
    setSelectedCustomerValues([]);
  };
 const [clearTime, setClearTime] = useState(false);

  const clearTimeData = () => {
    setClearTime(true);
    setSelectedYearWeeks([]);
    setClearSelectedWeeks(true);
    setSelectedWeeksApply([]);
  }

  const resetProduct = () => {
    setClearProduct(false);
  }
  const resetCustomer = () => {
    setClearCustomer(false);
  }
  const resetPromo = () => {
    setClearPromo(false);
  }


  const changeFixedCost = (e) => {
    setFixedCost(e.target.value)
    setFixedCostInclude(e.target.value)
  }
  useEffect(() => {
    if (weekSelectorsData === undefined) return;
    let lineDataObj = {};

    if (weekSelectorsData.data.length > 0) {
      for (const val of weekSelectorsData.data) {
        if (val !== null && val !== undefined) {
          let obj = val;
          let key = obj.year.toString();
          if (!lineDataObj[key]) {
            lineDataObj[key] = [obj];
          } else {
            lineDataObj[key].push(obj);
          }
        }
      }
      // Constructing drop down tree select object
      const treeNodesData = getDropDownTreeData(selectedCountry, lineDataObj);
      setTreeDataInstance(treeNodesData);
      setOriginalDataInstance(lineDataObj);

    }


  }, [weekSelectorsData]);
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <div className={classes.paperDiv}>
                <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                  Customer
                  <span className={classes.clearAll} id="clearCustomer" onClick={() => clearCustomerData()}>
                    Clear
                  </span>
                </div>
                <CustomerSearch dataFor="Promo" clear={clearCustomer} reset={resetCustomer} />
              </div>
            </Grid>
          

          
            <Grid item xs={12} sm={3}>
              <div className={classes.paperDiv}>
                <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                  Product
                  <span className={classes.clearAll} id="clearCustomer" onClick={() => clearProductData()}>
                    Clear
                  </span>
                </div>
                <ProductSearch dataFor="Promo" clear={clearProduct} resetClearProduct={resetProduct} />
              </div>
            </Grid>
          
          
            <Grid item xs={12} sm={selectedCountry === 'KR' ? 2 : 6}>
              <div className={classes.paperDiv}>
                <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                  Select Time Interval
                  <span className={classes.clearAll} onClick={() => clearTimeData()}>
                    Clear
                  </span>
                </div>
                <Paper onClick={() => setOpen(true)} style={{ background:"#fafafa" }}>
                  {selecteYeardWeeks.length < 4 ? (
                    <div style={{ display: "flex" }}>
                      {selecteYeardWeeks.map((year) =>
                        <li className={classes.listItem} key={year} style={{
                          marginLeft: "10px",
                          listStyle: "none",
                          backgroundColor: "#f4f4f4",
                          borderRadius: "5px",
                          marginTop:'10px'
                        }}>
                          {year}
                        </li>)}
                    </div>) : (
                    <li className={classes.listItem} style={{
                      marginLeft: "10px",
                      listStyle: "none",
                      backgroundColor: "#f4f4f4",
                      borderRadius: "5px",
                      paddingTop:"10px"
                    }} >
                      Multiple YearsWeeks Selected({selecteYeardWeeks.length})
                    </li>
                  )}
                </Paper>
              </div>
            </Grid>
          
            <Grid item xs={12} sm={2}>
              <div className={classes.paperDiv}>
                <div  style={{ height: 5, color: "black", fontWeight: "bold" }} >
                  Promo Type
                  <span onClick={() => clearPromo()} className={classes.clearAll} id="clearProducts" >
                    Clear
                  </span>
                </div>
                <PromoTypeSearch dataFor="Promo" clear={clearPromoType} resetPromo={resetPromo} />

              </div>
            </Grid>

            <Grid item xs={12} sm={2}>
              <div className={classes.paperDiv}>
                <div style={{ height: 5, color: "black", fontWeight: "bold" }}>
                  Fixed Cost
                </div>
                <Paper className={classes.paperDiv} style={{ background:"#fafafa" }}>
                  <div>
                    <FormControl required
                      component="fieldset"
                      sx={{ m: 2 }}
                      variant="standard"
                      defaultValue="no"
                      color="primary"
                      onChange={changeFixedCost}
                      id="kpiType">
                      <RadioGroup className="flex mb-1 fixed-radio " row="true">
                        <FormControlLabel checked={fixedCost === "Y"} value="Y" control={<Radio color='primary' />} label="Include" className={classes.radioitem} />
                        <FormControlLabel checked={fixedCost === "N"} value="N" control={<Radio color='primary' />} label="Exclude" className={classes.radioitem} />
                      </RadioGroup>
                    </FormControl>
                  </div>

                </Paper>
              </div>
            </Grid>
        </Grid>
       
          <div style={{ display: "inline" }}>
            <Paper >
              <AppTabs
                tabs={subTabs}
                logo={null}
              />
            </Paper>
          </div>
        

        <Dialog
          open={isROIFileDownloading}
          keepMounted
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle className="pt-0">
            {"Your request is being processed"}
          </DialogTitle>
        </Dialog>
        {open ? <MemoizedWeekSelector
          open={open}
          originalDataInstance={originalDataInstance}
          treeDataInstance={treeDataInstance}
          handleClose={handleClose}
          tabName="cross"
          setOpen={setOpen}
          setIsEditWeeksClicked={setIsEditWeeksClicked}
          isEditWeeksClicked={isEditWeeksClicked}
          setUserSelectedWeekRangesPromotional={setUserSelectedWeekRangesPromotional}
        /> : null}
      </Grid>

    </>
  );
};

export default PromoPerformanceKR;
