

import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import useStore from "../../store";
import NestedBaselinePromoType from "./NestedBaselinePromoType";
import useClickOutside from "../../ClickOutside";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {useGetPromotionSelectors} from "../../services/queries";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    width: theme.spacing(143),
    backgroundColor: theme.palette.background.paper,
  },
  listbox: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  input: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    float: "right",
    padding: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    float: "right",
    height: 28,
    margin: 4,
  },
  checkBox: {
    color: theme.palette.primary.main,
    fontFamily: "sans-serif",
  },
  listItem: {
    marginLeft: "10px",
    listStyle: "none",
    backgroundColor: "#f4f4f4",
    borderRadius: "5px",
  }
}));

function PromoTypeSearch(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [clear, setClear] = useState(false); 
  const [allPromotionData,setAllPromotionData]= useState();
  const [payload, setPayload] = useState({
    "brands": [],
    "categories": [],
    "channels": [],
    "country": "KR",
    "customers": [],
    "eans": [],
    "packs": [],
    "planningCustomers": [],
    "salesOffice": [],
    "sectors": [],
    "subBrands": [],
    "subCategories": [],
    "yearWeeks": [],
    "promoIds": [],
    "promoTypes": [],
});

let customersStateData = useStore(
  (state) => state.customersStateData
);
let productsStateData = useStore(
  (state) => state.productsStateData
);
const setPromoTypeCheckBox = useStore(
  (state) => state.setPromoTypeCheckBox
);
const setSelectedBaseLineFilter = useStore(
  (state) => state.setSelectedBaseLineFilter
);


  let selectedCountry = useStore((state) => state.selectedCountry);
  const { isLoading: isPromotionData, data: promotionData } = 
    useGetPromotionSelectors(payload);
    const setPromotionalsStateData = useStore(
      (state) => state.setPromotionalsStateData
    );
  let selectedPromotionalItem = useStore(
    (state) => state.selectedPromotionalItem
  );

  const setSelectedPromotionalItem = useStore(
    (state) => state.setSelectedPromotionalItem
  );
  const selecteYeardWeeks = useStore((state) => state.selectedYearWeeks);

  const setPromoStateData = useStore((state) => state.setPromoStateData);
  useEffect(() => {
    console.log("UPDATED")
    let selectedValue = {
        country: selectedCountry,
        yearWeeks: selecteYeardWeeks,  //202244         
    }
    let customer = getCustomerData();
    let product = getProductData();
    
    selectedValue = { ...payload, ...selectedValue, ...customer.data, ...product.data};
    setPayload(selectedValue)

}, [selectedCountry, selecteYeardWeeks, customersStateData, productsStateData, selectedPromotionalItem,clear]);

function putAll(x) {
  return x.name;
}

function isSelected(x) {
  return x.isChecked;
}
function getCustomerData() {
  let selectedArr = [];
  let customerPayLoad = {};
  let formatedPayLoad = {};
  const arr = [
      "customers"
  ];
  let values = { ...customersStateData };
  //iterating backword
  for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      customerPayLoad[level] = [];
      if (values && values[level]) {
          let selectedNodes = values[level].filter(isSelected).map(putAll);
          if (selectedNodes.length !== 0) {
              selectedNodes.forEach((val) => {
                  customerPayLoad[level].push(val);
                  selectedArr.push(val);
              });
          }
      }
  }
  formatedPayLoad['customers'] = customerPayLoad["customers"];
  return { data: formatedPayLoad };
}

function getProductData() {
  let selectedArr = [];
  let productPayLoad = {};
  let formatedPayLoad = {};
  const arr = [
      "brand",
      "category",
      "eans",
      "sector",
      "subBrand",
      "subCategory",
  ];
  let values = { ...productsStateData };
  //iterating backword
  for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      productPayLoad[level] = [];
      if (values && values[level]) {
          let selectedNodes = values[level].filter(isSelected).map(putAll);
          if (selectedNodes.length !== 0) {
              selectedNodes.forEach((val) => {
              productPayLoad[level].push(val);
              selectedArr.push(val);    
              });
          }
      }
  }

  formatedPayLoad.eans = [];
  if (productPayLoad["eans"].length > 0) {
      productPayLoad["eans"].forEach(element => {
          let eans = element.split("-");
          if (eans.length > 0) {
              formatedPayLoad.eans.push(eans[eans.length - 1].trim())
          }
      });
  }
  formatedPayLoad.sectors = productPayLoad["sector"];
  formatedPayLoad.categories = productPayLoad["category"];
  formatedPayLoad.subBrands = productPayLoad["subBrand"];
  formatedPayLoad.brands = productPayLoad["brand"];
  formatedPayLoad.subCategories = productPayLoad["subCategory"];
  //formatedPayLoad.eans = productPayLoad["eans"];
  return { data: formatedPayLoad };
}
  useEffect(() => {
    if (!isPromotionData && selectedCountry !== undefined && promotionData) {
      setAllPromotionData(promotionData);      
    }
  }, [isPromotionData, promotionData]);

  const handleClick = (event) => {
    if (selectedCountry) {
      setOpenSelector(!openSelector);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };  


  useEffect(() => {
    if (props.clear) {     
       setSelectedPromotionalItem([]);
       setPromoStateData([]);
       setPromotionalsStateData([])
      setClear(true);      
    }
  }, [props.clear])

  let domNode = useClickOutside(() => {
    setOpenSelector(false);
  })

  const resetClear = () => {
    setClear(false);
    props.resetPromo();
  }
  //changes for update selected promo based on customer
  useEffect(() => {
    if(allPromotionData){
    let Values = calculateInitial(allPromotionData);    
    let arr = [
      "promoType",
      "discountType",
      "promoGroup",
      "promoIds"
    ];
    let countObj = {
      "promoType": 0,
      "promoIds": 0,
      "discountType": 0,
      "promoGroup": 0
    };
    if (selectedPromotionalItem) {
      selectedPromotionalItem.forEach(value => {
        for (let x of arr) {
          if (Values[x].filter(o => o.name === value).length) {
            countObj[x] = countObj[x] + 1;
            let index = Values[x].findIndex(o => o.name === value);
            Values[x][index]['isChecked'] = true;
          }
        }
      })
    }
    setPromotionalsStateData({
      ...Values,
    });

    let ob = {};
    for (let x of arr) {
      ob[x] = Values[x].length === countObj[x];
    }
    setPromoTypeCheckBox(ob);
    setSelectedBaseLineFilter("");
    let obj = generateUrl(Values);
    setSelectedPromotionalItem(obj['selectedArr']);
  }
  }, [allPromotionData]);
//during initial render displays all values for diff levels 
function calculateInitial(data) {
  //const processed = processCustomerHierarchy(data, null)
  const transformed = transformDataUnique(data);
  return transformed;
}
function transformDataUnique(values) {
  if (values && values.data) {
    values = values.data;

    let arr1 = [
      "promoType",
      "discountType",
      "promoGroup",
      "promoIds"
    ];

    const objValues = {
      "promoType": [],
      "discountType": [],
      "promoGroup": [],
      "promoIds": [],
    }
    arr1.forEach((val) => {

      values[val] = [
        values.forEach((item) => {
          if (item[val] !== undefined) {
              if (val === "promoIds") {
                item[val].forEach((listItem) => {
                  objValues[val].push({ 'name': listItem, 'parent': item["promoType"], 'isChecked':   selectedPromotionalItem.includes( listItem) });
                })
              } else {
                objValues[val].push({ 'name': item[val], customer: item['customer'],promoIds:item['promoIds'], 'isChecked':  selectedPromotionalItem.includes( item[val]) });
              }
            
          }
        }),
      ];
    });
    arr1.forEach((val) => {
      objValues[val].sort(function (a, b) {
        if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else if (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
      });
    });
    return objValues;

  } else {    
    return values;
  }
}
function generateUrl(values) {
  let selectedArr = [];
  let reformatKcStr = '';
  
  let arr = [
    "promoType",
    "discountType",
    "promoGroup",
    "promoIds"
  ]
  //iterating backword
  for (let i = arr.length - 1; i >= 0; i--) {
    let level = arr[i];
    let selectedNodes = values[level].filter(isSelected).map(putAll);
    if (selectedNodes.length !== 0) {
      selectedNodes.forEach((val) => {
        reformatKcStr += `&${level}=${val}`;
        selectedArr.push(val);
      });
    }
  }
  return reformatKcStr === '' ? { url: '', selectedArr: [] } : { url: reformatKcStr, selectedArr: selectedArr };
}

  return (
    <div
      style={{ display: "flex", component: "form", flexDirection: "column" }}
      ref={domNode}
    >
      <Paper component="form" className={classes.root}>

        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton className={classes.iconButton} aria-label="search" onClick={handleClick}>
               <FilterAltIcon
                  className={`icon_${props.dataFor.toLowerCase()}`}
                  alt="filterIcon"
                  sx={{color: '#000'}}
                />
            </IconButton>
          </div>
          <div style={{ display: "flex" }}>
            {
              (
                 selectedPromotionalItem
                  || []).length > 3 ? (<li
                    className={`selected_${props.dataFor.toLowerCase()}`}
                    style={{
                      marginLeft: "10px",
                      listStyle: "none",
                      backgroundColor: "#f4f4f4",
                      borderRadius: "5px",
                    }}
                  >
                    Multiple Item Selected({selectedPromotionalItem.length})
                  </li>) : (
                (
                  selectedPromotionalItem
                    || []).filter((value, index, self) => self.indexOf(value) === index).map((item) => (
                      <li
                        className={`selected_${props.dataFor.toLowerCase()}`}
                        key={item}
                        style={{
                          marginLeft: "10px",
                          listStyle: "none",
                          backgroundColor: "#f4f4f4",
                          borderRadius: "5px",
                        }}
                      >
                        {item}
                      </li>
                    ))
              )
            }
          </div>
        </>
      </Paper>

      <div style={{ zIndex: 5 }} className="absolute mt-11">
        {openSelector && (
            <NestedBaselinePromoType
              key={'PromoTypeSearch'}
              data={promotionData}
              sellInData={props.sellInData}
              previouslySelected={selectedPromotionalItem}
              clear={props.clear ||clear}
              resetClear={resetClear}
              indexValue={props.indexValue}
              tabName={props?.tabName}
            />
          )}
      </div>

    </div>
  );
}
export default PromoTypeSearch;

