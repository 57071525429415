/* eslint-disable @typescript-eslint/no-unused-expressions */

export const convertDateRange = (range:string) => {
    if(!range) return "";
    let monthNames =["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
    let startDate = new Date(range);

    let stDay = startDate.getDate();
    let stMonthIndex = startDate.getMonth();
    let stMonthName = monthNames[stMonthIndex];
    let stYear = startDate.getFullYear();

    return `${stDay} ${stMonthName} ${stYear}`;
}


export const getSelectedWeekRange: any = (selectedDataPoints: any) => {
    let weekRange: any = {}
    // Get week ranges for each year
    selectedDataPoints.forEach((data: any) => {
        const {year, week} = data;
        if(!weekRange[year])
            weekRange[year] = []
        if(!weekRange[year].includes(week))
            weekRange[year].push(week)
    })
    // Sort weeks
    for (let x in weekRange) {
        weekRange[x].sort((a: any,b: any) => { return a - b })
    }
    return weekRange;
}

export const getDropDownTreeData = (country: string, lineData: any, selectedWeeks?: any) => {
    let treeNodes = [];
    const treeNodesData: any[] = []
    for(const key in lineData) {
        let arr = [];
        if(lineData.hasOwnProperty(key) && key !== '') {
        let val = lineData[key];
        // Sorting weeks
        val.sort((a: any, b: any) => { return a.week - b.week; });
        // Removing duplicate weeks
        let uniqueWeeks: any = Array.from(new Set(val.map(JSON.stringify)))
        val = uniqueWeeks.map(JSON.parse);
        let months: any = {};
        for(const obj of val) {
            let isChecked = false;
            if(months[obj['month']] && months[obj['month']] !== undefined) {
                if(selectedWeeks !== undefined && selectedWeeks[key]!==undefined && selectedWeeks[key].includes(obj['week'])){
                isChecked = true;
                }
                months[obj['month']].push({"label" : obj['weekRange'], "week" : obj['week'], "year" : obj['year'], "checked": isChecked});
            } else {
                if(selectedWeeks !== undefined && selectedWeeks[key]!==undefined && selectedWeeks[key].includes(obj['week'])){
                    isChecked = true;
                }
                months[obj['month']] = [{"label" : obj['weekRange'], "week" : obj['week'], "year" : obj['year'], "checked": isChecked}];
            }
            arr.push(months);
        }              
        }
        let node: any = {};
        node['label'] = key;
        //Removing duplicate labels
        node["children"] = arr.filter(
        (value, index, arr) =>
            index === arr.findIndex((t) => t.label === value.label )
        );
        treeNodes.push(node);
    }
    treeNodes.forEach(data => {
        const nodes: any = {};
        nodes["label"] = data.label;
        const childNodes = []
        let isYearChecked = false;
        let monthCounter = 0;
        if(data.children[0] !== undefined) {
        for(const [key, value] of Object.entries(data.children[0])){
            let childVal: any = value;
            let isMonthChecked = false;
            let weekCounter = 0
            childVal.forEach((val: any) => {
            if(val.checked) weekCounter++;
            })
            if (weekCounter!== 0 && weekCounter===childVal.length) {
            isMonthChecked = true;
            monthCounter++;
            }
            childNodes.push({"label" : key, "children" : value, "checked": isMonthChecked});
        }
        }
        nodes["children"] = childNodes;
        if(monthCounter!==0 && monthCounter===Object.keys(data.children[0]).length) 
        isYearChecked = true;
        nodes["checked"] = isYearChecked;
        treeNodesData.push(nodes);
    })
    return treeNodesData;
}

export const isSelected = (x: any) =>  {
    return x.isChecked;
}

export const putAll = (x: any) => {
    return x.name;
}
