import React, {Fragment, useEffect, useState} from 'react';
import {Box, Pagination} from '@mui/material';

import {paginationPerPageDataLimit} from './utils';

const AppPagination = ({pageData, handlePageData, dataCount, fromTab, applyPadding}) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
    handlePageData(1);
  }, [])

  useEffect(() => {
    handlePageData(currentPage);
    if(!pageData || pageData === undefined || (pageData !== undefined && Object.keys(pageData).length === 0)){
      setCurrentPage(1);
    }
  },[pageData, currentPage]);
  
  const handlePageChange = (evt, currentPage) => {
    setCurrentPage(currentPage);
  }
  
  return (
    <Fragment>
      {dataCount > paginationPerPageDataLimit ? <div className="block w-full left-9">
        <Box className='Pagination' justifyContent="end" alignItems="center" display="flex" >
          {(pageData !== undefined && Object.keys(pageData).length !== 0) ?
            <Pagination
              data-testid="pagination"
              size="large"
              sx={{padding: (fromTab === 'cross' || 'correlation') ? applyPadding ? "20px 0" : "5px 0" : "20px 0 0"}}
              count={Math.ceil(dataCount / paginationPerPageDataLimit)}
              onChange={handlePageChange}
          /> : null }
        </Box>
      </div> : null}
    </Fragment>
  )
}

export default AppPagination;