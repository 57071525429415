import React, {Fragment, useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { uniq } from 'lodash';
import { makeStyles } from '@material-ui/core';

import useStore from "../../../../store";
import {useGetCustomersSelectors} from '../../../../services/queries';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  select: {
    fontSize: '0.875rem !important',
    backgroundColor: '#fff'
  },
  dropdown: {
    '& > span' : {
      fontSize: '0.875rem !important',
      padding: '3px !important'
    }
  },
}));

type Func = () => void;

type CustomerFilterProps = {
  clearCustomers : boolean,
  resetCustomer : Func,
}

const CustomerFilter = ({clearCustomers, resetCustomer} : CustomerFilterProps) => {
  const classes = useStyles();

  const selectedCountry = useStore((state) => state.selectedCountry);
  const [selectedCustomers, setSelectedCustomers] = React.useState<string[]>([]);

  const selectedSimulationCustomers: string[] = useStore((state) => state.selectedSimulationCustomers);
  const setSelectedSimulationCustomers = useStore((state) => state.setSelectedSimulationCustomers);

  const { isLoading: isCustomersData, data: customersData } = useGetCustomersSelectors(`${selectedCountry}`);

  useEffect(() => {
    setSelectedSimulationCustomers([]);
  }, []);

  useEffect(() => {
    if(clearCustomers){
      clearCustomerData();
    }
  }, [clearCustomers])

  const getCustomers = (customerData: any) => {
    const customers: any[] = [];
    if(customerData) {
      customerData.forEach((data:any) => {
        (data.customers).forEach((customerName:any) => customers.push(customerName));
      })
    } 
    return uniq(customers).sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
  }  
  
  const clearCustomerData = () => {
    setSelectedCustomers([]);
    setSelectedSimulationCustomers([]);
  }

  const availableCustomers = React.useMemo(
    () => !isCustomersData && customersData ? getCustomers(customersData.data) : [],
    [customersData]
  );

  const handleChange = (event: SelectChangeEvent<typeof selectedCustomers>) => {
    resetCustomer();
    const {target: { value }} = event;
    setSelectedSimulationCustomers(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Fragment>
      <FormControl sx={{ width: '100%' }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedSimulationCustomers}
          onChange={(event) => handleChange(event)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return 'Select Customer';
            } else {
              return selected.length > 2 ? `Multiple Items Selected(${selected.length})` : selected.join(', ')
            }
          }}
          MenuProps={MenuProps}
          className={classes.select}
        >
          {availableCustomers.map((customer:string) => (
            <MenuItem key={customer} value={customer} className={classes.dropdown}>
              <Checkbox checked={selectedSimulationCustomers.indexOf(customer) > -1} />
              <ListItemText primary={customer} className={classes.dropdown}/>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  );
}

export default CustomerFilter;