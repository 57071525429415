import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getAPITestResults } from './services/queries';

const useStyles = makeStyles(theme => ({
    main: {
        padding:100,
        display: 'flex',
        flexDirection:'column'
    },
    wrapper: {
        paddingTop:20,
        display:'flex',
        flexDirection:'column',
        gap:'10px'
    },
    inner: {
        display:'flex',
        flexDirection:'row',
        gap:'10px'
    }
}))

const TestAPI = () => {
    const [testResults, settestResults] = useState('')
    const classes = useStyles();

    useEffect(() => {
        const getTestReults = async () => {
          const res = await getAPITestResults(1)
          settestResults(res.response)
        }
        getTestReults();
      }, [])

    return (
        <div className={classes.main}>
            <h3>Test results: </h3>
            <div className={classes.wrapper}>
                {
                    testResults
                    ? <p>
                        {testResults}
                    </p>
                    : null
                }
            </div>
        </div>
    )
}

export default TestAPI;