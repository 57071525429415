import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import useStore from "../../../../store";
import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import { CalculateButton } from '../InputSection/CalculateButton';
import { NumberFormatCustom } from './NumberFormatCustom';

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  cellData: {
    borderLeft: "1px solid #ccc",
    textTransform: "capitalize",
    padding: "3px",
    fontSize: ".75rem"
  },
  cellDataCenter: {
    borderLeft: "1px solid #ccc",
    textAlign: "center",
    padding: "3px",
    fontSize: ".75rem"
  },
  btnContainer: {
    marginTop: "30px",
    float: "right",
    marginRight: "50px",
  },
  input: {
    borderBottom: "none",
    padding: "3px",
    fontSize: ".75rem"
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
  dialogWidth: {
    minWidth: "1000px",
    maxWidth: "1200px",
  },
  btnClose: {
    position: "absolute",
    top: "0",
    right: "5px",
  },

  infoContainer: {
    marginRight: 15,
  },
  customInput: {
    fontSize: "0.75rem",
    textAlign: 'center',
    '& input': {
      fontSize: '0.75rem',
      textAlign: 'center',
      // marginLeft: '16px'
    },
  }
}));
const PROMOCALCULATIONKEYS = {
  "baseGSVListPrice": "Sell-In List Price",
  "sellInListPriceOverride": "Sell-In List Price Override",
  "tradingTerms": "Trading Terms",
  "baseSellInInvoicePrice": "Base Sell-In Invoice Price",
  "promoSellInInvoicePrice": "Promo Sell-In Invoice Price",
  "baseSellOutPrice": "Base Sell-Out Price",
  "baseSellOutPriceOverride": "Base Sell-Out Price Override",
  "promoSellOutPrice": "Promo Sell-Out Price",
  "cogs": "COGS",
  "fixedCogOverride": "COGS Override",
  "discount": "Discount Value",
  "discountPercent": "Discount %",
  "baseVolume": "Base Volume ",
  "baseVolumeOverride": "Base Volume Override",
  "promoVolume": "Promo Volume",
  "volumeUplift": "Volume Uplift",
  "additionalFunding": "Additional Funding",
  "additionalFundingOverride": "Additional Funding Override",
}
const KEYSFORTOTALVALUE = [
  "baseVolume",
  "baseVolumeOverride",
  "promoVolume",
  "volumeUplift",
  "additionalFunding",
  "additionalFundingOverride"
]
const EDITABLEKEYS = [
  "sellInListPriceOverride", "baseVolumeOverride", "additionalFundingOverride", "promoVolume", "fixedCogOverride", "tradingTerms", "promoSellInInvoicePrice", "promoSellOutPrice", "baseSellOutPriceOverride"
]
export const getHeaderKey = (key: string, displayPromoCalculations: any) => {
  let tempArray: string[] = [];
  let keyString = '';
  displayPromoCalculations.forEach((row: any) => {
    if (row.key === "eanDescription") {
      let productArray: string[] = [];
      row.value.forEach((item: any) => {
        keyString = item[Object.keys(item)[0]] + '-' + Object.keys(item)[0];
        productArray.push(keyString)
      })
      tempArray = productArray;
    }
  })
  return tempArray;
}
export const getTotalValue = (key: string, displayPromoCalculations: any) => {
  let total: number = 0;
  let promoVolumeTotal: number = 0;
  let baseVolumeOverrideTotal: number = 0
  if (KEYSFORTOTALVALUE.includes(key)) {
    displayPromoCalculations.forEach((row: any) => {
      if (row.key === key) {
        row.value.forEach((item: any) => {
          for (var key in item) {
            total += Number(item[key]);
          }
        })
      }
    })
    if (key === 'volumeUplift') {
      displayPromoCalculations.forEach((row: any) => {
        if (row.key === 'promoVolume') {
          row.value.forEach((item: any) => {
            for (var key in item) {
              promoVolumeTotal += Number(item[key]);
            }
          })
        }
        if (row.key === 'baseVolumeOverride') {
          row.value.forEach((item: any) => {
            for (var key in item) {
              baseVolumeOverrideTotal += Number(item[key]);
            }
          })
        }
      })
      return isNaN((promoVolumeTotal - baseVolumeOverrideTotal) / baseVolumeOverrideTotal) ? 0 : ((promoVolumeTotal - baseVolumeOverrideTotal) / baseVolumeOverrideTotal).toFixed(1)
    }
    return total.toLocaleString('en-US');
  }
  return '-';
}

export const PromoProductsGrid = ({ id, promoData }: any) => {
  let selectedCountry = useStore((state) => state.selectedCountry);
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  let displayPromoCalculations = promoSimulatorTabs[id].displayPromo;
  const [isUpdated, setIsupdated] = useState(false);
  const classes = useStyles();

  const updateValue = (e: any, productKey: string, key: string) => {
    let value = e.target.value ? e.target.value : '';
    if (selectedCountry === "KR") {
      value = Math.trunc(value);
    } else {
      value = Number(value.tofixed(2));
    }

    let tempDisplayPromoCalculations = displayPromoCalculations;
    let sellinListPriceOverrideValue = tempDisplayPromoCalculations.find((entry: any) => entry.key === 'sellInListPriceOverride').value.find((obj: any) => obj.hasOwnProperty(productKey))[productKey];
    let tradingTermsValue = tempDisplayPromoCalculations.find((entry: any) => entry.key === 'tradingTerms').value.find((obj: any) => obj.hasOwnProperty(productKey))[productKey];
    let promoSellOutPriceValue = tempDisplayPromoCalculations.find((entry: any) => entry.key === 'promoSellOutPrice').value.find((obj: any) => obj.hasOwnProperty(productKey))[productKey];
    let baseSellOutPriceOverrideValue = tempDisplayPromoCalculations.find((entry: any) => entry.key === 'baseSellOutPriceOverride').value.find((obj: any) => obj.hasOwnProperty(productKey))[productKey];
    let promoVolumeValue = tempDisplayPromoCalculations.find((entry: any) => entry.key === 'promoVolume').value.find((obj: any) => obj.hasOwnProperty(productKey))[productKey];
    let baseVolumeOverrideValue = tempDisplayPromoCalculations.find((entry: any) => entry.key === 'baseVolumeOverride').value.find((obj: any) => obj.hasOwnProperty(productKey))[productKey];
    let tax = tempDisplayPromoCalculations.find((entry: any) => entry.key === 'tax').value.find((obj: any) => obj.hasOwnProperty(productKey))[productKey];

    tempDisplayPromoCalculations.forEach((item: any) => {
      if (item.key === key) {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = Number(value);
          }
        })
      }
    })

    tempDisplayPromoCalculations.forEach((item: any) => {
      if (item.key === 'baseSellInInvoicePrice' && key === 'tradingTerms') {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = sellinListPriceOverrideValue - Number(value);
          }
        })
      }
    });

    tempDisplayPromoCalculations.forEach((item: any) => {
      if ((item.key === 'baseSellInInvoicePrice' && key === 'sellInListPriceOverride')) {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = Number(value) - tradingTermsValue;
          }
        })
      }
    });

    tempDisplayPromoCalculations.forEach((item: any) => {
      if ((item.key === 'discount' && key === 'baseSellOutPriceOverride')) {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = Number(value) - promoSellOutPriceValue;
          }
        })
      }
    });

    tempDisplayPromoCalculations.forEach((item: any) => {
      if ((item.key === 'discount' && key === 'promoSellOutPrice')) {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = baseSellOutPriceOverrideValue - Number(value);
          }
        })
      }
    });

    tempDisplayPromoCalculations.forEach((item: any) => {
      if ((item.key === 'discountPercent' && key === 'baseSellOutPriceOverride')) {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = value === 0 ? '0' : (((Number(value) - promoSellOutPriceValue) / Number(value)) * 100).toFixed(1);
          }
        })
      }
    });

    tempDisplayPromoCalculations.forEach((item: any) => {
      if ((item.key === 'discountPercent' && key === 'promoSellOutPrice')) {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = baseSellOutPriceOverrideValue === 0 ? '0' : (((baseSellOutPriceOverrideValue - Number(value)) / baseSellOutPriceOverrideValue) * 100).toFixed(1);
          }
        })
      }
    });

    tempDisplayPromoCalculations.forEach((item: any) => {
      if ((item.key === 'promoSellInInvoicePrice' && key === 'promoSellOutPrice') && promoSimulatorTabs && promoSimulatorTabs[0] && promoSimulatorTabs[0].sellInPriceType === "EXPECTED_MARGIN") {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = Number(value) === 0 ? '0' : ((Number(value)/(1+tax)) * (1 - ((promoSimulatorTabs[0].sellInPriceTypevalue)/100))).toFixed(1);
          }
        })
      }
    });

    tempDisplayPromoCalculations.forEach((item: any) => {
      if ((item.key === 'volumeUplift' && key === 'promoVolume')) {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = Number(baseVolumeOverrideValue) === 0 ? '0' : ((Number(value) - baseVolumeOverrideValue) / baseVolumeOverrideValue).toFixed(1);
          }
        })
      }
    });

    tempDisplayPromoCalculations.forEach((item: any) => {
      if ((item.key === 'volumeUplift' && key === 'baseVolumeOverride')) {
        item.value.forEach((productItem: any) => {
          if (productItem.hasOwnProperty(productKey)) {
            productItem[productKey] = Number(value) === 0 ? '0' : ((promoVolumeValue - Number(value)) / Number(value)).toFixed(1);
          }
        })
      }
    });

    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, displayPromo: tempDisplayPromoCalculations } : tab));
    setIsupdated(!isUpdated);
  }

  const getRowValue = (key: string) => {
    return displayPromoCalculations.map((row: any) => {
      if ((row.key === key && key === "sellInListPriceOverride")
        || (row.key === key && key === "baseVolumeOverride")
        || (row.key === key && key === "additionalFundingOverride")
        || (row.key === key && key === "promoVolume")
        || (row.key === key && key === "fixedCogOverride")
        || (row.key === key && key === "tradingTerms")
        || (row.key === key && key === "promoSellInInvoicePrice")
        || (row.key === key && key === "promoSellOutPrice")
        || (row.key === key && key === "baseSellOutPriceOverride")) {
        return row.value.map((item: any, index: number) => {
          return Object.keys(item).map((key: string) => {
            return <TableCell key={`row-cell-total-${index}`}
              component="th"
              scope="row"
              className={classes.cellDataCenter}
            >
              <TextField
                data-testid={`input-${key}-${row.key}`}
                value={item[key]}
                InputProps={{ inputProps: { min: 0 }, inputComponent: NumberFormatCustom }}
                onChange={(e) => { updateValue(e, key, row.key) }}
                className={classes.customInput}
                disabled={promoSimulatorTabs[0].pnLGridGenerated} />
            </TableCell>
          })

        })
      } else if (row.key === key) {
        return row.value.map((item: any, index: number) => {
          for (var key in item) {
            return <TableCell key={`row-cell-total-${index}`}
              component="th"
              scope="row"
              className={classes.cellDataCenter}
              style={{ background: "#F3F1F1" }}
            >
              {item[key] ? item[key].toLocaleString('en-US') : '-'}
            </TableCell>
          }
          return <></>;
        })
      }
      return <></>;
    })
  }
  const getFirstColumn = (row: any) => {
    let firstColArray = [
      { key: 'baseGSVListPrice', rowsPan: 5, label: 'Sell-In' },
      { key: 'baseSellOutPrice', rowsPan: 3, label: 'Sell-Out' },
      { key: 'discount', rowsPan: 2, label: 'Discount' },
      { key: 'baseVolume', rowsPan: 4, label: 'Volume' },
      { key: 'additionalFunding', rowsPan: 2, label: 'Additional Funding' },
      { key: 'cogs', rowsPan: 2, label: 'COGS' },
    ]
    let selectedkey = firstColArray.find((item: any) => {
      return item.key === row
    })
    if (selectedkey) {
      return (<TableCell rowSpan={selectedkey.rowsPan} key={`row-cell${row}`}
        style={{
          position: "sticky",
          left: 0,
          zIndex: 1200,
          background: "#e5e7eb",
          width: 80,
          textAlign: 'center'
        }}
        component="th"
        scope="row"
        className={classes.cellDataCenter}
      >
        {selectedkey.label}
      </TableCell>
      )
    }
  }

  if ((displayPromoCalculations === undefined || displayPromoCalculations.length === 0)) {
    return <>No data to display...</>;
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table" data-testid="PromoProductsGrid">
          <TableHead>
            <TableRow key="index-row">
              <TableCell
                align="center"
                className={classes.cellData}
                component="th"
                scope="row"
                style={{
                  position: "sticky",
                  width: 80,
                  minWidth: 80,
                  left: 0,
                  zIndex: 800,
                  background: "#e5e7eb",
                  top: 0,
                }}
              >
              </TableCell>
              <TableCell
                align="center"
                className={classes.cellData}
                component="th"
                scope="row"
                style={{
                  position: "sticky",
                  width: 180,
                  minWidth: 180,
                  left: 80,
                  zIndex: 800,
                  background: "#e5e7eb",
                  top: 0,
                }}
              >
              </TableCell>
              <TableCell
                align="center"
                component="th"
                scope="row"
                className={classes.cellData}
                style={{
                  position: "sticky",
                  left: 260,
                  top: 0,
                  zIndex: 800,
                  background: "#e5e7eb",
                  width: 100,
                  minWidth: 100,
                }}
              >Total</TableCell>
              {getHeaderKey("ean", displayPromoCalculations).map((row: any) => (
                <TableCell
                  align="center"
                  className={classes.cellData}
                  style={{
                    position: "sticky",
                    zIndex: 700,
                    background: "#e5e7eb",
                    maxWidth: 150,
                    minWidth: 150,
                  }}
                >{row}</TableCell>
              ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(PROMOCALCULATIONKEYS).map((row: string, index: number) => (
              <TableRow key={`row-header${row}-${index}`}>
                {getFirstColumn(row)}

                <TableCell key={`row-cell${row}-${index}`}
                  style={{
                    position: "sticky",
                    left: 80,
                    zIndex: 800,
                    background: "#e5e7eb",
                    width: "150px",
                    textAlign: 'left'
                  }}
                  component="th"
                  scope="row"
                  className={classes.cellDataCenter}
                >
                  {
                    (EDITABLEKEYS.includes(row)) ? (
                      <span>{PROMOCALCULATIONKEYS[row as keyof typeof PROMOCALCULATIONKEYS]}<>{row === "additionalFundingOverride" ? null : <span className="text-red-900 font-bold text-base">*</span>}</></span>
                    ) : (
                      PROMOCALCULATIONKEYS[row as keyof typeof PROMOCALCULATIONKEYS]
                    )
                  }
                </TableCell>

                <TableCell key={`row-cell-total${row}`}
                  style={{
                    position: "sticky",
                    left: 260,
                    zIndex: 800,
                    background: "#e5e7eb",
                  }}
                  component="th"
                  scope="row"
                  className={classes.cellDataCenter}
                >
                  {getTotalValue(row, displayPromoCalculations)}
                </TableCell>
                {getRowValue(row)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <span id='promo-grid'></span>
      <CalculateButton promoData={promoData} />
    </>
  );
};