import { useEffect, useState } from "react";

import useStore from "../../../store";
import { ElasticityScopeRecord, ElasticityRecord, Scope, CustomSortFuction, PriceType, Source } from "../../types"
import { SingleGrid } from "../../single_grid";
import { indexOf } from 'lodash';

type ScopeProps = {
    records: ElasticityScopeRecord[],
    addProductClick: () => void,
    selectedCountry: string,
    unselectRow: (name: string, target?: string, id?: any) => void,
    handleViewMore: (id: any, initial: string, target?: string, customer?: string, category?: string, subCategoryInitial?: string, subCategoryTarget?: string, manufacturerInitial?: string, manufacturerTarget?: string, brandInitial?: string, brandTarget?: string, subBrandInitial?: string, subBrandTarget?: string, packInitial?: string, packTarget?: string) => void,
    selectedProducts: string[],
    loading: boolean,
    filterCustomer: (name: string[]) => void,
    allCustomers: string[],
    customSort?: CustomSortFuction,
    priceType: PriceType,
    scope: Scope
    setHiddenCustomersArray?: (customer: string[]) => void,
    source?: Source
    removedProductList?: string[],
}

export const MarketScope = ({ records, addProductClick, selectedCountry, unselectRow, handleViewMore, selectedProducts, loading, filterCustomer, allCustomers, customSort, priceType, scope, setHiddenCustomersArray, source, removedProductList }: ScopeProps) => {
    const sortedAllCustomers = [...allCustomers].sort();
    const sortedRecords = [...records].sort((record1: any, record2: any) => (record1.customer && record2.customer) && record1.customer.toLowerCase() > record2.customer.toLowerCase() ? 1 : record1.customer.toLowerCase() < record2.customer.toLowerCase() ? -1 : 0);
    const [canShowCustomerFilter, setcanShowCustomerFilter] = useState(false);
    const [hiddenCustomers, setHiddenCustomers] = useState<string[]>([]);
    const [canShowName, setcanShowName] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    let selectedProductLevel = useStore((state) => state.selectedProductLevel);

    useEffect(() => {
        setHiddenCustomers([]);
    }, [])

    useEffect(() => {
        if (selectedProducts.length === 0) {
            setHiddenCustomers([]);
            setcanShowCustomerFilter(false);
            setcanShowName(false);
        }
    }, [selectedProducts])

    useEffect(() => {
        if (isChecked) {
            setHiddenCustomers(sortedAllCustomers)
            filterCustomer(sortedAllCustomers)
            if (setHiddenCustomersArray !== undefined) {
                setHiddenCustomersArray(sortedAllCustomers)
            }
        } else {
            setHiddenCustomers([])
            filterCustomer([])
            if (setHiddenCustomersArray !== undefined) {
                setHiddenCustomersArray([])
            }
        }
    }, [isChecked])

    const handleSort: CustomSortFuction = (field, direction) => {
        if (customSort) {
            customSort(field, direction)
            setcanShowName(true)
        }
    }

    const ScopeFilter = () =>
        <div>
            <button className="bg-white border border-primary text-primary rounded text-sm p-2" onClick={() => setcanShowCustomerFilter(!canShowCustomerFilter)}>Filter {scope === Scope.CUSTOMER ? 'Customer' : scope.toLowerCase() === Scope.PCUST ? 'Customer' : 'Channel'}</button>
            {canShowCustomerFilter
                ? <div className="absolute bg-white top-12 flex flex-col p-5 border shadow border-primary rounded z-50 filter-customer-channel-div" onMouseLeave={() => setcanShowCustomerFilter(false)}>
                    <div className="hover:bg-blue-200 p-1 flex justify-start items-center gap-1" onClick={(e) => { setIsChecked(!isChecked) }}>
                        <input type="checkbox" checked={hiddenCustomers.length === 0} />
                        <span className="text-sm"> <b>All</b> </span>
                    </div>
                    {sortedAllCustomers.map((customer, idx) => {
                        return <div key={idx} className="hover:bg-blue-200 p-1 flex justify-start items-center gap-1" onClick={() => {
                            if (hiddenCustomers.includes(customer)) {
                                const except = hiddenCustomers.filter(e => e !== customer)
                                setHiddenCustomers(except)
                                filterCustomer(except)
                                if (setHiddenCustomersArray !== undefined) {
                                    setHiddenCustomersArray(except)
                                }
                            } else {
                                const added = [...hiddenCustomers, customer]
                                setHiddenCustomers(added)
                                filterCustomer(added)
                                if (setHiddenCustomersArray !== undefined) {
                                    setHiddenCustomersArray(added)
                                }
                            }
                        }}>
                            <input type="checkbox" checked={!hiddenCustomers.includes(customer)} />
                            <span className="text-sm"> {customer} </span>
                        </div>
                    })
                    }
                </div> : null
            }
        </div>

    return <div className="flex flex-col">
        {sortedAllCustomers.length === 0 && records.length === 0
            ? <div className="flex">
                <div className="flex flex-col bg-white ">
                    <div onClick={() => addProductClick()} className="cursor-pointer px-5 py-4 h-16  border-t border-l border-gray-300 ">
                        <svg className={selectedProductLevel !== 'category' ? "fill-primary cursor-pointer" : ""} focusable="false" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" id="prod_picker1"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>
                    </div>
                    <div className="h-full border-l border-b border-gray-300"></div>
                </div>
                <div className="border-b border-gray-300 w-full">
                    <SingleGrid
                        records={[...records]}
                        handleViewMore={handleViewMore}
                        scope={Scope.CUSTOMER}
                        priceType={priceType}
                        source={source}
                        showName={true}
                        showOptions={true}
                        unselectRow={unselectRow}
                        selectedCountry={selectedCountry}
                        loading={loading}
                        customSort={handleSort}
                    />
                </div>
            </div> : null
        }
        {records.length === 0 && sortedAllCustomers.length > 0 && selectedProducts.length > 0
            ? <div className="flex  relative w-full h-full z-30 " style={{ minHeight: '5000px' }}>
                <div className={`flex flex-col bg-white ${!canShowName || (records.length === 0 && selectedProducts.length > 0) ? 'w-96' : 'w-0'} absolute left-0 top-0`}>
                    {!canShowName ?
                        <div className="flex h-16 border border-gray-300 justify-between items-center p-2 z-50">
                            <div className="flex items-center justify-between">
                                <div className="flex justify-start items-center">
                                    <div onClick={() => addProductClick()} className="cursor-pointer px-2 py-4  ">
                                        <svg className={selectedProductLevel !== 'category' ? "fill-primary cursor-pointer" : ""} focusable="false" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" id="prod_picker1">
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                                        </svg>
                                    </div>
                                    <span className="font-bold">Product</span>
                                </div>
                            </div>
                            <ScopeFilter />
                        </div>
                        : null
                    }
                </div>
                <div className={` absolute ${!canShowName || (records.length === 0 && selectedProducts.length > 0) ? 'left-96 ' : 'left-0'} right-0 border-t border-gray-300`}>
                    <div className="flex relative w-full   overflow-x-scroll">
                        <div className="flex flex-col border-l border-t border-gray-300 w-full">
                            <div className="flex  h-16 self-center items-center" ></div>
                            <SingleGrid records={[]} handleViewMore={handleViewMore} scope={Scope.CUSTOMER} priceType={priceType} source={source}
                                showName={canShowName} showOptions={true} unselectRow={unselectRow} selectedCountry={selectedCountry} loading={loading} customSort={handleSort} />
                        </div>
                    </div>
                </div>
            </div> : null
        }
        {(records && records.length > 0) && selectedProducts.length > 0
            ? <div className="flex  relative w-full h-full z-30 " style={{ minHeight: '5000px' }}>
                <div className={`flex flex-col bg-white ${!canShowName || (records.length === 0 && selectedProducts.length > 0) ? 'w-96' : 'w-0'} absolute left-0 top-0`}>
                    {!canShowName ?
                        <div className="flex h-16 border border-gray-300 justify-between items-center p-2 z-50">
                            <div className="flex items-center justify-between">
                                <div className="flex justify-start items-center">
                                    <div onClick={() => addProductClick()} className="cursor-pointer px-2 py-4  ">
                                        <svg className={selectedProductLevel !== 'category' ? "fill-primary cursor-pointer" : ""} focusable="false" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" id="prod_picker1">
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                                        </svg>
                                    </div>
                                    <span className="font-bold">Product</span>
                                </div>
                            </div>
                            <ScopeFilter />
                        </div>
                        : null
                    }
                    {!canShowName || (records.length === 0 && selectedProducts.length > 0) ?
                        <div className="h-full border border-gray-300 pt-16">
                            {selectedProducts.map((product, idx) => {
                                {
                                    return indexOf(removedProductList, product) <= 0 ?
                                        <div className="h-24 flex justify-center items-center  border-gray-300 p-2 m-0" key={idx}>
                                            <span className="  bg-primary text-white  rounded  text-sm p-2 font-bold">{product}</span>
                                        </div> : null
                                }
                            })}
                        </div> : null
                    }
                </div>

                <div className={` absolute ${!canShowName || (records.length === 0 && selectedProducts.length > 0) ? 'left-96 ' : 'left-0'} right-0 border-t border-gray-300`}>
                    <div className="flex relative w-full   overflow-x-scroll">
                        {sortedRecords.map((record, idx) => {
                            return <div className="flex flex-col border-l border-t border-gray-300 w-full" key={idx}>
                                <div className="flex  h-16 self-center items-center" >{record.customer}</div>
                                <SingleGrid
                                    records={record.data}
                                    handleViewMore={handleViewMore}
                                    scope={Scope.CUSTOMER}
                                    priceType={priceType}
                                    source={source}
                                    showName={canShowName}
                                    showOptions={true}
                                    unselectRow={unselectRow}
                                    selectedCountry={selectedCountry}
                                    key={idx}
                                    loading={loading}
                                    customSort={handleSort}
                                />
                            </div>
                        })
                        }
                    </div>
                </div>
                {/* <div className="flex flex-col bg-white absolute w-60 right-0">
                    <div className="h-full border-l border-b border-r border-gray-300 pt-16">
                        {
                            !canShowName && selectedProducts
                            ? selectedProducts.map((product,idx) => {
                                return <div className="h-24 w-60 flex justify-center items-center  border-gray-300 p-2 m-0" key={idx}>
                                    <svg className=" fill-red-600 w-5 cursor-pointer" focusable="false" viewBox="0 0 24 24" aria-hidden="true" onClick={() => unselectRow(product)}><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>
                                    <div className="ml-2 flex gap-5 cursor-pointer" onClick={()=> handleViewMore(product)}>
                                        <svg className=" bg-primary w-10 h-10 fill-white rounded-full p-1" focusable="false" viewBox="0 0 24 24" aria-hidden="true" ><path d="M12 8V4l8 8-8 8v-4H4V8z"></path></svg>    
                                        <span className="text-primary font-bold text-lg">View more</span>
                                    </div>
                                </div>
                            })
                            : null
                        }
                    </div>
                </div> */}
            </div> : null
        }
    </div>
}