import React, {useState, useEffect, Dispatch, SetStateAction, Fragment} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import {get} from 'lodash';

import useStore from "../../../../store";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    background: '#fff',
    border: "1px solid rgba(224, 224, 224, 1)",
    fontSize: "12px",
    maxHeight: '1px',
    overflow:'scroll'
  },
  tableHead: {
    fontSize: '12px',
    backgroundColor: '#bfbaba',
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
	    width: 5,
      height: 5,
    },
    "&::-webkit-scrollbar-track": {
	    boxShadow: "inset 0 0 5px #eee",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-thumb": {
	    backgroundColor: "#D3D3D3",
      borderRadius: 5,
      height: '40px',
    }
  },
  hyperLink: {
    color:'#1976d2',
    borderBottom: ''
  }
}));

const styles = {
  color:'#1976d2',
  borderBottom:'1px solid #1976d2'
}
interface EnhancedTableProps {
  numPromoSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  setOrder: Func,
  setOrderBy: OrderByFunc,
}

type Data = {
  customer?: string,
  promoName?: string,
  promoFrom?: string,
  promoTo?: string,
  fixedCosts?: string,
  products?: [
    {
      sector: string,
      category: string,
      subCategory: string,
      brand: string,
      subBrand: string,
      ean: string
    }
  ]
}

type TableDataType = {
  promoName?: string,
  customer?: string,
  promoFrom?: string,
  promoTo?: string,
  fixedCosts?: string,
}

type Order = 'asc' | 'desc';
type Func = Dispatch<SetStateAction<Order>>;
type OrderByFunc = Dispatch<SetStateAction<string>>;

function descendingComparator(a: any, b: any, orderBy: any) {
  if (orderBy === 'promoFrom' || orderBy === 'promoTo') {
    return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const sortHandler = (property : string, orderBy: string, order: string, setOrder: Func, setOrderBy: OrderByFunc) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly TableDataType[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableFieldArray = [
  {
    name: "Promo Name",
    field: "promoName",
    sticky: true,
    width: 200,
    left: 0,
    isSortable: true,
    alignLeft: true,
    title: 'Promo Name',
    numeric: false,
  },
  {
    name: "Customer",
    field: "customer",
    sticky: false,
    width: 200,
    left: 0,
    isSortable: true,
    alignLeft: false,
    title: 'Customer',
    numeric: false,
  },

  {
    name: "From Date",
    field: "promoFrom",
    sticky: false,
    width: 200,
    left: 0,
    isSortable: true,
    alignLeft: false,
    title: 'From Date(yyyy-mm-dd)',
    numeric: false,
  },
  {
    name: "To date",
    field: "promoTo",
    sticky: false,
    width: 200,
    left: 0,
    isSortable: true,
    alignLeft: false,
    title: 'To Date(yyyy-mm-dd)',
    numeric: false,
  },
  {
    name: "Fixed Cost",
    field: "fixedCosts",
    sticky: false,
    width: 200,
    left: 0,
    isSortable: true,  
    alignLeft: false,
    title: 'Fixed Cost',
    numeric: false,
  },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useStyles(); 
  const { onSelectAllClick, order, orderBy, numPromoSelected, rowCount, setOrder, setOrderBy } = props;

  return (
    <TableHead>
      <TableRow >
        <TableCell padding="checkbox" 
          className={classes.tableHead} 
          style={{
            background: 'rgb(246, 248, 252)',
          }}>
          <Checkbox
            color="primary"
            indeterminate={numPromoSelected > 0 && numPromoSelected < rowCount}
            checked={rowCount > 0 && numPromoSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all promotions',
            }}

          />
        </TableCell>
        {tableFieldArray.map((headCell) => (
          <TableCell
            key={headCell.field}
            className={classes.tableHead}
            sortDirection={
              orderBy === headCell.field ? order : false
            }
            align={headCell.field === 'promoName' ? 'left' : 'center'}
            style={{
              position: "sticky",
              left: headCell.sticky ? "sticky" : "",
              zIndex: headCell.sticky ? 1200 : 200,
              top: 0,
              fontWeight: 600,
              background: 'rgb(246, 248, 252)',
              fontSize: "12px",
              padding: '5px 4px',
              borderRight: '1px solid #ccc',
            }}
            title={headCell.title}
          >
            {headCell.isSortable ? 
              <TableSortLabel
                active={true}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={() => sortHandler(headCell.field, orderBy, order, setOrder, setOrderBy)}
              > 
                {headCell.name} 
              </TableSortLabel>
              : <span>{headCell.name}</span>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ViewSimulationTable({tableDataArray, onClickPromoName, setSelectedPromotions, setShowError, fromDate, toDate}:any) {
  const classes = useStyles();

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('promoFrom');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [tableData, setTableData] = useState([]);
  const [tableRows, setTableRows] = useState<TableDataType[]>([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);

  const selectedSimulationCustomers: any = useStore((state) => state.selectedSimulationCustomers);
  const selectedSimulationPromoNames: any =   useStore((state) => state.selectedSimulationPromoNames);
  const selectedSimulationProducts = useStore((state) => state.selectedSimulationProducts);
  const selectedSimulationFilterProducts: any = useStore((state) => state.selectedSimulationFilterProducts);

  useEffect(() => {
    setTableData([]);
  }, [])

  useEffect(() => {
    setTableData(get(tableDataArray, 'data', []));
  }, [tableDataArray])

  function createData(promoData: Data[]) {
    const promoTableData: TableDataType[]  = [];
    promoData.forEach((data: Data) => {
      const returnData : TableDataType =  {
        promoName: data.promoName,
        customer: data.customer,
        promoFrom: data.promoFrom,
        promoTo: data.promoTo,
        fixedCosts :data.fixedCosts,
      }
      promoTableData.push(returnData);
    })
    return promoTableData;
  }

  useEffect(() => {
    if(selectedSimulationPromoNames.length > 0) {
        const filteredTableData = tableData.filter((data: any) => {
          return selectedSimulationPromoNames.indexOf(data.promoName) >= 0 ? true : false 
        })
        const tableRows : TableDataType[] = createData(filteredTableData);
        setTableRows(tableRows);
    } else {
      const tableRows : TableDataType[] = createData(tableData);
      setTableRows(tableRows);
    }
  }, [selectedSimulationPromoNames])

  useEffect(() => {
    const tableRows: TableDataType[] = createData(get(tableDataArray, 'data', []));
    setTableRows(tableRows);
    setSelected([]);
  }, [tableData])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc'; 
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    if (event.target.checked) {
      const newSelected: string[] = tableRows.map((row: any) => row.promoName) as string[];
      setSelected(newSelected);
      setSelectedPromotions(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectPromo = (event: React.MouseEvent<unknown>, name: string) => {
    setShowError(false);
    const selectedIndex = selected.indexOf(name);
    let promoSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      promoSelected = promoSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      promoSelected = promoSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      promoSelected = promoSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      promoSelected = promoSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(promoSelected);
    setSelectedPromotions(promoSelected);
  };

  const getFixedCostValue = (value : string) => {
    return value === 'N' ? 'Exclude' : 'Include';
  }

  const visibleRows = React.useMemo(
    () => stableSort(tableRows, getComparator(order, orderBy)),
    [order, orderBy, tableRows],
  );

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
    
  return (
    <Fragment>
      <Box sx={{ width: '98%', margin: 'auto', boxShadow: 'unset'}}>
          <TableContainer className={classes.scrollBar} style={{maxHeight: "55vh", border: '1px solid #ccc'}}>
            <Table
              aria-labelledby="Promotion Table"
              size='small'
              stickyHeader={true}
              style={{
                minWidth: 650,
                maxHeight: 500,
                background: '#fff',
                fontSize: "11px",
                overflow:'scroll',
              }}
            >
              <EnhancedTableHead
                numPromoSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                rowCount={visibleRows.length}
                onRequestSort={handleRequestSort}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
              />
              {visibleRows.length > 0 ? <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.promoName as string);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.promoName}
                      sx={{cursor: 'pointer'}}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          onClick={(event) => handleSelectPromo(event, row.promoName as string)}
                        />
                      </TableCell>
                      {tableFieldArray.map((item) => (
                        <TableCell
                          id={labelId}
                          align={item.field === 'promoName' ? 'left' : 'center'}
                          className={classes.hyperLink}
                          style={{
                            fontWeight: 500,
                            fontSize: "11px",
                            padding: '9px 4px',
                            borderRight: '1px solid #ccc',
                            lineHeight: '10px'
                          }}
                        >
                          <span 
                            style={item.field === 'promoName' ? styles : {}}
                            onClick={item.field === 'promoName' ? (event) => onClickPromoName(event, row[item.field] as string) : ()=> {}}
                          >
                            {item.field === 'fixedCosts' ? getFixedCostValue(row[item.field] as string) : row[item.field]}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody> : null}
            </Table>
            {visibleRows.length === 0 ? <div style={{width: '100%', padding: '10px', margin: 'auto', textAlign: 'center', fontSize: '12px'}}>
              {isFilterSelected ? ('No Data with Selected Filters...') : ('No Data to Display')}
            </div> : null }
          </TableContainer>
      </Box>
    </Fragment>
  );
}