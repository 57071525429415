import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { makeStyles } from '@material-ui/core/styles';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css';
import './searchStyles.css';

import useStore from './store';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(10),
  },
  paper: {
    // border: '1px solid',
    padding: theme.spacing(1),
    width: 407,
    backgroundColor: theme.palette.background.light,
    overflowY: 'scroll'
  },
  listbox: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  input: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    float: 'right',
    padding: 10,
    color: theme.palette.primary.main
  },
  divider: {
    float: 'right',
    height: 28,
    margin: 4,
  },
  checkBox: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,

  }
}));


const ProductItemSelect = (props) => {
  const classes = useStyles();

  //SELECTION: KC PRODUCT
  const setSelectedKcProduct = useStore((state) => state.setSelectedKcProduct);
  let selectedKcProduct = useStore((state) => state.selectedKcProduct);


  const setSelectedBaseLineProduct = useStore((state) => state.setSelectedBaseLineProduct);
  let selectedBaseLineProduct = useStore((state) => state.selectedBaseLineProduct);


  //SERVICE: KC PRODUCT
  const [kcProduct, setKcProduct] = React.useState(0);

  //setKcProduct('');
  //setSelectedKcProduct('');

  let pathValue = '';


  const onAction = (node, action) => {
    console.log('onAction::', action, node)
  }
  const onNodeToggle = currentNode => {
    console.log('onNodeToggle::', currentNode)
  }


useEffect(() => {
    setSelectedBaseLineProduct(pathValue)
    console.log("newvalue", pathValue)
}, [pathValue])


  const onChange = (currentNode, selectedNodes) => {
    //setKcProduct(currentNode.label);
    //setSelectedKcProduct(selectedNodes[0].label);
    //this.setKcProduct(sNode);

    //console.log(selectedKcProduct)

    console.log("Selected Kc Product", currentNode._depth, currentNode.label)
    console.log("Selected Kc Product", selectedNodes)

    

    const getdepthValue = (depth) => {
      switch (depth) {
        case 1:          
          return  'categories';
          break;
        case 3:
          return 'subCategories';
          break;
        case 5:
          return 'brands';
          break;
        case 7:
          return 'subBrands';
          break;
        case 8:
          return 'eans';
          break;
        default:
          break;  
      }
      return '';
    }
    


    if(selectedNodes.length > 0){
      selectedNodes.map((item,i) => { 
        if(getdepthValue(item._depth)){          
          pathValue += `&${getdepthValue(item._depth)}=${item.label}`;
        }
        
      })
    }
   
  };


  const valuesSelected = () => {
    setSelectedBaseLineProduct(pathValue);   
  }
  
  return (
    <DropdownTreeSelect
      disableUnderline
      texts={{ placeholder: 'Search' }}
      className={classes.root}
      data={props.data}
      onChange={onChange}
      onAction={onAction}
      onNodeToggle={onNodeToggle} 
      onBlur = {valuesSelected}
      />
  )
}
export default ProductItemSelect;
