import React, {useState, useEffect} from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AlertDialog } from "../CreateSimulations/InputSection/AlertDialog";

import SimulationFilter from './SimulationFilter/SimulationFilter';
import ViewSimulationTable from './SimulationTable/ViewSimulationTable';
import useStore from "../../../store";
import {useGetSimulatedPromotions} from '../../../services/queries';
import {useDeletePromotion} from '../../../services/mutations';
import { AppQueryClient } from "../../../services/clients/query-client";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Data = {
  customer?: string,
  promoName?: string,
  promoFrom?: string,
  promoTo?: string,
  fixedCost?: string,
  products?: [
    {
      sector: string,
      category: string,
      subCategory: string,
      brand: string,
      subBrand: string,
      ean: string
    }
  ]
}

const useStyles = makeStyles((theme) => ({
  btnDivStyles: {
    margin: '0 13px 0 0'
  },
  spinner: {
		position: 'absolute',
		height: '50px',
		width: '50px',
		top: '50%',
		left:'50%',
	},
}));

const ViewSimulation = (props: any) => {
  const classes = useStyles();
  const selectedCountry = useStore((state) => state.selectedCountry);

  const [selectedPromotion, setSelectedPromotions] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [country, setCountry] = useState('');
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [isDeleteError, setIsDeleteError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [simulationPayload, setSimulationPayload] = useState({
    "country": selectedCountry,
    "customers": [],
    "promoFrom": '',
    "promoTo": '',
    "sectors": [],
    "categories": [],
    "subCategories": [],
    "brands": [],
    "subBrands": [],
    "eans": [],
  });

  const initialDetails = {
    promoName : '',
    type: '',
    fromView: false,
  }
 
  const setSelectedSimulationFromDate = useStore((state) => state.setSelectedSimulationFromDate);
  const setSelectedSimulationToDate = useStore((state) => state.setSelectedSimulationToDate);
  const setSelectedPromoDetails = useStore((state) => state.setSelectedPromoDetails);
  const setSimulationFilterProducts = useStore((state) => state.setSimulationFilterProducts);
  const setSelectedSimulationProducts = useStore((state) => state.setSelectedSimulationProducts);
  const setSelectedSimulationFilterProducts = useStore((state) => state.setSelectedSimulationFilterProducts);
  const selectedSimulationCustomers: any = useStore((state) => state.selectedSimulationCustomers);
  const selectedSimulationProducts = useStore((state) => state.selectedSimulationProducts);
  const selectedSimulationFilterProducts: any = useStore((state) => state.selectedSimulationFilterProducts);

  const { data: simulatedPromoTableData, isError: promoError, isLoading: isSimulationDataLoading } = useGetSimulatedPromotions(simulationPayload);
  const { mutate: deletePromotion, data: deleteMessage, isError: deleteError, isSuccess: deleteSuccess, isLoading: isDeletePromotion } = useDeletePromotion();
  
  useEffect(() => {
    setSelectedSimulationFromDate('');
    setSelectedSimulationToDate('');
    setSelectedPromoDetails(initialDetails);
    setSimulationFilterProducts([]);
    setSelectedSimulationFilterProducts([]);
    setSelectedSimulationProducts([]);
  }, [])

  useEffect(() => {
    setCountry(selectedCountry);
  }, [selectedCountry])

  useEffect(() => {
    if(deleteMessage && deleteMessage?.data && deleteMessage?.data.message) {
      setIsDeleteSuccess(true);
      setCountry(selectedCountry);
    }
  }, [deleteMessage])

  useEffect(() => {
    if(deleteError) {
      setIsDeleteError(true);
      setCountry(selectedCountry);
    }
  }, [deleteError, deleteMessage])

  useEffect(() => {
    if(isDeletePromotion) {
      setCountry('')
      setSelectedPromotions([]);
    } else if(!isDeletePromotion) {
      setCountry(selectedCountry);
    }
  }, [isDeletePromotion])

  useEffect(() => {
    setShowError(false);
    setErrorMessage('');
  }, [setSelectedPromotions])

  useEffect(() => {
    setSimulationPayload({
      "country": selectedCountry,
      "customers": selectedSimulationCustomers ? selectedSimulationCustomers : [],
      "promoFrom": fromDate !== '' ? getFormattedDate(fromDate) : '',
      "promoTo": toDate !== '' ? getFormattedDate(toDate) : '',
      "sectors": selectedSimulationProducts.length > 0 && selectedSimulationFilterProducts?.sector ? selectedSimulationFilterProducts?.sector : [],
      "categories": selectedSimulationProducts.length > 0 && selectedSimulationFilterProducts?.category ? selectedSimulationFilterProducts?.category : [],
      "subCategories": selectedSimulationProducts.length > 0 && selectedSimulationFilterProducts?.subCategory ? selectedSimulationFilterProducts?.subCategory : [],
      "brands": selectedSimulationProducts.length > 0 && selectedSimulationFilterProducts?.brand ? selectedSimulationFilterProducts?.brand : [],
      "subBrands": selectedSimulationProducts.length > 0 && selectedSimulationFilterProducts?.subBrand ? selectedSimulationFilterProducts?.subBrand : [],
      "eans": selectedSimulationProducts.length > 0 && selectedSimulationFilterProducts?.eans ? selectedSimulationFilterProducts?.eans : [],
    })
  }, [selectedSimulationProducts,
    selectedSimulationFilterProducts,
    selectedSimulationCustomers,
    fromDate,
    toDate,])

  const getFormattedDate = (selectedDate : string) => {
    var date = new Date(selectedDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const getPromoNames = (promoData: Data[]) => {
    const promoNames = promoData ? promoData.map((promo:Data) => promo.promoName).sort((a: any, b: any) => a.toLowerCase() > b.toLowerCase() ? 1 : -1) : [];
    return promoNames;
  }

  const getMessage = () => {
    return selectedPromotion.length > 1 ? `Are you sure to delete selected ${selectedPromotion.length} promotions` 
      : `Are you sure to delete selected ${selectedPromotion.length} promotion`;
  }

  const onClickPromoName = (event:React.MouseEvent<unknown>, promoName: string) => {
    setSelectedPromoDetails({
      promoName : promoName,
      type: 'view',
      fromView: true,
    })
    props.setSubValue(0);
    setShowError(false);
    setErrorMessage('');
  }

  const handleDuplicatePromotion = (event:React.MouseEvent<unknown>) => {
    if(!selectedPromotion.length) {
      setShowError(true);
      setErrorMessage('Please select promotion');
    } else if(selectedPromotion.length > 1) {
      setShowError(true);
      setErrorMessage('Only one promotion has to be selected for duplication');
    } else {
      setSelectedPromoDetails({
        promoName : selectedPromotion ? selectedPromotion[0] : '',
        type: 'duplicate',
        fromView: true,
      })
      props.setSubValue(0);
      setShowError(false);
      setErrorMessage('');
    }
  }

  const handleDeletePromo = (event:React.MouseEvent<unknown>) => {
    if(!selectedPromotion.length) {
      setShowError(true);
      setErrorMessage('Please select promotion');
    } else {
      setOpenDialog(true);
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsDeleteSuccess(false);
  };

  const handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsDeleteError(false);
  };

  const handleYes = () => {
    setOpenDialog(false);
    deletePromotion({country : selectedCountry, simulationNames: selectedPromotion});

    setTimeout(() => {
      AppQueryClient.invalidateQueries("useDeletePromotion");
    }, 5000);
  }

  const handleNo = () => {
    setOpenDialog(false);
    setSelectedPromotions([]);
  }

  const availablePromotionName = React.useMemo(
    () => simulatedPromoTableData ? getPromoNames(simulatedPromoTableData.data) : [],
    [simulatedPromoTableData]
  );

  return (
    <Card raised={true} className="p-4">
      <Paper style={{paddingTop: '5px', paddingBottom: '20px'}}>
        <div className='flex flex-col mx-auto center'>
          <SimulationFilter
            promotions={availablePromotionName as []}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
          />
          <div className="flex my-5 mx-auto justify-end" style={{width:'97%'}}>
            <Box component="span" className={classes.btnDivStyles}>
              <Button variant="contained" onClick={(event) => handleDuplicatePromotion(event)}>Duplicate</Button>
            </Box>
            <Box component="span">
              <Button variant="contained" onClick={(event) => handleDeletePromo(event)}>Delete</Button>
            </Box>
          </div>
          {showError && errorMessage ? 
            <div className="flex mb-3 mx-auto justify-end" style={{width:'97%', fontSize:'0.75rem'}}>
              <label style={{fontSize: '0.75rem', color: 'rgb(211, 47, 47)', lineHeight: '1.66px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, 
                letterSpacing: '0.03333em'}}>{errorMessage}</label>
            </div> 
          : null}
            <ViewSimulationTable
              tableDataArray={simulatedPromoTableData}
              onClickPromoName={onClickPromoName}
              setSelectedPromotions={setSelectedPromotions}
              setShowError={setShowError}
              fromDate={fromDate}
              toDate={toDate}
            />
        </div>
      </Paper>
      <Snackbar onClose={handleClose} open={isDeleteSuccess} autoHideDuration={6000}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Selected promotions deleted successfully!
        </Alert>
      </Snackbar>
      <Snackbar onClose={handleCloseError} open={isDeleteError} autoHideDuration={6000}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Something went wrong please try again
        </Alert>
      </Snackbar>
      <Dialog
        open={isDeletePromotion || isSimulationDataLoading}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="pt-0">
          {"Your request is being processed"}
        </DialogTitle>
      </Dialog>
      <AlertDialog 
        openDialog={openDialog} 
        handleYes={handleYes} 
        handleNo={handleNo} 
        message={getMessage()} />
    </Card>
  )
}

export default ViewSimulation;