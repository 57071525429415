import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ItemSearch from './Elasticity/Widgets/SearchWidget/ItemSearch';
import useStore from './store';
import { processProductHierarchy } from './util';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '600px',
    width: '450px',
    zIndex: 5,
    backgroundColor: 'white',
    overflowY: 'auto',
    direction: 'rtl',
    padding: '1rem',
    boxShadow: '5px 5px 5px #d6d9dc',
    marginTop: '5px',
    borderRadius: '5px',
  },
  paperDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  container: {
    backgroundColor: '#F6F8FC',
    height: '100vh',
  },
  searchBar: {
    display: 'inline-block',
    paddingRight: '2.5rem',
  },
  searchContainer: {
    background: '#F6F8FC',
    width: '100%',
    boxShadow: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    color: 'black',
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  autoCompleteSearch: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    background: '#FFFFFF',
  },
  gridContainer: {
    background: '#F6F8FC',
  },
  showCheckBox: {},
  appTab: {
    fontSize: '20px',
  },
  hide: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '650',
    opacity: '0.70',
  },
  open: {
    fontSize: '14px',
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

let display = {
  categories: { name: 'Categories', isOpen: false },
  subCategories: { name: 'Sub Categories', isOpen: false },
  brands: { name: 'Brands', isOpen: false },
  subBrands: { name: 'Sub Brands', isOpen: false },
  products: { name: 'Products', isOpen: false },
};

const NestedBaselineProductsSearch = (props) => {
  const classes = useStyles();

  const selectedProductsHierarchy = useStore((state) => state.selectedProductsHierarchy);
  const setSelectedProductsHierarchy = useStore((state) => state.setSelectedProductsHierarchy);

  const initial = {
    categories: false,
    subCategories: false,
    brands: false,
    subBrands: false,
    products: false,
  };

  const [iconState, setIconState] = useState(initial);
  let baseLineProductCheckBox = useStore(
    (state) => state.baseLineProductCheckBox
  );
  const setBaseLineProductCheckBox = useStore(
    (state) => state.setBaseLineProductCheckBox
  );

  function handleOpen(x) {
    setIconState({
      ...iconState,
      [x]: !iconState[x],
    });
  }

  function transformDataUnique(values) {
    let arr = [
      'categories',
      'subCategories',
      'brands',
      'subBrands',
      'products',
    ];
    arr.map((val) => {
      values[val] = [
        ...new Map(values[val].map((item) => [item['name'], item])).values(),
      ];
    });
    arr.map((val) => {
      values[val].sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
      });
    });

    return values;
  }
  const [kcProductsData, setkcProductsData] = useState(props.data);

  function isSelected(x) {
    return x.isChecked;
  }
  function putAll(x) {
    return x.name;
  }
  //stores powerbi report url
  let selectedBaseLineProductNew = useStore(
    (state) => state.selectedBaseLineProductNew
  );
  const setSelectedBaseLineProductNew = useStore(
    (state) => state.setSelectedBaseLineProductNew
  );

  let selectedBaseLineProductState = useStore(
    (state) => state.selectedBaseLineProductState
  );
  const setSelectedBaseLineProductState = useStore(
    (state) => state.setSelectedBaseLineProductState
  );

  let selectedBaseLineCustomerNew = useStore(
    (state) => state.selectedBaseLineCustomerNew
  );
  const setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );


  //during initial render displays all values for diff levels
  function calculateInitial() {
    const processed = processProductHierarchy(kcProductsData, null)
    const transformed = transformDataUnique(processed);
    return transformed;
  }

  useEffect(() => {
    if (props.previouslySelected && props.previouslySelected.length === 0) {
      let Values = calculateInitial();
      setSelectedBaseLineProductState({
        ...Values,
      });
      setBaseLineProductCheckBox(initial);
      setSelectedBaseLineProductNew("");
      setSelectedBaseLineFilter(selectedBaseLineProductNew);
    } else if (props.previouslySelected && props.previouslySelected.length > 0) {
      updateSelectedLevel()
    }
  }, [props]);

  useEffect(() => {
    if (props.tabName === 'preROI_test') {
      let Values = calculateInitial();
      let arr = ["categories", "subCategories", "brands", "subBrands", "products"];
      let countObj = {
        "categories": 0,
        "subCategories": 0,
        "brands": 0,
        "subBrands": 0,
        "products": 0
      };
      if (props.previouslySelected) {
        props.previouslySelected.map(value => {
          for (let x of arr) {
            if (Values[x].filter(o => o.name === value).length) {
              countObj[x] = countObj[x] + 1;
              let index = Values[x].findIndex(o => o.name === value);
              Values[x][index].isChecked = true;
            }
          }
        })
      }

      setSelectedBaseLineProductState({
        ...Values,
      });

      let obj = {};
      for (let x of arr) {
        obj[x] = Values[x].length === countObj[x];
      }
      setBaseLineProductCheckBox(obj);
      setSelectedBaseLineFilter("");
    }
  }, []);

  useEffect(() => {
    if (props.clear) {
      props.resetClear();
    }
  }, [props]);


  const updateSelectedLevel = (level) => {
    let values = { ...selectedBaseLineProductState };
    if (level === 'products')
      return;

    const processed = processProductHierarchy(kcProductsData, values)
    const transformed = transformDataUnique(processed);
    setSelectedBaseLineProductState({
      ...transformed,
    });
  };

  function generateUrl() {
    let selectedArr = [];
    let reformatKcStr = '';
    let values = { ...selectedBaseLineProductState };
    let arr = ['products', 'subBrands', 'brands', 'subCategories', 'categories'];
    //iterating backward
    for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      let selectedNodes = values[level].filter(isSelected).map(putAll);
      if (selectedNodes.length !== 0) {
        level = (level === 'products') ? 'eans' : level;
        selectedNodes.map((val, i) => {
          reformatKcStr += `&${level}=${val}`;
          selectedArr.push(val);
        });
      }
    }
    return reformatKcStr === '' ? { url: '', selectedArr: [] } : { url: reformatKcStr, selectedArr: selectedArr };
  }

  const unCheckBelowParentLevels = (x) => {
    let toggleParentCheckBox = { ...baseLineProductCheckBox };
    let flagP = false;
    for (let [key, value] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }
    setBaseLineProductCheckBox({ ...toggleParentCheckBox });
  };

  const onSelection = (data) => {
    console.log("selectedBaseLineProductState",selectedBaseLineProductState)
    let values = { ...selectedBaseLineProductState };
    let x = data[0]; //parent level name:subcat
    let y = data[1]; //value in subcat ex-subcat1
    let selectedArr = [];
    values[x].map(function (item) {
      if (item.name === y) item.isChecked = !item.isChecked;

      if (item.isChecked) {
        selectedArr.push(item.name);
      }
    });

    setSelectedProductsHierarchy({
      ...selectedProductsHierarchy,
      [x]: selectedArr
    });

    setSelectedBaseLineProductState({
      ...values,
    });

    let toggleParentCheckBox = { ...baseLineProductCheckBox };
    let flagP = false;
    for (let [key, value] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }

    if (selectedArr.length === selectedBaseLineProductState[x].length) {
      toggleParentCheckBox[x] = true;
    }
    setBaseLineProductCheckBox({ ...toggleParentCheckBox });

    updateSelectedLevel(x);

    let obj = generateUrl();
    let completeUrl = selectedBaseLineCustomerNew + obj['url'];
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setSelectedBaseLineProductNew(obj['url']);
    }
    props.handleSelect(obj['selectedArr']);
  };

  function SelectAllChildren(parentLevel) {
    let values = { ...selectedBaseLineProductState };
    let selectedArr = [];
    values[parentLevel].map(
      (x) => ((x.isChecked = true), selectedArr.push(x.name))
    );
    setSelectedBaseLineProductState(values);

    updateSelectedLevel(parentLevel);

    let obj = generateUrl();
    let completeUrl = selectedBaseLineCustomerNew + obj['url'];
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setSelectedBaseLineProductNew(obj['url']);
    }
    props.handleSelect(obj['selectedArr']);

  }

  function DeSelectAllChildren(parentLevel) {
    let values = { ...selectedBaseLineProductState };
    values[parentLevel].map((x) => (x.isChecked = false));

    setSelectedBaseLineProductState(values);
    unCheckBelowParentLevels(parentLevel);

    updateSelectedLevel(parentLevel);
    let obj = generateUrl([]);
    let completeUrl = selectedBaseLineCustomerNew + obj['url'];
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setSelectedBaseLineProductNew(obj['url']);
    }
    props.handleSelect(obj['selectedArr']);
  }

  const handleParentClick = (e) => {
    let x = e.target.value;
    setBaseLineProductCheckBox({
      ...baseLineProductCheckBox,
      [x]: !baseLineProductCheckBox[x],
    });

    setSelectedProductsHierarchy({
      ...selectedProductsHierarchy,
      [x]: []
    });

    if (!baseLineProductCheckBox[x]) SelectAllChildren(x);
    else DeSelectAllChildren(x);
  };

  return (
    <div className={classes.root}>
      <div style={{ direction: 'ltr' }}>
        {Object.entries(display).map(([key, item]) => (
          <div key={key}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                className="productExpander w-4"
                onClick={() => handleOpen(key)}
                style={{ cursor: 'pointer', justifyContent: 'center' }}
              >
                {iconState[key] ? '-' : '+'}
              </div>
              <input
                type='checkbox'
                value={key}
                checked={baseLineProductCheckBox[key]}
                onChange={handleParentClick}
              />
              <label className={`${iconState[key] ? classes.open : classes.hide} productLabel pl-1`}>
                {item.name}
              </label>
            </div>
            <div style={{ marginLeft: '20px' }}>
              {iconState[key] && (
                <ItemSearch
                  values={selectedBaseLineProductState[key]}
                  parentv={key}
                  onSelection={(e) => {
                    onSelection(e);
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default NestedBaselineProductsSearch;
