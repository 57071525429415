import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useTokenBI } from '../../services/queries';
import CircularProgress from '@mui/material/CircularProgress';
import { models } from 'powerbi-client';
import '../../styles.css';
import useStore from "../../store";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./PriceVolume.module.css";
const useStyles = makeStyles(() => ({
	spinner: {
		position: 'absolute',
		height: '50px',
		width: '50px',
		top: '50%',
		left:'50%'
	},
	btnContainer: {
		color: "rgb(0 0 179)",
		display: "flex",
    justifyContent:'flex-end',
		margin: "1em",
	},
}))

export const PriceVolumeReport = (props) => {  
	const classes = useStyles(); 
	let payloadforPOSTROIFilters = useStore(
        (state) => state.payloadforPOSTROIFilters
    );
	const [ allFilters , setAllFilters] = useState([]);
	const [ progress , setProgress] = useState(true);
	const [ filterChanged , setFilterChanged] = useState(true);
	const [ frequency , setFrequency] = useState('monthly');
	const [ pageId , setPageId] = useState(process.env.REACT_APP_PRICEVOLUME_PAGENAME_MONTHLY);
	const fixedCostInclude = useStore((state) => state.fixedCostInclude);
	const [payload, setPayload] = useState({
        "dataFor": props.dataFor,
        "brands": [],
        "categories": [],
        "channels": [],
        "country": "KR",
        "customers": [],
        "eans": [],
        "includeFixedCosts": fixedCostInclude,
        "packs": [],
        "planningCustomers": [],
        "salesOffice": [],
        "sectors": [],
        "subBrands": [],
        "subCategories": [],
        "yearWeeks": [],
        "promoIds": [],
        "promoTypes": [],
    });
	
	let selectedCountry = useStore((state) => state.selectedCountry);
	const { isLoading: isTokenData, data: tokenData, isSuccess } = useTokenBI(
		process.env.REACT_APP_PRICEVOLUME_REPORT_ID
		);
	const [biToken, setBiToken] = useState('');
	const [embedUrl, setEmbedUrl] = useState('');
	let selectedPromotionalItem = useStore(
        (state) => state.selectedPromotionalItem
    );
    let customersStateData = useStore(
        (state) => state.customersStateData
    );
    let promotionalsStateData = useStore(
        (state) => state.promotionalsStateData
    );
    let productsStateData = useStore(
        (state) => state.productsStateData
    );
    let setPayloadforPOSTROIFilters = useStore(
        (state) => state.setPayloadforPOSTROIFilters
    );
	

    const selecteYeardWeeks = useStore((state) => state.selectedYearWeeks);

	useEffect(() => {
		if (!isTokenData && tokenData) {
			setBiToken(tokenData?.accessToken);
			setEmbedUrl(tokenData?.url)
		}
	}, [isTokenData, tokenData])

	const getPageName = () =>{
		return process.env.REACT_APP_PRICEVOLUME_PAGENAME_MONTHLY;
	}
	const fieldArray = [
		{column:'Category',field:'categories',table:'Product Dim'},
		{column:'Brand',field:'brands',table:'Product Dim'},
		{column:'Channel',field:'channels',table:'Customer Dim'},
		{column:'Sold To Desc',field:'customers',table:'Customer Dim'},
		{column:'EAN',field:'eans',table:'Product Dim'},
		{column:'Sales Office',field:'salesOffice',table:'Customer Dim'},
		{column:'Sector',field:'sectors',table:'Product Dim'},
		{column:'Sub Brand',field:'subBrands',table:'Product Dim'},
		{column:'Sub Category',field:'subCategories',table:'Product Dim'},
		{column:'Planning Level',field:'planningCustomers',table:'Customer Dim'},
		{column:'Year Week',field:'yearWeeks',table:'Date Dim'},
		{column:'Event ID',field:'promoIds',table:'Promo Type Dim'},
		{column:'Promo Description',field:'promoTypes',table:'Promo Type Dim'},
	]	
	const basicFilterCountry = {
		$schema: 'http://powerbi.com/product/schema#basic',
		target: {
		  table: 'Country Dim',
		  column: 'Country ID',
		},
		operator: 'In',
		values: [selectedCountry],
		filterType: 1,
	  };
	  const fixedCostFilter = {
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: 'Fixed cost Dim',
			column: "Fixed Costs"
		},
		operator: "In",
		values:[payloadforPOSTROIFilters['includeFixedCosts']],	
		filterType: models.FilterType.Basic
	}
	useEffect(() => {
		let selectedFilters = [basicFilterCountry,fixedCostFilter];
		fieldArray.forEach((item)=>{
				
			if (payloadforPOSTROIFilters && payloadforPOSTROIFilters[item.field] && payloadforPOSTROIFilters[item.field].length > 0) {
				let filter = {
					$schema: "http://powerbi.com/product/schema#basic",
					target: {
						table: item.table,
						column: item.column
					},
					operator: "In",
					requireSingleSelection:false,
					values: payloadforPOSTROIFilters[item.field],
					filterType:models.FilterType.Basic
				}
				selectedFilters.push(filter);

			}
		
	})
	setAllFilters(selectedFilters);

	}, [payloadforPOSTROIFilters]);
	useEffect(() => {
        let selectedValue = {
            country: selectedCountry,
            yearWeeks: selecteYeardWeeks,  //202244 
            "includeFixedCosts": fixedCostInclude,
        }
        let customer = getCustomerData();
        let product = getProductData();
        let promo = gePromoData();
        selectedValue = { ...payload, ...selectedValue, ...customer.data, ...product.data, ...promo.data };
        setPayload(selectedValue)
        setPayloadforPOSTROIFilters(selectedValue)

    }, [selectedCountry, selecteYeardWeeks, customersStateData, fixedCostInclude, productsStateData, promotionalsStateData, selectedPromotionalItem]);

	function getCustomerData() {
        let selectedArr = [];
        let customerPayLoad = {};
        let formatedPayLoad = {};
        const arr = [
            "channel",
            "salesOffice",
            "planningCustomer",
            "customers"
        ];
        let values = { ...customersStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            customerPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                        customerPayLoad[level].push(val);
                        selectedArr.push(val);
                    });
                }
            }
        }
        formatedPayLoad['customers'] = customerPayLoad["customers"];
        formatedPayLoad['channels'] = customerPayLoad["channel"];
        formatedPayLoad['salesOffice'] = customerPayLoad["salesOffice"];
        formatedPayLoad["planningCustomers"] = customerPayLoad["planningCustomer"];
        return { data: formatedPayLoad };
    }

    function getProductData() {
        let selectedArr = [];
        let productPayLoad = {};
        let formatedPayLoad = {};
        const arr = [
            "brand",
            "category",
            "eans",
            "sector",
            "subBrand",
            "subCategory",
        ];
        let values = { ...productsStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            productPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                    productPayLoad[level].push(val);
                    selectedArr.push(val);    
                    });
                }
            }
        }

        formatedPayLoad.eans = [];
        if (productPayLoad["eans"].length > 0) {
            productPayLoad["eans"].forEach(element => {
                let eans = element.split("-");
                if (eans.length > 0) {
                    formatedPayLoad.eans.push(eans[eans.length - 1].trim())
                }
            });
        }
        formatedPayLoad.sectors = productPayLoad["sector"];
        formatedPayLoad.categories = productPayLoad["category"];
        formatedPayLoad.subBrands = productPayLoad["subBrand"];
        formatedPayLoad.brands = productPayLoad["brand"];
        formatedPayLoad.subCategories = productPayLoad["subCategory"];
        //formatedPayLoad.eans = productPayLoad["eans"];
        return { data: formatedPayLoad };
    }
    function gePromoData() {
        let selectedArr = [];
        let customerPayLoad = {};
        let formatedPayLoad = { promoTypes: [], promoIds: [] };
        const arr = [
            "promoType",
            "promoIds",
        ];
        let values = { ...promotionalsStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            customerPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                        customerPayLoad[level].push(val);
                        selectedArr.push(val);
                    });
                }
            }
        }

        formatedPayLoad.promoIds = customerPayLoad["promoIds"];
        formatedPayLoad.promoTypes = customerPayLoad["promoType"];
        return { data: formatedPayLoad };
    }
	function putAll(x) {
        return x.name;
    }
    function isSelected(x) {
        return x.isChecked;
    }
	const handleChangeFrequency = (value) => {	
		setFilterChanged(false)	
		setFrequency(value)
		if(value === 'monthly'){
			setPageId(process.env.REACT_APP_PRICEVOLUME_PAGENAME_MONTHLY)
		}else{
			setPageId(process.env.REACT_APP_PRICEVOLUME_PAGENAME_WEEKLY)
		}
		setFilterChanged(true)	
	  };
	  const frequencyData = {
		'Monthly': 'monthly',
		'Weekly': 'weekly'
	  }
	
	const generateReport = () =>{
		
		return (<header className="App-header">
			<PowerBIEmbed
					embedConfig={{
						pageName: pageId,
						type: 'report',   // Supported types: report, dashboard, tile, visual and qna
						id: process.env.REACT_APP_PRICEVOLUME_REPORT_ID, //"78afa3ce-f6da-4aca-9f13-361a73e2d6cf",
						embedUrl: embedUrl,
						accessToken: biToken,
						filters: allFilters,
						tokenType: models.TokenType.Embed,						
						settings: {
							panes: {
								filters: {
									expanded: false,
									visible: false
								},
								pageNavigation: {
									visible: false
								},
							},
						}
					}}
					eventHandlers={
					new Map([
						['loaded', (() => setProgress(true))],
						['rendered', function () { 
							setProgress(false);
							setTimeout(() => {
								const parent = document.getElementsByClassName('Embed-container')
								if (parent && parent.length > 0){
									const iframe = document.getElementsByTagName('iframe',parent)
									if (iframe && iframe[0]){
										iframe[0].setAttribute('id','powerBIFrame')
									}
								}
								
							}, 2000)
						}],
						['error', (() =>{setProgress(true)})]
					])
					}
					cssClassName={"Embed-container"}
					getEmbeddedComponent={(embeddedReport) => {
						window.report = embeddedReport;
					}}
			/>
		</header>)
	}
	
//style={{maxHeight: '30%',overflowY:'hidden', overflowX: 'scroll',maxWidth:'100%'}}
	return (
		<div className="App KR-PostROI-PriceVolume-Reports">
			<div className={styles.linkWindow}>
      <div className={styles.dateLinks} style={{ display: 'flex' }}>
      {
        Object.entries(frequencyData).map(([key, value]) => (
          <div key={key} className="frequencyItem" id={`frequency_${key}`}>
            <span
              value={value}
              onClick={() => handleChangeFrequency(value)}
              className={frequency===value?styles.dateLinksSpanActive:styles.dateLinksSpan}
            >
              {key}
            </span>
            <span>{key !== "Weekly" ? "|" : ""}</span>
          </div>
        ))
      }
      </div>
    </div>
			{
				!isTokenData && biToken &&  isSuccess && embedUrl && filterChanged
				? generateReport()
				: <CircularProgress className={classes.spinner}/>
			}

		</div>
	);
}

export const MemoizedPriceVolumeReport = React.memo(PriceVolumeReport);