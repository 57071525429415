import { useEffect, useState } from "react";
import { useKcProductHierarchy, useTargetProductHierarchy } from "../../../services/queries";
import NestedSearch from "../../NestedSearch"
import { SingleGrid } from "../../single_grid";
import { ElasticityRecord, Scope, ProductsOnComparison, CustomSortFuction, PriceType, Source } from "../../types"
import { ProductNames } from "../product_names";
import { SelectionRow } from "../selection_row";
import { ViewMore } from "../view_more";
import AppPagination from '../../Pagination';
import { Exclusions } from "../../Exclusions";

type Props = {
    records: ElasticityRecord[],
    selectedCountry: string,
    unselectRow: (initial: string, target?: string, id?: any) => void,
    handleViewMore?: (id: any, Initial: string, target?: string, category?: string,
        subCategoryInitial?: string, subCategoryTarget?: string, manufacturerInitial?: string,
        manufacturerTarget?: string, brandInitial?: string, brandTarget?: string) => void,
    loading: boolean,
    handleSelectedValues: (obj: any) => void,
    initialProductData: any,
    customSort?: CustomSortFuction,
    priceType: PriceType,
    scope?: Scope,
    source?: Source,
    selectedProducts: any,
    selectedTargetProducts: any
    pageData?: any
    dataCount?: any
    handlePageData?: any,
    useNewScopeBy: boolean,
    addProductClick?: any,
    selectedTab?: string,
    selectedCategoryLevel?: string,
    selectedProductLevel?: string,
    crossScopeDataExclusions?: any,
    crossScopeDataExclusionMessage?: any,
    filteredProducts?: any,
    filteredTargetProducts?: any
}

export const CrossCountryScope = ({ records, selectedCountry, unselectRow, handleViewMore,
    loading, handleSelectedValues, initialProductData, customSort, priceType, scope, source,
    selectedProducts, selectedTargetProducts, pageData, dataCount, handlePageData, useNewScopeBy, addProductClick, selectedTab, selectedProductLevel, selectedCategoryLevel,
    crossScopeDataExclusions, crossScopeDataExclusionMessage, filteredProducts, filteredTargetProducts}: Props) => {

    const [comparisonCount, setcomparisonCount] = useState(0);

    return (
        <div className="mt-2 flex flex-col  overflow-x-hidden relative w-full" style={{ minHeight: 600 }}>
            <div className=" min-h-20">
                <SelectionRow
                    initialScopeData={initialProductData}
                    handleSelectedValues={handleSelectedValues}
                    comparisonAdded={() => setcomparisonCount(comparisonCount + 1)}
                    emptySelection={records && records.length > 0 && records.length === comparisonCount}
                    source={source}
                    scope={scope}
                    filteredproducts={filteredProducts}
                    filteredTargetdproducts={filteredTargetProducts}
                    selectedProducts={selectedProducts}
                    selectedTargetProducts={selectedTargetProducts}
                    useNewScopeBy={useNewScopeBy}
                    selectedCategoryLevel={selectedCategoryLevel}
                    selectedProductLevel={selectedProductLevel}
                    selectedCountry={selectedCountry}
                />
            </div>
            {crossScopeDataExclusions?.length > 0 && <Exclusions exclusionMessage={crossScopeDataExclusionMessage} exclusions={crossScopeDataExclusions} />}
            {useNewScopeBy ?
                <div className="flex h-16 border border-gray-300 justify-between items-center p-2">
                    <div className="flex items-center justify-between">
                        <div className="flex justify-start items-center">
                            <div onClick={() => addProductClick()} className="cursor-pointer px-2 py-4  ">
                                <svg className="fill-primary cursor-pointer" focusable="false" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" id="prod_picker1">
                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                                </svg>
                            </div>
                            <span className="font-bold">Product ddd</span>
                        </div>
                    </div>
                    <div>
                        <AppPagination
                            pageData={pageData}
                            dataCount={dataCount}
                            handlePageData={handlePageData}
                            fromTab='cross'
                            applyPadding={true}
                        />
                    </div>
                </div> : null
            }
            {!useNewScopeBy ?
                <AppPagination
                    pageData={pageData}
                    dataCount={dataCount}
                    handlePageData={handlePageData}
                    fromTab='cross'
                    applyPadding={true}
                /> : null
            }
            <div className="mt-6 flex flex-col bg-white w-full relative">
                <div className=" flex w-full  ">
                    <div className=" border w-full border-gray-300">
                        <SingleGrid
                            loading={loading}
                            records={[...records]}
                            priceType={priceType}
                            scope={scope || Scope.COUNTRY}
                            showName={true}
                            showOptions={true}
                            selectedCountry={selectedCountry}
                            showTargetProduct={true}
                            handleViewMore={handleViewMore}
                            unselectRow={unselectRow}
                            useNewScopeBy={useNewScopeBy}
                            source={source}
                            selectedTab={selectedTab}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}