import { useState } from "react"
import { CSVLink } from "react-csv"
import { GrossProfit } from "./sub_components/gross_profit"
import { GrossProfitPercentage } from "./sub_components/gross_profit_percentage"
import { ChevronLeft } from "./icons/chevron_left"
import { ExportIcon } from "./icons/export"
import { NetInvoiceValue } from "./sub_components/net_invoice"
import { NetPromotedSales } from "./sub_components/net_promoted"
import { NetSales } from "./sub_components/net_sales"
import { NIV } from "./sub_components/NIV"
import { NonPromotedSales } from "./sub_components/non_promoted_sales"
import { NonPromotedSalesPercentage } from "./sub_components/non_promoted_sales_percentage"

type SimulatedResultProps = {
    goBack: Function,
    name: number,
    simulatedGridData: [ {[key: string]: string }],
}

export const SimulatedResult = ({goBack, name, simulatedGridData}: SimulatedResultProps) => {
    const headers = [
        { label: '', key: 'name' },
        { label: 'Actuals', key: 'actuals' },
        { label: 'Baseline Scenario', key: 'baselineScenario' },
        { label: 'Promo Scenario', key: 'promoScenario' },
        { label: 'Lift', key: 'liftScenario' }
      ];
    return <div>
        <div className="fixed w-full h-screen top-[55px] right-0 bg-gray-500 opacity-50" onClick={() => goBack()}>
        </div>
        <div className="fixed right-0 top-[55px]  h-screen bg-white w-2/3 p-10 shadow opacity-100 overflow-y-auto">
            <div className="w-full flex flex-col  pb-[55px]">
                {/* Back button */}
                <div className="flex gap-2 items-center" onClick={() => goBack()}>
                    <ChevronLeft color="#2663FF"></ChevronLeft>
                    <span className="text-blue-600 font-bold">Back</span>
                </div>

                {/* Header */}
                <div className="flex justify-between mt-10 pb-5  border-b items-center">
                    <span className="text-lg font-bold">Promo {name} - Simulated P&L</span>
                    <CSVLink className="export_icon" filename={"Simulated-P&L.csv"} data={simulatedGridData} headers={headers}>
                        <div className="flex rounded-full bg-blue-600  text-white  self-center justify-center p-2 px-5 cursor-pointer"> 
                            <ExportIcon color='white'></ExportIcon>
                            <div className='self-center pl-3 ' > Export </div>
                        </div>
                    </CSVLink>;
                </div>

                <div className="flex justify-between border-b py-2 items-center px-2">
                    <div className="min-w-40 w-[260px]"></div>
                    <div className="flex flex-col min-w-40">
                        <span className="text-sm font-bold">Actuals</span>
                        <span className="font-light text-sm">(avg. 3 months)</span>
                    </div>
                    <div className="text-sm font-bold min-w-40">Baseline Scenario</div>
                    <div className="text-sm font-bold min-w-40">Promo Scenario</div>
                    <div className="text-sm font-bold min-w-40">Lift</div>
                </div>

                {/* Result table */}
                <NetPromotedSales simulatedGridData={simulatedGridData}></NetPromotedSales>
                {/* <NetInvoiceValue></NetInvoiceValue>
                <NetSales></NetSales>
                <GrossProfit></GrossProfit>
                <GrossProfitPercentage></GrossProfitPercentage>
                <NonPromotedSales></NonPromotedSales>
                <NIV></NIV>
                <NonPromotedSalesPercentage></NonPromotedSalesPercentage>
 */}
            </div>  
        </div>
    </div>
}