/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios';
import useStore from  '../../store'
import jwt_decode,{JwtPayload} from "jwt-decode";
import {authenticateWithServer} from '../queries'

let cancelSource = axios.CancelToken.source();

export const apiClient = axios.create({
  //baseURL: 'https://app-promoanalytics-dev-ncus-3.azurewebsites.net/',
  baseURL : process.env.REACT_APP_baseURL, 
  withCredentials: false,
  /*headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }*/
});


// Intercepts all API calls, and sets the bearer token
// Also, this validates the API token expiry and refreshes it
apiClient.interceptors.request.use(
  async (config: any) => {
    const updatedConfig = {
      ...config,
    };

    updatedConfig.cancelToken = cancelSource.token;

    let token = useStore.getState().apiToken
    const oktaAccessToken = useStore.getState().oktaAccessToken
    const oktaIdToken = useStore.getState().oktaIdToken

    if (config.url !== '/generateApiToken'){
      // Check if the token has expired, then get the new token
      const decoded = jwt_decode<JwtPayload>(token)
      if (decoded){
        if ((decoded.exp && Date.now() >= (decoded.exp * 1000) )){
          if (oktaAccessToken && oktaIdToken){
            const result = await authenticateWithServer(oktaAccessToken, oktaIdToken)
            if (result.error || !result.jwtToken){
              cancelSource.cancel() // Cancel any pending API request to the API server
              useStore.setState({
                loginState: false,
                authError:  result.error || 'Invalid login',
                apiToken: ''
              })
            }else{
              useStore.setState({
                loginState: true,
                authError: '',
                apiToken: result.jwtToken
              })
              token = result.jwtToken
              updatedConfig.headers.Authorization = `Bearer ${token}`;
              return updatedConfig
            }
          }else{
            cancelSource.cancel() // Cancel any pending API request to the API server
            window.location.reload()
          }
  
        }else{
          updatedConfig.headers.Authorization = `Bearer ${token}`;
          return updatedConfig
        }
      }else{
        cancelSource.cancel() // Cancel any pending API request to the API server
        useStore.setState({
          loginState: false,
          authError:  'Unauthorized access',
          apiToken: ''
        })
      }
    
    }else{
      return updatedConfig
    }
    // updatedConfig.headers.Authorization = `Bearer ${token}`;
    // return updatedConfig

  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);


// Intercept all API calls and see if the resposne is `Unauthorized`
// If so, cancel all API pending requests to the server and set the login state to false
apiClient.interceptors.response.use((response:AxiosResponse) => {
  
  return response
},(error:any) => {
  if (error.response.status === 401){ // Unauthorized access
    cancelSource.cancel() // Cancel any pending API request to the API server
    useStore.setState({
      loginState: false,
      authError:  'Unauthorized access',
      apiToken: ''
    })
  }
})
