import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
import { omit, isEmpty } from 'lodash';

import AppPagination from "../Pagination";
import {
  useOwnAnalysis,
  useOwnProductHierarchy,
  useOwnScopeSelector,
  useLastModelRunDate,
  useLineFittingData
} from "../../services/queries";
import useStore from "../../store";
import NestedSearch from "../NestedSearch";
import ProductLevelSelector from "../Widgets/ProductLevelSelector";
import { CountryScope } from "./components/country_scope"
import { MarketScope } from "./components/market_scope";
import {
  ElasticityScopeRecord,
  ElasticityRecord,
  SelectedProduct,
  Scope,
  Source
} from "../types";
import {
  parseOwnAnalysisData,
  sortScopeData,
  getHierarchyLevel,
  paginationPerPageDataLimit,
  getDropDownTreeData,
  getSelectedWeekRange,
  getOwnViewMoreData,
} from "../utils";
import { TableColumn } from "react-data-table-component";
import { ApiRequestService } from "../../services/api-service";
import { MemoizedWeekSelector } from "../WeekSelector";
import useClickOutside from "../../ClickOutside";
import ErrorMessage from "../ErrorMessage";
import { Exclusions } from "../Exclusions";

const useStyles = makeStyles((theme: any) => ({
  btnContainer: {
    display: "flex",
    justifyContent: 'flex-end',
    marginRight: '15px',
  },
  btnReset: {
    background: "#cb0c0c",
    color: "#fff",
    textTransform: "none",
    margin: "0 20px",
    padding: "6px 20px",
    borderRadius: "10px",
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
}));

export const OwnTab = () => {
  const classes = useStyles();
  const initialProducts: SelectedProduct = {
    selectedOwnArr: [],
    apiUrl: "",
    selectedKcArr: [],
  };

  const elasticityPrice = {
    avgPrice: true,
    promoPrice: false,
    basePrice: false,
  };

  const selectedCountry = useStore((state) => state.selectedCountry);
  const selectedSourceChoice = useStore((state) => state.selectedSourceChoice);
  let setPriceOwnsValue = useStore((state) => state.setPriceOwnsValue);
  const setPriceElasticityTab = useStore((state) => state.setPriceElasticityTab);
  let displayTopItems = useStore((state) => state.displayTopItems);
  let selectedProductLevel = useStore((state) => state.selectedProductLevel);
  let selectedScopeLevel = useStore<string>((state) => state.selectedScopeLevel ? state.selectedScopeLevel : "WC");
  const setSelectedScopeLevel = useStore((state) => state.setSelectedScopeLevel);
  const setSelectedCategoryLevel = useStore((state) => state.setSelectedCategoryLevel);
  const setSelectedProductLevel = useStore((state) => state.setSelectedProductLevel);
  const selectedSubCategoryLevel:Array<string | number | boolean | any> = useStore((state) => state.selectedSubCategoryLevel);
  let selectedCategoryLevel = useStore((state) => state.selectedCategoryLevel);
  let priceType = useStore((state) => state.priceType ? state.priceType : elasticityPrice);
  const setPriceType = useStore((state) => state.setPriceType);
  const setDisplayTopItems = useStore((state) => state.setDisplayTopItems);
  const regressionApiBodyOwn = useStore((state) => state.regressionApiBodyOwn);
  const setRegressionApiBodyOwn = useStore((state) => state.setRegressionApiBodyOwn);
  const userSelectedWeekRangesOwn = useStore.getState().userSelectedWeekRangesOwn;
  const setUserSelectedWeekRangesOwn = useStore((state) => state.setUserSelectedWeekRangesOwn);
  const setSelectedWeeksApply = useStore((state) => state.setSelectedWeeksApply);

  const [selectedOwnProducts, setSelectedOwnProducts] = useState<SelectedProduct>(initialProducts);
  const [clear, setClear] = useState(false);
  const [count, setCount] = useState(displayTopItems);
  const [ownTabData, setOwnTabData] = useState<any>([]);
  const [ownTabScopeData, setOwnTabScopeData] = useState<any>([]);
  const [ownTabDataCount, setOwnTabPaginationCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ownElasticityPayload, setOwnElasticityPayload] = useState({});
  const [ParsedData, setParsedData] = useState<ElasticityRecord[]>([]);
  const [selectedProducts, setSelectedProducts] = useState([""]);
  const [subCustomerData, setSubCustomerData] = useState<ElasticityScopeRecord[]>([]);
  const [customerOriginal, setcustomerOriginal] = useState<ElasticityScopeRecord[]>([]);
  const [isDownloading, setisDownloading] = useState(false);
  const [canShowProgress, setcanShowProgress] = useState(false);
  const [canShowSearch, setcanShowSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [originalDataInstance, setOriginalDataInstance] = useState([]);
  const [treeDataInstance, setTreeDataInstance] = useState([]);
  const [lineFittingDataPayload, setLineFittingDataPayload] = useState({});
  const [isEditWeeksClicked, setIsEditWeeksClicked] = useState(false);
  const [hiddenCustomers, setHiddenCustomers] = useState([]);
  const [ownDataNew, setOwnDataNew] = useState<any>([]);
  const [ownScopeSelectorNew, setOwnScopeSelectorNew] = useState<any>([]);
  const [ownScopeSelectorForGrid, setOwnScopeSelectorForGrid] = useState<any>([]);
  const [removedProductList, setRemovedProductList] = useState<string[]>([]);
  const [useNewScopeBy, setUseNewByScope] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [ownScopeSelector, setOwnScopeSelector] = useState([]);
  const [ownScopeSelectorExclusions, setOwnScopeSelectorExclusions] = useState([]);
  const [ownScopeSelectorExclusionMessage, setOwnScopeSelectorExclusionMessage] = useState('');
  const [ownData, setOwnData] = useState([]);

  const { data: kcProductData, isError: kcProductDataError } = useOwnProductHierarchy(`${selectedCountry}${selectedSourceChoice
    ? `&source=${selectedSourceChoice}` : ""}${selectedProductLevel ? `&hierarchyLevel=${getHierarchyLevel(selectedProductLevel)}` : ""}${`&scope=${selectedScopeLevel}`}`);
  const { data: lastModelRunData, isError: lastModelRunError } = useLastModelRunDate(selectedCountry);
  const { isLoading: isOwnDataLoading, data: ownDataResponse } = useOwnAnalysis(ownElasticityPayload);
  const { isLoading: isOwnScopeSelectorLoading, data: ownScopeSelectorResponse } = useOwnScopeSelector(ownElasticityPayload);
  const { isLoading: loadingLineFittingData, data: lineFittingData, isError: isLineFittingError } = useLineFittingData(lineFittingDataPayload, "own");

  useEffect(() => {
    if (ownDataResponse?.data) {
      setOwnData(ownDataResponse.data)
    };
  }, [ownDataResponse]);

  useEffect(() => {
    if (ownScopeSelectorResponse?.data || ownScopeSelectorResponse?.exclusions.length > 0) {
      setOwnScopeSelector(ownScopeSelectorResponse?.data);
      if (userSelectedWeekRangesOwn.length > 0) {
        setOwnScopeSelectorExclusions(ownScopeSelectorResponse.exclusions);
        setOwnScopeSelectorExclusionMessage(ownScopeSelectorResponse.message);
      }
    };
  }, [ownScopeSelectorResponse, userSelectedWeekRangesOwn])

  useEffect(() => {
    setUserSelectedWeekRangesOwn([]);
    setOwnTabPaginationCount(0);
    setCurrentPage(1);
    setSelectedWeeksApply([])
  }, []);

  useEffect(() => {
    onClickClear();
  }, [selectedSourceChoice]);

  useEffect(() => {
    onClickClear();
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedWeeksApply([]);
  }, [selectedOwnProducts])

  useEffect(() => {
    if (lineFittingData === undefined) return;

    let lineDataObj: any = {};

    if (lineFittingData.length > 0) {
      for (const val of lineFittingData) {
        if (val !== null && val !== undefined) {
          let obj = val;
          let key = obj.year.toString();
          if (!lineDataObj[key]) {
            lineDataObj[key] = [obj];
          } else {
            lineDataObj[key].push(obj);
          }
        }
      }
      // Constructing drop down tree select object
      const treeNodesData: any = getDropDownTreeData(selectedCountry, lineDataObj);
      setTreeDataInstance(treeNodesData);
      setOriginalDataInstance(lineDataObj);

    }

    //On initial loading pushing all data points
    let dp = [];
    for (const key in lineDataObj) {
      for (let i = 0; i < lineDataObj[key].length; i++) {
        dp.push(lineDataObj[key][i]);
      }
    }
    setRegressionApiBodyOwn(dp);

  }, [lineFittingData]);

  useEffect(() => {
    const selectedProdData: any = selectedOwnProducts && selectedOwnProducts !== undefined ? selectedOwnProducts.selectedOwnArr : [];
    const selectedOwnArr: any = [];
    const productLevel = getHierarchyLevel(selectedProductLevel);
    selectedProdData.forEach((product: any) => {
      if (productLevel === 'EAN') {
        selectedOwnArr.push(product.split('-')[0].trim());
      } else {
        selectedOwnArr.push(product);
      }
    })
    let payload = {
      country: selectedCountry,
      customer: null,
      hierarchyLevel: productLevel,
      initial: selectedOwnArr,
      target: []
    }
    setLineFittingDataPayload(payload);
  }, [selectedOwnProducts, userSelectedWeekRangesOwn]);

  useEffect(() => {
    let topNItems = displayTopItems;
    const {
      selectedOwnArr,
      selectedManufacturer,
      selectedBrand,
      selectedSubBrands,
      selectedPacks,
    } = selectedOwnProducts;

    const selectedHierarchyLevel = getHierarchyLevel(selectedProductLevel);
    let ownElasticityPayload = {
      category: selectedCategoryLevel,
      forOwn: true,
      country: selectedCountry,
      hierarchyLevel: selectedHierarchyLevel,
      initialBrands: selectedHierarchyLevel === "BRAND" ? [] : selectedBrand,
      initialManufacturers: selectedManufacturer,
      initialNodeValues: selectedHierarchyLevel === 'CATEGORY' ? selectedCategoryLevel ? [selectedCategoryLevel] : [] : selectedOwnArr && selectedOwnArr !== undefined ? selectedOwnArr : [],
      initialPacks: selectedHierarchyLevel === "PACK" ? [] : selectedPacks,
      initialSubBrands: selectedHierarchyLevel === "SUB_BRAND" ? [] : selectedSubBrands,
      scope: selectedScopeLevel,
      source: selectedSourceChoice,
      subCategories: selectedSubCategoryLevel,
      targetBrands: null,
      targetManufacturers: null,
      targetNodeValues: null,
      targetPacks: null,
      targetSubBrands: null,
      topNBottomN: topNItems,
      timePeriods: [],
    };
    setOwnElasticityPayload(ownElasticityPayload);

    if (isEditWeeksClicked) {
      const timePeriods = [];
      let lineDataObj: any = {};
      if (userSelectedWeekRangesOwn.length > 0) {
        const dataPoints = userSelectedWeekRangesOwn ? userSelectedWeekRangesOwn : [];
        if (lineFittingData === undefined) return;
        if (dataPoints !== undefined && dataPoints.length > 0 && dataPoints.length !== lineFittingData.length) {
          for (const val of dataPoints) {
            if (val !== null && val !== undefined) {
              let obj: any = val;
              let key = obj.year.toString();
              if (!lineDataObj[key]) {
                lineDataObj[key] = [obj];
              } else {
                lineDataObj[key].push(obj);
              }
            }
          }

          for (const key in lineDataObj) {
            const weekArray = lineDataObj[key];
            const weeks: any = [];
            weekArray.forEach((weekData: any) => {
              if (weekData !== null && weekData !== undefined && weekData.week !== undefined) {
                weeks.push(weekData.week);
              }
            })
            const weekRange: any = {};
            weekRange["year"] = key;
            weekRange["weeks"] = weeks;
            timePeriods.push(weekRange);
          }
        }
      }

      let ownElasticityPayload = {
        category: selectedCategoryLevel,
        forOwn: true,
        country: selectedCountry,
        hierarchyLevel: getHierarchyLevel(selectedProductLevel),
        initialBrands: selectedHierarchyLevel === 'BRAND' ? [] : selectedBrand,
        initialManufacturers: selectedManufacturer,
        initialNodeValues: selectedHierarchyLevel === 'CATEGORY' ? selectedCategoryLevel ? [selectedCategoryLevel] : [] : selectedOwnArr && selectedOwnArr !== undefined ? selectedOwnArr : [],
        initialPacks: selectedHierarchyLevel === 'PACK' ? [] : selectedPacks,
        initialSubBrands: selectedHierarchyLevel === 'SUB_BRAND' ? [] : selectedSubBrands,
        scope: selectedScopeLevel,
        source: selectedSourceChoice,
        subCategories: selectedSubCategoryLevel,
        targetBrands: null,
        targetManufacturers: null,
        targetNodeValues: null,
        targetPacks: null,
        targetSubBrands: null,
        topNBottomN: topNItems,
        timePeriods: timePeriods,
      }
      setOwnElasticityPayload(ownElasticityPayload);
    }

  }, [selectedOwnProducts, selectedProductLevel, selectedCountry, selectedSourceChoice, selectedScopeLevel, selectedCategoryLevel, displayTopItems, regressionApiBodyOwn, userSelectedWeekRangesOwn, isEditWeeksClicked]);

  useEffect(() => {
    if (selectedOwnProducts.selectedOwnArr && ownTabData) {
      if (ownTabData.length > 0) {
        const parsed = parseOwnAnalysisData(
          selectedOwnProducts,
          ownTabData,
          selectedCategoryLevel
        );

        if (parsed) {
          setParsedData(parsed);
        }
        if (selectedProductLevel !== "category") {
          setSelectedProducts(selectedOwnProducts.selectedOwnArr);
        } else {
          setSelectedProducts([selectedCategoryLevel]);
        }
      } else {
        setParsedData([]);
      }
    }
    if (!selectedOwnProducts.selectedOwnArr) {
      setHiddenCustomers([]);
    }
  }, [selectedOwnProducts, ownTabData, ownData, ownDataNew]);

  useEffect(() => {
    if (Object.keys(ownTabScopeData).length < 0) {
      setIsEditWeeksClicked(false);
    }

    if (isEmpty(Object.entries(ownTabScopeData))) {
      setSubCustomerData([]);
    }
    if (ownTabScopeData === undefined || Object.keys(ownTabScopeData).length <= 0 || !ownTabScopeData || !selectedOwnProducts.selectedOwnArr) return;
    let customerData: ElasticityScopeRecord[] = [];
    let customerHead: string[] = [];

    if (ownScopeSelector !== undefined && ownScopeSelector !== null) {
      for (const productName of Object.keys(ownScopeSelector)) {
        for (const customerName of Object.keys(ownScopeSelector[productName as any])) {
          if (!customerHead.includes(customerName)) {
            customerHead.push(customerName);
          }
        }
      }
    }

    let selectedOwnProdArr = [];
    if (ownTabScopeData) {
      for (const productName in ownTabScopeData) {
        selectedOwnProdArr.push(productName);
      }
    }

    for (const customer of customerHead) {
      const _customerEntry: ElasticityScopeRecord = {
        customer,
        data: [],
      };
      let productInfoArray = selectedProductLevel === "category" ? [selectedCategoryLevel] : selectedOwnProdArr;
      for (const productName of productInfoArray) {
        if (Object.keys(ownTabScopeData).includes(productName)) {
          const obj: ElasticityRecord = {
            initial: productName,
            averageVolume: ownTabScopeData[productName][customer]?.averageVolume,
            averagePrice: ownTabScopeData[productName][customer]?.averagePrice,
            basePrice: ownTabScopeData[productName][customer]?.basePrice,
            priceIndex: ownTabScopeData[productName][customer]?.priceIndex,
            promoPrice: ownTabScopeData[productName][customer]?.promoPrice,
            range: ownTabScopeData[productName][customer]?.range,
            rsquared: ownTabScopeData[productName][customer]?.rsquared,
            viewMore: ownTabScopeData[productName][customer]?.viewMore,
            customer: customer,
            modelRun: ownTabScopeData[productName][customer]?.modelRun,
            periodBegin: ownTabScopeData[productName][customer]?.periodBegin,
            periodEnd: ownTabScopeData[productName][customer]?.periodEnd,
            nweeks: ownTabScopeData[productName][customer]?.nweeks,
            pvalue: ownTabScopeData[productName][customer]?.pvalue,
            category: selectedCategoryLevel,
            id: ownTabScopeData[productName][customer]?.id,
            subCategoryInitial: ownTabScopeData[productName][customer]?.subCategoryInitial,
            manufacturerInitial: ownTabScopeData[productName][customer]?.manufacturerInitial,
            brandInitial: ownTabScopeData[productName][customer]?.brandInitial,
            subBrandInitial: ownTabScopeData[productName][customer]?.subBrandInitial,
            packInitial: ownTabScopeData[productName][customer]?.packInitial
          };
          _customerEntry.data.push(obj);
        } else {
          const initialData: ElasticityRecord = {
            initial: productName,
            customer: customer,
          };
          _customerEntry.data.push(initialData);
        }
      }
      customerData.push(_customerEntry);
    }

    const hiddenCustomerData: any = hiddenCustomers;
    const filteredCustomerData = customerData.filter(data => {
      return !hiddenCustomerData.includes(data.customer);
    })

    setSubCustomerData(filteredCustomerData);
    setcustomerOriginal(customerData);

    if (selectedProductLevel !== "category") {
      setSelectedProducts(selectedOwnProdArr);
    } else {
      setSelectedProducts([selectedCategoryLevel]);
    }
  }, [ownScopeSelector, ownTabScopeData, selectedOwnProducts]);

  useEffect(() => {
    if (isOwnDataLoading || isOwnScopeSelectorLoading) {
      setcanShowProgress(true);
    } else {
      setcanShowProgress(false);
    }
  }, [isOwnDataLoading, isOwnScopeSelectorLoading]);

  useEffect(() => {
    if (ownData) {
      const modifiedData = handleAddUniqueIdForOwnData(ownData);
      setOwnDataNew(modifiedData);
    }
  }, [ownData])

  useEffect(() => {
    if (isEmpty(Object.entries(ownScopeSelectorForGrid))) {
      setcustomerOriginal([])
    }
  }, [ownScopeSelectorForGrid])

  useEffect(() => {
    if (ownScopeSelector) {
      const modifiedData = handleAddUniqueIdForScopeData(ownScopeSelector);
      setOwnScopeSelectorNew(modifiedData);
    }
  }, [ownScopeSelector])

  useEffect(() => {
    if (!isEmpty(Object.entries(ownScopeSelectorForGrid)) && isEmpty(ownTabScopeData) && currentPage !== 1) {
      setCurrentPage(currentPage => currentPage - 1);
    }
  }, [ownTabScopeData]);

  useEffect(() => {
    const startIndex = currentPage * paginationPerPageDataLimit - paginationPerPageDataLimit;
    const endIndex = startIndex + paginationPerPageDataLimit;

    if (selectedScopeLevel === Scope.COUNTRY) {
      let data = ownDataNew && ownDataNew.length > 0 ? ownDataNew : [];
      setOwnTabPaginationCount(data.length);
      setOwnTabData(data.slice(startIndex, endIndex));
    } else {
      let scopeData = ownScopeSelectorForGrid && ownScopeSelectorForGrid !== undefined ? Object.entries(ownScopeSelectorForGrid).sort((a, b) => a[0] > b[0] ? 1 : -1) : [];
      setOwnTabPaginationCount(scopeData.length);
      setOwnTabScopeData(Object.fromEntries(scopeData.slice(startIndex, endIndex)));
    }
  }, [currentPage, ownData, ownDataNew, ownScopeSelector, ownScopeSelectorForGrid]);

  useEffect(() => {
    const checkEmptyArr: any = [];
    Object.entries(ownScopeSelectorNew).forEach((data: any) => {
      if (isEmpty(Object.entries(data[1]))) {
        checkEmptyArr.push(data[0]);
      }
    })
    const modifiedData = omit(ownScopeSelectorNew, checkEmptyArr);
    setOwnScopeSelectorForGrid(modifiedData);
    setRemovedProductList(checkEmptyArr);
  }, [ownScopeSelectorNew]);

  useEffect(() => {
    setUseNewByScope(selectedCountry === 'KR');
  }, [selectedProductLevel, selectedCountry])

  const handleAddUniqueIdForScopeData = (scopeData:any) => {
    const modifiedDataArr: any = Object.entries(scopeData).map((custData: any) => {
      const custDataArr: any = [];
      custDataArr.push(custData[0]);
      const newDataArr: any = Object.entries(custData[1]).map((data: any) => {
        data[1]["id"] = uuidv4();
        return data;
      });
      const newData = Object.fromEntries(newDataArr);
      custDataArr.push(newData);
      return custDataArr;
    });
    const modifiedData = Object.fromEntries(modifiedDataArr);
    return modifiedData;
  }

  const handleAddUniqueIdForOwnData = (ownData:any) => {
    const modifiedData = ownData.map((data: any) => {
      data["id"] = uuidv4();
      return data;
    })
    modifiedData.sort((a: any, b: any) => a.initial > b.initial ? 1 : -1);
    return modifiedData
  }

  const handleSelectedValues = (kcObject: SelectedProduct) => {
    setSelectedOwnProducts({
      ...selectedOwnProducts,
      selectedOwnArr: kcObject.selectedKcArr,
      apiUrl: kcObject.apiUrl,
      selectedBrand: kcObject.selectedBrand,
      selectedManufacturer: kcObject.selectedManufacturer,
      selectedSubBrands: kcObject.selectedSubBrands,
      selectedPacks: kcObject.selectedPacks,
    });
  };

  const onDisplayItemChange = (data: any) => {
    setCount(data);
  };

  const onchange = (data: any[]) => {
    if (data[0] !== `categoryLevel` && data[0] !== "productLevel") {
      setPriceType(data[1]);
    } else {
      onClickClear();
      data[0] === "categoryLevel" ? setSelectedCategoryLevel(data[1]) : setSelectedProductLevel(data[1]);
      setSelectedOwnProducts(initialProducts);
      setSelectedProducts(['']);
      setParsedData([]);
      setSubCustomerData([]);
      setOwnTabScopeData([]);
      setcustomerOriginal([])
      setCount(0);
      setDisplayTopItems(0);
      setUserSelectedWeekRangesOwn([]);
      setOwnDataNew([]);
    }
  };

  const onScopeChange = (data: any) => {
    setSelectedScopeLevel(data);
    setCount(0);
    setDisplayTopItems(0);
  };

  const onSubCategoryChange = () => {
    setSelectedOwnProducts(initialProducts);
    setSelectedProducts(['']);
    setParsedData([]);
    setSubCustomerData([]);
    setOwnTabScopeData([]);
    setcustomerOriginal([])
    setCount(0);
    setDisplayTopItems(0);
    setUserSelectedWeekRangesOwn([]);
    setOwnDataNew([]);
    onClickClear();
  }

  const handleUnselect = (name: string, target?: any, id?: any) => {
    if (selectedScopeLevel === Scope.COUNTRY) {
      setOwnDataNew((data: any) => {
        const newData = data.filter((row: any) => row.id !== id);
        if (!newData.length) {
          setSelectedOwnProducts(initialProducts);
          setOwnDataNew([]);
          setParsedData([]);
        }
        return newData;
      });
    } else {
      setOwnScopeSelectorNew((customerData: any) => {
        const modifiedDataArr: any = Object.entries(customerData).map((custData: any) => {
          const custDataArr: any = [];
          custDataArr.push(custData[0]);
          const newDataArr: any = [];
          Object.entries(custData[1]).forEach((data: any) => {
            const filterData = data[1];
            if (filterData && filterData.id && filterData.id !== id) {
              newDataArr.push(data);
            }
          });
          const newData = Object.fromEntries(newDataArr);
          custDataArr.push(newData);
          return custDataArr;
        });
        const modifiedData = Object.fromEntries(modifiedDataArr);
        return modifiedData;
      })
    }
  };

  const handleViewMore = (id: any, initial: string, target?: string, customer?: string, category?: string, subCategoryInitial?: string, subCategoryTarget?: string, manufacturerInitial?: string, manufacturerTarget?: string, brandInitial?: string, brandTarget?: string, subBrandInitial?: string, subBrandTarget?: string, packInitial?: string, packTarget?: string) => {
    const userSelectedWeekRangesOwn = useStore.getState().userSelectedWeekRangesOwn;
    const source = useStore.getState().selectedSourceChoice;
    const pl = getHierarchyLevel(useStore.getState().selectedProductLevel);
    const weekRangeSelected = getSelectedWeekRange(userSelectedWeekRangesOwn);
    setPriceOwnsValue(initial.split('-')[0]);
    setPriceElasticityTab("Own");
    const subCategoryLevel = useStore.getState().selectedSubCategoryLevel;
    let data = (userSelectedWeekRangesOwn.length > 0)
      ? getOwnViewMoreData(id, selectedCountry, initial, pl, source, selectedScopeLevel, category!, subCategoryInitial!, subCategoryTarget!, manufacturerInitial!, manufacturerTarget!, brandInitial!, brandTarget!, subBrandInitial!, subBrandTarget!, packInitial!, packTarget!, customer!, subCategoryLevel!, weekRangeSelected)
      : getOwnViewMoreData(id, selectedCountry, initial, pl, source, selectedScopeLevel, category!, subCategoryInitial!, subCategoryTarget!, manufacturerInitial!, manufacturerTarget!, brandInitial!, brandTarget!, subBrandInitial!, subBrandTarget!, packInitial!, packTarget!, customer!, subCategoryLevel!)
    // Set data in localStorage to refer in View More page
    localStorage.setItem(id, JSON.stringify(data));
    // window.open(`/price-elasticity/own-dashboard?${url}`, '_blank');
    window.open(`/price-elasticity/own-dashboard?country=${selectedCountry}&id=${id}`);
  };

  const onClickClear = () => {
    setSelectedOwnProducts(initialProducts);
    setParsedData([]);
    setSubCustomerData([]);
    setClear(true);
    setCount(0);
    setDisplayTopItems(0);
    setOwnTabPaginationCount(0);
    setCurrentPage(1)
    setUserSelectedWeekRangesOwn([]);
    setHiddenCustomers([])
    setSelectedWeeksApply([])
    setcustomerOriginal([])
    setOwnTabScopeData([])
    setOwnTabData([]);
    setOwnDataNew([]);
    setOwnScopeSelectorNew([]);
    setOwnScopeSelectorForGrid([]);
    setRemovedProductList([]);
    setOwnScopeSelectorExclusions([]);
  };

  const handleScopeLevelSort = (
    field: TableColumn<ElasticityRecord>,
    direction: string
  ) => {
    const sorted = sortScopeData(subCustomerData, field, direction);
    setSubCustomerData(sorted);
  };

  const handleClickClose = () => {
    setcanShowProgress(false);
  };

  const handleOwnData = (currentPage: any) => {
    setCurrentPage(currentPage);
  };

  const handleClose = (handleClear: any) => {
    setOpen(false);
    handleClear();
  };

  const handleClickOutSide: any = useClickOutside(() => {
    setcanShowSearch(false);
  })

  const filterCustomer = (names: string[]) => {
    setSubCustomerData(
      customerOriginal.filter((x) => !names.includes(x.customer))
    );
  };

  const downloadOwnElasticity = async () => {
    const { selectedOwnArr, selectedManufacturer, selectedBrand, selectedSubBrands, selectedPacks } = selectedOwnProducts;
    const selectedHierarchyLevel = getHierarchyLevel(selectedProductLevel);

    const timePeriods: any = [];
    let lineDataObj: any = {};
    if (userSelectedWeekRangesOwn.length > 0) {
      const dataPoints = userSelectedWeekRangesOwn ? userSelectedWeekRangesOwn : [];
      if (lineFittingData === undefined) return;
      if (dataPoints !== undefined && dataPoints.length > 0 && dataPoints.length !== lineFittingData.length) {
        for (const val of dataPoints) {
          if (val !== null && val !== undefined) {
            let obj: any = val;
            let key = obj.year.toString();
            if (!lineDataObj[key]) {
              lineDataObj[key] = [obj];
            } else {
              lineDataObj[key].push(obj);
            }
          }
        }

        for (const key in lineDataObj) {
          const weekArray: any = lineDataObj[key];
          const weeks: any = [];
          weekArray.forEach((weekData: any) => {
            if (weekData !== null && weekData !== undefined && weekData.week !== undefined) {
              weeks.push(weekData.week);
            }
          })
          const weekRange: any = {};
          weekRange["year"] = key;
          weekRange["weeks"] = weeks;
          timePeriods.push(weekRange);
        }
      }
    }

    const ownElasticityDownloadData = {
      country: selectedCountry,
      source: selectedSourceChoice,
      scope: selectedScopeLevel,
      hierarchyLevel: selectedHierarchyLevel,
      category: selectedCategoryLevel,
      forOwn: true,
      subCategories: selectedSubCategoryLevel,
      initialNodeValues: selectedOwnArr,
      targetNodeValues: null,
      initialManufacturers: selectedManufacturer,
      targetManufacturers: null,
      initialBrands: selectedHierarchyLevel === "BRAND" ? [] : selectedBrand,
      targetBrands: null,
      initialSubBrands: selectedHierarchyLevel === "SUB_BRAND" ? [] : selectedSubBrands,
      targetSubBrands: null,
      initialPacks: selectedHierarchyLevel === "PACK" ? [] : selectedPacks,
      targetPacks: null,
      timePeriods: timePeriods.length > 0 ? timePeriods : []
    };

    try {
      setisDownloading(true);
      const api = ApiRequestService.createInstance();
      const res: AxiosResponse = await api.elasticityFileDownload(
        ownElasticityDownloadData
      );
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      const modelRun = lastModelRunData && lastModelRunData[selectedCountry] ? new Date(lastModelRunData[selectedCountry]).toLocaleDateString() : "";
      link.setAttribute("download", `${selectedCountry}_Elasticity_${modelRun}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      setisDownloading(false);
    } catch (error) {
      setisDownloading(false);
      console.log("Own Elasticity download error", error);
    }
  };

  let disableDownloadbtn = selectedCountry === "" || selectedSourceChoice === "" || selectedProductLevel === "" || selectedScopeLevel === "" ? true : isDownloading ? true : false;
  const productLevel = getHierarchyLevel(selectedProductLevel);
  const showEditWeeks = (selectedCountry === 'KR' ? !isEmpty(lineFittingData) : (selectedSourceChoice.toLowerCase() !== 'pos' && (productLevel !== 'PACK' && productLevel !== 'BRAND' && productLevel !== 'SUB_CATEGORY') && !isEmpty(lineFittingData)))
    && (selectedScopeLevel === Scope.COUNTRY ? ParsedData && ParsedData.length > 0 : subCustomerData);

  const handleOwnDeselectAll = () => {
    setCount(0);
    setDisplayTopItems(0);
    setOwnTabPaginationCount(0);
    setCurrentPage(1)
    setOwnDataNew([]);
    setOwnScopeSelectorForGrid([]);
  };
  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(getErrorMessageValue())
    }
  }, [selectedCategoryLevel, selectedProductLevel, selectedSourceChoice])

  const getErrorMessageValue = () => {
    return selectedSourceChoice && selectedCategoryLevel && selectedProductLevel
      ? ''
      : 'Please select mandatory fields'
  }
  const showNestedSearch = () => {
    setErrorMessage(getErrorMessageValue())
    setcanShowSearch(!getErrorMessageValue() && !canShowSearch)
  }

  return (
    <div className="flex flex-col p-5">
      <ProductLevelSelector
        onchange={(e: any) => onchange(e)}
        onScopeChange={(e: any) => onScopeChange(e)}
        onDisplayItemChange={(e: any) => onDisplayItemChange(e)}
        onSourceChange={(e: any) => onClickClear()}
        // useNewScopeBy={useNewScopeBy}
        tabName="own"
        onSubCategoryChange={(e: any) => onClickClear()}
      />
      <div className="flex flex-row w-full justify-between my-5">
        <div>
          <button className="border border-primary text-primary rounded p-2 w-20" onClick={() => onClickClear()}>Clear</button>
        </div>
        <div className="flex">
          {showEditWeeks && getHierarchyLevel(selectedProductLevel) !== 'CATEGORY' ?
            <div className={classes.btnContainer}>
              <button onClick={() => setOpen(true)} className="border border-primary text-primary rounded p-1 ">Edit Weeks</button>
            </div> : null}
          <button className={`border border-primary text-primary rounded p-2 flex justify-center align-middle ${disableDownloadbtn ? 'cursor-not-allowed' : ''}`} onClick={disableDownloadbtn ? () => { return null } : () => downloadOwnElasticity()} disabled={disableDownloadbtn}>
            {isDownloading ?
              <svg className="animate-spin ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#0000b3" stroke-width="4"></circle>
                <path className="opacity-75" fill="#0000b3" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              : null}
            <span>Download</span>
          </button>
        </div>
      </div>
      <ErrorMessage message={errorMessage} />
      {ParsedData && ParsedData.length > 0 && ParsedData[0].modelRun ? (
        <div className="flex flex-row-reverse w-full">
          <span className="text-primary w-fit">
            Last model update:{" "}
            <strong>
              {new Date(ParsedData[0].modelRun).toLocaleDateString()}
            </strong>
          </span>
        </div>
      ) : null}
      <AppPagination
        pageData={selectedScopeLevel === Scope.COUNTRY ? ParsedData : subCustomerData}
        dataCount={ownTabDataCount}
        handlePageData={handleOwnData}
        fromTab="own"
        applyPadding={false}
      />
      {userSelectedWeekRangesOwn.length > 0 && ownScopeSelectorExclusions?.length > 0 ? <Exclusions exclusionMessage={ownScopeSelectorExclusionMessage} exclusions={ownScopeSelectorExclusions} /> : null}
      <div className="relative w-full">
        <div className="top-9 absolute z-50">
          {canShowSearch && selectedProductLevel !== "category" ?
            <div className="absolute z-30" ref={handleClickOutSide}>
              <NestedSearch
                data={kcProductData}
                handleSelectedValues={handleSelectedValues}
                tabName="own"
                dataFor="own"
                clear={clear}
                handleClear={() => setClear(false)}
                id="searchOwn"
                previouslySelected={selectedOwnProducts.selectedOwnArr}
                handleOwnDeselectAll={handleOwnDeselectAll}
              />
            </div>
            : null}
        </div>
        {selectedScopeLevel === Scope.COUNTRY ? (
          <CountryScope
            records={ParsedData}
            selectedCountry={selectedCountry}
            priceType={priceType}
            handleViewMore={handleViewMore}
            unselectRow={handleUnselect}
            loading={isOwnDataLoading}
            addProductClick={showNestedSearch}
            selectedProducts={selectedProducts || []}
            source={selectedSourceChoice as Source}
          ></CountryScope>
        ) : null}
        {selectedScopeLevel !== Scope.COUNTRY ? (
          <MarketScope
            records={subCustomerData}
            selectedCountry={selectedCountry}
            allCustomers={customerOriginal.map((x) => x.customer)}
            priceType={priceType}
            handleViewMore={handleViewMore}
            unselectRow={handleUnselect}
            loading={isOwnScopeSelectorLoading}
            filterCustomer={filterCustomer}
            setHiddenCustomersArray={(customerData: any) => setHiddenCustomers(customerData)}
            scope={selectedScopeLevel as Scope}
            addProductClick={showNestedSearch}
            selectedProducts={selectedProducts || []}
            customSort={handleScopeLevelSort}
            source={selectedSourceChoice as Source}
            removedProductList={removedProductList}
          ></MarketScope>
        ) : null}
      </div>
      {open ?
        <MemoizedWeekSelector
          open={open}
          originalDataInstance={originalDataInstance}
          treeDataInstance={treeDataInstance}
          handleClose={handleClose}
          setOpen={setOpen}
          tabName="own"
          setIsEditWeeksClicked={setIsEditWeeksClicked}
          isEditWeeksClicked={isEditWeeksClicked}
          setUserSelectedWeekRangesOwn={setUserSelectedWeekRangesOwn}
        /> : null}
      <Dialog
        open={canShowProgress}
        keepMounted
        onClose={handleClickClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="pt-0">
          {"Your request is being processed"}
        </DialogTitle>
      </Dialog>
    </div>
  );
};
