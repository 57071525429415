import React,{useState, useEffect} from 'react'
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    container:{
        display:'flex',
        flexDirection:'column'
    },
    iconButton: {
        float: 'right',
        padding: 10,
        color: theme.palette.primary.main
    },
    searchInput:{
        width:'500px', 
        outline:'none',
        border:'none'
    },
    searchBox:{
        display:'flex',
        position:'relative'
    },
    filterItem:{
        fontSize: '14px',
        color:  theme.palette.primary.main,
        opacity:'0.75',
        marginLeft:'30px',
        fontWeight:'480',
        maxHeight:'100px',
        overflowY: 'auto',
        padding:'3px'
    },
    item:{
        width: '450px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));


function SearchBar(props){
    const classes = useStyles();
    const [data,setData] = useState();
    const [filteredList, setFilteredList] =useState([]);
    const [query,setQuery] = useState('');

    useEffect(() => {
        setData(props.values);
        setFilteredList(props.values);
    }, [props])

    
    const filterBySearch = (event) => {
        let query = event.target.value;
        setQuery(query);
    };

   const handleSelect=(e)=>{
      props.onSelection([props.parentv,e.target.name]);  
   }

   const FilteredValues=()=>{  
        var updatedList = query ? [...data] : [];
       
        updatedList = updatedList.filter((item) => {
        if (item.name.toLowerCase().includes(query.toLowerCase())) return item;
        });

        return ((query ? updatedList : filteredList) || []).map((item, index) => {
            let name = item.name
            if (props.parentv === 'planLevel'){
                const splitted = item.name.split('-')
                if (splitted.length > 1){
                    name = splitted[1]
                }
            }
            return <div key={index} className="flex items-center">
                <input
                    type={props.isRadio ? "radio" : "checkbox"}
                    name={item.name}
                    checked={item.isChecked}
                    onChange={handleSelect}
                    className="checker pt-1"
                />
                <Tooltip
                    title={<Typography fontSize={20}>{item.name}</Typography>}
                    disableFocusListener
                    disableTouchListener
                >
                    <div className={`${classes.item} checkerName pl-1`}>{name}</div>
                </Tooltip>
            </div>
        });
   }

    return (
        <div className={classes.container} id={props.id || null}>
            <div className={classes.searchBox} >
                <IconButton className={classes.iconButton}>
                    <SearchIcon />
                </IconButton>
                <input className={classes.searchInput} type='text' placeholder='Search...' onChange={filterBySearch}/>
            </div>
            
            <div className={classes.filterItem}>
                <FilteredValues/>
            </div> 
       </div>
    )
}

export default SearchBar;