import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ItemSearch from './Elasticity/Widgets/SearchWidget/ItemSearch';
import useStore from './store';
import { processCustomerHierarchy } from './util';


const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '600px',
    width: '450px',
    zIndex: 5,
    backgroundColor: 'white',
    overflowY: 'auto',
    direction: 'rtl',
    padding: '1rem',
    boxShadow: '5px 5px 8px #d6d9dc',
    marginTop: '5px',
    borderRadius: '5px',
  },
  paperDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  container: {
    backgroundColor: '#F6F8FC',
    height: '100vh',
  },
  searchBar: {
    display: 'inline-block',
    paddingRight: '2.5rem',
  },
  searchContainer: {
    background: '#F6F8FC',
    width: '100%',
    boxShadow: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    color: 'black',
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  autoCompleteSearch: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    background: '#FFFFFF',
  },
  gridContainer: {
    background: '#F6F8FC',
  },
  showCheckBox: {},
  appTab: {
    fontSize: '20px',
  },
  hide: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '650',
    opacity: '0.70',
    marginBottom: '1px',
  },
  open: {
    fontSize: '14px',
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '1px',
    marginLeft: '5px',
  },
  filterItem: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: theme.palette.primary.main,
    opacity: '0.75',
    fontWeight: '480',
    padding: '3px',
  }
}));

let display = {
  source: { name: 'Source', isOpen: false },
  channel: { name: 'Channel', isOpen: false },
  salesOffice: { name: 'Sales Office', isOpen: false },
  planLevel: { name: 'Planning Customer', isOpen: false },
  salesExecutive: { name: 'Sales Executive', isOpen: false },
  soldTo: { name: 'Sold to', isOpen: false },
};

const NestedBaselineCustomerSearch = (props) => {

  const selectedCustomersHierarchy = useStore((state) => state.selectedCustomersHierarchy);
  const setSelectedCustomersHierarchy = useStore((state) => state.setSelectedCustomersHierarchy);

  const classes = useStyles();
  //It controls expand of level
  const initial = {
    source: false,
    channel: false,
    salesOffice: false,
    planLevel: false,
    salesExecutive: false,
    soldTo: false,
  };
  //For checkbox state of source levels
  const sourceInitial = {
    'Sell In': false,
    'Sell Out': false
  }

  const [iconState, setIconState] = useState(initial);
  //Array to store symbols to exclude from the search dropdown list
  const nullSymbols = ["N/A", "NA", "0"];
  let baseLineCustomerCheckBox = useStore(
    (state) => state.baseLineCustomerCheckBox
  );
  const setBaseLineCustomerCheckBox = useStore(
    (state) => state.setBaseLineCustomerCheckBox
  );

  let postROISourceCheckbox = useStore(
    (state) => state.postROISourceCheckbox
  );
  const setPostROISourceCheckbox = useStore(
    (state) => state.setPostROISourceCheckbox
  );
  const selectedCountry = useStore(
    (state) => state.selectedCountry
  );

  function handleOpen(x) {
    setIconState({
      ...iconState,
      [x]: !iconState[x],
    });
  }

  function transformDataUnique(values) {
    let arr1 = ['channel', 'salesOffice', 'planLevel', 'soldTo'];
    arr1.map((val) => {
      values[val] = [
        ...new Map(values[val].map((item) => [item['name'], item])).values(),
      ];
    });
    arr1.map((val) => {
      values[val].sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
      });
    });

    return values;
  }

  function isSelected(x) {
    return x.isChecked;
  }

  function putAll(x) {
    return x.name;
  }

  const setSelectedBaseLineCustomerNew = useStore(
    (state) => state.setSelectedBaseLineCustomerNew
  );

  let selectedBaseLineCustomerState = useStore(
    (state) => state.selectedBaseLineCustomerState
  );

  const setSelectedBaseLineCustomerState = useStore(
    (state) => state.setSelectedBaseLineCustomerState
  );

  const setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );

  let selectedBaseLineProductNew = useStore(
    (state) => state.selectedBaseLineProductNew
  );

  let selectedSource = useStore(
    (state) => state.selectedSource
  );

  const setSelectedSource = useStore(
    (state) => state.setSelectedSource
  );

  const setSellInCustomerHashMap = useStore(
    (state) => state.setSellInCustomerHashMap
  );

  //during initial render displays all values for diff levels 
  function calculateInitial(data) {
    const processed = processCustomerHierarchy(data, null)
    const transformed = transformDataUnique(processed);
    return transformed;
  }
  useEffect(() => {
    let Values = calculateInitial(props.sellInData);
    setSellInCustomerHashMap({ ...Values });
    return (() => {
      setSellInCustomerHashMap({});
    });
  }, []);

  useEffect(() => {
    if (props.previouslySelected && props.previouslySelected.length === 0) {
      let Values = calculateInitial(props.data);
      setSelectedBaseLineCustomerState({
        ...Values,
      });
      setBaseLineCustomerCheckBox({ ...initial, 'source': selectedSource.length === 2 ? true : false });
      setSelectedBaseLineCustomerNew("");
      setSelectedBaseLineFilter(selectedBaseLineProductNew);
    } else if (props.previouslySelected && props.previouslySelected.length > 0) {
      updateSelectedLevel()
    }
    if (selectedSource.length === 0) {
      setPostROISourceCheckbox(sourceInitial);
      setBaseLineCustomerCheckBox({ ...initial, source: false });
    }
  }, [props]);

  useEffect(() => {
    if (props.tabName === 'preROI_test') {
      let Values = calculateInitial(props.data);
      let arr = ["channel", "planLevel", "salesOffice", "soldTo"];
      let countObj = {
        "channel": 0,
        "planLevel": 0,
        "salesOffice": 0,
        "soldTo": 0
      };
      if (props.previouslySelected) {
        props.previouslySelected.map(value => {
          for (let x of arr) {
            if (Values[x].filter(o => o.name === value).length) {
              countObj[x] = countObj[x] + 1;
              let index = Values[x].findIndex(o => o.name === value);
              Values[x][index]['isChecked'] = true;
            }
          }
        })
      }

      setSelectedBaseLineCustomerState({
        ...Values,
      });

      let ob = {};
      for (let x of arr) {
        ob[x] = Values[x].length === countObj[x];
      }
      setBaseLineCustomerCheckBox(ob);
      setSelectedBaseLineFilter("");

    }
  }, []);

  useEffect(() => {
    if (props.clear && props.tabName !== "preROI_test") {
      //if (props.clear) {
      props.resetClear();
    }
  }, [props]);

  const updateSelectedLevel = (level) => {
    let values = { ...selectedBaseLineCustomerState };
    const processed = processCustomerHierarchy(props.data, values)
    const transformed = transformDataUnique(processed);
    setSelectedBaseLineCustomerState({
      ...transformed,
    });

  };

  function generateUrl() {
    let selectedArr = [];
    let reformatKcStr = '';
    let values = { ...selectedBaseLineCustomerState };
    let arr = ['soldTo', 'salesExecutive', 'planLevel', 'salesOffice', 'channel']
    //iterating backword
    for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      let selectedNodes = values[level].filter(isSelected).map(putAll);
      if (selectedNodes.length !== 0) {
        if (level === "soldTo") {
          level = "customers";
        } else if (level === "salesExecutive" && props.data.displaySalesExecutives) {
          level = "salesExecutives";
        } else if (level === "planLevel") {
          level = "planLevels";
        }
        selectedNodes.map((val, i) => {
          reformatKcStr += `&${level}=${val}`;
          selectedArr.push(val);
        });
      }
    }
    return reformatKcStr === '' ? { url: '', selectedArr: [] } : { url: reformatKcStr, selectedArr: selectedArr };
  }

  function unCheckBelowParentLevels(x) {
    let toggleParentCheckBox = { ...baseLineCustomerCheckBox };
    let flagP = false;
    for (let [key, value] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }
    setBaseLineCustomerCheckBox({ ...toggleParentCheckBox });
  };

  const onSelection = (data) => {
    let values = { ...selectedBaseLineCustomerState };
    let x = data[0]; //parent level name:subcat
    let y = data[1]; //value in subcat ex-subcat1
    let selectedArr = [];
    values[x].map(function (item) {
      if (item.name === y) item.isChecked = !item.isChecked;

      if (item.isChecked) {
        selectedArr.push(item.name);
      }
    });

    setSelectedCustomersHierarchy({
      ...selectedCustomersHierarchy,
      [x]: selectedArr
    });

    setSelectedBaseLineCustomerState({
      ...values,
    });

    let toggleParentCheckBox = { ...baseLineCustomerCheckBox };
    let flagP = false;
    for (let [key, value] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }

    if (selectedArr.length === selectedBaseLineCustomerState[x].length) {
      toggleParentCheckBox[x] = true;
    }
    setBaseLineCustomerCheckBox({ ...toggleParentCheckBox });

    updateSelectedLevel(x);

    let obj = generateUrl();
    let completeUrl = obj['url'] + selectedBaseLineProductNew;
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setSelectedBaseLineCustomerNew(obj['url']);
    }
    props.handleSelect(obj['selectedArr']);

  };

  function SelectAllChildren(parentLevel) {
    let values = { ...selectedBaseLineCustomerState };
    let selectedArr = [];
    values[parentLevel].map(
      (x) => ((x.isChecked = true), selectedArr.push(x.name))
    );
    setSelectedBaseLineCustomerState(values);

    updateSelectedLevel(parentLevel);

    let obj = generateUrl();
    let completeUrl = obj['url'] + selectedBaseLineProductNew;
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setSelectedBaseLineCustomerNew(obj['url']);
    };
    props.handleSelect(obj['selectedArr']);
  }

  function DeSelectAllChildren(parentLevel) {
    let values = { ...selectedBaseLineCustomerState };
    values[parentLevel].map((x) => (x.isChecked = false));
    setSelectedBaseLineCustomerState(values);

    updateSelectedLevel(parentLevel);

    let obj = generateUrl();
    let completeUrl = obj['url'] + selectedBaseLineProductNew;
    if (props.tabName !== 'preROI') {
      setSelectedBaseLineFilter(completeUrl);
      setSelectedBaseLineCustomerNew(obj['url']);
    }
    props.handleSelect(obj['selectedArr']);
    unCheckBelowParentLevels(parentLevel);
  }

  const handleParentClick = (e) => {
    let x = e.target.value;
    setBaseLineCustomerCheckBox({
      ...baseLineCustomerCheckBox,
      [x]: !baseLineCustomerCheckBox[x],
    });

    setSelectedCustomersHierarchy({
      ...selectedCustomersHierarchy,
      [x]: []
    });

    if (x === 'source') {
      if (!baseLineCustomerCheckBox[x]) {
        setSelectedSource(['SELL IN', 'SELL OUT']);
        setPostROISourceCheckbox({ 'Sell In': true, 'Sell Out': true });
      } else {
        setSelectedSource([]);
        setPostROISourceCheckbox(sourceInitial);
      }

      return;
    }

    if (!baseLineCustomerCheckBox[x]) SelectAllChildren(x);
    else DeSelectAllChildren(x);
  };

  const handleSourceChange = (e) => {
    let sourceVal = [];
    let x = e.target.value;
    let sourceCheckbox = { ...postROISourceCheckbox, [x]: !postROISourceCheckbox[x] };

    for (const [level, isChecked] of Object.entries(sourceCheckbox)) {
      if (isChecked) {
        sourceVal.push(level.toUpperCase());
      }
    }
    setBaseLineCustomerCheckBox({
      ...baseLineCustomerCheckBox,
      ['source']: sourceVal.length === 2 ? true : false,
    });
    setPostROISourceCheckbox(sourceCheckbox);
    setSelectedSource(sourceVal);
  }

  return (
    <div className={classes.root}>
      <div style={{ direction: 'ltr' }}>
        {Object.entries(display).map(([key, item]) => (

          <div key={key}>
            {
              (key === 'source' && props.tabName === 'postROI' && props?.hasScopeData === true) || (key !== 'source' && key !== 'salesExecutive') || (key === 'salesExecutive' && props.data.displaySalesExecutives)
                ? <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className="customerExpander w-4"
                    onClick={() => handleOpen(key)}
                    style={{ cursor: 'pointer', justifyContent: 'center' }}>
                    {iconState[key] ? '-' : '+'}
                  </div>
                  <input
                    type='checkbox'
                    value={key}
                    checked={baseLineCustomerCheckBox[key]}
                    onChange={handleParentClick} />
                  <label className={`${iconState[key] ? classes.open : classes.hide} customerLabel pl-1`}>
                    {item.name}
                  </label>
                </div>
                : null
            }

            {
              key === "source" && iconState[key]
                ? <div className={classes.filterItem}>
                  {
                    Object.keys(sourceInitial).map((sourceKey) => (
                      <div style={{ alignItems: 'center' }} key={sourceKey}>
                        <input
                          type={"checkbox"}
                          value={sourceKey}
                          checked={postROISourceCheckbox[sourceKey]}
                          onChange={handleSourceChange}
                          className="checker pt-1"
                          style={{ margin: '5px' }}
                        />
                        {sourceKey}
                      </div>
                    ))
                  }
                </div>
                : <div style={{ marginLeft: '20px' }}>
                  {iconState[key] && (
                    <ItemSearch
                      values={selectedBaseLineCustomerState[key]}
                      parentv={key}
                      onSelection={(e) => {
                        onSelection(e);
                      }}
                    />
                  )}
                </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
};
export default NestedBaselineCustomerSearch;
