import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toaster from '../commonMuiComponents/Toaster'
import useStore from "../store";
import { useRoiQuadrant } from "../services/queries";
import { currencySymbol } from "../helper";
import { updateUrl } from "../UpdateUrl";

const useStyles = makeStyles((theme) => ({
  disFlex: {
    display: "flex",
    minHeight: "138px",
  },

  manContainer: {
    padding: 15,
  },

  quradent: {
    flex: 1,
    textAlign: "center",
    border: "1px solid #000",
    position: "relative",
    fontSize: "18px",
    fontWeight: 600,
  },
  btmtxt: {
    position: "absolute",
    textAlign: "center",
    bottom: 0,
    width: "100%",
  },

  lightGreen: {
    background: "#ceeece",
  },
  lightgray: {
    background: "#d3ddd3",
  },
  lightred: {
    background: "#e8a3a3",
  },
  lightsgreen: {
    background: "#f3f4f3",
  },
}));

export default function PostRoi(props) {
  const classes = useStyles();

  //store value
  let selectedContry = useStore((state) => state.selectedCountry);
  let selectedBaseLineFilters = useStore(
    (state) => state.selectedBaseLineFilters
  );
  let selectedBaseLineCustomerNew = useStore(
    (state) => state.selectedBaseLineCustomerNew
  );
  let selectedBaseLineProductNew = useStore(
    (state) => state.selectedBaseLineProductNew
  );
  let groupBy = useStore((state) => state.groupBy);
  const startMonth = useStore((state) => state.startMonth);
  const endMonth = useStore((state) => state.endMonth);

  const [data, setData] = useState("");
  let selectedSource = useStore(
    (state) => state.selectedSource
  );

  const [roiQuadrantPayload, setRoiQuadrantPayload] = useState(null);
  const selectedCustomersHierarchy = useStore(state => state.selectedCustomersHierarchy);
  const selectedProductsHierarchy = useStore(state => state.selectedProductsHierarchy);
  const selectedEventIdValues = useStore(state => state.selectedEventIdValues);
  const selectedQuadrant = useStore(state => state.selectedQuadrant);

  //calling api
  const { isLoading: isRoiData, data: RoiData, isError: roiDataError } = useRoiQuadrant(roiQuadrantPayload);
  useEffect(() => {
    let payload = {
      country: selectedContry,
      customers: selectedCustomersHierarchy?.soldTo,
      planLevels: selectedCustomersHierarchy?.planLevel,
      salesExecutive: selectedCustomersHierarchy?.salesExecutive,
      source: selectedSource,
      fromDate: startMonth,
      toDate: endMonth,
      eans: selectedProductsHierarchy?.products,
      categories: selectedProductsHierarchy?.categories,
      subCategories: selectedProductsHierarchy?.subCategories,
      brands: selectedProductsHierarchy?.brands,
      subBrands: selectedProductsHierarchy?.subBrands,
      groupBy,
      eventIds: selectedEventIdValues,
      quadrants: selectedQuadrant
    };

    setRoiQuadrantPayload(payload);
  }, [selectedContry, startMonth, endMonth,
    groupBy, selectedSource, selectedQuadrant,
    selectedCustomersHierarchy?.soldTo,
    selectedCustomersHierarchy?.planLevel,
    selectedCustomersHierarchy?.salesExecutive,
    selectedProductsHierarchy?.products,
    selectedProductsHierarchy?.categories,
    selectedProductsHierarchy?.subCategories,
    selectedProductsHierarchy?.brands,
    selectedProductsHierarchy?.subBrands, ,
    selectedBaseLineCustomerNew, selectedBaseLineProductNew, selectedEventIdValues]);

  useEffect(() => {
    if (!isRoiData) {
      setData(RoiData);
    }
  }, [isRoiData, groupBy, selectedBaseLineFilters]);

  let Quadrant1 =
    "Quadrant 1 - Promo Investments with ROI > 0 and Lift Volume > 0";
  let Quadrant2 =
    "Quadrant 2 - Promo Investments with ROI <= 0 and Lift Volume > 0";
  let Quadrant3 =
    "Quadrant 3 - Promo Investments with ROI <=0  and Lift Volume <= 0";
  let Quadrant4 =
    "Quadrant 4 - Promo Investments with ROI > 0 and Lift Volume <= 0";

  return (
    <div className={classes.manContainer1}>
      {roiDataError && <Toaster showAlert={{
        type: 'error',
        message: "Some thing went wrong, please reload page"
      }} />}
      {data && selectedContry && (
        <>
          <div className={classes.disFlex}>
            <div
              className={`${classes.quradent}  ${classes.lightgray}`}
              title={Quadrant2}
            >
              <p>
                {data.q2PromoPercent.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
              <p className={classes.btmtxt}>
                {currencySymbol(selectedContry)}
                {data.q2PromoInvestment.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
            <div
              className={`${classes.quradent}  ${classes.lightGreen}`}
              title={Quadrant1}
            >
              <p>
                {data.q1PromoPercent.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
              <p className={classes.btmtxt}>
                {currencySymbol(selectedContry)}
                {data.q1PromoInvestment.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
          </div>

          <div className={classes.disFlex}>
            <div
              className={`${classes.quradent}  ${classes.lightred}`}
              title={Quadrant3}
            >
              <p>
                {data.q3PromoPercent.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
              <p className={classes.btmtxt}>
                {currencySymbol(selectedContry)}
                {data.q3PromoInvestment.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
            <div
              className={`${classes.quradent}  ${classes.lightsgreen}`}
              title={Quadrant4}
            >
              <p>
                {data.q4PromoPercent.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
              <p className={classes.btmtxt}>
                {" "}
                {currencySymbol(selectedContry)}
                {data.q4PromoInvestment.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
