import React, { useEffect, useState } from 'react';
import { Stack, Box, TextField, Grid, MenuItem } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import useStore from "../../../../store";

import moment from "moment";
import { makeStyles } from '@material-ui/core';
import usePromoSimulatorStore from '../../../../store/promoSimulatorStore';
import { mockDropdownApiResposne } from "../Constants/mockDropdownApiResposne/mockDropdownApiResposne"

export const PromoPeriodSelector = ({ id, promoData }: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [updateDate, setUpdateDate] = React.useState(false);
  const promoSellInPriceType: any = mockDropdownApiResposne?.inputs.filter((resp: any) => {
    return (resp.internal === 'PROMO_SELL_IN_PRICE');
  });
  const measureUnits: any = promoSellInPriceType[0]?.units;
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);

  const [unitOfMeasure, setUnitOfMeasure] = useState(promoSimulatorTabs[id].unitOfMeasure);
  const [fromDate, setFromDate] = useState(promoSimulatorTabs[id].fromDate);
  const [toDate, setToDate] = useState(promoSimulatorTabs[id].toDate);
  const [duration, setDuration] = useState(promoSimulatorTabs[id].duration);
  const [fromDateErrorMsg, setFromDateErrorMsg] = useState('');
  const [toDateErrorMsg, setToDateErrorMsg] = useState('');
  const [bufferValue, setBufferValue] = useState({ value: '', name: '' });

  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);

  const calDuration = (startDate: any, endDate: any) => {
    let stDate = moment(startDate, "YYYY/MM/D");
    let edDate = moment(endDate, "YYYY/MM/D");
    let durationVal = edDate.diff(stDate, 'days');

    if (durationVal >= 0) {
      setDuration(durationVal + 1);
    } else if (durationVal < 0) {
      setDuration(0);
    }
  };

  const handleUnitOfMeasureChange = (e: any) => {
    setUnitOfMeasure(e.target.value);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, unitOfMeasure: e.target.value } : tab));
  };
  const checkForUpdate = () => {
    if (promoSimulatorTabs[0].calculateButtonClicked) {
      setOpen(true)
    } else {
      return true;
    }

  }

  const inputValueChange = (e: any, name: string) => {
    // console.log("THIS is called",e,name);
    // setBufferValue({value:e,name:name})
    // if(checkForUpdate()){
    updateValue(e, name)
    // }
  };
  const updateValue = (e: any, name: string) => {
    switch (name) {
      case 'fromDate':
        if (!moment(e).isAfter(toDate)) {
          setFromDate(e);
          calDuration(e, toDate);
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, fromDate: e } : tab));
          setFromDateErrorMsg('')
        }
        else {
          setFromDateErrorMsg('From Date must be equal or less than To Date');
          setFromDate('')
          setToDateErrorMsg('')
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, duration: 0, fromDate: '' } : tab));
          setDuration(0)
        }
        break;
      case 'toDate':
        if (moment(e).isSameOrAfter(fromDate)) {
          setToDate(e);
          calDuration(fromDate, e);
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, toDate: e } : tab));
          setToDateErrorMsg('')
        }
        else {
          setToDateErrorMsg('To Date must be equal or greater than From Date');
          setToDate('')
          setFromDateErrorMsg('')
          setDuration(0)
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, duration: 0, toDate: '' } : tab));
        }
        break;
      default:
        return null;
    };
  };

  const handleYes = () => {
    setOpen(false)
    updateValue(bufferValue.value, bufferValue.name)
  }
  const handleNo = () => {
    setOpen(false)
    updateValue(toDate, "toDate")
    updateValue(fromDate, "fromDate")
  }

  useEffect(() => {
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, duration: duration } : tab));
  }, [duration]);

  useEffect(() => {
    setFromDate(promoSimulatorTabs[id].fromDate);
    setToDate(promoSimulatorTabs[id].toDate);
  }, [promoSimulatorTabs])

  useEffect(() => {
    if (promoData && selectedPromoDetails.fromView) {
      calDuration(promoData.promoFrom, promoData.promoTo);
      setFromDate(new Date(promoData.promoFrom));
      setToDate(new Date(promoData.promoTo));
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, fromDate: new Date(promoData.promoFrom), toDate: new Date(promoData.promoTo), duration: duration, reset: false } : tab))
    }
  }, [selectedPromoDetails, promoData])

  useEffect(() => {
    if (promoSimulatorTabs[id]?.reset) {
      setDuration(0)
    }
  }, [promoSimulatorTabs[id]?.reset])

  return (
    <Box sx={{ mt: 2, }}>
      <Grid container>
        <Grid item xs={2}>
          <Box sx={{ m: 1, mt: 5, fontWeight: '500', fontSize: '0.75rem' }}>Promo Period <span className="text-red-900 font-bold text-base">*</span></Box>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="column" spacing={1}>
            <Box className="text-sm" sx={{ mt: 1, fontSize: '0.75rem' }}>From<span className="text-red-900 font-bold text-base">*</span></Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className={classes.fieldWidth}
                value={fromDate}
                onChange={(newValue: any) => inputValueChange(newValue, 'fromDate')}
                format="yyyy-MM-dd"
                disabled={promoSimulatorTabs[id].calculateButtonClicked}
                slotProps={{
                  textField: {
                    helperText: <div style={{ color: '#d32f2f', fontSize: '.75rem' }}>{fromDateErrorMsg}</div>,
                  },
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="column" spacing={1}>
            <Box className="text-sm" sx={{ mt: 1, fontSize: '0.75rem' }}>To<span className="text-red-900 font-bold text-base">*</span></Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className={classes.fieldWidth}
                disabled={promoSimulatorTabs[id].calculateButtonClicked || !moment(fromDate).isValid()}
                value={toDate}
                onChange={(newValue: any) => inputValueChange(newValue, 'toDate')}
                format="yyyy-MM-dd"
                minDate={fromDate}
                slotProps={{
                  textField: {
                    helperText: <div style={{ color: '#d32f2f', fontSize: '.75rem' }}>{toDateErrorMsg}</div>,
                  },
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ p: 1 }}>
            <Stack direction="column" spacing={1}>
              <Box className="text-sm" sx={{ fontSize: '0.75rem' }}>Duration:</Box>
              <Box className="font-bold" sx={{ fontSize: '0.75rem', paddingTop: '7%' }}>{duration}&nbsp;days</Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" spacing={1}>
            <Stack direction="column" spacing={1}>
              <Box sx={{ fontSize: '0.75rem' }} >Unit of measure</Box>
              <TextField
                select
                size="small"
                variant="outlined"
                className={classes.fieldWidth}
                value={unitOfMeasure}
                onChange={e => handleUnitOfMeasureChange(e)}
                InputLabelProps={{ shrink: true }}
                required
                disabled
              >
                {
                  measureUnits?.map((unit: any, index: number) => {
                    return <MenuItem key={index} value={unit}>{unit}</MenuItem>
                  })}
              </TextField>
            </Stack>
            <Stack direction="column">
              <Box sx={{ mt: 2, fontSize: '0.75rem' }}>All Volumes and Prices in  the simulations are in Packs</Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={1}>

        </Grid>
      </Grid>
      {/* <AlertDialog openDialog={open} handleYes={handleYes} handleNo={handleNo}></AlertDialog> */}
    </Box>
  );
};


const useStyles = makeStyles(() => ({
  fieldWidth: {
    width: '180px',
    '& input': {
      fontSize: '0.75rem !important',
    },
    '& div': {
      fontSize: '0.75rem !important',
    }
  }
}));