import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  IconButton
} from "@material-ui/core"
import InfoDialog from "./InfoDialog";
import { updateUrl } from "../UpdateUrl";
import InfoIcon from '@mui/icons-material/Info';
import { generateCountiesDataList, generateCountriesDailyDataList } from '../helper';
import Toaster from '../commonMuiComponents/Toaster'
import { CircularProgress, Box } from '@mui/material';
import useStore from "../store";

import {
  useBaseLineData,
  useGetDeleteImpactorHistory,
  useImpactorInfo,
  useDailyDataCheck,
} from "../services/queries";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    maxHeight: 500,
    overflow: 'scroll',
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
    borderCollapse: 'collapse'
  },
  cellData: {
    textTransform: "capitalize",
    boxShadow: 'inset 0.5px 0.5px 0 rgba(0,0,0,0.1), inset -0.5px -0.5px 0 rgba(0,0,0,0.1)'
  },
  cellDataCenter: {
    textTransform: "capitalize",
    textAlign: "center",
    boxShadow: 'inset 0.5px 0.5px 0 rgba(0,0,0,0.1), inset -0.5px -0.5px 0 rgba(0,0,0,0.1)'
  },
  btnContainer: {
    marginTop: "30px",
    float: "right",
    marginRight: "50px",
  },
  btnReset: {
    background: "#cb0c0c",
    color: "#fff",
    textTransform: "none",
    margin: "0 20px",
    padding: "6px 20px",
    borderRadius: "10px",
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
  dialogWidth: {
    minWidth: "1000px",
    maxWidth: "1200px",
  },
  btnClose: {
    position: "absolute",
    top: "0",
    right: "5px",
  },
  infoContainer: {
    marginRight: 15,
    cursor: 'pointer'
  },
  tableContainerParent: {
    maxHeight: 500,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: 12,
      height: 12
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px lightgrey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D3D3D3",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "grey",
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Country() {
  let selectedContry = useStore((state) => state.selectedCountry);
  const [selectedImpactor, setSelectorImpactor] = useState("");
  const [impactorInfo, setImpactorInfo] = useState({});

  let selectedBaseLineFilters = useStore(
    (state) => state.selectedBaseLineFilters
  );

  const [dailyDataAvailable, setDailyDataAvailable] = React.useState(false);
  const { isLoading: isLoadingData, data: dailyData } = useDailyDataCheck(selectedContry);
  let updatedBaseLineFilter = updateUrl(selectedBaseLineFilters);

  useEffect(() => {
    if (!isLoadingData) {
      setDailyDataAvailable(dailyData.dailyAvailable);
    }
  }, [isLoadingData]);
  //
  let selectedYear = useStore((state) => state.selectedYear);
  //let viewMonthly = useStore((state) => state.viewMonthly === "daily" ? "daily": "weekly" );  

  //let viewMonthly = useStore((state) => state.viewMonthly);
  let viewMonthly = useStore((state) => (state.viewMonthly === "daily" || state.viewMonthly === "monthly" || state.viewMonthly === "weekly") && dailyDataAvailable ? "daily" : "weekly");
  const [baseLineDataPayload, setBaseLineDataPayload] = useState(null);
  const { isLoading: isCountryLineData, data: countryLineData, isError: countryLineDataError } = useBaseLineData(baseLineDataPayload);

  const selectedCustomersHierarchy = useStore(state => state.selectedCustomersHierarchy);
  const selectedProductsHierarchy = useStore(state => state.selectedProductsHierarchy);


  useEffect(() => {
    let payload = {
      country: selectedContry,
      years: selectedYear,
      granularity: viewMonthly,
      customers: selectedCustomersHierarchy?.soldTo,
      eans: selectedProductsHierarchy?.products,
      categories: selectedProductsHierarchy?.categories,
      subCategories: selectedProductsHierarchy?.subCategories,
      brands: selectedProductsHierarchy?.brands,
      subBrands: selectedProductsHierarchy?.subBrands
    }
    setBaseLineDataPayload(payload)
  }, [
    selectedContry,
    selectedCustomersHierarchy?.soldTo,
    selectedProductsHierarchy?.products,
    selectedProductsHierarchy?.categories,
    selectedProductsHierarchy?.subCategories,
    selectedProductsHierarchy?.brands,
    selectedProductsHierarchy?.subBrands,
    viewMonthly, selectedYear]);

  const { isLoading: isImpactorHistoryAPI, data: impactorHistoryAPI, isError: impactorHistoryAPIError } =
    useGetDeleteImpactorHistory(`${selectedContry}&granularity=${viewMonthly}`);

  const { isLoading: isImpactorInfoAPI, data: impactorInfoAPI, isError: impactorInfoApiError } =
    useImpactorInfo(`${selectedImpactor}`);

  useEffect(() => {
    if (!isImpactorInfoAPI) {
      console.log("impactorInfoAPI121", impactorInfoAPI);
      setImpactorInfo(impactorInfoAPI);
    }
  }, [isImpactorInfoAPI]);

  const [countriesList, setCountriesList] = useState([]);

  const [impactorHistoryData, setImpactorHistoryData] = useState([]);

  useEffect(() => {
    if (!isCountryLineData) {
      setCountriesList(countryLineData);
    }
  }, [isCountryLineData]);

  useEffect(() => {
    if (!isImpactorHistoryAPI) {
      if (viewMonthly !== "daily")
        setImpactorHistoryData(generateCountiesDataList(impactorHistoryAPI));
      else setImpactorHistoryData(generateCountriesDailyDataList(impactorHistoryAPI));
    }
  }, [isImpactorHistoryAPI]);

  const { isLoading: isProductData, data: productData } = useBaseLineData(baseLineDataPayload);
  const [productService, setProductService] = useState([]);
  const [apidataLocal, setApidataLocal] = useState([]);
  const [apidata, setApidata] = useState({});

  useEffect(() => {
    if (!isProductData) {
      setApidataLocal(productData);

      if (selectedContry) {
        setApidata(generateCountiesDataList(productData));
      }
    }
  }, [isProductData, selectedBaseLineFilters]);

  useEffect(() => {
    setApidata(apidataLocal);
    setApidata(generateCountiesDataList(apidataLocal));
  }, [selectedContry, selectedBaseLineFilters]);

  //data loading
  let rowsData = [];


  console.log("rowsData", rowsData);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openInfo, setOpenInfo] = React.useState(false);

  const handleClickInfoOpen = (impName) => {
    console.log("impName274", impName);
    setSelectorImpactor(impName);
    setOpenInfo(true);
  };

  const handleInfoClose = () => {
    setOpenInfo(false);
  };

  return (
    <div data-testid="impactedHistoryDelete">
      {isImpactorHistoryAPI && <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px',
        }}>
            <CircularProgress sx={{ color: "#0000b3"}} />
        </Box>
      }
      {(countryLineDataError || impactorHistoryAPIError || impactorInfoApiError) && <Toaster showAlert={{
        type: 'error',
        message: "Some thing went wrong, please reload page"
      }} />}
      {!isImpactorHistoryAPI &&
        selectedContry &&
        impactorHistoryData.dataRows?.length > 0 && (

          <TableContainer component={Paper} className={classes.tableContainerParent}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead
                  style={{
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 900,
                    background: "white",
                  }}>
                <TableRow>
                  <TableCell
                    align="center"
                    className={classes.cellData}
                    colSpan={"1"}
                  >
                    {impactorHistoryData.headerData[0][0]}
                  </TableCell>

                  {impactorHistoryData.headerData[0]
                    .slice(1)
                    .map((headeVal, index) => (
                      <TableCell
                        align="center"
                        className={classes.cellData}
                        colSpan={impactorHistoryData.weekCountAPI[index + 1]}
                      >
                        {headeVal}
                      </TableCell>
                    ))}

                  <TableCell
                    align="center"
                    className={classes.cellData}
                  ></TableCell>
                </TableRow>

                <TableRow>
                  {impactorHistoryData.subAryheader[1]?.map((row, i) => (
                    <>
                      {!Array.isArray(row) && (
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.cellDataCenter}
                        >
                          {row}
                        </TableCell>
                      )}

                      {Array.isArray(row) &&
                        row.map((aryVal) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.cellDataCenter}
                          >
                            {aryVal}
                          </TableCell>
                        ))}
                    </>
                  ))}
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.cellDataCenter}
                  ></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {impactorHistoryData.dataRows?.map((row, i) => (
                  <>
                    <TableRow key={"row[0]"}>
                      {Array.isArray(row) && (
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 800,
                            background: "white",
                          }}
                          component="th"
                          scope="row"
                          className={classes.cellDataCenter}
                        >
                          {row[0]}
                        </TableCell>
                      )}

                      {Array.isArray(row) &&
                        row.slice(1).map((aryVal) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.cellDataCenter}
                          >
                            {aryVal}
                          </TableCell>
                        ))}
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.cellDataCenter}
                        style={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          background: "white",
                          width: 100,
                          minWidth: 100,
                        }}
                      >
                        <span
                          onClick={() => handleClickInfoOpen(row[0])}
                          className={classes.infoContainer}
                        >
                          <InfoIcon sx={{ color: "#0000b3" }} />
                        </span>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>



        )}

      {/*  info dialog starts here */}
      <Dialog
        open={openInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleInfoClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {selectedImpactor}
          <IconButton
            aria-label="close"
            onClick={handleInfoClose}
            className={classes.btnClose}
          >
            X
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <InfoDialog impactorData={impactorInfo} />
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <Button onClick={handleInfoClose} className={classes.btnReset}>
              Close
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {!isImpactorHistoryAPI &&
        impactorHistoryData.dataRows?.length === 0 && (
          <div>No records to display </div>
        )}
    </div>
  );
}
