import React, { useEffect } from "react";
import { Button, Box, makeStyles } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import DialogTitle from "@material-ui/core/DialogTitle";
import { AxiosResponse } from "axios";

import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import useStore from "../../../../store";
import { useSavePnL } from "../../../../services/mutations";
import { scrollTo } from "../utils/scrollTo";
import { ApiRequestService } from "../../../../services/api-service";
import { useGetSimulatedPromotions } from "../../../../services/queries";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SaveButton = (props: any) => {
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [isDownloading, setisDownloading] = React.useState(false);
  const [promoQuery, setPromoQuery] = React.useState({});

  let selectedCountry = useStore((state) => state.selectedCountry);
  let preRoiSelectedCustomers = useStore((state) => state.preRoiSelectedCustomers);
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);
  const setEnableDownloadButton = useStore((state) => state.setEnableDownloadButton);
  const enableDownloadButton = useStore((state) => state.enableDownloadButton);

  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const { mutate: savePnL, data: pnlData, isSuccess: saveSuccess, isError: successError, isLoading: isSaveInProgress } = useSavePnL();
  const { data: promoData, isError: promoError, isLoading: isSimulationDataLoading } = useGetSimulatedPromotions(promoQuery);

  useEffect(() => {
    setEnableDownloadButton(false);
  }, [])

  useEffect(() => {
    if (pnlData && pnlData.data && pnlData.data.message) {
      setOpenSuccess(true);
      setEnableDownloadButton(true);
      setPromoQuery({
        "country": selectedCountry,
        "customers": [],
        "promoFrom": '',
        "promoTo": '',
        "sectors": [],
        "categories": [],
        "subCategories": [],
        "brands": [],
        "subBrands": [],
        "eans": [],
      });
    } else {
      setPromoQuery({});
    }
  }, [pnlData])

  useEffect(() => {
    if(selectedPromoDetails.fromView && (promoSimulatorTabs[0].PnLResposne.length > 0) && promoSimulatorTabs[0].promoName) {
      setEnableDownloadButton(true);
    } else if(promoSimulatorTabs[0].PnLResposne.length > 0) {
      setPromoQuery({
        "country": selectedCountry,
        "customers": [],
        "promoFrom": '',
        "promoTo": '',
        "sectors": [],
        "categories": [],
        "subCategories": [],
        "brands": [],
        "subBrands": [],
        "eans": [],
      });
    } else if(!(promoSimulatorTabs[0].PnLResposne.length > 0)) {
      setEnableDownloadButton(false);
      setPromoQuery({});
    }
  }, [promoSimulatorTabs, selectedPromoDetails])

  useEffect(() => {
    if(promoData && promoData.data && promoData.data.length > 0 && promoSimulatorTabs[0].PnLResposne.length > 0 && promoSimulatorTabs[0].promoName) {
      const findPromo = promoData.data.filter((promo: any) => {
        return promo.promoName === promoSimulatorTabs[0].promoName;
      })
      if(findPromo && findPromo.length > 0) {
        setEnableDownloadButton(true);
      }
    }
  }, [promoData])

  const handleDownload = async () => {
    // @ts-expect-error
    let productArray = [];
    let displayPromoCalculations = promoSimulatorTabs[0].displayPromo ? promoSimulatorTabs[0].displayPromo : [];
    if (promoSimulatorTabs[0].pnlInputArray.length > 0) {
      promoSimulatorTabs[0].pnlInputArray.forEach((item: any) => {
        if (item.key !== '') {
          let product = {
            "additionalFunding": 0,
            "additionalFundingOverride": 0,
            "baseGSVListPrice": 0,
            "baseSellInInvoicePrice": 0,
            "baseSellOutPrice": 0,
            "baseSellOutPriceOverride": 0,
            "baseVolume": 0,
            "baseVolumeOverride": 0,
            "brand": "",
            "category": "",
            "cogs": 0,
            "country": "KR",
            "discount": 0,
            "discountPercent": 0,
            "distributionExpense": 0,
            "ean": "",
            "eanDescription": "",
            "fixedCog": 0,
            "cogsOverride": 0,
            "multiBuy": false,
            "promoName": "",
            "promoSellInInvoicePrice": 0,
            "promoSellOutPrice": 0,
            "promoVolume": 0,
            "redemptionRate": 0,
            "sector": "",
            "sellInListPriceOverride": 0,
            "subBrand": "",
            "subCategory": "",
            "tax": 0,
            "tradingTerms": 0,
            "variableCog": 0,
            "volumeUplift": 0
          }
          product.ean = item.key;
          product.additionalFunding = displayPromoCalculations.filter((item: any) => item.key === 'additionalFunding').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.additionalFundingOverride = displayPromoCalculations.filter((item: any) => item.key === 'additionalFundingOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.baseGSVListPrice = displayPromoCalculations.filter((item: any) => item.key === 'baseGSVListPrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.baseSellInInvoicePrice = displayPromoCalculations.filter((item: any) => item.key === 'baseSellInInvoicePrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.baseSellOutPrice = displayPromoCalculations.filter((item: any) => item.key === 'baseSellOutPrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.baseSellOutPriceOverride = displayPromoCalculations.filter((item: any) => item.key === 'baseSellOutPriceOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.baseVolume = displayPromoCalculations.filter((item: any) => item.key === 'baseVolume').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.baseVolumeOverride = displayPromoCalculations.filter((item: any) => item.key === 'baseVolumeOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.brand = displayPromoCalculations.filter((item: any) => item.key === 'brand').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.category = displayPromoCalculations.filter((item: any) => item.key === 'category').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.cogs = displayPromoCalculations.filter((item: any) => item.key === 'cogs').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.country = selectedCountry
          product.discount = displayPromoCalculations.filter((item: any) => item.key === 'discount').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.discountPercent = displayPromoCalculations.filter((item: any) => item.key === 'discountPercent').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.distributionExpense = displayPromoCalculations.filter((item: any) => item.key === 'distributionExpense').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.eanDescription = displayPromoCalculations.filter((item: any) => item.key === 'eanDescription').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.cogsOverride = displayPromoCalculations.filter((item: any) => item.key === 'fixedCogOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.multiBuy = promoSimulatorTabs[0].sellOutPriceType === 'MULTIBUY'
          product.promoName = promoSimulatorTabs[0].promoName;
          product.promoSellInInvoicePrice = displayPromoCalculations.filter((item: any) => item.key === 'promoSellInInvoicePrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.promoSellOutPrice = displayPromoCalculations.filter((item: any) => item.key === 'promoSellOutPrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.promoVolume = displayPromoCalculations.filter((item: any) => item.key === 'promoVolume').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.redemptionRate = promoSimulatorTabs[0].sellOutPriceType === 'MULTIBUY' ? Number(promoSimulatorTabs[0].redemptionRate) : 0;
          product.sector = displayPromoCalculations.filter((item: any) => item.key === 'sector').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.sellInListPriceOverride = displayPromoCalculations.filter((item: any) => item.key === 'sellInListPriceOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.subBrand = displayPromoCalculations.filter((item: any) => item.key === 'subBrand').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.subCategory = displayPromoCalculations.filter((item: any) => item.key === 'subCategory').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.tax = displayPromoCalculations.filter((item: any) => item.key === 'tax').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.tradingTerms = displayPromoCalculations.filter((item: any) => item.key === 'tradingTerms').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          product.volumeUplift = displayPromoCalculations.filter((item: any) => item.key === 'volumeUplift').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
          productArray.push(product)
        }
      });
      let payLoad = {
        "additionalFunding": promoSimulatorTabs[0].additionalFunding,
        "channel": "",
        "country": selectedCountry,
        "customer": preRoiSelectedCustomers,
        "fixedCosts": promoSimulatorTabs[0].fixedCost,
        "name": promoSimulatorTabs[0].promoName,
        "planningLevel": "",
        // @ts-expect-error
        "products": productArray,
        "promoFrom": moment(promoSimulatorTabs[0].fromDate).format("yyyy-MM-DD"),
        "promoTo": moment(promoSimulatorTabs[0].toDate).format("yyyy-MM-DD"),
        "salesOffice": "",
        "unitOfMeasure": promoSimulatorTabs[0].unitOfMeasure,
        "promoSellInOption": promoSimulatorTabs[0].sellInPriceType,
        "promoSellInValue": Number(promoSimulatorTabs[0].sellInPriceTypevalue),
        "promoSellOutOption": promoSimulatorTabs[0].sellOutPriceType,
        "promoSellOutValue": promoSimulatorTabs[0].sellOutPriceType !== 'MULTIBUY' ? Number(promoSimulatorTabs[0].sellOutPriceTypeValue) : "",
        "promoVolumeOption": promoSimulatorTabs[0].volumeType,
        "promoVolumeValue": Number(promoSimulatorTabs[0].volumeTypeValue),
        "redemptionRate": Number(promoSimulatorTabs[0].redemptionRate),
        "multiBuyOption": promoSimulatorTabs[0].sellOutPriceType === 'MULTIBUY' ? promoSimulatorTabs[0].sellOutPriceTypeValue : "",
      }
      try {
        setisDownloading(true);
        const api = ApiRequestService.createInstance();
        const res: AxiosResponse = await api.donloadPromoDetails(
          payLoad
        );
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${selectedCountry}_${promoSimulatorTabs[0].promoName}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setisDownloading(false);
      } catch (error) {
        setisDownloading(false);
        console.log("Promotion download error", error);
      }
    }
  }

  const handleSave = () => {
    if (selectedPromoDetails.fromView && selectedPromoDetails.type === 'duplicate' && promoSimulatorTabs[0] && (promoSimulatorTabs[0].promoName).length <= 0) {
      props.setPromoNameError(true);
      setTimeout(() => {
        scrollTo("promo-name");
      }, 100);
    } else {
      // @ts-expect-error
      let productArray = [];
      let displayPromoCalculations = promoSimulatorTabs[0].displayPromo ? promoSimulatorTabs[0].displayPromo : [];
      if (promoSimulatorTabs[0].pnlInputArray.length > 0) {
        promoSimulatorTabs[0].pnlInputArray.forEach((item: any) => {
          if (item.key !== '') {
            let product = {
              "additionalFunding": 0,
              "additionalFundingOverride": 0,
              "baseGSVListPrice": 0,
              "baseSellInInvoicePrice": 0,
              "baseSellOutPrice": 0,
              "baseSellOutPriceOverride": 0,
              "baseVolume": 0,
              "baseVolumeOverride": 0,
              "brand": "",
              "category": "",
              "cogs": 0,
              "country": "KR",
              "discount": 0,
              "discountPercent": 0,
              "distributionExpense": 0,
              "ean": "",
              "eanDescription": "",
              "fixedCog": 0,
              "cogsOverride": 0,
              "multiBuy": false,
              "promoName": "",
              "promoSellInInvoicePrice": 0,
              "promoSellOutPrice": 0,
              "promoVolume": 0,
              "redemptionRate": 0,
              "sector": "",
              "sellInListPriceOverride": 0,
              "subBrand": "",
              "subCategory": "",
              "tax": 0,
              "tradingTerms": 0,
              "variableCog": 0,
              "volumeUplift": 0
            }
            product.ean = item.key;
            product.additionalFunding = displayPromoCalculations.filter((item: any) => item.key === 'additionalFunding').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.additionalFundingOverride = displayPromoCalculations.filter((item: any) => item.key === 'additionalFundingOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.baseGSVListPrice = displayPromoCalculations.filter((item: any) => item.key === 'baseGSVListPrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.baseSellInInvoicePrice = displayPromoCalculations.filter((item: any) => item.key === 'baseSellInInvoicePrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.baseSellOutPrice = displayPromoCalculations.filter((item: any) => item.key === 'baseSellOutPrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.baseSellOutPriceOverride = displayPromoCalculations.filter((item: any) => item.key === 'baseSellOutPriceOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.baseVolume = displayPromoCalculations.filter((item: any) => item.key === 'baseVolume').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.baseVolumeOverride = displayPromoCalculations.filter((item: any) => item.key === 'baseVolumeOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.brand = displayPromoCalculations.filter((item: any) => item.key === 'brand').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.category = displayPromoCalculations.filter((item: any) => item.key === 'category').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.cogs = displayPromoCalculations.filter((item: any) => item.key === 'cogs').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.country = selectedCountry
            product.discount = displayPromoCalculations.filter((item: any) => item.key === 'discount').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.discountPercent = displayPromoCalculations.filter((item: any) => item.key === 'discountPercent').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.distributionExpense = displayPromoCalculations.filter((item: any) => item.key === 'distributionExpense').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.eanDescription = displayPromoCalculations.filter((item: any) => item.key === 'eanDescription').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.cogsOverride = displayPromoCalculations.filter((item: any) => item.key === 'fixedCogOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.multiBuy = promoSimulatorTabs[0].sellOutPriceType === 'MULTIBUY'
            product.promoName = promoSimulatorTabs[0].promoName;
            product.promoSellInInvoicePrice = displayPromoCalculations.filter((item: any) => item.key === 'promoSellInInvoicePrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.promoSellOutPrice = displayPromoCalculations.filter((item: any) => item.key === 'promoSellOutPrice').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.promoVolume = displayPromoCalculations.filter((item: any) => item.key === 'promoVolume').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.redemptionRate = promoSimulatorTabs[0].sellOutPriceType === 'MULTIBUY' ? Number(promoSimulatorTabs[0].redemptionRate) : 0;
            product.sector = displayPromoCalculations.filter((item: any) => item.key === 'sector').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.sellInListPriceOverride = displayPromoCalculations.filter((item: any) => item.key === 'sellInListPriceOverride').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.subBrand = displayPromoCalculations.filter((item: any) => item.key === 'subBrand').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.subCategory = displayPromoCalculations.filter((item: any) => item.key === 'subCategory').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.tax = displayPromoCalculations.filter((item: any) => item.key === 'tax').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.tradingTerms = displayPromoCalculations.filter((item: any) => item.key === 'tradingTerms').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            product.volumeUplift = displayPromoCalculations.filter((item: any) => item.key === 'volumeUplift').flatMap((data: any) => data.value).map((val: any) => val[item.key]).find((val: any) => val !== undefined);
            productArray.push(product)
          }
        });
        let payLoad = {
          "additionalFunding": promoSimulatorTabs[0].additionalFunding ? promoSimulatorTabs[0].additionalFunding : 0,
          "channel": "",
          "country": selectedCountry,
          "customer": preRoiSelectedCustomers,
          "fixedCosts": promoSimulatorTabs[0].fixedCost,
          "name": promoSimulatorTabs[0].promoName,
          "planningLevel": "",
          // @ts-expect-error
          "products": productArray,
          "promoFrom": moment(promoSimulatorTabs[0].fromDate).format("yyyy-MM-DD"),
          "promoTo": moment(promoSimulatorTabs[0].toDate).format("yyyy-MM-DD"),
          "salesOffice": "",
          "unitOfMeasure": promoSimulatorTabs[0].unitOfMeasure,
          "promoSellInOption": promoSimulatorTabs[0].sellInPriceType,
          "promoSellInValue": Number(promoSimulatorTabs[0].sellInPriceTypevalue),
          "promoSellOutOption": promoSimulatorTabs[0].sellOutPriceType,
          "promoSellOutValue": promoSimulatorTabs[0].sellOutPriceType !== 'MULTIBUY' ? Number(promoSimulatorTabs[0].sellOutPriceTypeValue) : "",
          "promoVolumeOption": promoSimulatorTabs[0].volumeType,
          "promoVolumeValue": Number(promoSimulatorTabs[0].volumeTypeValue),
          "redemptionRate": Number(promoSimulatorTabs[0].redemptionRate),
          "multiBuyOption": promoSimulatorTabs[0].sellOutPriceType === 'MULTIBUY' ? promoSimulatorTabs[0].sellOutPriceTypeValue : "",
        }
        savePnL(payLoad)
      }
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const enableDownload = selectedPromoDetails.fromView ? (promoSimulatorTabs[0].PnLResposne.length > 0 && promoSimulatorTabs[0].promoName ? true : false) : (pnlData && pnlData.data && pnlData.data.message ? true : false);

  return (
    <>
    <div>
      <Button disabled={!(promoSimulatorTabs[0].PnLResposne.length > 0)} onClick={handleSave} className={(promoSimulatorTabs[0].PnLResposne.length > 0) ? classes.calculateBtn : classes.disabledBtn}>
        Save
      </Button>
      <Button disabled={!enableDownloadButton} onClick={handleDownload} className={enableDownloadButton ? classes.calculateBtn : classes.disabledBtn}>
        Download
      </Button>
    </div>
      <Snackbar onClose={handleClose} open={openSuccess} autoHideDuration={6000}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Simulation saved successfully!
        </Alert>
      </Snackbar>
      <Dialog
        open={isSaveInProgress || isDownloading}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="pt-0">
          {"Your request is being processed"}
        </DialogTitle>
      </Dialog>
    </>
  )
};

const useStyles = makeStyles(() => ({
  actionsIconTextColor: {
    color: '#0000b3',
    textTransform: 'none'
  },
  disabledActionsIconTextColor: {
    color: 'gray',
    textTransform: 'none'
  },
  calculateBtn: {
    float: 'right',
    color: '#fff',
    background: '#1976d2',
    margin: '10px',
    '&:hover': {
      backgroundColor: "#1976d2 !important",
    },
  },
  disabledBtn: {
    float: 'right',
    color: 'rgb(217, 217, 217)',
    background: 'rgb(240, 240, 240)',
    margin: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: "rgb(240, 240, 240) !important",
    },
  }
}))

