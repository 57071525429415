import React, { useCallback, useEffect, useState } from 'react';
import { PromoSection, CalculatedResult } from './sub_components/promo_section';
import { Plus } from './icons/plus';
import { SimulatedResult } from './simulated_result';
import CustomSearch from '../CustomSearch';
import useStore from '../store';
import { useCustomerHierarchy, useProductHierarchy } from '../services/queries';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import { ApiRequestService } from "../../src/services/api-service";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Modal from '@mui/material/Modal';
import { Table } from './sub_components/table';
import moment from 'moment';
import Toaster from '../commonMuiComponents/Toaster'
import { useSimulationsForTallyURL } from '../services/mutations';

const useStyles = makeStyles((theme) => ({
	clearAll: {
		color: "#0000b3",
		opacity: 0.8,
		fontWeight: 400,
		cursor: "pointer",
		marginLeft: "10px",
	},
	actionsTxt: {
		fontWeight: 700,
	},
	actionsBtn: {
		cursor: 'pointer'
	},
	actionsIconText: {
		fontWeight: 700,
		color: '#2663ff',
		fontSize: '14px',
		lineHeight: '16px',
	},
	flexEnd: {
		alignItems: 'end'
	},
	btnClose: {
		position: "absolute",
		top: "0",
		right: "5px",
	},
	btnContainer: {
		marginTop: "30px",
		float: "right",
		marginRight: "50px",
	},
	btnReset: {
		background: "#cb0c0c",
		color: "#fff",
		textTransform: "none",
		margin: "0 20px",
		padding: "6px 20px",
		borderRadius: "10px",
	},
	btnSucess: {
		background: "#163c62",
		color: "#fff",
		textTransform: "none",
		margin: "0 10px",
		padding: "6px 35px",
		borderRadius: "10px",
	},
	boxContainer: {
		overflowY: 'auto',
		height: '500px'
	},
	inputBox: {
		border: '1px solid',
		borderRadius: '5px',
		padding: '2px'
	},
	overAllContainer: {
		display: 'flex',
		fontSize: '1.2rem',
		fontWeight: 600,
		justifyContent: 'center',
	},
	subOverAll: {
		marginRight: 20
	}
}))



const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	height: '85%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4
};
const promoHandler = {
	height: '700px',
}

export const PreROIDashboard = () => {
	const classes = useStyles();
	const [canShowSimulatedResult, setcanShowSimulatedResult] = useState(false);
	const [selectedPromoIndex, setselectedPromoIndex] = useState(0);
	const [showAlert, setShowAlert] = useState({ message: '', type: '' })
	const [openModal, setOpenModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [simulationState, setSimulationState] = useState({
		fromDate: '',
		thruDate: '',
		promoName: ''
	})
	const [simulationData, setSimulationData] = useState([])


	let numberOfPromos: any = useStore((state) => state.numberOfPromos)
	const setnumberOfPromos = useStore(
		(state) => state.setnumberOfPromos
	);

	useEffect(() => {
		setnumberOfPromos(1)
	}, [])


	const [simulatedGridData, setSimulatedGridData] = useState<any>([]);
	const [isLoadSimulationClicked, setIsLoadSimulationClicked] = useState(false);

	let selectedCountry = useStore((state) => state.selectedCountry)
	const setSelectedCustomersHierarchy = useStore((state) => state.setSelectedCustomersHierarchy);
	const setSelectedProductrsHierarchy = useStore((state) => state.setSelectedProductsHierarchy);
	const { isLoading: isSellInCustomerData, data: sellInCustomerData } = useCustomerHierarchy(`${selectedCountry}&source=SELL IN&feature=ROI Simulator`);
	const { isLoading: isCustomerData, data: customerData } = useCustomerHierarchy(`${selectedCountry}&feature=ROI Simulator`)
	const { isLoading: isProductData, data: productData } = useProductHierarchy(selectedCountry, "ROI Simulator")
	let simulationUrl = `country=${selectedCountry}&fromDate=${simulationState.fromDate}&toDate=${simulationState.thruDate}&name=${simulationState.promoName}`;


	const fetchSimulationData = async () => {
		setIsLoading(true);
		const apiRequestService = ApiRequestService.createInstance();
		const AxiosResponse = await apiRequestService.getSimulationsData(simulationUrl);
		let tableData = AxiosResponse.data;
		setIsLoading(false);
		if (tableData && tableData.length > 0) {
			tableData.forEach((item: any, index: number) => {
				item['id'] = index;
			})
			setSimulationData(tableData);
		}
	}

	useEffect(() => {
		if (openModal) {
			fetchSimulationData()
		}
		else {
			handleReset()
		}
	}, [openModal])


	const setSelectedBaseLineCustomerNew = useStore(
		(state) => state.setSelectedBaseLineCustomerNew
	);
	let setSelectedBaseLineFilter = useStore(
		(state) => state.setSelectedBaseLineFilter
	);
	const setSelectedBaseLineProductNew = useStore(
		(state) => state.setSelectedBaseLineProductNew
	);

	const [kcProductData, setKcProductData] = useState();

	const [openSave, setOpenSave] = React.useState(false);
	const setSelectedBaseLineCustomerState = useStore(
		(state) => state.setSelectedBaseLineCustomerState
	);

	const setSelectedBaseLineProductState = useStore(
		(state) => state.setSelectedBaseLineProductState
	);


	const setSelectedCustomerValues = useStore(
		(state) => state.setSelectedCustomerValues
	);

	const setSelectedProductValues = useStore(
		(state) => state.setSelectedProductValues
	);

	let promoStateData: any = useStore((state) => state.promoStateData);
	const setPromoStateData = useStore((state) => state.setPromoStateData);

	useEffect(() => {
		setSelectedPromoVal(1)
		const promoValue = promoStateData[1];
		if (promoValue && Object.keys(promoValue).length > 0) {
			setcalculatedResults({ 1: { netProfit: promoValue.netProfit, totalInvestment: promoValue.totalInvestment } });
		}
	}, [])

	const deleteFun = async (delVal: number) => {
		if (Object.keys(promoStateData).length > 0) {
			const filtered = Object.keys(promoStateData)
				.filter(x => parseInt(x) !== delVal)
				.reduce((obj: any, key: string) => {
					obj[key] = promoStateData[key]
					return obj
				}, {})
			setPromoStateData(filtered)
			const newPromoVal = (Object.keys(filtered).length > 0) ? Math.max(...Object.keys(filtered).map(x => parseInt(x))) : 1;
			setSelectedPromoVal(newPromoVal)
			setnumberOfPromos(filtered.filtered)
			const allCalculatedResults = { ...calculatedResults }
			delete allCalculatedResults[delVal];
			setcalculatedResults(allCalculatedResults);
		} else {
			setPromoStateData({})
			setcalculatedResults({})
		}
		setDeletedPromo(delVal);
		setOpen(false);
		setselectedPromos(selectedPromos.filter(x => x !== delVal))
	}

	const duplicateFun = () => {
		if (Object.keys(promoStateData).length < 12) {
			let valu = Math.max(...Object.keys(promoStateData).map(x => parseInt(x))) + 1
			const xx = { ...promoStateData, [valu]: { ...promoStateData[selectedPromoVal] } }
			setPromoStateData(xx);
			setPromoVal(valu);
			startDuplicate();
			setcalculatedResults({ ...calculatedResults, [valu]: { netProfit: promoStateData[selectedPromoVal].netProfit, totalInvestment: promoStateData[selectedPromoVal].totalInvestment } });
		} else {
			setShowAlert({
				type: 'error',
				message: 'It exceeds promo length to 12'
			})
		}
	}

	const startDuplicate = () => {
		setItemDuplicate(true);
	}

	const endDuplicate = () => {
		if (itemDuplicate) {
			setItemDuplicate(false);
			// setSelectedPromoVal(promoVal - 1);
		}
	}

	useEffect(() => {
		if (!isProductData && selectedCountry !== undefined && productData) {
			setKcProductData(productData['countries'][selectedCountry]);
		}
	}, [isProductData, productData])

	const [clearProduct, setClearProduct] = useState(false);
	const [clearCustomer, setClearCustomer] = useState(false);
	const [deletedPromo, setDeletedPromo] = useState(0);
	const [calcutateForm, setCalcutateForm] = useState(false);

	const [overAllROI, setOverAllROI] = useState(0);
	const [OverAllPLPer, setOverAllPLPer] = useState(0);

	let promoVal = useStore((state) => state.promoVal)
	const setPromoVal = useStore(
		(state) => state.setPromoVal
	);


	let selectedPromoVal = useStore((state) => state.promoValNew)
	const setSelectedPromoVal = useStore(
		(state) => state.setPromoValNew
	);

	const [itemDuplicate, setItemDuplicate] = useState(false);

	const [calculatedResults, setcalculatedResults] = useState<Record<number, CalculatedResult>>({ 1: { netProfit: 0, totalInvestment: 0 } })
	const [selectedPromos, setselectedPromos] = useState<number[]>([0])


	function pad(n: number) {
		return (n < 10) ? ("0" + n) : n;
	}

	useEffect(() => {
		if (Object.keys(promoStateData).length == 0) {
			setSelectedBaseLineCustomerState({})
			setSelectedBaseLineProductState({})
			setPromoStateData({ ...promoStateData, [promoVal]: {} });
		}
	}, []);

	useEffect(() => {
		if (selectedCountry && Object.keys(promoStateData).length != 0) {
			if (promoStateData[1]?.selectedContry != selectedCountry) {
				setSelectedBaseLineCustomerState({})
				setSelectedBaseLineProductState({})
				//setPromoVal(promoVal+1);
				//setPromoStateData({ [promoVal+1]: {} });

				setPromoVal(0)
				setPromoStateData({ 1: {} });
				return
			}
			setPromoStateData({})
		}

		//	}
	}, [selectedCountry]);

	useEffect(() => {
		if (clearCustomer || clearProduct) {
			setOverAllROI(0);
			setOverAllPLPer(0);
			setcalculatedResults({ 1: { netProfit: 0, totalInvestment: 0 } });
		}
	}, [clearCustomer, clearProduct]);

	const addPromo = () => {
		if (Object.keys(promoStateData).length < 12) {
			let nextSelected = 1
			if (Object.keys(promoStateData).length > 0) {
				nextSelected = Math.max(...Object.keys(promoStateData).map(x => parseInt(x))) + 1
			}
			setPromoVal(nextSelected);
			setSelectedPromoVal(nextSelected);
			setPromoStateData({ ...promoStateData, [nextSelected]: {} });
			setcalculatedResults({ ...calculatedResults, [nextSelected]: { netProfit: 0, totalInvestment: 0 } });
			setnumberOfPromos(numberOfPromos + 1)
		} else {
			setShowAlert({
				type: 'error',
				message: 'It exceeds promo length to 12'
			})
		}
	}

	const calDuration = (startname: any, endDate: any) => {
		let stDate = moment(startname, "YYYY/MM/D");
		let edDate = moment(endDate, "YYYY/MM/D");
		let durationVal = edDate.diff(stDate, 'days');

		let val = 0;
		if (durationVal >= 0) {
			val = durationVal + 1;
		} else if (durationVal < 0) {
			val = 0;
		}

		return val;
	}


	const addPromoFromSimulationClick = (modalData: any) => {
		let isClickedSimulation = true
		let nextSelected = Math.max(...Object.keys(promoStateData).map(x => parseInt(x))) + 1
		setPromoVal(nextSelected);
		setSelectedPromoVal(nextSelected);

		let datediffNum = calDuration(modalData.fromDate, modalData.toDate,)

		let mainObj = {};

		let mainData = modalData.tacticType
		let splicStatic = mainData.split(',')

		if (splicStatic.length > 0 && mainData != '' && mainData != 'string,string' && mainData != 'string') {
			splicStatic.map((item: any) => {
				if (item) {
					let newobj = item.split('=')

					let content = newobj[1].split('-')

					mainObj = { ...mainObj, [content[0].replaceAll('%25', '%')]: content[1] }
				}

			})

		}

		const productCustomerData = modalData?.productHierarchy;
		let customerValues: Array<string> = [];
		let productValues: Array<string> = [];

		if (productCustomerData) {
			let splitCPData = productCustomerData.split(':');
			splitCPData.map((item: any) => {
				if (item.includes('PlanLevels') || item.includes('Channel') || item.includes('SalesOffice') || item.includes('Customers')) {
					let cus = item.split('=')

					customerValues.push(cus[1])

				} else if (item.includes('Categories') || item.includes('SubCategories') || item.includes('Brands') || item.includes('SubBrands') || item.includes('Eans')) {
					let pro = item.split('=');
					productValues.push(pro[1])
				}
			})
		}

		modalData.name = `promo ${pad(nextSelected)}`
		setPromoStateData({ ...promoStateData, [nextSelected]: { ...modalData, selectedCustomerValues: customerValues, selectedProductValues: productValues, duration: datediffNum, savePromoValues: true, isCalCulate: true, selectedPromoVal: mainObj } });
		setIsLoadSimulationClicked(isClickedSimulation);
		setIsLoadSimulationClicked(!isClickedSimulation);
		setcalculatedResults({ ...calculatedResults, [nextSelected]: { netProfit: 0, totalInvestment: 0 } });
	}

	const [open, setOpen] = React.useState(false);

	const clearProductData = () => {
		setClearProduct(true);
		setSelectedBaseLineProductNew("");
		setPromoStateData({ 1: {} })
		setSelectedProductValues([])
		setSelectedPromoVal(1)
		setSelectedProductrsHierarchy({});
	}
	const clearCustomerData = () => {
		setClearCustomer(true);
		setSelectedBaseLineCustomerNew("");
		setPromoStateData({ 1: {} });
		setSelectedCustomerValues([])
		setSelectedPromoVal(1)
		setSelectedCustomersHierarchy({});
	}
	const resetProduct = () => {
		setClearProduct(false);
		setDeletedPromo(0);
	}
	const resetCustomer = () => {
		setClearCustomer(false);
		setDeletedPromo(0);
	}

	let setresetFormFieldsNew = useStore(
		(state) => state.setresetFormFieldsNew
	);
	const resetForm = () => {
		setresetFormFieldsNew(true);
	}
	let calcutateFormNew = useStore((state) => state.calcutateFormNew);
	let setCalcutateFormNew = useStore(
		(state) => state.setCalcutateFormNew
	);


	const calculateData = () => {
		setCalcutateForm(true);
		setCalcutateFormNew(true);
		setOpenSave(true);
	}


	useEffect(() => {
		if (!calcutateFormNew) {
			setOpenSave(false);
		}
	}, [calcutateFormNew]);


	const handleClose = () => {
		setOpen(false);
	};

	const [savePromoValues, setSavePromoValues] = useState(false)
	const savePromo = () => {
		setSavePromoValues(true)
	}

	const { mutate: simulationsTallyValues, data: simulationsTallyValuesResponse, isSuccess: issimulationsTallyValues,
		isError: sucesssimulationsTallyValues } =
		useSimulationsForTallyURL();

	useEffect(() => {
		if (issimulationsTallyValues) {
			setOverAllROI(simulationsTallyValuesResponse?.data?.currentTotalROI);
			setOverAllPLPer(simulationsTallyValuesResponse?.data?.currentTotalProfit)
		}

	}, [issimulationsTallyValues, sucesssimulationsTallyValues]);

	useEffect(() => {
		if (selectedPromos.length === 0 || selectedPromos == null) {
			setOverAllROI(0)
			setOverAllPLPer(0)
			return
		}
		const profitLoss = Object.values(calculatedResults).map(x => x.netProfit)
		const totalInvestment = Object.values(calculatedResults).map(x => x.totalInvestment)
		let request = { 'profitLoss': profitLoss, 'totalInvestment': totalInvestment }

		simulationsTallyValues(request)

	}, [calculatedResults, selectedPromos]);

	const updateCalculatedValues = (idx: number, value: CalculatedResult) => {
		setcalculatedResults({ ...calculatedResults, [idx]: value });
	}

	const handleSelectedPromos = (idx: number, checked: boolean) => {
		if (checked) {
			if (!selectedPromos.includes(idx)) {
				setselectedPromos([...selectedPromos, idx])
			}
		} else {
			setselectedPromos(selectedPromos.filter(x => x !== idx))
		}
	}

	const generateSec = (indexVal: number, parentID: number | null) => {

		return <PromoSection
			showResult={() => {
				setcanShowSimulatedResult(true)
				setselectedPromoIndex(indexVal)
			}}
			index={indexVal}
			key={indexVal}
			deletePromo={deleteFun}
			setSelectedPromoRadioVal={setSelectedPromoVal}
			selectedPromoValParent={parentID}
			setSimulatedGridData={setSimulatedGridData}
			savePromoValues={savePromoValues}
			setSavePromoValues={setSavePromoValues}
			isLoadSimulationClicked={isLoadSimulationClicked}
			calculateData={calculateData}
			resetForm={resetForm}
			savePromo={savePromo}
			handleModalOpen={handleModalOpen}
			duplicateFun={duplicateFun}
			updateCalculatedResult={updateCalculatedValues}
			setSelectedPromosHandler={handleSelectedPromos}
		/>

	}
	const handleModalOpen = () => {
		setOpenModal(true);
	}

	const handleModalClose = () => {
		setOpenModal(false);
	}

	const handleSimulationChange = (evt: any) => {
		setSimulationState({
			...simulationState,
			[evt.target.name]: evt.target.value
		})
	}

	const handleSubmit = () => {
		fetchSimulationData()
	}

	const handleReset = () => {
		setSimulationState({
			fromDate: '',
			thruDate: '',
			promoName: ''
		})
		setSimulationData([]);
		if (simulationState.fromDate || simulationState.thruDate || simulationState.promoName) {
			simulationUrl = `country=${selectedCountry}&fromDate=&toDate=&name=`;
			fetchSimulationData();
		}
	}

	const handleDeleteSimulation = (names: string[]) => {
		setSimulationData(simulationData.filter(x => !names.includes(x['name'])))
	}

	return (
		<div className=" mx-auto  ">
			{/* Search section */}

			<div className="flex justify-start gap-10 w-full px-3 mt-2">
				<div className="flex flex-col gap-2 w-1/2">
					<div className="flex gap-2  w-1/2">
						<span className="text-xs font-bold">Customer</span>

						<span id="clearCustomer" className={classes.clearAll} onClick={clearCustomerData}>Clear </span>
					</div>
					{
						!isCustomerData && !isSellInCustomerData && customerData
							? <CustomSearch
								indexValue={selectedPromoVal} data={customerData} sellInData={sellInCustomerData} dataFor="customer" tabName="preROI_test" clear={clearCustomer} reset={resetCustomer} deleteId={deletedPromo} duplicateFlag={itemDuplicate} endDuplication={endDuplicate}></CustomSearch>
							: <div className="flex flex-col gap-1">
								<CustomSearch
									indexValue={selectedPromoVal} dataFor="customer" tabName="preROI_test" clear={clearCustomer} reset={resetCustomer} deleteId={deletedPromo} duplicateFlag={itemDuplicate} endDuplication={endDuplicate}></CustomSearch>
								<span>Loading...</span>
							</div>
					}

				</div>
				<div className="flex flex-col gap-2  w-1/2">
					<div className="flex gap-2  w-1/2">
						<span className="text-xs font-bold">Product</span>
						<span id="clearProducts" className={classes.clearAll} onClick={clearProductData}>Clear</span>
					</div>
					{
						!isProductData && kcProductData
							? <CustomSearch
								indexValue={selectedPromoVal} data={kcProductData} dataFor="Product" tabName="preROI_test" clear={clearProduct} reset={resetProduct} deleteId={deletedPromo} duplicateFlag={itemDuplicate} endDuplication={endDuplicate}></CustomSearch>
							: <div className="flex flex-col">
								<CustomSearch
									indexValue={selectedPromoVal} dataFor="Product" tabName="preROI_test" clear={clearProduct} reset={resetProduct} deleteId={deletedPromo} duplicateFlag={itemDuplicate} endDuplication={endDuplicate}></CustomSearch>
								<span>Loading...</span>
							</div>
					}

				</div>
			</div>

			{/* <PromoActions calculateData={calculateData} setCalcutateTotalInvest={setCalcutateTotalInvest} resetForm={resetForm} savePromo={savePromo} handleModalOpen={handleModalOpen} duplicateFun={duplicateFun} /> */}


			<div className="flex gap-10 pl-5 pt-5 ">
				<div className="flex  flex-col">
					<span className="text-lg text-primary font-bold">OverAll ROI %</span>
					<span className="font-bold">{overAllROI ? overAllROI.toFixed(2) : 0}%</span>
				</div>
				<div className="flex  flex-col">
					<span className="text-lg text-primary font-bold">OverAll Profit/Loss</span>
					<span className="font-bold">{OverAllPLPer ? OverAllPLPer.toLocaleString() : 0}</span>
				</div>
			</div>

			{/* Promo section */}
			<div className={`p-5 pt-5 flex flex-col gap-10`}>
				{
					promoStateData && Object.keys(promoStateData).map((x: string) => {
						return generateSec(parseInt(x), null)
					})
				}
				<div className="flex rounded-full bg-blue-600  text-white w-72 self-center mt-10 justify-center p-2 cursor-pointer"
					onClick={() => addPromo()}>
					<Plus color='white'></Plus>
					<div className='self-center pl-3 ' > Add new promo scenario </div>
				</div>
			</div>
			{
				canShowSimulatedResult
					? <SimulatedResult goBack={() => setcanShowSimulatedResult(false)} name={selectedPromoIndex}
						simulatedGridData={simulatedGridData}
					></SimulatedResult>
					: null
			}
			{/* Load Modal */}
			<Modal
				open={openModal}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h5" component="h2" color="primary" style={{ fontWeight: "bold" }}>
						ROI Simulator Selection
					</Typography>
					<IconButton
						aria-label="close"
						onClick={handleModalClose}
						className={classes.btnClose}
					>
						X
					</IconButton>
					<div className="pb-5 bg-gray-200" color='primary' style={{ marginTop: "5px", padding: "5px", borderBottom: "1px solid" }}>
						<Typography variant="h6" component="h5" color='primary'>
							Filters
						</Typography>
					</div>
					<>
						<div id="container" className="flex justify-start gap-5 items-center py-2 pb-5">
							<span className="text-sm" style={{ marginRight: "40px", fontFamily: "sans-serif" }}>From Date</span>
							<div className="flex flex-col gap-6" style={{ marginRight: "250px" }}>
								<div className="flex bg-white rounded h-10 items-center px-2">
									<input type="date" name="fromDate" value={simulationState.fromDate} placeholder="DD/MM/AAAA" className={classes.inputBox} onChange={evt => handleSimulationChange(evt)} />
									{/* <Calendar color="#2663FF"></Calendar> */}
								</div>
							</div>

							<span className="text-sm" style={{ fontFamily: "sans-serif" }}>Thru Date</span>
							<div className="flex flex-col gap-6">
								<div className="flex bg-white rounded h-10 items-center">
									<input type="date" name="thruDate" value={simulationState.thruDate} placeholder="DD/MM/AAAA" className={classes.inputBox} onChange={evt => handleSimulationChange(evt)} />
									{/* <Calendar color="#2663FF"></Calendar> */}
								</div>
							</div>
							<div className="flex flex-col gap-6">
								<div className="flex px-2">
									<IconButton disabled={!simulationState.fromDate && !simulationState.thruDate && !simulationState.promoName}>
										<ReplayIcon onClick={handleReset} />
									</IconButton>
									<IconButton disabled={!simulationState.fromDate && !simulationState.thruDate && !simulationState.promoName}>
										<SearchIcon onClick={handleSubmit} />
									</IconButton>
								</div>
							</div>
						</div>
						<div id="container" className="flex justify-start gap-5 items-center py-2 pb-5">
							<span className="text-sm" style={{ fontFamily: "sans-serif" }}>Promotion Name</span>
							<div className="flex flex-col gap-6">
								<div className="flex bg-white rounded h-10 items-center px-2">
									<input type="text" name="promoName" value={simulationState.promoName} className={classes.inputBox} onChange={evt => handleSimulationChange(evt)} />
									{/* <Calendar color="#2663FF"></Calendar> */}
								</div>
							</div>
						</div>
					</>
					<Table rows={simulationData} isLoading={isLoading} addPromo={addPromoFromSimulationClick} deleteCallback={handleDeleteSimulation} setOpenModal={setOpenModal} />
				</Box>
			</Modal>


			<Dialog
				open={openSave}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>
					{"Calculate"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Transaction is in progress..
					</DialogContentText>
				</DialogContent>

			</Dialog>


			<Dialog
				open={savePromoValues}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>
					{"Data Save"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Data saving in progress.....
					</DialogContentText>
				</DialogContent>
			</Dialog>
			<Toaster showAlert={showAlert} />
		</div>
	);
}

