export const productDataJson = [ {
    "countries": {
        "PE": {
            "categories": {
                "Household Towels": {
                    "subCategories": {
                        "DRY HOUSEHOLD TOWELS": {
                            "brands": {
                                "TOWDRY SCOTT": {
                                    "subBrands": {
                                        "TOWDRY SCOTT DURAMAX": [
                                            "17702425679204 - KT SCOTT DURAMAX 1P RR 24X1X58 IRONMAN",
                                            "17702425679464 - KT SCOTT DURAMAX 1P RR 12X1X116 IRONMAN",
                                            "17702425802862 - KT SCOTT DURAMAX 1P RR 8X3X58 PE IRONMAN",
                                            "17702425552194 - KT SCOTT DURAMAX FD 12X7 MULTIUSOS IRMN",
                                            "17702425679211 - KT SCOTT DURAMAX 1P RR 12X2X58 IRONMAN"
                                        ],
                                        "TOWDRY SCOTT MULTIUSOS": [
                                            "17751493004821 - KT SCOTT MULTIUS 6X2 X100H CH",
                                            "17751493008607 - KT SCOTT MULTIUS 2X6 X100H HCOUNT",
                                            "17751493004838 - KT SCOTT MULTIUS 12X1X100 CHILE"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "Feminine Care": {
                    "subCategories": {
                        "FEMININE CARE TAMPON": {
                            "brands": {
                                "FEMININE CARE TAMPON KOTEX": {
                                    "subBrands": {
                                        "FEMTAM KOTEX EVOLUTION": [
                                            "27702425540617 - TAMP KOT APLIC M 12X8 SPORTS TTX",
                                            "27702425540600 - TAMP KOT APLIC SUPER 12X8 SPORTS TTX"
                                        ]
                                    }
                                }
                            }
                        },
                        "FEMININE CARE PADS": {
                            "brands": {
                                "FEMPAD KOTEX": {
                                    "subBrands": {
                                        "FEMPAD KOTEX": [
                                            "17702425806518 - FEM PAD KOT FITNESS UF 12X10 TTX",
                                            "17751493006368 - FEM PAD KOT NOR TELA 12X30 OT",
                                            "17751493002209 - FEM PAD KOT NOR TELA W/W 12X42 CORONA",
                                            "17751493793626 - FEM PAD KOT NOR MALLA 24X10 TTX",
                                            "17702425806532 - FEM PAD KOT FITNESS UF 12X30 TTX",
                                            "17702425550251 - FEM PAD KOT NOCT 12X16 FZ TTX",
                                            "17702425550237 - FEM PAD KOT NOCT 12X8 FZ TTX",
                                            "17702425801285 - FEM PAD KOT ANTIBACT UF TELA W/W 48X10",
                                            "17702425800776 - FEM PAD KOT NOR TELA W/W 48X10 CORONA",
                                            "17702425802732 - FEM PAD KOT NOCT TELA W/W 12X8 TTX",
                                            "17702425802749 - FEM PAD KOT NOCT TELA W/W 12X30 TTX"
                                        ],
                                        "FEMPAD KOTEX TEENS": [
                                            "17751493002551 - FEM PAD KOT TEENS 24X10"
                                        ]
                                    }
                                }
                            }
                        },
                        "FEMININE CARE LINERS": {
                            "brands": {
                                "FEMLIN KOTEX": {
                                    "subBrands": {
                                        "FEMLIN KOTEX": [
                                            "17702425804743 - FEM LIN KOT ULTRADEL FLEX 12X150 PCP",
                                            "27702425544936 - FEM LIN KOT NOR 24X15 PCP",
                                            "17441008171574 - FEM LIN KOT FITNESS REG 6X80 TTX V2",
                                            "17702425804811 - FEM LIN KOT ULTRADEL FLEX 12X15 PCP",
                                            "17702425807546 - FEM LIN KOT REG TELA REG 12X15 ANTIB P",
                                            "17702425807560 - FEM LIN KOT 12X150 ANTIBACTERIAL PCP"
                                        ],
                                        "FEMLIN KOTEX DAYS": [
                                            "17702425530932 - FEM LIN KOT NOR 12X180 PCP",
                                            "17702425544915 - FEM LIN KOT NOR 12X120 PCP"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "Facial Tissue": {
                    "subCategories": {
                        "FACIAL TISSUE BOXED": {
                            "brands": {
                                "FACIAL TISSUE BOX KLEENEX": {
                                    "subBrands": {
                                        "FACBOX KLEENEX ULTRASOFT": [
                                            "17702425533629 - FAC KLEENEX USOFT CUBE 3P 32X1 X55"
                                        ],
                                        "FACBOX KLEENEX BOUTIQUE": [
                                            "17702425640754 - FAC KLEENEX ORIG JUNIOR 36X1 X50 RUNWAY",
                                            "17702425524962 - FAC KLEENEX ORIG 36X1 X80 RUNWAY",
                                            "17702425642154 - FAC KLEENEX ORIG CUBE 32X1 X60 RUNWAY"
                                        ]
                                    }
                                }
                            }
                        },
                        "FACIAL TISSUE HANKS": {
                            "brands": {
                                "FACHAN  KLEENEX": {
                                    "subBrands": {
                                        "FACHAN  KLEENEX": [
                                            "17702425546063 - FAC KLEENEX ORIG POCKET 36X4 X10 SWE RW",
                                            "17702425805795 - FAC KLEENEX ORIG POCKET 3P 24X8 X10",
                                            "17702425806396 - FAC KLEENEX MINIPOCK 3P 12X4X7 PC II"
                                        ]
                                    }
                                }
                            }
                        },
                        "FAC HAND.FACE&BODY MOIST": {
                            "brands": {
                                "FAC HAND&BOD MOIST KLEENEX": {
                                    "subBrands": {
                                        "FAC HAND&BOD MOIST KLEENEX": [
                                            "17702425550466 - FAC KLX WIPES DSMQFAC RUNWAY 1x2x12 15s"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "Disposable Diapers": {
                    "subCategories": {
                        "DISPOSABLE DIAPERS": {
                            "brands": {
                                "DIAPER HUGGIES": {
                                    "subBrands": {
                                        "DIAPER HUGGIES NATURAL CARE": [
                                            "17751493006955 - DIA PANT HUG NATCARE XXL 4X36 XPAD",
                                            "17751493006979 - DIA PANT HUG NATCARE L 4X50 XPAD",
                                            "17751493006900 - DIA PANT HUG NATCARE GIRL M 4X54 CALZ",
                                            "17751493009185 - DIA HUG NATCARE XXL HIPER 2X38 X-CARE",
                                            "17702425802152 - DIA HUG NATCARE S ULTRAP 8X30 FROZEN",
                                            "17751493006894 - DIA PANT HUG NATCARE GIRL L 4X50 CALZ",
                                            "17702425802169 - DIA HUG NATCARE NB SUPMEGA 6X60 FROZEN",
                                            "17751493009161 - DIA HUG NATCARE L 2X52 FROZEN PE",
                                            "17702425802176 - DIA HUG NATCARE S JUMBO 6X50 FROZEN",
                                            "17751493009178 - DIA HUG NATCARE XL 2X42 FROZEN PE",
                                            "17751493007006 - DIA PANT HUG NATCARE XL 4X40 XPAD",
                                            "17702425802275 - DIA HUG NATCARE M SUPMEGA 4X60 FROZEN",
                                            "17751493006986 - DIA PANT HUG NATCARE M 4X54 XPAD",
                                            "17702425802145 - DIA HUG NATCARE NB MAXI 8X20 FROZEN"
                                        ],
                                        "DIAPER HUGGIES ACTIVE SEC": [
                                            "17751493008430 - DIA HUG ACTSEC M 2X56X1 X-PAD MM",
                                            "17751493008393 - DIA HUG ACTSEC L HIPER 2X58 X-PAD FR",
                                            "17751493001097 - DIA HUG ACTSEC S 4X60 MM",
                                            "17751493008386 - DIA HUG ACTSEC M HIPER 2X64 X-PAD",
                                            "17751493008416 - DIA HUG ACTSEC XXL HIPER 2X44 X-PAD FR",
                                            "17751493006344 - DIA HUG ACTSEC L SINGLEPK 2X58 X1 HULK",
                                            "17751493008478 - DIA HUG ACTSEC XL 2X44X1 X-PAD FR",
                                            "17751493006313 - DIA HUG ACTSEC M SINGLEPK 2X64 X1 HULK",
                                            "17751493008454 - DIA HUG ACTSEC L 2X50X1 X-PAD FR",
                                            "17751493008409 - DIA HUG ACTSEC XL HIPER 2X48 X-PAD FR",
                                            "17751493008492 - DIA HUG ACTSEC XXL 2X40X1 X-PAD FR"
                                        ],
                                        "DIAPER HUGGIES  NEWBORN": [
                                            "17702425802152 - DIA HUG NATCARE S ULTRAP 8X30 FROZEN",
                                            "17794626007146 - DIA HUG NATCARE PREMAT MEGA 8X30",
                                            "17702425802145 - DIA HUG NATCARE NB MAXI 8X20 FROZEN"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "Adult Care": {
                    "subCategories": {
                        "ADULT CARE SHIELDS": {
                            "brands": {
                                "ADULT CARE SHIELDS PLENITUD": {
                                    "subBrands": {
                                        "ADULT CARE SHIELDS PLENITUD": [
                                            "17751493264737 - ADU SHIE PLE 12X20 + 2 MG PRACTI FEMME",
                                            "17751493264720 - ADU SHIE PLE 24X10 + 2 MG PRACTI FEMME"
                                        ]
                                    }
                                }
                            }
                        },
                        "ADULT CARE BRIEF": {
                            "brands": {
                                "ADULT CARE BRIEF PLENITUD": {
                                    "subBrands": {
                                        "ADULT CARE BRIEF PLENITUD PROTECT": [
                                            "17751493006436 - ADU BRF PLE PROTECT L/XL 3X20 LIC",
                                            "17751493004005 - ADU BRF PLE PROTECT M 3X20 LIC"
                                        ],
                                        "ADUBRF PLENITUD CLASSIC": [
                                            "17793620922646 - ADU BRF PLE CLASSIC M 10X8 SPARK",
                                            "17793620922653 - ADU BRF PLE CLASSIC L 10X8 SPARK",
                                            "17751493003947 - ADU BRF PLE CLASSIC L 3X20 UPGRADE",
                                            "17751493003954 - ADU BRF PLE CLASSIC M 3X20 UPGRADE"
                                        ]
                                    }
                                }
                            }
                        },
                        "ADULT CARE PANTS": {
                            "brands": {
                                "ADUPAN PLENITUD": {
                                    "subBrands": {
                                        "ADUPAN PLENITUD SABBEL PREMIUM": [
                                            "17751493004449 - ROP PLN MJ ACTFT C OLR  P/M 12X8 REAL FT",
                                            "17751493004463 - ROP PLN HOM ACTFT C OLR P/M 12X8 REAL FT"
                                        ],
                                        "ADUPAN PLENITUD LYCRA HEAVY INCO": [
                                            "17896007547125 - SM ADU UWEAR PLE ACT PLUS S/M 4X8 DEL",
                                            "17896007547170 - SM ADU UWEAR PLE ACT PLUS L/XL 4X8 DEL"
                                        ]
                                    }
                                }
                            }
                        },
                        "ADULT CARE PADS": {
                            "brands": {
                                "ADUPAD DEPEND": {
                                    "subBrands": {
                                        "ADUPAD DEPEND": [
                                            "17702425549873 - BED PROT PLE 6X10 EXTREME"
                                        ]
                                    }
                                },
                                "ADUPAD PLENITUD": {
                                    "subBrands": {
                                        "ADUPAD PLEN FEMME": [
                                            "17794626007429 - ADU PAD PLE FEMME N/W 6X14 UF TOMLIN NOR",
                                            "17794626007382 - ADU PAD PLE FEMME NOCT 6X8 ANTIBAC",
                                            "17794626003018 - ADU PAD PLE FEMME ULTRA N/W 20X8 ANTIBAC"
                                        ]
                                    }
                                }
                            }
                        },
                        "ADULT CARE LINERS": {
                            "brands": {
                                "ADULIN POISE": {
                                    "subBrands": {
                                        "ADULIN POISE": [
                                            "17794626007412 - ADU LIN PLE FEMME TELA 8X24 ANTIBAC"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "Baby & Kids Wipes": {
                    "subCategories": {
                        "BABY WIPES": {
                            "brands": {
                                "BABY WIPES HUGGIES": {
                                    "subBrands": {
                                        "BABWIP HUGGIES ACTIVE FRESH": [
                                            "17896018700632 - BW HUG LIMP EFECT FTOP 24X48 BNOVA"
                                        ],
                                        "BABY WIPES HUGGIES PIEL SENSIBLE": [
                                            "17702425804231 - BW HUG P&N REFLL 6X184 BNOVA"
                                        ],
                                        "BABY WIPES HUGGIES NATURAL CARE": [
                                            "18801166650479 - BW HUG FF 10X24 HANDS & FACE"
                                        ],
                                        "BABY WIPES HUGGIES LIMPIEZA Y FRESCURA": [
                                            "17702425800707 - BW HUG CARE 4IN1 FTOP 24X48 BNOVA",
                                            "17702425803852 - BW HUG ONE&DONE REFLL 6X184 US",
                                            "17702425800714 - BW HUG CARE 4IN1 FTOP 12X80 BNOVA"
                                        ],
                                        "BABWIP HUGGIES NEW BORN": [
                                            "17896018700649 - BW HUG P&N FTOP 24X48 BNOVA",
                                            "17702425540894 - BW HUG P&N FTOP 12X80 BNOVA"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "General Wipes": {
                    "subCategories": {
                        "FAC HAND.FACE&BODY MOIST": {
                            "brands": {
                                "FAC HAND&BOD MOIST KLEENEX": {
                                    "subBrands": {
                                        "FAC HAND&BOD MOIST KLEENEX": [
                                            "17798038152098 - BW FAC KLEENEX AQUA 84X15 RUNWAY",
                                            "17798038152104 - BW FAC KLEENEX SENSAT CAMO 84X15 RUNWAY"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "Bathroom Tissue": {
                    "subCategories": {
                        "BATHROOM TISSUE DRY": {
                            "brands": {
                                "BATHROOM TISSUE DRY SUAVE": {
                                    "subBrands": {
                                        "BATHROOM TISSUE DRY SVE CUIDADO COMPLETO": [
                                            "17751493009109 - BT SUAVE CUIDADO COMPLETO 2P 1X40X192 TY",
                                            "17751493001561 - BT SUAVE CUIDADO COMPLETO 2P 2X24X192 TY"
                                        ],
                                        "BTHDRY RINDEMAX": [
                                            "7751493007344 - BT SUAVE RINDEM 2P 1X40 S. CUT 2.0",
                                            "17751493000649 - BT SUAVE CUIDADO COMPLETO 2P 12X4X192 TY",
                                            "17751493006375 - BT SUAVE RINDEM 2P 12X4 S. CUT 2.0",
                                            "17751493000373 - BT SUAVE RINDEM 2P 10X2X156 PROMOCIONAL2",
                                            "17751493000151 - BT SUAVE CUIDADO COMPLETO 2P 10X2X192 TY",
                                            "17751493003732 - BT SUAVE RINDEM 2P 2X24 S. CUT 2.0"
                                        ],
                                        "BTHDRY SUAVE GENTLE CARE": [
                                            "17751493008638 - BT SUAVE ESENCIAS 1X32 ELEGANCE",
                                            "17751493006665 - BT SUAVE ESENCIAS 2X24 ELEGANCE"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "Child Care": {
                    "subCategories": {
                        "YOUTH PANTS": {
                            "brands": {
                                "YOUTH PANTS GOODNITES": {
                                    "subBrands": {
                                        "YOUTH PANTS GOODNITES": [
                                            "10036000456100 - YOUTHPT GOODNITES LGE 4X11 UNISEX",
                                            "10036000456094 - YOUTHPT GOODNITES MED 4X14 UNISEX"
                                        ]
                                    }
                                }
                            }
                        },
                        "SWIM PANTS": {
                            "brands": {
                                "SWIPAN HUGGIES": {
                                    "subBrands": {
                                        "SWIPAN HUGGIES LITLLE SWIMMER": [
                                            "17751493008324 - SWIMPANTS HUG LIT SWIM M 8X11 DORY",
                                            "17751493008331 - SWIMPANTS HUG LIT SWIM L 8X10 DORY"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                "Table Napkins": {
                    "subCategories": {
                        "TABLE NAPKINS FAMILY": {
                            "brands": {
                                "NAPKINS SCOTT": {
                                    "subBrands": {
                                        "NAPK SCOTT PRACTICA": [
                                            "17751493000502 - NAPK SCOTT PRACT 12X200 CORTADA"
                                        ],
                                        "NAPK SCOTT ESTILOS": [
                                            "17751493877111 - NAPK SCOTT 32X50 ELEGANCE"
                                        ],
                                        "NAPK SCOTT  ABSORBENTE": [
                                            "17751493877074 - NAPK SCOTT PRACT 6X400 CORTADA"
                                        ],
                                        "NAPKINS SCOTT": [
                                            "17751493008751 - NAPK SCOTT 3X220 DOB 4 ECONOPACK",
                                            "17751493877050 - NAPK SCOTT 6X100 DOB 4 SUPER ABSORB BO",
                                            "17751493008690 - NAPK SCOTT 12X100 DOB 2 RENDIDORA CO",
                                            "17751493008744 - NAPK SCOTT 6X80 DOB 4 DECORADA"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

]