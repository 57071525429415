import axios, { AxiosRequestConfig } from 'axios';
import { IAddPost, IROIPost, ISimulationPost, BaseLineValuePost, SimulatedPAndLPost, SimulatedROIAndNPLPost,PromoCalculationPost } from '../models/common/post';

import { apiClient } from './clients/api-client';
/*
const header = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'max-age=0, no-cache, private',
  }
}
*/

export type FeatureType = "Baseline Forecasting" | "ROI Simulator" | "Promo Performance" | null

export class ApiRequestService {
  config?: AxiosRequestConfig = {};

  private cancellationToken = axios.CancelToken.source();

  static createInstance(): ApiRequestService {
    const activeInstance = new ApiRequestService();
    activeInstance.cancellationToken = axios.CancelToken.source();
    if (activeInstance.config) {
      activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    }
    return activeInstance;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return ApiRequestService.createInstance();
  }


  countriesList = () => apiClient.get(`/getCountriesList`);
  customerHierarchy = (data: string) => apiClient.get(`/customerHierarchy?country=${data}`);
  productHierarchy = (data: string, feature: FeatureType) => apiClient.get(`/productHierarchy?country=${data}${feature ? '&feature=' + encodeURIComponent(feature) : ''}`);
  baseLineData = (data: any) => apiClient.post(`/baseLineData`, data);
  getEventIds = (data: any) => apiClient.post(`/getEventIds`, data);
  postRoi = (data: any) => apiClient.post(`/postRoi`, data);
  postRoiQuadrant = (data: string) => apiClient.post(`/postRoiQuadrant`, data);
  roiQuadrant = (data: any) => apiClient.post(`/getTpQuadrant`, data);
  roiFileDownload = (data: any) => apiClient.post(`/downloadRoiFile`, data, { responseType: 'blob' });
  downloadPostRoi = (data: any) => apiClient.post(`/downloadPostRoi2`, data, { responseType: 'blob' });

  // CORRELATION API
  kcproductHierarchy = (data: string, hierarchyLevel?: string) => apiClient.get(`kcproductHierarchy?country=${data}${hierarchyLevel ? `&hierarchyLevel=${hierarchyLevel.toUpperCase()}` : ''}`);
  targetProductHierarchy = (data: any) => apiClient.post(`targetProductHierarchy`, data);

  correlationAnalysis = (data: any) => apiClient.post(`correlationAnalysis`, data);
  promotionPerformances = (data: any) => apiClient.post(`promotionPerformances`, data);
  productSummary = (data: any) => apiClient.post(`productSummary`, data);
  customerSummary = (data: any) => apiClient.post(`customerSummary`, data);

  ownProductHierarchy = (data: string) => apiClient.get(`ownProductHierarchy?country=${data}`);
  ownAnalysis = (data: object) => apiClient.post(`ownElasticity`, data);
  ownScopeSelector = (data: object) => apiClient.post(`ownElasticityPerScope`, data);
  ownAvailableSource = (data: string) => apiClient.get(`availableSources?${data}`);
  getSimulationsData = (data: string) => apiClient.get(`simulations?${data}`);

  crossElasticityAnalysis = (data: object) => apiClient.post(`crossElasticityAnalysis`, data);
  crossScopeSelector = (data: object) => apiClient.post(`crossElasticityAnalysisPerScope`, data);

  biToken = (reportId: string) => apiClient.get(`/powerBiToken?reportId=${reportId}`);

  addPost = (data: IAddPost) => apiClient.post('/posts', data);

  getImpactorNamesList = () => apiClient.get(`/getImpactorNames`);
  getDeleteImpactorHistory = (data: string) => apiClient.get(`/getDeletedImpactorHistory?country=${data}`);
  getImpactorHistory = (data: string) => apiClient.get(`/getImpactorHistory?country=${data}`);
  createImpactorHistory = (data: object) => apiClient.post(`/admin/createImpactor`, data);

  getImpactorInfo = (data: string) => apiClient.get(`/getImpactorInfo?impactorName=${data}`);
  getImpactorDelete = (data: string) => apiClient.delete(`/admin/deleteImpactor?${data}`);

  // validateAccessToken = (token:string) => apiClient.post('/validateAccessToken',{accessToken: token});
  validateAccessToken = (token: string) => apiClient.post(`/validateAccessToken?accessToken=${token}`);

  validateIdToken = (token: string) => apiClient.post('/validateIdToken', { idToken: token });

  autenticateWithAPI = (data: object) => apiClient.post('/generateApiToken', data);

  testAPI = () => apiClient.get('/api-docs/testrun');

  ///PrePOI
  getBaseLineValue = (data: BaseLineValuePost) => apiClient.post(`/getBaseLineValue`, data);
  getPromotionSelectors = (data: string) => apiClient.get(`/promotionSelectors?country=${data}`);
  getLinkedPromotion = (data: string) => apiClient.post(`/linkedPromotions`,data);
  getCustomersSelectors = (data: string) => apiClient.get(`/customerSelectors?country=${data}`);
  getPreRoiCustomersSelectors = (data: string) => apiClient.get(`/preRoi2Customers?country=${data}`);
  getProductsSelectors = (data: string) => apiClient.get(`/productSelectors?country=${data}`);
  getPreRoiProducts = (data: string) => apiClient.get(`/preRoi2Products?country=${data}`);
  getPrePOIPromoTypes = (data: string) => apiClient.get(`/preRoiPromoTypes?country=${data}`);
  getSimulatedValues = (data: SimulatedROIAndNPLPost) => apiClient.post(`/getSimulatedValues`, data);
  hasSellInSelloutData = (data: string) => apiClient.get(`/hasSellInSelloutData?country=${data}`);
  getSimulatedPAndL = (data: SimulatedPAndLPost) => apiClient.post(`/getSimulatedPAndL`, data);
  simulatedSaveURL = (data: IROIPost) => apiClient.post(`/savePromoSim?country=${data.country}`, data);
  getPromoInvMagic = (data: string) => apiClient.get(`/getPromoInvMagicWand?country=${data}`);
  simulationsForTallyURL = (data: ISimulationPost) => apiClient.post(`/simulationsForTally`, data);

  getCalculatedIncVolume = (data: string) => apiClient.get(`/getCalculatedIncrementalVolume?country=${data}`);

  //Baseline daily datacheck
  dailyAvailable = (data: string) => apiClient.get(`/dailyAvailable?country=${data}`);

  //Price elasticity scope datacheck
  marketScopeToggles = (data: string) => apiClient.get(`/marketScopeToggles?${data}`);

  elasticityFileDownload = (data: any) => apiClient.post('/downloadElasticity', data, { responseType: 'blob' });

  getLastModelRunDate = (country: string) => apiClient.get(`/lastModelRunDate?country=${country}`);

  deletePromoSimulation = (country: string, names: string[]) => apiClient.post('/deleteSimulations', { country: country, names })

  testDataAPI = () => apiClient.get('/api-docs/data-test');

  //To fetch Price elasticity sub category values
  getSubCategories = (data: string) => apiClient.get(`/getSubCategories?${data}`);

  //To fetch Price elasticity Source and Scope values
  getCountryMasterData = (data: string) => apiClient.get(`/countryMaster?${data}`);

  //To fetch Regression Values
  getRegressionValues = (data: object) => apiClient.post(`/dataPointsForRegression`, data);

  //To fetch week Values
  getLineFittingWeeks = (data: any) => apiClient.post(`/dataPointsForLineFitting`, data);
  getWeekSelectors = (country: any) => apiClient.get(`/weekSelectors?country=${country}`);
  getInputMethods = (country: any) => apiClient.get(`/inputMethods?country=${country}`);

  getAvailableScopes = (data: any) => apiClient.get(`/scopes?${data}`)

  getProductForElasticity = (data: any) => apiClient.post('/productForElasticity', data)
  getpromoforCalculations = (data:PromoCalculationPost) => apiClient.post(`/promoForCalculations`,data);
  checkPromoName = (data:any) => apiClient.post(`/promoNameCheck`,data);
  simulationParameters = (data:any) => apiClient.post(`/simulation-parameters`,data);
  generatePnl = (data:any) => apiClient.post(`/simulation-inputs`,data);
  savePnL = (data:any) => apiClient.post(`/simulations`,data);
  //To fetch Simulated promotions for KR
  getSimulatedPromotions = (data: any) => apiClient.post('/preRoi2Simulations', data);
   //To fetch Simulation data for KR
   getSimulatedPromoDetails = (data: string) => apiClient.get(`/simulation?country=${data}`);
   //To download Promottion Simulation for KR
   donloadPromoDetails = (data: any) => apiClient.post(`/pre-roi-2-download`,data , { responseType: 'blob' });
   //To Delete promotion for KR
   deletePromotion = (data: any) => apiClient.post('/delete', data);
}