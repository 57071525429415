import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ItemSearch from './ItemSearch';
import useStore from '../../store';
import {useGetPromotionSelectors} from "../../services/queries";


const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '600px',
    width: '450px',
    zIndex: 5,
    backgroundColor: 'white',
    overflowY: 'auto',
    direction: 'rtl',
    padding: '1rem',
    boxShadow: '5px 5px 8px #d6d9dc',
    marginTop: '5px',
    borderRadius: '5px',
  },
  paperDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  container: {
    backgroundColor: '#F6F8FC',
    height: '100vh',
  },
  searchBar: {
    display: 'inline-block',
    paddingRight: '2.5rem',
  },
  searchContainer: {
    background: '#F6F8FC',
    width: '100%',
    boxShadow: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    color: 'black',
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  autoCompleteSearch: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    background: '#FFFFFF',
  },
  gridContainer: {
    background: '#F6F8FC',
  },
  showCheckBox: {},
  appTab: {
    fontSize: '20px',
  },
  hide: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '650',
    opacity: '0.70',
    marginBottom: '1px',
  },
  open: {
    fontSize: '14px',
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '1px',
    marginLeft: '5px',
  },
  filterItem: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: theme.palette.primary.main,
    opacity: '0.75',
    fontWeight: '480',
    padding: '3px',
  }
}));

let display = {
  promoType: { name: 'Promo Type', isOpen: false },
  discountType: { name: 'Discount Type', isOpen: false },
  promoGroup: { name: 'Promo Group', isOpen: false },
  promoIds: { name: 'Event ID', isOpen: false }
};

const NestedBaselinePromoType = (props) => {
  const classes = useStyles();
  //It controls expand of level
  const initial = {
    promoType: false,
    discountType: false,
    promoGroup: false,
    promoIds: false,
  }; 
  const [allPromotionData,setAllPromotionData]= useState();
  const [iconState, setIconState] = useState(initial);
  const [allPromoData, setAllPromoData] = useState({});
  const [payload, setPayload] = useState({
    "brands": [],
    "categories": [],
    "channels": [],
    "country": "KR",
    "customers": [],
    "eans": [],
    "packs": [],
    "planningCustomers": [],
    "salesOffice": [],
    "sectors": [],
    "subBrands": [],
    "subCategories": [],
    "yearWeeks": [],
    "promoIds": [],
    "promoTypes": [],
});
  let promoTypeCheckBox = useStore(
    (state) => state.promoTypeCheckBox
  );
  const { isLoading: isPromotionData, data: promotionData } = 
  useGetPromotionSelectors(payload);
  const setPromoTypeCheckBox = useStore(
    (state) => state.setPromoTypeCheckBox
  );
  let selectedCountry = useStore((state) => state.selectedCountry);
  const selecteYeardWeeks = useStore((state) => state.selectedYearWeeks);
  
let customersStateData = useStore(
  (state) => state.customersStateData
);
let productsStateData = useStore(
  (state) => state.productsStateData
);

  const setSelectedPromotionalItem = useStore(
    (state) => state.setSelectedPromotionalItem
  );

  let promotionalsStateData = useStore(
    (state) => state.promotionalsStateData
  );

  const setPromotionalsStateData = useStore(
    (state) => state.setPromotionalsStateData
  );

  const setSelectedBaseLineFilter = useStore(
    (state) => state.setSelectedBaseLineFilter
  );

  let selectedBaseLineProductNew = useStore(
    (state) => state.selectedBaseLineProductNew
  );let selectedPromotionalItem = useStore(
    (state) => state.selectedPromotionalItem
  );


  useEffect(() => {
    let selectedValue = {
        country: selectedCountry,
        yearWeeks: selecteYeardWeeks,  //202244         
    }
    let customer = getCustomerData();
    let product = getProductData();
    
    selectedValue = { ...payload, ...selectedValue, ...customer.data, ...product.data};
    setPayload(selectedValue)

}, [selectedCountry, selecteYeardWeeks, customersStateData, productsStateData, selectedPromotionalItem]);

useEffect(() => {  
  if (!isPromotionData && selectedCountry !== undefined && promotionData) {
  setAllPromotionData(promotionData);
 calculateInitial(promotionData);  
}
}, [isPromotionData, promotionData]);


function putAll(x) {
  return x.name;
}

function isSelected(x) {
  return x.isChecked;
}
function getCustomerData() {
  let selectedArr = [];
  let customerPayLoad = {};
  let formatedPayLoad = {};
  const arr = [
      "customers"
  ];
  let values = { ...customersStateData };
  //iterating backword
  for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      customerPayLoad[level] = [];
      if (values && values[level]) {
          let selectedNodes = values[level].filter(isSelected).map(putAll);
          if (selectedNodes.length !== 0) {
              selectedNodes.forEach((val) => {
                  customerPayLoad[level].push(val);
                  selectedArr.push(val);
              });
          }
      }
  }
  formatedPayLoad['customers'] = customerPayLoad["customers"];
  return { data: formatedPayLoad };
}

function getProductData() {
  let selectedArr = [];
  let productPayLoad = {};
  let formatedPayLoad = {};
  const arr = [
      "brand",
      "category",
      "eans",
      "sector",
      "subBrand",
      "subCategory",
  ];
  let values = { ...productsStateData };
  //iterating backword
  for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      productPayLoad[level] = [];
      if (values && values[level]) {
          let selectedNodes = values[level].filter(isSelected).map(putAll);
          if (selectedNodes.length !== 0) {
              selectedNodes.forEach((val) => {
              productPayLoad[level].push(val);
              selectedArr.push(val);    
              });
          }
      }
  }

  formatedPayLoad.eans = [];
  if (productPayLoad["eans"].length > 0) {
      productPayLoad["eans"].forEach(element => {
          let eans = element.split("-");
          if (eans.length > 0) {
              formatedPayLoad.eans.push(eans[eans.length - 1].trim())
          }
      });
  }
  formatedPayLoad.sectors = productPayLoad["sector"];
  formatedPayLoad.categories = productPayLoad["category"];
  formatedPayLoad.subBrands = productPayLoad["subBrand"];
  formatedPayLoad.brands = productPayLoad["brand"];
  formatedPayLoad.subCategories = productPayLoad["subCategory"];
  //formatedPayLoad.eans = productPayLoad["eans"];
  return { data: formatedPayLoad };
}
  function handleOpen(x) {
    setIconState({
      ...iconState,
      [x]: !iconState[x],
    });
  }

  function transformDataUnique(values) {
   
    if (values && values.data ) {
      values = values.data;
      let arr1 = [
        "promoType",
        "discountType",
        "promoGroup",
        "promoIds"
      ];

      const objValues = {
        "promoType": [],
        "discountType": [],
        "promoGroup": [],
        "promoIds": [],
      }
      arr1.map((val) => {

        values[val] = [
          values.map((item) => {
            if (item[val] !== undefined) {
                if (val === "promoIds") {
                  item[val].forEach((listItem) => {
                    objValues[val].push({ 'name': listItem, 'parent': item["promoType"], 'isChecked':   selectedPromotionalItem.includes(listItem)});
                  })
                } else {
                  objValues[val].push({ 'name': item[val], customer: item['customer'],promoIds:item['promoIds'], 'isChecked':   selectedPromotionalItem.includes( item[val]) });
                }
              
            }
          }),
        ];
      });
      arr1.map((val) => {
        objValues[val].sort(function (a, b) {
          if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          else if (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else return 0;
        });
      });
      if(selectedPromotionalItem.length >0){
      let selectedSEC = objValues['promoIds'].filter((item) => item.isChecked === true);       
      let sCategory = props.data.data.filter((item)=>{
        return selectedSEC.find(element => {
          return item["promoIds"].includes(element.name);
        });
      })
      if(sCategory.length ===0){
        sCategory =  getSelectedAllValue(objValues,"promoType");         
      }
      const Values = {
        "promoType": [],
        "discountType": [],
        "promoGroup": [],
        "promoIds": [],
      }
      Values['promoType'] = getFormatedLevel(sCategory,'promoType');
      Values['promoIds'] = getFormatedLevel(sCategory,'promoIds')
      setAllPromoData(objValues);
      setPromotionalsStateData({
        ...Values,
      });
    }else{
      setAllPromoData(objValues);
      setPromotionalsStateData({
        ...objValues,
      });
    } 
      return values;

    } else {
      setAllPromoData(values);
      setPromotionalsStateData({
        ...values,
      }); 
      return values;
    }
  }
  function getSelectedAllValue(values,lavel){
    let selectedSEC = values[lavel].filter((item) => item.isChecked === true);       
    let sCategory = props.data.data.filter((item)=>{
    return selectedSEC.find(element => {
      return true;
    });
    });
    return sCategory;
  }
  function getFormatedLevel(data,level){
    let formatedLevel = [];
    data.forEach((listItem) => {
      if(listItem[level] !== null)
      if(level === "promoIds"){
        listItem.promoIds.forEach((listItem) => {
          formatedLevel.push({ 'name': listItem, 'parent': listItem["promoType"],'isChecked': selectedPromotionalItem.includes(listItem) });
        })
      }else{
        formatedLevel.push({ 'name': listItem[level],customer: listItem['customer'],promoIds:listItem['promoIds'], 'isChecked': selectedPromotionalItem.includes(listItem[level]) });
      }
    })
    const unique = [...new Map(formatedLevel.map(item =>
      [item['name'], item])).values()];
      unique.sort(function (a, b) {
        if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else if (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        else return 0;
      });
    return unique;
  }

  function isSelected(x) {
    return x.isChecked;
  }

  function putAll(x) {
    return x.name;
  }


  //during initial render displays all values for diff levels 
  function calculateInitial(data) {
    //const processed = processCustomerHierarchy(data, null)
    const transformed = transformDataUnique(data);
    return transformed;
  }

 

  useEffect(() => {
    if (props.clear) {
      props.resetClear();
    }
  }, [props.clear]);

  const updateSelectedLevel = () => {
    let values = { ...promotionalsStateData };
    //const processed = processCustomerHierarchy(allPromotionData, values)
   transformDataUnique(values);   

  };

  function generateUrl() {
    let selectedArr = [];
    let reformatKcStr = '';
    let values = { ...promotionalsStateData };
    let arr = [
      "promoType",
      "discountType",
      "promoGroup",
      "promoIds"
    ]
    //iterating backword
    for (let i = arr.length - 1; i >= 0; i--) {
      let level = arr[i];
      let selectedNodes = values[level].filter(isSelected).map(putAll);
      if (selectedNodes.length !== 0) {
        selectedNodes.map((val) => {
          reformatKcStr += `&${level}=${val}`;
          selectedArr.push(val);
        });
      }
    }
    return reformatKcStr === '' ? { url: '', selectedArr: [] } : { url: reformatKcStr, selectedArr: selectedArr };
  }

  function unCheckBelowParentLevels(x) {
    let toggleParentCheckBox = { ...promoTypeCheckBox };
    let flagP = false;
    for (let [key] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }
    setPromoTypeCheckBox({ ...toggleParentCheckBox });
  };

  const onSelection = (data) => {
    let values = { ...promotionalsStateData };
    let x = data[0]; //parent level name:subcat
    let y = data[1]; //value in subcat ex-subcat1
    let selectedArr = [];
    values[x].map(function (item) {
      if (item.name === y) item.isChecked = !item.isChecked;
      if (item.isChecked) {
        selectedArr.push(item.name);
      }
    });
    let toggleParentCheckBox = { ...promoTypeCheckBox };
    let flagP = false;
    for (let [key] of Object.entries(toggleParentCheckBox)) {
      if (key === x) flagP = true;
      if (flagP) toggleParentCheckBox[key] = false;
    }

    if (selectedArr.length === promotionalsStateData[x].length) {
      toggleParentCheckBox[x] = true;
    }
    setPromoTypeCheckBox({ ...toggleParentCheckBox });

    //updateSelectedLevel(x);
    values = loadChildOfSelectedParent(x, y, values);
    setPromotionalsStateData({
      ...values,
    });
    let obj = generateUrl();
    setSelectedPromotionalItem(obj['selectedArr']);

  };
  function loadChildOfSelectedParent(x, y, values) {
    if (values.data) {
      values = values.data;
    }
    const objValues = {
      "promoType": [],
      "discountType": [],
      "promoGroup": [],
      "promoIds": [],
    }
    if (x === 'promoType') {
      let selectedPromoType = values['promoType'].filter((item) => item.isChecked === true); 
      if(selectedPromoType.length > 0){
      values["promoType"].map((itemPtype) => {        
        if (itemPtype.isChecked) {
          allPromoData["promoIds"].map((item) => {
            if (item.parent !== undefined && item.parent === itemPtype.name) {
              objValues['promoIds'].push(item);
            }
          })
        }
      })
    }else{
      allPromoData["promoIds"].map((item) => {
          objValues['promoIds'].push(item);
      })
    }
      values["promoIds"] = objValues["promoIds"];
      return values;
    } else {
      let selectedPromId = values['promoIds'].filter((item) => item.isChecked === true); 
      if(selectedPromId.length > 0){
      values["promoIds"].map((itemPtype) => {
        if (itemPtype.isChecked) {
          allPromoData["promoType"].map((item) => {
            if (item.promoIds !== undefined && item.promoIds.length > 0 &&  item.promoIds.includes(itemPtype.name)) {
              objValues['promoType'].push(item);
            }
          })
        }
      })
    }else{
      values["promoIds"].map((itemPtype) => {
          allPromoData["promoType"].map((item) => {
              objValues['promoType'].push(item);            
          })        
      })
    }
      const unique = [...new Map(objValues["promoType"].map(item =>
        [item['name'], item])).values()];
      values["promoType"] = unique;
      return values;
    }
  }
  function SelectAllChildren(parentLevel) {
    let values = { ...promotionalsStateData };
    let selectedArr = [];
    let arr = [
      "promoType",
      "discountType",
      "promoGroup",
      "promoIds"
    ];

    values[parentLevel].map(
      (x) => ((x.isChecked = true))
    );
    arr.map((pItem)=>{
      values[pItem].map(function (item) {
        if (item.isChecked) {
          selectedArr.push(item.name);
        }
      });
    })
    setPromotionalsStateData(values);
    updateSelectedLevel(parentLevel);
    setSelectedPromotionalItem(selectedArr);  
  }

  function DeSelectAllChildren(parentLevel) {
    let values = { ...promotionalsStateData };
    values[parentLevel].map((x) => (x.isChecked = false));
    setPromotionalsStateData(values);
    updateSelectedLevel(parentLevel);
    let selectedArr = [];
    let arr = [
      "promoType",
      "discountType",
      "promoGroup",
      "promoIds"
    ];
    arr.map((pItem)=>{
      values[pItem].map(function (item) {
        if (item.isChecked) {
          selectedArr.push(item.name);
        }
      });
    })
    setSelectedPromotionalItem(selectedArr);
    
    unCheckBelowParentLevels(parentLevel);
  }

  const handleParentClick = (e) => {
    let x = e.target.value;
    setPromoTypeCheckBox({
      ...promoTypeCheckBox,
      [x]: !promoTypeCheckBox[x],
    });

    if (!promoTypeCheckBox[x]) SelectAllChildren(x);
    else DeSelectAllChildren(x);
  };



  return (
    <div className={classes.root}>
      <div style={{ direction: 'ltr' }}>
        {Object.entries(display).map(([key, item]) => (
          <>
            <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                className="customerExpander w-4"
                onClick={() => handleOpen(key)}
                style={{ cursor: 'pointer', justifyContent: 'center' }}>
                {iconState[key] ? '-' : '+'}
              </div>
              <input
                type='checkbox'
                value={key}
                checked={promoTypeCheckBox[key]}
                disabled={item.name === "Discount Type" || item.name === "Promo Group"}
                onChange={handleParentClick} />
              <label className={`${iconState[key] ? classes.open : classes.hide} customerLabel pl-1`}>
                {item.name}
              </label>
            </div>
            {
              <div style={{ marginLeft: '20px' }}>
                {iconState[key] && (
                  <ItemSearch
                    values={promotionalsStateData[key]}
                    parentv={key}
                    onSelection={(e) => {
                      onSelection(e);
                    }}
                  />
                )}
              </div>
            }
          </>
        ))}

      </div>
    </div>
  );
};
export default NestedBaselinePromoType;
