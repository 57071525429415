import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  CardContent,
  Typography,
} from "@material-ui/core";
import Select from 'react-select';
import { has, isEmpty, omit } from 'lodash';

import { useKcProductHierarchy, useCountryMasterData, useOwnProductHierarchy, useSubCategoryLevelData } from "../../services/queries";
import useStore from "../../store";
import { getHierarchyLevel, productLevelsOptionData, productLevelKROptionData, scopeSourceDetails, scopeAndSourceDetails } from "../utils";
import { Scope } from '../types';

const useStyles = makeStyles((theme) => ({
  radioButton: {
    fontWeight: "600",
    color: "#2663ff",
  },
  checkboxFilter: {
    fontWeight: "600",
    color: "#2663ff",
    display: 'flex',
    flexDirection: 'row'
  },
  cardHeader: {
    fontWeight: theme.typography.fontWeightBold,
    opacity: '0.70',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    display: 'flex'
  },
  displayItem: {
    border: '1px solid #b9b6bc',
    width: '100%',
    height: '40px',
    borderRadius: '4px'
  }
}));

const ProductLevelSelector = (props, { setElasticityCol }) => {
  const classes = useStyles();

  const elasticityPrice = {
    avgPrice: true,
    promoPrice: false,
    basePrice: false
  }

  const [categoryLevelsData, setCategoryLevelsData] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [scopeLevelArray, setScopeLevelArray] = useState([]);
  const [sourceLevelArray, setSourceLevelArray] = useState([]);
  const [productLevelsData, setProductLevelsData] = useState([]);
  const [productLevelsArray, setProductLevelsArray] = useState([]);

  let selectedCountry = useStore((state) => state.selectedCountry);
  let ownMasterData = useStore((state) => state.ownMasterData)
  let crossMasterData = useStore((state) => state.crossMasterData)
  //------SELECTION: PRODUCT LEVEL------//
  let selectedProductLevel = useStore((state) => state.selectedProductLevel);
  //------SELECTION: CATEGORY LEVEL------//
  let selectedCategoryLevel = useStore((state) => state.selectedCategoryLevel);
  //------SELECTION: SUBCATEGORY LEVEL------//
  let selectedSubCategoryLevel = useStore((state) => state.selectedSubCategoryLevel);
  //------SELECTION: SCOPE LEVEL------//
  let selectedScopeLevel = useStore((state) => state.selectedScopeLevel);
  let defaultSubCategory = useStore((state) => state.defaultSubCategory);
  let selectedSourceChoice = useStore(state => state.selectedSourceChoice);
  let setSelectedSourceChoice = useStore(state => state.setSelectedSourceChoice);
  const setDisplayTopItems = useStore((state) => state.setDisplayTopItems);
  const setSelectedScopeLevel = useStore((state) => state.setSelectedScopeLevel);
  const setSelectedSubCategoryLevel = useStore((state) => state.setSelectedSubCategoryLevel);
  const setDefaultSubCategory = useStore((state) => state.setDefaultSubCategory);
  let priceType = useStore((state) => state.priceType ? state.priceType : elasticityPrice);

  const { avgPrice, promoPrice, basePrice } = priceType;
  const error = [avgPrice, promoPrice, basePrice].filter((v) => v).length !== 1;

  const { isLoading: isKcProductData, data: kcProductData } = useKcProductHierarchy(`${selectedCountry}&hierarchyLevel=${getHierarchyLevel(selectedProductLevel)}&source=${selectedSourceChoice}&scope=${selectedScopeLevel}`);
  const { isLoading: isKcProductOwnData, data: kcProductOwnData } = useOwnProductHierarchy(`${selectedCountry}&hierarchyLevel=${getHierarchyLevel(selectedProductLevel)}&source=${selectedSourceChoice}&scope=${selectedScopeLevel}`);
  const { isLoading: isSubCategoryDataLoading, data: subCategoryData } = useSubCategoryLevelData(`country=${selectedCountry}&category=${encodeURIComponent(selectedCategoryLevel)}&hierarchyLevel=${getHierarchyLevel(selectedProductLevel)}&flag=${props.tabName === 'own' ? 'OWN' : 'CROSS'}`);
  const { data: masterData } = useCountryMasterData(`country=${selectedCountry}`);

  useEffect(() => {
    if (selectedCountry === 'KR') {
      setDisplayTopItems(0)
    }
    setSubCategoryOptions([]);
  }, [selectedCountry])

  useEffect(() => {
    var x = [];
    if (props.tabName === "own") {
      kcProductOwnData && Object.entries(kcProductOwnData.categories).map(([key, category]) => (
        x.push(key)
      ))
    }
    else {
      kcProductData && Object.entries(kcProductData.categories).map(([key, category]) => (
        x.push(key)
      ))
    }
    x.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      else if (a.toLowerCase() > b.toLowerCase()) return 1;
      else return 0;
    });
    const options = x.map(key => ({ id: key, label: key, value: key, className: 'categoryItem' }))
    setCategoryLevelsData(options);
  }, [kcProductData])

  useEffect(() => {
    if (!isSubCategoryDataLoading && !isEmpty(subCategoryData)) {
      subCategoryData.sort();
      const options = subCategoryData?.map((key) => ({
        id: key,
        label: key,
        value: key,
        className: "subCategoryItem",
      }));
      setSubCategoryOptions(options);
    }
  }, [isSubCategoryDataLoading, subCategoryData])

  useEffect(() => {
    if (selectedScopeLevel === 'CHANNEL' && props.tabName === 'own') {
      props.onchange(["productLevel", 'subBrand']);
    }
    if (props.tabName !== 'own' && selectedProductLevel === 'category') {
      props.onchange(["productLevel", 'ean']);
    }
  }, [selectedScopeLevel, props.tabName, selectedProductLevel])

  useEffect(() => {
    let prodLabel = props.tabName !== 'own' && selectedProductLevel === 'category' ? getHierarchyLevel('subBrand') : getHierarchyLevel(selectedProductLevel);
    extractSourceScopeOptions(props.tabName === 'own' ? ownMasterData : crossMasterData, prodLabel);
  }, [ownMasterData, crossMasterData, props.useNewScopeBy]);

  useEffect(() => {
    let filteredprodLevelKRArray = [];
    if (props.tabName !== 'own') {
      filteredprodLevelKRArray = omit(productLevelKROptionData, 'category');
    }
    const productLevelsData = selectedCountry === 'KR' ? (!isEmpty(filteredprodLevelKRArray) ? filteredprodLevelKRArray : productLevelKROptionData) : productLevelsOptionData;
    setProductLevelsData(productLevelsData);
    const productLevelsArray = Object.keys(productLevelsData).map(key => ({ id: key, label: productLevelsData[key].display, value: productLevelsData[key].display, className: `levelItem level_${key}` }));
    setProductLevelsArray(productLevelsArray);
  }, [props.tabName, selectedCountry])

  const extractSourceScopeOptions = (masterData, prodLabel) => {
    //Exception handling
    if (!masterData || !prodLabel || !masterData[prodLabel]) {
      setSourceLevelArray([]);
      setScopeLevelArray([]);
      setSelectedSourceChoice("");
      setSelectedScopeLevel("");
      return;
    }
    let allSourceOptions = Object.keys(masterData[prodLabel])?.map(key => ({ id: key, label: key, value: key }));
    allSourceOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
    let allScopeOptions = [];
    allSourceOptions.forEach(item => {
      masterData[prodLabel][item.id].forEach(scope => {
        allScopeOptions.push({ id: scope, label: scope, value: scope })
      })
    })
    setSourceLevelArray(allSourceOptions);
    setScopeLevelArray(allScopeOptions);

    //Setting default source and scope values
    setSelectedSourceChoice(allSourceOptions[0]?.value);
    setSelectedScopeLevel(allScopeOptions[0]?.value);

    if (props.useNewScopeBy) {
      setScopeLevelArray([
        {
          id: Scope.CUSTOMER,
          label: Scope.CUSTOMER,
          value: Scope.CUSTOMER
        },
        {
          id: Scope.CHANNEL,
          label: Scope.CHANNEL,
          value: Scope.CHANNEL
        }
      ])
    }
  }

  const handleChange = (event) => {
    //Setting default value when product level is updated
    setSelectedSourceChoice(selectedSourceChoice)
    setSelectedScopeLevel(selectedScopeLevel);
    if (selectedProductLevel !== event.id) {
      props.onchange(["productLevel", event.id]);
    }
  };

  const handleCategory = (event) => {
    props.onchange(["categoryLevel", event.value]);
    setSelectedSubCategoryLevel([]);
    setDefaultSubCategory([])
    setSubCategoryOptions([]);
  };

  const handleScopeChange = (event) => {
    const prevScope = selectedScopeLevel;
    const selectedScopeSource = selectedCountry === 'KR' ? scopeAndSourceDetails.find(scopeArr => scopeArr.scope === event.id) : scopeSourceDetails.find(scopeArr => scopeArr.scope === event.id);
    setSelectedScopeLevel(event.id);
    const nextScope = useStore.getState().selectedScopeLevel;
    setSelectedSourceChoice(selectedScopeSource.source);
    if (prevScope !== nextScope) {
      props.onScopeChange(event.id);
      props.onSourceChange(selectedScopeSource.source);
      props.onchange(["categoryLevel", '']);
    }
    setSelectedSubCategoryLevel([]);
    setDefaultSubCategory([])
    setSubCategoryOptions([]);
  };

  const handleSubCategoryChange = (event) => {
    const { onSubCategoryChange } = props;
    let option = [];

    for (let i = 0; i < event.length; i++) {
      option.push(event[i].value);
    }
    setSelectedSubCategoryLevel(option);
    setDefaultSubCategory(event);
    onSubCategoryChange();
  };

  let displayTopItems = useStore(
    (state) => state.displayTopItems
  );

  const chooseProduct = (event) => {
    props.onDisplayItemChange(event.target.value);
    setDisplayTopItems(event.target.value);
  };

  const handleSourceChange = evt => {
    const prevSource = selectedSourceChoice;
    const selectedSourceScope = scopeSourceDetails.find(sourceArr => sourceArr.source === evt.id);
    setSelectedSourceChoice(evt.id);
    const nextSource = useStore.getState().selectedSourceChoice;
    setSelectedScopeLevel(selectedSourceScope.scope);
    if (prevSource !== nextSource) {
      if (props.onSourceChange) {
        props.onSourceChange(evt.id);
      }
      props.onScopeChange(selectedSourceScope.scope);
      props.onchange(["categoryLevel", '']);
    }
    setSelectedSubCategoryLevel([]);
    setDefaultSubCategory([])
    setSubCategoryOptions([]);
  }

  const handleCheckboxChange = (event) => {
    let elasticityPrice = [
      "elasticityPriceChange",
      { ...priceType, [event.target.name]: event.target.checked },
    ];
    props.onchange(elasticityPrice);
  };

  const RedAsterisk = () => (<div style={{ color: 'red' }}>&nbsp;*</div>)

  return (
    <Grid container>
      <Grid item xs={2} style={{ marginRight: "3%", marginBottom: "1%" }}>
        <CardContent style={{ border: "2px solid #b9b6bc" }}>
          <Typography
            gutterBottom
            component="div"
            className={classes.cardHeader}
          >
            Source <RedAsterisk />
          </Typography>
          <Select
            baseClassName="rdn"
            className="sourceSelector"
            placeholder={selectedSourceChoice}
            onChange={handleSourceChange}
            key={selectedSourceChoice}
            options={sourceLevelArray}
          />
        </CardContent>
      </Grid>
      <Grid item xs={2} style={{ marginRight: "3%", marginBottom: "1%" }}>
        <CardContent style={{ border: "2px solid #b9b6bc" }}>
          <Typography
            gutterBottom
            component="div"
            className={classes.cardHeader}
          >
            Categories <RedAsterisk />
          </Typography>
          <Select
            placeholder={selectedCategoryLevel ? selectedCategoryLevel : "Select..."}
            baseClassName="rdn"
            className="categorySelector"
            onChange={handleCategory}
            key={selectedCategoryLevel}
            options={categoryLevelsData}
          />
        </CardContent>
      </Grid>

      <Grid item xs={2} style={{ marginRight: "3%", marginBottom: "1%" }}>
        <CardContent style={{ border: "2px solid #b9b6bc" }}>
          <Typography
            gutterBottom
            component="div"
            className={classes.cardHeader}
          >
            Sub Category
          </Typography>
          <Select
            placeholder={selectedSubCategoryLevel.length > 0 ? selectedSubCategoryLevel : "Select..."}
            baseClassName="rdn"
            className="subCategorySelector"
            onChange={handleSubCategoryChange}
            key={subCategoryOptions}
            options={subCategoryOptions}
            isMulti
            closeMenuOnSelect={false}
            defaultValue={defaultSubCategory}
          />
        </CardContent>
      </Grid>
 
      <Grid item xs={2} style={{ marginRight: "3%", marginBottom: "1%" }}>
        <CardContent style={{ border: "2px solid #b9b6bc" }}>
          <Typography
            gutterBottom
            component="div"
            className={classes.cardHeader}
          >
            Product Level <RedAsterisk />
          </Typography>
          <Select
            placeholder={!isEmpty(productLevelsData) && has(productLevelsData, selectedProductLevel) ? productLevelsData[selectedProductLevel].display : 'EAN'}
            baseClassName="rdn"
            className="levelSelector"
            onChange={handleChange}
            key={selectedProductLevel}
            options={productLevelsArray}
          />
        </CardContent>
      </Grid>
      {props.tabName !== 'correlation' && <>
        <Grid item xs={3} style={{ marginRight: "3%", marginBottom: "1%" }}>
          <CardContent style={{ border: "2px solid #b9b6bc" }}>
            <Typography
              gutterBottom
              component="div"
              className={classes.cardHeader}
            >
              Elasticity Price
            </Typography>
            <FormControl
              required
              error={error}
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
              id="productLevelForm"
            >
              <FormGroup className={classes.checkboxFilter}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={avgPrice}
                      onChange={handleCheckboxChange}
                      name="avgPrice"
                      color="primary"
                    />
                  }
                  label="AVG Price"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={promoPrice}
                      onChange={handleCheckboxChange}
                      name="promoPrice"
                      color="primary"
                    />
                  }
                  label="Promo Price"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={basePrice}
                      onChange={handleCheckboxChange}
                      name="basePrice"
                      color="primary"
                    />
                  }
                  label="Base Price"
                />
              </FormGroup>
            </FormControl>
          </CardContent>
        </Grid>
        {selectedCountry !== 'KR' && props.tabName === 'own' ?
          <>
            <Grid item xs={2} style={{ marginRight: "3%", marginBottom: "1%" }}>
              <CardContent style={{ border: "2px solid #b9b6bc" }}>
                <Typography
                  gutterBottom
                  component="div"
                  className={classes.cardHeader}
                >
                  # records by default
                </Typography>
                <input className={classes.displayItem} type='number' min="0" value={displayTopItems} onInput={chooseProduct} />
              </CardContent>
            </Grid>
          </>
          : ""}

      </>}
      <Grid item xs={2} style={{ marginRight: "3%", marginBottom: "1%" }}>
        <CardContent style={{ border: "2px solid #b9b6bc" }}>
          <Typography
            gutterBottom
            component="div"
            className={classes.cardHeader}
          >
            Market Scope (optional)
          </Typography>
          <Select
            baseClassName="rdn"
            className="scopeSelector"
            placeholder={selectedScopeLevel}
            onChange={handleScopeChange}
            key={selectedScopeLevel}
            options={scopeLevelArray}
          />
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default ProductLevelSelector;