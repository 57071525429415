import { Grid, Box, Button, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import useStore from '../../../../store';
import ClearIcon from '@mui/icons-material/Clear';
import ProductSearch from "./ProductSearch/ProductSearch";
import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";

const useStyles = makeStyles((theme) => ({
	clearBtn: {
		padding: '5px !important',
		minWidth: '50px !important',
		'&:hover': {
			backgroundColor: "#fcf !important",
		},
		display: 'block'
	}
}));

export const ProductSelectionInput = ({ id, promoData }: any) => {
	const classes = useStyles();
	const [clearProduct, setClearProduct] = useState(false);
	const [itemDuplicate, setItemDuplicate] = useState(false);
	const setSelectedBaseLineProductNew = useStore(
		(state: any) => state.setSelectedBaseLineProductNew
	);
	const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
	let selectedPreRoiProducts = useStore((state) => state.selectedPreRoiProducts);
	const setSelectedPreRoiProducts = useStore((state) => state.setSelectedPreRoiProducts)
	const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
	const preRoiSelectedCustomers = useStore(state => state.preRoiSelectedCustomers)

	useEffect(() => {
		if (preRoiSelectedCustomers?.length === 0) {
			setSelectedPreRoiProducts([]);
		}
	}, [preRoiSelectedCustomers])


	useEffect(() => {
		if (selectedPreRoiProducts.length > 0) {
			setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, selectedProduct: selectedPreRoiProducts } : tab))
		}
	}, [selectedPreRoiProducts])


	const clearProductData = () => {
		setClearProduct(true);
		setSelectedBaseLineProductNew(" ");
		setSelectedPreRoiProducts([])
	}

	const resetProduct = () => {
		setClearProduct(false);

	}

	return (
		<Grid container className="mt-2">
			<Grid item xs={2}>
				<Box sx={{ fontWeight: '500', m: 1, fontSize: '0.75rem' }}>Product<span className="text-red-900 font-bold text-base">*</span>  </Box>
			</Grid>
			<Grid item xs={10}>
				<Stack direction="row" spacing={1}>
					<div style={{ width: '38rem' }}>
						<ProductSearch disabled={promoSimulatorTabs[id].calculateButtonClicked} promoData={promoData} dataFor="Promo" clear={clearProduct} resetClearProduct={resetProduct} />
					</div>
					<Button style={{ maxHeight: '53px' }} variant="outlined" disabled={promoSimulatorTabs[id].calculateButtonClicked} className={classes.clearBtn} color="error" onClick={() => clearProductData()}><ClearIcon /></Button>
				</Stack>
			</Grid>
		</Grid>
	)
}