import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import useStore from "../store";
import { useCreateNewImpactorHistory } from "../services/mutations";
import { useBaseLineData, useGetImpactorNamesList, useDailyDataCheck } from "../services/queries";
import { generateBaseLineDataList } from "../helper";
import moment from "moment";
import { extractProduct, extractCustomer } from '../UpdateUrl';
import Toaster from '../commonMuiComponents/Toaster'
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { CircularProgress, Box } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  cellData: {
    borderLeft: "1px solid #ccc",
    textTransform: "capitalize",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  btnContainer: {
    marginTop: "30px",
    float: "right",
    marginRight: "50px",
  },
  btnReset: {
    background: "#cb0c0c",
    color: "#fff",
    textTransform: "none",
    margin: "0 20px",
    padding: "6px 20px",
    borderRadius: "10px",
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
  formBox: {
    border: "2px solid #ccc",
    margin: "0 0 20px",
    padding: "15px",
  },
  formBoxWidth: {
    width: "90%",
  },
  formBoxFullWidth: {
    width: "100%",
  },
  error: {
    color: "red",
  },
  disabled: {
    opacity: 0.5,
  },
  input: {
    marginLeft: 20,
  },
  boxContainer: {
    border: '2px solid #ccc',
    padding: '15px',
    borderRadius: '10px',
    marginLeft: '30px',
  },
  alignCenter: {
    alignItems: 'center',
    display: 'flex',
    margin: '0 10px',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewImpactors() {
  let selectedContry = useStore((state) => state.selectedCountry);

  let setBiReportUpdated = useStore((state) => state.setBiReportUpdated);

  const [showAlert, setShowAlert] = useState({ message: '', type: '' })

  const [dailyDataAvailable, setDailyDataAvailable] = React.useState(false);

  const { isLoading: isLoadingData, data: dailyData } = useDailyDataCheck(selectedContry);

  useEffect(() => {
    if (!isLoadingData) {
      setDailyDataAvailable(dailyData.dailyAvailable);
    }
  }, [isLoadingData]);

  //
  let selectedYear = useStore((state) => state.selectedYear);
  let viewMonthly = useStore((state) => (state.viewMonthly === "daily" || state.viewMonthly === "monthly" || state.viewMonthly === "weekly") && dailyDataAvailable ? "daily" : "weekly");
  //let viewMonthly = useStore((state) => state.viewMonthly);

  const [typeValue, setTypeValue] = useState("Percentage");
  const [nameValue, setNameValue] = useState("");
  const [impactorValue, setImpactorValue] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [impactorNamesData, setImpactorNamesData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [applyValues, setApplyValues] = useState(false);
  const [isWeightedDistribution, setIsWeightedDistribution] = useState(false);

  const inputValueChange = (event) => {
    //console.log("fromDate 741", event.target.name)
    switch (event.target.name) {
      case 'fromDate':
        setFromDate(event.target.value);
        calDuration(event.target.value, toDate);
        break;
      case 'toDate':
        setToDate(event.target.value);
        calDuration(fromDate, event.target.value);
        break;
      default:
        break;
    }
  }

  const calDuration = (startname, endDate) => {
    let stDate = moment(startname, "YYYY/MM/D");
    let edDate = moment(endDate, "YYYY/MM/D");
    let durationVal = edDate.diff(stDate, 'days');

    if (durationVal >= 0) {
      //setDuration(durationVal + 1)
    } else if (durationVal < 0) {
      setShowAlert({
        type: 'error',
        message: 'To date is greater than From date'
      })
      setToDate('');
      //setDuration(0)
    }

  }

  const applyDateFilter = () => {

    if (fromDate == '' || toDate == '') {
      setShowAlert({
        type: 'error',
        message: 'Please select From Date and To Date'
      })
      return false
    }

    console.log("selectedDate", fromDate)

    let fromMoment = moment(fromDate, 'YYYY-MM-DD');
    let toMoment = moment(toDate, 'YYYY-MM-DD');
    let tempObj = { 0: false }

    for (var m = fromMoment; m.diff(toMoment, 'days') <= 0; m.add(1, 'days')) {
      tempObj[`${m.format('D')}-${m.format('MMMM').toLowerCase()}`] = true
      //{row.split('-')[0]}
      //tempObj[`${m.format('D')}`] = true
    }

    setNewDataRow(tempObj);
    setApplyValues(true)
  }

  const clearFilter = () => {
    setToDate('');
    setFromDate('')
    setNewDataRow({ 0: false });
  }

  //errors

  const [isValidName, setIsValidName] = useState(false);

  const { mutate: createImpactor, isSuccess: successCreate, isError: successError } =
    useCreateNewImpactorHistory();

  let selectedBaseLineProductNew = useStore(
    (state) => state.selectedBaseLineProductNew
  );

  let selectedBaseLineCustomerNew = useStore(
    (state) => state.selectedBaseLineCustomerNew
  );

  let { category, subCategory, brand, subBrand, ean } = extractProduct(decodeURIComponent(selectedBaseLineProductNew));
  let { planLevel, soldTo } = extractCustomer(decodeURIComponent(selectedBaseLineCustomerNew));

  const submitImpactor = () => {

    let selectedValuesZeroRemoved = selectedValues;

    let requestBody = {
      country: selectedContry,
      year: selectedYear[0],
      granularity: viewMonthly,
      planLevels: planLevel,
      customers: soldTo,
      categories: category,
      subCategories: subCategory,
      brands: brand,
      subBrands: subBrand,
      eans: ean,
      impactorName: nameValue,
      isAbs: type,
      impactorValue: parseInt(impactorValue),
      weeks: [],
      days: [],
      weighted: isWeightedDistribution,
    };

    if (viewMonthly !== "daily") {
      let selectedWeeks = [];
      for (const key in selectedValuesZeroRemoved) {
        let weekNum = key.split("-")[0];
        if (weekNum) {
          let weekValue = weekNum.slice(1, weekNum.length);
          selectedWeeks.push(parseInt(weekValue));
        }
      }
      requestBody["weeks"] = selectedWeeks;
      createImpactor(requestBody);
    } else {
      let selectedDays = [];

      for (const key in selectedValuesZeroRemoved) {
        let monthName = key.split("-")[1];

        let monthNum = moment(monthName, "MMMM").format("MM");
        let date = key.split("-")[0];
        let dayNumFormatted = ("0" + date).slice(-2);
        if (date > 0) {
          selectedDays.push(`${selectedYear}-${monthNum}-${dayNumFormatted}`);
        }
      }

      requestBody["days"] = selectedDays;
      createImpactor(requestBody);
    }
    setOpenSave(true)
    handleClickClose();
  };

  const [apidataLocal, setApidataLocal] = useState([]);
  const [apidata, setApidata] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const selectedCustomersHierarchy = useStore(state => state.selectedCustomersHierarchy);
  const selectedProductsHierarchy = useStore(state => state.selectedProductsHierarchy);
  const [baseLineDataPayload, setBaseLineDataPayload] = useState(null);
  const { isLoading: isImpNamesList, data: namesListValyes, isError: nameListError } =
    useGetImpactorNamesList();

  const { isLoading: isCountryLineData, data: countryLineData, isError: countryDataError } =
    useBaseLineData(baseLineDataPayload);

  useEffect(() => {
    let payload = {
      country: selectedContry,
      years: selectedYear,
      granularity: viewMonthly,
      customers: selectedCustomersHierarchy?.soldTo,
      eans: selectedProductsHierarchy?.products,
      categories: selectedProductsHierarchy?.categories,
      subCategories: selectedProductsHierarchy?.subCategories,
      brands: selectedProductsHierarchy?.brands,
      subBrands: selectedProductsHierarchy?.subBrands
    }
    setBaseLineDataPayload(payload)
  }, [
    selectedContry,
    selectedCustomersHierarchy?.soldTo,
    selectedProductsHierarchy?.products,
    selectedProductsHierarchy?.categories,
    selectedProductsHierarchy?.subCategories,
    selectedProductsHierarchy?.brands,
    selectedProductsHierarchy?.subBrands,
    viewMonthly, selectedYear]);

  useEffect(() => {
    if (!isCountryLineData) {
      setCountriesList(countryLineData);
    }
  }, [isCountryLineData]);

  useEffect(() => {
    if (!isImpNamesList) {
      setImpactorNamesData(namesListValyes);
    }
  }, [isImpNamesList]);

  useEffect(() => {
    if (successCreate) {
      setOpenSave(false);
      setShowAlert({
        type: 'success',
        message: 'new impactor created'
      })
      setNameValue("");
      setImpactorValue("");
      setSelectedValues([]);
      setNewDataRow({});
      //331
      setBiReportUpdated(true)

    } else if (successError) {
      setOpenSave(false);
      setShowAlert({
        type: 'error',
        message: 'something went wrong, please try again'
      })
      setNameValue("");
      setImpactorValue("");
      setSelectedValues([]);
      setNewDataRow({});
      setBiReportUpdated(true)
    }
  }, [successCreate,]);

  useEffect(() => {
    if (!isCountryLineData) {
      setCountriesList(countryLineData);

      setApidata(apidataLocal);
      setApidata(
        generateBaseLineDataList(
          countryLineData,
          viewMonthly,
          typeValue,
          impactorValue
        )
      );

      setApplyValues(false)
    }
  }, [
    isCountryLineData,
    // selectedContry,
    // selectedBaseLineProductNew,
    // selectedBaseLineCustomerNew,
    typeValue,
    impactorValue,
    applyValues
  ]);

  /*  useEffect(()=>{
    setApidata(generateBaseLineDataList('123', viewMonthly));
  },[]) */
  const classes = useStyles();

  const [openSubmit, setOpenSubmit] = React.useState(false);
  const [openSave, setOpenSave] = React.useState(false);

  const handleClickOpen = () => {
    setIsValidName(false);

    if (isNameValid) {
      setIsValidName(true);
      return false;
    } //123456

    console.log("selectedValues", Object.entries(selectedValues).length)
    if (impactorValue === "") {
      setShowAlert({
        type: 'error',
        message: "Impactor value should not be empty"
      })
      return false;
    }

    if (nameValue === "") {
      setShowAlert({
        type: 'error',
        message: "Impactor name should not be empty"
      })
      return false;
    }
    if (Object.entries(selectedValues).length === 0) {
      setShowAlert({
        type: 'error',
        message: `Please select ${viewMonthly === 'daily' ? 'day' : 'week'} from list`
      })
      return false;
    }
    setOpenSubmit(true);
  };

  const handleClickClose = () => {
    setOpenSubmit(false);
  };

  const handleClickReset = () => {
    /* let ary = newDataRow.map((value) => false);
    setSelectAll(false);
    setNewDataRow(ary); */

    setNameValue("");
    setImpactorValue("");
  };

  const [newDataRow, setNewDataRow] = useState({});

  const [selectAll, setSelectAll] = useState(false);

  const onChange = (e, value) => {
    if (value === "Source") {
      if (e.target.checked) {
        let temObj = { 0: true };
        let selVal = [];

        for (const iterator of apidata.headerData[0]) {
          // temObj[iterator.substring(1)] = true;
          temObj[iterator] = true;
          selVal.push(iterator);
        }
        console.log("selValselVal", selVal)
        setSelectedValues(selVal);
        setNewDataRow(temObj);
      } else {
        let temObj = {};
        for (const iterator of apidata.headerData[0]) {
          temObj[iterator] = false;
        }
        setSelectedValues([]);
        setNewDataRow(temObj);
      }
    } else {

      let selval = selectedValues;
      if (viewMonthly === "daily") {
        value = e.target.value;
      }
      let y = value;
      let currMonth = new Date().getMonth() + 1;
      let i = 0;
      while (apidata.weekCountAPI[i] !== 0 && y > apidata.weekCountAPI[i]) {
        y -= apidata.weekCountAPI[i];
        i++;
      }
      let monthId = currMonth + i;
      let dayId = y;
      let newDay = `${monthId}-${dayId}`;

      if (e.target.checked) {
        setNewDataRow((item) => ({
          ...item,
          [value]: true,
        }));
        //123selval.push(viewMonthly === "daily" ? newDay : value);
        console.log("selval472", selval)
      } else {

        setNewDataRow((item) => ({
          ...item,
          0: false,
          [value]: false,
        }));
        const index = selval.indexOf(value);

        if (index > -1) {
          console.log("test482", value)
          selval.splice(index, 1);
        }
      }
      console.log("selval486", selval)
      setSelectedValues(selval);
    }
    console.log("NewDataRow", newDataRow)
  };

  const onChangeInput = (e) => {
    setNameValue(e.target.value);

    //let index = impactorNamesData.indexOf(e.target.value); // 1

    const index = impactorNamesData.findIndex((element) => {
      return element.toLowerCase() == e.target.value.toLowerCase();
    });

    //console.log('line 474', e.target.value, impactorNamesData, index)
    if (index < 0) {
      setIsNameValid(false);
    } else {
      setIsNameValid(true);
    }
  };

  const [type, setType] = useState(false);

  const [errorMsgVal, setErrorMsgVal] = useState("");

  const onChangeInputValue = (e) => {
    console.log("typetype", type)
    setImpactorValue(e.target.value);
    setErrorMsgVal("");
    let val = e.target.value;
    let error;


    if (type) {
      let minValues = apidata?.minValue;
      if (val <= -minValues) {
        error = `value not less than ${apidata?.minValue}`;
        setImpactorValue("");
      } else {
        error = "";
        setImpactorValue(e.target.value);
      }
    } else {
      if (val < -100) {
        error = "value not less than -100";
        setImpactorValue("");
      } else {
        error = "";
      }
    }

    setErrorMsgVal(error);
  };

  const valueType = (e) => {
    console.log("line225", e.target.value);
    let val;
    setTypeValue(e.target.value);
    setImpactorValue("");
    setNameValue("");

    if (e.target.value === "AbsoluteValue") {
      val = true;
    } else {
      val = false;
    }

    setType(val);
  };

  /*  var date = new Date();
   let dateVal =    new Date(date.getFullYear(), date.getMonth(),1)
   console.log("dateValdateVal", dateVal)
   let dateValEnd =    new Date(date.getFullYear(), date.getMonth() +1,1)
   console.log("dateValdateVal474", dateValEnd)
*/

  useEffect(() => {
    setSelectedValues(newDataRow);
  }, [newDataRow]);

  return (
    <div style={{ display: "inline" }}>
       {
        isCountryLineData && <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px',
        }}>
            <CircularProgress sx={{ color: "#0000b3"}} />
        </Box>
      }
      <div className={`${classes.formBox} ${dailyDataAvailable ? classes.formBoxFullWidth : classes.formBoxWidth}`}>
        {
          (nameListError || countryDataError) && setShowAlert({
            type: 'error',
            message: "Something went wrong, please reload page"
          })
        }
        <p>Select Value Type</p>
        {isValidName && (
          <p className={classes.error}>Please enter valid Name</p>
        )}
        {errorMsgVal && <p className={classes.error}>{errorMsgVal}</p>}
        <FormControl component="fieldset" className={classes.flexRow}>
          <RadioGroup
            row
            aria-label="gender"
            name="row-radio-buttons-group"
            onChange={(e) => valueType(e)}
            defaultValue={"Percentage"}
          >
            <FormControlLabel
              value="Percentage"
              control={<Radio />}
              label="Percentage"
            />
            <FormControlLabel
              value="AbsoluteValue"
              control={<Radio />}
              label="Absolute value(Cases)"
            />
          </RadioGroup>
          <div className={`${classes.input} ${classes.alignCenter}`}>
            Value{" "}
            <input
              type="number"
              size="15"
              onChange={(e) => onChangeInputValue(e)}
              value={impactorValue}
              className='border-2'
            />
          </div>
          <div className={`${classes.input} ${classes.alignCenter}`}>
            Name{" "}
            <input
              type="text"
              onChange={(e) => onChangeInput(e)}
              value={nameValue}
              className='border-2'
            />{" "}
            {nameValue.length > 0 && (
              <span>
                {isNameValid && <CloseOutlinedIcon sx={{color: 'red'}} />}

                {!isNameValid && (
                  <CheckOutlinedIcon sx={{color: 'green'}} />
                )}
              </span>
            )}
          </div>
          <div className={`${classes.input} ${classes.alignCenter}`}>
            <input className="mr-1" type="checkbox" checked={isWeightedDistribution} onChange={() => setIsWeightedDistribution(!isWeightedDistribution)} />
            <label>Weighted Distribution</label>
          </div>
          {dailyDataAvailable &&
            <div className={`flex flex-row gap-2 ${classes.boxContainer} `}>

              <div className="flex flex-col gap-2 ">
                <span className="text-sm">From<span className="text-red-900 font-bold text-base">*</span></span>

                <div className="flex bg-white rounded h-10 items-center px-2 border-2 mr-6">

                  {selectedContry == 'KR' ? (
                    <input type="date" name="fromDate"
                      value={fromDate}
                      //min={moment().startOf('month').format('YYYY-MM-DD')}
                      max={moment().endOf('year').format('YYYY-MM-DD')}
                      onChange={inputValueChange}
                      id="" placeholder="DD/MM/AAAA" className="placeholder:text-xs w-full calendar placeholder:text-gray-300" />
                  ) : (
                    <input type="date" name="fromDate"
                      value={fromDate}
                      min={moment().startOf('month').format('YYYY-MM-DD')}
                      max={moment().endOf('year').format('YYYY-MM-DD')}
                      onChange={inputValueChange}
                      id="" placeholder="DD/MM/AAAA" className="placeholder:text-xs w-full calendar placeholder:text-gray-300" />
                  )}


                </div>
              </div>

              <div className={`flex flex-col gap-2 `}>
                <span className="text-sm">To<span className="text-red-900 font-bold text-base">*</span></span>
                <div className="flex bg-white rounded h-10 items-center px-2 border-2">

                  {selectedContry == 'KR' ? (
                    <input type="date" name="toDate"
                      value={toDate}
                      onChange={inputValueChange}
                      max={moment().endOf('year').format('YYYY-MM-DD')}
                      id="" placeholder="DD/MM/AAAA" className="placeholder:text-xs w-full " />
                  ) : (
                    <input type="date" name="toDate"
                      value={toDate}
                      onChange={inputValueChange}
                      min={moment().startOf('month').format('YYYY-MM-DD')}
                      max={moment().endOf('year').format('YYYY-MM-DD')}
                      id="" placeholder="DD/MM/AAAA" className="placeholder:text-xs w-full " />
                  )}

                </div>
              </div>
              <div className={`flex flex-col gap-2  `}>

                <Button
                  className={classes.btnSucess}
                  onClick={() => applyDateFilter()}
                >
                  Apply
                </Button>

                <Button className={classes.btnReset} onClick={() => clearFilter()}>
                  Clear
                </Button>
              </div>

            </div>
          }
        </FormControl>
      </div>

      {selectedContry && apidata && apidata.dataRows?.length > 0 && apidata?.headerData.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                {viewMonthly !== "monthly" && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={1}
                      className={classes.cellData}
                    ></TableCell>

                    {apidata.mainHeaderForWeek?.map((headeVal, index) => (
                      <TableCell
                        align="center"
                        colSpan={apidata.weekCountAPI[index]}
                        className={classes.cellData}
                      >
                        {headeVal}
                      </TableCell>
                    ))}
                  </TableRow>
                )}

                <TableRow>
                  {apidata.headerData[0]?.map((row, i) => (
                    <TableCell
                      component="th"
                      scope="row"
                      className={`${classes.cellData} ${newDataRow[viewMonthly === 'daily' ? row : apidata?.headerData[0][i].substring(1)] ? "" : classes.disabled
                        }`}
                    >
                      {row.split('-')[0]}
                      <input
                        type="checkbox"
                        onChange={(e) => onChange(e, viewMonthly === 'daily' ? row : row)}
                        value={row}
                        index={row}
                        checked={newDataRow[row === 'Source' ? '0' : viewMonthly === 'daily' ? row : row]}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {apidata.dataRows?.map(
                  (row, i) =>
                    row[1] !== '' && (
                      <TableRow key={row[0]}>

                        {

                          row.map((data, j) => (
                            <TableCell
                              component="th"
                              scope="row"
                              /* className={`${classes.cellData} ${newDataRow[
                                  viewMonthly === 'daily' ? j : apidata?.headerData[0][j]
                               ] || j === 0
                                 ? ""
                                 : classes.disabled
                                 }`} */

                              className={`${classes.cellData}
                                ${j === 0 ? '' : newDataRow[apidata?.headerData[0][j]] ? '' : classes.disabled}
                              
                              `}
                            >




                              {(row[0] === 'Adjustment Baseline' || row[0] === 'stat + adj') && j === 0 && data}

                              {/* 
                              {(row[0] === 'Adjustment Baseline' || row[0] === 'stat + adj') && ` ${newDataRow[
                                viewMonthly === 'daily' ? j : apidata?.headerData[0][j]
                              ] && j !== 0
                                ? data
                                : ""
                                }`} */}

                              {(row[0] === 'Adjustment Baseline' || row[0] === 'stat + adj') && j !== 0 && newDataRow[apidata?.headerData[0][j]] ? data : ''}

                              {(row[0] !== 'Adjustment Baseline' && row[0] !== 'stat + adj') && data}



                            </TableCell>
                          ))}
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {selectedContry && apidata && apidata.dataRows?.length === 0 && apidata?.headerData.length === 0 &&

        <p>No data to display </p>

      }
      <Dialog
        open={openSubmit}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Record Submit?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please confirm that you want to submit the change
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btnContainer}>
          <Button onClick={handleClickClose} className={classes.btnReset}>
            Cancel
          </Button>
          <Button
            onClick={() => submitImpactor()}
            className={classes.btnSucess}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openSave}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Record Saving"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Saving the data, please wait
          </DialogContentText>
        </DialogContent>

      </Dialog>

      {selectedContry && apidata && apidata.dataRows?.length > 0 && apidata?.headerData.length > 0 &&
        <div className={classes.btnContainer}>
          <Button className={classes.btnReset} onClick={handleClickReset}>
            Reset
          </Button>
          <Button className={classes.btnSucess} onClick={handleClickOpen}>
            Submit
          </Button>
        </div>
      }
      <Toaster showAlert={showAlert} />
    </div>
  );
}

