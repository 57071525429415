import { useEffect, useState } from 'react';
import { Card, Typography } from "@mui/material";
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
//import { PnLResposne } from '../Constants/mockDropdownApiResposne/mockDropdownApiResposne';
import Paper from '@mui/material/Paper';
import { PNL_KEYS, PNL_PARENT_KEYS, PNL_KEYS_INC, PNL_TOTAL_KEYS, PNL_ROI_KEYS } from '../Constants';
import { SaveButton } from '../InputSection/SaveButton';

export const PnLGrids = ({ id, setPromoNameError, promoNameError }: any) => {
  const classes = useStyles();

  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const [PnLResposne, setPnLResposne] = useState(promoSimulatorTabs[0].PnLResposne);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs)
  const PnLGridTypes = ["base", "incremental", "promo"];
  const [PnlData, setPnlData] = useState([]) //.sort((a: any, b: any) => a.ean > b.ean ? 1 : -1);
  const [products, setProducts] = useState([]) // PnlData.map((data:any) => data.ean);
  let basePnlDataTransform: any = [];
  let incrementalPnlDataTransform: any = [];
  let promoPnlDataTransform: any = [];
  let isCalulateButtonClicked = promoSimulatorTabs[id].calculateButtonClicked;

  const getRowValues = (keyName: string, type: string) => {
    let tempPnLObject: any = {
      product: [],
      base: [],
      total: [],
      incremental: []
    };
    Object.keys(PNL_PARENT_KEYS).forEach((key: string) => {
      PnlData.map((data: any) => {
        tempPnLObject[key].push(data[key])
      });
    });

    if (type === "base") {
      basePnlDataTransform.push(generateKeyValueObject(keyName, PnlData, type));
      tempPnLObject.base = basePnlDataTransform.map((row: any) => getTableCells(row, keyName, type))
      return tempPnLObject.base
    };
    if (type === "incremental") {
      incrementalPnlDataTransform.push(generateKeyValueObject(keyName, PnlData, type));
      return incrementalPnlDataTransform.map((row: any) => getTableCells(row, keyName, type));
    };
    if (type === "promo") {
      promoPnlDataTransform.push(generateKeyValueObject(keyName, PnlData, type));
      return promoPnlDataTransform.map((row: any) => getTableCells(row, keyName, type));
    };
  };
  
  const generateKeyValueObject = (key: string, PnlData: any, type: string) => {
    let tempObject: any = {
      key: key,
      value: []
    };
    PnlData.forEach((data: any, index: number) => {
      type === "base" && tempObject.value.push({ [data.ean]: (data?.base[key] === undefined ? "" : data.base[key]) });
      type === "incremental" && tempObject.value.push({ [data.ean]: (data?.incremental[key] === undefined ? "" : data.incremental[key]) });
      type === "promo" && tempObject.value.push({ [data.ean]: (data?.total[key] === undefined ? "" : data.total[key]) });
    });
    return tempObject;
  };

  const getTableCells = (row: any, keyName: string, type: string) => {
    let tempObject: any = {
      value: []
    }
    PnlData.forEach((data: any, index: number) => {
      type === "base" && tempObject.value.push({ [data.ean]: (data?.base['gpNa'] === undefined ? false : data.base['gpNa']) });
      type === "incremental" && tempObject.value.push({ [data.ean]: (data?.incremental['gpNa'] === undefined ? false : data.incremental['gpNa']) });
      type === "promo" && tempObject.value.push({ [data.ean]: (data?.total['gpNa'] === undefined ? false : data.total['gpNa']) });
    });
    if ((row.key === keyName)) {
      return row.value.map((item: any, index: number) => {
        for (var key in item) {
          const findProduct = tempObject.value.find((ean: any) => Object.keys(ean)[0] === key);
          return <TableCell
            key={index}
            component="th"
            scope="row"
            className={classes.cellDataCenter}
            style={{ zIndex: 1200 }}
          >
            {row.key === 'gpPercent' && Object.values(findProduct)[0] ? 'N/A' : item[key]?.toLocaleString("en-US")}
          </TableCell>
        };
      });
    };
  };

  const getTotalValue = (keyName: string, type: string) => {
    const value = promoSimulatorTabs[0].pnlTotalData ? promoSimulatorTabs[0].pnlTotalData[PNL_TOTAL_KEYS[type as keyof typeof PNL_TOTAL_KEYS]][keyName] : '-';
    let tempObject: any = {
      value: []
    };
    PnlData.forEach((data: any, index: number) => {
      type === "base" && tempObject.value.push({ [data.ean]: (data?.base['gpNa'] === undefined ? false : data.base['gpNa']) });
      type === "incremental" && tempObject.value.push({ [data.ean]: (data?.incremental['gpNa'] === undefined ? false : data.incremental['gpNa']) });
      type === "promo" && tempObject.value.push({ [data.ean]: (data?.total['gpNa'] === undefined ? false : data.total['gpNa']) });
    });
    const gpPercentValues = tempObject.value.filter((obj:any) => {
      return !Object.values(obj)[0];
    });

    return keyName === 'gpPercent' ? (gpPercentValues.length > 0 ? value !== '-' ? value : '-' : 'N/A') : value !== '-' ? keyName !== 'gpPercent' && keyName !== 'rtpPercent' ? value.toLocaleString() : value : '-';
  }

  useEffect(() => {
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, ProfitAndLoss: { basePnlDataTransform, incrementalPnlDataTransform, promoPnlDataTransform, productDetailsCalculateButtonClicked: true } } : tab));
  }, [isCalulateButtonClicked]);

  useEffect(() => {
    if (promoSimulatorTabs[0].PnLResposne.length > 0) {
      setPnLResposne(promoSimulatorTabs[0].PnLResposne);
      setPnlData(promoSimulatorTabs[0].PnLResposne);
      setProducts(promoSimulatorTabs[0].PnLResposne.map((data: any) => data.eanDescription + ' - ' + data.ean))
    }
  }, [promoSimulatorTabs[0].PnLResposne]);

  return (<>
    {(promoSimulatorTabs[id].calculateButtonClicked && promoSimulatorTabs[id].PnLResposne.length > 0) ? (
      <Card raised={true} className="p-4 mt-2">
        <div className="mt-3">
          <TableContainer component={Paper}  >
            <Table stickyHeader className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(classes.headerCell, classes.typeWidth)}>
                  </TableCell>
                  <TableCell className={clsx(classes.headerCell, classes.fieldWidth)}>
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    className={classes.totalCells}
                  >Total</TableCell>
                  {products.map((product: any, index: any) => (
                    <TableCell key={index} className={classes.headerCell} style={{ left: 0, zIndex: 1, fontSize: '0.75rem' }}>
                      {product}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {PnLGridTypes.map((type, index) => (
                  type !== 'incremental' ? <>
                    <TableCell rowSpan={Object.keys(PNL_KEYS).length + 1} className={clsx(classes.bodylefHeaderCells, type !== 'base' ? classes.bodyLeftHeaderCellBorder : '')}>
                      <Typography variant="button" fontSize={'13px'} color='#0000b3' fontWeight="700">
                        &nbsp;{type}
                      </Typography>
                    </TableCell>
                    {Object.keys(PNL_KEYS).map((keyName, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.bodyleftCells}>
                          {PNL_KEYS[keyName as keyof typeof PNL_KEYS]}
                        </TableCell>
                        <TableCell key={`row-cell-total${keyName}`}
                          style={{
                            position: "sticky",
                            left: 260,
                            zIndex: 800,
                            background: "#e5e7eb",
                          }}
                          component="th"
                          scope="row"
                          className={classes.cellDataCenter}
                        >
                          {getTotalValue(keyName, type)}
                        </TableCell>
                        {getRowValues(keyName, type)}
                      </TableRow>
                    ))}
                  </> : <>
                    <TableCell rowSpan={Object.keys(PNL_KEYS_INC).length + 1} className={clsx(classes.bodylefHeaderCells, classes.bodyLeftHeaderCellBorder)}>
                      <Typography variant="button" fontSize={'13px'} color='#0000b3' fontWeight="700">
                        &nbsp;{type}
                      </Typography>
                    </TableCell>
                    {Object.keys(PNL_KEYS_INC).map((keyName, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.bodyleftCells}>
                          {PNL_KEYS_INC[keyName as keyof typeof PNL_KEYS_INC]}
                        </TableCell>
                        <TableCell key={`row-cell-total${keyName}`}
                          style={{
                            position: "sticky",
                            left: 260,
                            zIndex: 800,
                            background: "#e5e7eb",
                          }}
                          component="th"
                          scope="row"
                          className={classes.cellDataCenter}
                        >
                          {getTotalValue(keyName, type)}
                        </TableCell>
                        {getRowValues(keyName, type)}
                      </TableRow>
                    ))}
                  </>
                ))}
                <TableCell rowSpan={Object.keys(PNL_KEYS_INC).length + 1} className={classes.bodylefHeaderCells}>
                  <Typography variant="button" fontSize={'13px'} color='#0000b3' fontWeight="700">
                  </Typography>
                </TableCell>
                {Object.keys(PNL_ROI_KEYS).map((keyName, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.bodyleftCells}>
                      {PNL_ROI_KEYS[keyName as keyof typeof PNL_ROI_KEYS]}
                    </TableCell>
                    <TableCell key={`row-cell-total${keyName}`}
                      style={{
                        position: "sticky",
                        left: 260,
                        zIndex: 800,
                        background: "#e5e7eb",
                      }}
                      component="th"
                      scope="row"
                      className={classes.cellDataCenter}
                    >
                      {getTotalValue(keyName, 'incremental')}
                    </TableCell>
                    {getRowValues(keyName, 'incremental')}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <span id="pnl-grids"></span>
          <SaveButton setPromoNameError={setPromoNameError} promoNameError={promoNameError} />
        </div>
      </Card>
    ) : (
      <div className="mt-3">
        No data to display...
      </div>
    )
    }
  </>
  );
};

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  headerCell: {
    borderLeft: "1px solid #ccc",
    textAlign: 'center',
    textTransform: "capitalize",
    position: "sticky",
    background: "#e5e7eb",
    padding: '6px 16px 6px 16px',
  },
  fieldWidth: {
    minWidth: '210px',
    width: '210px',
    maxWidth: '210px',
    left: 140
  },
  typeWidth: {
    minWidth: '140px',
    width: '140px',
    maxWidth: '160px',
  },
  bodylefHeaderCells: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    background: "#e5e7eb",
    width: "140px",
    textAlign: 'left',
    borderRight: '1px solid #ccc',
    padding: '5px 16px 5px 16px',
  },
  bodyLeftHeaderCellBorder: {
    borderTop: '1px solid #ccc',
  },
  bodyleftCells: {
    position: "sticky",
    left: 140,
    zIndex: 1,
    background: "#e5e7eb",
    width: '210px',
    minWidth: "210px",
    textAlign: 'left',
    padding: '5px 16px 5px 16px',
    fontSize: ".75rem",
  },
  cellDataCenter: {
    borderLeft: "1px solid #ccc",
    textAlign: "center",
    padding: "1px",
    fontSize: ".75rem",
  },
  totalCells: {
    position: "sticky",
    left: 351,
    top: 0,
    zIndex: 3,
    background: "#e5e7eb",
    width: 150,
    minWidth: 150,
    borderLeft: "1px solid #ccc",
  }
}));