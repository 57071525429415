import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useTokenBI } from './services/queries';
import { models } from 'powerbi-client';
import './styles.css';
import useStore from './store';
import { extractProduct, extractCustomer } from './UpdateUrl';

function DemoNew() {
  
  const { isLoading: isTokenData, data: tokenData } = useTokenBI(
    process.env.REACT_APP_BASELINE_REPORT_ID
  );
  const [biToken, setBiToken] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');
  let selectedCountry = useStore((state) => state.selectedCountry);
  let selectedBaseLineCustomerNew = useStore(
    (state) => state.selectedBaseLineCustomerNew
  );
  let selectedBaseLineProductNew = useStore(
    (state) => state.selectedBaseLineProductNew
  );

  let selectedYear = useStore((state) => state.selectedYear);
  let setBiReportUpdated = useStore((state) => state.setBiReportUpdated);
  let biReportUpdated = useStore((state) => state.biReportUpdated);
  let viewMonthly = useStore((state) => state.viewMonthly);

  const [planLevel, setPlanLevel] = useState([]);
  const [customerValues, setCustomerValues] = useState([]);
  const [customerPlanValues, setCustomerPlanValues] = useState([]);
  const [productValues, setProductValues] = useState([]);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subBrands, setSubBrands] = useState([]);
  const [eans, setEans] = useState([]);
  const [filtersValue, setFiltersValue] = useState([]);

  // let categories = []
  /*    let subCategories = []
      let brands = []
     let subBrands = []
     let eans = []; */

  const [reportPageName, setReportPageName] = useState('');

  /*  pageName: 'Summary_Month',  */
  /* pageName: 'Summary_GSU', */

  useEffect(() => {
    if(selectedCountry === 'KR') {
      if (viewMonthly === 'monthly') {
        setReportPageName(process.env.REACT_APP_KOREA_BASELINE_PAGENAME_MONTHLY);
      } else if (viewMonthly === 'daily') {
        setReportPageName(process.env.REACT_APP_KOREA_BASELINE_PAGENAME_DAILY);
      }else if (viewMonthly === 'weekly'){
        setReportPageName(process.env.REACT_APP_KOREA_BASELINE_PAGENAME_WEEKLY);
      }
    } else{
      if (viewMonthly === 'monthly') {
        setReportPageName(process.env.REACT_APP_BASELINE_PAGENAME_MONTHLY);
      } else if (viewMonthly === 'daily') {
        setReportPageName(process.env.REACT_APP_BASELINE_PAGENAME_DAILY);
      }else if (viewMonthly === 'weekly'){
        setReportPageName(process.env.REACT_APP_BASELINE_PAGENAME_WEEKLY);
      }
    }
  }, [viewMonthly, selectedCountry]);

  useEffect(() => {
    setCustomerValues(' ');
    setCustomerPlanValues(' ');
    if (!selectedBaseLineCustomerNew !== '') { 
      
      let { planLevel, soldTo } = extractCustomer(decodeURIComponent(selectedBaseLineCustomerNew));
      //soldTo = decodeURIComponent(soldTo)
      setCustomerValues(soldTo);
      setCustomerPlanValues(planLevel);
      setPlanLevel(planLevel);
    }
  }, [selectedBaseLineCustomerNew]);

  useEffect(() => {
    if (!selectedBaseLineProductNew !== '') {
      let { category, subCategory, brand, subBrand, ean } = extractProduct(decodeURIComponent(selectedBaseLineProductNew));
      let categoryUpper = category.map(item=>item.toUpperCase());

      setCategories(categoryUpper);
      setSubCategories(subCategory);
      setBrands(brand);
      setSubBrands(subBrand);
      // Extract only ean code
      ean = ean.map((item) => {
        const eanCode = item.split('-')[0].trim();
        return eanCode;
      })
      setEans(ean);
    }
  }, [selectedBaseLineProductNew]);

   useEffect(() => {
    if (!isTokenData) {
      setBiToken(tokenData?.accessToken);
      setEmbedUrl(tokenData?.url);
      
    }
  }, [isTokenData]); 

  const basicFilterCountry = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'COUNTRY',
    },
    operator: 'In',
    values: [selectedCountry],
    filterType: models.FilterType.BasicFilter,
  };
  /* const basicFilterFrequency = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'WEEK'
    },
    operator: 'In',
    values: ['WEEK'],
    filterType: models.FilterType.BasicFilter
  }  */

  const basicFilterYear = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'YEAR',
    },
    operator: 'In',
    values: selectedYear, //[2021], 2021, '2021'
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: false,
  };

  const basicFilterPlanLevel = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'PLAN_LEVEL',
    },
    operator: 'In',
    //values: ['Asda-UK','Coop GB-UK'],
    values: planLevel,
    filterType: models.FilterType.BasicFilter,
  };


  const basicFilterCustomer = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'SOLD_TO_DESC',
    },
    operator: 'In',
    //values: ['SUPERMERCADO CANDY S.A.C.'],
   values: customerValues,
  // values: ['4 POINTS S.A.C'],
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterCustomerPlan = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'PLAN_LEVEL',
    },
    operator: 'In',
    //values: ['Asda-UK','Coop GB-UK'],
    values: customerPlanValues,
    filterType: models.FilterType.BasicFilter,
  };


  const basicFilterProductCategory = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'CATEGORY',
    },
    operator: 'In',
    values: categories,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProducSubCategories = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'SUB_CATEGORY',
    },
    operator: 'In',
    values: subCategories,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProductBrands = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'BRAND',
    },
    operator: 'In',
    values: brands,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProducteSubBrands = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'SUB_BRAND',
    },
    operator: 'In',
    values: subBrands,
    filterType: models.FilterType.BasicFilter,
  };

  const basicFilterProductEans = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'V_RGM_SLS_FCT_DATA_BASELINE',
      column: 'EAN',
    },
    operator: 'In',
    values: eans,
    filterType: models.FilterType.BasicFilter,
  };

  useEffect(() => {
    let filter = [];

    filter.push(basicFilterYear);
    // filter.push(basicFilterFrequency)

    if (selectedCountry?.length > 0) {
      filter.push(basicFilterCountry);
    }

    if (customerValues?.length > 0) {
      filter.push(basicFilterCustomer);
    }
    else if (planLevel?.length > 0) {
      filter.push(basicFilterPlanLevel);
    }

    if (categories?.length > 0) {
      filter.push(basicFilterProductCategory);
    }

    if (subCategories?.length > 0) {
      filter.push(basicFilterProducSubCategories);
    }
    if (brands?.length > 0) {
      filter.push(basicFilterProductBrands);
    }
    if (subBrands?.length > 0) {
      filter.push(basicFilterProducteSubBrands);
    }
    if (eans?.length > 0) {
      filter.push(basicFilterProductEans);
    }

     if(filter.length > 0){
      console.log("passssss")
      setFiltersValue(filter);
    } 
    
  }, [selectedCountry, selectedYear, planLevel, customerValues, customerPlanValues, categories,
    subCategories, brands, subBrands, eans]); 

  useEffect(() => {
    if(biReportUpdated){
      setBiReportUpdated(false)
    }
  }, [biReportUpdated]);

  return (
    <div className='App'>
      {/*  {embedUrl} */}

      
          {/* <p>subBrands {JSON.stringify(subBrands)}</p>
          <p>eans {JSON.stringify(eans)}</p> 
          <p>basicFilterKCEans {JSON.stringify(basicFilterYear)}</p>
          <p>basicFilterFrequency {JSON.stringify(basicFilterFrequency)}</p> */} 
      {/* <p>filtersValue {JSON.stringify(filtersValue)}</p */}
      {/* <p>reportPageName {JSON.stringify(reportPageName)}</p> */}
      {/* <p>viewMonthly {JSON.stringify (viewMonthly)} </p>
      <p> basicFilterCountry {JSON.stringify (basicFilterCountry)}</p> */}
      {console.log("filtersValue:",filtersValue)}

      <header className='App-header'>
        {!isTokenData && biToken && (
          <PowerBIEmbed
            key = {biReportUpdated}
            embedConfig={{
              pageName: reportPageName,
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              id: process.env.REACT_APP_BASELINE_REPORT_ID,
              embedUrl: embedUrl,
              accessToken: biToken,
              tokenType: models.TokenType.Embed,
              filters: filtersValue,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  function () {
                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  function () {
                    console.log('Report rendered');
                    const parent = document.getElementsByClassName('Embed-container')
                    const iframe = document.getElementsByTagName('iframe',parent)
                    iframe[0].setAttribute('id','powerBIFrame')
                  },
                ],
                [
                  'error',
                  function (event) {
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={'Embed-container'}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        )}
      </header>
    </div>
  );
}

export default DemoNew;
