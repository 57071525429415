import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import useStore from "../../store";
import NestedProductSearch from "./NestedProductSearch"
import useClickOutside from "../../ClickOutside";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useGetProductsSelectors } from "../../services/queries";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    width: theme.spacing(143),
    backgroundColor: theme.palette.background.paper,
  },
  listbox: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  input: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    float: "right",
    padding: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    float: "right",
    height: 28,
    margin: 4,
  },
  checkBox: {
    color: theme.palette.primary.main,
    fontFamily: "sans-serif",
  },
  listItem: {
    marginLeft: "10px",
    listStyle: "none",
    backgroundColor: "#f4f4f4",
    borderRadius: "5px",
  }
}));

function ProductSearch(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [openEventSelector, setOpenEventSelector] = React.useState(false);
  const [clear, setClear] = useState(false);

  let selectedCountry = useStore((state) => state.selectedCountry);

  const { isLoading: isProductsData, data: productsData } =
    useGetProductsSelectors(`${selectedCountry}`, "Promotion");

  let selectedProducts = useStore(
    (state) => state.selectedProducts
  );

  const setSelectedProducts = useStore(
    (state) => state.setSelectedProducts
  );
  const setProductsStateData = useStore((state) => state.setProductsStateData);

  const handleClick = (event) => {
    if (selectedCountry) {
      setOpenSelector(!openSelector);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };
  
  useEffect(() => {
    if (props.clear) {
      setSelectedProducts([]);
      setProductsStateData(props.data);
      setClear(true);
    }
  }, [props]);

  let domNode = useClickOutside(() => {
    setOpenSelector(false);
    setOpenEventSelector(false);
  })

  const resetClear = () => {
    setClear(false);
    props.resetClearProduct();
  }

  return (
    <div
      style={{ display: "flex", component: "form", flexDirection: "column" }}
      ref={domNode}
    >
      <Paper component="form" className={classes.root}>

        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton className={classes.iconButton} aria-label="search" onClick={handleClick}>
              <FilterAltIcon
                className={`icon_${props.dataFor.toLowerCase()}`}
                alt="filterIcon"
                sx={{ color: '#000' }}
              />
            </IconButton>
          </div>
          <div style={{ display: "flex" }}>
            {
              (
                selectedProducts 
                || []).length > 2 ?(<li
                  className={`selected_${props.dataFor.toLowerCase()}`}
                  style={{
                    marginLeft: "10px",
                    listStyle: "none",
                    backgroundColor: "#f4f4f4",
                    borderRadius: "5px",
                  }}
                >
                  Multiple Item Selected({selectedProducts.length})
                </li>) : (
                (
                  selectedProducts
                  || []).filter((value, index, self) => self.indexOf(value) === index).map((item) => (
                    <li
                      className={`selected_${props.dataFor.toLowerCase()}`}
                      key={item}
                      style={{
                        marginLeft: "10px",
                        listStyle: "none",
                        backgroundColor: "#f4f4f4",
                        borderRadius: "5px",
                      }}
                    >
                      {item}
                    </li>
                  ))
              )
            }
          </div>
        </>
      </Paper>
 
      <div style={{ zIndex: 8525 }} className="absolute mt-11">
        {openSelector && (
          <NestedProductSearch
            key={'ProductSearch'}
            data={productsData}
            allData={productsData}
            previouslySelected={selectedProducts}
            clear={props.clear || clear}
            resetClear={resetClear}
            indexValue={props.indexValue}
            tabName={props?.tabName}
            hasScopeData={props?.hasScopeData}
          />
        )}
      </div>

    </div>
  );
}
export default ProductSearch;

