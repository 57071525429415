
import React, { useEffect, useState } from 'react';
import AppTabs from './AppTabs';
import { Menu, Grid, MenuItem } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import Country from './Country';
import useStore from './store';
import kcLogo from './images/kcc_logo.PNG';
import { useOktaAuth } from '@okta/okta-react';

const Navbar = (props) => {
  const history = useHistory();
  const logo = <img src={kcLogo} width="40" height="30" style={{ display: 'flex', marginTop: 16, marginLeft: 10, justifyContent: 'center', alignSelf: 'center' }} />;
  let selectedCountry = useStore((state) => state.selectedCountry);
  const loginState = useStore((state) => state.loginState);
  const authError = useStore((state) => state.authError);
  const { oktaAuth, authState } = useOktaAuth();
  const [currentUser, setcurrentUser] = useState()
  const setLoginState = useStore((state) => state.setLoginState);
  const setAuthError = useStore((state) => state.setAuthError);

  useEffect(() => {
    const getCurrentUser = async () => {
      if (!authState) return;
      if (authState.error) {
        setLoginState(false)
        setAuthError('')
      } else {
        if (authState?.accessToken && authState?.idToken) {
          const userInfo = await oktaAuth.getUser()
          setcurrentUser(userInfo);
        }
      }
    }
    getCurrentUser()
  }, [authState, oktaAuth]);


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(null);
  };

  const handleLogout = (e) => {
    handleClose(e)
    setLoginState(false)
    setAuthError('')
    oktaAuth.signOut()
  }

  const gotoProfile = (e) => {
    handleClose(e)
    history.push('/profile')
  }

  if (!loginState) {
    if (authError) {
      return <div className="flex justify-left items-center mt-10 gap-5">
        <div>Unauthorized access</div>
        <button className="p-2 border border-primary text-primary" onClick={handleLogout}>Login again</button>
      </div>
    } else {
      return <div className="flex justify-left items-center mt-10 gap-5">
        <div data-testid="loggin-in-message">
          Logging you in, please wait...(If it takes too long to login, please click login again button)
        </div>
        <button
          data-testid="login-again-button"
          className="p-2 border border-primary text-primary"
          onClick={handleLogout}>Login again</button>
      </div>
    }
  }

  return (
    <AppTabs
      tabs={[
        {
          index: 0,
          tag: "Baseline Forecasting",
          route: "/baseline-forcasting",
          id: 'baseline-forcasting'
        },
        {
          index: 1,
          tag: "ROI Simulator",
          route: "/pre-roi",
          id: "pre-roi",
        },
        {
          index: 2,
          tag: "Promo Performance",
          route: "/promo-performace",
          id: "promo-performace",
        },
        {
          index: 3,
          tag: "Price Elasticity",
          route: "/price-elasticity",
          id: "price-elasticity",
        },
      ]}
      logo={logo}
    >
      <Grid container >
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <h3
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: 25,
            }}
          >
            Country:{" "}
            {!selectedCountry && <span style={{ color: "red" }}> *</span>}{" "}
          </h3>
          <Country style={{ justifyContent: "center" }}></Country>
          {
            authState && authState.isAuthenticated && currentUser
              ? <a href style={{
                justifyContent: "center",
                display: "flex",
                marginTop: 25,
              }} onClick={handleClick} className="user_profile">
                {currentUser.name}
                {
                  anchorEl
                    ? <Menu id="accountMenu" anchorEl={anchorEl} keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}>
                      <MenuItem onClick={gotoProfile}>Profile</MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                    : null
                }

              </a>
              : null
          }
        </div>
      </Grid>
    </AppTabs>
  );
}

export default Navbar;