import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PromoProductTable from "./PromoProductTable";
import DashBoardTab from "../SubTabs/DashBoardTab";
import SummaryTab from "../SubTabs/SummaryTab";
import PriceVolumeTab from "../SubTabs/PriceVolumeTab";
import Box from "@material-ui/core/Box";

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    textTransform: "none",
    minWidth: 50,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const AppTabs = ({ tabs, children, logo }) => {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [subValue, setSubValue] = React.useState(0);
  const [subCustomerValue] = React.useState(0);

  useEffect(() => {
    if(subValue === 3 && !tabs.find(x=>x.index === 3)) {
      setSubValue(0)
    }
  }, [tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);   
    const navTab = tabs.filter((tab) => tab.index === newValue);
    history["params"] = navTab[0].tag;    
  };

  const subHandleChange = (event, newValue) => {
    setSubValue(newValue);
    const navTab = tabs.filter((tab) => tab.index === newValue);
    history["params"] = navTab[0].tag;
  };

  let imgLogo = null;
  if (logo !== null) {
    imgLogo = (
      <Container style={{maxWidth:'98%'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={1} style={{ maxWidth: 60 }}>
            {logo}
          </Grid>
          <Grid item xs={12} sm={9}>
            <AntTabs value={tabs[0] !== undefined && tabs[0].name !== undefined ? (tabs[0].name === 'CUSTOMER' ? subCustomerValue : value) : value} onChange={handleChange}>
              {tabs.map((tab) => (
                <AntTab key={tab.tag} label={tab.tag} id={`tab-${tab.id}`} name={tab.name}/>
              ))}
            </AntTabs>
          </Grid>
          <Grid item xs={12} sm={2}>
            {children}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    imgLogo = (
      <Container style={{maxWidth:'98%'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10}>
            <AntTabs value={subValue} onChange={subHandleChange}>
              {tabs.map((tab) => (
                <AntTab key={tab.tag} label={tab.tag} id={`tab-${tab.id}`}/>
              ))}
            </AntTabs>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
        {tabs.map((tab, index) => (
          <TabPanel value={subValue} index={index} key={tab.value}>
            {tab.value === "Summary" && <SummaryTab />}
            {tab.value === "Dashboard" && <DashBoardTab />}
            {tab.value === "PriceVolume" && <PriceVolumeTab />}
            {tab.value === "Promo" && <PromoProductTable dataFor="PROMOTION" />}
            {tab.value === "PromoProduct" && <PromoProductTable dataFor="PROMO_PRODUCT" />}
            {tab.value === "Product" && <PromoProductTable dataFor="PRODUCT" />}
            {tab.value === "ProductCustomer" && <PromoProductTable dataFor="PRODUCT_CUSTOMER" />}
            
          </TabPanel>
        ))}
        
      </Container>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>{imgLogo}</div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles({tabContent:{
    maxWidth:'100%',
    maxHeight:'500px',
    overflow:'scroll'
  }});

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      key={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0.5} className={classes.tabContent}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default withRouter(AppTabs);
