import { useEffect } from 'react';
import { Grid, Box, MenuItem, Stack, TextField } from "@mui/material"
import { FormControl, FormControlLabel, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import { useState } from "react";

import useStore from "../../../../store";
import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import { validateInput } from "../utils/validateInput";
import { mockDropdownApiResposne } from "../Constants/mockDropdownApiResposne/mockDropdownApiResposne";

export const FixedCostInput = ({ id, promoData }: any) => {
  const classes = useStyles();
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const [fixedCost, setFixedCost] = useState(promoSimulatorTabs[0].fixedCost);

  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);

  useEffect(() => {
    if (promoData && selectedPromoDetails.fromView) {
      setFixedCost(promoData.fixedCosts);
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, fixedCost: promoData.fixedCosts, reset: false } : tab))
    }
  }, [selectedPromoDetails, promoData])

  const changeFixedCost = (e: any) => {
    setFixedCost(e.target.value)
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, fixedCost: e.target.value } : tab));
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Box sx={{ fontWeight: '500', m: 1, fontSize: '0.75rem' }}>Fixed Cost<span className="text-red-900 font-bold text-base">*</span></Box></Grid>
        <Grid item xs={2.5}>
          <FormControl required
            onChange={changeFixedCost}
            id="kpiType">
            <RadioGroup className="flex mb-1 fixed-radio " row={true}>
              <FormControlLabel disabled={promoSimulatorTabs[id].calculateButtonClicked} checked={fixedCost === "Y"} value="Y" control={<Radio color='primary' />} label="Include" className={classes.radioitem} />
              <FormControlLabel disabled={promoSimulatorTabs[id].calculateButtonClicked} checked={fixedCost === "N"} value="N" control={<Radio color='primary' />} label="Exclude" className={classes.radioitem} />
            </RadioGroup>
          </FormControl>

        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  fieldWidth: {
    width: '228px',
    '& input': {
      fontSize: '0.75rem',
    },
    '& div': {
      fontSize: '0.75rem !important',
    }
  },
  radioitem: {
    marginLeft: '5px !important',
    marginBottom: "10px",
    marginTop: "-5px",
    marginRight: "2px !important",
    fontSize: '11px !important'
  }
}));
