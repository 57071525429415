import { createTheme } from '@material-ui/core/styles'
const theme = createTheme({
  palette: {
    primary: {
      light: '#fafafa',
      main: '#0000b3',
      dark:'black'
    },
    background: {
      paper: '#F6F8FC'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    htmlFontSize:16,
    fontWeightLight:300,
    fontWeightRegular:400,
    fontWeightMedium:500,
    fontWeightBold:700,
    tableCell: {
      fontSize: `1.5rem`,
      fontFamily: ['sans-serif'].join(','),
      fontWeightBold:700,
    }
  },
  spacing: factor => `${0.25 * factor}rem`,

})
export default theme