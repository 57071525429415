import { useEffect, useState,useMemo } from 'react';
import useStore from '../../../store';
import Table from "@material-ui/core/Table";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styles from "./ProductTableSummary.module.css";
import { useProductSummary } from '../../../services/queries';
import CurrencyFormat from 'react-currency-format';
import Collapse from '@material-ui/core/Collapse';

export default function ProductTableSummary(props) {
    const [tableData, setTableData] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('levelLabel');

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    let selectedCountry = useStore((state) => state.selectedCountry);
    let selectedPromotionalItem = useStore(
        (state) => state.selectedPromotionalItem
    );
    let customersStateData = useStore(
        (state) => state.customersStateData
    );
    let promotionalsStateData = useStore(
        (state) => state.promotionalsStateData
    );
    let productsStateData = useStore(
        (state) => state.productsStateData
    );

    const selecteYeardWeeks = useStore((state) => state.selectedYearWeeks);
    let groupBy = useStore((state) => state.groupBy);

    const fixedCostInclude = useStore((state) => state.fixedCostInclude);
    const [payload, setPayload] = useState({
        "dataFor": props.dataFor,
        "brands": [],
        "categories": [],
        "channels": [],
        "country": "KR",
        "customers": [],
        "eans": [],
        "includeFixedCosts": fixedCostInclude,
        "packs": [],
        "planningCustomers": [],
        "salesOffice": [],
        "sectors": [],
        "subBrands": [],
        "subCategories": [],
        "yearWeeks": [],
        "promoIds": [],
        "promoTypes": [],
    });

    //calling api POST /useProductSummary
    const { isLoading: isProductSummaryData, data: productSummaryData } = useProductSummary(payload);
    useEffect(() => {
        if (!isProductSummaryData && productSummaryData) {
            setTableData([]);
            if (productSummaryData.data.length > 3000) {
                let sliceData = productSummaryData;
                sliceData.data = sliceData.data.slice(0, 2000);
                setTableData(sliceData);
            } else {
                setTableData(productSummaryData);
            }
            //handleRequestSort('', "ASC");
        }
    }, [isProductSummaryData, groupBy, productSummaryData]);
    useEffect(() => {
        let selectedValue = {
            country: selectedCountry,
            yearWeeks: selecteYeardWeeks,  //202244 
            "includeFixedCosts": fixedCostInclude,
        }
        let customer = getCustomerData();
        let product = getProductData();
        let promo = gePromoData();
        selectedValue = { ...payload, ...selectedValue, ...customer.data, ...product.data, ...promo.data };
        setPayload(selectedValue)


    }, [selectedCountry, selecteYeardWeeks, customersStateData, fixedCostInclude, productsStateData, promotionalsStateData, selectedPromotionalItem]);

    function putAll(x) {
        return x.name;
    }
    function isSelected(x) {
        return x&&x.isChecked;
    }

    function getCustomerData() {
        let selectedArr = [];
        let customerPayLoad = {};
        let formatedPayLoad = {};
        const arr = [
            "channel",
            "salesOffice",
            "planningCustomer",
            "customers"
        ];
        let values = { ...customersStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            customerPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                        customerPayLoad[level].push(val);
                        selectedArr.push(val);
                    });
                }
            }
        }
        formatedPayLoad['customers'] = customerPayLoad["customers"];
        formatedPayLoad['channels'] = customerPayLoad["channel"];
        formatedPayLoad['salesOffice'] = customerPayLoad["salesOffice"];
        formatedPayLoad["planningCustomers"] = customerPayLoad["planningCustomer"];
        return { data: formatedPayLoad };
    }

    function getProductData() {
        let selectedArr = [];
        let productPayLoad = {};
        let formatedPayLoad = {};
        const arr = [
            "brand",
            "category",
            "eans",
            "sector",
            "subBrand",
            "subCategory",
        ];
        let values = { ...productsStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            productPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                        productPayLoad[level].push(val);
                        selectedArr.push(val);
                    });
                }
            }
        }

        formatedPayLoad.eans = [];
        if (productPayLoad["eans"].length > 0) {
            productPayLoad["eans"].forEach(element => {
                let eans = element.split("-");
                if (eans.length > 0) {
                    formatedPayLoad.eans.push(eans[eans.length - 1].trim())
                }
            });
        }
        formatedPayLoad.sectors = productPayLoad["sector"];
        formatedPayLoad.categories = productPayLoad["category"];
        formatedPayLoad.subBrands = productPayLoad["subBrand"];
        formatedPayLoad.brands = productPayLoad["brand"];
        formatedPayLoad.subCategories = productPayLoad["subCategory"];
        //formatedPayLoad.eans = productPayLoad["eans"];
        return { data: formatedPayLoad };
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === 'levelLabel' && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy('levelLabel');
    };
    function stableSort(array, comparator) {
        array.sort((a, b) => {
            const order = comparator(a.sector, b.sector);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        array.map((categories)=>{
        if(categories.categories){
            categories.categories.sort((a, b) => {
            const order = comparator(a, b);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        }
    })
        return array
    }
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    const visibleRows = useMemo(
        () =>
            stableSort(tableData && tableData.data && tableData.data.length > 0 ? tableData.data : [], getComparator(order, orderBy)),
        [tableData,order, orderBy],
    );
    
    function gePromoData() {
        let selectedArr = [];
        let customerPayLoad = {};
        let formatedPayLoad = { promoTypes: [], promoIds: [] };
        const arr = [
            "promoType",
            "promoIds",
        ];
        let values = { ...promotionalsStateData };
        //iterating backword
        for (let i = arr.length - 1; i >= 0; i--) {
            let level = arr[i];
            customerPayLoad[level] = [];
            if (values && values[level]) {
                let selectedNodes = values[level].filter(isSelected).map(putAll);
                if (selectedNodes.length !== 0) {
                    selectedNodes.forEach((val) => {
                        customerPayLoad[level].push(val.trim());
                        selectedArr.push(val.trim());
                    });
                }
            }
        }

        formatedPayLoad.promoIds = customerPayLoad["promoIds"];
        formatedPayLoad.promoTypes = customerPayLoad["promoType"];
        return { data: formatedPayLoad };
    }

    return (
        <div>
            <TableContainer style={{ maxHeight: "600px" }} >
                
                    <Table stickyHeader aria-label="Product table" data-testid="PromoProductsGrid" className={styles.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={styles.cellDataTitle}>Sector/Category <TableSortLabel
                                active={orderBy === 'levelLabel'}
                                direction={orderBy === 'levelLabel' ? order : 'asc'}
                                onClick={createSortHandler('levelLabel')}
                            > </TableSortLabel></TableCell>
                                <TableCell className={styles.cellDataKey} align="center" colSpan={4}>Key Metrics</TableCell>
                                <TableCell className={styles.cellDataPromotions} align="center" colSpan={4}>Promotions</TableCell>
                                <TableCell className={styles.cellDataRIR} align="center" colSpan={4}>RIR Spend</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            <TableRow >
                                <TableCell className={styles.headerCellTitle}></TableCell>
                                <TableCell className={styles.headerCell}>Incr RIR (₩)</TableCell>
                                <TableCell className={styles.headerCell}>Incr NSV (₩)</TableCell>
                                <TableCell className={styles.headerCell}>Incr GP (₩)</TableCell>
                                <TableCell className={styles.headerCell}>GP ROI </TableCell>
                                <TableCell className={styles.headerCellMid}>Number of Promotions</TableCell>
                                <TableCell className={styles.headerCellMid}>Positive Incr GP %</TableCell>
                                <TableCell className={styles.headerCellMid}>Negative Incr GP %</TableCell>
                                <TableCell className={styles.headerCellMid}>Negative GP %</TableCell>
                                <TableCell className={styles.headerCell}>Total RIR Spend (₩)</TableCell>
                                <TableCell className={styles.headerCell}>Positive Incr GP %</TableCell>
                                <TableCell className={styles.headerCell}>Negative Incr GP %</TableCell>
                                <TableCell className={styles.headerCell}>Negative GP %</TableCell>
                            </TableRow>
                            {visibleRows && visibleRows && visibleRows.length > 0 ? (
                                <>
                            {(tableData.totals !== null &&                           
                                <TableRow>
                                    <TableCell className={styles.totalTitleCell}>{tableData.totals.levelLabel}</TableCell>
                                    <TableCell className={styles.totalCell}> <CurrencyFormat value={tableData.totals.incrementalRIR} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.totalCell}> <CurrencyFormat value={tableData.totals.incrementalNSV} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.totalCell}> <CurrencyFormat value={tableData.totals.incrementalGP} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.totalCell}> <CurrencyFormat value={tableData.totals.gpROI} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.totalCell}> <CurrencyFormat value={tableData.totals.numberOfPromotions} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.totalCell}>{tableData.totals.positiveIncrementalGP}</TableCell>
                                    <TableCell className={styles.totalCell}>{tableData.totals.negativeIncrementalGP}</TableCell>
                                    <TableCell className={styles.totalCell}>{tableData.totals.negativeGP}</TableCell>
                                    <TableCell className={styles.totalCell}> <CurrencyFormat value={tableData.totals.totalRIRSpend} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.totalCell} >{tableData.totals.positiveIncrementalGPRIR}</TableCell>
                                    <TableCell className={styles.totalCell}>{tableData.totals.negativeIncrementalGPRIR}</TableCell>
                                    <TableCell className={styles.totalCell}>{tableData.totals.negativeGPRIR}</TableCell>
                                </TableRow>
                            )}
                            <>
                                {visibleRows.map((item) => (
                                    <RanderRow item={item} />
                                ))}
                            </>
                            </>
                        ):(
                            <TableRow>
                            <TableCell className={styles.cellWithNoData} colSpan={13}><center>No data with selected filters</center></TableCell>
                        </TableRow>
                        )}  

                        </TableBody>
                    </Table>
                
            </TableContainer>
        </div>
    )
}
function RanderRow(props) {
    const { item } = props;
    const [open, setOpen] = useState(false);
    return (

        <>
            <>
                {item.sector &&
                    <TableRow>
                        <TableCell className={styles.titleCell}>{item.sector && <span className={styles.expandBtn}  onClick={() => setOpen(!open)}>
                            {open ? '-' : "+"}
                        </span>}<span className={styles.titleLabel}>{item.sector.levelLabel}</span></TableCell>
                        <TableCell className={styles.cell}> <CurrencyFormat value={item.sector.incrementalRIR} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell className={styles.cell}> <CurrencyFormat value={item.sector.incrementalNSV} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell className={styles.cell}> <CurrencyFormat value={item.sector.incrementalGP} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell className={styles.cell}> <CurrencyFormat value={item.sector.gpROI} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell className={styles.midCell}> <CurrencyFormat value={item.sector.numberOfPromotions} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell className={styles.midCell}>{item.sector.positiveIncrementalGP}</TableCell>
                        <TableCell className={styles.midCell}>{item.sector.negativeIncrementalGP}</TableCell>
                        <TableCell className={styles.midCell}>{item.sector.negativeGP}</TableCell>
                        <TableCell className={styles.cell}> <CurrencyFormat value={item.sector.totalRIRSpend} displayType={'text'} thousandSeparator={true} /></TableCell>
                        <TableCell className={styles.cell} >{item.sector.positiveIncrementalGPRIR}</TableCell>
                        <TableCell className={styles.cell}>{item.sector.negativeIncrementalGPRIR}</TableCell>
                        <TableCell className={styles.cell}>{item.sector.negativeGPRIR}</TableCell>
                    </TableRow>
                }
            </>
            <> <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={13}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {item.categories && (
                            item.categories.map((cat) =>
                            <Table className={styles.table}>
                                <TableRow>
                                    <TableCell className={styles.childTitleCell} > {cat.levelLabel}</TableCell>
                                    <TableCell className={styles.childCell}> <CurrencyFormat value={cat.incrementalRIR} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.childCell}> <CurrencyFormat value={cat.incrementalNSV} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.childCell}> <CurrencyFormat value={cat.incrementalGP} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.childCell}> <CurrencyFormat value={cat.gpROI} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.childMidCell}> <CurrencyFormat value={cat.numberOfPromotions} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.childMidCell} >{cat.positiveIncrementalGP}</TableCell>
                                    <TableCell className={styles.childMidCell}>{cat.negativeIncrementalGP}</TableCell>
                                    <TableCell className={styles.childMidCell}>{cat.negativeGP}</TableCell>
                                    <TableCell className={styles.childCell}><CurrencyFormat value={cat.totalRIRSpend} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell className={styles.childCell} >{cat.positiveIncrementalGPRIR}</TableCell>
                                    <TableCell className={styles.childCell}>{cat.negativeIncrementalGPRIR}</TableCell>
                                    <TableCell className={styles.childCell}>{cat.negativeGPRIR}</TableCell>
                                </TableRow>
                                </Table>
                            )
                        )
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
            </>

        </>

    )
}