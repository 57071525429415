/* eslint-disable @typescript-eslint/no-unused-expressions */
import { TableColumn } from "react-data-table-component";
import { ElasticityRecord, ElasticityScopeRecord, SelectedProduct } from "./types";
import { isNil, isEmpty, forEach } from "lodash";

export const parseOwnAnalysisData = (selectedProducts: SelectedProduct, result: any,category:string) => {
    if (selectedProducts.selectedOwnArr && result.length > 0) {
        const parsed: ElasticityRecord[] = []
        result.forEach((obj: any) => {
            const newObj: ElasticityRecord = {
                initial: obj.initial,
                subCategoryInitial: obj.subCategoryInitial,
                subCategoryTarget: obj.subCategoryTarget,
                manufacturerInitial: obj.manufacturerInitial,
                manufacturerTarget: obj.manufacturerTarget,
                brandInitial: obj.brandInitial,
                brandTarget: obj.brandTarget,
                subBrandInitial: obj.subBrandInitial,
                subBrandTarget: obj.subBrandInitial,
                packInitial: obj.packInitial,
                packTarget: obj.packTarget,
                priceIndex: obj.priceIndex,
                range: obj.range,
                rsquared: obj.rsquared,
                averagePrice: obj.averagePrice,
                promoPrice: obj.promoPrice,
                basePrice: obj.basePrice,
                viewMore: obj.viewMore,
                modelRun: obj.modelRun,
                periodBegin:  obj.periodBegin,
                periodEnd:  obj.periodEnd,
                nweeks:  obj.nweeks,
                pvalue: obj.pvalue,
                averageVolume: obj.averageVolume,
                category: category,
                id: obj.id,
            };
            parsed.push(newObj)
        });
        return parsed;
    }
    return []
}


export const parseCrossAnalysisData = ( response: any, scope?:string) => {
    if (response ){
        const results: ElasticityRecord[]  = []
        for (const result of response) {
            const record: ElasticityRecord = {
                initial:result.initial,
                target:result.target,
                product: result.subBrand,
                channelInitial: result.channelInitial,
                channelTarget: result.channelTarget,
                customerInitial: result.customerInitial,
                customerTarget: result.customerTarget,
                customer: scope,
                category: result.category,
                subCategoryInitial: result.subCategoryInitial,
                subCategoryTarget: result.subCategoryTarget,
                manufacturerInitial: result.manufacturerInitial,
                manufacturerTarget: result.manufacturerTarget,
                brandInitial: result.brandInitial,
                brandTarget: result.brandTarget,
                subBrandInitial: result.subBrandInitial,
                subBrandTarget: result.subBrandTarget,
                packInitial: result.packInitial,
                packTarget: result.packTarget,
                averagePrice : result.averagePrice,
                elasticity : result.elasticity,
                range : result.range,
                rsquared : result.rsquared,
                basePrice : result.basePrice,
                promoPrice : result.promoPrice,
                priceIndex : result.priceIndex,
                viewMore : result.viewMore,
                modelRun: result.modelRun,
                periodBegin:  result.periodBegin,
                periodEnd:  result.periodEnd,
                nweeks:  result.nweeks,
                pvalue: result.pvalue,
                averageVolume: result.averageVolume,
                id: result.id,
            }
            results.push(record)
        }
        return results
    
    }
    return []
}

export const sortScopeData = (data: ElasticityScopeRecord[] ,field:  TableColumn<ElasticityRecord>, direction: string) => {
    const sorted  = data.map(customerData => {
        const cloned = [...customerData.data]
        cloned.sort((rowA, rowB) => {
            if (field.selector){
                let aField = rowA[ field.sortField as keyof ElasticityRecord]
                let bField = rowB[ field.sortField as keyof ElasticityRecord]
                if (!aField){
                    if (field.sortField === 'priceIndex' || field.sortField === 'basePrice' 
                        || field.sortField === 'averagePrice' || field.sortField === 'promoPrice'
                        || field.sortField === 'rsquared' ){
                            aField = -1000
                        }else{
                            aField = ''
                        }
                }
                if (!bField){
                    if (field.sortField === 'priceIndex' || field.sortField === 'basePrice' 
                    || field.sortField === 'averagePrice' || field.sortField === 'promoPrice'
                    || field.sortField === 'rsquared' ){
                        bField = -1000
                    }else{
                        bField = ''
                    }
                }

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }

                return direction === 'desc' ? comparison * -1 : comparison;
            }
            return 0

        })
        customerData.data = cloned
        return customerData
    })
    return sorted
}

export const PowerBITableMapWithProductlevel = {
    'EAN' : [
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_MS',
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_SIM'
    ],
    'SUB_BRAND' : [
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_MS_SUB_BRAND',
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_SIM_SUB_BRAND'
    ],
    'BRAND': [
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_MS_BRAND',
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_SIM_BRAND'
    ],
    'SUB_CATEGORY': [
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_MS_SUB_CATEGORY',
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_SIM_SUB_CATEGORY'
    ],
    'PACK': [
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_MS_PACK',
        'V_RGM_SLS_FCT_DATA_PROFIT_PARABOLA_SIM_PACK'
    ]
}


export const getHierarchyLevel = (selectedProductLevel: string) => {
    let productLevelParameter = ''
    switch (selectedProductLevel) {
        case "category":
          productLevelParameter = "CATEGORY";
          break;
        case "subCategory":
          productLevelParameter = "SUB_CATEGORY";
          break;
        case "brand":
          productLevelParameter = "BRAND";
          break;
        case "subBrand":
          productLevelParameter = "SUB_BRAND";
          break;
        case "tier":
          productLevelParameter = "TIER";
          break; 
        case "pack":
          productLevelParameter = "PACK";
          break;
        default:
          productLevelParameter = "EAN";
          break;
    }
    return productLevelParameter
}

export const getCrossURLParams = (initialNodes: string[], targetNodes: string[], category:string, hierarchy: string, country: string, source: string, scope: string, subCategoryInitial: string[],subCategoryTarget: string[],manufacturerInitial: string[], manufacturerTarget: string[], brandInitial: string[], brandTarget: string[], ) => {
    if (initialNodes.length > 0 &&targetNodes.length > 0){
        const params = new URLSearchParams({
            country: country.toUpperCase(),
            hierarchyLevel: hierarchy.toUpperCase(),
        })
        if (source){
            params.set('source', source.toUpperCase())
        }
        if(category) {
            params.append('category', category)
        }
        for (const _initial of initialNodes) {
            params.append('initialNodeValues', _initial)
        }

        for (const _target of targetNodes) {
            params.append('targetNodeValues', _target)
        }

        if (scope !== 'COUNTRY'){
            params.set('scope',scope?.toUpperCase())
        }

        for (const _initial of subCategoryInitial) {
            if(_initial!=="null")
                params.append('initialSubCategories', _initial)
        }

        if(manufacturerInitial[0]!=="null") {
            for (const _initial of manufacturerInitial) {
               
                    params.append('initialManufacturers', _initial)}
            
        }
        
        if(manufacturerTarget[0]!=="null") {
            for (const _initial of manufacturerTarget) {
                
                    params.append('targetManufacturers', _initial)
                }
            
        }
       
        if(brandInitial[0]!=="null") {
            for (const _initial of brandInitial) {
                    params.append('initialBrands', _initial)}
        }
        if(brandTarget[0]!=="null") {
            for (const _initial of brandTarget) {
                if(!_initial!== null) {
                    params.append('targetBrands', _initial)}
            }
        }
       
        
        return params.toString()
    }
    return null
  
}

export const getCrossViewMoreURL = (country: string, initial: string, target: string, productLevel: string, source: string, scope: string, category: string, subCategoryInitial: string,subCategoryTarget: string,manufacturerInitial: string, manufacturerTarget: string, brandInitial: string, brandTarget: string, customer?: string, weekRange?: any) => {
    
    const params = new URLSearchParams({
        country: country
    })
    params.append('initial', initial)
    params.append('target', target)
    params.append('productLevel', productLevel)
    params.append('source', source)
    params.append('scope', scope)
    params.append('category', category)
    params.append('subCategoryInitial', subCategoryInitial)
    params.append('subCategoryTarget', subCategoryTarget)
    params.append('manufacturerInitial',manufacturerInitial)
    params.append('manufacturerTarget', manufacturerTarget)
    params.append('brandInitial', brandInitial)
    params.append('brandTarget', brandTarget)
    if(customer) {
        params.append('customer', customer)
    }
    if(weekRange) {
        params.append('weekRange', JSON.stringify(weekRange))
    }
    
    return params.toString()
}

export const getCrossViewMoreData = (id: any, country: string, initial: string, target: string, productLevel: string, source: string, scope: string, category: string, subCategoryInitial: string,subCategoryTarget: string,manufacturerInitial: string, manufacturerTarget: string, brandInitial: string, brandTarget: string, subBrandInitial?: string,subBrandTarget?: string,packInitial?: string,packTarget?: string, customer?: string, subCategory?: any, weekRange?: any) => {
    const params: any = {
        'id': id,
        'country': country,
        'initial': [initial],
        'target': [target],
        'productLevel': productLevel,
        'source': source,
        'scope': scope,
        'category': category,
        'subCategoryInitial': subCategoryInitial,
        'subCategoryTarget': subCategoryTarget,
        'manufacturerInitial': manufacturerInitial,
        'manufacturerTarget': manufacturerTarget,
        'brandInitial': brandInitial,
        'brandTarget': brandTarget,
        'subBrandInitial': subBrandInitial,
        'subBrandTarget': subBrandTarget,
        'packInitial': packInitial,
        'packTarget': packTarget,
        'subCategory': subCategory,
        'customer': customer ? customer : null,
        'weekRange': weekRange ? [weekRange] : [],
    }
    return params;
}

export const getOwnViewMoreURL = (country: string, initial: string, category?: string, productLevel?: string, source?:string, scope?: string, customer?: string, weekRange?: any) => {
    const params = new URLSearchParams({
        country: country
    })
    if(category) {
        params.append('category',category);
    }
    
    params.append('initial', initial)
    if(productLevel) {
        params.append('productLevel', productLevel)
    }
    if(source) {
        params.append('source', source)
    }
    if(scope) {
        params.append('scope', scope)
    }
   
    if(customer) {
        params.append('customer', customer)
    }
    if(weekRange) {
        params.append('weekRange', JSON.stringify(weekRange))
    }
    return params.toString()
}

export const getOwnViewMoreData = (id: any, country: string, initial: string, productLevel: string, source: string, scope: string, category: string, subCategoryInitial: string,subCategoryTarget: string,manufacturerInitial: string, manufacturerTarget: string, brandInitial: string, brandTarget: string, subBrandInitial: string,subBrandTarget: string,packInitial: string,packTarget: string, customer?: string, subCategory?: any, weekRange?: any) => {
    const params: any = {
        'id': id,
        'country': country,
        'initial': initial,
        'productLevel': productLevel,
        'source': source,
        'scope': scope,
        'category': category,
        'subCategoryInitial': subCategoryInitial !== null ? [subCategoryInitial] : null,
        'subCategoryTarget': null,
        'manufacturerInitial': manufacturerInitial !== null ? [manufacturerInitial] : null,
        'manufacturerTarget': null,
        'brandInitial': brandInitial !== null ? [brandInitial] : null,
        'brandTarget': null,
        'subBrandInitial': subBrandInitial !== null ? [subBrandInitial] : null,
        'subBrandTarget': null,
        'packInitial': packInitial !== null ? [packInitial] : null,
        'packTarget': null,
        'subCategory': subCategory,
        'customer': customer ? customer : null,
        'weekRange': weekRange ? [weekRange] : [],
    }
    return params;
}

export const getOwnURLParams = (initialNodes: string[], category: string, hierarchy: string, country: string, source?: string, scope?: string) => {

    const params = new URLSearchParams({
        country: country
    })
    if(initialNodes && initialNodes?.length>0) {  
        for(const _initial of initialNodes) {
            params.append('nodeValues',_initial)
        }  
    }
    if(category)
        params.append('category',category);
        
    params.append('hierarchyLevel',hierarchy);
    if(source) {
        params.append('source',source);
    }
    if(scope) {
        params.append('scope',scope);
    }
    if(hierarchy === 'CATEGORY' && !category) {
        return ''
    }
    return params.toString()
}

export const productLevelsOptionData = {
    subCategory: {
      label: "SUB_CATEGORY",
      display: "Sub Category",
    },
    brand: {
      label: "BRAND",
      display: "Brand",
    },
    subBrand: {
      label: "SUB_BRAND",
      display: 'Sub Brand',
    },
    tier: {
      label: "TIER",
      display: 'TIER',
    },
    pack: {
      label: "PACK",
      display: 'Pack',
    },
    ean: {
      label: "EAN",
      display: 'EAN',
    },
  };

  export const productLevelKROptionData = {
    category: {
      label: "CATEGORY",
      display: "Category",
    },
    subBrand: {
      label: "SUB_BRAND",
      display: 'Sub Brand',
    },
    ean: {
      label: "EAN",
      display: 'EAN',
    },
  };

export const convertDateRange = (range:string) => {
    if(!range) return "";
    let monthNames =["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
    let startDate = new Date(range);

    let stDay = startDate.getDate();
    let stMonthIndex = startDate.getMonth();
    let stMonthName = monthNames[stMonthIndex];
    let stYear = startDate.getFullYear();

    return `${stDay} ${stMonthName} ${stYear}`;
}

export const getVolumeFieldName = (country: string, isTarget = false) => {
    if (country === 'KR'){
        if (!isTarget) {
            return 'Average Volume SU'
        }else {
            return 'Volume SU Target'
        }
    }else if(country === 'PE'){
        if (isTarget){
            return 'Market Share'
        }
        return 'Average Volume'
    }else{
        return  'Average Volume'
    }
}

export const paginationPerPageDataLimit = 5;

export const scopeSourceDetails = [
    {scope : "CUSTOMER", source : "POS"},
    {scope : "WC", source : "NIELSEN"},
    {scope : "PCUST", source : "CDMS"}
]

export const scopeAndSourceDetails = [
    {scope : "CHANNEL", source : "POS"},
    {scope : "CUSTOMER", source : "POS"},
    {scope : "WC", source : "NIELSEN"},
    {scope : "PCUST", source : "CDMS"}
]

export const getSelectedWeekRange: any = (selectedDataPoints: any) => {
    let weekRange: any = {}
    // Get week ranges for each year
    selectedDataPoints.forEach((data: any) => {
        const {year, week} = data;
        if(!weekRange[year])
            weekRange[year] = []
        if(!weekRange[year].includes(week))
            weekRange[year].push(week)
    })
    // Sort weeks
    for (let x in weekRange) {
        weekRange[x].sort((a: any,b: any) => { return a - b })
    }
    return weekRange;
}

export const getDropDownTreeData = (country: string, lineData: any, selectedWeeks?: any) => {
    let treeNodes = [];
    const treeNodesData: any[] = []
    for(const key in lineData) {
        let arr = [];
        if(lineData.hasOwnProperty(key) && key !== '') {
        let val = lineData[key];
        // Sorting weeks
        val.sort((a: any, b: any) => { return a.week - b.week; });
        // Removing duplicate weeks
        let uniqueWeeks: any = Array.from(new Set(val.map(JSON.stringify)))
        val = uniqueWeeks.map(JSON.parse);
        let months: any = {};
        for(const obj of val) {
            let isChecked = false;
            if(months[obj['month']] && months[obj['month']] !== undefined) {
                if(selectedWeeks !== undefined && selectedWeeks[key]!==undefined && selectedWeeks[key].includes(obj['week'])){
                isChecked = true;
                }
                months[obj['month']].push({"label" : obj['weekRange'], "week" : obj['week'], "year" : obj['year'], "checked": isChecked});
            } else {
                if(selectedWeeks !== undefined && selectedWeeks[key]!==undefined && selectedWeeks[key].includes(obj['week'])){
                    isChecked = true;
                }
                months[obj['month']] = [{"label" : obj['weekRange'], "week" : obj['week'], "year" : obj['year'], "checked": isChecked}];
            }
            arr.push(months);
        }              
        }
        let node: any = {};
        node['label'] = key;
        //Removing duplicate labels
        node["children"] = arr.filter(
        (value, index, arr) =>
            index === arr.findIndex((t) => t.label === value.label )
        );
        treeNodes.push(node);
    }
    treeNodes.forEach(data => {
        const nodes: any = {};
        nodes["label"] = data.label;
        const childNodes = []
        let isYearChecked = false;
        let monthCounter = 0;
        if(data.children[0] !== undefined) {
        for(const [key, value] of Object.entries(data.children[0])){
            let childVal: any = value;
            let isMonthChecked = false;
            let weekCounter = 0
            childVal.forEach((val: any) => {
            if(val.checked) weekCounter++;
            })
            if (weekCounter!=0 && weekCounter==childVal.length) {
            isMonthChecked = true;
            monthCounter++;
            }
            childNodes.push({"label" : key, "children" : value, "checked": isMonthChecked});
        }
        }
        nodes["children"] = childNodes;
        if(monthCounter!=0 && monthCounter==Object.keys(data.children[0]).length) 
        isYearChecked = true;
        nodes["checked"] = isYearChecked;
        treeNodesData.push(nodes);
    })
    return treeNodesData;
}

export const isSelected = (x: any) =>  {
    return x.isChecked;
}

export const putAll = (x: any) => {
    return x.name;
}

export const getProductSelectorHierarchy = (hierarchy: any, selectedHierarchy: any, depth: number, selectedSubCategoryLevel: any, selectedProductLevel: any) => {
    let manufacturers: any[] = [];
    let brands: any[] = [];
    let subBrands: any[] = [];
    let tiers: any[] = [];
    let packs: any[] = [];
    let eans: any[] = [];
    let subcategories: any[] = [];

    let selectedManufacturer = selectedHierarchy['manufacturer']?.filter(isSelected);
    let selectedBrand = selectedHierarchy['brand']?.filter(isSelected);
    let selectedSubBrand = selectedHierarchy['subBrand']?.filter(isSelected);
    let selectedTier = selectedHierarchy['tier']?.filter(isSelected);
    let selectedPack = selectedHierarchy['pack']?.filter(isSelected);

    let selectedSubCategory =
      selectedSubCategoryLevel.length === 0
          ? selectedHierarchy['subCategory'].map(putAll)
          : selectedSubCategoryLevel;

    if(depth>=2){
    selectedManufacturer =
      selectedManufacturer.length === 0
        ? selectedHierarchy['manufacturer'].map(putAll)
        : selectedManufacturer.map(putAll);
    }
    if(depth>=3){
    selectedBrand =
      selectedBrand.length === 0
        ? selectedHierarchy['brand'].map(putAll)
        : selectedBrand.map(putAll);
    }
    if(depth>=4){
    selectedSubBrand =
      selectedSubBrand.length === 0
        ? selectedHierarchy['subBrand'].map(putAll)
        : selectedSubBrand.map(putAll);
    }
    if(depth>=5){
      selectedTier =
        selectedTier.length === 0
          ? selectedHierarchy['tier'].map(putAll)
          : selectedTier.map(putAll);
      }
    if(depth>=6){
      selectedPack =
        selectedPack.length === 0
          ? selectedHierarchy['pack'].map(putAll)
          : selectedPack.map(putAll);
      }

    if(selectedProductLevel === 'subCategory'){
        forEach(hierarchy, ([key, subcategory]: any) => {
            if (subcategory !== null) {
                depth <=2 ? manufacturers.push({ name: key, isChecked: selectedHierarchy['manufacturer'].some((p: any)=> p.name === key && p.isChecked)? true:false }): '';
                (depth <=0  ? manufacturers.map(putAll) : selectedManufacturer).map(function(m: any) {
                    if(key === m && !isEmpty(subcategory)) {
                        manufacturers.map(putAll)
                        if(isEmpty(selectedSubCategoryLevel)) {
                            forEach(subcategory.subCategories, (subCat:any) => {
                                subCat ? subcategories.push({ name: subCat, isChecked: selectedHierarchy['subCategory'].some((p: any) => p.name === subCat && p.isChecked)? true:false }) : [];
                                (depth === 1 ? subcategories.map(putAll) : selectedSubCategory)
                            })
                        } else {
                            forEach(subcategory.subCategories, (subCat:any) => {
                                selectedSubCategoryLevel.includes(subCat) ? subcategories.push({ name: subCat, isChecked: selectedHierarchy['subCategory'].some((p: any) => p.name === subCat && p.isChecked)? true:false }) : [];
                                (depth === 1 ? subcategories.map(putAll) : selectedSubCategory)
                            })
                        }
                    }
                })
            }
        })
    } else {
        hierarchy.map(([key, subcategory]: any) =>
          selectedSubCategory.map(function(c: any) {
              if (key === c) {
                if(subcategory !== null){
                  Object.entries(subcategory.manufacturers).map(
                    ([key3, val3]: any) => (
                    depth<=1?manufacturers.push({ name: key3, isChecked: selectedHierarchy['manufacturer'].some((p: any)=> p.name === key3 && p.isChecked)? true:false }):"",
                    (depth<=1?manufacturers.map(putAll):selectedManufacturer).map(function(m: any) {
                        if (key3 === m && val3 && val3.brands) {
                            Object.entries(val3.brands).map(([key4, val4]: any) => (
                                depth<=2?brands.push({ name: key4, isChecked: selectedHierarchy['brand'].some((p: any) => p.name === key4 && p.isChecked)? true:false }):"",
                                (depth<=2?brands.map(putAll):selectedBrand).map(function(b: any) {
                                    if (key4 === b && val4 && val4.subBrands) {
                                        Object.entries(val4.subBrands).map(
                                            ([key5, val5]: any) => (
                                            depth<=3?subBrands.push({ name: key5, isChecked: selectedHierarchy['subBrand'].some((p: any) => p.name === key5 && p.isChecked)? true:false }):"",
                                            (depth<=3?subBrands.map(putAll):selectedSubBrand).map(function(sb: any) {
                                                if (key5 === sb && val5 && val5.tiers) {
                                                    Object.entries(val5.tiers).map(
                                                        ([key6, val6]: any) => (
                                                        depth<=4?tiers.push({ name: key6, isChecked: selectedHierarchy['tier'].some((p: any) => p.name === key6 && p.isChecked)? true:false }):"",
                                                        (depth<=4?tiers.map(putAll):selectedTier).map(function(tr: any) {
                                                          if (key6 === tr && val6 && val6.packs) {
                                                            Object.entries(val6.packs).map(
                                                                ([key7, val7]: any) => (
                                                                depth<=4?packs.push({ name: key7, isChecked: selectedHierarchy['pack'].some((p: any) => p.name === key7 && p.isChecked)? true:false }):"",
                                                                (depth<=4?packs.map(putAll):selectedPack).map(function(item: any) {
                                                                    if (item === key7) {
                                                                        val7.map((x: any) =>
                                                                            eans.push({ name: x, isChecked: selectedHierarchy["ean"].some((p: any) => p.name === x && p.isChecked ) ? true:false })   
                                                                        );
                                                                    }
                                                                })
                                                              )
                                                            )
                                                          }
                                                        })
                                                      )
                                                    );
                                                }
                                            })
                                          )
                                        );
                                    }
                                })
                              )
                            );
                        }
                    })
                  )
                  );
                } 
              }
            })
        );
    }
    
    if (selectedProductLevel !== 'subCategory' && depth <= 1) {
        selectedHierarchy['manufacturer'] = manufacturers;
    }
    
    if(selectedProductLevel === 'subCategory' && depth <= 0){
        selectedHierarchy['manufacturer'] = manufacturers;
    }

    if(selectedProductLevel === 'subCategory' && depth === 2) {
        selectedHierarchy['subCategory'] = subcategories;
    }

    if (depth <= 2) {
        selectedHierarchy['brand'] = brands;
    }

    if (depth <= 3) {
        selectedHierarchy['subBrand'] = subBrands;
    }

    if (depth <= 4) {
       selectedHierarchy['tier'] = tiers;
    }

    if (depth <= 5) {
        selectedHierarchy['pack'] = packs;
    }

    if (depth <= 6) {
        selectedHierarchy['ean'] = eans;
    }

    return selectedHierarchy;
}

export const getUpdatedHierarchy = (hierarchy: any, selectedHierarchy: any, selectedSubCategoryLevel: any, availableSubcategory: any, selectedProductLevel: any) => {
    let manufacturers: any[] = [];
    let brands: any[] = [];
    let subBrands: any[] = [];
    let tiers: any[] = [];
    let packs: any[] = [];
    let eans: any[] = [];
    let subcategories: any[] = [];

    let selectedSubCategory =
      selectedSubCategoryLevel.length === 0
          ? availableSubcategory
          : selectedSubCategoryLevel;

    if(selectedProductLevel === 'subCategory'){
        forEach(hierarchy, ([key, subcategory]: any) => {
            if(isEmpty(selectedSubCategoryLevel)) {
                manufacturers.push({ name: key, isChecked: false });
                manufacturers.map(function(m: any) {
                    if(key === m.name && !isEmpty(subcategory)) {
                        manufacturers.map(putAll)
                        forEach(subcategory.subCategories, (subCat:any) => {
                            subCat ? subcategories.push({ name: subCat, isChecked: false }) : [];
                        })
                    }
                })
            } else {
                if(!isEmpty(subcategory)) {
                    const hasSelectedSubCategory = selectedSubCategoryLevel.every((elem: any) => (subcategory.subCategories).includes(elem));
                    if(hasSelectedSubCategory){
                        manufacturers.push({ name: key, isChecked: false });
                    }
                    forEach(subcategory.subCategories, (subCat:any) => {
                        selectedSubCategoryLevel.includes(subCat) ? subcategories.push({ name: subCat, isChecked: false }) : [];
                        (subcategories.map(putAll))
                    })
                }
            }
        })
    } else {
        hierarchy.map(([key, subcategory]: any) =>
          selectedSubCategory.map(function(c: any) {
              if (key === c) {
                if(subcategory !== null){
                  Object.entries(subcategory.manufacturers).map(
                    ([key3, val3]: any) => (
                        (manufacturers.push({ name: key3, isChecked: false }),
                         (manufacturers.map(putAll)).map((m: any) => {
                         if (key3 === m && val3 && val3.brands) {
                            Object.entries(val3.brands).map(([key4, val4]: any) => (
                                brands.push({ name: key4, isChecked: false }),
                                (brands.map(putAll)).map((b: any) => {
                                    if (key4 === b && val4 && val4.subBrands) {
                                        Object.entries(val4.subBrands).map(
                                            ([key5, val5]: any) => (
                                            subBrands.push({ name: key5, isChecked: false }),
                                            (subBrands.map(putAll)).map(function(sb: any) {
                                                if (key5 === sb && val5 && val5.tiers) {
                                                    Object.entries(val5.tiers).map(
                                                        ([key6, val6]: any) => (
                                                        tiers.push({ name: key6, isChecked: false }),
                                                        (tiers.map(putAll)).map(function(tr: any) {
                                                          if (key6 === tr && val6 && val6.packs) {
                                                            Object.entries(val6.packs).map(
                                                                ([key7, val7]: any) => (
                                                                packs.push({ name: key7, isChecked: false }),
                                                                (packs.map(putAll)).map(function(item: any) {
                                                                    if (item === key7) {
                                                                        val7.map((x: any) =>
                                                                            eans.push({ name: x, isChecked: false })
                                                                        );
                                                                    }
                                                                })
                                                              )
                                                            )
                                                          }
                                                        })
                                                      )
                                                    );
                                                }
                                            })
                                          )
                                        );
                                    }
                                })
                              )
                            );
                        }
                    })
                  )
                  ));
                } 
              }
            })
        );
    }
    
    selectedHierarchy['manufacturer'] = manufacturers;
    selectedHierarchy['subCategory'] = subcategories;
    selectedHierarchy['brand'] = brands;
    selectedHierarchy['subBrand'] = subBrands;
    selectedHierarchy['tier'] = tiers;
    selectedHierarchy['pack'] = packs;
    selectedHierarchy['ean'] = eans;

    return selectedHierarchy;
}

export const getViewMoreElasticityAnalysisData = (data: any, params: any, tab: string) => {
    const resultData = data.filter((item: any) => {
        if(tab === "own") {
            return (item.initial === params.initial &&
                    (!isNil(item.subCategoryInitial) && !isNil(params.subCategoryInitial) ? item.subCategoryInitial === params.subCategoryInitial[0] : true) &&
                    (!isNil(item.manufacturerInitial) && !isNil(params.manufacturerInitial) ? item.manufacturerInitial === params.manufacturerInitial[0] : true) &&
                    (!isNil(item.brandInitial) && !isNil(params.brandInitial) ? item.brandInitial === params.brandInitial[0] : true) &&
                    (!isNil(item.subBrandInitial) && !isNil(params.subBrandInitial) ? item.subBrandInitial === params.subBrandInitial[0] : true) &&
                    (!isNil(item.packInitial) && !isNil(params.packInitial) ? item.packInitial === params.packInitial[0] : true));
                    
        } else if(tab === "cross") {
            return ((item.initial === params.initial[0]) && (item.target === params.target[0]) &&
                (!isNil(item.subCategoryInitial) && !isNil(params.subCategoryInitial) ? item.subCategoryInitial === params.subCategoryInitial : true) &&
                (!isNil(item.subCategoryTarget) && !isNil(params.subCategoryTarget) ? item.subCategoryTarget === params.subCategoryTarget : true) &&
                (!isNil(item.manufacturerInitial) && !isNil(params.manufacturerInitial) ? item.manufacturerInitial === params.manufacturerInitial : true) &&
                (!isNil(item.manufacturerTarget) && !isNil(params.manufacturerTarget) ? item.manufacturerTarget === params.manufacturerTarget : true) &&
                (!isNil(item.brandInitial) && !isNil(params.brandInitial) ? item.brandInitial === params.brandInitial : true) &&
                (!isNil(item.brandTarget) && !isNil(params.brandTarget) ? item.brandTarget === params.brandTarget : true) &&
                (!isNil(item.subBrandInitial) && !isNil(params.subBrandInitial) ? item.subBrandInitial === params.subBrandInitial : true) &&
                (!isNil(item.subBrandTarget) && !isNil(params.subBrandTarget) ? item.subBrandTarget === params.subBrandTarget : true) &&
                (!isNil(item.packInitial) && !isNil(params.packInitial) ? item.packInitial === params.packInitial : true) &&
                (!isNil(item.packTarget) && !isNil(params.packTarget) ? item.packTarget === params.packTarget : true));
        }
    })
    return resultData[0];
}

export const getEditWeekRangeforPowerBI = (timePeriods:  any) => {
    let yearweekRange: any[] = [];
    if(timePeriods.length > 0) {
        timePeriods.forEach((obj: any) => {
            let y = obj.year;
            for (let k in obj.weeks){
              let w = '0' + obj.weeks[k];
              let c = y + w.slice(-2);
              yearweekRange.push(c);
            }
        })
    }
    return yearweekRange;
}