import { useEffect, useState } from "react";
import { Button, Box, makeStyles, CircularProgress } from '@material-ui/core';
import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import useStore from "../../../../store";
import { usePromoCalculations, useSimulationParameters } from "../../../../services/mutations";
import { scrollTo } from "../utils/scrollTo";
import moment from "moment";
import { AlertDialog } from "./AlertDialog";
interface prompCalculationType {
  key: string, value: { [key: string]: string }[]
}
interface formatedValue {
  "brand": string,
  "category": string,
  "ean": string,
  "eanDescription": string,
  "sector": string,
  "subBrand": string,
  "subCategory": string,

}
interface ProductPayLoad {
  "brand": string[],
  "category": string[],
  "eans": string[],
  "sector": string[],
  "subBrand": string[],
  "subCategory": string[],
}
export const ProductGridButton = (props: any) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [isCreateGridClicked, setIsCreateGridClicked] = useState(false);
  let selectedCountry = useStore((state) => state.selectedCountry);
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  let selectedPreRoiProducts = useStore((state) => state.selectedPreRoiProducts);
  let preRoiSelectedCustomers = useStore((state) => state.preRoiSelectedCustomers);
  const preRoiProductsStateData = useStore((state) => state.preRoiProductsStateData);
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);
  const setSimulatedProductDetails = useStore((state) => state.setSimulatedProductDetails);
  const isValueValid = usePromoSimulatorStore((state: any) => state.isValueValid)
  const [payLoad, setPayLoad] = useState({
    "additionalFunding": 0,
    "country": selectedCountry,
    "customer": "",
    "fixedCosts": "",
    "products": [
      {
        "brand": "",
        "category": "",
        "ean": "",
        "eanDescription": "",
        "sector": "",
        "subBrand": "",
        "subCategory": ""
      }
    ],
    "promoTo": "",
    "promoFrom": "",
    "promoSellInPrice": {
      "internal": "",
      "value": 0
    },
    "promoSellOutPrice": {
      "internal": "",
      "multiBuyValue": "",
      "value": ""
    },
    "promoVolume": {
      "internal": "",
      "value": 0
    },
    "redemptionRate": 0
  });
  //as of now we are using mock data so we are not using PromoCalculationsReponse and successError but we will need this in future 
  const { mutate: PromoCalculations } = usePromoCalculations();
  const { mutate: getSimulationsData, data: simulationsData, isSuccess: simulationsDataSuccess, isError: successError, isLoading: isSimulationDataLoading } = useSimulationParameters(payLoad);

  const [hasMandatoryInputs, setHasMandatoryInputs] = useState(false);

  useEffect(() => {
    let hasAllRequiredInputs = (
      isValueValid.isPromoVolumeValid &&
      isValueValid.isSellInPriceValid &&
      isValueValid.isAdditionalFundingValid &&
      selectedPreRoiProducts?.length > 0 &&
      preRoiSelectedCustomers?.length > 0 &&
      promoSimulatorTabs[props.id].duration > 0 &&
      promoSimulatorTabs[props.id].promoName &&
      promoSimulatorTabs[props.id].sellInPriceType &&
      promoSimulatorTabs[props.id].unitOfMeasure &&
      promoSimulatorTabs[props.id].sellOutPriceType &&
      promoSimulatorTabs[props.id].volumeType);
    if (hasAllRequiredInputs && (promoSimulatorTabs[props.id].sellInPriceType !== 'MANUAL_INPUT')) {
      hasAllRequiredInputs = promoSimulatorTabs[props.id].sellInPriceTypevalue
    }
    if (hasAllRequiredInputs && (promoSimulatorTabs[props.id].sellOutPriceType !== 'MANUAL_INPUT')) {
      hasAllRequiredInputs = promoSimulatorTabs[props.id].sellOutPriceTypeValue
    }
    if (hasAllRequiredInputs && (promoSimulatorTabs[props.id].volumeType !== 'MANUAL_INPUT')) {
      hasAllRequiredInputs = promoSimulatorTabs[props.id].volumeTypeValue
    }
    if (hasAllRequiredInputs && (promoSimulatorTabs[props.id].sellOutPriceType === 'MULTIBUY')) {
      hasAllRequiredInputs = promoSimulatorTabs[props.id].redemptionRate && isValueValid.isRedemptionRateValid
    }
    if (hasAllRequiredInputs && (promoSimulatorTabs[props.id].sellOutPriceType !== 'MULTIBUY')) {
      hasAllRequiredInputs = isValueValid.isSellOutPriceValid
    }
    hasAllRequiredInputs || selectedPromoDetails.type === 'duplicate' ? setHasMandatoryInputs(true) : setHasMandatoryInputs(false)
    return (() => {
      setHasMandatoryInputs(false);
    });
  }, [promoSimulatorTabs, selectedPreRoiProducts, preRoiSelectedCustomers, selectedPromoDetails, promoSimulatorTabs[props.id].redemptionRate,
    isValueValid.isPromoVolumeValid, isValueValid.isSellInPriceValid, isValueValid.isSellOutPriceValid, isValueValid.isRedemptionRateValid, isValueValid.isAdditionalFundingValid])

  useEffect(() => {
    const selectedTabData = promoSimulatorTabs[props.id];
    if (selectedTabData["triggerCalculate"]) {
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => {
        return tab.tabIndex === props.id ? { ...tab, triggerCalculate: false } : tab
      }
      ))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSimulatorTabs]);

  useEffect(() => {
    if (selectedPromoDetails.fromView && selectedPromoDetails.type === 'duplicate') {
      setHasMandatoryInputs(true);
    }
  }, [selectedPromoDetails])

  useEffect(() => {
    let tempPromoArray: prompCalculationType[] = [];
    if (true) {
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === props.id ? { ...tab, triggerCalculate: false } : tab));
      if (simulationsData && simulationsData.data) {
        let calculatedArr = simulationsData ? simulationsData.data.data : [];
        if (calculatedArr.length > 0) {
          calculatedArr = calculatedArr.sort((a: any, b: any) => a.ean > b.ean ? 1 : -1);
          Object.keys(calculatedArr[0]).forEach((row: any) => {
            tempPromoArray.push(generateKeyObject(row, row, calculatedArr));
            if (row === "baseGSVListPrice") {
              tempPromoArray.push(generateKeyObject("baseGSVListPrice", "sellInListPriceOverride", calculatedArr));
            } else if (row === "baseVolume") {
              tempPromoArray.push(generateKeyObject("baseVolume", "baseVolumeOverride", calculatedArr));
            } else if (row === "additionalFunding") {
              tempPromoArray.push(generateKeyObject("additionalFunding", "additionalFundingOverride", calculatedArr));
            } else if (row === "baseSellOutPrice") {
              tempPromoArray.push(generateKeyObject("baseSellOutPrice", "baseSellOutPriceOverride", calculatedArr));
            } else if (row === "cogs") {
              tempPromoArray.push(generateKeyObject("cogs", "fixedCogOverride", calculatedArr));
            }
          })
        }

        const findValueByKey = (keyToFind: any, targetKey: any) => {
          const row = tempPromoArray.find((item: any) => item.key === keyToFind);
          if (row) {
            const valueItem = row.value.find((item: any) => Object.keys(item)[0] === targetKey)
            if (valueItem) {
              return valueItem[targetKey];
            }
          }
          return 0;
        }

        tempPromoArray.forEach((row: any) => {
          if (row.key === "discount") {
            row.value.forEach((item: any) => {
              const key = Object.keys(item)[0];
              const baseSellOutPriceOverrideValue = findValueByKey("baseSellOutPriceOverride", key);
              const promoSellOutPriceValue = findValueByKey("promoSellOutPrice", key);
              item[key] = Number(baseSellOutPriceOverrideValue) - Number(promoSellOutPriceValue)
            })
          }
        })

        tempPromoArray.forEach((row: any) => {
          if (row.key === "discountPercent") {
            row.value.forEach((item: any) => {
              const key = Object.keys(item)[0];
              const baseSellOutPriceOverrideValue = findValueByKey("baseSellOutPriceOverride", key);
              const promoSellOutPriceValue = findValueByKey("promoSellOutPrice", key);
              item[key] = baseSellOutPriceOverrideValue === 0 ? 0 : (((Number(baseSellOutPriceOverrideValue) - Number(promoSellOutPriceValue)) / Number(baseSellOutPriceOverrideValue)) * 100).toFixed(1);
            })
          }
        })

        tempPromoArray.forEach((row: any) => {
          if (row.key === "volumeUplift") {
            row.value.forEach((item: any) => {
              const key = Object.keys(item)[0];
              const baseVolumeOverrideValue = findValueByKey("baseVolumeOverride", key);
              const promoVolumeValue = findValueByKey("promoVolume", key);
              item[key] = baseVolumeOverrideValue === 0 ? 0 : ((Number(promoVolumeValue) - Number(baseVolumeOverrideValue)) / Number(baseVolumeOverrideValue)).toFixed(1);
            })
          }
        })
        setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === props.id ? { ...tab, promoCalculation: calculatedArr, displayPromo: tempPromoArray, triggerCalculate: false } : tab));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationsData]);

  useEffect(() => {
    if (isCreateGridClicked && promoSimulatorTabs[0]?.calculateButtonClicked && promoSimulatorTabs[0]?.promoCalculation?.length > 0) {
      setTimeout(() => {
        if (document.getElementById('promo-grid')) {
          scrollTo('promo-grid');
          setIsCreateGridClicked(false);
        }
      }, 200)
    }
  }, [isCreateGridClicked, promoSimulatorTabs]);

  const generateKeyObject = (row: any, displayRow: string, calculatedArr: any) => {
    let tempObject: prompCalculationType = {
      key: displayRow,
      value: []
    }
    let displayPromo = promoSimulatorTabs[0].displayPromo;
    let selectedItem = displayPromo.filter((item: any) => item.key === displayRow);
    if (row === displayRow) {
      calculatedArr.forEach((col: any) => {
        const findValue = calculatedArr.find((data: any) => data.ean === col.ean);
        if (tempObject.key === 'promoSellInInvoicePrice' && promoSimulatorTabs[0].sellInPriceType === "MANUAL_INPUT") {
          tempObject.value.push({ [col.ean]: findValue.baseSellInInvoicePrice })
        }
        else if (tempObject.key === 'promoSellInInvoicePrice' && promoSimulatorTabs[0].sellInPriceType === "PROMO_DEAL_FIXED") {
          // tempObject.value.push({ [col.ean]: (findValue.baseSellInInvoicePrice - promoSimulatorTabs[0].sellInPriceTypevalue).toString() })
          tempObject.value.push({ [col.ean]: (findValue.promoSellInInvoicePrice).toString() })
        }
        else if (tempObject.key === 'promoSellInInvoicePrice' && promoSimulatorTabs[0].sellInPriceType === "PROMO_DEAL_PERCENT") {
          // tempObject.value.push({ [col.ean]: (findValue.baseSellInInvoicePrice - Math.round((promoSimulatorTabs[0].sellInPriceTypevalue / 100) * findValue.baseSellInInvoicePrice)).toString() })
          tempObject.value.push({ [col.ean]: (findValue.promoSellInInvoicePrice).toString() })
        }
        else if (tempObject.key === 'promoSellInInvoicePrice' && promoSimulatorTabs[0].sellInPriceType === "EXPECTED_MARGIN") {
          // let value = (Math.round(findValue.promoSellOutPrice / (1 + (Number(promoSimulatorTabs[0].sellInPriceTypevalue) / 100))));
          // tempObject.value.push({ [col.ean]: value.toString() })
          tempObject.value.push({ [col.ean]: (findValue.promoSellInInvoicePrice).toString() })
        }
        else if (tempObject.key === 'promoSellOutPrice' && promoSimulatorTabs[0].sellOutPriceType === "MANUAL_INPUT") {
          tempObject.value.push({ [col.ean]: findValue ? findValue.baseSellOutPrice : '0' })
        }
        else if (tempObject.key === 'promoSellOutPrice' && promoSimulatorTabs[0].sellOutPriceType === "MULTIBUY") {
          promoSimulatorTabs[0].sellOutPriceTypeValue === '3 for 2' && tempObject.value.push({ [col.ean]: Math.round(Number(findValue.baseSellOutPrice * (2 / 3))).toString() })
          promoSimulatorTabs[0].sellOutPriceTypeValue === '2 for 1' && tempObject.value.push({ [col.ean]: Math.round(Number(findValue.baseSellOutPrice * (1 / 2))).toString() })
        }
        else if (tempObject.key === 'promoVolume' && promoSimulatorTabs[0].volumeType === "MANUAL_INPUT") {
          tempObject.value.push({ [col.ean]: findValue ? findValue.baseVolume : '0' })
        }
        else {
          tempObject.value.push({ [col.ean]: col[row] })
        }
      })
    } else {
      calculatedArr.forEach((col: any) => {
        if (selectedItem.length > 0) {
          let findIndex = selectedItem[0].value.findIndex((item: any) => Object.keys(item)[0] === col.ean);
          if (findIndex >= 0) {
            selectedItem[0].value.map((item: any) => {
              if (Object.keys(item)[0] === col.ean) {
                tempObject.value.push({ [col.ean]: item[col.ean] });
              }
            })
          } else {
            tempObject.value.push({ [col.ean]: '0' });
          }
        } else {
          const findValue = calculatedArr.find((data: any) => data.ean === col.ean);
          if (tempObject.key === 'sellInListPriceOverride') {
            tempObject.value.push({ [col.ean]: findValue ? findValue.baseGSVListPrice : '0' })
          } else if (tempObject.key === 'baseVolumeOverride') {
            tempObject.value.push({ [col.ean]: findValue ? findValue.baseVolume : '0' })
          } else if (tempObject.key === 'additionalFundingOverride') {
            tempObject.value.push({ [col.ean]: findValue ? findValue.additionalFunding : '0' })
          } else if (tempObject.key === 'baseSellOutPriceOverride') {
            tempObject.value.push({ [col.ean]: findValue ? findValue.baseSellOutPrice : '0' })
          } else if (tempObject.key === 'fixedCogOverride') {
            tempObject.value.push({ [col.ean]: findValue ? findValue.cogs : '0' })
          } else {
            tempObject.value.push({ [col.ean]: '0' })
          }
        }
      })
    }
    return tempObject
  }

  const handleCalculateClick = () => {
    if (selectedPromoDetails.fromView && selectedPromoDetails.type === 'duplicate' && (promoSimulatorTabs[props.id].promoName).length <= 0) {
      props.setPromoNameError(true);
      setTimeout(() => {
        scrollTo("promo-name");
      }, 100);
    } else {
      setIsCreateGridClicked(true);
      props.setPromoNameError(false);
      let payLoadData = payLoad;
      let selectedTabData = promoSimulatorTabs[0];
      payLoadData.customer = preRoiSelectedCustomers;
      payLoadData.additionalFunding = selectedTabData.additionalFunding;
      payLoadData.fixedCosts = selectedTabData.fixedCost;
      payLoadData.promoSellInPrice = {
        "internal": selectedTabData.sellInPriceType,
        "value": selectedTabData.sellInPriceTypevalue
      };
      payLoadData.promoSellOutPrice = {
        "internal": selectedTabData.sellOutPriceType,
        "multiBuyValue": selectedTabData.sellOutPriceType === "MULTIBUY" ? selectedTabData.sellOutPriceTypeValue : '',
        "value": selectedTabData.sellOutPriceType !== "MULTIBUY" ? selectedTabData.sellOutPriceTypeValue : ''
      };
      payLoadData.promoVolume = {
        "internal": selectedTabData.volumeType,
        "value": selectedTabData.volumeType === "MULTIPLIER_OF_BASE" ? parseFloat(selectedTabData.volumeTypeValue) : selectedTabData.volumeTypeValue
      };
      payLoadData.products = getProductData()
      payLoadData.promoFrom = moment(selectedTabData.fromDate).format("yyyy-MM-DD");
      payLoadData.promoTo = moment(selectedTabData.toDate).format("yyyy-MM-DD");
      if(selectedTabData.sellOutPriceType === "MULTIBUY") {
        payLoadData.redemptionRate = selectedTabData.sellOutPriceType === "MULTIBUY" ? parseFloat(selectedTabData.redemptionRate) : 0
      } else {
        payLoadData.redemptionRate = 0;
      }

      getSimulationsData(payLoadData)
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any, i: any) => i === props.id ? { ...tab, calculateButtonClicked: true, triggerCalculate: true, PnLResposne: [] } : tab))
      setTimeout(() => {
        document.getElementById('promo-grid')?.scrollIntoView({ behavior: 'smooth' });
      }, 2000);
    }
  };

  const handleEditClick = () => {
    setOpenDialog(true)
  }

  const handleYes = () => {
    setOpenDialog(false)
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any, i: any) => i === props.id ? { ...tab, calculateButtonClicked: false, displayPromo: [], pnLGridGenerated: false, PnLResposne: [] } : tab))
  }
  const handleNo = () => {
    setOpenDialog(false)
  }
  function getProductData() {
    let selectedArr: any = [];
    let productPayLoad: ProductPayLoad = {
      "brand": [],
      "category": [],
      "eans": [],
      "sector": [],
      "subBrand": [],
      "subCategory": [],

    };
    let formatedPayLoad: formatedValue[] = [];

    const arr = [
      "brand",
      "category",
      "eans",
      "sector",
      "subBrand",
      "subCategory",
    ];
    let values = { ...preRoiProductsStateData };
    //iterating backword
    for (let i = arr.length - 1; i >= 0; i--) {
      let level: string = arr[i];
      if (values && values[level as keyof typeof values]) {
        // @ts-expect-error
        let selectedNodes = values[level as keyof typeof values].filter(isSelected).map(putAll);
        if (selectedNodes.length !== 0) {
          selectedNodes.forEach((val: any) => {
            productPayLoad[level as keyof typeof productPayLoad].push(val);

            selectedArr.push(val);
          });
        }
      }
    }

    if(selectedPromoDetails.fromView && productPayLoad["eans"].length === 0) {
      if(promoSimulatorTabs && promoSimulatorTabs[0].pnlInputArray && promoSimulatorTabs[0].pnlInputArray.length > 0) {
        promoSimulatorTabs[0].pnlInputArray.forEach((product : any) => {
          if(product.key && product.key !== '') {
            productPayLoad.eans.push(product.key)
          }
        });
      }
    }

    formatedPayLoad = [];
    if (productPayLoad["eans"].length > 0) {
      productPayLoad["eans"].forEach(element => {
        let eans = element.split("-");
        if (eans.length > 0) {
          let productPaylod = {
            "brand": "",
            "category": "",
            "ean": "",
            "eanDescription": "",
            "sector": "",
            "subBrand": "",
            "subCategory": ""
          }
          productPaylod.ean = eans[eans.length - 1].trim();
          formatedPayLoad.push(productPaylod)
        }
      });
    }

    return formatedPayLoad;
  }
  function putAll(x: any) {
    return x.name;
  }
  function isSelected(x: any) {
    return x && x.isChecked;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 5 }}>
      <label className={classes.requiredField}>Fields marked * are mandatory</label>
      {!promoSimulatorTabs[props.id].calculateButtonClicked ?
        <Button className={hasMandatoryInputs ? classes.calculateBtn : classes.disabledBtn} disabled={!hasMandatoryInputs}
          onClick={handleCalculateClick}
        >
          Create Product Grid
        </Button> : <Button onClick={handleEditClick} className={classes.calculateBtn}>
          {isSimulationDataLoading && <CircularProgress size="1rem" style={{ marginRight: '10px', color: 'white' }} />}
          Edit
        </Button>
      }
      <AlertDialog openDialog={openDialog} handleYes={handleYes} handleNo={handleNo} message="Promotion details are being changed which could cause reworking on entire simulation. Are you sure to continue?" />
    </Box>
  )
};

const useStyles = makeStyles(() => ({
  calculateBtn: {
    float: 'right',
    color: '#fff',
    background: '#1976d2',
    margin: '10px',
    '&:hover': {
      backgroundColor: "#1976d2 !important",
    },
  },
  disabledBtn: {
    float: 'right',
    color: 'rgb(217, 217, 217)',
    background: 'rgb(240, 240, 240)',
    margin: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: "rgb(240, 240, 240) !important",
    },
  },
  requiredField: {
    color: '#d32f2f !important',
    fontSFize: '0.75rem'
  }
}))

