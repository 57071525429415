import create from "zustand";

const usePromoSimulatorStore = create(set => ({
  promoSimulatorTabs: [],
  setPromoSimulatorTabs: (req: any) => set((state: any) => ({ promoSimulatorTabs: req })),
  isValueValid: {
    isPromoVolumeValid: true,
    isSellInPriceValid: true,
    isSellOutPriceValid: true,
    isRedemptionRateValid: true,
    isAdditionalFundingValid: true
  },
  setIsValueValid: (req: any) => set((state: any) => ({ isValueValid: req })),
}));

export default usePromoSimulatorStore;