import React from 'react'
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: any) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  cellData: {
    borderLeft: "1px solid #ccc",
    textTransform: "capitalize",
  },
  xAddButtonCell: {
    fontWeight: "bold",
    color: "#2662FF",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    width: 100,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  yAddButtonCell: {
    fontWeight: "bold",
    color: "#2662FF",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    width: 100,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  correlationTable: {
    background: "inherit",
    width: "100%",
    alignItems: "flex-start",
  },
  gridHeader: {
    textAlign: "center",
    padding: "2.5rem",
  },
  tableHeadCell: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
  },
  correlationCell: {
    fontWeight: "bold",
    color: "#494949",
    whiteSpace: "nowrap",
    width: 100,
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  cellTable: {
    padding: "0",
    verticalAlign: "top",
  },
  high: {
    backgroundColor: "#CBF2DB",
    fontWeight: "bold",
    color: "#2ECC71",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  medium: {
    backgroundColor: "#FDF2C5",
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    color: "#DFB202",
  },
  low: {
    backgroundColor: "#FBCCCC",
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    color: "#F03434",
  },
  emptyHeader: {
    borderBottom: "none",
    borderRight: "none",
  },
  emptyCell: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  circleActive: {
    marginLeft: "1px",
    cursor: "pointer",
  },
  circleInActive: {
    marginLeft: "1px",
    color: "#808080",
  },
  coefficient: {
    fontWeight: "bold",
    textAlign: "center",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  c0: {
    backgroundColor: "#fee4e5",
  },
  c1: {
    backgroundColor: "#fee4e5",
  },
  c2: {
    backgroundColor: "#fee0e1",
  }, c3: {
    backgroundColor: "#fdd1d2",
  }, c4: {
    backgroundColor: "#fcbabb",
  }, c5: {
    backgroundColor: "#fcb3b4",
  }, c6: {
    backgroundColor: "#fb999a",
  }, c7: {
    backgroundColor: "#fb9697",
  }, c8: {
    backgroundColor: "#fa8789",
  }, c9: {
    backgroundColor: "#f97f81",
  },
  c10: {
    backgroundColor: "#000000",
  },
  bgBlack: {
    backgroundColor: 'black',
    color: 'white',
  },
  container: {

  }
}))

type CorrelationData = {
  initial: string,
  scopeName: string,
  target: string,
  value: string
};
type Props = {
  initialProductSelected: string[],
  targetProductSelected: string[],
  headCells: string[],
  correlationDataNew: CorrelationData[],
  customer: string,
  productLevel: string
}
const CorrelationGrid = ({ initialProductSelected, targetProductSelected, headCells, correlationDataNew, customer, productLevel }: Props) => {
  const classes = useStyles();

  return (
    <div className="flex justify-center items-center" style={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
      <TableContainer className={classes.container} style={{ display: "flex", flexDirection: 'column' }}>
        <Table>
          <div className="flex h-16 justify-center items-center font-semibold" data-testid="customer" >{customer}</div>
        </Table>
        <Table className={classes.correlationTable}>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={1}
                className={classes.correlationCell}
              >
              </TableCell>
              {targetProductSelected.length > 0
                ? targetProductSelected.map((targetCell: any, i: any) => (
                  <TableCell
                    data-testid="cell-target-product"
                    colSpan={1}
                    className={classes.xAddButtonCell}
                    key={i}
                  >
                    <Tooltip
                      data-testid="tooltip-target-product"
                      title={<Typography>{targetCell}</Typography>}
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                    >
                      <div className="targetOvalShape targetProduct" style={{ display: "flex", justifyContent: 'center' }} data-testid="target-product-text">{targetCell}</div>
                    </Tooltip>
                  </TableCell>
                ))
                : headCells.map((headCell: any) => (
                  <TableCell
                    colSpan={1}
                    data-testid="target-head-cells"
                    className={classes.xAddButtonCell}
                    key={headCell.id}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
            </TableRow>

            {initialProductSelected.length > 0
              ? initialProductSelected.map((kcCellVal: any, i: any) => (
                <TableRow key={i}>
                  <TableCell
                    className={classes.yAddButtonCell}
                    data-testid="cell-initial-product"
                  >
                    <Tooltip
                      data-testid="tooltip-inital-product"
                      title={<Typography>{kcCellVal}</Typography>}
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                    >
                      <div className="kcOvalShape initialProduct" style={{ display: "flex", justifyContent: 'center' }} data-testid="initial-product-text">{kcCellVal}</div>
                    </Tooltip>
                  </TableCell>
                  {
                    targetProductSelected.map((targetCell: any, j: any) => {
                      let initial = initialProductSelected[i];
                      let target = targetProductSelected[j];
                      let isSameProduct = false
                      if (initial === target) {
                        isSameProduct = true
                      }

                      if (productLevel === 'EAN') {
                        initial = initialProductSelected[i]?.split(" - ")[0];
                        target = targetProductSelected[j]?.split(" - ")[0];
                      }
                      const value = correlationDataNew?.find((item) => (item.initial === initial && item.target === target));
                      const values = value?.value ? +parseFloat(value?.value).toFixed(2) : 0;
                      let colorClass = isSameProduct ? classes.bgBlack : ''
                      if (!isSameProduct) {
                        if ((values >= 0.00 && values <= 0.09) || (values <= -0.00 && values >= -0.09)) {
                          colorClass = classes.c0
                        } else if ((values >= 0.10 && values <= 0.19) || (values <= -0.10 && values >= -0.19)) {
                          colorClass = classes.c1
                        } else if ((values >= 0.20 && values <= 0.29) || (values <= -0.20 && values >= -0.29)) {
                          colorClass = classes.c2
                        } else if ((values >= 0.30 && values <= 0.39) || (values <= -0.30 && values >= -0.39)) {
                          colorClass = classes.c3
                        } else if ((values >= 0.40 && values <= 0.49) || (values <= -0.40 && values >= -0.49)) {
                          colorClass = classes.c4
                        } else if ((values >= 0.50 && values <= 0.59) || (values <= -0.50 && values >= -0.59)) {
                          colorClass = classes.c5
                        } else if ((values >= 0.60 && values <= 0.69) || (values <= -0.60 && values >= -0.69)) {
                          colorClass = classes.c6
                        } else if ((values >= 0.70 && values <= 0.79) || (values <= -0.70 && values >= -0.79)) {
                          colorClass = classes.c7
                        } else if ((values >= 0.80 && values <= 0.89) || (values <= -0.80 && values >= -0.89)) {
                          colorClass = classes.c8
                        } else if ((values >= 0.90 && values <= 0.99) || (values <= -0.90 && values >= -0.99)) {
                          colorClass = classes.c9
                        } else {
                          colorClass = classes.c10
                        }
                      }

                      return <TableCell
                        data-testid={`correlation-cell-${i}-${j}`}
                        className={`${classes.coefficient} ${colorClass}`}
                        key={j}>
                        {
                          isSameProduct
                            ? ''
                            : value?.value ? (parseFloat(value?.value)).toFixed(3) : ''}
                      </TableCell>
                    })
                  }

                </TableRow>
              ))
              : headCells.map((headCell: any) => (
                <TableRow>
                  <TableCell
                    data-testid="initial-head-cells"
                    className={classes.yAddButtonCell}
                    key={headCell.id}
                  >
                    {headCell.label}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  )
}

export default CorrelationGrid