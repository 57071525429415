import React, { useState, useEffect } from "react";
import { Grid, Box, MenuItem, Stack, TextField } from "@mui/material";
import { makeStyles } from '@material-ui/core';
import usePromoSimulatorStore from "../../../../store/promoSimulatorStore";
import InputAdornment from '@mui/material/InputAdornment';

import useStore from "../../../../store";

export const validateInput = (input: any, validationType: string) => {
  switch (validationType) {
    case "positiveNumber":
      return (/^[0-9]+$/.test(input));
    case "positiveNumberwithDecimal":
      return (/^(\d)*(\.)?([0-9]{1})?$/.test(input));
    case "positivePercentage":
      //0.1-99.9 with only one decimal place
      return (/^(?!0(\.0)?$)(?:\d{1,2}(?:\.\d)?|99(?:\.0)?)$/.test(input));
  };
};

export const PromoSellInPriceInput = ({ id, promoSellInPriceType, promoData }: any) => {
  const classes = useStyles();
  const promoSimulatorTabs = usePromoSimulatorStore((state: any) => state.promoSimulatorTabs);
  const setPromoSimulatorTabs = usePromoSimulatorStore((state: any) => state.setPromoSimulatorTabs);
  const selectedPromoDetails = useStore((state) => state.selectedPromoDetails);

  const [priceType, setPriceType] = useState('')
  const [value, setValue] = useState('');
  const isValueValid = usePromoSimulatorStore((state: any) => state.isValueValid)
  const setIsValueValid = usePromoSimulatorStore((state: any) => state.setIsValueValid)
  const [validationErrorText, setValidationErrorText] = useState("");

  useEffect(() => {
    if (promoSimulatorTabs[id].reset) {
      setPriceType(promoSimulatorTabs[id].sellInPriceType);
      setValue(promoSimulatorTabs[id].sellInPriceTypevalue);
    }
  }, [promoSimulatorTabs])

  useEffect(() => {
    if (promoData && selectedPromoDetails.fromView) {
      setPriceType(promoData.promoSellInOption);
      setValue(promoData.promoSellInValue);
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceType: promoData.promoSellInOption } : tab));
      setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: promoData.promoSellInValue, reset: false } : tab));
    }
  }, [selectedPromoDetails, promoData])

  const validateValueInputs = (e: any) => {
    if (priceType === 'PROMO_DEAL_FIXED') {
      if (e.target.value) {
        if (!validateInput(e.target.value, "positiveNumber")) {
          setIsValueValid({ ...isValueValid, isSellInPriceValid: false });
          setValidationErrorText("Only positive values without decimals are allowed!");
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: '', reset: false } : tab));
        }
        else {
          setIsValueValid({ ...isValueValid, isSellInPriceValid: true });
          setValidationErrorText("");
          setValue(e.target.value)
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: e.target.value, reset: false } : tab));
        }
        setValue(e.target.value)
      } else {
        setValue(e.target.value)
        setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: e.target.value, reset: false } : tab));
      }
    }
    else if (priceType === 'EXPECTED_MARGIN') {
      if (e.target.value) {
        if (!validateInput(e.target.value, "positivePercentage")) {
          setIsValueValid({ ...isValueValid, isSellInPriceValid: false });
          setValidationErrorText("Only positive values greater than 0 and lesser than 100 with up to 1 decimal are allowed");
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: '', reset: false } : tab));
        }
        else {
          setIsValueValid({ ...isValueValid, isSellInPriceValid: true });
          setValidationErrorText("");
          setValue(e.target.value)
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: e.target.value, reset: false } : tab));
        }
        setValue(e.target.value)
      } else {
        setValue(e.target.value)
        setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: e.target.value, reset: false } : tab));
      }
    }
    else {
      if (e.target.value) {
        if (!validateInput(e.target.value, "positivePercentage")) {
          setIsValueValid({ ...isValueValid, isSellInPriceValid: false });
          setValidationErrorText("Only positive values greater than 0 and lesser than 100 with up to 1 decimal are allowed");
        }
        else {
          setIsValueValid({ ...isValueValid, isSellInPriceValid: true });
          setValidationErrorText("");
          setValue(e.target.value)
          setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: e.target.value } : tab));
        }
        setValue(e.target.value)
      }
      else {
        setValue(e.target.value)
        setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceTypevalue: e.target.value, reset: false } : tab));
      }
    }
  };

  const handlePriceTypeChange = (e: any) => {
    setValue("");
    setIsValueValid({ ...isValueValid, isSellInPriceValid: true });
    setValidationErrorText("");
    setPriceType(e.target.value);
    setPromoSimulatorTabs(promoSimulatorTabs.map((tab: any) => tab.tabIndex === id ? { ...tab, sellInPriceType: e.target.value, sellInPriceTypevalue: '' } : tab));
  };

  const handleValueChange = (e: any) => {
    setIsValueValid({ ...isValueValid, isSellInPriceValid: true });
    setValidationErrorText("");
    validateValueInputs(e);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Box sx={{ fontWeight: '500', m: 1, fontSize: '0.75rem' }}>Promo Sell In Price<span className="text-red-900 font-bold text-base">*</span></Box>
        </Grid>
        <Grid item xs={2.5}>
          <TextField
            select
            size="small"
            variant="outlined"
            className={classes.fieldWidth}
            value={priceType}
            onChange={e => handlePriceTypeChange(e)}
            InputLabelProps={{ shrink: true }}
            disabled={promoSimulatorTabs[id].calculateButtonClicked}
            required
          >
            {promoSellInPriceType?.options?.map((promoType: any, index: number) => {
              return <MenuItem sx={{ fontSize: '0.8rem' }} key={index} value={promoType.internal}>{promoType.label}</MenuItem>
            })}
          </TextField>
        </Grid>
        <Grid item xs={2.5}>
          <Stack direction="row" spacing={1}>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="end">{priceType === "PROMO_DEAL_PERCENT" || priceType === "EXPECTED_MARGIN" ? '%' : ''}</InputAdornment>,
              }}
              error={!isValueValid.isSellInPriceValid}
              size="small"
              variant="outlined"
              label="Value"
              className={classes.fieldWidth}
              value={value}
              onChange={e => handleValueChange(e)}
              InputLabelProps={{ shrink: true }}
              helperText={!isValueValid?.isSellInPriceValid ? validationErrorText : null}
              disabled={!priceType || priceType === "MANUAL_INPUT" || promoSimulatorTabs[id].calculateButtonClicked}
              required
            />
          </Stack>
        </Grid>

      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  fieldWidth: {
    width: '228px',
    '& input': {
      fontSize: '0.75rem !important',
    },
    '& div': {
      fontSize: '0.75rem !important',
    }
  },
  labelColor: {
    color: "black"
  }
}));
