import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useStore from "./store";
import Toaster from './commonMuiComponents/Toaster'
import { CircularProgress, Box } from '@mui/material';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import { isEmpty } from "lodash";

import { useBaseLineData } from "./services/queries";
import { updateUrl } from "./UpdateUrl";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
  },
  cellData: {
    borderLeft: "1px solid #ccc",
    textTransform: "capitalize",
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Baseline() {
  let selectedContry = useStore((state) => state.selectedCountry);
  let selectedBaseLineFilters = useStore(
    (state) => state.selectedBaseLineFilters
  );
  const [apidataLocal, setApidataLocal] = useState([]);
  const [apidata, setApidata] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  let updatedBaseLineFilter = updateUrl(selectedBaseLineFilters);
  let selectedYear = useStore((state) => state.selectedYear);
  let viewMonthly = useStore((state) => state.viewMonthly);
  const selectedCustomersHierarchy = useStore(state => state.selectedCustomersHierarchy);
  const selectedProductsHierarchy = useStore(state => state.selectedProductsHierarchy);
  const [baseLineDataPayload, setBaseLineDataPayload] = useState(null);
  const setSelectedCustomersHierarchy = useStore(state => state.setSelectedCustomersHierarchy);
  const setSelectedProductsHierarchy = useStore(state => state.setSelectedProductsHierarchy);
  useEffect(() => {
    let payload = {
      country: selectedContry,
      years: selectedYear,
      granularity: viewMonthly,
      customers: selectedCustomersHierarchy?.soldTo,
      eans: selectedProductsHierarchy?.products,
      categories: selectedProductsHierarchy?.categories,
      subCategories: selectedProductsHierarchy?.subCategories,
      brands: selectedProductsHierarchy?.brands,
      subBrands: selectedProductsHierarchy?.subBrands
    }
    setBaseLineDataPayload(payload)
  }, [
    selectedContry,
    selectedCustomersHierarchy?.soldTo,
    selectedProductsHierarchy?.products,
    selectedProductsHierarchy?.categories,
    selectedProductsHierarchy?.subCategories,
    selectedProductsHierarchy?.brands,
    selectedProductsHierarchy?.subBrands,
    viewMonthly, selectedYear]);


  const { isLoading: isCountryLineData, data: countryLineData, isError: countryLineError } = useBaseLineData(baseLineDataPayload);
  const { isLoading: isProductData, data: productData, isError: productDataError } = useBaseLineData(baseLineDataPayload);
  let selectedBaseLineCustomerNew = useStore((state) => state.selectedBaseLineCustomerNew);
  let selectedBaseLineProductNew = useStore((state) => state.selectedBaseLineProductNew);
  const setSelectedProductValues = useStore(state => state.setSelectedProductValues);
  const setSelectedCustomerValues = useStore(state => state.setSelectedCustomerValues);
  useEffect(() => {
    if (selectedBaseLineCustomerNew.length === 0) {
      setSelectedCustomersHierarchy({});
      setSelectedCustomerValues([]);

    };
    if (selectedBaseLineProductNew.length === 0) {
      setSelectedProductsHierarchy({});
      setSelectedProductValues([]);
    }

  }, [selectedBaseLineCustomerNew, selectedBaseLineProductNew]);

  useEffect(() => {
    if (!isCountryLineData) {
      setCountriesList(countryLineData);
    }
  }, [isCountryLineData]);

  useEffect(() => {
    if (!isProductData) {
      setApidataLocal(productData);
      // setApidata(generateCountiesDataList(productData));
      //generateCountiesDataList()
      if (selectedContry) {
        setApidata(generateCountiesDataList(productData));
      }
    }
  }, [isProductData, selectedBaseLineFilters]);

  useEffect(() => {
    setApidata(apidataLocal);
    setApidata(generateCountiesDataList(apidataLocal));
  }, [selectedContry, selectedBaseLineFilters]);

  //data loading
  let rowsData = [];

  let weekCount = [5, 5, 6, 5, 5, 5, 4, 5, 5, 4, 5, 6];

  const generateCountiesDataList = (value) => {
    // const countriesList = countryValuesList;



    const countriesList = value;

    let dataRows = [];
    let headerData = [];
    let weekCountAPI = [];

    let totalQty = 0;
    let avgVal = 0;

    let adjTotalQty = 0;
    let adjAvgVal = 0;

    let statisticalBaseLineHeader, adjustedBaseLineHeader;

    let rowList = [];
    let headerList = [];

    let headerMain = {};
    let rowMain = {};
    let sourceMain = {};

    let headerListLocal = [];
    let rowListLocal = [];

    let mainHeaderForWeek = [];

    for (const key in countriesList) {
      let curYear = countriesList[key]["year"];
      statisticalBaseLineHeader = countriesList[key]["statisticalBaseLine"];
      adjustedBaseLineHeader = countriesList[key]["adjustedBaseLine"];

      totalQty += statisticalBaseLineHeader.totalQuantity;
      avgVal += statisticalBaseLineHeader.averageQuantity;

      adjTotalQty += adjustedBaseLineHeader.totalQuantity;
      adjAvgVal += adjustedBaseLineHeader.averageQuantity;;
      let mainList = countriesList[key];

      delete countriesList[key]["year"];

      /// if (countriesList[key] !== 2021) {

      let indexAuto = 0;

      headerListLocal = ["Source", "Total", "Average"];

      for (const keyMain in mainList) {
        let source = keyMain;
        let total = mainList[keyMain].totalQuantity || "";
        let avg = mainList[keyMain].averageQuantity || "";

        if (Array.isArray(sourceMain[indexAuto])) {
          sourceMain[indexAuto] = [source, totalQty, avgVal];
        } else {
          sourceMain[indexAuto] = indexAuto === 1 ? [source, adjTotalQty, adjAvgVal] : [source, totalQty, avgVal];
        }

        rowList = [...rowListLocal];
        headerList = [...headerListLocal];

        let index = 0;

        for (const month in mainList[keyMain]) {
          let count = 0;
          if (month !== "totalQuantity" && month !== "averageQuantity") {
            if (indexAuto === 1) {
              mainHeaderForWeek.push(`${month} ${curYear}`);
            }

            if (mainList[keyMain][month]) {
              if(isEmpty(mainList[keyMain][month])){
                if (Array.isArray(rowMain[indexAuto])) {
                  rowMain[indexAuto].push(0);
                  headerMain[indexAuto].push('-');
                } else {
                  rowMain[indexAuto] = [0];
                  headerMain[indexAuto] = ['-'];
                }
                rowList.push(0);
                headerList.push('-');
                count = 1;
              }
              else{
                for (const monthVal in mainList[keyMain][month]) {
                  if (Array.isArray(rowMain[indexAuto])) {
                    rowMain[indexAuto].push(mainList[keyMain][month][monthVal]);
                    let headerTxt = viewMonthly === "monthly" ? `${monthVal} ${curYear}` : `${monthVal}`;
                    headerMain[indexAuto].push(headerTxt);
                  } else {
                    rowMain[indexAuto] = [mainList[keyMain][month][monthVal]];
                    let headerTxt = viewMonthly === "monthly" ? `${monthVal} ${curYear}` : `${monthVal}`;
                    headerMain[indexAuto] = [headerTxt];
                  }
  
                  rowList.push(mainList[keyMain][month][monthVal]);
                  headerList.push(`${monthVal} ${curYear}`);
                }
                count = Object.keys(mainList[keyMain][month]).length;
              }
              if(keyMain === "adjustedBaseLine"){
                weekCountAPI.push(count);
              }
            } else {
              for (let i = 0; i < weekCount[index]; i++) {
                rowList.push(" ");
              }
              index++;
            }
          }
        }

        rowsData.push(rowList);
        headerData.push(headerList);

        dataRows.push(rowList);
        headerData.push(headerList);

        indexAuto++;
      }
      ///}
    }
    dataRows = [];
    headerData = [];

    for (const key in rowMain) {
      dataRows.push([...sourceMain[key], ...rowMain[key]]);

      headerData.push([...headerListLocal, ...headerMain[key]]);
    }

    let newReturn = { dataRows, headerData, weekCountAPI, mainHeaderForWeek };
    
    return newReturn;
  };

  //generateCountiesDataList();

  //const [returnItems] = useState(generateCountiesDataList());

  const classes = useStyles();


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    if (!selectedContry) {
      handleClickOpen();
    }

  }, []);

  return (
    <div style={{ display: "inline" }} data-testid="baseline">
      {
        (isProductData || isCountryLineData) && <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px',
        }}>
            <CircularProgress sx={{ color: "#0000b3"}}/>
        </Box>
      }

      {(countryLineError || productDataError) && <Toaster showAlert={{
        type: 'error',
        message: "Some thing went wrong, please reload page"
      }} />}

      {!isProductData && selectedContry && apidata && apidata.dataRows?.length > 0 && (
        <>
          <TableContainer component={Paper} className="BaselineGrid">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                {viewMonthly !== "monthly" && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={3}
                      className={classes.cellData}
                    ></TableCell>

                    {apidata.mainHeaderForWeek.map((headeVal, index) => (
                      <TableCell
                        align="center"
                        colSpan={apidata.weekCountAPI[index]}
                        className={classes.cellData}
                        key={index}
                      >
                        {headeVal}
                      </TableCell>
                    ))}
                  </TableRow>
                )}

                <TableRow>
                  {apidata.headerData[0].map((row, i) => (
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.cellData}
                      key={i}
                    >
                      {row}

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {apidata.dataRows.map(
                  (row, i) =>
                    <TableRow key={row[0]}>
                      {row.map((data) => (
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.cellData}
                          key={i}
                        >


                          {data.toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          })}
                        </TableCell>
                      ))}
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {!isProductData && selectedContry && (!apidata || !apidata.dataRows || apidata.dataRows?.length === 0 || apidata.dataRows[0][1] === 0) &&
        <p>No data to display</p>
      }
      {
        !selectedContry
          ? <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            className="mini-dialog"
          >``
            <DialogTitle>
              {"Select Country"}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.btnClose}
              >
                X
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {"Please select Country from countries list drop down"}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.btnContainer}>
              <Button onClick={handleClose} className="btnClose">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          : null
      }


    </div>
  );
}
