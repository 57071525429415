import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core';
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AlertDialog = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.openDialog) {
      setOpen(true);
    }
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    props.handleNo()

  };
  const handleYes = () => {
    setOpen(false);
    props.handleYes()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DialogTitle id="alert-dialog-title">
        Alert
      </DialogTitle>
      <hr />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.dialogMsg}>
          {props.message}
        </DialogContentText>
      </DialogContent>
      <hr />
      <DialogActions>
        <Button variant="contained" size="small" color="success" onClick={handleYes}>Yes</Button>
        <Button size="small" color="error" onClick={handleClose} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const useStyles = makeStyles(() => ({
  actionsIconTextColor: {
    color: '#0000b3',
    textTransform: 'none'
  },
  disabledActionsIconTextColor: {
    color: 'gray',
    textTransform: 'none'
  },
  calculateBtn: {
    float: 'right',
    color: '#fff',
    background: '#0000b3',
    margin: '10px',
    '&:hover': {
      backgroundColor: "#0000b3 !important",
    },
  },
  dialogMsg: {
    color: '#000 !important',
    fontSize: "0.9rem !important"
  }
}))

