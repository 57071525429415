import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Slide,
  IconButton
} from "@material-ui/core"
import InfoDialog from "./InfoDialog";
import Toaster from '../commonMuiComponents/Toaster'
import { CircularProgress, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import useStore from "../store";
import {
  useGetImpactorHistory,
  useImpactorInfo, useDailyDataCheck
} from "../services/queries";
import { useDeleteImpactor } from "../services/mutations";
import { AppQueryClient } from "../services/clients/query-client";
import { generateCountiesDataList, generateCountriesDailyDataList } from "../helper";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
    background: "#fff",
    border: "1px solid rgba(0,0,0,0.2)",
    borderCollapse: 'collapse'
  },
  cellData: {
    textTransform: "capitalize",
    boxShadow: 'inset 0.5px 0.5px 0 rgba(0,0,0,0.1), inset -0.5px -0.5px 0 rgba(0,0,0,0.1)'
  },
  cellDataCenter: {
    textAlign: "center",
    background: '#fff',
    boxShadow: 'inset 0.5px 0.5px 0 rgba(0,0,0,0.1), inset -0.5px -0.5px 0 rgba(0,0,0,0.1)'
  },
  btnContainer: {
    marginTop: "30px",
    float: "right",
    marginRight: "50px",
  },
  btnReset: {
    background: "#cb0c0c",
    color: "#fff",
    textTransform: "none",
    margin: "0 20px",
    padding: "6px 20px",
    borderRadius: "10px",
  },
  btnSucess: {
    background: "#163c62",
    color: "#fff",
    textTransform: "none",
    margin: "0 10px",
    padding: "6px 35px",
    borderRadius: "10px",
  },
  dialogWidth: {
    minWidth: "1000px",
    maxWidth: "1200px",
  },
  btnClose: {
    position: "absolute",
    top: "0",
    right: "5px",
  },
  infoContainer: {
    marginRight: 15,
    cursor: 'pointer'
  },
  tableContainerParent: {
    maxHeight: 500,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: 12,
      height: 12
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px lightgrey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D3D3D3",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "grey",
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Country() {
  let selectedContry = useStore((state) => state.selectedCountry);

  const [selectedImpactor, setSelectorImpactor] = useState("");
  const [impactorInfo, setImpactorInfo] = useState({});
  const [dailyDataAvailable, setDailyDataAvailable] = React.useState(false);

  const [delImp, selectDelImp] = useState("");
  const [showAlert, setShowAlert] = useState({ message: '', type: '' })

  const { isLoading: isLoadingData, data: dailyData } = useDailyDataCheck(selectedContry);
  //store
  const setViewMonthly = useStore((state) => state.setViewMonthly);

  useEffect(() => {
    if (!isLoadingData) {
      setDailyDataAvailable(dailyData.dailyAvailable);
    }
  }, [isLoadingData]);

  let viewMonthly = useStore((state) => (state.viewMonthly === "daily" || state.viewMonthly === "monthly" || state.viewMonthly === "weekly") && dailyDataAvailable ? "daily" : "weekly");

  const countryAndRole = useStore((state) => state.countryAndRole);
  const roles = (countryAndRole && selectedContry) ? countryAndRole[selectedContry] : "";

  //let viewMonthly = useStore((state) => state.viewMonthly);

  const { isLoading: isImpactorHistoryAPI, data: impactorHistoryAPI, isError: impactorHistoryError } =
    useGetImpactorHistory(`${selectedContry}&granularity=${viewMonthly}`);

  const { isLoading: isImpactorInfoAPI, data: impactorInfoAPI, isError: impactorInfoAPIError } =
    useImpactorInfo(`${selectedImpactor}`);

  const [openSave, setOpenSave] = React.useState(false);


  const { mutate: removeImpactor, isSuccess: successCreate, isError: successError } = useDeleteImpactor();


  useEffect(() => {
    if (successCreate) {
      setOpenSave(false);
      setShowAlert({
        type: 'success',
        message: 'Record deleted sucessfully'
      })
    } else if (successError) {
      setOpenSave(false);
      setShowAlert({
        type: 'error',
        message: "Some thing went wrong, please try again"
      })

    }
  }, [successCreate,]);

  useEffect(() => {
    if (!isImpactorInfoAPI) {
      setImpactorInfo(impactorInfoAPI);
    }
  }, [isImpactorInfoAPI]);

  const [impactorHistoryData, setImpactorHistoryData] = useState([]);

  useEffect(() => {
    if (!isImpactorHistoryAPI) {
      console.log("impactorHistoryAPI", impactorHistoryAPI);
      if (viewMonthly !== "daily")
        setImpactorHistoryData(generateCountiesDataList(impactorHistoryAPI));
      else setImpactorHistoryData(generateCountriesDailyDataList(impactorHistoryAPI));
    }
  }, [isImpactorHistoryAPI, selectedContry, impactorHistoryAPI]);

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (deleteImp) => {
    console.log(deleteImp);
    selectDelImp(deleteImp);
    setOpen(true);
  };

  const deleteImpactorNew = () => {
    removeImpactor(delImp);
    setOpen(true);
    setOpenSave(true);
  };



  const deleteImpactorAPI = () => {
    //setDeleteImpactor(delImp);
    removeImpactor(`name=${delImp}&country=${selectedContry}`);
    setOpenSave(true);

    handleClose();

    setTimeout(() => {
      AppQueryClient.invalidateQueries("useGetImpactorHistory");
    }, 5000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openInfo, setOpenInfo] = React.useState(false);

  const handleClickInfoOpen = (impName) => {
    console.log("line26000", impName);
    setSelectorImpactor(impName);
    setOpenInfo(true);
  };

  const handleInfoClose = () => {
    setOpenInfo(false);
  };

  return (
    <div style={{display: 'inline'}}>
      {isImpactorHistoryAPI && <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px',
        }}>
            <CircularProgress sx={{ color: "#0000b3"}} />
        </Box>
      }
      {
        (impactorInfoAPIError || impactorHistoryError) && setShowAlert({
          type: 'error',
          message: "Something went wrong, please reload page"
        })
      }
      {!isImpactorHistoryAPI &&
        selectedContry &&
        impactorHistoryData.dataRows?.length > 0 && (
          <TableContainer component={Paper} className={classes.tableContainerParent}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead
                style={{
                  position: "sticky",
                  top: 0,
                  left: 0,
                  zIndex: 900,
                  background: "white"
                }}>
                <TableRow>
                  <TableCell
                    align="center"
                    className={classes.cellData}
                    colSpan={"1"}
                  >
                    {impactorHistoryData.headerData[0][0]}
                  </TableCell>


                  {impactorHistoryData.headerData[0]
                    .slice(1)
                    .map((headeVal, index) => (
                      <TableCell
                        align="center"
                        className={classes.cellData}
                        colSpan={impactorHistoryData.weekCountAPI[index + 1]}
                      >
                        {headeVal}
                      </TableCell>
                    ))}

                  <TableCell
                    align="center"
                    className={classes.cellData}
                  ></TableCell>
                </TableRow>

                <TableRow>
                  {impactorHistoryData.subAryheader[1]?.map((row, i) => (
                    <>
                      {!Array.isArray(row) && (
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.cellDataCenter}
                        >
                          {row}
                        </TableCell>
                      )}

                      {Array.isArray(row) &&
                        row.map((aryVal) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.cellDataCenter}
                          >
                            {aryVal}
                          </TableCell>
                        ))}
                    </>
                  ))}
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.cellDataCenter}
                    style={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      background: "white",
                      width: 100,
                      minWidth: 100,
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {impactorHistoryData.dataRows?.map((row, i) => (
                  <>
                    <TableRow key={"row[0]"}>
                      {Array.isArray(row) && (
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 800,
                            background: "white",
                          }}
                          component="th"
                          scope="row"
                          className={classes.cellDataCenter}
                        >
                          {row[0]}
                        </TableCell>
                      )}

                      {Array.isArray(row) &&
                        row.slice(1).map((aryVal) => (
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.cellDataCenter}
                          >
                            {aryVal}
                          </TableCell>
                        ))}
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.cellDataCenter}
                        style={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                          background: "white",
                          width: 100,
                          minWidth: 100,
                        }}
                      >
                        <span
                          onClick={() => handleClickInfoOpen(row[0])}
                          className={classes.infoContainer}
                        >
                          <InfoIcon sx={{ color: "#0000b3" }} />
                        </span>
                        {roles && roles.includes('Admin') &&
                          <span onClick={() => handleClickOpen(row[0])}>
                            <DeleteIcon sx={{ color: "#FF0000", cursor: 'pointer' }} />
                          </span>
                        }
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Are you sure?"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.btnClose}
          >
            X
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btnContainer}>
          <Button onClick={handleClose} className={classes.btnReset}>
            Cancel
          </Button>
          <Button
            onClick={() => deleteImpactorAPI()}
            className={classes.btnSucess}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/*  info dialog starts here */}
      <Dialog
        open={openInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleInfoClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {selectedImpactor}
          <IconButton
            aria-label="close"
            onClick={handleInfoClose}
            className={classes.btnClose}
          >
            X
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <InfoDialog impactorData={impactorInfo} />
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <Button onClick={handleInfoClose} className={classes.btnReset}>
              Close
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>


      <Dialog
        open={openSave}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Record Deleting"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Recording deleting, please wait
          </DialogContentText>
        </DialogContent>

      </Dialog>


      {!isImpactorHistoryAPI &&
        impactorHistoryData.dataRows?.length === 0 && (
          <div>No records to display </div>
        )}
      <Toaster showAlert={showAlert} />
    </div>
  );
}
